import { useDispatch, useSelector } from "react-redux";

import {
  closeFullModeViewInsight,
  closeViewInsight,
  openFullModeViewInsight,
  openViewInsight,
  updateViewInsight,
} from "core/models/dashboard/actions";
import { selector as dashboardSelector } from "core/models/dashboard/selectors";
import { toggleEdit } from "core/models/pinnedReports/actions";
import { IViewInsight } from "core/models/viewInsights";

export const useEditDashboard = () => {
  const { openedInsight, fullModeInsight } = useSelector(dashboardSelector);
  const dispatch = useDispatch();
  const onUpdateViewInsight = (viewInsight: IViewInsight) =>
    dispatch(updateViewInsight({ viewInsight }));
  const clearOpenedViewInsight = () => dispatch(closeViewInsight());
  const openInsightBuilder = (viewInsight: IViewInsight) =>
    dispatch(openViewInsight({ viewInsight }));
  const onOpenFullModeViewInsight = (viewInsight: IViewInsight) =>
    dispatch(openFullModeViewInsight({ viewInsight }));
  const onCloseFullModeViewInsight = () => dispatch(closeFullModeViewInsight());
  const toggleEditDashboard = () => dispatch(toggleEdit());

  return {
    openedInsight,
    fullModeInsight,
    onUpdateViewInsight,
    clearOpenedViewInsight,
    openInsightBuilder,
    onOpenFullModeViewInsight,
    onCloseFullModeViewInsight,
    toggleEditDashboard,
  };
};
