import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useCrmSyncSettings } from "core/hooks/useCrmSyncSettings";
import {
  useCreateObjectMutation,
  useLazyGetFetchObjectsQuery,
  useUpdateObjectMutation,
} from "core/models/attio";
import { Integration } from "core/models/dataMappings";
import { IAppObject } from "core/types/AppObject";
import { IContact } from "core/types/Contact";
import { IGroup } from "core/types/Group";

export interface IAttioObjectsResponse {
  data: {
    [key: string]: string;
  }[];
  lastSyncedAt: string;
}

export const useAttioObjects = ({
  entity,
  appObject,
}: {
  entity: IContact | IGroup;
  appObject: IAppObject;
}) => {
  const { appId } = useParams();
  const [
    getFetchObjects,
    { data, isLoading: isLoadingObjects, isFetching: isFetchingObjects, error },
  ] = useLazyGetFetchObjectsQuery();
  const [createUser, { isLoading: isCreatingObject }] =
    useCreateObjectMutation();
  const [updateUser, { isLoading: isUpdatingObject }] =
    useUpdateObjectMutation();
  const { crmSyncSetting } = useCrmSyncSettings({
    integration: Integration.Attio,
    objectType: appObject.objectType,
  });

  useEffect(() => {
    onFetchObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, entity?.id]);

  function onFetchObjects() {
    getFetchObjects({
      appId: Number(appId),
      entityId: entity.id,
      appObjectId: appObject.id,
    });
  }

  function onCreateObject() {
    createUser({
      appId: Number(appId),
      entityId: entity.id,
      appObjectId: appObject.id,
    });
  }

  function onUpdateObject() {
    updateUser({
      appId: Number(appId),
      entityId: entity.id,
      appObjectId: appObject.id,
    });
  }

  return {
    objects: data?.objects || [],
    workspaceName: data?.workspaceName || "",
    objectSlug: data?.objectSlug || "",
    custom: data?.custom || false,
    route: data?.objectRoute,
    isLoading: isLoadingObjects,
    isFetching: isFetchingObjects,
    error,
    lastSyncedAt: data?.lastSyncedAt,
    isCreatingObject,
    isUpdatingObject,
    onFetchObjects,
    onCreateObject,
    onUpdateObject,
    crmSyncSetting,
  };
};
