import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "core/initializers/store";
import { IAlert } from "core/types/Alert";

interface EditEventAlertState {
  alerts: {
    [id: number]: IAlert;
  };
}

const initialState: EditEventAlertState = {
  alerts: {},
};

export const editEventAlertSlice = createSlice({
  name: "editEventAlert",
  initialState,
  reducers: {
    setEventAlert: (state, action) => {
      state.alerts[action.payload.id] = action.payload;
    },
    resetEventAlert: (state, action) => {
      delete state.alerts[action.payload.id];
    },
  },
});

export const { setEventAlert, resetEventAlert } = editEventAlertSlice.actions;
export const { reducer: editEventAlertSliceReducer } = editEventAlertSlice;
export const editedAlertSelector = (id: number) => (state: RootState) =>
  state.editEventAlert.alerts[id];
