import { JuneSpinner } from "@/core/components/JuneSpinner";
import { PageContainer } from "@/core/components/PageContainer";

export const JuneLoadingPage: React.FC = () => {
  return (
    <div className="h-screen w-full overflow-hidden">
      <PageContainer className="ml-1" maxW="full" containerless>
        <div className="flex min-h-screen w-full items-center justify-center">
          <div className="flex flex-col items-center gap-2">
            <JuneSpinner />
            <p className="text-sm font-medium text-muted-foreground">
              Loading...
            </p>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};
