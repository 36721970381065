import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

import Teammate from "core/components/Teams/Teammate";
import { Icon } from "core/design-system/components/Icon";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useInviteUsers } from "core/hooks/useInviteUsers";
import { useGetUsersQuery } from "core/models/users";
import { IAppUser } from "core/types/User";

interface IInviteTeam {
  page: string;
}

export const InviteTeam: React.FC<IInviteTeam> = ({ page }) => {
  const { id: appId } = useCurrentApp();
  const [emails, setEmails] = useState<string>("");
  const { data, isLoading, refetch } = useGetUsersQuery({ appId });
  const { validationError, inviteUsers } = useInviteUsers();

  return (
    <>
      <Flex direction="column" align="center" mb={2}>
        <Text color="gray.800" fontWeight="medium" fontSize="xl">
          June is better together 💜
        </Text>
        <Text color="gray.600">
          Invite team members to join your workspace, at no additional cost.
        </Text>
      </Flex>
      {validationError ? (
        <Flex
          align="center"
          bg="red.50"
          borderRadius="lg"
          color="red.500"
          px={4}
          py={2}
          mt={2}
          mb={2}
        >
          <Icon iconType="icon" name="warning" mr={2} />
          <Text fontSize="sm">{validationError}</Text>
        </Flex>
      ) : undefined}
      <Flex w="100%" mt={4} justifyContent="space-between">
        <InputGroup size="2xl">
          <Input
            w="full"
            py={2}
            focusBorderColor="purple.500"
            placeholder="example1@email.com, example2@email.com"
            type="email"
            name="email"
            value={emails}
            onChange={(e) => {
              setEmails(e.target.value);
            }}
            borderRadius="lg"
            size="sm"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                inviteUsers({ event: e, emails, page });
              }
            }}
          />

          <InputRightElement>
            <Button
              borderLeftRadius={0}
              colorScheme="purple"
              onClick={(e) => inviteUsers({ event: e, emails, page })}
            >
              Send invites
            </Button>
          </InputRightElement>
        </InputGroup>
      </Flex>
      {isLoading ? (
        <>
          <Skeleton h="40px" mt={4} borderRadius="lg" />
          <Skeleton h="40px" mt={2} borderRadius="lg" />
          <Skeleton h="40px" mt={2} borderRadius="lg" />
        </>
      ) : (
        <SimpleGrid
          mt={4}
          columns={1}
          borderWidth="1px"
          borderColor="gray.100"
          borderRadius="lg"
        >
          {data?.map((invitee: IAppUser, index: number) => (
            <Teammate
              key={index}
              mutateUserList={refetch}
              member={invitee}
              showActions={false}
            />
          ))}
        </SimpleGrid>
      )}
    </>
  );
};
