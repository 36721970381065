import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { IAudience } from "core/types/Audience";

const AudienceNameCell: React.FC<{ props: IAudience }> = ({
  props: { id, name, description },
}) => {
  const { id: appId } = useCurrentApp();

  return (
    <Link to={`/a/${appId}/audience/${id}`}>
      <div className="flex items-center gap-4">
        <Flex align="flex-start" direction="column">
          <Text noOfLines={1} maxW="600" fontSize="sm">
            {name}
          </Text>
          <Text noOfLines={1} maxW="600" fontSize="sm" color="gray.600">
            {description}
          </Text>
        </Flex>
      </div>
    </Link>
  );
};

export default AudienceNameCell;
