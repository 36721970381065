import humps from "humps";

import axios from "core/initializers/axios";
import { AppThunk } from "core/initializers/store";
import { slice } from "core/models/contact/index";
import { IContactGroups } from "core/types/Contact";

interface IContactGroupsParams {
  appId: string | number;
  id: string;
}

export const fetchOneContactGroups =
  ({ appId, id }: IContactGroupsParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.fetchingContactGroups({ id }));
      const { data } = await axios.get<IContactGroups>(
        `/contacts/${encodeURIComponent(id)}/groups`,
        {
          params: humps.decamelizeKeys({ appId }),
        },
      );

      dispatch(slice.actions.upsertOneContactGroups({ id, data }));
    } catch (e: any) {
      const title = "Failed to fetch contact groups";
      const description = e?.response?.data?.error;
      const error = `${title}: ${description}`;
      dispatch(slice.actions.fetchingContactGroupsFailed({ id, error }));
    }
  };
