import { defineStyle } from "@chakra-ui/react";
import { cssVar } from "@chakra-ui/styled-system";

const $arrowBg = cssVar("popper-arrow-bg");

const baseStyle = {
  borderRadius: "lg",
  fontSize: "sm",
  fontWeight: "medium",
  size: "md",
  color: "white",
  bg: "gray.900",
  [$arrowBg.variable]: "colors.gray.900",
};

const sizes = {
  sm: defineStyle({
    fontSize: "sm",
    px: 2,
    py: 0.5,
    fontWeight: "normal",
  }),
  md: defineStyle({
    p: 3,
  }),
};

const Tooltip = {
  baseStyle,
  sizes,
};

export default Tooltip;
