import { Label } from "Components/ui/label";
import { PercentChange } from "core/design-system/components/PercentChange";

export const NewActiveUsersCustomTooltipHeadless = ({
  label,
  payload,
  active,
  unit,
  showNew,
  withDrawer,
  onClick,
}: {
  label: string;
  payload: any;
  active: boolean;
  unit: string;
  showNew: boolean;
  withDrawer: boolean;
  onClick: (_: any, payload: any) => void;
}) => {
  const data = payload && payload[0] && payload[0].payload;

  if (!active || !data) return null;

  const totalActiveUsers =
    data.currentActiveUsers !== undefined
      ? data.currentActiveUsers
      : data.activeUsers;
  const returningActiveUsers =
    data.currentIdentifiedUsersCount !== undefined
      ? data.currentIdentifiedUsersCount
      : data.identifiedUsersCount;
  const newActiveUsers =
    data.currentIdentifiedNewUsersCount !== undefined
      ? data.currentIdentifiedNewUsersCount
      : data.identifiedNewUsersCount;

  return (
    <div
      className="flex w-full flex-col justify-between rounded-lg border border-gray-50 bg-white p-2 shadow-xl"
      onClick={() => {
        return withDrawer ? onClick(undefined, { payload: data }) : null;
      }}
    >
      {showNew && (
        <div className="flex w-full flex-col justify-between">
          <div className="ml-0.5 flex h-full w-full gap-2">
            <div className="h-[full] min-w-[3px] max-w-[3px] rounded-full bg-gray-200" />
            <div>
              <div className="flex w-full justify-between truncate">
                <Label className="text-xs">{label}</Label>
              </div>
              {typeof totalActiveUsers === "number" && (
                <p className="text-xs text-gray-600">
                  Total {totalActiveUsers} active users
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="mt-2">
        {showNew && (
          <>
            {typeof newActiveUsers === "number" && (
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center gap-1.5">
                  <div className="h-[8px] w-[8px] rounded-[2px] bg-purple-300"></div>
                  <p className="text-xs">{newActiveUsers} new</p>
                </div>
                {data.previousDate && (
                  <PercentChange
                    ml={3}
                    percentChange={data.growthIdentifiedNew}
                  />
                )}
              </div>
            )}
          </>
        )}
        <>
          {typeof returningActiveUsers === "number" && (
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-1.5">
                <div className="h-[8px] w-[8px] rounded-[2px] bg-purple-400"></div>
                <p className="text-xs">{returningActiveUsers} returning</p>
              </div>
              <div className="flex justify-end">
                {data.previousDate && (
                  <PercentChange ml={3} percentChange={data.growthIdentified} />
                )}
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
};
