import { IListConfig } from "core/components/List/List.types";
import AudienceActionsCell from "modules/Audiences/List/AudienceActionsCell";
import AudienceNameCell from "modules/Audiences/List/AudienceNameCell";
import { AudiencePinCell } from "modules/Audiences/List/AudiencePinCell";
import { UsersCountCell } from "modules/Audiences/List/UsersCountCell";

const config = {
  resourceName: "audiences",
  columns: [
    {
      title: "",
      name: "pinned",
      align: "left",
      Component: AudiencePinCell,
      width: 10,
    },
    {
      title: "Audience",
      name: "name",
      align: "left",
      Component: AudienceNameCell,
    },
    {
      title: "Users",
      name: "contactCount",
      align: "left",
      tooltip: "",
      Component: UsersCountCell,
    },
    {
      title: "",
      name: "menu",
      align: "right",
      Component: AudienceActionsCell,
    },
  ],
} as IListConfig;

export { config };
