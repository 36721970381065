import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import { SnakeCasedPropertiesDeep } from "type-fest";

import { baseQuery } from "core/initializers/rtk";
import { CrmSyncStatus } from "core/models/crmSyncs";
import { IAppObject } from "core/types";
import { IAudience } from "core/types/Audience";

export interface IStripeApp {
  id?: number;
  appId?: number;
  createdAt?: string;
  updatedAt?: string;
  appObjectId?: number;
  matchingLogic?: "manual" | "automatic";
  sourceTrait?: string;
}

export interface IStripeApps {
  stripeApps: IStripeApp[];
}

export interface IStripeSync {
  id: number;
  appId: number;
  appObject: IAppObject;
  status: CrmSyncStatus;
  createdAt: string;
  updatedAt: string;
}

export const stripeAppsApi = createApi({
  baseQuery,
  reducerPath: "stripeAppsApi",
  tagTypes: ["StripeApps", "StripeSyncs"],
  endpoints: (builder) => ({
    getStripeApps: builder.query<IStripeApps, { appId: number }>({
      query: ({ appId }) => ({
        url: "/stripe_apps",
        params: humps.decamelizeKeys({ appId }),
      }),
      providesTags: ["StripeApps"],
      transformResponse: (response: IStripeApps): IStripeApps =>
        humps.camelizeKeys(response) as IStripeApps,
    }),
    deleteStripeApp: builder.mutation<void, { id: number; appId: number }>({
      query: ({ id, appId }) => ({
        url: `/stripe_apps/${id}`,
        method: "DELETE",
        params: humps.decamelizeKeys({ appId }),
      }),
      invalidatesTags: ["StripeApps"],
    }),
    updateStripeApp: builder.mutation<
      void,
      {
        id: number;
        appId: number;
        appObjectId?: number;
        matchingLogic?: "manual" | "automatic";
        sourceTrait?: string;
      }
    >({
      query: ({ id, appId, appObjectId, matchingLogic, sourceTrait }) => ({
        url: `/stripe_apps/${id}`,
        method: "PUT",
        params: humps.decamelizeKeys({ appId }),
        body: humps.decamelizeKeys({
          stripeApp: {
            appObjectId,
            matchingLogic,
            sourceTrait,
          },
        }),
      }),
      invalidatesTags: ["StripeApps", "StripeSyncs"],
    }),
    getLastStripeSync: builder.query<IStripeSync, { appId: number }>({
      query: ({ appId }) => ({
        url: `/stripe_apps/last_sync`,
        params: humps.decamelizeKeys({ appId }),
      }),
      providesTags: ["StripeSyncs"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IStripeSync>,
      ): IStripeSync => humps.camelizeKeys(response) as IStripeSync,
    }),
    getStripeAudience: builder.query<IAudience, { appId: number }>({
      query: ({ appId }) => ({
        url: `/stripe_apps/stripe_audience`,
        params: humps.decamelizeKeys({ appId }),
      }),
      providesTags: ["StripeSyncs"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IAudience>,
      ): IAudience => humps.camelizeKeys(response) as IAudience,
    }),
  }),
});

export const {
  useGetStripeAppsQuery,
  useDeleteStripeAppMutation,
  useUpdateStripeAppMutation,
  useGetLastStripeSyncQuery,
  useGetStripeAudienceQuery,
} = stripeAppsApi;
