import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

import { IssueLevel } from "core/helpers/event-validation/issue";

function getColorByValidationResult(maxLevel: IssueLevel | null) {
  if (!maxLevel) {
    return "text-green-400";
  }

  if (maxLevel === "error") {
    return "text-red-500";
  }

  if (maxLevel === "warning") {
    return "text-orange-500";
  }

  if (maxLevel === "info") {
    return "text-blue-500";
  }

  return null;
}

export const LogIcon = ({
  issueLevel,
}: {
  issueLevel: IssueLevel | null;
  size?: "normal" | "sm" | "xs";
}) => {
  const color = getColorByValidationResult(issueLevel);
  if (!issueLevel) {
    return <CheckCircleIcon className={`${color} size-5 flex-none`} />;
  }

  if (issueLevel === "error") {
    return <ExclamationCircleIcon className={`${color} size-5 flex-none`} />;
  }

  if (issueLevel === "warning") {
    return <ExclamationTriangleIcon className={`${color} size-5 flex-none`} />;
  }

  if (issueLevel === "info") {
    return (
      <InformationCircleIcon
        className={`${color} size-5 flex-none`}
        aria-hidden="true"
      />
    );
  }

  return null;
};
