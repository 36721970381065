import { Center } from "@chakra-ui/react";

import { ConversationContainer } from "core/components/AI/ConversationContainer";

export const AIContainer: React.FC = () => {
  return (
    <Center w="full" data-testid="ai-container">
      <ConversationContainer />
    </Center>
  );
};
