import { Skeleton } from "@chakra-ui/react";
import { get, isNil } from "lodash";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkParse from "remark-parse";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetEventQuery } from "core/models/events";
import { EventProperties, useGetPropertiesQuery } from "core/models/properties";
import { IEvent } from "core/types/Event";

import "modules/Events/List/markdown.styles.css";

const getPropertyValue = (properties: EventProperties, variable: string) => {
  const path = variable.replace("event.properties.", "").split(".");
  const propertyName = path[0];
  const property = properties.find(({ property }) => property === propertyName);

  if (!property) {
    return "*invalid property*";
  }

  if (typeof property?.value !== "object") {
    return property.value;
  }

  return get(property.value, path.slice(1), "*invalid or empty property*");
};

const variableValue = ({
  event,
  properties,
  placeholder,
}: {
  placeholder: string;
  event?: IEvent;
  properties?: EventProperties;
}) => {
  // Remove braces
  const variable = placeholder.replace(/}|{/g, "");
  let value = "";
  if (variable.includes("contact.traits")) {
    return "**test_contact_trait**";
  }
  if (variable.includes("company.traits")) {
    return "**test_company_trait**";
  }
  if (variable.includes("group.traits")) {
    return "**test_group_trait**";
  }
  if (variable.includes("event.properties.")) {
    return getPropertyValue(properties || [], variable);
  }

  switch (variable) {
    case "event.name":
      value = event?.name || "Signed up";
      break;
    case "contact.email":
      value = "**user@example.com**";
      break;
    case "contact.user_id":
      value = "**23456**";
      break;
    case "company.name":
      value = "**Google**";
      break;
    case "audience.name":
      value = "Churn risk";
      break;
    default:
      value = `*Error: invalid property in variable **${variable
        .split(`{{`)
        .join("")
        .split("}}")
        .join("")}** *`;
      break;
  }
  return value;
};

export const RenderedMessagePreview: React.FC<{
  messageBody: string;
  eventId?: number;
}> = ({ messageBody, eventId }) => {
  const { id: appId } = useCurrentApp();
  const regex = /\{\{(.+?)\}\}/g;

  const { data: event, isFetching } = useGetEventQuery(
    {
      appId,
      eventId: eventId!,
    },
    {
      skip: !eventId,
    },
  );

  const { data: properties, isLoading: propertiesLoading } =
    useGetPropertiesQuery(
      {
        appId,
        eventId: eventId!,
        withExampleValue: true,
      },
      {
        skip: !eventId,
      },
    );

  const getRenderedMessage = () => {
    let messageString = messageBody;
    let matchObject = null;
    matchObject = messageString.match(regex);

    // eslint-disable-next-line no-constant-condition
    while (!isNil(matchObject)) {
      messageString = messageString.replace(
        matchObject[0],
        variableValue({ placeholder: matchObject[0], event, properties }),
      );
      matchObject = messageString.match(regex);
    }

    return messageString;
  };

  const renderedMessage = getRenderedMessage();

  return (
    <>
      {isFetching || propertiesLoading ? (
        <Skeleton h="2rem" w="95%" />
      ) : (
        <ReactMarkdown
          className="markdown"
          linkTarget="_blank"
          children={renderedMessage}
          remarkPlugins={[remarkParse, remarkBreaks]}
        />
      )}
    </>
  );
};

export default RenderedMessagePreview;
