import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";

import { baseQuery } from "core/initializers/rtk";
import { IViewInsight } from "core/models/viewInsights";

export interface IQueryResult {
  columns: { name: string; type: string }[];
  data: any;
}

export interface IExecuteResponse {
  result: IQueryResult;
  error: boolean;
}

export const sqlApi = createApi({
  baseQuery,
  reducerPath: "sqlApi",
  tagTypes: ["Sql"],
  endpoints: (builder) => ({
    prompt: builder.query<
      { query: string },
      { appId: number; prompt: string; query: string }
    >({
      query: ({ appId, prompt, query }) => ({
        url: `/sql/prompt`,
        params: humps.decamelizeKeys({ appId, prompt, query }),
      }),
      transformResponse: (response: { query: string }) =>
        humps.camelizeKeys(response) as { query: string },
    }),
    execute: builder.query<
      IExecuteResponse,
      {
        appId: number;
        viewId: number;
        queryId: number;
        groupId?: string;
        sharingSecretToken?: string;
      }
    >({
      query: ({ appId, viewId, queryId, groupId, sharingSecretToken }) => ({
        url: `/sql/execute`,
        params: humps.decamelizeKeys({
          appId,
          viewId,
          queryId,
          groupId,
          sharingSecretToken,
        }),
      }),
    }),
    preview: builder.query<IViewInsight, { appId: number; viewId: number }>({
      query: ({ appId, viewId }) => ({
        url: `/sql/preview`,
        params: humps.decamelizeKeys({ appId, viewId }),
      }),
      transformResponse: (response: IViewInsight) =>
        humps.camelizeKeys(response) as IViewInsight,
    }),
    update: builder.mutation<
      void,
      {
        appId: number;
        viewInsightId: number;
        title: string;
        graphType: string;
        color: string;
        gridWidth: number;
        graphXAxis: string;
        graphYAxis: string;
        query: string;
        state?: number;
      }
    >({
      query: ({
        appId,
        viewInsightId,
        title,
        graphType,
        color,
        gridWidth,
        graphXAxis,
        graphYAxis,
        query,
        state,
      }) => ({
        url: `/sql/${viewInsightId}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          appId,
          viewInsightId,
          title,
          graphType,
          color,
          gridWidth,
          graphXAxis,
          graphYAxis,
          query,
          state,
        }),
      }),
    }),
  }),
});

export const {
  useLazyPromptQuery,
  useLazyExecuteQuery,
  usePreviewQuery,
  useUpdateMutation,
} = sqlApi;
