import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import { ICohortTableTitle } from "core/components/CohortTable/CohortTable.types";

export const OverviewRow = ({
  text,
  fontWeight,
  color,
  fontSize = "sm",
  ...props
}: ICohortTableTitle) => {
  return (
    <Flex h="40px" my={2} align="center" w="120px" {...props}>
      <Text fontSize={fontSize} fontWeight={fontWeight} color={color}>
        {text}
      </Text>
    </Flex>
  );
};
