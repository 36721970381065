import { IQueryResult } from "core/models/sql";

export const useSqlCsvExport = ({ result }: { result: IQueryResult }) => {
  const getCSVData = () => {
    if (!result || !result.data) return [];

    return result.data.map((row: any) => {
      return Object.values(row).map((value: any) => {
        return typeof value === "object" || Array.isArray(value)
          ? JSON.stringify(value)
          : value;
      });
    });
  };

  const getCSVHeaders = () => {
    if (!result || !result.columns) return [];

    return result.columns.map((column: any) => column.name);
  };

  return {
    data: getCSVData(),
    headers: getCSVHeaders(),
  };
};
