import { Issue } from "core/helpers/event-validation/issue";
import { isDefinedString, isId } from "core/helpers/event-validation/utils";

export const validateTrack = (payload: any): Issue[] => {
  const issues: Issue[] = [];

  if (!isId(payload["userId"])) {
    issues.push({
      level: "warning",
      message: "Missing user id",
    });
  }

  if (!isDefinedString(payload["name"])) {
    issues.push({
      level: "error",
      message: "Missing event name",
    });
  }

  if (
    isDefinedString(payload["properties"]?.["context"]?.["groupId"]) &&
    !isId(payload["context"]?.["groupId"])
  ) {
    issues.push({
      level: "warning",
      message:
        "The groupId should be sent as part of the context object not as a property",
    });
  }

  if (!isDefinedString(payload["timestamp"])) {
    issues.push({
      level: "error",
      message: "Missing timestamp",
    });
  }

  if (!isId(payload["context"]?.["groupId"])) {
    issues.push({
      level: "info",
      message: "Missing group id",
    });
  }

  return issues;
};
