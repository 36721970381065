import { Navigate } from "react-router-dom";

import { NEW_COMPANY_PROFILE } from "core/constants/features";
import useFlag from "core/hooks/useFlag";
import { NewGroupPage } from "modules/Groups/Group/NewGroup";

export const NewGroup: React.FC = () => {
  const hasNewGroupEnabled = useFlag(NEW_COMPANY_PROFILE);

  if (!hasNewGroupEnabled) {
    return <Navigate to="/groups" />;
  }
  return <NewGroupPage />;
};
