import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Tooltip,
} from "@chakra-ui/react";
import {
  BuildingOffice2Icon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/20/solid";

import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { thousandsToK } from "core/helpers/thousands";
import { useAppObjects } from "core/hooks/useAppObjects";
import { usePeopleCount } from "core/hooks/usePeopleCount";
import { Level } from "core/models/people";
import { AppObjectType, IAppObject } from "core/types/AppObject";
import { IAudienceFilters } from "core/types/Audience";
import { updateLevel } from "helpers/filters";

interface CountData {
  matchedCount: number | undefined;
  totalCount: number | undefined;
  isLoadingCount: boolean;
}

const getCountForType = (
  type: AppObjectType,
  counts: {
    user: CountData;
    company: CountData;
    group: CountData;
  },
): CountData => {
  switch (type) {
    case AppObjectType.User:
      return counts.user;
    case AppObjectType.Company:
      return counts.company;
    case AppObjectType.Group:
      return counts.group;
  }
};

const getIconForType = (type: AppObjectType) => {
  switch (type) {
    case AppObjectType.User:
      return <UserIcon className="h-4" />;
    case AppObjectType.Company:
      return <BuildingOffice2Icon className="h-4" />;
    case AppObjectType.Group:
      return <UserGroupIcon className="h-4" />;
  }
};

const CountDisplay: React.FC<{
  isLoading: boolean;
  count: number;
  total: number;
  label: string;
}> = ({ isLoading, count, total, label }) => {
  if (isLoading) {
    return <Skeleton h="20px" w="40px" />;
  }

  return (
    <Tooltip
      placement="right"
      label={`Out of ${thousandsToK(total)?.toLocaleString()} ${label}`}
      hasArrow
    >
      <p className="text-xs font-semibold">
        {thousandsToK(count)?.toLocaleString()}
      </p>
    </Tooltip>
  );
};

export const PeopleSelect: React.FC<{
  audienceFilters: IAudienceFilters;
  searchQuery: string;
  setPage: (page: number) => void;
}> = ({ audienceFilters, searchQuery, setPage }) => {
  const { appObjects, activeAppObject, setActiveAppObject } = useAppObjects();

  const handleLevelChange = (appObject: IAppObject) => {
    setActiveAppObject(appObject);
    updateLevel(appObject.slug);
    setPage(1);
  };

  const userCounts = usePeopleCount({
    audienceFilters,
    searchQuery,
    level: Level.User,
  });

  const companyCounts = usePeopleCount({
    audienceFilters,
    searchQuery,
    level: Level.Group,
    groupType: GroupType.Company,
  });

  const groupCounts = usePeopleCount({
    audienceFilters,
    searchQuery,
    level: Level.Group,
    groupType: GroupType.Group,
  });

  const counts = {
    user: userCounts,
    company: companyCounts,
    group: groupCounts,
  };

  const renderMenuContent = (appObject: IAppObject, showCount = true) => {
    const { matchedCount, totalCount, isLoadingCount } = getCountForType(
      appObject.objectType,
      counts,
    );

    return (
      <div className="flex w-full items-center justify-between gap-1">
        <div className="flex items-center gap-1">
          {getIconForType(appObject.objectType)}
          {appObject.pluralName}
        </div>
        {showCount && (
          <CountDisplay
            isLoading={isLoadingCount}
            count={matchedCount ?? 0}
            total={totalCount ?? 0}
            label={appObject.pluralName.toLowerCase()}
          />
        )}
      </div>
    );
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        bg="gray.50"
        border="none"
        borderRadius="lg"
      >
        {activeAppObject && renderMenuContent(activeAppObject, false)}
      </MenuButton>
      <MenuList>
        {appObjects
          .filter((aO) => aO.isEnabled)
          .map((appObject) => (
            <MenuItem
              key={appObject.objectType}
              onClick={() => handleLevelChange(appObject)}
            >
              {renderMenuContent(appObject)}
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
};
