import {
  Box,
  Flex,
  HStack,
  StackDivider,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import debounce from "lodash/debounce";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

import InsightCard from "core/components/InsightCard/Index";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import GoalReachedModal from "core/modules/reports/Achievements/GoalReachedModal";
import { MOCK_MILESTONES_INSIGHT } from "core/modules/reports/Achievements/mocks";
import { TrackProgress } from "core/modules/reports/Achievements/TrackProgress";
import {
  Goal,
  InsightResponse,
  Track,
} from "core/modules/reports/Achievements/types";
import {
  buildAchievementsState,
  diffAndSave,
  getCompletionState,
  getEmptyInsight,
  getMetricText,
} from "core/modules/reports/Achievements/utils";
import { isUserReportLevel } from "core/modules/reports/utils";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

export const AchievementsContainer: React.FC<IReportInsight> = ({
  report,
  reportId,
  config,
  previewMode,
  sharingMode,
  screenshotMode,
  reportLevel,
  sharingSecretToken,
}) => {
  const isUserReport = isUserReportLevel(reportLevel);
  const [animationsFinished, setAnimationsFinished] = useState(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [goalChanges, setGoalChanges] = useState<Goal[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const insightSlug = isUserReport
    ? "feature-adoption-milestones"
    : "feature-adoption-company-milestones";
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === insightSlug,
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const { response, isFetching, onRefreshInsight } = useInsightDataFetching({
    report,
    insight,
    insightParams: { truncate: true },
    sharingSecretToken,
    previewMode,
    skip: !isSetupValid,
  });
  const { activeAppObject } = useAppObjects();

  let trackData = useMemo<InsightResponse>(() => {
    if (previewMode) return MOCK_MILESTONES_INSIGHT.data;
    return response?.data ?? getEmptyInsight();
  }, [response, previewMode]);

  // Build the game state.
  const state = useMemo(
    () => buildAchievementsState(trackData ?? getEmptyInsight()),
    [trackData],
  );

  // Make sure that we diff previous state and save the current state.
  useEffect(() => {
    if (reportId && !(isFetching || previewMode)) {
      const { isFirstLoad, changes: diff } = diffAndSave(reportId, state);
      // Prevent infinite renders.
      if (diff.length > 0) {
        setFirstLoad((prevFirstLoad) => !prevFirstLoad && isFirstLoad);
        setGoalChanges((prevChanges) => [...prevChanges, ...diff]);

        if (!isOpen) {
          onOpen();
        }
      }
    }
  }, [
    isOpen,
    goalChanges,
    isFetching,
    previewMode,
    reportId,
    state,
    onOpen,
    onClose,
  ]);

  const isFinished: boolean = useMemo(() => getCompletionState(state), [state]);

  // If we have multiple animations as children, give a 200ms grace interval for all of them to finish up.
  // No way to check if a group of animations are done in Framer Motion.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAnimationFinish = useCallback(
    debounce(() => {
      setAnimationsFinished(true);
    }, 200),
    [],
  );

  const onModalClose = useCallback(() => {
    onClose();
    setGoalChanges([]);
    setFirstLoad(false);
  }, [onClose]);

  const adoptionPercentage = response?.data?.adoption?.percentage;
  const featureReleasedTimestamp = moment(
    response?.data?.adoption?.featureReleasedTimestamp,
  ).format("DD MMM, YYYY");

  const shouldShowAdoptionPercentage = Boolean(
    adoptionPercentage && adoptionPercentage > 0 && featureReleasedTimestamp,
  );

  if (!activeAppObject) {
    return null;
  }

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container
          insight={insight}
          id={response && animationsFinished ? "loaded" : ""}
          colSpan={2}
        >
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          />
          <Card.Body
            insight={insight}
            isLoading={false}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
          >
            <div>
              {shouldShowAdoptionPercentage && (
                <Flex
                  direction="column"
                  w="100%"
                  className="recharts-responsive-container"
                >
                  <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    bg="purple.50"
                    py={2}
                  >
                    <Text color="purple.600" px={1} fontSize="sm">
                      <Text as="span" fontWeight="bold">
                        {adoptionPercentage}% overall conversion rate{" "}
                      </Text>{" "}
                      since {featureReleasedTimestamp}
                    </Text>
                  </Flex>
                </Flex>
              )}
              <HStack
                spacing="6"
                p="6"
                divider={
                  <StackDivider borderWidth={1} borderColor="gray.100" />
                }
              >
                <Box width="full">
                  <TrackProgress
                    isLoading={isFetching}
                    metricText={getMetricText(
                      Track.ADOPTION,
                      trackData,
                      activeAppObject?.pluralName.toLowerCase(),
                    )}
                    track={Track.ADOPTION}
                    state={state}
                    unit={activeAppObject?.pluralName.toLowerCase()}
                    isCompanyLevel={!isUserReport}
                    onAnimationFinish={onAnimationFinish}
                  />
                </Box>
                <Box width="full">
                  <TrackProgress
                    isLoading={isFetching}
                    metricText={getMetricText(
                      Track.USAGE,
                      trackData,
                      activeAppObject?.pluralName.toLowerCase(),
                    )}
                    track={Track.USAGE}
                    state={state}
                    unit={activeAppObject?.pluralName.toLowerCase()}
                    isCompanyLevel={!isUserReport}
                    onAnimationFinish={onAnimationFinish}
                  />
                </Box>
              </HStack>
            </div>
          </Card.Body>
          <GoalReachedModal
            isFinished={isFinished}
            isFirstLoad={firstLoad}
            goals={goalChanges}
            isOpen={isOpen && report.reportMailSetting.isEnabled}
            onClose={onModalClose}
            unit={activeAppObject?.pluralName.toLowerCase()}
            isCompanyLevel={!isUserReport}
            state={state}
          />
        </Card.Container>
      )}
    </InsightCard>
  );
};

export default AchievementsContainer;
