import {
  Button,
  ButtonGroup,
  Divider,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { GROUP_FILTER, USER_TRAIT } from "core/constants/report-setup";
import CloseIcon from "core/design-system/components/Icon/Interface/CloseIcon";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetTraitsQuery } from "core/models/traits";

interface ITraitKeySelectionProps {
  isOpen: boolean;
  onOpen: () => void;
  isUserTrait: boolean;
  onRemoveFilter: () => void;
  onTraitSelected: (trait: string) => void;
}

export const TraitKeyPicker: React.FC<ITraitKeySelectionProps> = ({
  onRemoveFilter,
  isOpen,
  onOpen,
  onTraitSelected,
  isUserTrait,
}) => {
  const { id: appId } = useCurrentApp();
  const [searchTrait, setSearchTrait] = useState("");
  const { data: traits, isLoading } = useGetTraitsQuery({
    appId,
    filterType: isUserTrait ? USER_TRAIT : GROUP_FILTER,
  });
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  if (isLoading)
    return (
      <div className="flex items-center space-x-2">
        <div className="flex">
          <Spinner size="xs" color="gray.500" />
        </div>
        <div className="flex">
          <p className="text-xs text-gray-500">Loading traits</p>
        </div>
      </div>
    );

  if (!traits)
    return <div className="text-xs text-gray-500">No traits found</div>;

  return (
    <Popover initialFocusRef={initialFocusRef} isOpen={isOpen}>
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <ButtonGroup isAttached variant={"outline"}>
              <Button
                bg={isOpen ? "gray.50" : ""}
                color={isOpen ? "gray.500" : ""}
                _hover={{ bg: "gray.50", color: "gray.500" }}
                fontSize="sm"
                onClick={() => (isOpen ? onOpen() : onClose())}
              >
                Select a trait
              </Button>
              <Button borderLeft="none" onClick={() => onRemoveFilter()}>
                <CloseIcon h={2} w={2} />
              </Button>
            </ButtonGroup>
          </PopoverTrigger>
          <PopoverContent position="relative" left="60px" w="200px">
            <PopoverBody px={0} py={0} maxH="200px" overflowY="scroll">
              <Input
                ref={initialFocusRef}
                placeholder="Search a trait..."
                size="sm"
                borderRadius="0"
                border="none"
                value={searchTrait}
                onChange={(e: any) => setSearchTrait(e.target.value)}
                _focus={{
                  borderBottom: "1px solid",
                  borderColor: "purple.500",
                }}
                borderTopRadius={4}
              />
              <Divider />
              {traits?.length > 0 && (
                <>
                  <Text
                    color="gray.700"
                    px={3}
                    py={1}
                    fontSize="xs"
                    fontWeight="bold"
                  >
                    {(isUserTrait && "User traits") || "Company traits"}
                  </Text>
                  <>
                    {traits
                      .filter(({ trait }) => trait.includes(searchTrait))
                      .map(({ trait, valueCount }) => {
                        const hasTooManyValues = Number(valueCount) > 25;

                        return (
                          <Tooltip
                            hasArrow
                            placement="right"
                            label={
                              hasTooManyValues
                                ? "This trait has too many values"
                                : ""
                            }
                          >
                            <Text
                              cursor="pointer"
                              color={hasTooManyValues ? "gray.400" : "gray.700"}
                              px={3}
                              py={1}
                              _hover={{
                                bg: hasTooManyValues ? "white" : "gray.100",
                                color: hasTooManyValues ? "gray.400" : "black",
                              }}
                              fontSize="sm"
                              onClick={() => {
                                if (hasTooManyValues) return;
                                onTraitSelected(trait);
                              }}
                            >
                              {trait}
                            </Text>
                          </Tooltip>
                        );
                      })}
                  </>
                </>
              )}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
