import { Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import React from "react";

import { IAlertWithAlertable } from "core/models/alerts";
import { Header } from "core/modules/reports/setup/Setup/Header";
import { EventAlertEditor } from "modules/Events/List/EventAlertEditor";

interface EventAlertEditorDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  alert: IAlertWithAlertable;
}

export const SingleEventAlertDrawer: React.FC<EventAlertEditorDrawerProps> = ({
  isOpen,
  onClose,
  alert,
}) => {
  return (
    <Drawer
      trapFocus={false}
      size="xl"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <div className="flex h-full flex-col overflow-y-auto">
          <div className="flex flex-col justify-start">
            <Header
              title={
                <p>
                  Slack alert for{" "}
                  <span className="font-semibold text-purple-500">
                    {alert.alertable?.name}
                  </span>
                </p>
              }
              howToSetupText="Learn more"
              howToSetupLink="https://help.june.so/en/articles/5538197-how-to-use-the-event-notification-for-slack"
              useCases
            />
          </div>
          <div className="mx-8">
            <EventAlertEditor
              key={`${alert.state}-${JSON.stringify(alert.setup)}`}
              eventId={alert.alertableId}
              alert={alert}
              onClose={onClose}
            />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
