import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import { useInsightTitle } from "core/hooks/useInsightTitle";
import { IReport } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

interface ITitleProps {
  showReportTitle: boolean;
  insight: IInsightSection;
  report?: IReport;
}

export const Title: React.FC<ITitleProps> = ({
  showReportTitle,
  insight,
  report,
}) => {
  const insightTitle = useInsightTitle({ title: insight.title });

  return (
    <>
      {showReportTitle && report ? (
        <Flex direction="column">
          <Flex>
            <Text>{insightTitle}</Text>
          </Flex>
          <Flex>
            <Text fontSize="sm" color="gray.600">
              {report?.name}
            </Text>
          </Flex>
        </Flex>
      ) : (
        insightTitle
      )}
    </>
  );
};
