import { Button, MenuItem, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useState } from "react";

import { PaywallPopover } from "core/components/Paywall/Popover";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import { SolidChevronDown } from "core/design-system/components/Icon/Custom/SolidChevronDown";
import { useAppObjects } from "core/hooks/useAppObjects";
import { IUpdateInsightPayload } from "core/hooks/useEditViewInsight";
import { useGetAudiencesQuery } from "core/models/audiences";
import { IViewInsight } from "core/models/viewInsights";
import { Plan } from "core/types/App";

export const SetupContainer: React.FC<{
  viewInsight: IViewInsight;
  onUpdateInsight: (payload: IUpdateInsightPayload) => Promise<void>;
}> = ({ viewInsight, onUpdateInsight }) => {
  const [searchValue, setSearchValue] = useState("");
  const { data } = useGetAudiencesQuery({
    appId: viewInsight.appId,
    page: 1,
    query: searchValue,
  });
  const audiences = data?.audiences || [];
  const { appObjects } = useAppObjects();

  return (
    <div className="relative flex h-full w-full flex-grow flex-col">
      <p className="text-sm font-medium ">Audience</p>
      <Popover className="relative">
        <PopoverButton className="flex w-full justify-between">
          <Button textAlign="left" mt={2} w="full" bg="gray.50" border="none">
            <div className="flex w-full justify-between">
              {viewInsight.audience?.name}
              <SolidChevronDown className="h-4 w-4 text-gray-900" />
            </div>
          </Button>
        </PopoverButton>
        <PopoverPanel className="flex flex-col">
          {({ close }) => (
            <>
              <Lifecycle onUnmount={() => setSearchValue("")} />
              <Command>
                <CommandInput
                  className="mb-1"
                  placeholder="Search..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />

                <CommandList>
                  {audiences.map((audience) => (
                    <CommandOption
                      key={audience.id}
                      value={() => {
                        close();
                        onUpdateInsight({
                          audienceId: audience.id,
                        });
                      }}
                    >
                      {audience.name}
                    </CommandOption>
                  ))}
                </CommandList>
              </Command>
            </>
          )}
        </PopoverPanel>
      </Popover>
      <div className="mt-4">
        <p className="mb-2 text-sm font-medium">Object</p>
        <Menu matchWidth>
          <PaywallPopover
            feature="company level metrics"
            redirect="people"
            plan={Plan.Growth}
          >
            <MenuButton
              textAlign="left"
              w="full"
              as={Button}
              rightIcon={<SolidChevronDown className="h-4 w-4 text-gray-900" />}
              bg="gray.50"
              border="none"
              textTransform="capitalize"
            >
              <div className="flex items-center gap-1">
                {viewInsight?.appObject?.pluralName}
              </div>
            </MenuButton>
          </PaywallPopover>
          <MenuList>
            {appObjects.map((appObject) => (
              <MenuItem
                textTransform="capitalize"
                onClick={async () =>
                  await onUpdateInsight({
                    ...viewInsight,
                    objectType: appObject.objectType,
                  })
                }
              >
                <div className="flex items-center gap-1">
                  {appObject.pluralName}
                </div>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};
