import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";

import { IDataMapping, ITempDataMapping } from "core/hooks/useDataMappings";
import { Integration, IProperty } from "core/models/dataMappings";
import { IAppObject } from "core/types/AppObject";
import { SelectDestination } from "modules/Integrations/SelectDestination";
import { CreateTraitModal } from "modules/Settings/Crm/SyncSettings/CreateTraitModal";

interface IReverseMappingRowProps {
  dataMapping: IDataMapping | ITempDataMapping;
  currentProperties: IProperty[];
  properties: IProperty[];
  page: number;
  search: string;
  hasMore: boolean;
  setSearch: (query: string) => void;
  setPage: (page: number) => void;
  dataMappings: IDataMapping[];
  integration: Integration;
  appObject: IAppObject;
  isLoading: boolean;
}

export const ReverseMappingRow: React.FC<IReverseMappingRowProps> = ({
  dataMapping,
  currentProperties,
  properties,
  hasMore,
  page,
  search,
  setPage,
  setSearch,
  dataMappings,
  integration,
  appObject,
  isLoading,
}) => {
  function findExternalProperty() {
    return (
      dataMapping &&
      properties.find((hp) => hp.name === dataMapping.destination)
    );
  }

  const usedExternalProperties = dataMappings.map((dm) => dm.destination);
  const [externalProperty, setExternalProperty] = useState<
    IProperty | undefined
  >(findExternalProperty());

  useEffect(() => {
    if (!externalProperty && currentProperties.length > 0) {
      setExternalProperty(findExternalProperty());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProperties.length, externalProperty]);

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="w-[200px]">
        <CreateTraitModal
          appObject={appObject}
          integration={integration}
          dataMapping={dataMapping}
        />
      </div>
      <div>
        <ArrowLeftIcon className="h-4" />
      </div>
      <div className="flex w-[200px] max-w-[200px] flex-row justify-end">
        <SelectDestination
          setExternalProperty={setExternalProperty}
          dataMapping={dataMapping}
          externalProperty={externalProperty}
          deleteTempMapping={() => {}}
          setSearchValue={setSearch}
          searchValue={search}
          properties={currentProperties}
          setPage={setPage}
          page={page}
          hasMore={hasMore}
          usedExternalProperties={usedExternalProperties}
          integration={integration}
          appObject={appObject}
          isLoading={isLoading}
          ignoreTypeAndProtectionChecks={true}
        />
      </div>
    </div>
  );
};
