import { Button } from "@chakra-ui/button";
import { Box, Flex, Text } from "@chakra-ui/layout";

import { useNavigation } from "core/hooks/useNavigation";

export default function InsightEmptyState({ appId = null }) {
  const { setIsNewReportModalOpen } = useNavigation();
  return (
    <Box p={8} borderWidth={5} borderColor={"gray-500"} borderRadius="xl" a>
      <Flex direction="column" alignItems="center" justifyContent="center">
        <Text color="gray.600" fontSize="sm" mb={4}>
          You have no compatible insights available.
        </Text>
        {appId !== null && (
          <Button
            colorScheme="purple"
            onClick={() => setIsNewReportModalOpen(true)}
          >
            Create a report
          </Button>
        )}
      </Flex>
    </Box>
  );
}
