import { Flex } from "@chakra-ui/react";
import React from "react";

import { CurrentPeriodLineChart } from "core/modules/reports/report-types/ActiveUsers/CurrentPeriodLineChart";
import { splitCurrentPeriodAndFormatData } from "core/modules/reports/report-types/ActiveUsers/lineChartHelpers";
import { ISticknessRatioData } from "core/types/LineChart";

const DauMau = ({
  data,
  previewMode,
  sharingMode,
}: {
  data: ISticknessRatioData;
  previewMode: boolean;
  sharingMode: boolean;
}) => {
  const [startOfMonth, formattedData] = splitCurrentPeriodAndFormatData(
    data.data,
  );

  return (
    <Flex direction="column" bg="white" borderRadius="lg">
      <Flex
        borderTopRightRadius="lg"
        borderTopLeftRadius="lg"
        direction="column"
        justify="center"
      >
        {data?.data && (
          <CurrentPeriodLineChart
            unit="% DAU / MAU"
            graphId="DAU / MAU"
            data={formattedData}
            currentPeriodReferenceLineValue={startOfMonth}
            currentPeriodTooltipText="The ratio is incomplete because the MAU for the current month changes every day."
            withDrawer={false}
            previewMode={previewMode}
            sharingMode={sharingMode}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default DauMau;
