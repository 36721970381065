import { Label } from "@/Components/ui/label";
import { IColorPalette } from "@/core/design-system/constants/theme/colors";
import { IUpdateInsightPayload } from "@/core/hooks/useEditViewInsight";
import { IViewInsight } from "@/core/models/viewInsights";
import { IAppObject } from "@/core/types/AppObject";
import { IReport } from "@/core/types/Report";
import {
  Aggregation,
  GraphType,
  Measure,
  Size,
} from "@/core/types/ViewInsight";
import { AggregationSelector } from "@/modules/Dashboard/InsightBuilder/SidePanel/AggregationSelector";
import { GraphTypeSelector } from "@/modules/Dashboard/InsightBuilder/SidePanel/GraphTypeSelector";
import { InsightSizeSelector } from "@/modules/Dashboard/InsightBuilder/SidePanel/InsightSizeSelector";
import { ColorPicker } from "@/modules/Labels/ColorPicker";

export const DesignContainer: React.FC<{
  viewInsight: IViewInsight;
  report?: IReport;
  graphType: GraphType;
  onUpdateInsight: (payload: IUpdateInsightPayload) => void;
  colorPalette: IColorPalette;
  colors: IColorPalette[];
  size: Size;
  measure: Measure;
  appObject: IAppObject;
  groupId?: string;
}> = ({
  viewInsight,
  report,
  graphType,
  onUpdateInsight,
  colorPalette,
  colors,
  size,
  measure,
  appObject,
  groupId,
}) => {
  function isColorPickerDisabled() {
    return [
      GraphType.Scatter,
      GraphType.StackedLine,
      GraphType.StackedBar,
      GraphType.Table,
      GraphType.ContributionGraph,
    ].includes(graphType);
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2 border-b border-gray-200 p-5">
        <Label>Type</Label>
        <GraphTypeSelector
          graphType={graphType}
          onSelect={(graphType) => onUpdateInsight({ graphType })}
          measure={measure}
          appObject={appObject}
          groupId={groupId}
        />
      </div>
      {viewInsight.insight.hasAggregations && (
        <div className="flex flex-col gap-2 border-b border-gray-200 px-5 pb-4 pt-2">
          <Label>Calculation</Label>
          <AggregationSelector
            aggregation={viewInsight.aggregation}
            onSelect={(aggregation: Aggregation) =>
              onUpdateInsight({ aggregation })
            }
          />
        </div>
      )}
      <div className="flex flex-col gap-2 border-b border-gray-200 px-5 pb-4 pt-2">
        <Label>Size</Label>
        <InsightSizeSelector
          size={size}
          onSelect={(size: Size) => onUpdateInsight({ size })}
        />
      </div>
      <div className="flex flex-col gap-2 px-5 pt-2">
        <Label>Colour</Label>
        <ColorPicker
          color={colorPalette}
          setColor={(color) => onUpdateInsight({ colorPalette: color })}
          colors={colors}
          isDisabled={isColorPickerDisabled()}
          shade={300}
          disabledTooltip="This graph type does not support colors"
          variant="secondary"
          buttonProps={{
            bg: "#f5f5f5",
            rounded: "lg",
            height: "30px",
            w: "full",
          }}
        />
      </div>
    </div>
  );
};
