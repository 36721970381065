import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";

import { ITraitWithConfig } from "core/types/Trait";

export const Overflow: React.FC<{
  trait: ITraitWithConfig;
  onDelete?: () => void;
}> = ({ trait, onDelete, ...styleProps }) => {
  const onDeleteTrait = async () => {
    if (!trait.id) return;
    onDelete?.();
  };

  return (
    <>
      <Menu>
        <MenuButton as={Button} variant="ghost" ml={6} {...styleProps}>
          <EllipsisVerticalIcon className="h-4" />
        </MenuButton>
        <MenuList boxShadow="md" minWidth="140px">
          <MenuItem onClick={onDeleteTrait}>
            <Flex justify="flex-start" alignItems="center" gap={2}>
              <TrashIcon width={15} height={15} color="black" />
              <Text fontWeight="medium" fontSize="sm" color="black">
                Delete
              </Text>
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
