import { GrantAdditionalScopesCard } from "@/modules/Settings/Integrations/Hubspot/GrantAdditionalScopesCard";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Integration } from "core/models/dataMappings";
import { CrmSyncSettingsRow } from "modules/Settings/Crm/SyncSettings/Row";
import { CrmSyncSettingsTable } from "modules/Settings/Crm/SyncSettings/Table";

export const Settings: React.FC = () => {
  const { userAppObject, companyAppObject, groupAppObject } = useAppObjects();

  return (
    <div className="flex flex-col gap-4">
      <GrantAdditionalScopesCard />
      <CrmSyncSettingsTable>
        {userAppObject && (
          <CrmSyncSettingsRow
            appObject={userAppObject}
            integration={Integration.Hubspot}
          />
        )}
        {companyAppObject && (
          <CrmSyncSettingsRow
            appObject={companyAppObject}
            integration={Integration.Hubspot}
          />
        )}
        {groupAppObject && (
          <CrmSyncSettingsRow
            appObject={groupAppObject}
            integration={Integration.Hubspot}
          />
        )}
      </CrmSyncSettingsTable>
    </div>
  );
};
