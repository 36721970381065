import humps from "humps";

import axios from "core/initializers/axios";
import { AppThunk } from "core/initializers/store";
import { slice } from "core/models/group/index";
import { IGroupEvents } from "core/types/Group";
import { IFilterEvents } from "modules/Contacts/Contact/ContactActivity";

interface IGroupEventsParams {
  appId: number | string;
  id: string;
  filterEvents?: IFilterEvents;
}

export const fetchOneGroupEvents =
  ({ appId, id, filterEvents }: IGroupEventsParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.fetchingGroupEvents({ id }));
      const { data } = await axios.get<IGroupEvents>(
        `/groups/${id}/most_triggered_events`,
        {
          params: humps.decamelizeKeys({
            appId,
            filterEvents,
          }),
        },
      );

      dispatch(slice.actions.upsertOneGroupEvents({ id, data }));
    } catch (e: any) {
      const title = "Failed to fetch group events";
      const description = e?.response?.data?.error;
      const error = `${title}: ${description}`;
      dispatch(slice.actions.fetchGroupEventsFailed({ id, error }));
    }
  };
