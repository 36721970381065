import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { useAppObjects } from "core/hooks/useAppObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { updateApp } from "core/models/app";
import { IAppObject } from "core/types/AppObject";

export const DefaultObjectSettings: React.FC = () => {
  const app = useCurrentApp();
  const { userAppObject, appObjects } = useAppObjects();

  const [defaultObject, setDefaultObject] = useState<IAppObject | null>(
    app.defaultObject || null,
  );

  const isDefaultObjectDirty = defaultObject?.id !== app.defaultObject?.id;
  const dispatch = useDispatch();
  const updateDefaultObject = (defaultObject: IAppObject) => {
    dispatch(updateApp({ appId: app.id, defaultObjectId: defaultObject.id }));
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        <p className="mb-2 text-sm font-semibold">
          Default object for People list
        </p>
        <p className="text-sm">
          The default object selected for the People list. This object will be
          used to display the People list.
        </p>
      </div>
      <div className="flex w-full justify-between">
        <Menu>
          <MenuButton
            bg="gray.50"
            border="none"
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            {defaultObject?.pluralName
              ? defaultObject?.pluralName
              : userAppObject?.pluralName}
          </MenuButton>
          <MenuList>
            {appObjects?.map((appObject) => (
              <MenuItem
                key={appObject.id}
                fontSize="sm"
                isDisabled={appObject.isEnabled === false}
                onClick={() => setDefaultObject(appObject)}
              >
                {appObject.pluralName}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Tooltip
          label={
            isDefaultObjectDirty
              ? "Update the default object"
              : "Default object has not changed"
          }
          hasArrow
        >
          <Button
            colorScheme="purple"
            variant="ghost"
            isDisabled={!isDefaultObjectDirty || !defaultObject}
            onClick={() => {
              if (defaultObject) {
                updateDefaultObject(defaultObject);
              }
            }}
          >
            Save
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
