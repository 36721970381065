import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect } from "react";

import { NO_REPORTS } from "@/core/constants/features";
import useFlag from "@/core/hooks/useFlag";
import { ReportInsight } from "core/components/Reportinsight";
import { getReportConfig } from "core/helpers/getReportConfig";
import { useEditDashboard } from "core/hooks/useEditDashboard";
import { useNavigateToReport } from "core/hooks/useNavigateToReport";
import { useReportSetup } from "core/hooks/useReportSetup";
import { useReportTimerange } from "core/hooks/useReportTimerange";
import {
  IViewInsight,
  useUpdateViewInsightMutation,
} from "core/models/viewInsights";
import { TimerangePicker } from "core/modules/reports/Timerange/TimerangePicker";
import { IReport } from "core/types/Report";
import { ITimerangeOption } from "core/types/TimerangeOption";
import { GraphType } from "core/types/ViewInsight";
import { InsightsContainer } from "modules/Report/InsightsContainer";
import { getInsightConfig } from "modules/ViewInsight/ViewInsightContainer";

export const FullInsightModal: React.FC<{
  viewInsight: IViewInsight;
  isOpen: boolean;
  onClose: () => void;
  isBlurred?: boolean;
  groupId?: string;
}> = ({ viewInsight, isOpen, onClose, isBlurred, groupId }) => {
  const templateConfig = getReportConfig(viewInsight.report.reportType)!;
  const insightConfig = getInsightConfig(
    viewInsight.insight.reportType,
    viewInsight.insightId as number,
  );
  const noReports = useFlag(NO_REPORTS);
  const { navigateToReport } = useNavigateToReport({ viewInsight });
  const { hasDateRangeSection, dateRangeOptions, timerangeTooltipText } =
    templateConfig;
  const { currentReport, loadReportSetup } = useReportSetup();
  const { onCloseFullModeViewInsight, onUpdateViewInsight } =
    useEditDashboard();
  const [updateViewInsight] = useUpdateViewInsightMutation();

  function onUpdateTimerange(timerange: ITimerangeOption) {
    updateViewInsight({
      ...viewInsight,
      timerangeValue: timerange.timerangeValue,
    });
    onUpdateViewInsight({
      ...viewInsight,
      timerangeValue: timerange.timerangeValue || 0,
    });
  }

  const {
    label,
    timerangeType,
    relativeTimerange,
    customTimerange,
    setCustomTimerange,
  } = useReportTimerange({
    type: viewInsight.timerangeType,
    value: viewInsight.timerangeValue,
    startTimestamp: viewInsight.timerangeStartTimestamp,
    endTimestamp: viewInsight.timerangeEndTimestamp,
  });

  useEffect(() => {
    loadReportSetup({
      report: viewInsight.report,
      setup: templateConfig.setup,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewInsight]);

  return (
    <Modal
      scrollBehavior="inside"
      isCentered
      size="6xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <div className="flex w-[95%] items-center justify-between">
            <div>
              <p>{viewInsight.title || viewInsight.report.name}</p>
              <p className="text-sm font-normal capitalize text-gray-600">
                {insightConfig?.title.replace(
                  "{APP_OBJECT_PLURAL_NAME}",
                  viewInsight.appObject?.pluralName || "",
                )}
              </p>
            </div>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          minH="60vh"
          pb={Boolean(viewInsight.query) ? 10 : 0}
          className="flex flex-col"
          h="full"
        >
          <div
            className={
              "flex flex-1 flex-col justify-center rounded-lg bg-gray-100 px-10 pb-10 pt-5"
            }
          >
            <div className="flex justify-end">
              {hasDateRangeSection && (
                <div className="flex items-center">
                  <TimerangePicker
                    label={label}
                    options={dateRangeOptions}
                    timerangeType={timerangeType as number}
                    relativeTimerange={relativeTimerange as ITimerangeOption}
                    customTimerange={customTimerange}
                    setCustomTimerange={setCustomTimerange}
                    saveTimerange={onUpdateTimerange}
                    tooltipText={timerangeTooltipText}
                  />
                </div>
              )}
            </div>
            {!viewInsight.query && insightConfig && currentReport.id && (
              <InsightsContainer
                viewInsight={viewInsight}
                report={
                  {
                    ...currentReport,
                    audience: viewInsight.useViewAudience
                      ? viewInsight.view.audience
                      : currentReport.audience,
                  } as IReport
                }
                templateConfig={templateConfig}
                insightConfig={insightConfig}
                appObject={viewInsight.appObject}
              />
            )}
            {viewInsight.query ? (
              <ReportInsight
                viewInsight={viewInsight}
                query={viewInsight.query}
              />
            ) : null}
          </div>
        </ModalBody>

        {!Boolean(viewInsight.query) && (
          <ModalFooter>
            <Button
              colorScheme="purple"
              variant="ghost"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
            {!noReports && (
              <Button
                isDisabled={
                  viewInsight.graphType === GraphType.ContributionGraph
                }
                onClick={() => {
                  onCloseFullModeViewInsight();
                  navigateToReport({
                    groupId: groupId,
                    skipNavigate: isBlurred,
                  });
                }}
                colorScheme="purple"
              >
                View full report
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
