import { Button } from "@chakra-ui/react";
import { PresentationChartBarIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

import {
  EmptyStateContainer,
  EmptyStateDescription,
  EmptyStateIconContainer,
  EmptyStateTitle,
} from "core/components/Card";
import { EventIcon } from "core/design-system/components/Icon/Custom/EventIcon";
import { useCurrentApp } from "core/hooks/useCurrentApp";

export const AlertsEmptyState: React.FC = () => {
  const { id: appId } = useCurrentApp();

  return (
    <div className="max-w-full">
      <h2 className="text-base font-semibold leading-6 text-gray-900">
        Create your first alert
      </h2>
      <div className="mt-6 flex justify-center gap-4 rounded-lg border border-gray-200 p-8">
        <div className="flex w-full max-w-md justify-center">
          <div className="max-w-[300px]">
            <EmptyStateContainer className="md:col-span-1">
              <EmptyStateIconContainer>
                <EventIcon />
              </EmptyStateIconContainer>
              <EmptyStateTitle>Event notification</EmptyStateTitle>
              <EmptyStateDescription>
                Instantly get notified when something important happens in your
                product.
              </EmptyStateDescription>
              <Button mt={4} as={Link} to={`/a/${appId}/events`}>
                Go to events
              </Button>
            </EmptyStateContainer>
          </div>
        </div>
        <div className="hidden w-[1px] border-r border-gray-200 md:block"></div>
        <div className="flex w-full max-w-md justify-center">
          <div className="max-w-[300px]">
            <EmptyStateContainer className="md:col-span-1">
              <EmptyStateIconContainer>
                <PresentationChartBarIcon className="h-5" />
              </EmptyStateIconContainer>
              <EmptyStateTitle>Report digest</EmptyStateTitle>
              <EmptyStateDescription>
                Keep your team up to date with daily, weekly or monthly updates
                on your product.
              </EmptyStateDescription>
              <Button mt={4} as={Link} to={`/a/${appId}/reports`}>
                Go to reports
              </Button>
            </EmptyStateContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
