import { Box, Progress } from "@chakra-ui/react";

import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from "@/Components/ui/tooltip";
import { cn } from "@/lib/utils";
import { DataPoint } from "@/modules/ViewInsight/ViewInsightComponent";
import { FunnelTooltip } from "core/design-system/charts/FunnelTooltip";
import { IColorPalette } from "core/design-system/constants/theme/colors";
import { thousandsToK } from "core/helpers/thousands";
import { IBarItem } from "core/types/BarChart";
import { Size } from "core/types/ViewInsight";

export interface IHomeFunnelStep {
  name: string;
  convertedCount: number;
  percentage: number;
  isPlaceholder?: boolean;
}

export const FunnelStep: React.FC<{
  item: IBarItem;
  color?: IColorPalette;
  size?: Size;
  onClick?: (dataPoint: DataPoint) => void;
}> = ({ item, color, size = Size.Small, onClick }) => {
  const convertedCount = thousandsToK(item.conversion.count);
  const percentage = `${Math.round(item.conversion.percentage || 0)}%`;

  return (
    <Box data-testid="milestone" w="100%">
      <div className="flex w-full flex-col rounded-md border border-white py-1 hover:cursor-pointer hover:border hover:border-gray-200">
        <div
          className="flex w-full items-center justify-between gap-x-2 px-1"
          onClick={() => onClick?.({ ...item })}
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <p
                className="w-1/2 max-w-[180px] truncate text-xs text-gray-600"
                data-testid={`milestone-name-${item.label}`}
              >
                {item.label}
              </p>
            </TooltipTrigger>
            <TooltipContent>{item.label}</TooltipContent>
          </Tooltip>
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <Progress
                borderRadius="3px"
                size="md"
                w={"full"}
                colorScheme={color?.scheme || "purple"}
                value={Number(item.conversion.percentage)}
                data-testid={`milestone-convertedCount-${convertedCount}`}
              />
            </TooltipTrigger>
            <TooltipContent>
              <FunnelTooltip item={item} index={item.index} />
            </TooltipContent>
          </Tooltip>
          <p
            className={cn(
              "w-[50px] text-right font-mono text-xs font-medium",
              color?.scheme && `text-${color?.scheme}-500`,
            )}
            data-testid={`milestone-percentage-${percentage}`}
          >
            {percentage}
          </p>
        </div>
      </div>
    </Box>
  );
};
