import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";

import { AuthenticatedApp } from "@/AuthenticatedApp";
import { UnauthenticatedApp } from "@/UnauthenticatedApp";
import { Loader } from "Components/Loader";
import { UpdateNotification } from "Components/UpdateNotification";
import { isDev } from "constants/environment";
import juneTheme from "core/design-system/constants/theme";
import { useUserAuth } from "core/hooks/useUserAuth";
import { VerifyEmail } from "modules/VerifyEmail";

import "focus-visible/dist/focus-visible";

export const App = () => {
  const { currentUser, isLoading } = useUserAuth();

  return (
    <ChakraProvider theme={juneTheme}>
      {!isDev && <UpdateNotification />}
      <BrowserRouter>
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {currentUser ? (
                <>
                  {currentUser.confirmed ? (
                    <AuthenticatedApp user={currentUser} />
                  ) : (
                    <VerifyEmail email={currentUser.email} />
                  )}
                </>
              ) : (
                <UnauthenticatedApp />
              )}
            </>
          )}
        </>
      </BrowserRouter>
    </ChakraProvider>
  );
};
