import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useToast } from "core/hooks/useToast";
import { useUserAuth } from "core/hooks/useUserAuth";
import { deleteApp, selector as appSelector } from "core/models/app";

interface IAppDeletionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AppDeletionModal: React.FC<IAppDeletionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const appId = useParams<{ appId: string }>() as { appId: string };
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const dispatch = useDispatch();
  const toast = useToast();
  const { logout } = useUserAuth();

  const { isDeleting, errors, isDeleted } = useSelector(appSelector);

  useEffect(() => {
    if (errors.deleting) {
      toast({
        title: "You cannot delete a workspace that is receiving data",
        description:
          "Please disconnect your Segment account or remove the June SDK from your app before deleting this workspace",
        status: "error",
        duration: 5000,
      });
    }
  }, [errors.deleting, toast]);

  const onDeleteApp = async () => {
    await dispatch(deleteApp(appId));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you absolutely sure?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isDeleted ? (
            <Flex justify={"center"} align={"center"}>
              <Stack spacing={4} align="center">
                <CheckCircleIcon w={16} h={16} color="green.500" />
                <Text textAlign={"center"} fontWeight="bold">
                  Deletion request received
                </Text>
                <Text textAlign={"center"}>
                  You will be notified via email when <br /> your workspace is
                  deleted.
                </Text>
                <Flex>
                  <Button
                    onClick={() => {
                      logout();
                    }}
                  >
                    Logout
                  </Button>
                </Flex>
              </Stack>
            </Flex>
          ) : (
            <Stack gap={2}>
              <Flex
                direction={"column"}
                bg="yellow.100"
                p={4}
                borderRadius="lg"
                mb={4}
              >
                <Text>
                  Unexpected bad things will happen if you don’t read this!
                </Text>
              </Flex>
              <Text>
                Are you sure you want to delete all the data collected by June
                for this workspace?
              </Text>
              <Text>
                Before deleting your account and all associated data, we need
                you to make sure you have disconnected your product-usage data
                from June. If you have not done so, please disconnect your
                Segment account or remove the June SDK from your app.
              </Text>
              <Text>
                If you're still sending data to June about your users, we will
                not be able to delete your account. Before deleting your account
                we want to make sure you won't keep sending us data about your
                customers after your account is deleted.
              </Text>
              <Text>
                This action{" "}
                <Text as="span" fontWeight={"bold"}>
                  cannot
                </Text>{" "}
                be undone. This will permanently delete all the data collected
                by June for this workspace. This includes all the data collected
                by June for your users, events, reports, audiences and every
                user in your workspace that does not belong to any other
                workspace.
              </Text>
              <Text py={2}>
                Please type{" "}
                <Text as="span" fontWeight={"bold"}>
                  delete workspace
                </Text>{" "}
                to confirm.
              </Text>
              <Input
                onChange={(e) => {
                  e.target.value === "delete workspace"
                    ? setIsDeleteDisabled(false)
                    : setIsDeleteDisabled(true);
                }}
                placeholder={"delete workspace"}
              />
            </Stack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isDeleting}
            isDisabled={isDeleteDisabled || isDeleted}
            colorScheme="red"
            onClick={onDeleteApp}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
