import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import ReactMarkdown from "react-markdown";

import Message from "core/components/Slack/Message";
import { SlackIconEnabled } from "core/design-system/components/Icon/Interface/SlackIconEnabled";

const USE_CASES = [
  { useCase: "acquisition", message: "joe@example.com **signed up**" },
  { useCase: "success", message: "susan@example.com **converted to paying**" },
  {
    useCase: "success",
    message: "john@example.com **showed purchase intent**",
  },
  { useCase: "success", message: "bob@example.com **started a free trial**" },
  { useCase: "success", message: "megan@example.com **invited a teammate**" },
  {
    useCase: "product",
    message: "david@example.com **used your core feature**",
  },
];

const EventAlertUseCaseModal: React.FC<
  Pick<UseDisclosureReturn, "isOpen" | "onClose">
> = ({ isOpen, onClose }) => {
  return (
    <Modal
      size="xl"
      closeOnEsc
      closeOnOverlayClick
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="lg" fontWeight="semibold" mb={2}>
            <SlackIconEnabled mr={2} />
            Slack event alert use cases
          </Text>
          <Text fontWeight="normal" color="gray.600" fontSize="md">
            Here are some examples of useful Slack event alerts that your{" "}
            <Text as="span" fontWeight="semibold" color="gray.700">
              product, sales, marketing
            </Text>{" "}
            or{" "}
            <Text as="span" fontWeight="semibold" color="gray.700">
              success teams
            </Text>{" "}
            can use
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={10}>
          <Flex direction="column" bg="gray.100" p={4}>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
            >
              {USE_CASES.map((useCase, index) => (
                <motion.li
                  style={{ listStyleType: "none" }}
                  variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 },
                  }}
                  transition={{ delay: index }}
                >
                  <Message
                    mt={2}
                    body={<ReactMarkdown children={useCase.message} />}
                  />
                </motion.li>
              ))}
            </motion.div>
            <Flex></Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default EventAlertUseCaseModal;
