import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  IAlertDeliveryChannelEnum,
  IAlertStateEnum,
} from "core/constants/alert";
import { useCompanyView } from "core/hooks/useCompanyView";
import { useToast } from "core/hooks/useToast";
import {
  useCreateAlertMutation,
  useGetAlertQuery,
  useUpdateAlertMutation,
} from "core/models/alerts";
import { AppObjectType } from "core/models/appObjects";
import { IAlert, ICreateAlert, IUpdateAlert } from "core/types/Alert";

export enum AlertButtonStateEnum {
  Save,
  Enable,
  Update,
  Pause,
}

export const useViewAlert = ({
  groupId,
  appObjectType,
}: {
  groupId: string;
  appObjectType: AppObjectType;
}) => {
  const toast = useToast();
  const { appId } = useParams<{
    appId: string;
  }>() as {
    appId: string;
  };
  const { view } = useCompanyView();
  const { data: existingAlert } = useGetAlertQuery({
    appId: Number(appId),
    viewId: view?.id,
    groupId,
    objectType: appObjectType,
  });

  const [createAlert, { isLoading: isCreateLoading }] =
    useCreateAlertMutation();
  const [updateAlert, { isLoading: isUpdateLoading }] =
    useUpdateAlertMutation();

  const [state, setState] = useState(AlertButtonStateEnum.Save);
  const [alert, setAlert] = useState<IAlert>({
    id: 0,
    appId: Number(appId),
    viewId: view?.id,
    groupId,
    objectType: appObjectType,
    setup: {},
    state: IAlertStateEnum.Disabled,
    created: false,
  } as unknown as IAlert);
  const [hasChanges, setHasChanges] = useState(false);
  const isValidEmailAlert = Boolean(
    alert?.deliveryChannel === IAlertDeliveryChannelEnum.Email &&
      alert?.setup?.emails,
  );
  const isValidSlackAlert = Boolean(
    alert?.deliveryChannel === IAlertDeliveryChannelEnum.Slack &&
      alert?.slackChannelId,
  );
  const isValidAlert = Boolean(
    typeof alert?.deliveryChannel !== "undefined" &&
      typeof alert?.frequency !== "undefined" &&
      (isValidEmailAlert || isValidSlackAlert),
  );

  useEffect(() => {
    if (existingAlert?.created) setAlert(existingAlert);
  }, [existingAlert]);

  useEffect(() => {
    setAlert((alert) => ({
      ...alert,
      viewId: view?.id,
    }));
  }, [view]);

  useEffect(() => {
    setHasChanges(!isEqual(existingAlert, alert));
  }, [existingAlert, alert]);

  useEffect(() => {
    switch (true) {
      case alert?.created === false:
        return setState(AlertButtonStateEnum.Save); // Alert does not exist
      case alert?.state === IAlertStateEnum.Disabled:
        return setState(AlertButtonStateEnum.Enable); // Alert is paused
      case alert?.created === true && hasChanges:
        return setState(AlertButtonStateEnum.Update); // Alert is created and some values have changed
      case alert?.created === true &&
        alert?.state === IAlertStateEnum.Enabled &&
        !hasChanges:
        return setState(AlertButtonStateEnum.Pause); // Alert is created, enabled, and no values have changed
      default:
        return setState(AlertButtonStateEnum.Save);
    }
  }, [alert, hasChanges]);

  const onCreate = () => {
    if (!isValidAlert) {
      return toast({
        title: "Select alert destination",
        description: "You need to configure every step to enable the alert.",
        status: "error",
      });
    }

    createAlert({
      ...alert,
      state: IAlertStateEnum.Enabled,
    } as unknown as ICreateAlert)
      .unwrap()
      .then(() => {
        toast({
          title: "Alert created",
          description: "You will now receive this alert.",
        });
      });
  };

  const onUpdate = () => {
    updateAlert({
      id: Number(alert?.id),
      alert: alert as unknown as IUpdateAlert,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Saved successfully",
          description: "Your alert was updated successfully.",
        });
      });
  };

  const onPause = () => {
    updateAlert({
      id: Number(alert?.id),
      alert: {
        ...alert,
        state: IAlertStateEnum.Disabled,
      } as unknown as IUpdateAlert,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Saved successfully",
          description: "Your alert was updated successfully.",
        });
      });
  };

  const onEnable = () => {
    updateAlert({
      id: Number(alert?.id),
      alert: {
        ...alert,
        state: IAlertStateEnum.Enabled,
      } as unknown as IUpdateAlert,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Saved successfully",
          description: "Your alert was updated successfully.",
        });
      });
  };

  return {
    alert,
    setAlert,
    hasChanges,
    state,
    isValidAlert,
    onCreate,
    onUpdate,
    onPause,
    onEnable,
    isEnabled: alert?.state === IAlertStateEnum.Enabled,
    isCreateLoading: isCreateLoading,
    isUpdateLoading: isUpdateLoading,
  };
};
