import { Flex } from "@chakra-ui/react";

import { IEvent } from "core/types/Event";
import EventFeedList from "modules/Feed/List/EventFeedList";

export const Activity: React.FC<{ event: IEvent }> = ({ event }) => {
  return (
    <Flex w="100%" direction="column">
      <EventFeedList
        eventName={event.name}
        showSearch={false}
        showFilters={false}
        showEventType={false}
        showEventName={false}
      />
    </Flex>
  );
};
