import { Tooltip } from "@chakra-ui/react";

import { CommandOption } from "core/design-system/components/Command";
import { Integration, IProperty } from "core/models/dataMappings";

type WritableKeyType = "reader" | "writer";

interface NestedPropertyParams {
  key: string;
  type: WritableKeyType;
}

const UNIQUE_KEYS: Record<Integration, keyof IProperty> = {
  [Integration.Hubspot]: "hasUniqueValue",
  [Integration.Attio]: "isUnique",
  [Integration.Salesforce]: "isUnique",
};

const WRITABLE_KEYS: Record<
  Integration,
  {
    key: keyof IProperty;
    type: WritableKeyType;
  }
> = {
  [Integration.Hubspot]: {
    key: "modificationMetadata.readOnlyValue" as keyof IProperty,
    type: "reader",
  },
  [Integration.Attio]: {
    key: "isWritable",
    type: "writer",
  },
  [Integration.Salesforce]: {
    key: "isWritable",
    type: "writer",
  },
};

const VALID_TYPES: Record<Integration, string[]> = {
  [Integration.Hubspot]: [
    "single-line text",
    "text",
    "domain",
    "email-address",
    "string",
  ],
  [Integration.Attio]: ["text", "email-address"],
  [Integration.Salesforce]: ["string", "id"],
};

function getNestedProperty(
  obj: IProperty,
  { key, type }: NestedPropertyParams,
) {
  const value = key.split(".").reduce((acc, part) => acc && acc[part], obj);
  return type === "reader" ? !value : value;
}

export const IdentifierPropertyMenu: React.FC<{
  property: IProperty;
  onSelect: (property: IProperty) => void;
  integration: Integration;
}> = ({ property, onSelect, integration }) => {
  const isUniqueKey = UNIQUE_KEYS[integration];
  const isUnique = property[isUniqueKey];
  const isWritable = getNestedProperty(property, WRITABLE_KEYS[integration]);
  const validType = VALID_TYPES[integration];
  const isValidType = validType.includes(property.type) && isWritable;

  return (
    <CommandOption
      value={() => isValidType && isUnique && onSelect(property)}
      disabled={!isValidType || !isUnique}
    >
      <Tooltip
        label={
          !isWritable
            ? "This field is read-only and cannot be written to"
            : !isValidType
              ? `The field type must be of type ${validType} and require unique values`
              : !isUnique
                ? "The property must require unique values"
                : ""
        }
        placement="right"
        shouldWrapChildren
        hasArrow
      >
        <div className="flex items-center space-x-2">
          <p className="truncate">{property.label}</p>
        </div>
      </Tooltip>
    </CommandOption>
  );
};
