import {
  ColorProps,
  IconProps,
  Image,
  ImageProps,
  Box,
} from "@chakra-ui/react";
import { AnimationControls, motion, useAnimationControls } from "framer-motion";
import { useEffect, useRef } from "react";

import { BadgeShape } from "core/design-system/components/Icon/BadgeShape";

const bounceSequence = async (controls: AnimationControls) => {
  await controls.start({ scale: 1.2 });
  return await controls.start({ scale: 1 });
};

export type BadgeProps = {
  width?: IconProps["width"];
  color?: ColorProps["color"];
  icon?: string;
  empty?: boolean;
  shimmer?: boolean;
};

export const Badge: React.FC<BadgeProps> = ({
  width,
  color,
  icon,
  empty,
  shimmer,
}) => {
  const prevEmptyRef = useRef<boolean>();
  const controls = useAnimationControls();

  const iconProps = {
    position: "absolute",
    top: "25%",
    left: 0,
    right: 0,
    margin: "0 auto",
    height: "45%",
  };

  // Bounce animation for when badges go from empty -> filled.
  useEffect(() => {
    if (prevEmptyRef.current && !empty) {
      bounceSequence(controls);
    }
    prevEmptyRef.current = empty;
  }, [controls, empty]);

  return (
    <motion.div
      animate={controls}
      style={{
        display: "inline-flex",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {icon && !empty ? (
        <Image {...(iconProps as ImageProps)} src={icon} />
      ) : null}
      {!empty && shimmer && <Box className="badge-shimmer"></Box>}
      <BadgeShape empty={empty} color={color} width={width} />
    </motion.div>
  );
};

export default Badge;
