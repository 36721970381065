import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React from "react";

import {
  BarChart as BreakdownBarChart,
  BarItem as BreakdownBarItem,
} from "core/design-system/charts/Breakdown/BreakdownBarChart";
import { VerticalBreakdownChart } from "core/design-system/charts/Breakdown/VerticalBreakdownChart";
import {
  BarChart,
  BarItem,
} from "core/design-system/charts/ConversionBarChart";
import {
  IHandleBarClickProps,
  VerticalConversionChart,
} from "core/design-system/charts/VerticalConversionChart";
import { useReportSetup } from "core/hooks/useReportSetup";
import { IBarData, IBarItem } from "core/types/BarChart";
import { GraphData } from "core/types/GraphData";
import { Unit } from "core/types/Unit";

interface IFunnelData extends GraphData {
  data?: {
    stardDate: string;
    endDate: string;
    overallConversionPercentage: number;
    eventBars: IBarData[];
  };
}
interface IFunnelChartProps {
  unit?: Unit;
  onOpen: () => void;
  graphData?: IFunnelData;
  setEventIndex: (index: number) => void;
  setHumanizedEventName: (name: string) => void;
  setConvertedContactCount: (count: number) => void;
  setDroppedContactCount: (count: number) => void;
  setShowConverted: (show: boolean) => void;
  isVertical: boolean;
  traitValues?: string[];
}

const InfoPopoverBody = () => {
  return (
    <Flex
      p={2}
      w="100%"
      h={120}
      direction="column"
      align="center"
      justify="space-between"
    >
      <Text fontSize="sm">
        Should this conversion be more than zero? You might not be merging
        anonymous and identified users.
      </Text>
      <Button
        _hover={{ bg: "gray.500" }}
        mt={1}
        w="100%"
        colorScheme="black"
        as="a"
        target="_blank"
        href="https://segment.com/docs/connections/spec/best-practices-identify/#merging-identified-and-anonymous-user-profiles"
      >
        <Text p={2}>Learn more</Text>
      </Button>
    </Flex>
  );
};

export const FunnelChart: React.FC<IFunnelChartProps> = ({
  onOpen,
  graphData,
  setEventIndex,
  setHumanizedEventName,
  setConvertedContactCount,
  setDroppedContactCount,
  setShowConverted,
  isVertical,
  traitValues,
  unit = Unit.Users,
}) => {
  const { serializeKey } = useReportSetup();
  const handleBarClick = ({
    index,
    humanizedName,
    convertedCount,
    droppedCount,
    showConverted = true,
  }: IHandleBarClickProps) => {
    onOpen();
    setEventIndex(index);
    setHumanizedEventName(humanizedName || "");
    setConvertedContactCount(convertedCount || 0);
    setDroppedContactCount(droppedCount || 0);
    setShowConverted(showConverted);
  };
  const barData = graphData?.data?.eventBars || [];
  const hasBreakdown = barData?.filter((d) => d.breakdown)?.length > 0;
  const formattedData = barData.map((item) => {
    return {
      index: item.index,
      label: item.humanizedName,
      dropped: {
        onClick: (barItem: IBarItem) => {
          handleBarClick({
            index: barItem.index,
            humanizedName: barItem.label,
            convertedCount: barItem.conversion.count,
            droppedCount: barItem.dropped?.count,
            showConverted: false,
          });
        },
        count: item.droppedCount,
        unit,
      },
      conversion: {
        onClick: (barItem: IBarItem) => {
          handleBarClick({
            index: barItem.index,
            humanizedName: barItem.label,
            convertedCount: barItem.conversion.count,
            droppedCount: barItem.dropped?.count,
            showConverted: true,
          });
        },
        count: item.convertedCount,
        unit,
        percentage: item.conversionPercentage,
        percentageFromPreviousStep: item.conversionPercentageFromPreviousStep,
        breakdown: item?.breakdown,
      },
      medianTimeTaken: item.medianTimeTaken,
      averageTimeTaken: item.averageTimeTaken,
      barItems: barData.length,
    };
  });

  if (hasBreakdown && isVertical)
    return (
      <VerticalBreakdownChart
        barData={barData}
        formattedData={formattedData as IBarItem[]}
        unit={unit}
        handleBarClick={handleBarClick}
      />
    );

  if (hasBreakdown && !isVertical)
    return (
      <BreakdownBarChart>
        {formattedData.map((item, index) => {
          return (
            <BreakdownBarItem
              key={index}
              item={item as IBarItem}
              index={index}
              barItems={formattedData as IBarItem[]}
              traitValues={traitValues}
              infoPopoverBody={unit === Unit.Users ? <InfoPopoverBody /> : null}
            />
          );
        })}
      </BreakdownBarChart>
    );

  if (isVertical)
    return (
      <VerticalConversionChart
        barData={barData}
        formattedData={formattedData as IBarItem[]}
        unit={unit}
        handleBarClick={handleBarClick}
      />
    );

  return (
    <Box w="full" key={serializeKey}>
      <BarChart>
        {formattedData.map((item, index) => {
          return (
            <BarItem
              key={`${serializeKey}-${index}`}
              index={item.index}
              item={item as IBarItem}
              barItems={barData}
              infoPopoverBody={unit === Unit.Users ? <InfoPopoverBody /> : null}
            />
          );
        })}
      </BarChart>
    </Box>
  );
};
