import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Switch,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { debounce } from "lodash";
import { useCallback } from "react";

import {
  CONVERSION_WINDOW_INTERVAL_TYPE_NAMES,
  DAY,
  HOUR,
  MINUTE,
  MONTH,
  SECOND,
  WEEK,
} from "core/constants/timerange";
import { useReportSetup } from "core/hooks/useReportSetup";
import { NumberInputFilter } from "core/modules/reports/setup/Audience/NumberInputFilter";
import { IntervalType } from "core/types/Filters";
import { cx } from "helpers/cx";

export const INTERVAL_OPTIONS = [
  SECOND,
  MINUTE,
  HOUR,
  DAY,
  WEEK,
  MONTH,
] as IntervalType[];

export const ConversionWindowPicker = () => {
  const {
    currentReport,
    enableConversionWindow,
    disableConversionWindow,
    updateConversionWindowIntervalCount,
    updateConversionWindowIntervalType,
  } = useReportSetup();

  const { conversionWindow } = currentReport?.config;
  const intervalCount = conversionWindow?.intervalCount || 7;
  const intervalType = conversionWindow?.intervalType || DAY;

  const debouncedUpdateIntervalCount = useCallback(
    (value: number) => {
      debounce((v: number) => {
        updateConversionWindowIntervalCount({
          intervalCount: v,
        });
      }, 300)(value);
    },
    [updateConversionWindowIntervalCount],
  );

  return (
    <div className="items-left mb-5 flex w-full gap-2">
      <div className="flex items-center gap-2">
        <Switch
          size="sm"
          colorScheme="purple"
          isChecked={conversionWindow?.enabled}
          onChange={() => {
            if (conversionWindow?.enabled) {
              disableConversionWindow();
            } else {
              enableConversionWindow();
            }

            updateConversionWindowIntervalCount({ intervalCount });
            updateConversionWindowIntervalType({ intervalType });
          }}
        />
        <p
          className={cx(
            "text-sm",
            conversionWindow?.enabled ? "text-gray-900" : "text-gray-500",
          )}
        >
          Converted within
        </p>
      </div>
      <div className="flex items-center gap-2">
        <div className="flex h-8 gap-2">
          <div className="flex w-1/2">
            <NumberInputFilter
              onChange={(value) => debouncedUpdateIntervalCount(Number(value))}
              isDisabled={!conversionWindow?.enabled}
              defaultValue={intervalCount}
              bg="white"
              border="0.5px solid"
              borderColor="gray.200"
              borderRadius="md"
              size="sm"
              h="full"
            />
          </div>
          <div className="flex w-1/2">
            <Menu closeOnSelect closeOnBlur matchWidth>
              <MenuButton
                h="full"
                borderRadius="md"
                fontSize="sm"
                as={Button}
                _hover={{ bg: "white" }}
                fontWeight="normal"
                isDisabled={!conversionWindow?.enabled}
                data-testid="user-event-filter-relative-interval-button"
              >
                <div className="flex items-center justify-between gap-2">
                  <div>
                    {CONVERSION_WINDOW_INTERVAL_TYPE_NAMES[intervalType]}
                    {intervalCount > 1 ? "s" : ""}
                  </div>
                  <ChevronDownIcon className="h-4 w-4" />
                </div>
              </MenuButton>
              <MenuList minW="100px" zIndex={"popover"}>
                <MenuOptionGroup
                  defaultValue={String(intervalType)}
                  type="radio"
                >
                  {INTERVAL_OPTIONS.map((i) => (
                    <MenuItem
                      key={i}
                      data-testid={`user-event-filter-interval-item-${i}`}
                      fontSize="sm"
                      onClick={() =>
                        updateConversionWindowIntervalType({
                          intervalType: i,
                        })
                      }
                      value={i}
                    >
                      {CONVERSION_WINDOW_INTERVAL_TYPE_NAMES[i]}
                      {intervalCount > 1 ? "s" : ""}
                    </MenuItem>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};
