import { Button, Text } from "@chakra-ui/react";

import { Icon } from "core/design-system/components/Icon";
import { IAudienceFilters } from "core/types/Audience";
import SaveAudienceModal from "modules/Audiences/SaveAudienceModal/SaveAudienceModal";

interface ICreateButton {
  onClick: () => void;
  isDisabled: boolean;
  audienceFilters: IAudienceFilters;
  isOpen: boolean;
  onClose: () => void;
}

export const CreateButton: React.FC<ICreateButton> = ({
  onClick,
  isDisabled,
  audienceFilters,
  isOpen,
  onClose,
}) => {
  return (
    <div className="flex">
      <Button colorScheme="purple" disabled={isDisabled} onClick={onClick}>
        <Icon iconType="icon" w={4} h={4} name="audiences" />
        <Text ml={2}>Save audience</Text>
      </Button>
      <SaveAudienceModal
        initialAudienceFilters={audienceFilters}
        isOpen={isOpen}
        onClose={onClose}
      />
    </div>
  );
};
