import { Result } from "@/core/components/AI/Graph/Result";
import { IViewInsight } from "@/core/models/viewInsights";
import { IQuery } from "@/core/types/Query";

export const ReportInsight: React.FC<{
  viewInsight?: IViewInsight;
  query: IQuery;
  sharingMode?: boolean;
}> = ({ query, viewInsight, sharingMode }) => {
  return (
    <Result
      viewInsight={viewInsight}
      query={query}
      isEditing={false}
      sharingMode={sharingMode}
    />
  );
};
