import { Badge, Flex } from "@chakra-ui/react";
import React from "react";

import { ITraitWithConfig } from "core/types/Trait";

export const TraitStatusCell: React.FC<{ props: ITraitWithConfig }> = ({
  props: trait,
}) => {
  return (
    <Flex justifyContent="flex-start">
      <Flex alignItems="center" gap={2} justifyContent="start">
        {trait.isLive ? (
          <Badge bg="green.100" color="green">
            Live
          </Badge>
        ) : (
          <Badge>Paused</Badge>
        )}
      </Flex>
    </Flex>
  );
};
