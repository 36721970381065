import { Box, Flex, Text } from "@chakra-ui/react";

import colors from "core/design-system/constants/theme/colors";

export const CustomTooltip = ({
  label,
  payload,
  active,
}: {
  label: string;
  payload: any;
  active: boolean;
  unit: string;
}) => {
  const data = payload && payload[0] && payload[0].payload;

  if (!active || !data) return null;

  return (
    <Box w="280px" bg="gray.900" px={3} py={2} borderRadius={5}>
      <Text fontSize="sm" color="gray.400">
        {label}
        {` `}
      </Text>
      <Flex mt={2} direction="column">
        {typeof data.y === "number" && (
          <Flex align="center">
            <Box
              h={2}
              w={2}
              mr={2}
              bg={colors.purple[400]}
              borderRadius="100%"
            ></Box>
            <Text fontSize="sm" color="white">
              {data.y} users
            </Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
