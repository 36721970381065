import { Menu } from "@headlessui/react";

import { cx } from "helpers/cx";
import { MenuItemText } from "modules/HelpCenter/MenuItemText";

interface MenuItemProps {
  href?: string;
  onClick?: () => void;
  label: string;
  subtitle?: string;
}

interface MenuItemContentProps extends MenuItemProps {
  active: boolean;
}

const MenuItemContent: React.FC<MenuItemContentProps> = ({
  active,
  onClick,
  href,
  label,
  subtitle,
}) => {
  const menuItemClass = cx(
    "m-1 flex cursor-pointer items-center justify-between gap-2 rounded-md px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100",
    active && "bg-gray-100",
  );

  return href ? (
    <a href={href} target="_blank" rel="noreferrer">
      <div className={menuItemClass}>
        <MenuItemText label={label} subtitle={subtitle} />
      </div>
    </a>
  ) : (
    <div onClick={onClick} className={menuItemClass}>
      <MenuItemText label={label} subtitle={subtitle} />
    </div>
  );
};

export const MenuItem: React.FC<MenuItemProps> = ({
  href,
  label,
  onClick,
  subtitle,
}) => {
  if (!href && !onClick) return null;

  return (
    <Menu.Item>
      {({ active }) => (
        <MenuItemContent
          active={active}
          href={href}
          label={label}
          onClick={onClick}
          subtitle={subtitle}
        />
      )}
    </Menu.Item>
  );
};
