import { Flex } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";

import InsightCard from "core/components/InsightCard/Index";
import { TimeRangePicker } from "core/components/TimeRangePicker";
import {
  DATE_RANGE_OPTIONS_FROM_VALUE,
  RELATIVE_TIMERANGE_VALUE_FROM_KEYS,
} from "core/constants/timerange";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";
import { ITimerangeOption } from "core/types/TimerangeOption";

export type IntervalPeriod =
  | "ONE_DAY"
  | "SEVEN_DAYS"
  | "FOURTEEN_DAYS"
  | "THIRTY_DAYS"
  | "THREE_MONTHS"
  | "SIX_MONTHS"
  | "TWELVE_MONTHS";

interface ILineContainerProps extends IReportInsight {
  LineChart: React.FC<any>;
  mockData: { [key: string]: any };
  keyAccessor: string;
  intervalType: number;
  insight: IInsightSection;
  groupId?: string | number;
  unit?: string;
  initialTimeRange?: string;
  timeRangeOptions?: ITimerangeOption[];
  showYAxis?: boolean;
  actions?: React.ReactNode;
  intervalPeriod?: IntervalPeriod;
  showFooter?: boolean;
  showActions?: boolean;
  colSpan?: number;
  showGrid?: boolean;
  simplifyXAxis?: boolean;
  h?: number;
  showReportTitle?: boolean;
  showTooltips?: boolean;
  headless?: boolean;
}

export const getDefaultTimeRange = (
  initialTimeRange:
    | "ONE_DAY"
    | "SEVEN_DAYS"
    | "FOURTEEN_DAYS"
    | "THIRTY_DAYS"
    | "FOUR_WEEKS"
    | "EIGHT_WEEKS"
    | "TWELVE_WEEKS"
    | "THREE_MONTHS"
    | "SIX_MONTHS"
    | "TWELVE_MONTHS",
) => {
  if (!initialTimeRange) return;

  const dateValue = RELATIVE_TIMERANGE_VALUE_FROM_KEYS[initialTimeRange];

  type DateRangeOptionsType =
    | 86400
    | 604800
    | 1209600
    | 2592000
    | 7776000
    | 15552000
    | 31536000;

  const isDateRangeOptionsType = (
    value: number,
  ): value is DateRangeOptionsType => {
    return (
      value === 86400 ||
      value === 604800 ||
      value === 1209600 ||
      value === 2592000 ||
      value === 7776000 ||
      value === 15552000 ||
      value === 31536000
    );
  };

  if (isDateRangeOptionsType(dateValue)) {
    return DATE_RANGE_OPTIONS_FROM_VALUE[dateValue];
  }
};

export const getTimerangeDays = (
  timerange: ITimerangeOption,
  intervalPeriod: IntervalPeriod,
) => {
  if (!timerange) return;
  const seconds = Number(
    RELATIVE_TIMERANGE_VALUE_FROM_KEYS[
      timerange.value as keyof typeof RELATIVE_TIMERANGE_VALUE_FROM_KEYS
    ],
  );
  const periodSeconds = RELATIVE_TIMERANGE_VALUE_FROM_KEYS[intervalPeriod];

  return Math.floor(seconds / periodSeconds);
};

export const LineContainer: React.FC<ILineContainerProps> = ({
  LineChart,
  report,
  insight,
  previewMode,
  sharingMode,
  screenshotMode,
  config,
  sharingSecretToken,
  initialTimeRange,
  timeRangeOptions,
  intervalType,
  keyAccessor,
  intervalPeriod,
  mockData,
  actions = null,
  unit = "users",
  showYAxis,
  additionalConfigProps,
  showInProgress = true,
  showTooltips = true,
  showFooter = true,
  showActions = true,
  colSpan = 2,
  showGrid = true,
  simplifyXAxis = false,
  h = 300,
  showReportTitle,
  headless = false,
}) => {
  const [timerange, setTimerange] = useState<ITimerangeOption>(
    getDefaultTimeRange(
      additionalConfigProps?.initialTimeRange || initialTimeRange,
    ) ||
      additionalConfigProps?.initialTimeRange ||
      initialTimeRange,
  );

  const timerangeDays =
    intervalPeriod && getTimerangeDays(timerange, intervalPeriod);

  const setAndSaveTimerange = (timerange: ITimerangeOption) => {
    setTimerange(timerange);
    localStorage.setItem(
      `${insight.slug}_LastTimeRange`,
      JSON.stringify(timerange),
    );
  };

  const isSetupValid = config?.validateSetup
    ? config.validateSetup(report.config)
    : true;
  const isFirstRender = useRef(true);

  const {
    response,
    isLoading,
    isFetching,
    error,
    refetch: originalRefetch,
    onRefreshInsight,
  } = useInsightDataFetching({
    report,
    insight,
    insightParams: {
      interval: timerangeDays,
      ...(keyAccessor === "powerUsers" && {
        daysOfUsage: timerange?.value,
      }),
    },
    timerangeValue: timerange?.timerangeValue,
    sharingSecretToken,
    previewMode,
    skip: !isSetupValid,
  });

  const refetchCallback = useCallback(() => {
    if (!isFirstRender.current) {
      originalRefetch();
    }
    isFirstRender.current = false;
  }, [originalRefetch, isFirstRender]);

  useEffect(() => {
    refetchCallback();
  }, [timerange, refetchCallback]);

  const data = previewMode ? mockData : response?.data;

  if (headless) {
    return (
      <div className="h-full w-full">
        {data && data[keyAccessor] && (
          <LineChart
            intervalType={intervalType}
            unit={unit}
            report={report}
            graphId={insight.title}
            data={data[keyAccessor]}
            settings={report?.config?.settings}
            previewMode={previewMode}
            sharingMode={sharingMode}
            insight={insight}
            timerange={timerange}
            showYAxis={showYAxis}
            showGrid={showGrid}
            showInProgress={showInProgress}
            simplifyXAxis={simplifyXAxis}
            showTooltips={showTooltips}
            headless={headless}
          />
        )}
      </div>
    );
  }

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container
          data-testid={`${insight.slug}-container`}
          id={insight.slug}
          insight={insight}
          colSpan={colSpan}
        >
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={showReportTitle || sharingMode}
            report={report}
            config={config}
            insight={insight}
            showActions={!sharingMode && !previewMode && showActions}
            sharingMode={sharingMode}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
            additionalConfigProps={{
              initialTimeRange: timerange?.value,
            }}
          >
            {actions}
            {timerange && timeRangeOptions && (
              <TimeRangePicker
                previewMode={previewMode}
                timerange={timerange}
                setTimeRange={setAndSaveTimerange}
                options={timeRangeOptions}
              />
            )}
          </Card.Header>
          <Card.Body
            h={h}
            isLoading={isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
            insight={insight}
          >
            <Flex
              bg="white"
              borderRadius="lg"
              direction="column"
              justify="center"
              h="full"
            >
              {data && data[keyAccessor] && (
                <LineChart
                  intervalType={intervalType}
                  unit={unit}
                  report={report}
                  graphId={insight.title}
                  data={data[keyAccessor]}
                  settings={report?.config?.settings}
                  previewMode={previewMode}
                  sharingMode={sharingMode}
                  insight={insight}
                  timerange={timerange}
                  showYAxis={showYAxis}
                  showGrid={showGrid}
                  showInProgress={showInProgress}
                  simplifyXAxis={simplifyXAxis}
                  showTooltips={showTooltips}
                  headless={headless}
                />
              )}
            </Flex>
          </Card.Body>
          {insight.description && !screenshotMode && showFooter && (
            <Card.Footer>
              <Card.DescriptionAccordion insight={insight} />
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
};
