import { Table, TableContainer, Tbody } from "@chakra-ui/react";

import { useSelectedEventLog } from "core/hooks/useSelectedEventLog";
import { IEventLog } from "core/types/Debugger";
import { Log } from "modules/Developers/Logs/Log";

function getId(d: IEventLog) {
  return `${d.type}${d.timestamp}`;
}

export const LogTable: React.FC<{
  data: IEventLog[];
}> = ({ data }) => {
  const { selectLog, selectedLog } = useSelectedEventLog();

  const selectedLogId = selectedLog ? getId(selectedLog) : null;

  return (
    <TableContainer mb={10}>
      <Table variant="simple">
        <Tbody w="full">
          {data?.map((d: IEventLog, index: number) => (
            <Log
              selected={selectedLogId === getId(d)}
              key={getId(d) + index}
              data={d}
              onSelect={() => selectLog(d)}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
