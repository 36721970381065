import {
  ComponentDefaultProps,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
} from "@chakra-ui/react";

import { DrilldownBody } from "core/components/Drilldown/private/DrilldownBody";
import { DrilldownDownloadButton } from "core/components/Drilldown/private/DrilldownDownloadButton";
import { DrilldownHeader } from "core/components/Drilldown/private/DrilldownHeader";
import { DrilldownList } from "core/components/Drilldown/private/DrilldownList";
import { DrilldownSearch } from "core/components/Drilldown/private/DrilldownSearch";
import { DrilldownTitle } from "core/components/Drilldown/private/DrilldownTitle";
import { DrilldownToggle } from "core/components/Drilldown/private/DrilldownToggle";

interface IDrilldownProps extends ComponentDefaultProps {
  isOpen: boolean;
  onClose: () => void;
  children: ({
    Header,
    Title,
    Body,
  }: {
    Header: typeof DrilldownHeader;
    Title: typeof DrilldownTitle;
    Search: typeof DrilldownSearch;
    List: typeof DrilldownList;
    DownloadButton: typeof DrilldownDownloadButton;
    Toggle: typeof DrilldownToggle;
    Body: typeof DrilldownBody;
  }) => React.ReactElement;
}

export const Drilldown: React.FC<IDrilldownProps> = ({
  size = "lg",
  isOpen,
  onClose,
  children,
  ...styleProps
}) => {
  return (
    <Drawer
      trapFocus={true}
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      isFullHeight
    >
      <DrawerOverlay />
      <DrawerContent
        h="99vh"
        borderRadius="lg"
        bg="gray.200"
        p={1}
        m="auto 0"
        data-testid="drilldown-drawer-container"
      >
        <Flex
          p={6}
          bg="white"
          borderRadius="lg"
          h="100%"
          flexDirection="column"
          {...styleProps}
        >
          <DrawerCloseButton data-testid="drilldown-drawer-close-button" />
          {children({
            Header: DrilldownHeader,
            Search: DrilldownSearch,
            Title: DrilldownTitle,
            DownloadButton: DrilldownDownloadButton,
            Toggle: DrilldownToggle,
            Body: DrilldownBody,
            List: DrilldownList,
          })}
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};
