import React from "react";

import { useGetAppObjectsQuery } from "core/models/appObjects";
import { AppObjectType, IAppObject } from "core/types/AppObject";

const companyRelationships = [
  {
    name: "Users",
    relationship: "Has many",
  },
];

export const Relationships: React.FC<{ appObject: IAppObject }> = ({
  appObject,
}) => {
  const { data: appObjects, isLoading } = useGetAppObjectsQuery({
    appId: appObject.appId,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const getRelationships = () => {
    if (appObjects === undefined) return [];
    if (appObject.objectType === AppObjectType.User) {
      const companyObjects = appObjects.filter(
        (object) => object.objectType !== AppObjectType.User,
      );
      return companyObjects.map((object) => ({
        name: object.pluralName,
        relationship:
          object.objectType === AppObjectType.Company ? "Has one" : "Has many",
      }));
    } else if (
      appObject.objectType === AppObjectType.Company ||
      appObject.objectType === AppObjectType.Group
    ) {
      return companyRelationships;
    } else {
      return [];
    }
  };

  const relationships = getRelationships();

  return (
    <div className="flex flex-col">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Relationship
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {relationships.map((relationship) => (
                <tr key={relationship.name}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    {relationship.name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {relationship.relationship}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
