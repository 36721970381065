import { Tooltip } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { BoltIcon as BoltOutlineIcon } from "@heroicons/react/24/outline";
import { BoltIcon as SolidBoltIcon } from "@heroicons/react/24/solid";
import { HeaderContext } from "@tanstack/react-table";

import { ComputedTraitPopover } from "core/components/Table/People/Column/ComputedTraitPopover";
import { HeaderMenu } from "core/components/Table/People/Column/HeaderMenu";
import { TraitIcon } from "core/components/Traits/TraitIcon";
import { IContact } from "core/types/Contact";
import { IGroup } from "core/types/Group";
import { ITableTraitConfig } from "core/types/TableTraitConfig";
import { cx } from "helpers/cx";

export const ColumnHeader: React.FC<{
  info: HeaderContext<IContact | IGroup, unknown>;
  traitConfig: ITableTraitConfig;
  deleteTraitConfig: ({ id }: { id: string }) => void;
  moveColumnLeft: (columnId: string) => void;
  moveColumnRight: (columnId: string) => void;
  isFirst: boolean;
  isLast: boolean;
  sortBy: string;
  sortOrder: string;
  setSortBy: (sortBy: string) => void;
  setSortOrder: (sortOrder: string) => void;
}> = ({
  info,
  traitConfig,
  deleteTraitConfig,
  moveColumnLeft,
  moveColumnRight,
  isFirst,
  isLast,
  sortBy,
  sortOrder,
  setSortBy,
  setSortOrder,
}) => {
  const traitName = traitConfig.trait;

  return (
    <>
      <Popover className="relative">
        <PopoverButton
          as="span"
          className={cx(
            "block w-full min-w-full py-1.5 pl-3 pr-5 hover:bg-gray-50 hover:ring-2 hover:ring-gray-50",
          )}
        >
          <div className="flex w-full flex-row items-center justify-between">
            <div className="flex w-full items-center gap-1">
              <TraitIcon
                traitName={traitName}
                computationType={
                  traitConfig?.computedTrait?.config?.computationType
                }
              />
              <Tooltip label={info.header.id} placement="top" hasArrow>
                <p className="w-full truncate selection:bg-transparent">
                  {traitConfig.trait.split(`_`).join(` `)}
                </p>
              </Tooltip>
            </div>
            <div className="flex items-center">
              {traitConfig?.computedTrait?.isComputed && (
                <ComputedTraitPopover computedTrait={traitConfig.computedTrait}>
                  {traitConfig.computedTrait.isLive ? (
                    <SolidBoltIcon className="h-3 w-3 text-purple-500" />
                  ) : (
                    <BoltOutlineIcon className="h-3 w-3 text-purple-500" />
                  )}
                </ComputedTraitPopover>
              )}
            </div>
          </div>
        </PopoverButton>
        <PopoverPanel>
          <HeaderMenu
            traitConfig={traitConfig}
            deleteTraitConfig={deleteTraitConfig}
            moveColumnLeft={moveColumnLeft}
            moveColumnRight={moveColumnRight}
            isFirst={isFirst}
            isLast={isLast}
            sortBy={sortBy}
            sortOrder={sortOrder}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
          />
        </PopoverPanel>
      </Popover>
    </>
  );
};
