import { BoxProps } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import { ErrorPage } from "@/Components/Error/Page";
import { selector as errorSelector } from "@/core/models/error";

export const Error = (props: BoxProps) => {
  const { data: error } = useSelector(errorSelector);

  return (
    <>
      {error && (
        <ErrorPage title={error.title} action={error.action} {...props} />
      )}
    </>
  );
};
