import { useState } from "react";

import InsightCard from "core/components/InsightCard/Index";
import { TimeRangePicker } from "core/components/TimeRangePicker";
import { WAU_OPTIONS } from "core/constants/timerange";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { wauMauMockData } from "core/modules/reports/report-types/ActiveUsers/mockData";
import WauMau from "core/modules/reports/report-types/ActiveUsers/WauMau";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";
import { ITimerangeOption } from "core/types/TimerangeOption";

const WauMauContainer = ({
  report,
  previewMode,
  sharingMode,
  screenshotMode,
  config,
  sharingSecretToken,
}: IReportInsight) => {
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "wau-mau",
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const initialTimeRange = WAU_OPTIONS[0];
  const [timerange, setTimerange] =
    useState<ITimerangeOption>(initialTimeRange);

  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      insight,
      sharingSecretToken,
      insightParams: { interval: timerange.count ? timerange.count / 7 : 12 },
      previewMode,
      skip: !isSetupValid,
    });
  const data = previewMode ? wauMauMockData : response?.data;

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            tooltipText="Percentage of monthly users that use the product on a weekly basis"
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          >
            <TimeRangePicker
              previewMode={previewMode}
              setTimeRange={setTimerange}
              timerange={timerange}
              options={WAU_OPTIONS}
            />
          </Card.Header>
          <Card.Body
            isLoading={isFetching || !data || isLoading}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            <WauMau
              data={data}
              previewMode={previewMode}
              sharingMode={sharingMode}
            />
          </Card.Body>
        </Card.Container>
      )}
    </InsightCard>
  );
};
export default WauMauContainer;
