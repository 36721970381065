import { Grid, GridItem } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

import { Template } from "core/constants/templates";
import { TemplateCard } from "modules/Templates/TemplateCard";

interface ITemplateCollectionProps {
  collection: Template[];
  setSelectedTemplate: (template: Template) => void;
  onClose: () => void;
}

export const TemplateCollection: React.FC<ITemplateCollectionProps> = ({
  collection,
  setSelectedTemplate,
  onClose,
}) => {
  return (
    <Grid
      w="full"
      templateRows="repeat(3, 0fr)"
      templateColumns={"repeat(9, 1fr)"}
      gap={6}
    >
      {collection.map((template, index) => {
        return (
          <GridItem rowSpan={1} colSpan={3} key={index}>
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: index * 0.08 }}
            >
              <TemplateCard
                template={template}
                setSelectedTemplate={setSelectedTemplate}
                onClose={onClose}
              />
            </motion.div>
          </GridItem>
        );
      })}
    </Grid>
  );
};
