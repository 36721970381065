import { ComponentDefaultProps, Flex } from "@chakra-ui/react";

import { Toggle } from "core/components/Toggle";

export const DrilldownToggle: React.FC<ComponentDefaultProps> = ({
  children,
}) => {
  return (
    <Flex direction="column" alignItems="center" justifyContent="center" mb={4}>
      <Toggle h={"2rem"}>{children}</Toggle>
    </Flex>
  );
};
