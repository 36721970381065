import { useEffect } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";

import { JuneSpinner } from "@/core/components/JuneSpinner";
import { useAppObjects } from "@/core/hooks/useAppObjects";

export const Group = () => {
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const navigate = useNavigate();
  const { groupAppObject } = useAppObjects();
  const id = decodeURIComponent(encodedGroupId);

  useEffect(() => {
    if (appId && id && groupAppObject) {
      navigate(`/a/${appId}/objects/${groupAppObject.id}/object/${id}`);
    }
  }, [appId, id, navigate, groupAppObject]);

  if (!id) {
    return <Navigate to={`/a/${appId}/groups`} />;
  }

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <JuneSpinner />
    </div>
  );
};
