import { Flex, StyleProps, Text } from "@chakra-ui/react";

import { COMPARISON_TO_NAME } from "core/constants/traitFilterComponents";
import { IEvent } from "core/types/Event";
import { ComparisonType } from "core/types/Filters.d";

interface IPageEventPillProps extends StyleProps {
  event: IEvent;
}

const PageEventPill: React.FC<IPageEventPillProps> = ({
  event,
  children,
  ...props
}) => {
  const existingProperties = event.properties[0].body;
  const { value, comparisonType, property } = existingProperties;
  const comparison =
    COMPARISON_TO_NAME[Number(comparisonType) as ComparisonType];
  return (
    <Flex
      display="inline-block"
      px={3}
      py={1}
      bg="gray.100"
      color="gray.900"
      border="none"
      borderRadius="md"
      mr={2}
      direction="row"
      {...props}
    >
      <Flex>
        <Text fontWeight="bold" fontSize="sm">
          <Text display="inline-block" fontWeight="normal" color="gray.900">
            Page{" "}
          </Text>{" "}
          <Text display="inline-block" fontWeight="normal" color="gray.900">
            where
          </Text>{" "}
          {property}{" "}
          <Text display="inline-block" fontWeight="normal" color="gray.900">
            {comparison}
          </Text>{" "}
          {value}
        </Text>
      </Flex>
      {children}
    </Flex>
  );
};

export default PageEventPill;
