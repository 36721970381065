import { Button, Flex, Grid, Stack, Text } from "@chakra-ui/react";
import { Navigate } from "react-router-dom";

import { CompanyJuneLogoIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { useUserAuth } from "core/hooks/useUserAuth";
import { isUserReportLevel } from "core/modules/reports/utils";
import { IReport } from "core/types/Report";
import { ITemplateConfig } from "core/types/TemplateConfig";
import { inIframe } from "helpers/iframe";

interface IPublicReportProps {
  appId: number;
  report: IReport;
  sharingSecretToken: string;
  config: ITemplateConfig;
}

const SignUpBanner = () => {
  if (inIframe()) return null;

  return (
    <div className="flex items-start justify-center" data-id-public-banner>
      <div className="mb-8 flex w-[80vw] max-w-[870px] items-center justify-between rounded-lg border border-gray-50 bg-white px-4 py-3 shadow-sm">
        <div className="flex items-center">
          <div className="flex">
            <CompanyJuneLogoIcon h={6} w={6} color="primary" />
          </div>
          <p className="ml-4 text-sm font-medium">Welcome to June!</p>
          <p className="ml-2 text-sm font-medium">
            Create an account to edit and collaborate on this report.
          </p>
        </div>
        <div className="flex items-center">
          <Button as="a" href="https://analytics.june.so/login" fontSize="sm">
            Log in
          </Button>
          <Button
            ml={4}
            as="a"
            href="https://analytics.june.so/start"
            fontSize="sm"
            colorScheme="purple"
          >
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};

export const PublicReport: React.FC<IPublicReportProps> = ({
  appId,
  report,
  config,
  sharingSecretToken,
}) => {
  const { currentUser } = useUserAuth();
  const isUserLevel = isUserReportLevel();
  // Temporary fix for handling old and new report configs
  let sections = config.sections as any;
  if (sections.company && sections.company) {
    sections = !isUserLevel ? sections.company : sections.user;
  }

  if (currentUser && !inIframe())
    return <Navigate to={`/a/${appId}/report/${report.id}`} />;

  return (
    <Flex direction="column" minHeight="100vh" bg="gray.50" pt={12}>
      <SignUpBanner />
      <Flex align="flex-start" justify="center">
        <Flex maxWidth="870px" width="80vw">
          <Text fontSize="3xl" fontWeight="bold">
            {report.name}
          </Text>
        </Flex>
      </Flex>
      <Flex
        ml={0}
        justify="center"
        align="center"
        direction="column"
        transition="margin-left 0.3s"
      />
      <Flex align="flex-start" justify="center">
        <>
          <Flex
            transition="margin-left 0.3s"
            ml={0}
            justify="center"
            direction="column"
            align="center"
            my={8}
          >
            <Stack maxWidth="870px" width="80vw">
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={4}
                maxW="870px"
                minW="870px"
              >
                {sections.map((Section: React.FC<any>, index: number) => (
                  <Section
                    appId={appId}
                    config={config}
                    reportId={report.id}
                    report={report}
                    sharingMode={true}
                    sharingSecretToken={sharingSecretToken}
                    showYAxis
                  />
                ))}
              </Grid>
            </Stack>
          </Flex>
        </>
      </Flex>
    </Flex>
  );
};
