import { AttachmentIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";

import { Action } from "core/components/AI/Action";
import { CloseConversation } from "core/components/AI/Actions/CloseConversation";
import { IBlockComponent } from "core/components/AI/ConversationContainer";
import { Message } from "core/components/AI/Message";
import { uuid } from "core/helpers/uuid";
import { useAI } from "core/hooks/useAI";
import { From } from "core/models/ai";

export const PinToReport: React.FC<IBlockComponent> = ({
  delay,
  isReverse,
  children,
  ...props
}) => {
  const { pinQuery, addConversationBlock } = useAI();
  const { appId, reportId } = useParams() as {
    appId: string;
    reportId: string;
  };

  return (
    <Action
      onClick={() => {
        pinQuery({
          appId: Number(appId),
          reportId: Number(reportId),
          queryId: props.query?.id,
        });
        addConversationBlock({
          id: uuid(),
          from: From.June,
          blocks: [
            {
              Component: Message,
              content:
                "The insight has been pinned to this report. Is there anything else I can help with?",
            },
            {
              Component: CloseConversation,
            },
          ],
        });
      }}
      delay={delay}
      isReverse={isReverse}
    >
      <AttachmentIcon mr={2} />
      Pin to this report
    </Action>
  );
};
