import { PresentationChartLineIcon } from "@heroicons/react/24/outline";

import { IFavourite } from "core/models/favourites";
import { useGetReportQuery } from "core/models/reports";
import { LoadingItem } from "modules/Navigation/Sidebar/Favourites/LoadingItem";

export const ReportItem: React.FC<{ favourite: IFavourite }> = ({
  favourite,
}) => {
  const { data: report, isLoading } = useGetReportQuery({
    appId: favourite.appId,
    reportId: favourite.objectId,
    sharingSecretToken: null,
    skipReportShowMetrics: true,
  });

  if (isLoading) return <LoadingItem />;

  return (
    <div className="flex flex-row items-center gap-2">
      <PresentationChartLineIcon className="h-4 w-4 text-gray-600" />
      <p className="truncate text-[13px]">{report?.name || ""}</p>
    </div>
  );
};
