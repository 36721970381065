import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { orderBy } from "lodash";

import { TRAIT_COMPARISON_TYPES } from "core/constants/traitFilterComponents";
import { ICustomTooltipProps as ITooltipProps } from "core/types/CustomTooltip";
import { IEventProperty } from "core/types/EventProperty";

interface IProps extends ITooltipProps {
  hideEventNames?: boolean;
}

export const CustomTooltip: React.FC<IProps> = ({
  label,
  payload,
  active,
  hideEventNames = false,
  ...props
}) => {
  if (active && payload && payload.length) {
    return (
      <Box bg="gray.700" px={3} py={2} borderRadius="lg" {...props}>
        <Text fontSize="xs" color="gray.400">
          {label}
        </Text>
        {orderBy(payload, "payload.y", "desc")?.map((p, idx) => (
          <Stack direction="row" spacing="2" align="center" key={idx}>
            <div className="flex items-center justify-between gap-2">
              <div className="flex items-center gap-2">
                <Box h="2" w="2" bg={p.color} borderRadius="lg"></Box>
                <p key={idx} className="text-sm text-white">
                  {p.payload.y} {hideEventNames ? "" : "events"}
                </p>
              </div>
              <p className="max-w-[100px] truncate text-xs text-gray-400">
                {hideEventNames ? "times" : p.name}
              </p>
              <Flex>
                {p.payload.eventProperties?.map(
                  (eventProperty: IEventProperty, idx: number) => (
                    <Text
                      as="span"
                      color="gray.600"
                      fontWeight="medium"
                      fontSize="xs"
                      key={idx}
                    >
                      <>
                        {eventProperty.body.property}{" "}
                        <Text as="u" fontSize="xs">
                          {
                            TRAIT_COMPARISON_TYPES[
                              Number(eventProperty.body.comparisonType)
                            ]?.name
                          }
                        </Text>{" "}
                      </>
                      {eventProperty.body.value}
                    </Text>
                  ),
                )}
              </Flex>
            </div>
          </Stack>
        ))}
      </Box>
    );
  }
  return null;
};
