import { withInView } from "core/components/InsightCard/withInView";
import { ActivationOverviewGraph } from "core/components/ViewInsight/Graphs/ActivationOverviewGraph";
import { List } from "core/components/ViewInsight/Graphs/List";
import defaultTemplateConfig from "core/constants/report-configurations/report-types/defaultTemplateConfig";
import {
  AUDIENCE_TYPE,
  FIRST,
  MULTI_EVENT_TYPE,
  OR,
  PAGE,
  TRACK,
} from "core/constants/report-setup";
import { Template } from "core/constants/templates";
import { DEFAULT_GROUPED_OPTIONS } from "core/constants/timerange";
import { CompletionRateContainer } from "core/modules/reports/Milestones/CompletionRateContainer";
import HottestContainer from "core/modules/reports/Milestones/HottestContainer";
import OverviewContainer from "core/modules/reports/Milestones/OverviewContainer";
import StrugglingContainer from "core/modules/reports/Milestones/StrugglingContainer";
import { IContact } from "core/types/Contact";
import { IEvent } from "core/types/Event";
import { IGroup } from "core/types/Group";
import { ITemplateConfig } from "core/types/TemplateConfig";
import { ITrait } from "core/types/Trait";

const LazyCompletionRateContainer = withInView(CompletionRateContainer);

const milestones: ITemplateConfig = {
  ...defaultTemplateConfig,
  reportType: 22,
  route: "user-activation",
  title: "Activation",
  template: Template.Activation,
  subtitle: "A template to capture how your users are activating",
  aboutTemplate:
    "A way to keep tracking of your users activation throughout their journey inside of your product.",
  sections: {
    user: [
      OverviewContainer,
      StrugglingContainer,
      HottestContainer,
      LazyCompletionRateContainer,
    ],
    company: [
      OverviewContainer,
      StrugglingContainer,
      HottestContainer,
      LazyCompletionRateContainer,
    ],
  },
  insights: [
    {
      typeId: 0,
      slug: "milestones-overview-users",
      title: "Milestones overview",
      section: OverviewContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about how we calculate the milestones overview",
        content: `We look at users that have performed the set of events in each milestone out of the users that have completed the first milestone based on the report timerange.`,
      },
      view: {
        level: "user",
        Component: ActivationOverviewGraph,
        transform: (data) => data?.milestones,
        highlight: (data) => {
          return data?.overallCompletionPercentage !== undefined
            ? {
                value: data?.overallCompletionPercentage,
                unit: "%",
                description: "completed all milestones",
              }
            : { value: 0, unit: "%" };
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 1,
      slug: "milestones-struggling-users",
      title: "Struggling {APP_OBJECT_PLURAL_NAME}",
      section: StrugglingContainer,
      shareable: false,
      copyable: false,
      downloadable: false,
      view: {
        level: "user",
        Component: List,
        transform: (data) => {
          return data?.contacts?.slice(0, 4)?.map((contact: IContact) => {
            const traits = contact?.traits as ITrait;
            return {
              id: contact?.userId,
              displayName: traits?.["email"],
              avatar: traits?.["avatar"],
              description: `Completed ${contact?.milestonesCompleted} milestones`,
            };
          });
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 2,
      slug: "milestones-hottest-users",
      title: "Hottest {APP_OBJECT_PLURAL_NAME}",
      section: HottestContainer,
      shareable: false,
      copyable: false,
      downloadable: false,
      view: {
        level: "user",
        Component: List,
        transform: (data) => {
          return data?.contacts?.slice(0, 4)?.map((contact: IContact) => {
            const traits = contact?.traits as ITrait;
            return {
              id: contact?.userId,
              displayName: traits?.["email"],
              avatar: traits?.["avatar"],
              description: `Completed ${contact?.milestonesCompleted} milestones`,
            };
          });
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 3,
      slug: "milestones-completion-rate",
      title: "Completion rate",
      section: CompletionRateContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn how we calculate the completion rate for each milestone",
        content:
          "We segment users into weeks based on when they have performed their first event, and how many of the milestones they have reached within that week.",
      },
      hasInProgress: true,
      hasYAxis: true,
    },
    {
      typeId: 6,
      slug: "milestones-overview-companies",
      title: "Milestones overview",
      section: OverviewContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about how we calculate the milestones overview",
        content: `We look at companies that have performed the set of events in each milestone out of the companies that completed the first milestone based on the report timerange.`,
      },
      view: {
        level: "group",
        Component: ActivationOverviewGraph,
        transform: (data) => data?.milestones,
        highlight: (data) => {
          return data?.overallCompletionPercentage !== undefined
            ? {
                value: data?.overallCompletionPercentage,
                unit: "%",
                description: "completed all milestones",
              }
            : { value: 0, unit: "%" };
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 7,
      slug: "milestones-struggling-companies",
      title: "Struggling {APP_OBJECT_PLURAL_NAME}",
      section: StrugglingContainer,
      shareable: false,
      copyable: false,
      view: {
        level: "group",
        Component: List,
        transform: (data) => {
          return data?.groups?.slice(0, 3)?.map((group: IGroup) => {
            const traits = group?.traits as ITrait;
            return {
              id: group?.groupId,
              displayName: traits?.["name"],
              avatar: traits?.["avatar"],
              description: `Completed ${group?.milestonesCompleted} milestones`,
            };
          });
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 8,
      slug: "milestones-hottest-companies",
      title: "Hottest {APP_OBJECT_PLURAL_NAME}",
      section: HottestContainer,
      shareable: false,
      copyable: false,
      view: {
        level: "group",
        Component: List,
        transform: (data) => {
          return data?.groups?.slice(0, 3)?.map((group: IGroup) => {
            const traits = group?.traits as ITrait;
            return {
              id: group?.groupId,
              displayName: traits?.["name"],
              avatar: traits?.["avatar"],
              description: `Completed ${group?.milestonesCompleted} milestones`,
            };
          });
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 9,
      slug: "milestones-completion-rate-companies",
      title: "Completion rate",
      section: CompletionRateContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn how we calculate the completion rate for each milestone",
        content:
          "We segment companies into weeks based on when they have performed their first event, and how many of the milestones they have reached within that week.",
      },
      hasInProgress: true,
      hasYAxis: true,
    },
  ],
  setup: {
    requiredSections: [0],
    eventOperator: [OR],
    setupSections: [
      {
        id: "milestone1",
        configKey: "milestone",
        title: "Signed up",
        type: MULTI_EVENT_TYPE,
        eventOccurrences: true,
        supportedEventTypes: [FIRST, TRACK, PAGE],
        required: true,
        isRenameable: true,
        isDynamic: true,
        limit: 5,
        validate: (events) =>
          events?.length ===
          events?.map((event) => event.id)?.filter((x) => x)?.length,
      },
      {
        id: "audience",
        configKey: "audience",
        title: "Audience",
        description: "Select an audience for your report",
        type: AUDIENCE_TYPE,
        supportedUserTypes: ["user"],
      },
    ],
  },
  hasDateRangeSection: true,
  dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
  validateSetup: (config) =>
    Object.keys(config).some(
      (key) =>
        key.includes("milestone") &&
        Array.isArray(config[key]) &&
        config[key].length > 0 &&
        config[key].some((e: IEvent) => e.name),
    ),
};

export default milestones;
