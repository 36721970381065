import { useSelector } from "react-redux";

import { selector as appSelector } from "core/models/app";
import { Plan } from "core/types/App";

interface IUsePaywall {
  isFree: boolean;
  isPaying: boolean;
  isTrialing: boolean;
  isPaymentOverdue: boolean;
  shouldBePaywalled: boolean;
  plan: Plan;
}

export function usePaywall(): IUsePaywall {
  const {
    data: { isPaying, isTrialing, isFree, isPaymentOverdue, plan },
  } = useSelector(appSelector);

  const shouldBePaywalled = !(isPaying || isTrialing) || isPaymentOverdue;

  return {
    isPaying,
    isTrialing,
    isPaymentOverdue,
    isFree,
    shouldBePaywalled,
    plan,
  };
}
