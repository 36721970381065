import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

import { TRAIT_COMPARISON_TYPES } from "core/constants/traitFilterComponents";

export const ComparisonMenu: React.FC<{
  selectedProperty: string;
  onSelectComparison: (c: number) => void;
}> = ({ selectedProperty, onSelectComparison }) => {
  return (
    <Menu>
      <MenuButton className="rounded px-1 py-0.5 hover:bg-gray-200">
        {selectedProperty}
      </MenuButton>
      <MenuList>
        {TRAIT_COMPARISON_TYPES.map((c) => (
          <MenuItem onClick={() => onSelectComparison(c.value)} key={c.value}>
            {c.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
