import { isNumber } from "lodash";

import { DataRow, TextAlign } from "core/components/AI/Graph/DataTable/types";
import SingleContact from "core/components/Contact/SingleContact";
import SingleGroup from "core/components/Group/SingleGroup";

interface CellProps {
  value: DataRow[keyof DataRow];
  columnName: string;
  index: number;
  align: TextAlign;
}

export const Cell = ({ value, columnName, index, align }: CellProps) => {
  const isObject = typeof value === "object" || Array.isArray(value);

  if (columnName === "user_id") {
    return (
      <SingleContact
        id={value as string}
        p={0}
        _hover={{ borderColor: "transparent" }}
        hideExternalLinkIcon
      />
    );
  }

  if (columnName === "group_id") {
    return (
      <SingleGroup
        id={value as string}
        p={0}
        _hover={{ borderColor: "transparent" }}
        hideExternalLinkIcon
      />
    );
  }

  if (columnName.includes(`timestamp`) || columnName.includes(`date`)) {
    if (!value) return <span className="text-sm text-gray-600">-</span>;

    return <span className="text-sm text-black">{String(value)}</span>;
  }

  if (isNumber(value)) {
    return (
      <span
        className={`text-sm text-black ${
          index === 0
            ? "text-left"
            : align === TextAlign.RIGHT
              ? "text-right"
              : "text-left"
        }`}
      >
        {Number(value).toLocaleString("en-US")}
      </span>
    );
  }

  return (
    <span
      className={`text-sm text-black ${
        index === 0
          ? "text-left"
          : align === TextAlign.RIGHT
            ? "text-right"
            : "text-left"
      }`}
    >
      {isObject ? JSON.stringify(value) : String(value)}
    </span>
  );
};
