import { TraitLevel } from "core/models/traits";
import { AppObjectType } from "core/types/AppObject";

// Section types
export const ENUM_TYPE: string = "enum";
export const SINGLE_EVENT_TYPE: string = "single_event";
export const MULTI_EVENT_TYPE: string = "multi_event";
export const AUDIENCE_TYPE: string = "audience";
export const SETTINGS_TYPE: string = "settings";
export const BREAKDOWN_TYPE: string = "breakdown";
export const CONVERSION_WINDOW_TYPE: string = "conversion_window";
export const OCCURRENCES_TYPE: string = "occurrences";

// Event types
export enum EventType {
  FIRST = -2,
  ANY = -1,
  PAGE = 0,
  TRACK = 2,
  IDENTIFY = 3,
}
export const FIRST: number = -2;
export const ANY: number = -1;
export const PAGE: number = 0;
export const TRACK: number = 2;

// Event operators
export const AND: string = "AND";
export const OR: string = "OR";
export const THEN: string = "THEN";

// Audience filter types
export const USER_TRAIT_FILTER: string = "user_trait_filter";
export const GROUP_TRAIT_FILTER: string = "group_trait_filter";
export const COMPANY_TRAIT_FILTER: string = "company_trait_filter";
export const TRACK_EVENT_FILTER: string = "track_event_filter";

// Audience track event comparison types
export const LESS_THAN: number = 0;
export const MORE_THAN: number = 1;
export const EXACTLY: number = 2;

export const FREQUENCY_MATCHER_LABELS = {
  [LESS_THAN]: "less than",
  [MORE_THAN]: "more than",
  [EXACTLY]: "exactly",
};

export const USER_TRAIT: number = 0;
export const EVENT_FILTER: number = 1;
export const GROUP_FILTER: number = 4;
export const COMPANY_FILTER: number = 7;
export const FILTER_TYPE_TO_TRAIT_TYPE = {
  [USER_TRAIT_FILTER]: USER_TRAIT,
  [GROUP_TRAIT_FILTER]: GROUP_FILTER,
  [COMPANY_TRAIT_FILTER]: COMPANY_FILTER,
  [TRACK_EVENT_FILTER]: EVENT_FILTER,
};
export const TRAIT_TYPE_TO_FILTER_TYPE = {
  [USER_TRAIT]: USER_TRAIT_FILTER,
  [GROUP_FILTER]: GROUP_TRAIT_FILTER,
  [COMPANY_FILTER]: COMPANY_TRAIT_FILTER,
  [EVENT_FILTER]: TRACK_EVENT_FILTER,
};

export enum FilterType {
  UserTrait = USER_TRAIT,
  EventFilter = EVENT_FILTER,
  GroupFilter = GROUP_FILTER,
  CompanyFilter = COMPANY_FILTER,
}

export const FilterTypeToAppObjectType = {
  [FilterType.UserTrait]: AppObjectType.User,
  [FilterType.CompanyFilter]: AppObjectType.Company,
  [FilterType.GroupFilter]: AppObjectType.Group,
};

export const FilterTypeToTraitLevel = {
  [FilterType.UserTrait]: TraitLevel.USER_LEVEL,
  [FilterType.CompanyFilter]: TraitLevel.COMPANY_LEVEL,
  [FilterType.GroupFilter]: TraitLevel.GROUP_LEVEL,
};

export enum TimerangeType {
  ALL_TIME = 0,
  RELATIVE = 1,
  ABSOLUTE = 2,
  RELATIVE_FIRST = 3,
}
export const TIMERANGE_TYPE_LABELS = {
  [TimerangeType.ALL_TIME]: "all time",
  [TimerangeType.RELATIVE]: "in the last",
  [TimerangeType.ABSOLUTE]: "between",
  [TimerangeType.RELATIVE_FIRST]: "in the first",
} as { [key: string]: string };
