import { Menu } from "@headlessui/react";

import { QuestionIcon } from "core/design-system/components/Icon/Interface/QuestionIcon";
import { Changelogs } from "modules/HelpCenter/Changelogs";
import { MenuItem } from "modules/HelpCenter/MenuItem";

export const HelpCenter: React.FC = () => {
  return (
    <div className="fixed bottom-4 right-4 z-sticky">
      <Menu>
        <Menu.Button className="mt-1 rounded-full border border-gray-200 bg-white p-2 text-left text-sm text-gray-700 shadow-md hover:bg-gray-100">
          <div className="flex items-center justify-center">
            <QuestionIcon color="gray.600" className="h-6 w-6" />
          </div>
        </Menu.Button>
        <Menu.Items className="absolute bottom-10 right-0 z-popover w-[256px] max-w-[256px] rounded-md border border-gray-200 bg-white py-0 text-base shadow-sm ring-1 ring-gray-200 ring-opacity-5 focus:outline-none sm:text-sm">
          <Changelogs />
          <MenuItem href="https://help.june.so" label="Help center" />
          <MenuItem
            href="https://www.june.so/docs"
            label="Developer documentation"
          />
          <MenuItem
            onClick={() => window.Intercom("show")}
            label="Talk to us"
          />
        </Menu.Items>
      </Menu>
    </div>
  );
};
