import { Flex, Text } from "@chakra-ui/react";
import moment from "moment";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { RELATIVE_TIMERANGE_VALUE_FROM_KEYS } from "@/core/constants/timerange";
import { IViewInsight } from "@/core/models/viewInsights";
import { GridWidth } from "@/core/types/ViewInsight";
import { cn } from "@/lib/utils";
import { DataPoint } from "@/modules/ViewInsight/ViewInsightComponent";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { ISingleCohortData } from "core/models/home";

function getRowColor({
  retentionData,
  color,
}: {
  retentionData: number;
  color: IColorPalette;
}) {
  const { gray } = colors;

  const retention = Math.floor(retentionData / 4);
  let colorIndex = retention > 5 ? 5 : retention;
  const colorKey = Number(Object.keys(color)[colorIndex]) as keyof typeof color;

  const backgroundColor =
    retentionData === 0 ? gray[50] : (color[colorKey] as string);

  return backgroundColor;
}

function getTextColor({
  retentionData,
  color,
}: {
  retentionData: number;
  color: IColorPalette;
}) {
  const { gray } = colors;

  const retention = Math.floor(retentionData / 4);
  let colorIndex = retention > 5 ? 5 : retention;

  // For zero retention, use a dark gray text on the light background
  if (retentionData === 0) {
    return gray[600];
  }

  // For higher retention values (darker backgrounds), use white text
  return colorIndex >= 3 ? "white" : gray[700];
}

interface ICohortTable {
  data: ISingleCohortData[];
  color?: IColorPalette;
  onClickDataPoint: (payload: DataPoint) => void;
  viewInsight: IViewInsight;
}

export const CohortTable: React.FC<ICohortTable> = ({
  data,
  color = colors.templateLightPurple,
  onClickDataPoint,
  viewInsight,
}) => {
  const cohortData = data ? [...data] : [];
  const reversedData = [...cohortData.reverse()];
  const isLessThan90Days =
    viewInsight.timerangeValue <
    RELATIVE_TIMERANGE_VALUE_FROM_KEYS.THREE_MONTHS;
  const width = viewInsight?.gridWidth === GridWidth.Small ? "40px" : "60px";

  return (
    <div
      className={cn(
        "relative flex h-full max-h-[209px] w-full max-w-full flex-col overflow-auto",
        isLessThan90Days ? "justify-end" : "justify-start",
      )}
    >
      <Flex
        data-testid="home-retention-cohort-table"
        justify="flex-end"
        direction="column"
        gridGap="1px"
        mr="14px"
        w="100%"
      >
        {reversedData?.map((cohort, index) => {
          return (
            <Flex gridGap="1px" key={index}>
              <div className="flex items-center">
                <div className="w-[50px]">
                  <p className="text-[10px] text-gray-600">
                    {moment(cohort.startDate).format("MMM DD")}
                  </p>
                </div>
                {cohort?.data?.map((value, i) => {
                  return (
                    <Tooltip key={i}>
                      <TooltipTrigger>
                        <Flex
                          onClick={() =>
                            onClickDataPoint({
                              intervalType: cohort.intervalType,
                              startDate: cohort.startDate,
                              data: cohort.data,
                              intervalStartDate: value.intervalStartDate,
                              retainedUsersCount: value.retainedUsersCount,
                              droppedUsersCount: value.droppedUsersCount,
                              retainedGroupsCount: value.retainedGroupsCount,
                              droppedGroupsCount: value.droppedGroupsCount,
                              retainedGroupIds: value.retainedGroupIds,
                              droppedGroupIds: value.droppedGroupIds,
                            })
                          }
                          _hover={{ border: `1px solid ${color[600]}` }}
                          bg={getRowColor({ retentionData: value.y, color })}
                          border="1px solid"
                          borderColor="white"
                          w={width}
                          minW={width}
                          borderRadius="4px"
                          p="2px"
                          px="5px"
                          alignItems="center"
                          justifyContent="center"
                          cursor="pointer"
                        >
                          <p
                            className="font-mono text-[10px] font-medium"
                            style={{
                              color: getTextColor({
                                retentionData: value.y,
                                color,
                              }),
                            }}
                          >
                            {value.y}%
                          </p>
                        </Flex>
                      </TooltipTrigger>
                      <TooltipContent>
                        <div className="flex flex-col gap-1 p-0.5">
                          <div className="flex items-center gap-1">
                            <div className="h-4 w-1 rounded-[2px] bg-gray-300" />
                            <p className="font-medium text-gray-600">
                              Joined on {cohort.id}
                            </p>
                          </div>
                          <div className="flex items-center gap-1">
                            <div
                              className="h-2 w-2 rounded-[2px]"
                              style={{
                                backgroundColor: getRowColor({
                                  retentionData: value.y,
                                  color,
                                }),
                              }}
                            />
                            <p>
                              {value.y}% after {i + 1} {cohort.intervalType}
                              {i === 0 ? "" : "s"}
                            </p>
                          </div>
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  );
                })}
              </div>
            </Flex>
          );
        })}
        <div className="sticky bottom-0 left-0 bg-white pt-1">
          <div className="ml-[50px] flex">
            {reversedData?.map((cohort, index) => {
              return (
                <Text
                  bg="white"
                  border="2px solid"
                  borderColor="white"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  minW={width}
                  maxW={width}
                  w="full"
                  key={index}
                  px={0}
                  fontSize="10px"
                  color="gray.700"
                >
                  {index + 1}
                </Text>
              );
            })}
          </div>
        </div>
      </Flex>
    </div>
  );
};
