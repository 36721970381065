import { useSelector } from "react-redux";

import { selector as appSelector } from "core/models/app";
import { IApp } from "core/types/App";

const DEFAULT_TIME_ZONE = "UTC";

export const useCurrentApp = (): IApp => {
  const { data: currentApp } = useSelector(appSelector);

  if (!currentApp) {
    throw new Error(
      "No current app found, use the app selector outside of the AuthenticatedApp component",
    );
  }

  return currentApp;
};

export const useTimeZone = () => {
  const app = useCurrentApp();
  return app.timeZone || DEFAULT_TIME_ZONE;
};
