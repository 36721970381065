import { Button } from "@chakra-ui/react";
import { DocumentIcon } from "@heroicons/react/24/outline";

export const DocsLink: React.FC = () => {
  return (
    <a href="https://june.so/docs" target="_blank" rel="noreferrer">
      <Button variant="outline" size="sm" p={0}>
        <div className="flex items-center gap-1 px-2">
          <DocumentIcon
            className="h-4 w-4 flex-none text-black"
            aria-hidden="true"
          />
          <span>June Docs</span>
        </div>
      </Button>
    </a>
  );
};
