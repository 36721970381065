import { createIcon } from "@chakra-ui/icons";

export const PublicIcon = createIcon({
  path: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 2.06689C11.1113 5.35156 11.1113 10.6489 8 13.9336"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99988 13.9336C4.88855 10.6489 4.88855 5.35156 7.99988 2.06689"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 2C11.3213 2 14 4.67867 14 8C14 11.3213 11.3213 14 8 14"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14C4.67867 14 2 11.3213 2 8C2 4.67867 4.67867 2 8 2"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33948 5.99984H13.6595"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33948 9.99984H13.6595"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  viewBox: "0 0 16 16",
});
