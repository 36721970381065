import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import { Button } from "Components/ui/button";
import {
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogHeader,
  DialogFooter,
} from "Components/ui/dialog";
import { useDeleteObjectMutation } from "core/models/objects/object";
import { IAppObject } from "core/types/AppObject";

interface DeleteObjectDialogProps {
  appObject: IAppObject;
}

export const DeleteObjectDialog = ({ appObject }: DeleteObjectDialogProps) => {
  const { appId, objectId } = useParams();

  const [deleteObject] = useDeleteObjectMutation();
  const navigate = useNavigate();

  function handleDelete() {
    deleteObject({
      appId: Number(appId),
      appObjectId: Number(appObject.id),
      objectId: objectId || "",
    });
    toast.success(`${appObject?.singularName} deleted`, {
      description: `It might take at least an hour before this ${appObject?.singularName} disappears from your objects list.`,
    });
    navigate(`/a/${appId}/people`);
  }
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Delete this {appObject?.singularName}?</DialogTitle>
        <DialogDescription className="text-sm">
          <p>
            This will delete this {appObject?.singularName} and all the group
            calls we received until now. If they send more group events, they
            will show up again.
          </p>
          <p className="mt-1">
            It might take at least an hour before this {appObject?.singularName}{" "}
            disappears from your objects list.
          </p>
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button
          variant="destructive"
          size="sm"
          type="submit"
          onClick={handleDelete}
        >
          Delete permanently
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};
