import { PAYWALLED } from "core/constants/features";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import useFlag from "core/hooks/useFlag";
import { useMauThreshold } from "core/hooks/useMauThreshold";

export const usePaywallModal = () => {
  const currentApp = useCurrentApp();
  const isPaywalled = useFlag(PAYWALLED);
  const {
    isApproachingLimit: isApproachingMauLimit,
    isAboveLimit,
    isTrialEnded,
  } = useMauThreshold();

  const isTrialFinished =
    currentApp.isTrialing && (currentApp.noCardTrialEnded || isTrialEnded);

  if (isPaywalled) return { isModalClosable: false, isTrialFinished };
  if (isTrialFinished) return { isModalClosable: false, isTrialFinished };
  if (isAboveLimit) return { isModalClosable: false, isTrialFinished };
  if (isApproachingMauLimit) return { isModalClosable: true, isTrialFinished };

  return { isModalClosable: true, isTrialFinished };
};
