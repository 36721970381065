import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

import { ITraitKeyResponse } from "core/models/traits";
import { TraitMenu } from "core/modules/audience/Filters/TraitMenu";

export const Trait: React.FC<{
  trait: string;
  onSelectTrait: (trait: ITraitKeyResponse) => void;
}> = ({ trait, onSelectTrait }) => {
  return (
    <Popover className="relative">
      <PopoverButton
        data-testid="audience-filter-trait-editor"
        className="flex w-full items-center rounded-md px-1 py-0.5 text-black hover:bg-gray-200"
      >
        <p data-testid="audience-trait-name" className="text-sm font-semibold">
          {trait}
        </p>
      </PopoverButton>
      <PopoverPanel className="flex w-full flex-col">
        {({ close }) => (
          <TraitMenu
            onSelectTrait={(t) => {
              onSelectTrait && onSelectTrait(t);
              close();
            }}
            isEventFilterDisabled
          />
        )}
      </PopoverPanel>
    </Popover>
  );
};
