import { format, formatDistanceToNow } from "date-fns";

export function formatNumber(value: number): string {
  return value.toLocaleString();
}

export function formatDate(
  value: string | Date,
  useRelative: boolean = false,
): string {
  const date = value instanceof Date ? value : new Date(value);
  if (isNaN(date.getTime())) {
    return value.toString();
  }
  return useRelative
    ? formatDistanceToNow(date, { addSuffix: true })
    : format(date, "do MMM, yyyy");
}

export function formatValue(
  value: unknown,
  relative: boolean = false,
): string | number {
  if (value === null || value === undefined) {
    return "";
  }

  if (typeof value === "number") {
    return formatNumber(value);
  }

  if (value instanceof Date) {
    return formatDate(value, relative);
  }

  const stringValue = String(value);
  // Try to parse as number
  const numberValue = Number(stringValue);
  if (!isNaN(numberValue)) {
    return Math.ceil(numberValue);
  }

  // Only try to parse as date if it looks like a date string
  const datePattern =
    /^(\d{4}[-/]\d{1,2}[-/]\d{1,2}|\d{1,2}[-/]\d{1,2}[-/]\d{4}|\d{4}[-]\d{2}[-]\d{2}T\d{2}:\d{2}:\d{2})/;
  if (datePattern.test(stringValue)) {
    // For DD/MM/YYYY format, convert to YYYY-MM-DD before parsing
    const parts = stringValue.split(/[-/]/);
    const dateString =
      parts.length === 3 && parts[0].length === 2
        ? `${parts[2]}-${parts[1]}-${parts[0]}` // Convert DD/MM/YYYY to YYYY-MM-DD
        : stringValue;

    const dateValue = new Date(dateString);
    if (!isNaN(dateValue.getTime())) {
      return formatDate(dateValue, relative);
    }
  }

  return stringValue;
}
