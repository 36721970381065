import React from "react";

import InsightCardBody from "core/components/InsightCard/Body";
import { InsightContainer } from "core/components/InsightCard/Container";
import { DescriptionAccordion } from "core/components/InsightCard/DescriptionAccordion";
import InsightCardEmptyState from "core/components/InsightCard/EmptyState";
import { Footer as InsightCardFooter } from "core/components/InsightCard/Footer";
import InsightCardHeader from "core/components/InsightCard/Header";

const InsightCard: React.FC<{
  children: ({
    Container,
    Header,
    Body,
    Footer,
  }: {
    Container: typeof InsightContainer;
    Header: typeof InsightCardHeader;
    Body: typeof InsightCardBody;
    EmptyState: typeof InsightCardEmptyState;
    Footer: typeof InsightCardFooter;
    DescriptionAccordion: typeof DescriptionAccordion;
  }) => React.ReactElement;
}> = ({ children }) => {
  return children({
    Container: InsightContainer,
    Header: InsightCardHeader,
    Body: InsightCardBody,
    EmptyState: InsightCardEmptyState,
    Footer: InsightCardFooter,
    DescriptionAccordion: DescriptionAccordion,
  });
};

export default InsightCard;
