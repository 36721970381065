import { useEffect } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";

import { JuneSpinner } from "@/core/components/JuneSpinner";
import { useAppObjects } from "@/core/hooks/useAppObjects";

export const Company = () => {
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const navigate = useNavigate();
  const { companyAppObject } = useAppObjects();
  const id = decodeURIComponent(encodedGroupId);

  useEffect(() => {
    if (appId && id && companyAppObject) {
      navigate(`/a/${appId}/objects/${companyAppObject.id}/object/${id}`);
    }
  }, [appId, id, navigate, companyAppObject]);

  if (!id) {
    return <Navigate to={`/a/${appId}/home`} />;
  }

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <JuneSpinner />
    </div>
  );
};
