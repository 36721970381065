import { IListConfig } from "core/components/List/List.types";
import { SearchBar } from "core/components/SearchBar";
import { Table } from "core/components/Table/People";
import { useTableTraitConfig } from "core/hooks/useTableTraitConfig";
import { useTraits } from "core/hooks/useTraits";
import { IAppObject } from "core/types/AppObject";
import { IAudienceFilters } from "core/types/Audience";
import { IContact } from "core/types/Contact";
import { IGroup } from "core/types/Group";
import { IPagy } from "core/types/Pagy";
import { cx } from "helpers/cx";
import { BulkTrait } from "modules/People/BulkTrait";
import { ExportCsv } from "modules/People/ExportCsv";

export const ObjectList: React.FC<{
  appObject: IAppObject;
  listConfig: IListConfig;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  entities: IContact[] | IGroup[] | undefined;
  isLoading: boolean;
  pagy: IPagy | undefined;
  currentPage: number;
  previousPage: () => void;
  nextPage: () => void;
  audienceFilters: IAudienceFilters;
  onSetAudienceFilters?: (audienceFilters: IAudienceFilters) => void;
  showSearchBar?: boolean;
  showAddTraitButton?: boolean;
  showExportCsvButton?: boolean;
  maxHeight?: string;
  matchedCount?: number;
  totalCount?: number;
  isLoadingCount?: boolean;
  skipPagination?: boolean;
  sortBy: string;
  sortOrder: string;
  setSortBy: (sortBy: string) => void;
  setSortOrder: (sortOrder: string) => void;
  marginTop?: string;
  className?: string;
}> = ({
  appObject,
  searchQuery,
  setSearchQuery,
  entities,
  isLoading,
  pagy,
  nextPage,
  currentPage,
  previousPage,
  audienceFilters,
  onSetAudienceFilters,
  showSearchBar = true,
  showAddTraitButton = true,
  showExportCsvButton = true,
  maxHeight = "max-h-[70vh]",
  matchedCount = 0,
  totalCount = 0,
  isLoadingCount = false,
  sortBy,
  sortOrder,
  setSortBy,
  setSortOrder,
  marginTop,
  className,
}) => {
  const {
    tableTraitConfigs,
    createTraitConfig,
    deleteTraitConfig,
    updateTraitConfigOrder,
  } = useTableTraitConfig(appObject.objectType);
  const { traits } = useTraits(appObject.objectType);

  function onClearFilters() {
    const newAudienceFilters = JSON.parse(JSON.stringify(audienceFilters));
    newAudienceFilters.filterGroups = [];
    onSetAudienceFilters && onSetAudienceFilters(newAudienceFilters);
  }

  return (
    <div
      data-id-groups-list-container
      className={cx(
        "flex h-full min-h-full w-full flex-col justify-start",
        maxHeight,
        className,
      )}
    >
      <div className="flex items-center gap-2">
        {showSearchBar && (
          <SearchBar
            my={3}
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            placeholder={`Search ${appObject.pluralName.toLocaleLowerCase()} by name...`}
          />
        )}
        {showAddTraitButton && (
          <BulkTrait
            appObject={appObject}
            audienceFilters={audienceFilters}
            searchQuery={searchQuery}
          />
        )}
        {showExportCsvButton && entities && (
          <ExportCsv
            appObject={appObject}
            audienceFilters={audienceFilters}
            searchQuery={searchQuery}
          />
        )}
      </div>
      <Table
        key={appObject.objectType}
        data={entities || []}
        appObject={appObject}
        tableTraitConfigs={tableTraitConfigs || []}
        traits={traits || []}
        createTraitConfig={createTraitConfig}
        deleteTraitConfig={deleteTraitConfig}
        updateTraitConfigOrder={updateTraitConfigOrder}
        hasFilters={!!audienceFilters.filterGroups.length}
        onClearFilters={onClearFilters}
        isLoading={isLoading}
        pagination={{
          currentPage,
          previousPage,
          nextPage,
        }}
        pagy={pagy}
        sortBy={sortBy}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        matchedCount={matchedCount}
        totalCount={totalCount}
        isLoadingCount={isLoadingCount}
        marginTop={marginTop}
      />
    </div>
  );
};
