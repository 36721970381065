import { Flex, StyleProps, Text } from "@chakra-ui/react";
import { CursorArrowRippleIcon } from "@heroicons/react/20/solid";
import React from "react";

import { COMPARISON_TO_LABEL } from "core/constants/eventFilterComponents";
import { COMPARISON_TO_NAME } from "core/constants/traitFilterComponents";
import { IEvent } from "core/types/Event";

interface IEventPillProps extends StyleProps {
  showProperties: boolean;
  showOccurrences?: boolean;
  event: IEvent;
}

export const EventPill: React.FC<IEventPillProps> = ({
  showProperties,
  showOccurrences = false,
  event,
  children,
  ...props
}) => {
  return (
    <Flex
      px={3}
      py={1}
      bg="gray.100"
      color="gray.900"
      border="none"
      borderRadius="md"
      mr={2}
      direction="row"
      align="center"
      gridGap={2}
      flexWrap="wrap"
      {...props}
    >
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        flexWrap="wrap"
        fontWeight="medium"
        fontSize="sm"
      >
        <CursorArrowRippleIcon style={{ height: "17px", top: "2px" }} />
        <Text
          ml={1}
          display="inline-block"
          color={props.color || "gray.900"}
          noOfLines={1}
          fontWeight={props.fontWeight || "md"}
        >
          {`${event.name || "Please select an event"} `}{" "}
        </Text>
        {showProperties &&
          event.properties.map((p, index) => (
            <Text as="span" key={index}>
              <Text ml={1} as="span">
                {index !== 0 && "and"} where{" "}
              </Text>
              <Text color={props.color || "gray.900"} as="b">
                {p?.body?.property}
              </Text>{" "}
              {p?.body?.comparisonType &&
                COMPARISON_TO_NAME[Number(p.body.comparisonType)]}{" "}
              <Text color={props.color || "gray.900"} as="b">
                {p?.body?.value}
              </Text>{" "}
            </Text>
          ))}
        {showOccurrences && event?.occurrences && (
          <Text as="span">
            <Text ml={1} as="span">
              {event?.properties?.length > 0 ? "and" : ""} is triggered{" "}
            </Text>
            <Text color={props.color || "gray.900"} as="b">
              {COMPARISON_TO_LABEL[event?.occurrences?.body?.comparisonType]}
            </Text>{" "}
            <Text color={props.color || "gray.900"} as="b">
              {event?.occurrences?.body?.times} times
            </Text>{" "}
          </Text>
        )}
      </Flex>
      {children}
    </Flex>
  );
};
