import { useState } from "react";

import InsightCard from "core/components/InsightCard/Index";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import {
  IntervalPicker,
  TIME_RANGE_PICKER_OPTIONS,
  UsersInterval,
} from "core/modules/reports/report-types/FeatureRelease/Users/FeatureUsersContainer";
import { ConversionLineChart } from "core/modules/reports/report-types/Funnel/ConversionLineChart";
import { isUserReportLevel } from "core/modules/reports/utils";
import { IReportInsight } from "core/types/Report";

const previewData = {
  data: [
    {
      humanizedDate: "7 Jun - 14 Jun",
      date: "2021-06-07",
      conversionPercentage: 70,
    },
    {
      humanizedDate: "14 Jun - 21 Jun",
      date: "2021-06-14",
      conversionPercentage: 80,
    },
    {
      humanizedDate: "21 Jun - 27 Jun",
      date: "2021-06-21",
      conversionPercentage: 100,
    },
  ],
};

const LineChartContainer: React.FC<IReportInsight> = ({
  report,
  sharingSecretToken = null,
  previewMode,
  sharingMode,
  config,
  screenshotMode,
  showYAxis,
}) => {
  const [usersInterval, setUsersInterval] = useState<UsersInterval>("weekly");
  const intervalType = Object.values(TIME_RANGE_PICKER_OPTIONS).find(
    (o) => o.value === usersInterval,
  )?.intervalType;
  const isUserReport = isUserReportLevel();
  const insight = config.insights.find(({ slug }: { slug: string }) =>
    !isUserReport
      ? slug === "company-conversion-over-time-test" ||
        slug === "company-conversion-over-time"
      : slug === "conversion-over-time-test" || slug === "conversion-over-time",
  );
  const isSetupValid = config.validateSetup(report.config);
  const hasBreakdown = Object.keys(report?.config?.breakdown || {}).length > 0;

  const {
    response: data,
    isLoading,
    isFetching,
    error,
    onRefreshInsight,
  } = useInsightDataFetching({
    report,
    insight,
    insightParams: {
      intervalType,
    },
    sharingSecretToken,
    previewMode,
    skip: !isSetupValid,
  });

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight?.slug || ""}>
          <Card.Header
            hasCaching={data?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight!}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={data?.cachedAt}
          >
            <IntervalPicker
              usersInterval={usersInterval}
              setUsersInterval={setUsersInterval}
            />
          </Card.Header>
          <Card.Body
            isLoading={!data || isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            {(data || previewMode) && (
              <ConversionLineChart
                data={previewMode ? previewData.data : data.data}
                showYAxis={showYAxis}
                hasBreakdown={hasBreakdown}
              />
            )}
          </Card.Body>
          {insight?.description && !screenshotMode && (
            <Card.Footer bg="white">
              <Card.DescriptionAccordion insight={insight} />
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
};

export default LineChartContainer;
