import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import React from "react";

import { IEventRow } from "@/core/models/events";
import { DEMO_APP_ID } from "core/constants/appIds";
import { SlackIconDisabled } from "core/design-system/components/Icon/Interface/SlackIcon";
import { SlackIconEnabled } from "core/design-system/components/Icon/Interface/SlackIconEnabled";
import { EventAlertEditorDrawer } from "modules/Events/List/EventAlertEditorDrawer";

interface IAlertsCellProps {
  props: IEventRow;
  mutate: () => void;
}

export const AlertsCell: React.FC<IAlertsCellProps> = ({
  props,
  mutate: reloadCell,
}) => {
  const { appId, alertEnabled } = props;
  const queryParams = new URLSearchParams(window.location.search);
  const defaultIsOpen = Boolean(queryParams.get("showSlack"));
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen });

  return (
    <Flex justify="flex-end">
      <Button
        bg="gray.50"
        color="gray.800"
        borderColor="gray.100"
        onClick={onOpen}
        leftIcon={
          alertEnabled ? (
            <SlackIconEnabled color="gray.700" h={5} w={5} />
          ) : (
            <SlackIconDisabled color="gray.700" h={5} w={5} />
          )
        }
        isDisabled={appId === DEMO_APP_ID}
      >
        {alertEnabled ? "Edit Slack alert" : "Setup Slack alert"}
      </Button>
      <EventAlertEditorDrawer
        isOpen={isOpen}
        onClose={onClose}
        event={props}
        reloadParent={reloadCell}
      />
    </Flex>
  );
};

export default AlertsCell;
