import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { DivideIcon, CalculatorIcon, Undo2Icon } from "lucide-react";

import { Button } from "@/Components/ui/button";
import {
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
} from "@/Components/ui/dropdown-menu";
import {
  Aggregation,
  AggregationOptions,
  AggregationToLabel,
} from "@/core/types/ViewInsight";

export const AggregationTypeToIcon = {
  [Aggregation.Avg]: DivideIcon,
  [Aggregation.Sum]: CalculatorIcon,
  [Aggregation.Last]: Undo2Icon,
};

export const AggregationSelector: React.FC<{
  aggregation: Aggregation;
  onSelect: (aggregation: Aggregation) => void;
}> = ({ aggregation, onSelect }) => {
  const Icon = AggregationTypeToIcon[aggregation];
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex items-center gap-1">
          <Button variant="secondary" size="sm" className="w-full">
            <div className="flex w-full flex-row items-center justify-between">
              <div className="flex items-center gap-1">
                <span>{<Icon className="h-4 w-4" />}</span>
                <span className="text-sm font-medium">
                  {AggregationToLabel[aggregation]}
                </span>
              </div>
              <ChevronDownIcon className="h-2 w-2" />
            </div>
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {AggregationOptions.map((aggregationValue) => {
          const Icon = AggregationTypeToIcon[aggregationValue];
          return (
            <DropdownMenuItem
              key={aggregationValue}
              onClick={() => {
                onSelect(aggregationValue as Aggregation);
              }}
              className="flex cursor-pointer items-center gap-2"
            >
              <div className="flex items-center gap-1">
                <span className="text-gray-600">
                  <Icon className="h-4 w-4" />
                </span>
                <span className="text-sm font-medium">
                  {AggregationToLabel[aggregationValue as Aggregation]}
                </span>
              </div>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
