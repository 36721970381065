import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import { getReportConfig } from "core/helpers/getReportConfig";
import { IReport } from "core/types/Report";

interface IReportTypeCellProps {
  props: IReport;
}

export const ReportTypeCell: React.FC<IReportTypeCellProps> = ({ props }) => {
  const { appId, id, reportType } = props;
  const reportTypeName = getReportConfig(reportType)!.title;

  return (
    <Link to={`/a/${appId}/report/${id}`}>
      <Flex align="center">
        <Flex align="flex-start" direction="column">
          <Text
            py={0.5}
            px={2}
            bg="gray.50"
            color="gray.600"
            fontSize="xs"
            fontWeight="medium"
            maxW="600px"
            borderRadius="md"
          >
            {reportTypeName}
          </Text>
        </Flex>
      </Flex>
    </Link>
  );
};
