import { createIcon } from "@chakra-ui/icons";
import React from "react";

const HelpCircle = createIcon({
  viewBox: "0 0 16 16",
  path: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.0003"
        cy="12"
        r="9.00375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.68499 9.6847C9.92201 8.66314 10.8524 7.95484 11.9002 7.99832C13.0729 7.93337 14.0782 8.82697 14.1512 9.99916C14.1512 11.5037 12.0003 12 12.0003 13.0004"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1253 15.7514C12.1253 15.8205 12.0693 15.8765 12.0002 15.8765C11.9312 15.8765 11.8752 15.8205 11.8752 15.7514C11.8752 15.6824 11.9312 15.6264 12.0002 15.6264"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9999 15.6266C12.069 15.6266 12.125 15.6826 12.125 15.7516"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});

export default HelpCircle;
