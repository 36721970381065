import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { FilterType } from "core/constants/report-setup";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useListConfig } from "core/hooks/useListConfig";
import usePagination from "core/hooks/usePagination";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useTableTraitConfig } from "core/hooks/useTableTraitConfig";
import { useLazyGetCompaniesQuery } from "core/models/companies";
import { useLazyGetContactsQuery } from "core/models/contacts";
import { useLazyGetGroupsQuery } from "core/models/groups";
import { getAppObject } from "core/modules/appObjects/utils";
import { getLevelParam } from "core/modules/reports/utils";
import {
  AppObjectSlugs,
  AppObjectType,
  IAppObject,
} from "core/types/AppObject";
import { IAudienceFilters } from "core/types/Audience";
import { IFilter } from "core/types/Filters.d";
import {
  DEFAULT_FILTER_GROUPS,
  parseFilterGroupsFromUrl,
  updateURL,
} from "helpers/filters";

export function removeFiltersByFilterType(
  audienceFilters: IAudienceFilters,
  filterType: FilterType,
) {
  return {
    ...audienceFilters,
    filterGroups: audienceFilters.filterGroups.map((filterGroup) => ({
      ...filterGroup,
      filters: filterGroup.filters.filter(
        (filter: IFilter) => filter.type !== filterType,
      ),
    })),
  };
}

export function usePeopleList({
  skipUrlHashing = false,
  appObjectParam,
  audienceFiltersParam,
  viewInsightId,
}: {
  skipUrlHashing?: boolean;
  appObjectParam?: IAppObject;
  audienceFiltersParam?: IAudienceFilters;
  viewInsightId?: number;
}) {
  const { appId } = useParams();
  const query = useQueryParam("query");
  const token = useQueryParam("token");
  const { appObjects, activeAppObject, setActiveAppObject } = useAppObjects();
  const { tableTraitConfigs } = useTableTraitConfig(
    activeAppObject?.objectType,
    viewInsightId,
  );

  const [sortBy, setSortBy] = useState("Last seen");
  const [sortOrder, setSortOrder] = useState("DESC");
  const level = getLevelParam();
  const appObject = appObjectParam
    ? appObjectParam
    : getAppObject(appObjects, level);
  const skipPagination = true;

  if (!activeAppObject && appObject) setActiveAppObject(appObject);

  const { listConfig } = useListConfig(activeAppObject?.objectType);

  const [audienceFilters, setAudienceFilters] = useState<IAudienceFilters>(
    audienceFiltersParam
      ? audienceFiltersParam
      : skipUrlHashing
        ? DEFAULT_FILTER_GROUPS
        : (parseFilterGroupsFromUrl() as IAudienceFilters),
  );

  const [searchQuery, setSearchQuery] = useState(query || "");
  const { currentPage, previousPage, nextPage, setPage } = usePagination({
    persist: !skipUrlHashing,
    searchQuery,
  });

  const [
    getContacts,
    { data: contactsData, isFetching: isContactsLoading, error: contactsError },
  ] = useLazyGetContactsQuery();

  const [
    getGroups,
    { data: groupsData, isFetching: isGroupsLoading, error: groupsError },
  ] = useLazyGetGroupsQuery();

  const [
    getCompanies,
    {
      data: companiesData,
      isFetching: isCompaniesLoading,
      error: companiesError,
    },
  ] = useLazyGetCompaniesQuery();

  const objectEntities = {
    [AppObjectType.User]: contactsData?.contacts,
    [AppObjectType.Group]: groupsData?.groups,
    [AppObjectType.Company]: companiesData?.companies,
  };

  const isLoadingObject = {
    [AppObjectType.User]: isContactsLoading,
    [AppObjectType.Group]: isGroupsLoading,
    [AppObjectType.Company]: isCompaniesLoading,
  };

  const objectErrors = {
    [AppObjectType.User]: contactsError,
    [AppObjectType.Group]: groupsError,
    [AppObjectType.Company]: companiesError,
  };

  const objectPagy = {
    [AppObjectType.User]: contactsData?.pagy,
    [AppObjectType.Group]: groupsData?.pagy,
    [AppObjectType.Company]: companiesData?.pagy,
  };

  const getParams = {
    appId: Number(appId),
    page: currentPage,
    audienceFilters,
    searchQuery,
    skipPagination,
    sortBy,
    sortOrder,
    token,
    viewInsightId,
  };

  function fetchPeople() {
    if (!activeAppObject) return;

    switch (activeAppObject.slug) {
      case AppObjectSlugs.User:
        getContacts({
          ...getParams,
          token: token || undefined,
          viewInsightId: viewInsightId || undefined,
        });
        break;
      case AppObjectSlugs.Group:
        getGroups({
          ...getParams,
          audienceFilters: removeFiltersByFilterType(
            audienceFilters,
            FilterType.CompanyFilter,
          ),
          token: token || undefined,
          viewInsightId: viewInsightId || undefined,
        });
        break;
      case AppObjectSlugs.Company:
        getCompanies({
          ...getParams,
          audienceFilters: removeFiltersByFilterType(
            audienceFilters,
            FilterType.GroupFilter,
          ),
        });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    fetchPeople();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appId,
    activeAppObject,
    audienceFilters,
    searchQuery,
    currentPage,
    sortBy,
    sortOrder,
    tableTraitConfigs?.length,
  ]);

  useEffect(() => {
    if (appObjects && !skipUrlHashing) {
      updateURL({
        appId,
        audienceFilters,
        searchQuery,
        skipUrlHashing,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, audienceFilters, searchQuery]);

  useEffect(() => {
    setSortBy("Last seen");
    setSortOrder("DESC");
  }, [activeAppObject]);

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder]);

  function onSetAudienceFilters(filters: IAudienceFilters) {
    setAudienceFilters(filters);
    setPage(1);
  }

  return {
    activeAppObject,
    onSetAudienceFilters,
    audienceFilters,
    searchQuery,
    setPage,
    setSearchQuery,
    listConfig,
    entities: activeAppObject ? objectEntities[activeAppObject.objectType] : [],
    pagy: activeAppObject ? objectPagy[activeAppObject.objectType] : undefined,
    isLoading: activeAppObject
      ? isLoadingObject[activeAppObject.objectType]
      : false,
    error: activeAppObject ? objectErrors[activeAppObject.objectType] : null,
    skipPagination,
    currentPage,
    previousPage,
    nextPage,
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
    refetchEntities: fetchPeople,
  };
}
