import { Box, Center, Text } from "@chakra-ui/react";

import CelebrateIcon from "core/design-system/components/Icon/Interface/CelebrateIcon";
import InstantIcon from "core/design-system/components/Icon/Interface/InstantIcon";
import KeepControlIcon from "core/design-system/components/Icon/Interface/KeepControlIcon";
import TeamIcon from "core/design-system/components/Icon/Interface/TeamIcon";

export const Features = () => (
  <div className="grid grid-cols-2 gap-4">
    <div className="flex flex-col gap-4 py-6">
      <div className="flex flex-row gap-4">
        <div>
          <InstantIcon color="primary" h={7} w={7} />
        </div>
        <div className="flex flex-col">
          <Text fontSize="sm" fontWeight="bold">
            Connect Stripe to your product data
          </Text>
          <Text fontSize="sm" fontWeight="sm" color="gray.600">
            Connect your revenue data to your product data to get a complete
            view of your customers.
          </Text>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div>
          <CelebrateIcon h={7} w={7} />
        </div>
        <div className="flex flex-col">
          <Text fontSize="sm" fontWeight="bold">
            See your revenue data in June
          </Text>
          <Text fontSize="sm" fontWeight="sm" color="gray.600">
            Automatically update data in June based on Stripe customers and
            payments.
          </Text>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div>
          <KeepControlIcon h={7} w={7} />
        </div>
        <div className="flex flex-col">
          <Text fontSize="sm" fontWeight="bold">
            Configure the way we sync your data
          </Text>
          <Text fontSize="sm" fontWeight="sm" color="gray.600">
            Map the Stripe data you want to send to your team in June so its
            always up to date.
          </Text>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div>
          <TeamIcon color="purple.500" h={7} w={7} />
        </div>
        <div className="flex flex-col">
          <Text fontSize="sm" fontWeight="bold">
            Everything you need to know about your customers
          </Text>
          <Text fontSize="sm" fontWeight="sm" color="gray.600">
            Get the revenue data you need to know about your customers without
            leaving June.
          </Text>
        </div>
      </div>
    </div>
    <div className="w-full">
      <Center w="100%">
        <Box
          borderRadius="lg"
          h="350px"
          w="100%"
          bgImage={`url('/stripe-asset.svg')`}
          bgRepeat="no-repeat"
          bgPosition="center"
          bgSize="contain"
        />
      </Center>
    </div>
  </div>
);
