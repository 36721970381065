import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import { ShareIcon } from "@heroicons/react/24/outline";
import { AnimatePresence, motion } from "framer-motion";
import { GlobeIcon } from "lucide-react";
import React from "react";
import { useParams } from "react-router-dom";

import { Spinner } from "@/Components/ui/spinner";
import { Button } from "Components/ui/button";
import { ClipboardButton } from "core/components/ClipboardButton";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { usePaywall } from "core/hooks/usePaywall";
import {
  IView,
  useGetViewTokenQuery,
  useShareViewMutation,
  useUnshareViewMutation,
} from "core/models/views";
import { Plan } from "core/types/App";

interface IShareable {
  shareable: IView;
  size?: "default" | "sm";
  showLabel?: boolean;
  shareableType: "View" | "ViewInsight";
}

export const Shareable: React.FC<IShareable> = ({
  shareable,
  size = "default",
  showLabel = true,
  shareableType,
}) => {
  const { appObjectId, objectId } = useParams<{
    appObjectId: string;
    objectId: string;
  }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: viewToken, isLoading: isLoadingViewToken } =
    useGetViewTokenQuery({
      appId: shareable.appId,
      viewId: shareable.id,
      groupId: objectId,
    });
  const { plan } = usePaywall();
  const [share] = useShareViewMutation();
  const [unshare] = useUnshareViewMutation();

  const onShare = () => {
    share({ appId: shareable.appId, viewId: shareable.id, groupId: objectId });
  };

  const onUnshare = () => {
    unshare({
      appId: shareable.appId,
      viewId: shareable.id,
      groupId: objectId,
    });
  };

  const isShared =
    viewToken &&
    typeof viewToken === "object" &&
    Object.keys(viewToken).length > 0;

  const BASE_URL = import.meta.env.VITE_APP_HOST || "http://localhost:3000";
  const publicUrl =
    isShared &&
    (objectId
      ? `${BASE_URL}/a/${shareable.appId}/objects/${appObjectId}/object/${objectId}/dashboard/${shareable.id}/${viewToken.token}`
      : `${BASE_URL}/a/${shareable.appId}/dashboard/${shareable.id}/${viewToken.token}`);

  return (
    <>
      <Button variant="ghost" onClick={onOpen} size={size}>
        <div className="flex items-center gap-1">
          {isLoadingViewToken ? (
            <Spinner size="small" />
          ) : (
            <>
              {isShared ? (
                <GlobeIcon className="size-4 text-gray-700" />
              ) : (
                <ShareIcon className="size-4 text-gray-700" />
              )}

              {showLabel && (isShared ? "Public" : "Share")}
            </>
          )}
        </div>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share {shareable.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="flex items-center justify-between pb-4">
              <div>
                <p className="text-sm font-medium">Enable public sharing</p>
                <p className="text-sm text-gray-600">
                  Publish and share link with anyone
                </p>
              </div>
              <Switch
                isChecked={isShared}
                isDisabled={plan !== Plan.Pro}
                colorScheme="purple"
                onChange={isShared ? onUnshare : onShare}
              />
            </div>
            <AnimatePresence>
              {isShared && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <p className="text-sm font-medium">Copy public link</p>
                  <p className="text-sm text-gray-600">
                    Anyone with the link can view this dashboard
                  </p>
                  <div className="mt-4 flex w-full items-center gap-1">
                    <p className="truncate text-sm text-gray-700 underline">
                      {publicUrl}
                    </p>
                    <ClipboardButton
                      value={publicUrl || ""}
                      showLabel={false}
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </ModalBody>
          <ModalFooter>
            <div className="flex w-full items-center justify-end gap-2">
              <PaywallPopover
                feature="public dashboards"
                redirect="home"
                plan={Plan.Pro}
              >
                <Button onClick={onClose}>
                  {plan !== Plan.Pro ? "Share" : "Done"}
                </Button>
              </PaywallPopover>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
