import { Button, Flex } from "@chakra-ui/react";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useDataExport } from "core/hooks/useDataExport";

export const DataExport: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const { startDataExport } = useDataExport({ appId });

  return (
    <div className="align-center flex w-full justify-between rounded-lg">
      <div className="flex flex-col">
        <div className="mb-1 text-sm font-semibold">Export workspace data</div>
        <div className="text-xs">
          Exports will be emailed to you in CSV format.
        </div>
      </div>
      <Flex>
        <Button onClick={startDataExport}>
          <div className="text-sm">Start new export</div>
        </Button>
      </Flex>
    </div>
  );
};
