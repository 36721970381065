import { Box } from "@chakra-ui/react";
import { FC } from "react";

interface IClickableTooltipContainerProps {
  children: React.ReactNode;
  onClick: () => void;
  isClickable: boolean;
}

export const ClickableTooltipContainer: FC<IClickableTooltipContainerProps> = ({
  children,
  onClick,
  isClickable,
}) => {
  return (
    <Box
      w="200px"
      bg="gray.900"
      px={3}
      py={2}
      borderRadius={5}
      cursor="pointer"
      pointerEvents={isClickable ? "auto" : "none"}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};
