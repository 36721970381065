import { Center, Flex, Grid, GridItem, Spinner, Text } from "@chakra-ui/react";
import { closestCenter, DndContext } from "@dnd-kit/core";
import {
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS, Transform } from "@dnd-kit/utilities";
import { SquaresPlusIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useQueryParam } from "@/core/hooks/useQueryParam";
import { useIsEditingView } from "core/contexts/EditingViewContext";
import { useEditDashboard } from "core/hooks/useEditDashboard";
import { usePaywall } from "core/hooks/usePaywall";
import { useReorderViewInsights } from "core/hooks/useReorderViewInsights";
import { ViewLocation } from "core/hooks/useViews";
import {
  IViewInsight,
  useGetViewInsightsQuery,
} from "core/models/viewInsights";
import { IView } from "core/models/views";
import { GRID_TO_PX } from "modules/ViewInsight/Builder/LayoutContainer";
import { AddAudienceModalContainer } from "modules/ViewInsight/Modal/AddAudienceModalContainer";
import { AudienceModalContainer } from "modules/ViewInsight/Modal/AudienceModalContainer";
import { InsightBuilderContainer } from "modules/ViewInsight/Modal/InsightBuilderContainer";
import { SQLEditorContainer } from "modules/ViewInsight/Modal/SQLEditorContainer";
import { ViewInsight } from "modules/ViewInsight/ViewInsight";

export const EmptyState = ({
  viewInsights,
  width,
}: {
  viewInsights?: IViewInsight[];
  width: string;
}) => {
  const { appId, viewId } = useParams();
  const navigate = useNavigate();
  const isEditing = useIsEditingView();

  const onCardClick = () => {
    navigate(`/a/${appId}/dashboard/${viewId}/graph/new`);
  };

  return (
    <>
      {(isEditing || viewInsights?.length === 0) && (
        <GridItem w={width} h="315px">
          <Flex
            w="full"
            h="full"
            border="2px dashed"
            borderRadius="lg"
            borderColor="purple.300"
            transition="all .1s ease-in-out"
            cursor="pointer"
            _hover={{
              borderColor: "purple.500",
              shadow: "sm",
              bg: "purple.50",
            }}
            onClick={onCardClick}
          >
            <Center w="full">
              <div className="flex flex-col items-center gap-2">
                <SquaresPlusIcon className="h-5 w-5 text-purple-500" />
                <Text
                  textAlign="center"
                  px={20}
                  fontSize="sm"
                  fontWeight="medium"
                  color="purple.500"
                >
                  {viewInsights?.length === 0
                    ? "Add your first insight"
                    : "Add insight"}
                </Text>
              </div>
            </Center>
          </Flex>
        </GridItem>
      )}
    </>
  );
};

const SortableInsight: React.FC<{
  viewInsight: IViewInsight;
  view: IView;
  location: ViewLocation;
  shouldBePaywalled?: boolean;
  paywallInsights?: boolean;
  onRefetch: () => Promise<unknown>;
}> = ({
  viewInsight,
  view,
  location,
  shouldBePaywalled,
  paywallInsights,
  onRefetch,
}) => {
  const { openedInsight, clearOpenedViewInsight } = useEditDashboard();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: viewInsight.id,
  });

  const displayWidth = viewInsight.gridWidth;
  const displayHeight = viewInsight.gridHeight;

  const style = {
    transform: transform
      ? CSS.Transform.toString({
          x: transform.x,
          y: transform.y,
          scaleX: 1,
          scaleY: 1,
        } as Transform)
      : "",
    transition,
    width: GRID_TO_PX[displayWidth],
    height: GRID_TO_PX[displayHeight],
    gridColumn: `span ${displayWidth}`,
    maxWidth: GRID_TO_PX[displayWidth],
    zIndex: isDragging ? 999 : "auto",
  };

  return (
    <GridItem
      ref={setNodeRef}
      style={style}
      w="full"
      h={GRID_TO_PX[displayHeight]}
      colSpan={displayWidth}
      rowSpan={viewInsight.gridHeight}
      {...attributes}
      {...listeners}
    >
      <ViewInsight
        view={view}
        location={location}
        viewInsight={viewInsight}
        isBlurred={
          shouldBePaywalled && paywallInsights && !viewInsight.isAutoGenerated
        }
        query={viewInsight.query}
        isAI={Boolean(viewInsight.queryId)}
      />
      <React.Suspense fallback={<div />}>
        {viewInsight.audience ? (
          <AudienceModalContainer
            onClose={clearOpenedViewInsight}
            viewInsight={viewInsight}
            isOpen={openedInsight?.viewInsight?.id === viewInsight.id}
          />
        ) : viewInsight.query ? (
          <SQLEditorContainer
            isOpen={openedInsight?.viewInsight?.id === viewInsight.id}
            onClose={clearOpenedViewInsight}
            viewInsight={viewInsight}
            onRefetch={onRefetch}
          />
        ) : (
          openedInsight?.viewInsight?.id === viewInsight.id && (
            <InsightBuilderContainer
              isOpen={openedInsight.viewInsight?.id === viewInsight.id}
              onClose={clearOpenedViewInsight}
              viewId={viewInsight.viewId}
              viewInsight={viewInsight}
              onRefetch={onRefetch}
            />
          )
        )}
      </React.Suspense>
    </GridItem>
  );
};

export const ViewContainer: React.FC<{
  location: ViewLocation;
  view: IView;
  viewId: number;
  columns?: number;
  width?: string;
  paywallInsights?: boolean;
  showAudienceModal: boolean;
  setShowAudienceModal: (show: boolean) => void;
}> = ({
  location,
  view,
  viewId,
  columns = 3,
  width = "315px",
  paywallInsights,
  showAudienceModal,
  setShowAudienceModal,
}) => {
  const { appId } = useParams();
  const groupId = useQueryParam("groupId");
  const {
    data: viewInsights,
    isLoading: isLoadingViewInsights,
    refetch,
  } = useGetViewInsightsQuery({
    appId: Number(appId),
    viewId,
    groupId,
  });
  function onRefetch() {
    return new Promise((resolve) => refetch().then(resolve));
  }
  const { reorderableInsights, handleDragEnd, sensors } =
    useReorderViewInsights({ viewInsights: viewInsights || [] });

  const { shouldBePaywalled } = usePaywall();

  if (isLoadingViewInsights) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-y-2">
        <Spinner emptyColor="gray.200" color="gray.500" thickness="3px" />
      </div>
    );
  }

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <Grid
          templateColumns={`repeat(${columns}, 1fr)`}
          autoRows="315px"
          autoColumns="515px"
          gridGap={5}
          position="relative"
        >
          <SortableContext
            items={reorderableInsights.map((i) => i.id)}
            strategy={rectSortingStrategy}
          >
            {reorderableInsights.map((viewInsight) => (
              <SortableInsight
                key={viewInsight.id}
                viewInsight={viewInsight}
                view={view}
                location={location}
                shouldBePaywalled={shouldBePaywalled}
                paywallInsights={paywallInsights}
                onRefetch={onRefetch}
              />
            ))}
          </SortableContext>
          <EmptyState viewInsights={viewInsights} width={width} />
        </Grid>
      </DndContext>

      <React.Suspense fallback={<div className="h-10">Loading...</div>}>
        {showAudienceModal && (
          <AddAudienceModalContainer
            onClose={() => {
              setShowAudienceModal(false);
              onRefetch();
            }}
          />
        )}
      </React.Suspense>
    </>
  );
};
