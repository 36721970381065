import { TimeIcon } from "@chakra-ui/icons";
import { Box, ComponentDefaultProps, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";

import ContactAvatar from "core/components/Avatars/ContactAvatar";
import { TRAIT_LEVEL } from "core/constants/traits";
import { getCompanyName } from "core/helpers/companyName";
import { getContactEmail } from "core/helpers/contactEmail";
import { IContact } from "core/types/Contact";
import { IGroup } from "core/types/Group";
import { CompanyLogo } from "modules/Groups/Group/CompanyLogo";

const GRADIENT_HEIGHT = 20;

type ITraitPreviewProps = {
  level: TRAIT_LEVEL;
  user?: IContact;
  group?: IGroup;
  company?: IGroup;
  entityParam?: IContact | IGroup;
};
export const PreviewHeader: React.FC<
  ITraitPreviewProps & ComponentDefaultProps
> = ({ level, user, group, company, entityParam, children, ...styleProps }) => {
  const entity: IContact | IGroup | undefined = entityParam
    ? entityParam
    : level === TRAIT_LEVEL.USER
      ? user
      : level === TRAIT_LEVEL.GROUP
        ? group
        : company;
  const { appId } = useParams();

  return (
    <Flex direction="column" boxShadow="sm" borderRadius="lg">
      <Box
        w="100%"
        h={`${GRADIENT_HEIGHT}px`}
        borderRadius="lg"
        bgGradient="linear(to-r, purple.200 20%, purple.100 55%, purple.50 100%)"
        borderBottomRadius={0}
      />
      <Flex
        bg="white"
        w="100%"
        direction="column"
        borderRadius="lg"
        borderTopRadius={0}
        {...styleProps}
      >
        <Flex justify={"space-between"} align="flex-start">
          <Flex alignItems="center" mb={6} px={6} pt={8}>
            <Flex gridGap={4} align="center">
              <Flex>
                {level === TRAIT_LEVEL.USER ? (
                  <ContactAvatar size="md" contact={entity as IContact} />
                ) : (
                  <CompanyLogo
                    groupId={entity?.id}
                    appId={Number(appId)}
                    border="4px solid"
                    borderColor="white"
                    boxShadow="sm"
                  />
                )}
              </Flex>
              <Flex direction="column" maxW="full">
                <Flex maxW="full">
                  <p className="text-md w-[240px] truncate font-medium">
                    {level === TRAIT_LEVEL.USER
                      ? getContactEmail(entity)
                      : level === TRAIT_LEVEL.GROUP
                        ? entity && getCompanyName(entity)
                        : entity?.id}
                  </p>
                </Flex>
                {entity?.firstSeenAt && (
                  <Flex>
                    <Text color="gray.600" fontSize="sm">
                      Joined{" "}
                      {moment.utc(entity?.firstSeenAt).format("DD MMM, YYYY")}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" px={6} pb={6} gridGap={1}>
          <Flex align="center" gridGap={2}>
            <Flex>
              <TimeIcon mx={0.5} fontSize="sm" color="gray.600" />
            </Flex>
            <Flex>
              <Text fontSize="sm" color="gray.700">
                Last seen{" "}
                {moment
                  .utc(entity?.lastSeenAt)
                  .format("DD MMM, YYYY [at] hh:mm a")}
              </Text>
            </Flex>
          </Flex>
          <div className="px-0.5 ">{children}</div>
        </Flex>
      </Flex>
    </Flex>
  );
};
