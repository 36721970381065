import {
  Button,
  Center,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
} from "@chakra-ui/react";
import { FaceFrownIcon } from "@heroicons/react/24/outline";

import { Body, Container, Header } from "@/core/components/ViewInsight";
import AnimatedToggle from "core/components/AnimatedToggle";
import { SimpleAreaChart } from "core/components/ViewInsight/Graphs/SimpleAreaChart";
import { SimpleBarChart } from "core/components/ViewInsight/Graphs/SimpleBarChart";
import { SimpleTable } from "core/components/ViewInsight/Graphs/SimpleTable";
import { TwEditable } from "core/design-system/components/TwEditable";
import { IColorPalette } from "core/design-system/constants/theme/colors";
import { COLOR_PALETTES, GraphTypeToIcon } from "core/hooks/useEditViewInsight";
import { GraphType, Size } from "core/types/ViewInsight";
import { formatValue } from "lib/format";
import { ColorPicker } from "modules/Labels/ColorPicker";
import { Sizes } from "modules/ViewInsight/Builder/LayoutContainer";

export const PreviewContainer: React.FC<{
  data: any;
  size: Size;
  setSize: (size: Size) => void;
  graphType: GraphType;
  setGraphType: (graphType: GraphType) => void;
  colorPalette: IColorPalette;
  setColorPalette: (colorPalette: IColorPalette) => void;
  xAxis: string;
  setXAxis: (xAxis: string) => void;
  yAxis: string;
  setYAxis: (yAxis: string) => void;
  title: string;
  setTitle: (title: string) => void;
  isLoadingData: boolean;
}> = ({
  data,
  size,
  setSize,
  graphType,
  setGraphType,
  colorPalette,
  setColorPalette,
  xAxis,
  setXAxis,
  yAxis,
  setYAxis,
  title,
  setTitle,
  isLoadingData,
}) => {
  function transformGraphData() {
    if (!data?.result?.data) return [];

    return data?.result?.data?.map((point: { [key: string]: string }) => {
      return {
        x: formatValue(point[xAxis]),
        y: formatValue(point[yAxis]),
      };
    });
  }

  return (
    <div className="relative flex h-full w-full flex-grow flex-col">
      <div className="flex w-full flex-grow flex-col overflow-auto bg-gradient-to-b from-gray-50 to-gray-100 p-5">
        <div className="flex w-full items-center justify-between">
          {graphType !== GraphType.Table ? (
            <div
              key={data?.result?.columns?.length}
              className="flex items-center gap-2"
            >
              <Menu>
                <MenuButton
                  as={Button}
                  isDisabled={data?.result?.columns?.length === 0}
                >
                  <div className="flex items-center gap-1">
                    <p className="text-sm text-gray-500">X Axis:</p>
                    {xAxis}
                  </div>
                </MenuButton>
                <MenuList minW="140px">
                  {data?.result?.columns.map(
                    (column: { name: string; type: string }) => {
                      return (
                        <MenuItem onClick={() => setXAxis(column.name)}>
                          <div className="flex items-center gap-1">
                            {column.name}
                          </div>
                        </MenuItem>
                      );
                    },
                  )}
                </MenuList>
              </Menu>
              <Menu>
                <MenuButton
                  as={Button}
                  isDisabled={data?.result?.columns?.length === 0}
                >
                  <div className="flex items-center gap-1">
                    <p className="text-sm text-gray-500">Y Axis:</p>
                    {yAxis}
                  </div>
                </MenuButton>
                <MenuList minW="140px">
                  {data?.result?.columns.map(
                    (column: { name: string; type: string }) => {
                      return (
                        <MenuItem onClick={() => setYAxis(column.name)}>
                          <div className="flex items-center gap-1">
                            {column.name}
                          </div>
                        </MenuItem>
                      );
                    },
                  )}
                </MenuList>
              </Menu>
            </div>
          ) : (
            <div />
          )}
          <div className="flex items-center gap-2">
            <div>
              <ColorPicker
                color={colorPalette}
                setColor={setColorPalette}
                colors={COLOR_PALETTES}
                isDisabled={[GraphType.Table].includes(graphType)}
                shade={300}
                disabledTooltip="This graph type does not support colors"
              />
            </div>
            <div>
              <Menu matchWidth>
                <MenuButton as={Button}>
                  <div className="flex items-center gap-1 capitalize">
                    {GraphTypeToIcon[graphType].solid}
                    {graphType.split(`_`).join(` `)}
                  </div>
                </MenuButton>
                <MenuList minW="90px">
                  {[GraphType.Bar, GraphType.Area, GraphType.Table].map(
                    (graphType) => {
                      return (
                        <MenuItem
                          textTransform="capitalize"
                          onClick={() => setGraphType(graphType)}
                        >
                          <div className="flex items-center gap-1">
                            {GraphTypeToIcon[graphType].solid}
                            {graphType.split(`_`).join(` `)}
                          </div>
                        </MenuItem>
                      );
                    },
                  )}
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Center h="full" w="full">
            <div
              style={{ height: Sizes[size].height, width: Sizes[size].width }}
            >
              <Container>
                <Header>
                  <div className="flex items-center justify-between gap-2">
                    <TwEditable
                      defaultValue={title}
                      onSubmit={setTitle}
                      placeholder={"Untitled"}
                    />
                    <div />
                  </div>
                </Header>
                <Body>
                  {!isLoadingData && !data?.result?.data && (
                    <div className="ml-2.5 flex h-full w-full flex-col items-center justify-center gap-y-1 text-gray-600">
                      <FaceFrownIcon className="h-4 w-full" />
                      <p className="text-xs">No data found</p>
                    </div>
                  )}
                  {isLoadingData ? (
                    <div className="h-full w-full pl-4">
                      <Skeleton h="full" w="full" borderRadius="lg" />
                    </div>
                  ) : (
                    <>
                      {data?.result?.data && data?.result?.data.length > 0 && (
                        <>
                          {graphType === GraphType.Table && (
                            <SimpleTable
                              columns={data?.result?.columns}
                              data={data?.result?.data}
                              size={size}
                            />
                          )}
                          {graphType === GraphType.Area && (
                            <SimpleAreaChart
                              data={transformGraphData()}
                              color={colorPalette}
                              size={size}
                            />
                          )}
                          {graphType === GraphType.Bar && (
                            <SimpleBarChart
                              data={transformGraphData()}
                              color={colorPalette}
                              size={size}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </Body>
              </Container>
            </div>
          </Center>
          <div>
            <AnimatedToggle
              items={[
                {
                  label: "Small",
                  value: "small",
                },
                {
                  label: "Medium",
                  value: "medium",
                },
                {
                  label: "Large",
                  value: "large",
                },
              ]}
              value={size}
              containerProps={{
                bg: "gray.200",
              }}
              onChange={(val, e) => {
                e.preventDefault();
                setSize(val as Size);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
