import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import { SnakeCasedProperties } from "type-fest/source/snake-cased-properties";

import { baseQuery } from "core/initializers/rtk";
import { IEnrichedDomain } from "core/types/EnrichedDomain";

export const enrichedDomainApi = createApi({
  baseQuery,
  reducerPath: "enrichedDomainApi",
  tagTypes: ["EnrichedDomain"],
  endpoints: (builder) => ({
    getEnrichedDomain: builder.query<
      IEnrichedDomain,
      { appId: string | number; domain: string }
    >({
      query: ({ appId, domain }) => ({
        url: `/enriched_domains/${appId}`,
        params: humps.decamelizeKeys({ appId, domain }),
      }),
      providesTags: ["EnrichedDomain"],
      transformResponse: (response: SnakeCasedProperties<IEnrichedDomain>) =>
        humps.camelizeKeys(response) as IEnrichedDomain,
    }),
    createEnrichedDomain: builder.mutation<
      IEnrichedDomain,
      { appId: string | number; domain: string }
    >({
      query: ({ appId, domain }) => ({
        url: `/enriched_domains/${appId}`,
        method: "PUT",
        params: humps.decamelizeKeys({ appId, domain }),
      }),
      invalidatesTags: ["EnrichedDomain"],
      transformResponse: (response: SnakeCasedProperties<IEnrichedDomain>) =>
        humps.camelizeKeys(response) as IEnrichedDomain,
    }),
  }),
});

export const { useGetEnrichedDomainQuery, useCreateEnrichedDomainMutation } =
  enrichedDomainApi;
