import {
  Combobox,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";

import {
  COMPARISON_TO_NAME,
  TRAIT_COMPARISON_TYPES,
  TraitComparisonType,
} from "core/constants/traitFilterComponents";
import { Command, CommandList } from "core/design-system/components/Command";
import { DataTypeString } from "core/models/traits";
import { ComparisonType } from "core/types/Filters.d";
import { ITraitWithConfig } from "core/types/Trait";

export const Comparison: React.FC<{
  comparisonType: ComparisonType;
  onSelectComparisonType: (comparisonType: ComparisonType) => void;
  trait?: ITraitWithConfig;
}> = ({ comparisonType, onSelectComparisonType, trait }) => {
  const comparisonTypes =
    trait?.dataType === DataTypeString.UNASSIGNED
      ? TRAIT_COMPARISON_TYPES
      : TRAIT_COMPARISON_TYPES.filter((c) =>
          c.dataTypes.includes(trait?.dataType as DataTypeString),
        );
  return (
    <Popover className="relative">
      <PopoverButton
        data-testid="audience-filter-trait-comparison-editor"
        className="flex w-full min-w-full items-center truncate rounded-md px-1 py-0.5 text-sm text-black hover:bg-gray-200"
      >
        <p data-testid={`audience-trait-selected-comparison`}>
          {COMPARISON_TO_NAME[comparisonType]}
        </p>
      </PopoverButton>
      <PopoverPanel className="h-full w-full">
        {({ close }) => (
          <div data-testid={`audience-trait-comparison-list`}>
            <Command className="w-[200px]">
              <CommandList className="h-full">
                {comparisonTypes.map((c: TraitComparisonType) => (
                  <Combobox.Option
                    className="w-full"
                    value={c.value}
                    onClick={() => {
                      onSelectComparisonType(c.value);
                      close();
                    }}
                  >
                    <div
                      data-testid={`audience-trait-comparison-${c.value}`}
                      className="flex w-full cursor-pointer items-center justify-between gap-1 rounded-md px-2 py-1.5 text-gray-900 hover:bg-gray-100"
                    >
                      <p className="text-sm font-medium" key={c.name}>
                        {c.name}
                      </p>
                      {c.value === comparisonType && (
                        <CheckIcon className="h-4" />
                      )}
                    </div>
                  </Combobox.Option>
                ))}
              </CommandList>
            </Command>
          </div>
        )}
      </PopoverPanel>
    </Popover>
  );
};
