import {
  SquaresPlusIcon,
  CommandLineIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";

import { Button } from "@/Components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/Components/ui/dropdown-menu";
import { AUDIENCE_VIEW_INSIGHTS } from "core/constants/features";
import useFlag from "core/hooks/useFlag";
import { cn } from "lib/utils";

export const AddMenu: React.FC<{
  onAddInsightClick: () => void;
  onAddSQLClick: () => void;
  onAddAudienceClick?: () => void;
  className?: string;
  size?: "default" | "sm";
}> = ({
  onAddInsightClick,
  onAddSQLClick,
  onAddAudienceClick,
  className,
  size = "default",
}) => {
  const { groupId } = useParams();
  const isAudienceViewInsightsEnabled = useFlag(AUDIENCE_VIEW_INSIGHTS);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className={cn("text-gray-800", className)}
          size={size || "sm"}
        >
          <div className="flex items-center gap-2">
            <SquaresPlusIcon className="h-3 w-3 text-gray-900" />
            <span className="text-xs">Add</span>
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-[200px]">
        <DropdownMenuItem
          onClick={onAddInsightClick}
          className="cursor-pointer gap-2 text-xs"
        >
          <PresentationChartLineIcon className="h-3 w-3 text-gray-600" />
          Add graph
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={onAddSQLClick}
          className="cursor-pointer gap-2 text-xs"
        >
          <CommandLineIcon className="h-3 w-3 text-gray-600" />
          Add SQL
        </DropdownMenuItem>
        {isAudienceViewInsightsEnabled && !groupId && onAddAudienceClick && (
          <DropdownMenuItem
            onClick={onAddAudienceClick}
            className="cursor-pointer gap-2 text-xs"
          >
            <UserGroupIcon className="h-3 w-3 text-gray-600" />
            <div className="flex items-center gap-2">
              Add audience
              <span className="inline-flex items-center rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">
                New
              </span>
            </div>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
