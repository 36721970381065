import { FRESHPAINT, RUDDERSTACK, SEGMENT } from "core/constants/apiKeys";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { ConnectSegmentAction } from "modules/Developers/Overview/ConnectSegmentAction";
import { SelectDataSource } from "modules/Developers/Overview/SelectDataSource";
import { SetupItem } from "modules/Developers/Overview/SetupItem";

export const CompleteSetup: React.FC = () => {
  const { onboardingChecklist, isLoading } = useOnboardingChecklist({
    pollingEnabled: true,
  });
  return (
    <div className="flex flex-col">
      <SetupItem
        isLoading={isLoading}
        isComplete={true}
        title="Select data source"
        ItemAction={SelectDataSource}
      />
      {onboardingChecklist &&
        [RUDDERSTACK, FRESHPAINT].includes(onboardingChecklist.dataSource) && (
          <SetupItem
            isLoading={isLoading}
            isComplete={onboardingChecklist?.connectedDataSource}
            title="Connect data source"
            itemKey={"connectedDataSource"}
          />
        )}
      {onboardingChecklist && onboardingChecklist.dataSource === SEGMENT && (
        <SetupItem
          isLoading={isLoading}
          isComplete={onboardingChecklist?.connectedDataSource}
          title="Connect data source"
          ItemAction={ConnectSegmentAction}
        />
      )}
      <SetupItem
        isLoading={isLoading}
        isComplete={onboardingChecklist?.isIdentifyingUsers}
        title="Identify users"
        itemKey={"isIdentifyingUsers"}
      />
      <SetupItem
        isLoading={isLoading}
        isComplete={onboardingChecklist?.isSendingEvents}
        title="Track your first event"
        itemKey={"isSendingEvents"}
      />
      <SetupItem
        isLoading={isLoading}
        isComplete={onboardingChecklist?.isIdentifyingGroups}
        title="Identify workspaces (groups)"
        itemKey={"isIdentifyingGroups"}
        tooltip={`June automatically creates companies based on users. If you'd like to identify different groups, e.g. workspaces, you can use the group call. Read the docs for more info.`}
        isOptional
      />
    </div>
  );
};
