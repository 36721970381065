import { useEffect, useState } from "react";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  useDeleteSettingsMutation,
  useGetSettingsQuery,
  useReinstallMutation,
  useUpdateSettingsMutation,
} from "core/models/hubspot";

const SCOPE_VERSION_V1 = 1;
export const SCOPE_VERSION_V2 = 2;

export interface IHubspotSetting {
  hubspotId?: string;
  autoUpdatesEnabled: boolean;
  autoCreatesEnabled: boolean;
  autoAssociatesEnabled: boolean;
  companyIdentifier: string;
  hubspotCompanyIdentifier: string;
  lastSyncedGroupsAt?: string;
  lastSyncedIdentifiesAt?: string;
  isSyncingUsers?: boolean;
  isSyncingWorkspaces?: boolean;
  isSyncingCompanies?: boolean;
  scopeVersion: typeof SCOPE_VERSION_V1 | typeof SCOPE_VERSION_V2;
}

interface IUpdateSettingsParams {
  autoUpdatesEnabled?: boolean;
  autoCreatesEnabled?: boolean;
  autoAssociatesEnabled?: boolean;
  companyIdentifier?: string;
  hubspotCompanyIdentifier?: string;
  isSyncingUsers?: boolean;
  isSyncingWorkspaces?: boolean;
  isSyncingCompanies?: boolean;
}

interface IUseHubspotSettingsResponse {
  setting: IHubspotSetting;
  isLoadingSetting: boolean;
  isDeletingSettings: boolean;
  isUpdatingSettings: boolean;
  isReinstalling: boolean;
  hasHubspotInstalled: boolean;
  onDeleteSettings: () => void;
  onReinstall: () => void;
  onUpdateSettings: ({
    autoUpdatesEnabled,
    autoCreatesEnabled,
    companyIdentifier,
    hubspotCompanyIdentifier,
  }: IUpdateSettingsParams) => void;
}

export const useHubspotSettings = (): IUseHubspotSettingsResponse => {
  const currentApp = useCurrentApp();
  const [setting, setSetting] = useState<IHubspotSetting>({
    hubspotId: undefined,
    autoUpdatesEnabled: false,
    autoCreatesEnabled: false,
    autoAssociatesEnabled: false,
    hubspotCompanyIdentifier: "group_id",
    companyIdentifier: "name",
    isSyncingUsers: true,
    isSyncingWorkspaces: true,
    isSyncingCompanies: false,
    scopeVersion: SCOPE_VERSION_V2,
  });
  const { data: dataSetting, isLoading: isLoadingSetting } =
    useGetSettingsQuery({
      appId: currentApp.id,
    });
  const [
    deleteSettings,
    { data: deletedSettings, isLoading: isDeletingSettings },
  ] = useDeleteSettingsMutation();

  const [reinstall, { isLoading: isReinstalling }] = useReinstallMutation();

  const [
    updateSettings,
    { data: updatedSettings, isLoading: isUpdatingSettings },
  ] = useUpdateSettingsMutation();

  function onDeleteSettings() {
    deleteSettings({ appId: currentApp.id });
  }

  function onReinstall() {
    reinstall({ appId: currentApp.id });
    window.location.replace(
      `${import.meta.env.VITE_API_HOST}/auth/hubspot/install`,
    );
  }

  function onUpdateSettings(updatedSettings: Partial<IUpdateSettingsParams>) {
    updateSettings({
      appId: currentApp.id,
      ...setting,
      ...updatedSettings,
    });
  }

  useEffect(() => {
    if (dataSetting?.hubspotId) {
      setSetting(dataSetting);
    }

    if (deletedSettings !== undefined) {
      setSetting(deletedSettings);
    }

    if (updatedSettings !== undefined) {
      setSetting(updatedSettings);
    }
  }, [dataSetting, setSetting, deletedSettings, updatedSettings]);

  return {
    setting,
    isLoadingSetting,
    isDeletingSettings,
    isUpdatingSettings,
    isReinstalling,
    hasHubspotInstalled: Boolean(setting?.hubspotId),
    onDeleteSettings,
    onUpdateSettings,
    onReinstall,
  };
};
