import { Box, Flex, Text } from "@chakra-ui/react";

import {
  generateColorForTraitValueWithIndex,
  isTraitNotAvailable,
} from "core/design-system/charts/Breakdown/utils";
import { IBreakdownTooltip } from "core/hooks/useFunnelBreakdown";

export const BreakdownTooltip: React.FC<{
  otherCount?: number;
  otherPercentage?: string;
  breakdownTooltip?: IBreakdownTooltip[];
}> = ({ otherCount, otherPercentage, breakdownTooltip }) => {
  return (
    <Flex direction="column" p={1} gap={2}>
      <Text color="white" fontWeight="bold" mb={2}>
        {breakdownTooltip?.[0]?.name}
      </Text>
      {otherCount && (
        <Flex align="flex-start">
          <Flex pt={1.5} mr={2} align="flex-start">
            <Box h="10px" w="10px" borderRadius="full" bg="gray.400" />
          </Flex>
          <Flex align="flex-start">
            <Text fontSize="sm" color="white">
              <Text as="span">
                Not available{" "}
                <Text mt={1} fontSize="xs" fontWeight="medium" color="gray.500">
                  {otherPercentage}% converted
                </Text>
                <Text fontSize="xs" fontWeight="medium" color="gray.500">
                  {otherCount} users
                </Text>
              </Text>
            </Text>
          </Flex>
        </Flex>
      )}
      {breakdownTooltip?.map((tooltip: IBreakdownTooltip, i: number) => {
        if (isTraitNotAvailable(tooltip.value)) return null;
        return (
          <Flex align="flex-start">
            <Flex pt={1.5} mr={2} align="flex-start">
              <Box
                h="10px"
                w="10px"
                borderRadius="full"
                bg={generateColorForTraitValueWithIndex(
                  tooltip.value,
                  breakdownTooltip.length - i - 1,
                )}
              />
            </Flex>
            <Flex>
              <Text fontSize="sm" color="white">
                <Text as="span">
                  {tooltip.value}{" "}
                  <Text
                    mt={1}
                    fontSize="xs"
                    fontWeight="medium"
                    color="gray.500"
                  >
                    {tooltip.traitConversionPercentage}% converted
                  </Text>
                  <Text fontSize="xs" fontWeight="medium" color="gray.500">
                    {tooltip.traitCount} users
                  </Text>
                </Text>
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
