import { IContact } from "core/types/Contact";
import { IAudienceFilter } from "core/types/Filters.d";
import { IGroup } from "core/types/Group";
import { IPagy } from "core/types/Pagy";

export enum JoinOperatorValue {
  AND = 0,
  OR = 1,
}

export interface IAudience {
  id?: number;
  reportId?: number;
  name?: string;
  description?: string;
  filterGroups: IAudienceFilter[];
  joinOperator: JoinOperatorValue;
  contactCount?: number;
  groupCount?: number;
  isSavedAudience: boolean;
  pinned: boolean;
  appObject?: IAppObject;
  updatedAt?: string;
  createdAt?: string;
}

export interface IAudienceFilters {
  filterGroups: IAudienceFilter[];
  joinOperator: JoinOperatorValue;
}

export interface IAudienceContacts {
  id: number;
  filterGroups: IAudienceFilter[];
  contacts?: IContact[];
  pagy: IPagy;
}

export interface IAudienceGroups {
  id: number;
  filterGroups: IAudienceFilter[];
  groups?: IGroup[];
  pagy: IPagy;
}
