import { HStack, useRadioGroup } from "@chakra-ui/react";

import { TemplateCategory } from "core/constants/templates";
import { TemplateRadio } from "modules/Templates/TemplateRadio";

interface ITemplateCategoriesProps {
  setSelectedCategory: (categorie: TemplateCategory) => void;
  selectedCategory?: TemplateCategory;
}

export const TemplateCategories: React.FC<ITemplateCategoriesProps> = ({
  setSelectedCategory,
  selectedCategory,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "category",
    defaultValue: selectedCategory,
    value: selectedCategory,
    onChange: (value) => setSelectedCategory(value as TemplateCategory),
  });

  const categories = getRootProps();
  return (
    <HStack {...categories} mb={4} data-testid="template-categories">
      {[
        TemplateCategory.ProductUsage,
        TemplateCategory.Activate,
        TemplateCategory.Retain,
      ].map((category) => {
        const radio = getRadioProps({
          value: category,
          checked: selectedCategory === category,
          isChecked: selectedCategory === category,
        });
        return (
          <TemplateRadio key={category} {...radio}>
            {category}
          </TemplateRadio>
        );
      })}
    </HStack>
  );
};
