import { EnvelopeIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Navigate, useParams } from "react-router-dom";

import * as Integration from "@/modules/Settings/Integrations/Integration";
import { EnabledAlerts as EnabledEmailAlerts } from "modules/Settings/Email/EnabledAlerts";

const CONTENT: Integration.IntegrationMetadata = {
  title: "Email",
  description: "Get digests for the companies",
  logoComponent: <EnvelopeIcon />,
};

export const Email: React.FC = () => {
  const { appId } = useParams();

  if (window.location.pathname.includes("settings/integrations/email")) {
    return <Navigate to={`/a/${appId}/integrations/email`} />;
  }

  return (
    <>
      <Integration.Container isExpanded>
        <Integration.Header metadata={CONTENT} />
        <Integration.Body>
          <Integration.Section
            title={"Email settings"}
            description={"Configure how do you send alerts to Email"}
          >
            <EnabledEmailAlerts />
          </Integration.Section>
        </Integration.Body>
      </Integration.Container>
    </>
  );
};
