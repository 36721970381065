import { Flex, Tooltip } from "@chakra-ui/react";
import { format } from "date-fns";

import {
  Goal,
  ProgressionState,
} from "core/modules/reports/Achievements/types";
import {
  getGoalName,
  getTimestamps,
} from "core/modules/reports/Achievements/utils";

export const GoalTooltip: React.FC<{
  goal: Goal;
  unit: string;
  state?: ProgressionState;
  goalLevel?: number;
}> = ({ goal, unit, state, goalLevel, children }) => {
  if (state && goalLevel) {
    const timestamps = getTimestamps(state, goal.track);
    const timestamp = timestamps?.length > 0 ? timestamps[goalLevel] : null;
    return (
      <Tooltip
        hasArrow
        label={
          <Flex direction="column" gridGap={2}>
            {timestamp && (
              <p className="text-gray-600">
                On {format(new Date(timestamp), "do MMM, yyyy h:mm a")}
              </p>
            )}
            <p>{getGoalName(goal, unit)}</p>
          </Flex>
        }
      >
        {children}
      </Tooltip>
    );
  }

  return (
    <Tooltip hasArrow label={getGoalName(goal, unit)}>
      {children}
    </Tooltip>
  );
};

export default GoalTooltip;
