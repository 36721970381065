import { ComponentDefaultProps } from "@chakra-ui/react";
import { useState } from "react";

import { FIRST_EVENT } from "core/constants/events";
import {
  COMPANY_TRAIT,
  EVENT,
  GROUP_TRAIT,
  USER_TRAIT,
} from "core/constants/filters";
import {
  FilterType,
  MORE_THAN,
  TimerangeType,
} from "core/constants/report-setup";
import { INTERVAL_TYPE_NUMBERS } from "core/constants/timerange";
import {
  AFTER,
  CONTAINS,
  GREATER_THAN,
  HAS_ANY_VALUE,
  IS,
} from "core/constants/traitFilterComponents";
import { DataTypeString } from "core/models/traits";
import { AddFilterButton } from "core/modules/audience/Filters/AddFilterButton";
import { Filter } from "core/modules/audience/Filters/Filter";
import { IAudienceFilters } from "core/types/Audience";
import { IFilter, IFilterGroup, JoinOperatorValue } from "core/types/Filters.d";

interface IFiltersProps extends ComponentDefaultProps {
  audienceFilters: IAudienceFilters;
  setAudienceFilters: (newAudienceFilters: IAudienceFilters) => void;
  filterGroupIndex: number;
  view?: string;
}

export const COMPARISON_TYPE_BY_DATA_TYPE = {
  [DataTypeString.NUMBER]: GREATER_THAN,
  [DataTypeString.STRING]: CONTAINS,
  [DataTypeString.BOOLEAN]: IS,
  [DataTypeString.DATE]: AFTER,
  [DataTypeString.UNASSIGNED]: HAS_ANY_VALUE,
  [DataTypeString.OPTIONS]: IS,
  [DataTypeString.WORKSPACE_MEMBER]: IS,
};

const ONE_DAY_IN_MS = 86400000;

export const DEFAULT_VALUES_FOR_DATA_TYPE = {
  [DataTypeString.NUMBER]: 0,
  [DataTypeString.STRING]: "",
  [DataTypeString.BOOLEAN]: "true",
  [DataTypeString.DATE]: new Date(Date.now() - ONE_DAY_IN_MS * 7)
    .toISOString()
    .split("T")[0],
  [DataTypeString.UNASSIGNED]: "",
  [DataTypeString.OPTIONS]: "",
  [DataTypeString.WORKSPACE_MEMBER]: "-1",
};

export const emptyUserTraitFilter = {
  type: USER_TRAIT,
  body: {
    trait: "",
    value: "",
    comparisonType: IS,
  },
};

export const emptySmartEventFilter = {
  type: EVENT,
  body: {
    ...FIRST_EVENT,
    times: 0,
    interval: 7,
    intervalType: INTERVAL_TYPE_NUMBERS.day,
    comparisonType: MORE_THAN,
    timerangeType: TimerangeType.RELATIVE,
    trait: "",
    value: "",
  },
};

const emptyGroupTraitFilter = {
  type: GROUP_TRAIT,
  body: {
    trait: "",
    value: "",
    comparisonType: IS,
  },
};

const emptyCompanyTraitFilter = {
  type: COMPANY_TRAIT,
  body: {
    trait: "",
    value: "",
    comparisonType: IS,
  },
};

export const EmptyFilters = {
  [FilterType.UserTrait]: emptyUserTraitFilter,
  [FilterType.GroupFilter]: emptyGroupTraitFilter,
  [FilterType.EventFilter]: emptySmartEventFilter,
  [FilterType.CompanyFilter]: emptyCompanyTraitFilter,
};

const Filters: React.FC<IFiltersProps> = ({
  audienceFilters,
  setAudienceFilters,
  filterGroupIndex,
  children,
  view,
}) => {
  const [highlight, setHighlight] = useState(false);
  const filters =
    audienceFilters.filterGroups[filterGroupIndex]?.filters?.map(
      (filter: IFilter, index: number) => ({
        ...filter,
        id: index,
      }),
    ) || [];
  const isUsingEventFilters = audienceFilters.filterGroups[
    filterGroupIndex
  ]?.filters?.some((filter: IFilter) => filter.type === EVENT);

  const onAddEventFilter = (filterType: FilterType) => {
    const emptyFilter = EmptyFilters[filterType];
    // TODO: @aliceclv the problem here comes from UUID generated as a string
    const nextId = filters.length > 0 ? filters[filters.length - 1].id! + 1 : 0;
    const newFilters = [...filters, { ...emptyFilter, id: nextId }];

    const newAudienceFilters = JSON.parse(JSON.stringify(audienceFilters));
    newAudienceFilters.filterGroups[filterGroupIndex].filters = newFilters;
    setAudienceFilters(newAudienceFilters);
  };

  const onAddTraitFilter = (
    filterType: FilterType,
    trait: string,
    dataType: DataTypeString,
  ) => {
    const emptyFilter = EmptyFilters[filterType];
    // TODO: @aliceclv the problem here comes from UUID generated as a string
    const nextId = filters.length > 0 ? filters[filters.length - 1].id! + 1 : 0;
    const emptyTraitFilter = {
      ...emptyFilter,
      body: {
        ...emptyFilter.body,
        trait,
        comparisonType: COMPARISON_TYPE_BY_DATA_TYPE[dataType],
        value: DEFAULT_VALUES_FOR_DATA_TYPE[dataType].toString(),
      },
      id: nextId,
    };
    const newFilters = filters.concat(emptyTraitFilter);

    const newAudienceFilters = JSON.parse(JSON.stringify(audienceFilters));
    newAudienceFilters.filterGroups[filterGroupIndex].filters = newFilters;
    setAudienceFilters(newAudienceFilters);
  };

  const onRemoveFilter = (filter: IFilter) => {
    const newFilters = filters.filter((f: IFilter) => f.id !== filter.id);

    let newAudienceFilters = JSON.parse(JSON.stringify(audienceFilters));
    if (newFilters.length === 0) {
      newAudienceFilters.filterGroups = newAudienceFilters.filterGroups.filter(
        (_fg: IFilterGroup, index: number) => index !== filterGroupIndex,
      );
    } else {
      newAudienceFilters.filterGroups[filterGroupIndex].filters = newFilters;
    }
    setAudienceFilters(newAudienceFilters);
  };

  const onChangeJoinOperator = (joinOperatorValue: JoinOperatorValue) => {
    const newAudienceFilters = JSON.parse(JSON.stringify(audienceFilters));
    newAudienceFilters.filterGroups[filterGroupIndex].joinOperator =
      joinOperatorValue;
    setAudienceFilters(newAudienceFilters);
  };

  return (
    <div key={JSON.stringify(filters)} data-testid="audience-filters">
      <div className="flex w-full flex-wrap items-center gap-y-2">
        {filters.map((filter: IFilter, index: number) => {
          return (
            <Filter
              key={`filter-${filter.id}`}
              filter={filter}
              filterIndex={index}
              filterGroupIndex={filterGroupIndex}
              audienceFilters={audienceFilters}
              setAudienceFilters={setAudienceFilters}
              onRemoveFilter={onRemoveFilter}
              onChangeJoinOperator={onChangeJoinOperator}
              highlight={highlight}
              setHighlight={setHighlight}
              isLast={index === filters.length - 1}
              isFirst={index === 0}
              view={view}
            />
          );
        })}
        <AddFilterButton
          onAddEventFilter={onAddEventFilter}
          onAddTraitFilter={onAddTraitFilter}
          isEventFilterDisabled={isUsingEventFilters}
        />
        {children}
      </div>
    </div>
  );
};

export default Filters;
