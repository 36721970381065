import { Image } from "@chakra-ui/react";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { updateLogo } from "core/models/app";

export const WorkspaceLogo = () => {
  const currentApp = useCurrentApp();
  const dispatch = useDispatch();

  const handleUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newLogo = event.target.files[0];
      dispatch(updateLogo({ appId: currentApp.id, logo: newLogo }));
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="mb-1 text-sm font-semibold">Workspace logo</div>
      <div className="flex gap-6">
        <label
          htmlFor="files"
          className="relative cursor-pointer overflow-hidden rounded border border-gray-300"
        >
          <div className="aspect-square rounded object-cover">
            {currentApp.logo ? (
              <Image
                className="aspect-square h-16 w-16"
                src={currentApp.logo}
              />
            ) : (
              <div className="flex h-16 w-16 items-center justify-center text-gray-400">
                <PhotoIcon className="h-8 w-8" />
              </div>
            )}
          </div>
          <div className="absolute inset-0 flex items-center justify-center bg-slate-900 bg-opacity-50 opacity-0 transition-opacity duration-100 hover:opacity-100">
            <span className="text-xs font-medium text-white">Upload</span>
          </div>
        </label>
        <input
          name="logo"
          type="file"
          id="files"
          accept=".png, .jpg, .jpeg"
          onChange={handleUpdate}
          className="hidden"
        />
      </div>
      <p className="text-xs leading-5 text-gray-500">
        Pick a logo for your workspace. Recommended size is 256x256px.
      </p>
    </div>
  );
};
