import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useQueryParam } from "core/hooks/useQueryParam";
import {
  useCreateTableTraitConfigMutation,
  useDeleteTableTraitConfigMutation,
  useGetTableTraitConfigsQuery,
  useUpdateTableTraitConfigOrderMutation,
} from "core/models/tableTraitConfig";
import { AppObjectSlugs, AppObjectType } from "core/types/AppObject";
import { ITableTraitConfig } from "core/types/TableTraitConfig";

export enum TableType {
  People = "people",
  Group = "group",
  Company = "company",
  Audience = "audience",
}

export const useTableTraitConfig = (
  objectType?: AppObjectType,
  trait?: string,
  viewInsightId?: number,
): any => {
  const { appId, audienceId } = useParams();
  const token = useQueryParam("token") || undefined;
  const location = useLocation();
  const isGroup = location.pathname.includes("group");
  const isCompany = location.pathname.includes("company");
  const isAudience = location.pathname.includes("audience");

  function getTableType() {
    if (isAudience) return TableType.Audience;
    if (isGroup) return TableType.Group;
    if (isCompany) return TableType.Company;
    return TableType.People;
  }

  const { data, refetch } = useGetTableTraitConfigsQuery({
    appId: Number(appId),
    tableType: getTableType(),
    audienceId: audienceId ? Number(audienceId) : undefined,
    token,
    viewInsightId,
  });

  const [createTableTraitConfig] = useCreateTableTraitConfigMutation();
  const [deleteTableTraitConfig] = useDeleteTableTraitConfigMutation();
  const [updateTableTraitConfigOrder] =
    useUpdateTableTraitConfigOrderMutation();

  function createTraitConfig({
    trait,
    objectType,
  }: {
    trait: string;
    objectType: string;
  }) {
    if (!appId) return;
    createTableTraitConfig({
      appId,
      trait,
      objectType,
      tableType: getTableType(),
      audienceId: audienceId ? Number(audienceId) : undefined,
    })
      .unwrap()
      .then(() => {
        refetch();
        window.analytics.track(
          "added_trait_column",
          {
            appId,
            trait: trait,
          },
          { context: { groupId: appId } },
        );
      });
  }

  function deleteTraitConfig({ id }: { id: string }) {
    if (!appId) return;
    deleteTableTraitConfig({ id, appId })
      .unwrap()
      .then(() => {
        refetch();
        window.analytics.track(
          "removed_trait_column",
          {
            appId,
          },
          { context: { groupId: appId } },
        );
      });
  }

  function updateTraitConfigOrder({
    order,
  }: {
    order: { id: string; order: number }[];
  }) {
    if (!appId) return;
    updateTableTraitConfigOrder({ order, appId })
      .unwrap()
      .then(() => {
        refetch();
        window.analytics.track(
          "update_trait_column_order",
          {
            appId,
          },
          { context: { groupId: appId } },
        );
      });
  }

  const userTableTraitConfigs = useMemo(
    () =>
      data?.tableTraitConfigs.filter(
        (ttc: ITableTraitConfig) => ttc.appObject.slug === AppObjectSlugs.User,
      ) || [],
    [data],
  );

  const groupTableTraitConfigs = useMemo(
    () =>
      data?.tableTraitConfigs.filter(
        (ttc: ITableTraitConfig) => ttc.appObject.slug === AppObjectSlugs.Group,
      ) || [],
    [data],
  );

  const companyTableTraitConfigs = useMemo(
    () =>
      data?.tableTraitConfigs.filter(
        (ttc: ITableTraitConfig) =>
          ttc.appObject.slug === AppObjectSlugs.Company,
      ) || [],
    [data],
  );

  const traitConfigsByObjectType = {
    [AppObjectType.User]: userTableTraitConfigs,
    [AppObjectType.Company]: companyTableTraitConfigs,
    [AppObjectType.Group]: groupTableTraitConfigs,
  };

  return {
    tableTraitConfig: data?.tableTraitConfigs?.find(
      (ttc) => ttc.trait === trait,
    ),
    userTableTraitConfigs,
    groupTableTraitConfigs,
    companyTableTraitConfigs,
    createTraitConfig,
    deleteTraitConfig,
    updateTraitConfigOrder,
    tableTraitConfigs: objectType && traitConfigsByObjectType[objectType],
  } as any;
};
