import { PresentationChartLineIcon } from "@heroicons/react/24/outline";

import { useGetViewQuery } from "@/core/models/views";
import { IFavourite } from "core/models/favourites";
import { LoadingItem } from "modules/Navigation/Sidebar/Favourites/LoadingItem";

export const ViewItem: React.FC<{ favourite: IFavourite }> = ({
  favourite,
}) => {
  const { data: view, isLoading } = useGetViewQuery({
    appId: favourite.appId,
    id: Number(favourite.objectId),
  });

  if (isLoading) return <LoadingItem />;

  return (
    <div className="flex flex-row items-center gap-2">
      <PresentationChartLineIcon className="h-4 w-4 text-gray-600" />
      <p className="truncate text-[13px]">{view?.name || ""}</p>
    </div>
  );
};
