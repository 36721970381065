import { UseDisclosureReturn } from "@chakra-ui/react";
import React from "react";

import { IViewInsight } from "@/core/models/viewInsights";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { Drilldown } from "core/components/Drilldown";
import { Error } from "core/components/Error";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { IDrilldownContact } from "core/types/Contact";
import { IReport } from "core/types/Report";

interface IActiveUsersListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  report: IReport;
  key: string;
  activeUsersCount: number;
  date: string;
  hour?: string;
  humanizedDate: string;
  intervalType: number;
  showNew?: boolean;
  viewInsight?: IViewInsight;
}

interface IActiveUser extends IDrilldownContact {
  newUser: string;
}

export const ActiveUsersList: React.FC<IActiveUsersListProps> = ({
  report,
  intervalType,
  activeUsersCount,
  showNew,
  humanizedDate,
  date,
  hour,
  isOpen,
  onClose,
  viewInsight,
}) => {
  const getInsightParams = () => {
    const baseParams = { intervalType, showNew, date };

    return typeof hour !== "undefined"
      ? { ...baseParams, intervalType: 3, hour }
      : baseParams;
  };

  const {
    contacts,
    isLoading,
    error,
    hasMoreContacts,
    loadMore,
    searchQuery,
    onSearch,
  } = useContactDrilldown({
    report,
    insightType: 6,
    skip: !isOpen,
    insightParams: getInsightParams(),
    viewInsight,
  });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: 6,
    insightParams: getInsightParams(),
    count: activeUsersCount,
  });

  if (error) {
    return <Error />;
  }

  const activeUsers = contacts as IActiveUser[];

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              title={`There are ${activeUsersCount} active users for ${humanizedDate}`}
              isLoading={isLoading}
            />
            <Drilldown.DownloadButton
              isDisabled={activeUsersCount <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
            <Drilldown.Search
              placeholder="Search your users by email address..."
              searchQuery={searchQuery}
              onSearch={onSearch}
              disabled={activeUsersCount <= 0}
            />
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              items={activeUsers}
              itemRenderer={({ item }) => (
                <UserListSingleContact
                  key={item.userId}
                  id={item.userId}
                  traits={item.traits}
                  isNewUser={item.newUser}
                />
              )}
              hasMore={hasMoreContacts}
              isLoading={isLoading}
              loadNext={loadMore}
              emptyStateText={"No active users"}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
