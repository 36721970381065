import { Button, ComponentDefaultProps, Text, Tooltip } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

import { EmojiPickerContent } from "@/core/components/EmojiPicker/Content";

interface IEmojiPicker extends ComponentDefaultProps {
  emoji: string;
  handleEmojiClick: (emoji: string) => void;
}

export const EmojiPicker: React.FC<IEmojiPicker> = ({
  emoji,
  handleEmojiClick,
  buttonProps,
  textProps,
}) => {
  return (
    <Popover>
      {({ close }) => (
        <>
          <PopoverButton as="div">
            <Button
              _hover={{ bg: "gray.200" }}
              size="xs"
              py={1}
              variant="ghost"
              {...buttonProps}
            >
              <Tooltip
                label="Change emoji"
                placement="top"
                fontSize="xs"
                bg="white"
                border="1px solid"
                borderColor="gray.200"
                textColor="gray.700"
                shadow="none"
              >
                <Text fontSize="sm" {...textProps}>
                  {emoji}
                </Text>
              </Tooltip>
            </Button>
          </PopoverButton>
          <PopoverPanel
            className="absolute z-[10000] rounded-b-lg"
            portal={true}
            anchor={{ to: "right end" }}
            focus={true}
          >
            <EmojiPickerContent
              onSelect={(emoji) => handleEmojiClick(emoji)}
              onClose={close}
            />
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};
