import { Button, Tooltip } from "@chakra-ui/react";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Typed from "typed.js";

import { useLazyGetSummaryQuery } from "core/models/contacts";
import { cx } from "helpers/cx";

interface IContactAISummary {
  contactId: string;
}

export const ContactAISummary: React.FC<IContactAISummary> = ({
  contactId,
}) => {
  const { appId } = useParams();
  const [getSummary, { data, isLoading, isFetching }] =
    useLazyGetSummaryQuery();
  const health = useRef(null);
  const summary = useRef(null);
  const isChurning = (data?.data?.split(".")[0] || "").includes(
    "at risk of churn" || "",
  );

  function fetchSummary() {
    getSummary({ appId: Number(appId), contactId, refresh: false });
  }

  function refreshSummary() {
    getSummary({ appId: Number(appId), contactId, refresh: true });
  }

  useEffect(() => {
    if (!data) return;

    if (health.current) {
      new Typed(health.current, {
        strings: [`${isChurning ? "🚨" : "💪"} ${data.data.split(".")[0]}`],
        typeSpeed: 10,
        showCursor: false,
      });
    }

    if (summary.current) {
      new Typed(summary.current, {
        strings: [data.data.split(".").slice(1).join(".")],
        typeSpeed: 10,
        showCursor: false,
        startDelay: 1000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="border-b border-gray-200 px-4 py-4">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <span className="inline-flex items-center rounded-lg bg-purple-50 px-2 py-1 text-xs font-medium text-purple-500">
            <SparklesIcon className="mr-1 h-3 text-purple-500" /> AI
          </span>
          <p className="text-md font-medium ">Usage summary</p>
        </div>
        {data?.data && (
          <div>
            <Tooltip label="Refresh" shouldWrapChildren>
              <Button
                colorScheme="purple"
                variant="ghost"
                onClick={refreshSummary}
              >
                <ArrowPathIcon className="h-4" />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      {(isLoading || isFetching) && (
        <p className="typing-effect mt-2 text-sm">
          Looking at the user's activity...
        </p>
      )}
      {!(isLoading || isFetching) && data?.data && (
        <div className="mt-2">
          <p
            ref={health}
            className={cx(
              "mb-2 inline-block rounded-xl px-3 py-1 text-sm font-medium",
              isChurning ? "text-red-700" : "text-green-700",
              isChurning ? "bg-red-100" : "bg-green-100",
            )}
          ></p>
          <p ref={summary} className="text-sm text-gray-700"></p>
        </div>
      )}
      {!(isLoading || isFetching) && !data && (
        <div className="mt-2">
          <p className="text-sm text-gray-700">
            Find out whether this user is engaged or at risk of churn
          </p>
          <Button mt={4} w="full" size="xs" py={4} onClick={fetchSummary}>
            Get usage summary
          </Button>
        </div>
      )}
    </div>
  );
};
