import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { App } from "@/App";
import * as serviceWorker from "@/serviceWorker";
import { store } from "core/initializers/store";

import "@/App.css";
import "@june-so/ui/style.css";

const SENTRY_DSN = import.meta.env.PROD
  ? "https://fb15137311b1494cac7ff691baaaa4ab@o400829.ingest.sentry.io/5297725"
  : "";

const RELEASE = process.env?.VITE_VERCEL_GIT_COMMIT_SHA
  ? "june-client-" + import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA
  : "no-release";

// Checkout https://docs.sentry.io/clients/javascript/config/
Sentry.init({
  dsn: SENTRY_DSN,
  release: RELEASE,
  ignoreErrors: [
    "Event missing",
    "ResizeObserver loop completed with undelivered notifications",
  ],
  beforeSend(event) {
    if (event.exception && event.exception.values) {
      for (const exception of event.exception.values) {
        if (exception.stacktrace && exception.stacktrace.frames) {
          for (const frame of exception.stacktrace.frames) {
            if (
              frame.filename &&
              frame.filename.startsWith("chrome-extension://")
            ) {
              return null;
            }
          }
        }
      }
    }

    return event;
  },
});

const container = document.getElementById("root");

if (!container) {
  throw new Error("No element with id 'root' found");
}

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <div className="min-h-full bg-gray-50">
      <App />
    </div>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
