import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { useCurrentApp } from "core/hooks/useCurrentApp";

export const BookACallItem: React.FC = () => {
  const { id, bookedACall } = useCurrentApp();
  const navigate = useNavigate();
  const show = localStorage.getItem("dismiss_book_a_call_banner") === "true";

  if (bookedACall || !show) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: 5, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.05 }}
      >
        <div className="mx-4 mb-2 flex items-center justify-between gap-x-6 rounded-lg bg-purple-500 px-3 py-2.5 shadow-xl shadow-purple-500/30">
          <div className="flex items-center gap-2">
            <div className="relative">
              <img
                src="/enzo.jpg"
                alt="Enzo"
                className="h-5 w-5 rounded-full"
              />
              <div className="absolute -right-0.5 -top-0.5 h-2 w-2 rounded-full bg-green-400 ring-1 ring-purple-500" />
            </div>
            <p
              className="text-sm font-medium text-purple-50"
              onClick={() =>
                navigate(`/a/${id}/onboarding/schedule?book-a-call=true`)
              }
            >
              Need help?
              <p className="ml-1 inline cursor-pointer font-semibold text-white">
                Book a call with me
              </p>
            </p>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
