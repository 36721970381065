import { Divider } from "@chakra-ui/react";

import DefaultHeader from "modules/Pages/DefaultHeader";
import { DangerZone } from "modules/Settings/DangerZone";
import { DataManagement } from "modules/Settings/DataManagement";
import MobileWidgets from "modules/Settings/MobileWidgets";
import { UserSettings } from "modules/Settings/UserSettings";
import { WorkspaceSettings } from "modules/Settings/WorkspaceSettings";

const SECTION = {
  name: "General",
  title: "General",
  description: "Manage your workspace settings",
};

export const GeneralSettings: React.FC = () => {
  return (
    <div className="flex w-[985px] flex-col gap-5">
      <DefaultHeader w="inherit" section={SECTION} />
      <WorkspaceSettings />
      <Divider />
      <UserSettings />
      <Divider />
      <MobileWidgets />
      <Divider />
      <DataManagement />
      <Divider />
      <DangerZone />
    </div>
  );
};
