import { Drawer } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { ICohortUsers } from "core/components/CohortTable/CohortTable.types";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { Drilldown } from "core/components/Drilldown";
import { Error } from "core/components/Error";
import { ToggleButton } from "core/components/Toggle";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { RetentionDrilldownHeader } from "core/modules/reports/report-types/Retention/RetentionDrilldownHeader";

export const CohortUsers: React.FC<ICohortUsers> = ({
  report,
  isOpen,
  onClose,
  intervalType,
  cohortStartDate,
  cohortIntervalStartDate,
  retainedUsersCount,
  droppedUsersCount,
  viewInsight,
}) => {
  const [retained, setRetained] = useState(true);
  const [{ cohortEndDate, cohortIntervalEndDate, intervalNumber }, setDates] =
    useState({
      cohortEndDate: "",
      cohortIntervalEndDate: "",
      intervalNumber: 0,
    });
  const dates = {
    cohortStartDate,
    cohortEndDate,
    cohortIntervalStartDate,
    cohortIntervalEndDate,
  };

  const {
    contacts,
    metadata,
    isLoading,
    error,
    hasMoreContacts,
    loadMore,
    searchQuery,
    onSearch,
    setPage,
  } = useContactDrilldown({
    report,
    insightType: 2,
    skip: !isOpen || !cohortStartDate || !cohortIntervalStartDate,
    insightParams: {
      cohortStartDate,
      cohortIntervalStartDate,
      contactType: retained ? "retained" : "dropped",
    },
    viewInsight,
  });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: 2,
    insightParams: {
      cohortStartDate,
      cohortIntervalStartDate,
      contactType: retained ? "retained" : "dropped",
    },
    count: retained ? retainedUsersCount : droppedUsersCount,
  });

  const onDrilldownClose = () => {
    onClose();
    // Reset retained value
    setRetained(true);
    setPage(0);
  };

  const onToggle = (retained: boolean) => {
    setRetained(retained);
    setPage(0);
  };

  useEffect(() => {
    if (metadata) {
      const interval = metadata[intervalType];
      setDates({
        cohortEndDate: metadata.cohortEndDate,
        cohortIntervalEndDate: metadata.cohortIntervalEndDate,
        intervalNumber: interval,
      });
    }
  }, [metadata, intervalType, retained]);

  if (error) {
    return (
      <Drawer
        trapFocus={false}
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
      >
        <Error />
      </Drawer>
    );
  }

  const isSubcohort = intervalNumber && intervalNumber > 0;

  return (
    <Drilldown isOpen={isOpen} onClose={onDrilldownClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <RetentionDrilldownHeader
              {...dates}
              intervalType={intervalType}
              intervalNumber={intervalNumber}
              count={retained ? retainedUsersCount : droppedUsersCount}
              retained={retained}
              isLoading={isLoading}
            />
            <Drilldown.DownloadButton
              isDisabled={contacts.length <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />

            {isSubcohort ? (
              <Drilldown.Toggle>
                <ToggleButton
                  isDisabled={false}
                  isSelected={retained}
                  onToggle={() => onToggle(true)}
                >
                  Retained
                </ToggleButton>
                <ToggleButton
                  isDisabled={false}
                  isSelected={!retained}
                  onToggle={() => onToggle(false)}
                >
                  Dropped
                </ToggleButton>
              </Drilldown.Toggle>
            ) : null}

            <Drilldown.Search
              searchQuery={searchQuery}
              placeholder="Search your users by email address..."
              onSearch={onSearch}
              disabled={contacts.length <= 0}
            />
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              items={contacts}
              hasMore={hasMoreContacts}
              itemRenderer={({ item }) => (
                <UserListSingleContact
                  key={item.userId}
                  id={item.userId}
                  traits={item.traits}
                />
              )}
              loadNext={loadMore}
              isLoading={isLoading}
              emptyStateText={`No users ${
                retained ? "retained" : "dropped"
              } for this ${intervalType}`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
