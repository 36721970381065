import { CircularProgress } from "@chakra-ui/react";
import {
  Cog8ToothIcon,
  PuzzlePieceIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { Bars3Icon, PresentationChartBarIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { CompanyJuneLogoIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGettingStarted } from "core/hooks/useGettingStarted";
import { useNavigation } from "core/hooks/useNavigation";
import { cx } from "helpers/cx";

interface MenuItem {
  icon: React.ReactNode;
  type: MenuItemType;
  paths: string[];
  defaultPath: string;
  isClickable: boolean;
  label?: string;
  open?: boolean;
}

export enum MenuItemType {
  GettingStarted = "Getting started",
  Home = "Home",
  Dashboards = "Dashboards",
  People = "People",
  Integrations = "Integrations",
  Settings = "Settings",
}

export function getCurrentMenuItem(): MenuItem {
  const currentPath = window.location.pathname;
  return MENU_ITEMS.find((item) => {
    return item.paths.some((path) => currentPath.includes(path));
  }) as MenuItem;
}

const MENU_ITEMS: MenuItem[] = [
  {
    icon: <CompanyJuneLogoIcon h={5} w={5} />,
    type: MenuItemType.Home,
    paths: [],
    defaultPath: `/home`,
    isClickable: false,
    open: true,
  },
  {
    icon: null,
    label: MenuItemType.GettingStarted,
    type: MenuItemType.GettingStarted,
    paths: ["/getting-started"],
    defaultPath: `/getting-started`,
    isClickable: true,
    open: false,
  },
  {
    icon: <PresentationChartBarIcon className="h-5 w-5" />,
    label: MenuItemType.Dashboards,
    type: MenuItemType.Dashboards,
    paths: [
      "/report/",
      "/reports",
      "/home",
      "/events",
      "/event/",
      "/views",
      "/dashboards",
      "/dashboard/",
    ],
    defaultPath: `/home`,
    isClickable: true,
    open: true,
  },
  {
    icon: <UsersIcon className="h-5 w-5" />,
    label: MenuItemType.People,
    type: MenuItemType.People,
    paths: [
      "/people",
      "/contact/",
      "/company/",
      "/group/",
      "/audiences",
      "/audience/",
      "/object/",
    ],
    defaultPath: `/people`,
    isClickable: true,
    open: true,
  },
  {
    icon: <PuzzlePieceIcon className="h-5 w-5" />,
    label: MenuItemType.Integrations,
    type: MenuItemType.Integrations,
    paths: ["/integrations"],
    defaultPath: `/integrations/computed-traits`,
    isClickable: true,
    open: true,
  },
  {
    icon: <Cog8ToothIcon className="h-5 w-5" />,
    label: MenuItemType.Settings,
    type: MenuItemType.Settings,
    paths: ["/settings"],
    defaultPath: `/settings`,
    isClickable: true,
    open: true,
  },
];

export const BaseSidebar: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const { navigation, toggleSidebar } = useNavigation();
  const navigate = useNavigate();
  const { progress, showGettingStarted } = useGettingStarted();

  function isSelected(item: MenuItem) {
    return item.paths.some((path) => window.location.pathname.includes(path));
  }

  return (
    <div
      className={cx("flex h-screen flex-col")}
      style={{
        minWidth: navigation.isOpen ? "50px" : "50px",
        maxWidth: navigation.isOpen ? "50px" : "50px",
      }}
    >
      {MENU_ITEMS.map((item: MenuItem) => {
        if (item.type === MenuItemType.GettingStarted && !showGettingStarted) {
          return null;
        }
        return (
          <Tooltip key={item.type}>
            <TooltipTrigger asChild>
              <div
                className={cx(
                  "base-sidebar__item relative flex h-[50px] flex-col items-center justify-center",
                  isSelected(item)
                    ? "base-sidebar__item--is-selected bg-gray-50 text-purple-500"
                    : "bg-gray-100",
                  item.type === MenuItemType.Home
                    ? !navigation.isOpen
                      ? "cursor-pointer"
                      : ""
                    : "cursor-pointer",
                )}
                onClick={() => {
                  if (item.type === MenuItemType.Home && navigation.isOpen)
                    return;

                  if (item.type === MenuItemType.Home && !navigation.isOpen)
                    return toggleSidebar();

                  if (navigation.isOpen !== item.open) {
                    toggleSidebar();
                  }

                  navigate(`/a/${appId}${item.defaultPath}`);
                }}
              >
                {isSelected(item) && (
                  <div className="absolute left-0 top-0 h-full w-[3px] bg-purple-500" />
                )}
                {item.type === MenuItemType.Home &&
                !navigation.isOpen &&
                !isSelected(
                  MENU_ITEMS.find(
                    (menuItem) => menuItem.type === MenuItemType.GettingStarted,
                  )!,
                ) ? (
                  <Bars3Icon className="h-5 w-5" />
                ) : (
                  item.icon
                )}
                {item.type === MenuItemType.GettingStarted && (
                  <CircularProgress
                    thickness="14px"
                    value={progress}
                    color={isSelected(item) ? "primary" : "black"}
                    trackColor={isSelected(item) ? "purple.100" : "gray.300"}
                    size="18px"
                  />
                )}
              </div>
            </TooltipTrigger>
            {item.label && (
              <TooltipContent side="right">{item.label}</TooltipContent>
            )}
          </Tooltip>
        );
      })}
      <div className="base-sidebar__item flex-1 bg-gray-100"></div>
    </div>
  );
};
