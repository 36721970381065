import {
  ArrowLongDownIcon,
  ArrowLongRightIcon,
  ArrowLongUpIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { useState } from "react";

import { Button } from "@/Components/ui/button";
import { IViewInsight } from "@/core/models/viewInsights";
import { DataPoint } from "@/modules/ViewInsight/ViewInsightComponent";
import { thousandsToK } from "core/helpers/thousands";
import { Interval, Measure } from "core/types/ViewInsight";
import { cx } from "helpers/cx";

export interface ITrendData {
  count: number;
  change: number;
  interval: Interval;
}

interface ITrendProps {
  data: ITrendData;
  measure: Measure;
  onClickDataPoint: (dataPoint: DataPoint) => void;
  viewInsight: IViewInsight;
}

const HUMANIZED_TREND_INTERVAL = {
  [Interval.Daily]: "vs yesterday",
  [Interval.Weekly]: "vs last week",
  [Interval.Monthly]: "vs last month",
};

export const Trend: React.FC<ITrendProps> = ({
  data,
  measure,
  onClickDataPoint,
  viewInsight,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  if (
    !data ||
    data?.count === undefined ||
    data?.change === undefined ||
    data?.interval === undefined
  )
    return <></>;

  return (
    <div
      className={cx(
        "relative flex h-full w-full flex-col items-center justify-center gap-y-3 rounded-lg bg-gradient-to-t",
        data.change < 0 ? "from-red-50 to-white" : "from-green-50 to-white",
      )}
      onClick={() => onClickDataPoint(data)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex w-full flex-col items-center justify-center pt-4">
        <div className="flex">
          <p className="truncate text-6xl font-medium">
            {thousandsToK(data.count)}
          </p>
        </div>
      </div>
      <div className="flex items-center gap-x-1 pb-4">
        <div
          className={cx(
            "text-md flex items-center",
            data.change < 0 ? " text-red-600" : "text-green-600 ",
          )}
        >
          {data.change < 0 ? (
            <ArrowLongDownIcon className="h-3.5 w-3.5" />
          ) : (
            <ArrowLongUpIcon className="h-3.5 w-3.5" />
          )}
          <p className="text-sm font-medium">{data.change || 0}%</p>
        </div>
        <p className="text-sm text-gray-600">
          {HUMANIZED_TREND_INTERVAL[data.interval]}
        </p>
      </div>
      {isHovered && onClickDataPoint && (
        <motion.div
          className="absolute bottom-0 left-0 h-40 w-full bg-gradient-to-t from-white to-transparent duration-300 ease-in-out"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <div className="flex h-full w-full flex-col justify-end pb-4 text-center">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Button
                variant="outline"
                size="sm"
                onClick={() => onClickDataPoint(data)}
                className="mx-auto rounded-full"
              >
                <div className="flex items-center gap-1">
                  <span className="text-xs">View</span>
                  <span className="text-xs">
                    {viewInsight?.appObject?.pluralName?.toLowerCase()}
                  </span>
                  <ArrowLongRightIcon className="h-3 w-3 text-gray-600" />
                </div>
              </Button>
            </motion.div>
          </div>
        </motion.div>
      )}
    </div>
  );
};
