import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { get } from "lodash";
import React, { ReactNode } from "react";

import { TooltipBody } from "core/components/TooltipBody";
import { Icon } from "core/design-system/components/Icon";
import HelpCircle from "core/design-system/components/Icon/Interface/HelpCircle";
import { IPagy } from "core/types/Pagy";

interface ITooltipLabel {
  tooltip?: string;
  TooltipComponent?: React.FC<any> | null;
}

const TooltipLabel = ({ tooltip, TooltipComponent }: ITooltipLabel) => {
  if (tooltip) return <TooltipBody text={tooltip} />;
  if (TooltipComponent) return <TooltipComponent />;

  return null;
};

interface ICell {
  rowIndex?: number;
  pagy?: IPagy;
  text?: string;
  name: string;
  fontWeight?: string;
  textAlign?: string;
  Component?: React.FC<any> | null;
  dataKey?: string;
  color?: string;
  props?: any;
  fontSize?: string | number;
  tooltip?: string;
  mutate?: () => void;
  sortable?: boolean;
  sortedBy?: { name: string; direction: string };
  setSortedBy?: (sortedBy: { name: string; direction: string }) => void;
  previewMode?: boolean;
  sharingMode?: boolean;
  additionalProps?: any;
  TooltipComponent?: React.FC<any> | null;
}

export const Cell = ({
  rowIndex = 0,
  pagy,
  text,
  name,
  fontWeight = "normal",
  textAlign = "left",
  Component,
  dataKey,
  color = "black",
  props,
  fontSize = "md",
  tooltip,
  mutate,
  sortable,
  sortedBy = undefined,
  setSortedBy = (_) => {},
  previewMode,
  sharingMode,
  additionalProps = {},
  TooltipComponent,
}: ICell) => {
  if (Component) {
    const componentProps = dataKey ? get(props, dataKey) : props;

    return (
      <Component
        name={name}
        rowIndex={rowIndex}
        pagy={pagy}
        mutate={mutate}
        props={componentProps}
        previewMode={previewMode}
        sharingMode={sharingMode}
        additionalProps={additionalProps}
      />
    );
  }

  const tooltipLabel = (
    <TooltipLabel tooltip={tooltip} TooltipComponent={TooltipComponent} />
  );

  function formatValue(value: string | number | ReactNode) {
    if (typeof value === "number") {
      return value.toLocaleString();
    }
    return value;
  }

  return (
    <Flex align="center" justifyContent={textAlign} py={1}>
      <Text color={color} fontWeight={fontWeight} fontSize={fontSize}>
        {formatValue(text)}
      </Text>
      {tooltip || TooltipComponent ? (
        <Tooltip label={tooltipLabel} placement="bottom" borderRadius="lg">
          <HelpCircle h={4} w={4} color="purple.500" ml={1} />
        </Tooltip>
      ) : undefined}
      {sortable && sortedBy && (
        <Flex>
          {sortedBy.name === name && (
            <Icon
              cursor="pointer"
              iconType="icon"
              color="purple.600"
              fontWeight="bold"
              name={`chevron-${(sortedBy.direction === "asc" && "up") || "down"}`}
              onClick={() =>
                setSortedBy({
                  name: sortedBy.name,
                  direction: sortedBy.direction === "asc" ? "desc" : "asc",
                })
              }
            />
          )}
          {sortedBy.name !== name && (
            <Icon
              cursor="pointer"
              onClick={() => setSortedBy({ name, direction: "desc" })}
              iconType="icon"
              name="chevron-down"
              color="gray.500"
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};
