import { withInView } from "core/components/InsightCard/withInView";
import { List } from "core/components/ViewInsight/Graphs/List";
import { SimpleBarChart } from "core/components/ViewInsight/Graphs/SimpleBarChart";
import defaultTemplateConfig from "core/constants/report-configurations/report-types/defaultTemplateConfig";
import {
  ANY,
  AUDIENCE_TYPE,
  MULTI_EVENT_TYPE,
  OR,
  PAGE,
  TRACK,
} from "core/constants/report-setup";
import { Template } from "core/constants/templates";
import { SEVEN_DAYS, THIRTY_DAYS } from "core/constants/timerange";
import { IViewInsight } from "core/models/viewInsights";
import GroupBarChartGraphL30 from "core/modules/reports/report-types/PowerGroups/BarChartGraphL30";
import GroupBarChartGraphL7 from "core/modules/reports/report-types/PowerGroups/BarChartGraphL7";
import BarChartGraphL30 from "core/modules/reports/report-types/PowerUsers/BarChartGraphL30";
import BarChartGraphL7 from "core/modules/reports/report-types/PowerUsers/BarChartGraphL7";
import { PowerUsersWeekOnWeekContainer } from "core/modules/reports/report-types/PowerUsers/PowerUsersWeekOnWeekContainer";
import { TopPowerUsersContainer } from "core/modules/reports/report-types/PowerUsers/TopPowerUsersContainer";
import { ITemplateConfig } from "core/types/TemplateConfig";
import { ITrait } from "core/types/Trait";

const LazyPowerUsersWeekOnWeekContainer = withInView(
  PowerUsersWeekOnWeekContainer,
);
const LazyBarChartGraphL30 = withInView(BarChartGraphL30);

const powerUsers: ITemplateConfig = {
  ...defaultTemplateConfig,
  route: "power-users",
  reportType: 6,
  subtitle: "Find who loves your product",
  aboutTemplate:
    "Get your users’ engagement by the number of days they were active in a week or a month. Customize this view for the actions you decide are important to measure for your product.",
  title: "Power users",
  template: Template.PowerUsers,
  sections: {
    user: [
      BarChartGraphL7,
      TopPowerUsersContainer,
      // Below the fold
      LazyPowerUsersWeekOnWeekContainer,
      LazyBarChartGraphL30,
    ],
    company: [GroupBarChartGraphL7, GroupBarChartGraphL30],
  },
  insights: [
    {
      typeId: 0,
      slug: "power-users-7-days",
      title: "7 days power {APP_OBJECT_PLURAL_NAME}",
      section: BarChartGraphL7,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about power users",
        content:
          "The power users bar chart shows the distribution (in number of days) of the activity of your identified users within a week / month. The activity is determined by the events that have been selected in the report setup.",
      },
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleBarChart,
        transform: (data) => {
          return data?.powerUsers?.map(({ days, contactsCount }: any) => {
            return {
              x: `${days} day`,
              y: contactsCount,
              days,
              contactsCount,
            };
          });
        },
        timerange: (insight: IViewInsight) => SEVEN_DAYS.short,
      },
    },
    {
      typeId: 3,
      slug: "top-power-users",
      title: "Top power {APP_OBJECT_PLURAL_NAME}",
      section: TopPowerUsersContainer,
      shareable: false,
      copyable: false,
      view: {
        level: "user",
        Component: List,
        transform: (data) => {
          return data?.contacts?.slice(0, 5)?.map((entity: any) => {
            const traits = entity?.traits as ITrait;
            return {
              id: entity?.userId,
              displayName: traits?.["email"],
              avatar: traits?.["avatar"],
              description: `Power user for ${entity?.numberOfActiveWeeks} weeks`,
            };
          });
        },
      },
    },
    {
      typeId: 4,
      slug: "power-users-week-on-week",
      title: "Are power {APP_OBJECT_PLURAL_NAME} increasing?",
      section: PowerUsersWeekOnWeekContainer,
      slackAlertAvailable: true,
    },
    {
      typeId: 1,
      slug: "power-users-30-days",
      title: "30 days power {APP_OBJECT_PLURAL_NAME}",
      section: BarChartGraphL30,
      slackAlertAvailable: true,
      hasYAxis: true,
      description: {
        title: "Learn more about power users",
        content:
          "The power users bar chart shows the distribution (in number of days) of the activity of your identified users within a week / month. The activity is determined by the events that have been selected in the report setup.",
      },
      view: {
        level: "user",
        Component: SimpleBarChart,
        transform: (data) => {
          return data?.powerUsers?.map(({ days, contactsCount }: any) => ({
            x: `${days} day`,
            y: contactsCount,
            days,
            contactsCount,
          }));
        },
        timerange: (insight: IViewInsight) => THIRTY_DAYS.short,
      },
    },
    {
      typeId: 7,
      slug: "power-companies-7-days",
      title: "7 days power {APP_OBJECT_PLURAL_NAME}",
      section: GroupBarChartGraphL7,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about power companies",
        content:
          "The power companies bar chart shows the distribution (in number of days) of the activity of your identified companies within a week / month. The activity is determined by the events that have been selected in the report setup.",
      },
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleBarChart,
        transform: (data) => {
          return data?.powerGroups?.map(({ days, groupsCount }: any) => ({
            x: `${days} day`,
            y: groupsCount,
            days,
            groupsCount,
          }));
        },
      },
    },
    {
      typeId: 8,
      slug: "power-companies-30-days",
      title: "30 days power {APP_OBJECT_PLURAL_NAME}",
      section: GroupBarChartGraphL30,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about power companies",
        content:
          "The power users bar chart shows the distribution (in number of days) of the activity of your identified companies within a week / month. The activity is determined by the events that have been selected in the report setup.",
      },
      view: {
        level: "group",
        Component: SimpleBarChart,
        transform: (data) => {
          return data?.powerGroups?.map(({ days, groupsCount }: any) => ({
            x: `${days} day`,
            y: groupsCount,
            days,
            groupsCount,
          }));
        },
      },
    },
  ],

  setup: {
    requiredSections: [0],
    eventOperator: OR,
    setupSections: [
      {
        id: "retention_event",
        configKey: "events",
        title: "Events",
        description:
          "This event should be a core action in your product. Pick multiple events if you have more than one",
        type: MULTI_EVENT_TYPE,
        supportedEventTypes: [ANY, TRACK, PAGE],
        required: true,
        validate: (events) =>
          events &&
          events.length > 0 &&
          events.length ===
            events.map((event) => event.id).filter((x) => x).length,
      },
      {
        id: "audience",
        configKey: "audience",
        title: "Audience",
        description: "Select an audience for your report",
        type: AUDIENCE_TYPE,
        supportedUserTypes: ["user"],
      },
    ],
  },
  validateSetup: (config) =>
    config &&
    config?.events &&
    config?.events?.length > 0 &&
    config?.events?.some((e) => e.name),
};

export default powerUsers;
