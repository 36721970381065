import { Clock } from "lucide-react";
import React from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";

interface EmptyValueProps {
  isComputed?: boolean;
}

export const EmptyValue: React.FC<EmptyValueProps> = ({ isComputed }) => {
  if (isComputed) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex items-center gap-1 text-sm text-gray-500">
            <Clock
              className="h-4 w-4"
              data-testid="pending-computed-traits-clock-icon"
            />
            <span>Pending</span>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>
            This value is still calculating. Computed values update every 24
            hours.
          </p>
        </TooltipContent>
      </Tooltip>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <p className="text-sm text-gray-500">Select a value</p>
      </TooltipTrigger>
      <TooltipContent>
        <p>Click to edit</p>
      </TooltipContent>
    </Tooltip>
  );
};
