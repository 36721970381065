import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ComponentDefaultProps,
  Flex,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import moment from "moment";

import { useBilling } from "core/hooks/useBilling";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useMauThreshold } from "core/hooks/useMauThreshold";
import { usePaywall } from "core/hooks/usePaywall";
import { DefaultHeader } from "modules/Pages/DefaultHeader";

const SECTION = {
  name: "Usage & Billing",
  title: "Usage & Billing",
  description: `Your workspace's usage and billing information`,
};

const BillingRow: React.FC<
  {
    name: string;
    value: string;
    isBold?: boolean;
    tooltip?: string;
    valueTooltip?: string;
  } & ComponentDefaultProps
> = ({ name, value, isBold, tooltip, valueTooltip, children, ...props }) => {
  return (
    <Flex justifyContent="space-between" {...props}>
      <Flex align="center" gap={2}>
        <Text
          fontSize="sm"
          color={isBold ? "black" : "gray.600"}
          fontWeight={isBold ? "bold" : "normal"}
          textTransform="capitalize"
        >
          {name} {children}
        </Text>
        {tooltip && (
          <Tooltip label={tooltip} placement="right" hasArrow>
            <QuestionMarkCircleIcon className="h-4" />
          </Tooltip>
        )}
      </Flex>
      <Flex align="center" gap={2}>
        <Text
          fontSize="sm"
          color={isBold ? "black" : "gray.800"}
          fontWeight={isBold ? "bold" : "normal"}
        >
          {value}
        </Text>
        {valueTooltip && (
          <Tooltip label={valueTooltip} placement="top" hasArrow>
            <QuestionMarkCircleIcon className="h-4" />
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

export const Billing: React.FC = () => {
  const currentApp = useCurrentApp();

  const { startDate, endDate } = useMauThreshold();
  const { isTrialing, isFree } = usePaywall();
  const { redirectToBillingPortal, isStripeCustomer } = useBilling();

  return (
    <Box>
      <Flex align="center" justifyContent="space-between">
        <div className="flex w-full justify-between">
          <div className="flex">
            <DefaultHeader section={SECTION} />
          </div>
          <div className="flex items-center gap-2">
            {isStripeCustomer && (
              <div className="flex">
                <Button
                  variant="outline"
                  onClick={redirectToBillingPortal}
                  rightIcon={<ExternalLinkIcon />}
                  data-testid="manage-billing-button"
                >
                  Manage billing
                </Button>
              </div>
            )}
            <div className="flex">
              <Button onClick={() => window.Intercom("show")} variant="outline">
                Talk to us
              </Button>
            </div>
          </div>
        </div>
      </Flex>

      {isTrialing ? (
        <Flex
          w="full"
          direction="column"
          my={4}
          gridGap={6}
          data-testid="billing-container"
        >
          <Flex direction="column">
            <Text fontSize="sm" fontWeight="bold">
              Current period
            </Text>
            <Text fontSize="sm">
              {moment(startDate).format(`DD MMM, YYYY`)} -{" "}
              {moment(endDate).format(`DD MMM, YYYY`)}
            </Text>
          </Flex>
          <Flex direction="column">
            <Text fontSize="sm" fontWeight="bold">
              Overview
            </Text>
            <Text mb={2} fontSize="sm">
              An overview of your usage and costs
            </Text>
            <Flex
              direction="column"
              border="1px solid"
              borderColor="gray.200"
              borderRadius="md"
              mt={4}
            >
              <Flex p={4} direction="column" gridGap={4}>
                <BillingRow
                  data-testid="billing-plan"
                  name={"trial"}
                  value={`Free for ${currentApp.trialDaysRemaining} day${currentApp.trialDaysRemaining > 1 ? "s" : ""}`}
                  valueTooltip={`For pricing, talk to sales`}
                >
                  <p className="ml-2 inline rounded-sm bg-orange-50 px-2 py-1 text-xs font-medium text-orange-400">
                    {currentApp.trialDaysRemaining === 0
                      ? "until today"
                      : `${currentApp.trialDaysRemaining} day${currentApp.trialDaysRemaining > 1 ? "s" : ""} left`}
                  </p>
                </BillingRow>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      ) : isFree ? (
        <div className="flex flex-col text-sm text-gray-900">
          To get a quote for your usage, contact us via our shared Slack channel
          or support chat.
        </div>
      ) : (
        <div className="flex flex-col text-sm text-gray-900">
          <p>
            To access your billing information and invoices - contact us via our
            shared Slack channel or support chat. <br />
            We'll do our best to reply within a working day.
          </p>
        </div>
      )}
    </Box>
  );
};
