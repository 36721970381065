import { useDispatch } from "react-redux";

import { useToast } from "core/hooks/useToast";
import {
  useAddViewInsightMutation,
  useDeleteViewInsightMutation,
} from "core/models/viewInsights";
import { viewsApi } from "core/models/views";

interface IUseViewInsightManagementProps {
  appId: number;
  reportId: string;
  insightId: number;
  objectType?: string;
}

export const useViewInsightManagement = ({
  appId,
  reportId,
  insightId,
  objectType,
}: IUseViewInsightManagementProps) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [addViewInsight] = useAddViewInsightMutation();
  const [deleteViewInsight] = useDeleteViewInsightMutation();

  const handleAddOrRemoveInsight = ({
    isAdded,
    viewInsightId,
    viewId,
    viewName,
  }: {
    isAdded: boolean;
    viewInsightId: number | null;
    viewId: string;
    viewName?: string;
  }) => {
    if (isAdded && viewInsightId) {
      deleteViewInsight({ id: viewInsightId, appId })
        .unwrap()
        .then(() => {
          dispatch(viewsApi.util.invalidateTags(["Views"]));
          toast({
            title: "Insight removed",
            description: `Insight removed from the ${viewName ? viewName + " " : ""}view`,
            status: "success",
          });
        });
    } else if (!isAdded) {
      addViewInsight({
        appId: Number(appId),
        viewId: Number(viewId),
        reportId: Number(reportId),
        insightId: Number(insightId),
        objectType,
      })
        .unwrap()
        .then(() => {
          dispatch(viewsApi.util.invalidateTags(["Views"]));
          toast({
            title: "Insight added",
            description: `Insight added to the ${viewName ? viewName + " " : ""}view`,
            status: "success",
          });
        });
    }
  };

  return { handleAddOrRemoveInsight };
};
