import {
  GroupCohortData,
  IntervalType,
  UserCohortData,
} from "@/core/components/CohortTable/CohortTable.types";
import { FEATURE_RELEASE } from "@/core/constants/report-configurations";
import { DAY, MONTH, WEEK } from "@/core/constants/timerange";
import { ActiveGroupsList } from "@/core/modules/reports/report-types/ActiveGroups/ActiveGroupsList";
import { MostActiveGroupsList } from "@/core/modules/reports/report-types/ActiveGroups/MostActiveGroupsList";
import { ActiveUsersList } from "@/core/modules/reports/report-types/ActiveUsers/ActiveUsersList";
import { MostActiveUsersList } from "@/core/modules/reports/report-types/ActiveUsers/MostActiveUsersList";
import { FeatureUsageList } from "@/core/modules/reports/report-types/FeatureRelease/Usage/FeatureUsageList";
import { FunnelUsers } from "@/core/modules/reports/report-types/Funnel/FunnelUsers";
import { FunnelGroups } from "@/core/modules/reports/report-types/GroupFunnel/FunnelGroups";
import { CohortGroups } from "@/core/modules/reports/report-types/GroupRetention/CohortGroups";
import { NewGroupsList } from "@/core/modules/reports/report-types/NewGroups/NewGroupsList";
import { NewUsersList } from "@/core/modules/reports/report-types/NewUsers/NewUsersList";
import { PowerGroupsList } from "@/core/modules/reports/report-types/PowerGroups/PowerGroupsList";
import { useLastThreeAndCurrentMonths } from "@/core/modules/reports/report-types/PowerUsers/BarChartGraphL30";
import { useLastSixAndCurrentWeeks } from "@/core/modules/reports/report-types/PowerUsers/BarChartGraphL7";
import { PowerUsersList } from "@/core/modules/reports/report-types/PowerUsers/PowerUsersList";
import { TopPowerUsersList } from "@/core/modules/reports/report-types/PowerUsers/TopPowerUsersList";
import { CohortUsers } from "@/core/modules/reports/report-types/Retention/CohortUsers";
import { AppObjectType } from "@/core/types";
import { GraphType, Interval, Measure } from "@/core/types/ViewInsight";
import { IViewInsight } from "core/models/viewInsights";
import { IInsightSection } from "core/types/TemplateConfig";
import { INTERVAL_TYPES } from "modules/ViewInsight/Builder/Preview/Container";

interface UsageData {
  chartDate: string;
  usageCount: number;
  uniqueUserCount: number;
}

export enum FEATURE_WEEKLY_USAGE_INSIGHT_IDS {
  USER_DAILY = 21,
  USER_WEEKLY = 22,
  USER_MONTHLY = 23,
  GROUP_DAILY = 24,
  GROUP_WEEKLY = 25,
  GROUP_MONTHLY = 26,
}

export interface DataPoint {
  id?: string;
  x?: string;
  y?: string;
  eventIndex?: number;
  label?: string;
  conversion?: {
    count: number;
  };
  dropped?: {
    count: number;
  };
  humanizedDate?: string;
  days?: number;
  count?: number;
  change?: number;
  interval?: Interval;
  date?: string;
  startDate?: string;
  intervalStartDate?: string;
  retainedUsersCount?: number;
  droppedUsersCount?: number;
  intervalType?: IntervalType;
  chartDate?: string;
  data?: UserCohortData[] | GroupCohortData[] | UsageData;
}

export const ViewInsightDrilldown: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  dataPoint: DataPoint;
  viewInsight: IViewInsight;
  id: string;
  insightConfig: IInsightSection;
}> = ({ isOpen, onClose, dataPoint, viewInsight, id, insightConfig }) => {
  const { currentWeek } = useLastSixAndCurrentWeeks();
  const { currentMonth } = useLastThreeAndCurrentMonths();
  const currentPeriod =
    viewInsight.interval === Interval.Weekly ? currentWeek : currentMonth;
  const intervalTypes = {
    [Interval.Daily]: DAY,
    [Interval.Weekly]: WEEK,
    [Interval.Monthly]: MONTH,
  };

  const intervalLength =
    intervalTypes[viewInsight.interval as keyof typeof intervalTypes];

  const SUPPORTED_LEGACY_INSIGHTS = {
    [FEATURE_RELEASE]: [
      FEATURE_WEEKLY_USAGE_INSIGHT_IDS.USER_DAILY,
      FEATURE_WEEKLY_USAGE_INSIGHT_IDS.USER_WEEKLY,
      FEATURE_WEEKLY_USAGE_INSIGHT_IDS.USER_MONTHLY,
      FEATURE_WEEKLY_USAGE_INSIGHT_IDS.GROUP_DAILY,
      FEATURE_WEEKLY_USAGE_INSIGHT_IDS.GROUP_WEEKLY,
      FEATURE_WEEKLY_USAGE_INSIGHT_IDS.GROUP_MONTHLY,
    ],
  };

  const usageIntervalTypes = {
    [FEATURE_WEEKLY_USAGE_INSIGHT_IDS.USER_DAILY]: DAY,
    [FEATURE_WEEKLY_USAGE_INSIGHT_IDS.USER_WEEKLY]: WEEK,
    [FEATURE_WEEKLY_USAGE_INSIGHT_IDS.USER_MONTHLY]: MONTH,
    [FEATURE_WEEKLY_USAGE_INSIGHT_IDS.GROUP_DAILY]: DAY,
    [FEATURE_WEEKLY_USAGE_INSIGHT_IDS.GROUP_WEEKLY]: WEEK,
    [FEATURE_WEEKLY_USAGE_INSIGHT_IDS.GROUP_MONTHLY]: MONTH,
  };

  if (
    Object.keys(SUPPORTED_LEGACY_INSIGHTS).includes(
      viewInsight.report.reportType.toString(),
    )
  ) {
    return (
      <FeatureUsageList
        key={id}
        isOpen={isOpen}
        onClose={onClose}
        humanizedDate={(dataPoint?.data as UsageData)?.chartDate}
        date={dataPoint?.chartDate || ""}
        usageCount={(dataPoint?.data as UsageData)?.usageCount}
        uniqueUserCount={(dataPoint?.data as UsageData)?.uniqueUserCount}
        intervalType={
          usageIntervalTypes[
            viewInsight.insightId as keyof typeof usageIntervalTypes
          ]
        }
        unit={viewInsight?.appObject?.pluralName.toLowerCase() || ""}
        report={viewInsight?.report}
        viewInsight={viewInsight}
      />
    );
  }

  if (viewInsight.measure === Measure.ActiveUsers) {
    if (viewInsight.graphType === GraphType.Table) {
      return viewInsight?.appObject?.objectType === AppObjectType.User ? (
        <MostActiveUsersList
          key={id}
          isOpen={isOpen}
          onClose={onClose}
          report={viewInsight?.report}
          insight={insightConfig}
          csvExportLoading={false}
          requestCsvExport={() => {}}
        />
      ) : (
        <MostActiveGroupsList
          key={id}
          isOpen={isOpen}
          onClose={onClose}
          report={viewInsight?.report}
          insight={insightConfig}
          previewMode={false}
          sharingSecretToken={null}
          viewInsight={viewInsight}
        />
      );
    }
    return viewInsight?.appObject?.objectType === AppObjectType.User ? (
      <ActiveUsersList
        key={id}
        isOpen={isOpen}
        onClose={onClose}
        report={viewInsight?.report}
        humanizedDate={dataPoint?.x || dataPoint?.humanizedDate || ""}
        viewInsight={viewInsight}
        showNew
        activeUsersCount={Number(dataPoint?.y) || dataPoint?.count || 0}
        date={dataPoint?.date || ""}
        intervalType={INTERVAL_TYPES[viewInsight.interval || Interval.Daily]}
      />
    ) : (
      <ActiveGroupsList
        key={id}
        isOpen={isOpen}
        onClose={onClose}
        report={viewInsight?.report}
        humanizedDate={dataPoint?.x || dataPoint?.humanizedDate || ""}
        viewInsight={viewInsight}
        showNew
        activeGroupsCount={Number(dataPoint?.y) || dataPoint?.count || 0}
        date={dataPoint?.date || ""}
        intervalType={INTERVAL_TYPES[viewInsight.interval || Interval.Daily]}
      />
    );
  }

  if (viewInsight.measure === Measure.NewUsers) {
    return viewInsight?.appObject?.objectType === AppObjectType.User ? (
      <NewUsersList
        isOpen={isOpen}
        onClose={onClose}
        report={viewInsight?.report}
        humanizedDate={dataPoint?.x || dataPoint?.humanizedDate || ""}
        viewInsight={viewInsight}
        newUsersCount={Number(dataPoint?.y) || dataPoint?.count || 0}
        date={dataPoint?.date || ""}
        intervalType={INTERVAL_TYPES[viewInsight.interval || Interval.Daily]}
      />
    ) : (
      <NewGroupsList
        isOpen={isOpen}
        onClose={onClose}
        report={viewInsight?.report}
        viewInsight={viewInsight}
        newGroupsCount={Number(dataPoint?.y) || dataPoint?.count || 0}
        date={dataPoint?.date || ""}
        intervalType={INTERVAL_TYPES[viewInsight.interval || Interval.Daily]}
        humanizedDate={dataPoint?.x || dataPoint?.humanizedDate || ""}
      />
    );
  }

  if (viewInsight.measure === Measure.Funnel) {
    return viewInsight?.appObject?.objectType === AppObjectType.User ? (
      <FunnelUsers
        isOpen={isOpen}
        onClose={onClose}
        report={viewInsight?.report}
        viewInsight={viewInsight}
        showConverted
        eventIndex={dataPoint?.eventIndex as number}
        humanizedEventName={dataPoint?.label as string}
        convertedContactCount={dataPoint?.conversion?.count as number}
        droppedContactCount={dataPoint?.dropped?.count as number}
        traitValues={[]}
      />
    ) : (
      <FunnelGroups
        isOpen={isOpen}
        onClose={onClose}
        report={viewInsight?.report}
        viewInsight={viewInsight}
        showConverted
        eventIndex={dataPoint?.eventIndex as number}
        humanizedEventName={dataPoint?.label as string}
        convertedContactCount={dataPoint?.conversion?.count as number}
        droppedContactCount={dataPoint?.dropped?.count as number}
        traitValues={[]}
      />
    );
  }

  if (viewInsight.measure === Measure.Power) {
    if (viewInsight.graphType === GraphType.Table)
      return (
        <TopPowerUsersList
          report={viewInsight?.report}
          insight={insightConfig}
          isOpen={isOpen}
          onClose={onClose}
          csvExportLoading={false}
          requestCsvExport={() => {}}
          viewInsight={viewInsight}
        />
      );

    return viewInsight?.appObject?.objectType === AppObjectType.User ? (
      <PowerUsersList
        isOpen={isOpen}
        onClose={onClose}
        report={viewInsight?.report}
        viewInsight={viewInsight}
        date={currentPeriod?.date}
        intervalLength={intervalLength}
        daysOfUsage={Number(dataPoint?.days)}
        powerUsersCount={Number(dataPoint?.y)}
        description={`Week of ${currentPeriod?.humanReadable}`}
      />
    ) : (
      <PowerGroupsList
        isOpen={isOpen}
        onClose={onClose}
        report={viewInsight?.report}
        viewInsight={viewInsight}
        date={currentPeriod?.date}
        intervalLength={intervalLength}
        daysOfUsage={Number(dataPoint?.days)}
        powerGroupsCount={Number(dataPoint?.y)}
      />
    );
  }

  if (viewInsight.measure === Measure.Retention) {
    return viewInsight?.appObject?.objectType === AppObjectType.User ? (
      <CohortUsers
        key={id}
        report={viewInsight?.report}
        isOpen={isOpen}
        onClose={onClose}
        intervalType={dataPoint?.intervalType as IntervalType}
        cohortStartDate={dataPoint?.startDate || ""}
        cohortIntervalStartDate={dataPoint?.intervalStartDate || ""}
        retainedUsersCount={dataPoint?.retainedUsersCount || 0}
        droppedUsersCount={dataPoint?.droppedUsersCount || 0}
        viewInsight={viewInsight}
      />
    ) : (
      <CohortGroups
        key={id}
        isOpen={isOpen}
        onClose={onClose}
        intervalType={dataPoint?.intervalType as IntervalType}
        cohortData={dataPoint?.data as GroupCohortData[]}
        cohortStartDate={dataPoint?.startDate || ""}
        cohortIntervalStartDate={dataPoint?.intervalStartDate || ""}
        viewInsight={viewInsight}
      />
    );
  }

  return null;
};
