import { Button, Flex, Image, Text } from "@chakra-ui/react";
import axios from "axios";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import useSWR from "swr";

const assetManifestFetcher = (assetManifestUrl: string) => {
  return axios
    .get(`${assetManifestUrl}?_${new Date().getTime()}`, {
      responseType: "text",
      transformResponse: [(data) => data],
    })
    .catch((e) => {
      console.error("Error fetching asset manifest:", e);
    });
};

export const UpdateNotification: React.FC = () => {
  const assetManifestUrl = "/asset-manifest.json";
  const [assetManifest, setAssetManifest] = useState("");
  const [entryAnimationFinished, setEntryAnimationFinished] = useState(false);

  const { data } = useSWR(assetManifestUrl, assetManifestFetcher, {
    refreshInterval: 120000,
    revalidateOnFocus: false,
  });
  useEffect(() => {
    if (!assetManifest && data) {
      setAssetManifest(data.data);
    }
  }, [data, assetManifest]);

  const onReload = () =>
    typeof window !== "undefined" && window.location.reload();

  const updateAvailable = assetManifest && data && data.data !== assetManifest;
  if (!updateAvailable) {
    return null;
  }

  return (
    <motion.div
      animate={{
        y: 0,
        opacity: 1,
      }}
      initial={{ opacity: 0, y: 100 }}
      transition={{ duration: 1.5, type: "spring", bounce: 0.6 }}
      onAnimationComplete={() => {
        setEntryAnimationFinished(true);
      }}
      style={{
        position: "fixed",
        bottom: 22,
        left: 13,
        zIndex: 10000,
      }}
    >
      <Flex
        className="container"
        w={"299px"}
        h={12}
        borderRadius={"lg"}
        zIndex={"toast"}
        p={3}
        justifyContent="space-between"
        align="center"
        bg="gray.50"
        backdropBlur="12px"
        backdropFilter="auto"
        filter="auto"
        dropShadow="0px 1px 4px rgba(0, 0, 0, 0.10)"
        overflow="hidden"
      >
        {entryAnimationFinished && (
          <motion.div
            style={{
              position: "absolute",
              zIndex: 1,
              top: 0,
              left: "-50%",
              width: "40%",
              height: "100%",
              background:
                "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0))",
              transform: "skewX(20deg)",
            }}
            animate={{
              left: "100%",
            }}
            transition={{
              duration: 1.5,
              repeat: 2,
              ease: "linear",
            }}
          />
        )}

        <motion.div
          animate={
            entryAnimationFinished && {
              scale: [1, 0.4, 1, 1],
            }
          }
          transition={{ duration: 1.5, repeat: 1.5, delay: 0.25 }}
          style={{ position: "absolute", top: "7px", left: "103px" }}
        >
          <Image src="/notification/star-small.svg" alt="star-sm" />
        </motion.div>
        <motion.div
          animate={
            entryAnimationFinished && {
              scale: [1, 0.4, 1, 1],
            }
          }
          transition={{ duration: 1.5, repeat: 1.5, delay: 0.1 }}
          style={{ position: "absolute", top: "32px", left: "52px" }}
        >
          <Image src="/notification/star-small.svg" alt="star-sm" />
        </motion.div>
        <motion.div
          animate={
            entryAnimationFinished && {
              scale: [1, 0.3, 1, 1],
            }
          }
          transition={{ duration: 1.5, repeat: 1.5 }}
          style={{ position: "absolute", top: "28px", left: "8px" }}
        >
          <Image src="/notification/star-medium.svg" alt="star-md" />
        </motion.div>
        <motion.div
          animate={
            entryAnimationFinished && {
              scale: [1, 0.2, 1, 1],
            }
          }
          transition={{ duration: 1.5, repeat: 1.5, delay: 0.5 }}
          style={{ position: "absolute", top: "15px", left: "172px" }}
        >
          <Image src="/notification/star-large.svg" alt="star-lg" />
        </motion.div>
        <Text
          color="gray.700"
          ml={3}
          fontSize={"sm"}
          fontWeight={"bold"}
          letterSpacing="-0.28px"
          lineHeight="normal"
          className="chrome"
        >
          New version available
        </Text>
        <Button
          position={"relative"}
          zIndex={3}
          h={6}
          w={"59px"}
          borderWidth="0px"
          fontSize={"xs"}
          onClick={onReload}
        >
          Refresh
        </Button>
      </Flex>
    </motion.div>
  );
};
