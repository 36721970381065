import { IGraphInsight } from "@/core/types/reports/retention";
import { Line, LineChart } from "core/design-system/charts/LineChart";
import colors from "core/design-system/constants/theme/colors";

const Insight = ({
  title,
  insight,
  children,
}: {
  title: string;
  insight: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="col-span-2 row-span-1 rounded-lg bg-gray-50 p-4">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col">
          <p className="mb-1 text-sm font-semibold">{title}</p>
          <p className="text-sm text-gray-700">{insight}</p>
          <div className="flex justify-end">{children}</div>
        </div>
      </div>
    </div>
  );
};

const TwentyPercentRuleGraph = ({
  retentionPercent,
}: {
  retentionPercent: number;
}) => {
  if (retentionPercent < 20)
    return (
      <div className="mt-5 flex w-full flex-col gap-5">
        <div className="flex flex-col">
          <div>
            <p className="text-sm font-semibold text-gray-400">20%</p>
          </div>
          <div>
            <div className="w-full border-2 border-dashed border-gray-300" />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div>
              <p className="text-sm font-semibold text-orange-700">
                {retentionPercent}%
              </p>
            </div>
            <div>
              <p className="text-sm font-semibold text-orange-700">Below</p>
            </div>
          </div>
          <div className="h-1 w-full rounded-sm bg-orange-600" />
        </div>
      </div>
    );

  return (
    <div className="mt-5 flex w-full flex-col gap-5">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <div>
            <p className="text-sm font-semibold text-green-500">
              {retentionPercent}%
            </p>
          </div>
          <div>
            <p className="text-sm font-semibold text-green-500">Above</p>
          </div>
        </div>
        <div className="h-1 w-full rounded-sm bg-green-400" />
      </div>
      <div className="flex flex-col">
        <div>
          <p className="text-sm font-semibold text-gray-400">20%</p>
        </div>
        <div>
          <div className="w-full border-2 border-dashed border-gray-300" />
        </div>
      </div>
    </div>
  );
};

const FirstDaysChart = ({ retentionPercent }: { retentionPercent: number }) => {
  if (retentionPercent < 20)
    return (
      <div className="relative flex h-[100px] w-full flex-row items-center justify-start pt-5">
        <p className="absolute right-0 top-5 text-sm font-semibold text-orange-700">
          Steep curve
        </p>
        <LineChart
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          height={100}
        >
          <Line
            dataKey="y"
            activeDot={false}
            dot={false}
            isAnimationActive={false}
            stroke={colors.orange[500]}
            strokeWidth={4}
            data={[
              { x: 1, y: 100 },
              { x: 2, y: 40 },
              { x: 3, y: 50 },
              { x: 4, y: 20 },
              { x: 5, y: 10 },
            ]}
          />
        </LineChart>
      </div>
    );

  return (
    <div className="relative flex h-[100px] w-full flex-row items-center justify-start pt-5">
      <p className="absolute right-0 top-5 text-sm font-semibold text-green-600">
        Smooth curve
      </p>
      <LineChart
        margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
        height={100}
      >
        <Line
          dataKey="y"
          activeDot={false}
          dot={false}
          isAnimationActive={false}
          stroke={colors.green[400]}
          strokeWidth={4}
          data={[
            { x: 1, y: 50 },
            { x: 2, y: 70 },
            { x: 3, y: 30 },
            { x: 4, y: 60 },
            { x: 5, y: 50 },
          ]}
        />
      </LineChart>
    </div>
  );
};

export const GraphInsight = ({
  graphInsightOverview,
  retentionPercent,
}: {
  graphInsightOverview?: IGraphInsight;
  retentionPercent: number;
}) => {
  if (!graphInsightOverview) return null;

  return (
    <div className="mb-5 grid grid-cols-4 gap-4 px-5">
      <Insight
        title="20% rule"
        insight={graphInsightOverview.percentRuleInsight}
      >
        <TwentyPercentRuleGraph retentionPercent={retentionPercent} />
      </Insight>
      <Insight title="First days" insight={graphInsightOverview.firstDay}>
        <FirstDaysChart retentionPercent={retentionPercent} />
      </Insight>
    </div>
  );
};
