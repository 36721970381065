import { ComponentDefaultProps, Spinner } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { useGetCrmErrorsQuery } from "core/models/crmErrors";
import { Integration } from "core/models/dataMappings";
import { IAppObject } from "core/types/AppObject";
import { IssuesList } from "modules/Settings/Crm/SyncSettings/Issues/IssuesList";

interface ICrmSyncSettingProps extends ComponentDefaultProps {
  appObject: IAppObject;
  integration: Integration;
}

export const Issues: React.FC<ICrmSyncSettingProps> = ({
  appObject,
  integration,
}) => {
  const { appId } = useParams() as { appId: string };

  const { data, isLoading } = useGetCrmErrorsQuery({
    appId: Number(appId),
    integration: integration as string,
    objectTypeId: appObject.id,
  });

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col gap-3">
        <div>
          <h3 className="text-base font-semibold leading-7 text-black">
            Unresolved issues
          </h3>
        </div>
        <div className="flex w-full flex-col gap-3">
          <div className="flex w-full flex-row items-center justify-between">
            {isLoading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <IssuesList issues={data ?? []} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
