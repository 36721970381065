import { createIcon } from "@chakra-ui/icons";
import React from "react";

const TeamIcon = createIcon({
  path: (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3571 14.6423C19.6588 15.9441 19.6588 18.0546 18.3571 19.3564C17.0554 20.6581 14.9448 20.6581 13.6431 19.3564C12.3413 18.0546 12.3413 15.9441 13.6431 14.6423C14.9448 13.3406 17.0554 13.3406 18.3571 14.6423"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6718 6.64233C26.9735 7.94407 26.9735 10.0546 25.6718 11.3564C24.37 12.6581 22.2595 12.6581 20.9578 11.3564C19.656 10.0546 19.656 7.94407 20.9578 6.64233C22.2595 5.34058 24.37 5.34058 25.6718 6.64233"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 16.332C26.044 16.332 28 17.0427 29.3333 18.1094"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0424 6.64233C12.3441 7.94407 12.3441 10.0546 11.0424 11.3564C9.74065 12.6581 7.63011 12.6581 6.32836 11.3564C5.02661 10.0546 5.02661 7.94407 6.32836 6.64233C7.63011 5.34058 9.74066 5.34058 11.0424 6.64233"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66675 18.1094C4.00008 17.0427 5.95608 16.332 8.00008 16.332"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1213 26.332C20.568 25.1307 18.336 24.332 16 24.332C13.664 24.332 11.432 25.1307 9.87866 26.332"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});

export default TeamIcon;
