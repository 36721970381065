import { Button, Tooltip } from "@chakra-ui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
  Lifecycle,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@june-so/ui";
import { useCallback, useState } from "react";
import { FixedSizeList as List } from "react-window";

import { TraitFilterCta } from "core/components/SmartTrait/TraitFilterCta";
import { TraitOption } from "core/components/Traits/TraitOption";
import { ITraitKeyResponse } from "core/models/traits";
import { AppObjectType, IAppObject } from "core/types/AppObject";

export const AddColumnButton = ({
  traits,
  existingConfiguredTraits,
  createTraitConfig,
  appObject,
}: {
  traits?: ITraitKeyResponse[];
  existingConfiguredTraits: string[];
  createTraitConfig?: ({
    trait,
    objectType,
  }: {
    trait: string;
    objectType: AppObjectType;
  }) => void;
  appObject: IAppObject;
}) => {
  const [input, setInput] = useState("");

  const filteredTraits = traits?.filter((t) =>
    t.trait.toLowerCase().includes(input.toLowerCase()),
  );

  const handleSelect = useCallback(
    (trait: string) => {
      if (appObject?.objectType && createTraitConfig) {
        createTraitConfig({
          trait,
          objectType: appObject.objectType,
        });
      }
    },
    [appObject, createTraitConfig],
  );

  return (
    <div className="flex h-full">
      <Popover className="relative">
        {({ close }: { close: () => void }) => (
          <>
            <PopoverButton>
              <Tooltip
                shouldWrapChildren
                label="Add column"
                placement="top"
                hasArrow
              >
                <Button size="xs" variant="ghost">
                  <PlusIcon className="h-4 w-4" />
                </Button>
              </Tooltip>
            </PopoverButton>
            <PopoverPanel>
              <>
                <Lifecycle onUnmount={() => setInput("")} />
                <Command className="max-h-100">
                  <CommandInput
                    className="font-medium tracking-normal"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                  />
                  <CommandList>
                    {filteredTraits && (
                      <List
                        height={200}
                        itemCount={filteredTraits.length}
                        itemSize={30}
                        width="100%"
                      >
                        {({
                          index,
                          style,
                        }: {
                          index: number;
                          style: object;
                        }) => {
                          const t = filteredTraits[index];
                          return (
                            <div style={style}>
                              <CommandOption
                                key={index}
                                disabled={existingConfiguredTraits.includes(
                                  t.trait,
                                )}
                                value={() => {
                                  close();
                                  handleSelect(t.trait);
                                }}
                              >
                                <TraitOption
                                  trait={t}
                                  isDisabled={existingConfiguredTraits.includes(
                                    t.trait,
                                  )}
                                />
                              </CommandOption>
                            </div>
                          );
                        }}
                      </List>
                    )}
                  </CommandList>
                  <TraitFilterCta
                    onClick={() => {}}
                    onComplete={(trait) => {
                      handleSelect(trait.name);
                      close();
                    }}
                  />
                </Command>
              </>
            </PopoverPanel>
          </>
        )}
      </Popover>
    </div>
  );
};
