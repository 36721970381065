export enum AppObjectType {
  User = "identify",
  Group = "custom_group",
  Company = "automatic_company",
}

export enum AppObjectSlugs {
  User = "user",
  Group = "group",
  Company = "company",
}

export interface IAppObject {
  id: number;
  appId: number;
  singularName: string;
  pluralName: string;
  slug: string;
  objectType: AppObjectType;
  isEnabled: boolean;
  level: "user" | "group" | "company";
}

export const GROUP_TYPE_TO_SLUG = {
  [AppObjectType.Group]: "group",
  [AppObjectType.Company]: "company",
  [AppObjectType.User]: "user",
};
