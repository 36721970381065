import { createIcon } from "@chakra-ui/icons";

import { IIcon } from "core/design-system/components/Icon/Icon.types";
import icons from "core/design-system/components/Icon/icons";
import illustrations from "core/design-system/components/Icon/illustrations";
import logos from "core/design-system/components/Icon/logos";

export const Icon = ({
  iconType = null,
  name,
  iconStylingProps,
  ...props
}: IIcon) => {
  let iconSvg;
  switch (iconType) {
    case "icon":
      iconSvg = icons[name as keyof typeof icons];
      break;
    case "logo":
      iconSvg = logos[name as keyof typeof logos];
      break;
    case "illustration":
      iconSvg = illustrations[name as keyof typeof illustrations];
      break;
    default:
      iconSvg = icons[name as keyof typeof icons];
      break;
  }

  if (import.meta.env.VITE_NODE_ENV === "test") return <></>;

  const IconComponent = createIcon(iconSvg);
  return <IconComponent {...iconStylingProps} {...props} />;
};
