import { useParams } from "react-router-dom";

import { useQueryParam } from "core/hooks/useQueryParam";
import {
  IViewInsight,
  useGetViewInsightsQuery,
} from "core/models/viewInsights";

export const useViewInsights = ({
  viewId,
  sharingSecretToken,
}: {
  viewId: number;
  sharingSecretToken?: string | null;
}): {
  viewInsights: IViewInsight[];
  isLoadingViewInsights: boolean;
  onRefetch: () => Promise<unknown>;
} => {
  const { appId, objectId } = useParams();
  const groupIdQueryParam = useQueryParam("groupId");
  const groupId = groupIdQueryParam ? groupIdQueryParam : objectId;
  const {
    data,
    isLoading: isLoadingViewInsights,
    isFetching: isFetchingViewInsights,
    refetch,
  } = useGetViewInsightsQuery({
    appId: Number(appId),
    viewId,
    sharingSecretToken,
    groupId,
  });

  async function onRefetch() {
    return new Promise((resolve) => refetch().then(resolve));
  }

  return {
    viewInsights: data || [],
    isLoadingViewInsights: isLoadingViewInsights || isFetchingViewInsights,
    onRefetch,
  };
};
