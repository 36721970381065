import { IListConfig } from "core/components/List/List.types";
import ReportActionsCell from "modules/Reports/List/ReportActionsCell";
import { ReportLabelsCell } from "modules/Reports/List/ReportLabelsCell";
import { ReportNameCell } from "modules/Reports/List/ReportNameCell";
import { ReportTypeCell } from "modules/Reports/List/ReportTypeCell";

const config: IListConfig = {
  resourceName: "reports",
  columns: [
    {
      title: "Report name",
      name: "name",
      width: 600,
      align: "left",
      Component: ReportNameCell,
    },
    {
      title: "Report type",
      name: "reportType",
      width: 300,
      align: "left",
      Component: ReportTypeCell,
    },
    {
      title: "Label",
      name: "labels",
      width: 300,
      align: "right",
      Component: ReportLabelsCell,
    },
    {
      title: "",
      name: "menu",
      width: 90,
      align: "right",
      Component: ReportActionsCell,
    },
  ],
};

export default config;
