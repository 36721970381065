import { Combobox } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

import { AvatarIcon } from "core/design-system/components/Icon/AvatarIcon";
import { classNames } from "core/helpers/classNames";
import {
  generateRandomAnimalName,
  getContactAvatar,
  hashStringToNumber,
  IGetContactAvatarProps,
} from "core/helpers/contactAvatar";
import { isValidUrl } from "core/helpers/isValidUrl";
import { useClearbitLogo } from "core/hooks/useClearbitLogo";
import { IGroup } from "core/types/Group";

const CompanyResultOption: React.FC<{ result: IGroup; active: boolean }> = ({
  result,
  active,
}) => {
  const avatarURL = getContactAvatar(
    result as unknown as IGetContactAvatarProps,
  );
  const groupId =
    typeof result?.id === "number"
      ? result?.id
      : hashStringToNumber(result?.id);
  const logoURL = useClearbitLogo({ domain: result?.id });
  const url = isValidUrl(avatarURL) ? avatarURL : logoURL;

  return (
    <>
      {url ? (
        <img src={url} alt="" className="mx-auto h-6 w-6 rounded-full" />
      ) : (
        <AvatarIcon h={6} w={6} name={generateRandomAnimalName(groupId)} />
      )}
      <span className="ml-3 flex-auto truncate">{result.name}</span>
      {active && (
        <ChevronRightIcon
          className="ml-3 size-5 flex-none text-gray-400"
          aria-hidden="true"
        />
      )}
    </>
  );
};

export const CompanyResultOptions: React.FC<{ searchResults: IGroup[] }> = ({
  searchResults,
}) => {
  return (
    <>
      {searchResults.map((result: IGroup) => (
        <Combobox.Option
          as="div"
          key={result.id}
          value={result}
          className={({ active }) =>
            classNames(
              "flex cursor-default select-none items-center rounded-md p-2",
              active && "bg-gray-100 text-gray-900",
            )
          }
        >
          {({ active }) => (
            <CompanyResultOption result={result as IGroup} active={active} />
          )}
        </Combobox.Option>
      ))}
    </>
  );
};
