import { Badge } from "@chakra-ui/react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import { useReportSetup } from "core/hooks/useReportSetup";
import { AudienceCountPreview } from "core/modules/reports/setup/Audience/CountPreview";
import AudiencePicker from "core/modules/reports/setup/Editor/Audience";
import { ITemplateConfigSetupSection } from "core/types/TemplateConfig";

interface IAudienceAccordionProps {
  section: ITemplateConfigSetupSection;
}

export const AudienceAccordion: React.FC<IAudienceAccordionProps> = ({
  section,
}) => {
  const { currentReport } = useReportSetup();

  return (
    <div
      className="border-1 my-5 w-full rounded-md border border-gray-200 bg-white px-8 py-4 shadow-sm"
      data-testid="setup-sections-accordion-item"
    >
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button
              className="w-full py-2"
              data-testid="setup-sections-accordion-item-header"
            >
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-medium">Audience</p>
                  <Badge>
                    <p className="text-gray-700">Optional</p>
                  </Badge>
                  {open ? (
                    <ChevronDownIcon className="h-4 w-4 text-gray-600" />
                  ) : (
                    <ChevronRightIcon className="h-4 w-4 text-gray-600" />
                  )}
                </div>
                <AudienceCountPreview
                  audienceFilters={{
                    filterGroups: currentReport.audience?.filterGroups,
                    joinOperator: currentReport.audience?.joinOperator,
                  }}
                />
              </div>
            </Disclosure.Button>
            <Disclosure.Panel data-testid="setup-sections-accordion-item-body">
              <div className="flex flex-col">
                <div className="mt-2 flex flex-col gap-4">
                  <p className="w-4/5 text-xs text-gray-600">
                    {section.description}
                  </p>
                  <AudiencePicker section={section} />
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
