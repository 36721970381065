import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { InlineWidget } from "react-calendly";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";

const isCalendlyEventScheduled = (e: {
  origin: string;
  data: {
    event: string;
    type: string;
  };
}) => {
  return (
    e.origin === "https://calendly.com" &&
    e.data.event &&
    e.data.event.indexOf("calendly.event_scheduled") === 0
  );
};
declare global {
  interface Window {
    analytics: any;
  }
}

export const ScheduleCall: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const { updateOnboardingChecklist } = useOnboardingChecklist();

  useEffect(() => {
    window.addEventListener("message", function (e) {
      if (isCalendlyEventScheduled(e)) {
        window.analytics.track(
          "onboarding_call_booked",
          { app_id: appId },
          { context: { groupId: appId } },
        );

        updateOnboardingChecklist({ appId, step: "call_booked" });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Flex direction="column" gap={4}>
      <Text fontSize="sm">
        With his expertise from 1,000+ onboardings, Alberto will personally
        guide you in setting up your workspace and turn you into a power user.
      </Text>
      <Flex
        justify="center"
        border="1px"
        borderRadius="lg"
        borderColor="gray.200"
      >
        <Box w="100%">
          <InlineWidget url="https://calendly.com/alberto-junedotso" />
        </Box>
      </Flex>
    </Flex>
  );
};
