import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ArrowPathIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { Navigate } from "react-router-dom";

import HubspotIcon from "core/design-system/components/Icon/Interface/HubspotIcon";
import { trackEvent } from "core/helpers/trackEvent";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useHubspotSettings } from "core/hooks/useHubspotSettings";
import { Settings } from "modules/Settings/Integrations/Hubspot/Settings";
import { UninstallModal } from "modules/Settings/Integrations/Hubspot/UninstallModal";
import * as Integration from "modules/Settings/Integrations/Integration";
import { Install } from "routes/Settings/Integrations/Hubspot/Install";

const CONTENT: Integration.IntegrationMetadata = {
  title: "HubSpot",
  description: "Send your June data to HubSpot",
  logoComponent: <HubspotIcon color="#ff7a59" w="full" h="full" />,
  docsUrl: "https://help.june.so/en/articles/6782394-hubspot-integration",
  isBeta: false,
};

export const PaywallUpgradeHeader: React.FC = () => {
  return (
    <Text fontWeight="bold" fontSize="2xl">
      Get access to the HubSpot
    </Text>
  );
};

export const PaywallUpgradeSubHeader: React.FC = () => {
  return (
    <Text color="gray.700" fontSize="md" fontWeight="normal" px={10}>
      Upgrade to start using the{" "}
      <Text as="span" fontWeight="bold">
        HubSpot integration
      </Text>{" "}
      and with June
    </Text>
  );
};

const Action = ({
  hasHubspotInstalled,
  onOpenConfirmation,
  isConfirmationOpen,
  onCloseConfirmation,
  onDeleteSettings,
  onReinstall,
  id,
  isReinstalling,
}: {
  hasHubspotInstalled: boolean;
  onOpenConfirmation: () => void;
  isConfirmationOpen: boolean;
  onCloseConfirmation: () => void;
  onDeleteSettings: () => void;
  id: number;
  onReinstall: () => void;
  isReinstalling: boolean;
}) => {
  return (
    <div className="flex">
      <div className="flex items-center gap-2">
        {!hasHubspotInstalled ? (
          <Button
            colorScheme="purple"
            onClick={() => {
              trackEvent({ eventName: "clicked_install_hubspot", appId: id });
              window.location.replace(
                `${import.meta.env.VITE_API_HOST}/auth/hubspot/install`,
              );
            }}
          >
            Connect HubSpot
          </Button>
        ) : (
          <>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<EllipsisVerticalIcon className="h-5 w-5" />}
                colorScheme="gray"
              />
              <MenuList boxShadow="lg">
                <MenuItem
                  icon={<ArrowPathIcon className="h-4 w-4" />}
                  onClick={onReinstall}
                  color="gray.700"
                  fontWeight="medium"
                >
                  {isReinstalling ? <Spinner size="sm" /> : "Reconnect"}
                </MenuItem>
                <MenuItem
                  icon={<TrashIcon className="h-4 w-4" />}
                  onClick={onOpenConfirmation}
                  color="red.500"
                  fontWeight="medium"
                >
                  Uninstall
                </MenuItem>
              </MenuList>
            </Menu>

            <UninstallModal
              isOpen={isConfirmationOpen}
              onClose={onCloseConfirmation}
              onUninstall={() => {
                trackEvent({
                  eventName: "clicked_hubspot_uninstall",
                  appId: id,
                });
                onDeleteSettings();
              }}
              integration="hubspot"
            />
          </>
        )}
      </div>
    </div>
  );
};

export const Hubspot: React.FC = () => {
  const { id } = useCurrentApp();
  const {
    onDeleteSettings,
    hasHubspotInstalled,
    isLoadingSetting,
    onReinstall,
    isReinstalling,
  } = useHubspotSettings();
  const {
    isOpen: isConfirmationOpen,
    onClose: onCloseConfirmation,
    onOpen: onOpenConfirmation,
  } = useDisclosure();

  if (window.location.pathname.includes("settings/integrations/hubspot")) {
    return <Navigate to={`/a/${id}/integrations/hubspot`} />;
  }

  return (
    <>
      <Integration.Container isExpanded>
        <Integration.Header
          metadata={CONTENT}
          ActionComponent={() => (
            <Action
              {...{
                hasHubspotInstalled,
                onOpenConfirmation,
                isConfirmationOpen,
                onCloseConfirmation,
                onDeleteSettings,
                id,
                onReinstall,
                isReinstalling,
              }}
            />
          )}
        />
        <Integration.Body>
          <Integration.Section>
            {isLoadingSetting && <Spinner />}
            {hasHubspotInstalled && <Settings />}
            {!hasHubspotInstalled && <Install />}
          </Integration.Section>
        </Integration.Body>
      </Integration.Container>
    </>
  );
};
