import { Button, useClipboard } from "@chakra-ui/react";
import {
  ArrowLongRightIcon,
  CheckCircleIcon,
  Square2StackIcon,
} from "@heroicons/react/16/solid";
import React from "react";
import { useNavigate } from "react-router-dom";

import { SDK } from "core/constants/apiKeys";
import { useApiKeys } from "core/hooks/useApiKeys";
import { ApiKeyTypes } from "core/models/apiKeys";
import { IViewInsight } from "core/models/viewInsights";

export const APIContent: React.FC<{
  viewInsight: IViewInsight;
}> = ({ viewInsight }) => {
  const { apiKeys, isFetching } = useApiKeys({
    appId: viewInsight.appId,
    keyType: ApiKeyTypes.SECRET,
    source: SDK,
  });
  const navigate = useNavigate();
  const BASE_URL = import.meta.env.VITE_API_HOST || "http://localhost:3001";
  const code = `curl -X GET ${BASE_URL}/api/platform/insights/sql/${viewInsight.id} \\
-H "Authorization: Basic ${apiKeys?.[0]?.key}" \\
-H "Content-Type: application/json" \\
-d "{\\"group_id\\":\\"GROUP_ID\\"}"  // Optional`;
  const { onCopy, hasCopied } = useClipboard(code);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (!apiKeys || apiKeys.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-4 pt-7">
        <p className="text-sm font-medium">You don't have any API keys</p>
        <Button
          colorScheme="purple"
          onClick={() =>
            navigate(`/a/${viewInsight.appId}/settings/integrations/api`)
          }
        >
          Create API Key
          <ArrowLongRightIcon className="ml-2 h-4 w-4" />
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <p className="text-sm">Fetch the data for this insight via API</p>
      <div className="flex  gap-2 rounded-md bg-gray-100 p-4">
        <code className="text-xs">
          <p>{`curl -X GET ${BASE_URL}/api/platform/insights/sql/${viewInsight.id}`}</p>
          <p>{`-H "Authorization: Basic ${apiKeys?.[0]?.key}"`}</p>
          <p>{`-H "Content-Type: application/json"`}</p>
          <p>{`-d "{\\"group_id\\":\\"GROUP_ID\\"}" // Optional`}</p>
        </code>
      </div>
      <div className="flex justify-end">
        <Button
          onClick={() => onCopy()}
          leftIcon={
            hasCopied ? (
              <CheckCircleIcon className="h-4 w-4" />
            ) : (
              <Square2StackIcon className="h-4 w-4" />
            )
          }
        >
          {hasCopied ? "Copied" : "Copy"}
        </Button>
      </div>
    </div>
  );
};
