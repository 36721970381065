import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";

import { PaywallPopover } from "core/components/Paywall/Popover";
import { useEditDashboard } from "core/hooks/useEditDashboard";
import { useSqlEditor } from "core/hooks/useSqlEditor";
import { IViewInsight } from "core/models/viewInsights";
import { Plan } from "core/types/App";
import { cx } from "helpers/cx";
import { LayoutContainer } from "modules/ViewInsight/SQLEditor/LayoutContainer";

export const SQLEditor: React.FC<{
  viewInsight: IViewInsight;
  viewId: number;
  isOpen: boolean;
  onClose: () => void;
  onRefetch?: () => void;
  execute?: boolean;
}> = ({ isOpen, onClose, viewId, viewInsight, execute = false, onRefetch }) => {
  const { groupId } = useParams();
  const {
    onCreate,
    prompt,
    setPrompt,
    query,
    setQuery,
    suggestion,
    data,
    size,
    setSize,
    graphType,
    setGraphType,
    colorPalette,
    setColorPalette,
    xAxis,
    setXAxis,
    yAxis,
    setYAxis,
    title,
    setTitle,
    isPromptFetching,
    isExecuteFetching,
    isPromptLoading,
    isExecuteLoading,
    onPrompt,
    onExecute,
    onApply,
    onReject,
    onInvalidateInsights,
    hasError,
    showAIAssistant,
    setShowAIAssistant,
  } = useSqlEditor({ viewInsight, execute });
  const { clearOpenedViewInsight } = useEditDashboard();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent maxH="98vh" minH="98vh" w="99vw" borderRadius="lg">
        <ModalHeader>Create SQL insight</ModalHeader>
        <ModalCloseButton />
        <ModalBody h="full" display="flex" flexDirection="column">
          <LayoutContainer
            viewId={viewId}
            prompt={prompt}
            setPrompt={setPrompt}
            query={query}
            setQuery={setQuery}
            suggestion={suggestion}
            data={data}
            size={size}
            setSize={setSize}
            graphType={graphType}
            setGraphType={setGraphType}
            colorPalette={colorPalette}
            setColorPalette={setColorPalette}
            xAxis={xAxis}
            setXAxis={setXAxis}
            yAxis={yAxis}
            setYAxis={setYAxis}
            title={title}
            setTitle={setTitle}
            isPromptFetching={isPromptFetching}
            isExecuteFetching={isExecuteFetching}
            isPromptLoading={isPromptLoading}
            isExecuteLoading={isExecuteLoading}
            onPrompt={onPrompt}
            onExecute={onExecute}
            onApply={onApply}
            onReject={onReject}
            hasError={hasError}
            showAIAssistant={showAIAssistant}
            setShowAIAssistant={setShowAIAssistant}
          />
        </ModalBody>
        <Divider mt={2} />
        <ModalFooter>
          <div className="flex w-full items-center justify-between ">
            <div
              className={cx(
                "flex items-center rounded-md p-2 px-3",
                groupId
                  ? "bg-purple-100 text-purple-800"
                  : "bg-gray-100 text-gray-900",
              )}
            >
              <InformationCircleIcon className="mr-1 h-4 w-4" />
              <p className="text-xs">
                {groupId
                  ? `Queries will only apply on the company which is being viewed`
                  : `SQL insights do not support audience filters set at the
                dashboard level`}
              </p>
            </div>
            <div>
              {viewInsight.persisted ? (
                <Button
                  colorScheme="purple"
                  onClick={() => {
                    onClose();
                    onInvalidateInsights();
                    clearOpenedViewInsight();
                  }}
                >
                  Done
                </Button>
              ) : (
                <>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                  <PaywallPopover
                    feature="SQL insights"
                    redirect="home"
                    plan={Plan.Pro}
                  >
                    <Button
                      colorScheme="purple"
                      onClick={async () => {
                        await onCreate();
                        await onInvalidateInsights();
                        clearOpenedViewInsight();
                        onClose();
                      }}
                    >
                      Add
                    </Button>
                  </PaywallPopover>
                </>
              )}
            </div>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
