import humps from "humps";

import axios from "core/initializers/axios";
import { AppThunk } from "core/initializers/store";
import { slice } from "core/models/contact/index";
import { IContactGroups } from "core/types/Contact";

interface IContactCompaniesParams {
  appId: string | number;
  id: string;
}

export const fetchOneContactCompanies =
  ({ appId, id }: IContactCompaniesParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.fetchingContactCompanies({ id }));
      const { data } = await axios.get<IContactGroups>(
        `/contacts/${encodeURIComponent(id)}/companies`,
        {
          params: humps.decamelizeKeys({ appId }),
        },
      );

      dispatch(slice.actions.upsertOneContactCompanies({ id, data }));
    } catch (e: unknown) {
      const title = "Failed to fetch contact companies";
      const description = e?.response?.data?.error;
      const error = `${title}: ${description}`;
      dispatch(slice.actions.fetchingContactCompaniesFailed({ id, error }));
    }
  };
