import { Flex, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { ReferenceLine } from "recharts";

import { IReport } from "@/core/types/Report";
import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import {
  CommonDot,
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import colors from "core/design-system/constants/theme/colors";
import { computeGrowth } from "core/helpers/computeGrowth";
import { useTooltipCoordinate } from "core/hooks/useTooltipCoordinates";
import { CustomTooltip } from "core/modules/reports/report-types/NewGroups/CustomTooltip";
import { NewGroupsList } from "core/modules/reports/report-types/NewGroups/NewGroupsList";

// @ts-nocheck

interface ILineChart {
  unit: string;
  data: any;
  dataKey: string;
  previewMode: boolean;
  sharingMode: boolean;
  hasHour: boolean;
  intervalType: number;
  showInProgress?: boolean;
  report: IReport;
}

interface ILineChartData {
  x: string;
  y: number;
  growth: number;
  previousDate: string;
  date: Date;
  hour: string;
}

export const LineChart = ({
  unit,
  data,
  dataKey = "newGroups",
  previewMode,
  sharingMode,
  intervalType,
  hasHour,
  showInProgress = true,
  report,
}: ILineChart) => {
  const {
    isOpen: isAudiencePanelOpen,
    onOpen: onAudienceOpen,
    onClose: onAudienceClose,
  } = useDisclosure();
  const { coordinates, onMouseMove } = useTooltipCoordinate(-50);

  const [humanizedDate, setHumanizedDate] = useState("");
  const [date, setDate] = useState("");
  const [hour, setHour] = useState("");
  const [newGroupsCount, setNewGroupsCount] = useState(0);

  const onDataPointClick = (_data, { payload }) => {
    if (!sharingMode && !previewMode) {
      onAudienceOpen();
      setHumanizedDate(payload.x);
      setDate(payload.date);
      if (typeof payload.y === "number") {
        setNewGroupsCount(payload.y);
      }
      if (typeof payload.currentY === "number") {
        setNewGroupsCount(payload.currentY);
      }
      if (hasHour) {
        setHour(payload.hour);
      }
    }
  };

  let marker;

  const formattedData = data.reduce(
    (formatted: ILineChartData[], row, currentIndex) => {
      const lastRow = formatted[formatted.length - 1];
      const nextRow = data[currentIndex + 1];
      const previousDate = lastRow?.x;
      const growth = lastRow && computeGrowth(lastRow.y, row[dataKey]);

      if (!marker && row.incomplete) {
        marker = lastRow.x;
      }

      formatted.push({
        x: row.humanizedDate,
        y: !row.incomplete ? row[dataKey] : undefined,
        currentY:
          nextRow?.incomplete || row.incomplete ? row[dataKey] : undefined,
        hideY: nextRow?.incomplete,
        growth,
        previousDate,
        date: row.date,
        hour: row.hour,
      });

      return formatted;
    },
    [],
  );

  if (!showInProgress) {
    formattedData.pop();
  }

  return (
    <Flex justify="center" h={300} onMouseMove={onMouseMove}>
      <AreaChart
        margin={{ top: 30, left: 0, right: 50, bottom: 20 }}
        height="100%"
        data={formattedData}
      >
        <defs>
          <linearGradient id="purpleGradient" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={colors.purple[400]}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={colors.purple[100]}
              stopOpacity={0.4}
            />
          </linearGradient>
          <linearGradient
            id="currentPeriodGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors.gray[100]} stopOpacity={0.4} />
          </linearGradient>
        </defs>
        <CommonGrid />
        <CommonXAxis dataKey="x" />
        <CommonYAxis />
        <CommonTooltip
          trigger="hover"
          position={coordinates}
          content={
            <CustomTooltip
              onClick={onDataPointClick}
              unit={unit}
              previewMode={previewMode}
              data={formattedData}
            />
          }
        />
        {showInProgress && (
          <ReferenceLine
            x={marker}
            stroke={colors.gray[600]}
            strokeDasharray="3 3"
          />
        )}
        <Area
          dataKey="y"
          activeDot={
            <CommonDot
              r={7}
              onClick={onDataPointClick}
              cursor={!previewMode && "pointer"}
            />
          }
          type="monotone"
          fillOpacity={1}
          fill="url(#purpleGradient)"
        />
        <Area
          type="monotone"
          fillOpacity={1}
          fill="url(#currentPeriodGradient)"
          dataKey="currentY"
          stroke={colors.gray[500]}
          activeDot={
            <CommonDot
              onClick={onDataPointClick}
              r={7}
              cursor={!previewMode && "pointer"}
            />
          }
        />
      </AreaChart>
      {!sharingMode && !previewMode && report && (
        <NewGroupsList
          report={report}
          isOpen={isAudiencePanelOpen}
          onClose={onAudienceClose}
          humanizedDate={humanizedDate}
          date={date}
          hour={hour}
          newGroupsCount={newGroupsCount}
          intervalType={intervalType}
        />
      )}
    </Flex>
  );
};
