import { ComponentDefaultProps } from "@chakra-ui/react";
import {
  CartesianGrid as RechartsCartesianGrid,
  Cell as RechartsCell,
  Dot as RechartsDot,
  Legend as RechartsLegend,
  Tooltip as RechartsTooltip,
  XAxis as RechartsXAxis,
  YAxis as RechartsYAxis,
} from "recharts";

import { TickText } from "core/design-system/charts/TickText";
import colors from "core/design-system/constants/theme/colors";

// @ts-nocheck

const CommonCustomTooltip: React.FC<{
  label: string;
  payload: any[];
  active: boolean;
  yAxisDataAccessor: string | null;
  xAxisDataAccessor: string | null;
  yAxisLabelAffix: string | null;
  yAxisLabelPrefix: string | null;
}> = ({
  label,
  payload,
  active,
  yAxisDataAccessor = null,
  xAxisDataAccessor = null,
  yAxisLabelAffix = null,
  yAxisLabelPrefix = null,
}) => {
  if (active && payload && payload.length) {
    const xAccessor = xAxisDataAccessor || "x";
    const yAccessor = yAxisDataAccessor || "y";

    return (
      <div className="pointer-events-none z-overlay flex flex-col gap-y-2 rounded-md border border-gray-200 bg-white p-1.5 shadow-md">
        <div className="flex w-full items-center gap-x-1">
          <div className="h-4 w-1 rounded-full bg-gray-200" />
          <div className="text-xs font-medium">
            {xAccessor ? payload[0].payload[xAccessor] : label}
          </div>
        </div>
        {payload.map((item) => (
          <div key={item.name}>
            <div className="flex w-full items-center gap-x-1 text-xs">
              <div
                className="h-2 w-2 rounded-full"
                style={{ backgroundColor: item.color }}
              />
              {yAxisLabelPrefix}
              <p className="font-mono">{item.payload[yAccessor]}</p>
              <p>{yAxisLabelAffix?.split(`-`).join(` `)}</p>
              {item.name && <p className="font-mono">{item.name}</p>}
            </div>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

class CommonXAxis extends RechartsXAxis {
  static defaultProps = {
    ...RechartsXAxis.defaultProps,
    // Customized props
    axisLine: { stroke: colors.gray[50] },
    tickLine: false,
    tick: ({ y, ...props }) => {
      const value = props?.payload?.value;
      return (
        <TickText y={y + 15} {...props}>
          {value}
        </TickText>
      );
    },
  };
}

class CommonYAxis extends RechartsYAxis {
  static defaultProps = {
    ...RechartsYAxis.defaultProps,
    // Customized props
    axisLine: false,
    tickLine: false,
    tick: ({ x, ...props }) => (
      <TickText x={x - 5} {...props}>
        {props.payload.value}
      </TickText>
    ),
  };
}

class CommonGrid extends RechartsCartesianGrid {
  static defaultProps = {
    ...RechartsCartesianGrid.defaultProps,
    // Customized props
    vertical: false,
    stroke: colors.gray[50],
  } as ComponentDefaultProps;
}

class CommonTooltip extends RechartsTooltip {
  static defaultProps = {
    ...RechartsTooltip.defaultProps,
    // Customized props
    cursor: { fill: colors.gray[50], stroke: colors.gray[200] },
    content: <CommonCustomTooltip />,
    wrapperStyle: { outline: "none", zIndex: 9999 },
    allowEscapeViewBox: { x: true, y: true },
    position: { x: "auto", y: "auto" },
  };
}

class CommonCell extends RechartsCell {
  static defaultProps = {
    ...RechartsCell.defaultProps,
    // Customized props
  };
}

class CommonDot extends RechartsDot {
  static defaultProps = {
    ...RechartsDot.defaultProps,
    // Customized props
    r: 5,
  };
}

class CommonLegend extends RechartsLegend {
  static defaultProps = {
    ...RechartsLegend.defaultProps,
    // Customized props
  };
}

export {
  CommonXAxis,
  CommonYAxis,
  CommonGrid,
  CommonTooltip,
  CommonCell,
  CommonDot,
  CommonLegend,
  CommonCustomTooltip,
};
