import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Spinner } from "@/Components/ui/spinner";
import { useNavigation } from "core/hooks/useNavigation";
import { useReportSetup } from "core/hooks/useReportSetup";
import { NewReportSetup } from "core/modules/reports/setup/NewReportSetup";
import { IEmptyReportState, IReport } from "core/types/Report";

const instanceOfIReport = (
  object: IReport | IEmptyReportState,
): object is IReport => {
  return (
    "reportType" in object &&
    "reportType" in object &&
    "id" in object &&
    "state" in object &&
    "config" in object
  );
};

const NewReportSetupRoute = () => {
  const { appId, reportId } = useParams<{ appId: string; reportId: string }>();
  const { navigation, toggleSidebar } = useNavigation();
  const { currentReport, isLoading, fetchReport } = useReportSetup();
  const isValidReport =
    Object.keys(currentReport).length !== 0 &&
    typeof currentReport?.reportType === "number";

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isValidReport) {
      fetchReport();
    }
  }, [appId, reportId, fetchReport, isValidReport]);

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size="medium" />
      </div>
    );
  }

  if (isValidReport && instanceOfIReport(currentReport)) {
    return <NewReportSetup report={currentReport} />;
  }
  return <></>;
};

export default NewReportSetupRoute;
