import { createIcon } from "@chakra-ui/icons";
import React from "react";

const AttioIcon = createIcon({
  path: (
    <svg
      width="31"
      height="25"
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.7538 17.2064L27.2422 13.1868C27.2422 13.1868 27.2329 13.17 27.2273 13.1625L27.0292 12.8467C26.6555 12.2469 26.0089 11.8881 25.3025 11.8862L21.2568 11.8731L20.9746 12.3254L16.1403 20.0618L15.873 20.4897L17.8987 23.7263C18.2725 24.3281 18.919 24.6868 19.631 24.6868H25.3007C25.9996 24.6868 26.6611 24.3187 27.0311 23.7282L27.231 23.4087C27.231 23.4087 27.2385 23.3993 27.2404 23.3956L29.7556 19.3704C30.1686 18.7126 30.1686 17.8623 29.7556 17.2064H29.7538ZM28.9876 18.8901L26.4723 22.9153C26.4611 22.934 26.448 22.9489 26.4368 22.9639C26.349 23.0629 26.235 23.076 26.1846 23.076C26.1266 23.076 25.9846 23.0592 25.893 22.9134L23.3778 18.8883C23.3497 18.8434 23.3254 18.7967 23.303 18.7462C23.2806 18.6976 23.2638 18.6491 23.2488 18.5986C23.1928 18.3968 23.1928 18.18 23.2488 17.9782C23.2769 17.8792 23.3198 17.7801 23.3759 17.6904L25.8874 13.669C25.8874 13.669 25.8912 13.6634 25.893 13.6596C25.9528 13.5699 26.0276 13.5288 26.093 13.5157C26.1192 13.5083 26.1416 13.5064 26.1603 13.5027C26.1696 13.5027 26.1789 13.5027 26.1883 13.5027C26.2462 13.5027 26.3901 13.5213 26.4798 13.6671L28.9913 17.6867C29.2212 18.0529 29.2212 18.5239 28.9913 18.8901H28.9876Z"
        fill="currentColor"
      />
      <path
        d="M22.3217 7.48047C22.7328 6.82077 22.7328 5.97237 22.3217 5.31647L19.8101 1.29689L19.6008 0.95865C19.2252 0.3588 18.5787 0 17.8685 0H12.1989C11.4907 0 10.8441 0.3588 10.4666 0.96052L0.313942 17.2089C0.110254 17.5341 0 17.9097 0 18.2909C0 18.6721 0.108385 19.0477 0.312074 19.371L3.03478 23.7307C3.41038 24.3324 4.05696 24.6893 4.76519 24.6893H10.4348C11.1468 24.6893 11.7934 24.3306 12.1671 23.7288L12.3746 23.3999C12.3746 23.3999 12.3746 23.3999 12.3746 23.3962L12.3783 23.3887L14.4021 20.1521L20.4006 10.5526L22.3179 7.48227L22.3217 7.48047ZM21.7293 6.39847C21.7293 6.60587 21.6714 6.81517 21.5536 7.00017L11.6084 22.9178C11.5187 23.0617 11.3748 23.0785 11.3169 23.0785C11.2589 23.0785 11.1169 23.0617 11.0254 22.9178L8.51195 18.8908C8.28396 18.5264 8.28396 18.0573 8.51195 17.6892L18.4572 1.77527C18.5469 1.62951 18.6908 1.6127 18.7487 1.6127C18.8066 1.6127 18.9505 1.62951 19.0421 1.77714L21.5536 5.79677C21.6714 5.98177 21.7293 6.19097 21.7293 6.39847Z"
        fill="currentColor"
      />
    </svg>
  ),
  viewBox: "0 0 31 25",
});

export default AttioIcon;
