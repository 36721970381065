import { useCurrentApp } from "core/hooks/useCurrentApp";
import useRequest from "core/hooks/useRequest";

export function useSlack() {
  const { id: appId } = useCurrentApp();

  const { data: slackApp } = useRequest({
    url: `/slack_apps`,
    params: { appId },
    withCredentials: true,
  });

  const hasSlackAppInstalled = slackApp && slackApp.length > 0;

  return { hasSlackAppInstalled };
}
