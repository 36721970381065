import { useEffect } from "react";
import {
  AreaChart as RechartsAreaChart,
  Area as RechartsArea,
  ResponsiveContainer as RechartsResponsiveContainer,
} from "recharts";

import {
  isAnimationDisabled,
  addLoadedIdToElement,
  isScreenshotMode,
} from "core/design-system/constants/charts";
import colors from "core/design-system/constants/theme/colors";

const AreaChart = ({ children, data = [], ...props }) => {
  useEffect(() => {
    addLoadedIdToElement();
  }, []);

  return (
    <RechartsResponsiveContainer width="100%" height="100%">
      <RechartsAreaChart
        height="100%"
        width="100%"
        margin={{ top: 10, left: 10, right: 10, bottom: 10 }}
        data={data}
        {...props}
      >
        {children}
      </RechartsAreaChart>
    </RechartsResponsiveContainer>
  );
};

class Area extends RechartsArea {
  static defaultProps = {
    ...RechartsArea.defaultProps,
    // Customized props
    type: "monotone",
    stroke: colors.purple[500],
    strokeWidth: 1.5,
    fill: colors.purple[300],
    activeDot: { r: 5 },
    dataKey: "y",
    isAnimationActive: !isAnimationDisabled && !isScreenshotMode,
  };
}

export { AreaChart, Area };
