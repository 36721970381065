import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";

import { IGraphInsight } from "@/core/types/reports/retention";
import InsightCard from "core/components/InsightCard/Index";
import { Benchmarks, sortInsights } from "core/components/Insights/Benchmarks";
import { buildRetentionHighlight } from "core/constants/report-configurations/report-types/retention";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { CohortDropdown } from "core/modules/reports/report-types/Retention/Cohort/Dropdown";
import { GraphInsight } from "core/modules/reports/report-types/Retention/GraphInsight";
import { RetentionGraph } from "core/modules/reports/report-types/Retention/RetentionGraph";
import { IReportInsight } from "core/types/Report";

const mockData = {
  status: 200,
  retentionData: [
    {
      id: "Mar 01 - Mar 07",
      data: [
        { x: "W0", y: 100.0 },
        { x: "W1", y: 47.62 },
        { x: "W2", y: 41.14 },
        { x: "W3", y: 40.91 },
        { x: "W4", y: 40.1 },
        { x: "W5", y: 42.13 },
        { x: "W6", y: 38.61 },
        { x: "W7", y: 35.96 },
        { x: "W8", y: 40.01 },
        { x: "W9", y: 41.0 },
        { x: "W10", y: 42.0 },
        { x: "W11", y: 38.0 },
        { x: "W12", y: 42.0 },
      ],
    },
  ],
  retentionDataByCohort: [{ data: [{ x: "W0", y: 100.0 }] }],
};

const LOW = 5;
const AVERAGE = 15;
const GOOD = 20;
const GREAT = 40;

const GRAPH_INSIGHT: IGraphInsight[] = [
  {
    lowRange: 40,
    highRange: 100,
    value: GREAT,
    retentionTrendInsight:
      "You have a stable long-term retention parallel to the x-axis. Congrats!",
    percentRuleInsight:
      "All good here but less than 20% retention you’ve got to pay attention, no matter what space you’re in.",
    benchmarkComparison:
      "You are in the great cohort compared to other B2B Saas companies. Outstanding job!",
    firstDay:
      "A good portion of new users return after the first days. Great job 🎉",
    benchmarkLabel: "Great",
  },
  {
    lowRange: 20,
    highRange: 39,
    value: GOOD,
    retentionTrendInsight:
      "You have a stable long-term retention parallel to the x-axis. Congrats!",
    percentRuleInsight:
      "All good here but less than 20% retention you’ve got to pay attention, no matter what space you’re in.",
    benchmarkComparison:
      "You are in the good cohort compared to other B2B Saas companies. Great job 🎉",
    firstDay:
      "A good portion of new users return after the first days. Great job 🎉",
    benchmarkLabel: "Good",
  },
  {
    lowRange: 10,
    highRange: 19,
    value: AVERAGE,
    retentionTrendInsight:
      "You have a stable long-term retention parallel to the x-axis. Congrats!",
    percentRuleInsight:
      "If you have anything less than 20% retention, you've got to pay attention, no matter what space you're in.",
    benchmarkComparison:
      "You are below the average compared to other B2B Saas companies. You're gonna get there!",
    firstDay:
      "A large portion of new users don’t return after the first days. Focus on product onboarding and early use.",
    benchmarkLabel: "Average",
  },
  {
    lowRange: 0,
    highRange: 9,
    value: LOW,
    retentionTrendInsight:
      "You don't have a stable long-term retention parallel to the x-axis. You should work on it!",
    percentRuleInsight:
      "If you have anything less than 20% retention, you've got to pay attention, no matter what space you're in.",
    benchmarkComparison:
      "You are below the average compared to other B2B Saas companies. You'll get there!",
    firstDay:
      "A large portion of new users don’t return after the first days. Focus on product onboarding and early use.",
    benchmarkLabel: "Low",
  },
];

function shouldShowInsights(retentionData: any) {
  const aggregatedData =
    retentionData?.retentionData && retentionData?.retentionData[0]?.data;

  return aggregatedData?.length !== 1;
}

export const GraphContainer: React.FC<IReportInsight> = ({
  report,
  config,
  sharingSecretToken = "null",
  previewMode,
  sharingMode,
  screenshotMode,
  showYAxis,
}) => {
  const insight = config.insights.find(
    ({ slug }) => slug === "retention-graph",
  )!;
  const isSetupValid = config.validateSetup(report.config);
  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      insight,
      sharingSecretToken,
      previewMode,
      skip: !isSetupValid,
    });

  const data = previewMode ? mockData : (response && response.data) || response;
  const [selectedAggregated, setSelectedAggregated] = useState(true);
  const [selectedCohorts, setSelectedCohorts] = useState([]);
  const cohorts = useMemo(
    () => data?.retentionDataByCohort?.map((m: any) => m.id),
    [data],
  );

  const { value: retentionPercent } = buildRetentionHighlight(data);

  let graphInsightOverview: (typeof GRAPH_INSIGHT)[0];

  if (GRAPH_INSIGHT) {
    [graphInsightOverview] = GRAPH_INSIGHT.filter(
      (insight) =>
        retentionPercent >= insight.lowRange &&
        retentionPercent <= insight.highRange,
    );
  }

  const noData = data?.retentionData?.length === 0;

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          >
            {cohorts && !screenshotMode && (
              <CohortDropdown
                cohorts={cohorts}
                selectedAggregated={selectedAggregated}
                selectedCohorts={selectedCohorts}
                onSelectAggregated={(selected) =>
                  setSelectedAggregated(selected)
                }
                onSelectCohorts={(selected) => setSelectedCohorts(selected)}
              />
            )}
          </Card.Header>
          <Card.Body
            isLoading={!data || isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            <Flex
              px={screenshotMode ? undefined : 4}
              direction="column"
              bg="white"
            >
              {noData ? (
                <Card.EmptyState />
              ) : (
                <RetentionGraph
                  data={data}
                  selectedCohorts={selectedCohorts}
                  selectedAggregated={selectedAggregated}
                  showYAxis={showYAxis}
                />
              )}
              {!screenshotMode && !noData && shouldShowInsights(data) && (
                <Box>
                  <GraphInsight
                    graphInsightOverview={graphInsightOverview}
                    retentionPercent={retentionPercent}
                  />
                  <Flex bg="gray.50" mx={5} mb={8} p={4} borderRadius="lg">
                    <Flex w={"100%"} mr={5}>
                      <Box>
                        <Text fontSize="sm" fontWeight="semibold" as="span">
                          Your user retention is {retentionPercent}%
                        </Text>{" "}
                        <Text color="gray.700" mt={1} fontSize={"sm"}>
                          {
                            GRAPH_INSIGHT.find(
                              (insight) =>
                                Math.round(retentionPercent) >=
                                  insight.lowRange &&
                                Math.round(retentionPercent) <=
                                  insight.highRange,
                            )?.benchmarkComparison
                          }
                        </Text>
                      </Box>
                    </Flex>
                    <Box borderRadius={"2%"} w={"100%"} pl={5}>
                      {GRAPH_INSIGHT.concat([
                        {
                          benchmarkLabel: "You",
                          value: retentionPercent,
                          color: "purple.400",
                          fontWeight: "semibold",
                          fontColor: "gray.700",
                        },
                      ] as any)
                        .sort(sortInsights)
                        .map((insight: any, i) => (
                          <Benchmarks
                            key={i}
                            label={insight.benchmarkLabel}
                            value={insight.value}
                            color={insight.color}
                            fontWeight={insight.fontWeight}
                            fontColor={insight.fontColor}
                            relativeWidth={2}
                          />
                        ))}
                    </Box>
                  </Flex>
                </Box>
              )}
            </Flex>
          </Card.Body>
          {insight.description && !screenshotMode && (
            <Card.Footer bg="white">
              <Card.DescriptionAccordion insight={insight} />
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
};
