import { Flex } from "@chakra-ui/react";

import { IAudience } from "core/types/Audience";
import AudienceActionsMenu from "modules/Audiences/List/AudienceActionsMenu";

interface IAudienceActionsCell {
  props: IAudience;
}

const AudienceActionsCell: React.FC<IAudienceActionsCell> = ({
  props: audience,
}) => {
  return (
    <Flex>
      <AudienceActionsMenu audience={audience} />
    </Flex>
  );
};

export default AudienceActionsCell;
