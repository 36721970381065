import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import type { SnakeCasedPropertiesDeep } from "type-fest";

import { FRESHPAINT, RUDDERSTACK, SDK, SEGMENT } from "core/constants/apiKeys";
import { baseQuery } from "core/initializers/rtk";

interface IGetOnboardingChecklistArgs {
  appId: number | string;
}

interface IUpdateOnboardingChecklistArgs {
  appId: number | string;
  step: string;
  value?: string;
}

interface ILastIdentifyArgs {
  appId: number;
}

interface ILastTrackArgs {
  appId: number;
}

interface ILastGroupArgs {
  appId: number;
}

export interface ILastIdentifyResponse {
  appId: number;
  id: string;
  traits: {
    [key: string]: string;
  };
  timestamp: string;
}

export interface ILastTrackResponse {
  appId: number;
  name: string;
  userId: string;
  timestamp: string;
  anonymousId: string;
  properties: {
    [key: string]: string;
  };
  context: {
    [key: string]: string;
  };
}

export interface ILastGroupResponse {
  appId: number;
  id: string;
  traits: {
    [key: string]: string;
  };
  timestamp: string;
}

export type DataSource =
  | typeof SDK
  | typeof SEGMENT
  | typeof RUDDERSTACK
  | typeof FRESHPAINT;

export interface IOnboardingChecklist {
  id: number;
  appId: number;
  connectedSegment: boolean;
  connectedDataSource: boolean;
  isIdentifyingUsers: boolean;
  isIdentifyingGroups: boolean;
  isSendingEvents: boolean;
  liveFeedChecked: boolean;
  invitedTeammate: boolean;
  reportSlackDigestEnabled: boolean;
  eventSlackNotificationEnabled: boolean;
  callBooked: boolean;
  demoWorkspaceChecked: boolean;
  importRequested: boolean;
  importSettingsChecked: boolean;
  eventListChecked: boolean;
  createdAt: string;
  updatedAt: string;
  dataSource: DataSource;
}

export const onboardingChecklistApi = createApi({
  baseQuery,
  reducerPath: "onboardingChecklistApi",
  tagTypes: ["OnboardingChecklist"],
  endpoints: (builder) => ({
    getOnboardingChecklist: builder.query<
      IOnboardingChecklist,
      IGetOnboardingChecklistArgs
    >({
      query: ({ appId }) => ({
        url: `/onboarding`,
        params: { app_id: appId },
      }),
      providesTags: ["OnboardingChecklist"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IOnboardingChecklist>,
      ): IOnboardingChecklist =>
        humps.camelizeKeys(response) as IOnboardingChecklist,
    }),
    updateOnboardingChecklist: builder.mutation<
      IOnboardingChecklist,
      IUpdateOnboardingChecklistArgs
    >({
      query: ({ appId, step, value }) => ({
        url: `/onboarding/${appId}`,
        method: "PATCH",
        body: { app_id: appId, step, value },
      }),
      invalidatesTags: ["OnboardingChecklist"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IOnboardingChecklist>,
      ): IOnboardingChecklist =>
        humps.camelizeKeys(response) as IOnboardingChecklist,
    }),
    lastIdentify: builder.query<ILastIdentifyResponse, ILastIdentifyArgs>({
      query: ({ appId }) => ({
        url: "/onboarding/last_identify",
        params: {
          app_id: appId,
        },
      }),
      transformResponse: (
        response: SnakeCasedPropertiesDeep<ILastIdentifyResponse>,
      ): ILastIdentifyResponse =>
        humps.camelizeKeys(response) as ILastIdentifyResponse,
    }),
    lastTrack: builder.query<ILastTrackResponse, ILastTrackArgs>({
      query: ({ appId }) => ({
        url: "/onboarding/last_track",
        params: {
          app_id: appId,
        },
      }),
      transformResponse: (
        response: SnakeCasedPropertiesDeep<ILastTrackResponse>,
      ): ILastTrackResponse =>
        humps.camelizeKeys(response) as ILastTrackResponse,
    }),
    lastGroup: builder.query<ILastGroupResponse, ILastGroupArgs>({
      query: ({ appId }) => ({
        url: "/onboarding/last_group",
        params: {
          app_id: appId,
        },
      }),
      transformResponse: (
        response: SnakeCasedPropertiesDeep<ILastGroupResponse>,
      ): ILastGroupResponse =>
        humps.camelizeKeys(response) as ILastGroupResponse,
    }),
  }),
});

export const {
  useGetOnboardingChecklistQuery,
  useLazyGetOnboardingChecklistQuery,
  useUpdateOnboardingChecklistMutation,
  useLastIdentifyQuery,
  useLastTrackQuery,
  useLastGroupQuery,
} = onboardingChecklistApi;
