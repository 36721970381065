import { Input } from "Components/ui/input";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { ITraitOption } from "core/types/Trait";
import { ColorPicker } from "modules/Labels/ColorPicker";

export const COLOR_NAME_TO_PALETTE = {
  purple: colors.purple,
  red: colors.red,
  green: colors.green,
  yellow: colors.yellow,
  orange: colors.orange,
} as { [key in string]: IColorPalette };

export const TraitOption: React.FC<{
  option: ITraitOption;
  onUpdateOptionColor: (option: ITraitOption, color: string) => void;
  onUpdateOptionLabel: (option: ITraitOption, label: string) => void;
}> = ({ option, onUpdateOptionColor, onUpdateOptionLabel }) => {
  return (
    <div key={option.id} className="flex w-full items-center gap-2">
      <div className="w-[45px]">
        {option.color && (
          <ColorPicker
            showChevron={false}
            color={COLOR_NAME_TO_PALETTE[option.color]}
            setColor={(c) => {
              onUpdateOptionColor(option, c.scheme);
            }}
            colors={Object.values(COLOR_NAME_TO_PALETTE)}
            shade={400}
          />
        )}
      </div>
      <div className="w-full">
        <Input
          size="sm"
          className="w-full"
          placeholder="Option label"
          value={option.label}
          onChange={(e) => onUpdateOptionLabel(option, e.target.value)}
        />
      </div>
    </div>
  );
};
