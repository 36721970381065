import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import type { SnakeCasedPropertiesDeep } from "type-fest";

import { baseQuery } from "core/initializers/rtk";
import { IAppObject } from "core/models/appObjects";
import { IReport } from "core/types/Report";

export interface IDigestInsight {
  id: number;
  appId: number;
  report: IReport;
  reportId: number;
  reportType: number;
  insight: {
    name: string;
    reportType: number;
    reportName?: string;
    level?: "user" | "group";
    defaultParams?: object;
  };
  insightId?: number;
  appObject?: IAppObject;
  isEnabled: boolean;
}

export const digestInsightsApi = createApi({
  baseQuery,
  reducerPath: "digestInsightsApi",
  tagTypes: ["DigestInsight"],
  endpoints: (builder) => ({
    getDigestInsights: builder.query<IDigestInsight[], { appId: number }>({
      query: ({ appId }) => ({
        url: `/digest_insights`,
        params: humps.decamelizeKeys({ appId }),
      }),
      providesTags: ["DigestInsight"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IDigestInsight[]>,
      ): IDigestInsight[] => humps.camelizeKeys(response) as IDigestInsight[],
    }),
    updateDigestInsight: builder.mutation<
      {},
      {
        id: number;
        appId: number;
        reportId?: number;
        isEnabled?: boolean;
      }
    >({
      query: ({ id, appId, reportId, isEnabled }) => ({
        url: `/digest_insights/${id}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          id,
          appId,
          reportId,
          isEnabled,
        }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["DigestInsight"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IDigestInsight[]>,
      ): IDigestInsight[] => humps.camelizeKeys(response) as IDigestInsight[],
    }),
  }),
});

export const { useGetDigestInsightsQuery, useUpdateDigestInsightMutation } =
  digestInsightsApi;
