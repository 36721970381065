import { Box, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import React from "react";

import {
  BarChart,
  BarItem,
  BarRectangle,
} from "core/design-system/charts/BarChart";
import {
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import colors from "core/design-system/constants/theme/colors";
import { ICustomTooltipProps } from "core/types/CustomTooltip";
import { IReport } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

// @ts-nocheck

interface ILineChart {
  report: IReport;
  unit: string;
  data: any;
  dataKey: string;
  previewMode: boolean;
  sharingMode: boolean;
  hasHour: boolean;
  intervalType: number;
  insight: IInsightSection;
  showYAxis?: boolean;
}

interface IAdoptionCustomTooltipProps extends ICustomTooltipProps {
  previewMode: boolean;
  unit: string;
}

const CustomTooltip: React.FC<IAdoptionCustomTooltipProps> = ({
  unit,
  payload,
  active,
  previewMode,
}) => {
  if (active && payload && payload.length) {
    return (
      <Box w="240px" bg="gray.700" px={3} py={2} borderRadius={5}>
        <Text fontSize="xs" color="gray.400">
          {payload[0].payload.humanizedDate}
        </Text>
        <Text fontSize="sm" color="white" mt={2}>
          {payload[0].payload.y} {unit} used the feature for the first time
          {` `}
          <Text as="span" fontSize="sm" color="gray.400" mt={1}>
            out of {payload[0].payload.eligibleAudienceCount} {unit} who joined
            this week
          </Text>
        </Text>
      </Box>
    );
  }
  return null;
};

export const AdoptionLineChart: React.FC<ILineChart> = ({
  unit,
  data,
  previewMode,
  sharingMode,
  insight,
  showYAxis,
  report,
}) => {
  const formattedData = data.map((row) => ({
    x: moment(row.date).format("MMM DD"),
    y: row.adoptionCount,
    humanizedDate: row.humanizedDate,
    adoptionCount: row.adoptionCount,
    eligibleAudienceCount: row.eligibleAudienceCount,
    chartEligibleAudienceCount: row.eligibleAudienceCount - row.adoptionCount,
    date: row.date,
  }));

  return (
    <Flex justify="center" h={250}>
      <BarChart
        showYAxis={showYAxis}
        margin={{ top: 30, left: showYAxis ? 0 : 40, right: 50, bottom: 20 }}
        height="100%"
        data={formattedData}
      >
        <CommonGrid />
        {/* @ts-ignore */}
        <CommonXAxis dataKey="x" />
        {/* @ts-ignore */}
        {showYAxis && <CommonYAxis />}
        {/* @ts-ignore */}
        <CommonTooltip
          content={<CustomTooltip unit={unit} previewMode={previewMode} />}
        />
        <BarItem
          barSize={20}
          stackId="a"
          dataKey="y"
          fill={colors.purple[500]}
          shape={<BarRectangle radius={[0, 0, 0, 0]} />}
        />
        <BarItem
          barSize={20}
          stackId="a"
          dataKey="chartEligibleAudienceCount"
        />
      </BarChart>
    </Flex>
  );
};
