import {
  IAlertDeliveryChannelEnum,
  IAlertFrequencyEnum,
  IAlertStateEnum,
} from "core/constants/alert";
import { IAppObject } from "core/types/AppObject";
import { JoinOperatorValue } from "core/types/Filters.d";
import { z } from "zod";

export interface IAlert {
  /**
   * Alerts get created on the fly, so this is the only way to know if an alert has been created without returning a 404.
   * This clusterfuck is due to inconsistencies in the API around insights not being handled as a model.
   *
   * - created: true means the alert will have a record on the database.
   * - created: false means the alert will not have a record on the database.
   */
  created: boolean;
  id: number;
  appId: number;
  alertableId: number;
  insightId?: number;
  groupId?: string;
  appObject?: IAppObject;
  deliveryChannel?: IAlertDeliveryChannelEnum;
  slackChannelId?: string;
  setup: { [key: string]: any };
  alertableType: "Report" | "Event" | "ViewInsight" | "Audience";
  thresholdType: ThresholdType;
  threshold: number;

  frequency: IAlertFrequencyEnum;
  state: IAlertStateEnum;
  preferredUtcHour?: number;
  enrichmentEnabled: boolean;
  property?: string;
  propertyComparisonOperator?: string;
  propertyValue?: string;
  joinOperator?: JoinOperatorValue.AND;
  lastSentAt?: string;
  insightTimerange?: number;
  enabled?: boolean;
}

export interface ICreateAlert {
  appId: string;
  reportId?: string;
  insightId?: string;
  viewInsightId?: string;
  audienceId?: string;
  objectType?: string;
  frequency: IAlert["frequency"];
  slackChannelId: string;
  insightTimerange?: number;
}

export interface IUpdateAlert {
  appId: string;
  reportId?: string;
  insightId?: string;
  viewInsightId?: string;
  objectType?: string;
  frequency?: IAlert["frequency"];
  setup?: IAlert["setup"];
  state?: IAlert["state"];
  enrichmentEnabled?: IAlert["enrichmentEnabled"];
  deliveryChannel?: IAlert["deliveryChannel"];
  slackChannelId?: string;
  property?: string;
  propertyValue?: string;
  propertyComparisonOperator?: string;
  joinOperator?: JoinOperatorValue.AND;
  threshold?: number;
  thresholdType?: ThresholdType;
  preferredUtcHour?: number;
  insightTimerange?: number;
}
export interface IAlertState {
  isLoading: boolean;
  isCreating: boolean;
  error?: string;
}

export const ReportAlertableSchema = z.object({
  id: z.number(),
  name: z.string(),
  reportType: z.number(),
  config: z.record(z.any()).optional(),
  reportMailSetting: z.any().optional(),
  labels: z.array(z.any()).optional(),
  hasPinnedViewInsights: z.boolean().optional()
});

export const ViewInsightAlertableSchema = z.object({
  id: z.number(),
  insight: z.object({
    name: z.string(),
    slug: z.string()
  })
});

export const AudienceAlertableSchema = z.object({
  id: z.number(),
  name: z.string()
});

export const EventAlertableSchema = z.object({
  id: z.number(),
  name: z.string(),
  title: z.string()
});

// Combined alertable schema
export const AlertableSchema = z.discriminatedUnion("alertableType", [
  z.object({ alertableType: z.literal("Report"), alertable: ReportAlertableSchema }),
  z.object({ alertableType: z.literal("ViewInsight"), alertable: ViewInsightAlertableSchema }),
  z.object({ alertableType: z.literal("Audience"), alertable: AudienceAlertableSchema }),
  z.object({ alertableType: z.literal("Event"), alertable: EventAlertableSchema })
]);

// Main alert schema
export const AlertSchema = z.object({
  id: z.number(),
  appId: z.number(),
  alertableId: z.number(),
  insightId: z.number().optional().nullable(),
  groupId: z.string().optional().nullable(),
  appObject: z.custom<IAppObject>().optional().nullable(),
  deliveryChannel: z.nativeEnum(IAlertDeliveryChannelEnum).optional().nullable(),
  slackChannelId: z.string().optional().nullable(),
  setup: z.record(z.any()).optional().nullable().default({}),
  alertableType: z.enum(["Report", "Event", "ViewInsight", "Audience"]),
  thresholdType: z.string(),
  threshold: z.number(),
  frequency: z.nativeEnum(IAlertFrequencyEnum),
  state: z.nativeEnum(IAlertStateEnum),
  enrichmentEnabled: z.boolean(),
  property: z.string().optional().nullable(),
  propertyComparisonOperator: z.string().optional().nullable(),
  propertyValue: z.string().optional().nullable(),
  joinOperator: z.literal(JoinOperatorValue.AND).optional().nullable(),
  lastSentAt: z.string().optional().nullable(),
  insightTimerange: z.number().optional().nullable(),
  enabled: z.boolean().optional().nullable(),
  preferredUtcHour: z.number().optional().nullable(),
});

// Combined alert with alertable schema
export const AlertWithAlertableSchema = AlertSchema.extend({
  alertable: z.union([
    ReportAlertableSchema,
    ViewInsightAlertableSchema,
    AudienceAlertableSchema,
    EventAlertableSchema
  ])
});
