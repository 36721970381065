import humps from "humps";

import {
  INTERVAL_TYPE_NUMBERS,
  IntervalTypeNames,
} from "core/constants/timerange";
import axios from "core/initializers/axios";
import { AppThunk } from "core/initializers/store";
import { slice } from "core/models/contact";
import { IContactActivity } from "core/types/Contact";
import { IFilterEvents } from "modules/Contacts/Contact/ContactActivity";

interface IContactActivityParams {
  appId: string | number;
  id: string;
  intervalType: IntervalTypeNames;
  filterEvents: IFilterEvents;
}

export const fetchOneContactActivity =
  ({
    appId,
    id,
    intervalType,
    filterEvents,
  }: IContactActivityParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.fetchingContactActivity({ id }));
      const { data } = await axios.get<IContactActivity>(
        `/contacts/${encodeURIComponent(id)}/activity`,
        {
          params: humps.decamelizeKeys({
            appId,
            intervalType: INTERVAL_TYPE_NUMBERS[intervalType],
            filterEvents,
          }),
        },
      );

      dispatch(slice.actions.upsertOneContactActivity({ id, data }));
    } catch (e: any) {
      const title = "Failed to fetch contact activity";
      const description = e?.response?.data?.error;
      const error = `${title}: ${description}`;
      dispatch(slice.actions.fetchingContactActivityFailed({ id, error }));
    }
  };
