import { ComponentDefaultProps } from "@chakra-ui/react";

import { CardContent } from "@/Components/ui/card";
import { Loader } from "core/components/ViewInsight/Loader";

export interface IBodyProps extends ComponentDefaultProps {
  isLoading?: boolean;
}

export const Body: React.FC<IBodyProps> = ({
  children,
  isLoading,
  ...props
}) => {
  if (isLoading) return <Loader />;
  return (
    <CardContent
      data-testid="highlight-card-body"
      className="h-full max-h-full w-full max-w-full p-3"
    >
      {children}
    </CardContent>
  );
};
