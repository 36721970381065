import axios from "axios";
import humps from "humps";
import { lowerCase } from "lodash";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

interface IUseRequestResponse extends SWRResponse {
  response: any;
  isLoading: boolean;
}

export function transformData(response: any): any {
  if (response && response.data) {
    // Use lowercase instead of camelization for uppercase-only keys
    // (to prevent USERNAME from becoming uSERNAME)
    const data = humps.camelizeKeys(
      response.data,
      function (key, convert, options) {
        return /^[A-Z0-9_]+$/.test(key)
          ? lowerCase(key)
          : convert(key, options);
      },
    );
    return data;
  }
  return undefined;
}

function transformParams(request: { params: { [key: string]: any } }) {
  if (request && request.params) {
    const params = humps.decamelizeKeys(request.params);
    return { ...request, params };
  }
  return request;
}

interface IUseRequestProps extends SWRConfiguration {
  initialData?: any;
}

export default function useRequest(
  request: any,
  { initialData, ...config }: IUseRequestProps = {},
): IUseRequestResponse {
  const transformedParams = transformParams(request);
  const requestConfig = {
    mode: "cors",
    baseURL: import.meta.env.VITE_API_HOST,
    shouldRetryOnError: false,
    ...transformedParams,
  };
  const {
    data: response,
    error,
    isValidating,
    mutate,
  } = useSWR(
    requestConfig && JSON.stringify(requestConfig),
    () => axios(requestConfig || {}),
    {
      ...config,
      fallbackData: initialData && {
        status: 200,
        statusText: "InitialData",
        headers: {},
        data: initialData,
      },
    },
  );
  return {
    data: transformData(response),
    response: transformData(response),
    isLoading: !response && !error,
    error,
    isValidating,
    mutate,
  };
}
