import moment from "moment";
import { useParams } from "react-router-dom";

import { useToast } from "core/hooks/useToast";
import {
  CrmSyncStatus,
  CrmSyncTriggerType,
  CrmSyncType,
  ICrmSync,
  useGetLastCrmSyncQuery,
  useLazyGetLastCrmSyncQuery,
  useTriggerSyncMutation,
} from "core/models/crmSyncs";
import { Integration } from "core/models/dataMappings";
import { AppObjectType } from "core/types/AppObject";

interface IUseCrmSyncs {
  integration: Integration;
  objectType: AppObjectType;
}

export function useCrmSyncs({ integration, objectType }: IUseCrmSyncs): {
  lastCrmSync: ICrmSync | undefined;
  isLoadingCrmSyncs: boolean;
  lastSyncedAt: string;
  lastSyncStatus: CrmSyncStatus | undefined;
  onTriggerSync: ({
    triggerType,
    syncType,
  }: {
    triggerType?: CrmSyncTriggerType;
    syncType?: CrmSyncType;
  }) => void;
  onRefresh: () => void;
} {
  const { appId } = useParams();
  const toast = useToast();
  const {
    data: lastCrmSync,
    isLoading: isLoadingCrmSyncs,
    isFetching,
  } = useGetLastCrmSyncQuery({
    appId: Number(appId),
    integration,
    objectType,
  });
  const [getLastCrmSync] = useLazyGetLastCrmSyncQuery();
  const [triggerSync] = useTriggerSyncMutation();

  function onRefresh() {
    getLastCrmSync({
      appId: Number(appId),
      integration,
      objectType,
    });
  }

  function onTriggerSync({
    triggerType = CrmSyncTriggerType.Automatic,
    syncType = CrmSyncType.Incremental,
  }: {
    triggerType?: CrmSyncTriggerType;
    syncType?: CrmSyncType;
  }) {
    triggerSync({
      appId: Number(appId),
      integration,
      objectType,
      triggerType,
      syncType,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Triggered a sync",
          description: "Your sync will start shortly",
        });
      })
      .catch((error) => {
        toast({
          title: "Finish setting up",
          description:
            "Please ensure all of your traits and properties have been mapped",
          status: "error",
        });
      });
  }

  return {
    lastCrmSync,
    isLoadingCrmSyncs: isLoadingCrmSyncs || isFetching,
    lastSyncedAt: lastCrmSync?.createdAt
      ? moment.utc(lastCrmSync?.createdAt).fromNow()
      : "",
    lastSyncStatus: lastCrmSync?.status,
    onTriggerSync,
    onRefresh,
  };
}
