import { Flex, Text } from "@chakra-ui/react";
import {
  BuildingOffice2Icon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import React from "react";

import { TRAIT_LEVEL, TRAIT_LEVEL_STRING } from "core/constants/traits";
import colors from "core/design-system/constants/theme/colors";
import { useAppObjects } from "core/hooks/useAppObjects";
import { getAppObject } from "core/modules/appObjects/utils";
import { ITraitWithConfig } from "core/types/Trait";

const LEVEL_TO_ICON: { [key: string]: React.ComponentType<any> } = {
  [TRAIT_LEVEL.USER]: UsersIcon,
  [TRAIT_LEVEL.GROUP]: UserGroupIcon,
  [TRAIT_LEVEL.COMPANY]: BuildingOffice2Icon,
};

export const TraitLevelCell: React.FC<{ props: ITraitWithConfig }> = ({
  props: trait,
}) => {
  const { appObjects } = useAppObjects();
  const Icon = LEVEL_TO_ICON[trait.level];
  const appObject = getAppObject(appObjects, TRAIT_LEVEL_STRING[trait.level]);

  return (
    <Flex justifyContent="flex-start">
      <Flex alignItems="center" gap={2} justifyContent="start">
        <Icon style={{ height: "18px", color: colors.gray[700] }} />
        <Text fontSize="sm" className="capitalize">
          {appObject?.singularName || "Company"}
        </Text>
      </Flex>
    </Flex>
  );
};
