import {
  ComponentDefaultProps,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";

import { Icon } from "core/design-system/components/Icon";

interface INumberInputFilterProps extends ComponentDefaultProps {
  onChange: (value: string) => void;
  defaultValue: number;
  hasInterval?: boolean;
}

export const NumberInputFilter: React.FC<INumberInputFilterProps> = ({
  onChange,
  defaultValue,
  hasInterval,
  ...props
}) => {
  return (
    <NumberInput
      data-testid="number-input-filter"
      defaultValue={defaultValue}
      min={hasInterval ? 1 : 0}
      onChange={(v) => onChange(v)}
      bg="gray.100"
      focusBorderColor="none"
      size="sm"
      border="none"
      borderLeftRadius={0}
      w="80px"
      {...props}
    >
      <NumberInputField border="none" borderRadius={0} {...props} />
      <NumberInputStepper borderRightRadius={4}>
        <NumberIncrementStepper
          borderTopRightRadius={4}
          children={<Icon iconType="icon" name="chevron-up" />}
          _hover={{ bg: "gray.100" }}
          onClick={(e) => e.preventDefault()}
        />
        <NumberDecrementStepper
          borderBottomRightRadius={4}
          children={<Icon iconType="icon" name="chevron-down" />}
          _hover={{ bg: "gray.100" }}
          onClick={(e) => e.preventDefault()}
        />
      </NumberInputStepper>
    </NumberInput>
  );
};
