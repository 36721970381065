import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import SegmentIcon from "core/design-system/components/Icon/Logos/SegmentIcon";

const Setup = () => {
  return (
    <Flex m={12} p={12} align="center" justify="center">
      <Flex w="750px" align="center" justify="center">
        <div>
          <Text fontSize="4xl">Get started</Text>
          <Text p={2}>
            To start getting insights you first need to connect June to your
            Segment account.
          </Text>

          <Flex direction="column">
            <a href={`${import.meta.env.VITE_API_HOST}/auth/segment`}>
              <Flex
                direction="row"
                align="center"
                justify="center"
                bg="green.100"
                p={2}
                borderRadius={4}
                mt={4}
              >
                <SegmentIcon color="green.500" h={8} w={8} />
                <Text pl={4} textAlign="center" fontWeight="bold">
                  Connect your Segment account
                </Text>
              </Flex>
            </a>
          </Flex>
        </div>
      </Flex>
    </Flex>
  );
};

export default Setup;
