import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import { EventType } from "core/constants/report-setup";

const EventTypeCell = ({ props: { type } }: { props: { type: EventType } }) => {
  return (
    <Flex minW="100px" maxW="100px">
      <Text color="gray.500" fontSize="xs" textTransform="uppercase">
        {(type === 0 && "visited") || "triggered"}
      </Text>
    </Flex>
  );
};

export default EventTypeCell;
