import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { Button, Flex, Progress, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

import { Icon } from "core/design-system/components/Icon";
import { PercentChange } from "core/design-system/components/PercentChange";
import { Header } from "core/design-system/components/Stat/Header";
import Loading from "core/design-system/components/Stat/Loading";

export const HeaderInsightStat = ({
  buttonText,
  changeText,
  changeTooltip,
  footer,
  headingTooltip,
  helpText,
  isLoading = false,
  label,
  mainCount,
  onButtonClick,
  percentChange,
  progressBarColor = "purple",
  reverseChangeColor = false,
  showProgressBar = false,
  timerange,
  firstStat = false,
  lastStat = false,
  ...props
}: {
  buttonText?: string;
  changeText?: string;
  changeTooltip?: string;
  footer?: string;
  headingTooltip?: string;
  helpText?: string;
  isLoading: boolean;
  label?: string;
  mainCount: string | number;
  onButtonClick?: () => void;
  percentChange?: number;
  progressBarColor?: string;
  reverseChangeColor?: boolean;
  showProgressBar?: boolean;
  timerange?: string;
  firstStat?: boolean;
  lastStat?: boolean;
}) => {
  if (isLoading) {
    return (
      <Loading
        label={label}
        labelTooltip={headingTooltip}
        helpText={helpText}
      />
    );
  }

  const isViewDisabled = !mainCount || mainCount === 0 || mainCount === "0%";

  return (
    <Flex
      p={5}
      align="flex-start"
      justify="space-between"
      border="0.5px solid"
      borderColor="gray.50"
      bg="white"
      direction="column"
      borderBottomLeftRadius={firstStat ? "lg" : "none"}
      borderBottomRightRadius={lastStat ? "lg" : "none"}
      {...props}
    >
      <Flex width="100%" align="center" justify="space-between">
        <Header
          label={label || ""}
          labelTooltip={headingTooltip}
          helpText={helpText}
        />
        {buttonText && onButtonClick && (
          <Button
            px={4}
            mr={-2}
            variant="ghost"
            colorScheme="purple"
            rightIcon={<Icon iconType="icon" name="arrow-right-2" />}
            size="xs"
            onClick={onButtonClick}
            isDisabled={isViewDisabled}
          >
            {buttonText}
          </Button>
        )}
        {timerange && (
          <Text color="gray.600" fontSize="xs" w="30%" fontWeight="medium">
            {timerange}
          </Text>
        )}
      </Flex>
      <Flex my={1} align="baseline" justify="flex-start">
        <Text fontSize="2xl" fontWeight="medium">
          {mainCount}
        </Text>
      </Flex>
      {percentChange !== undefined && (
        <Flex align="center" gap={1}>
          <PercentChange
            percentChange={percentChange}
            changeText={changeText}
            reverseColor={reverseChangeColor}
          />
          {changeTooltip && (
            <Tooltip label={changeTooltip} placement="right">
              <QuestionOutlineIcon w={3} h={3} color="gray.600" />
            </Tooltip>
          )}
        </Flex>
      )}
      {showProgressBar && (
        <Progress
          colorScheme={progressBarColor}
          mt={4}
          value={
            typeof mainCount === "string" ? parseInt(mainCount, 10) : mainCount
          }
          size="sm"
          borderRadius="lg"
          w="100%"
        />
      )}
      {footer && (
        <Flex align="flex-start" justify="space-between">
          <Text color={"gray.600"} fontSize="sm">
            {footer}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
