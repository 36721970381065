import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { GroupListSingleGroup } from "core/components/Group/GroupListSingleGroup";
import InsightCard from "core/components/InsightCard/Index";
import { InsightReactions } from "core/components/InsightReactions";
import { TimeRangePicker } from "core/components/TimeRangePicker";
import {
  DATE_RANGE_OPTIONS_FROM_VALUE,
  MOST_ACTIVE_USERS_OPTIONS,
  NINETY_DAYS,
  RELATIVE_TIMERANGE_VALUE_FROM_KEYS,
} from "core/constants/timerange";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { useQueryParam } from "core/hooks/useQueryParam";
import { MostActiveGroupsList } from "core/modules/reports/report-types/ActiveGroups/MostActiveGroupsList";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";
import { ITimerangeOption } from "core/types/TimerangeOption";
import { pluralize } from "helpers/pluralize";

export interface IMostActiveGroup {
  groupId: string;
  eventCount: number;
  dayCount: number;
  groupType: number;
  traits: { [key: string]: string };
}

export interface IMostActiveGroupsData {
  contacts?: IMostActiveGroup[];
}

const mockData = {
  contacts: [
    {
      groupId: "Josephine Edamon",
      eventCount: 5,
      dayCount: 5,
    },
    {
      groupId: "Roy Carpent",
      eventCount: 4,
      dayCount: 4,
    },
    {
      groupId: "Anita James",
      eventCount: 3,
      dayCount: 3,
    },
    {
      groupId: "Alice Terpantine",
      eventCount: 2,
      dayCount: 2,
    },
    {
      groupId: "Gus Edams",
      eventCount: 1,
      dayCount: 1,
    },
  ],
};

export const MostActiveGroupsContainer: React.FC<IReportInsight> = ({
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken,
  report,
  config,
}) => {
  const { reportId } = useParams<{ reportId: string }>() as {
    reportId: string;
  };
  const { activeAppObject } = useAppObjects();
  const insight = config.insights.find(
    ({ slug }) => slug === "most-active-companies",
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const timerangeParam = useQueryParam("insight_timerange");
  const initialTimeRange = timerangeParam
    ? DATE_RANGE_OPTIONS_FROM_VALUE[
        parseInt(
          timerangeParam,
          10,
        ) as keyof typeof DATE_RANGE_OPTIONS_FROM_VALUE
      ]
    : DATE_RANGE_OPTIONS_FROM_VALUE[
        RELATIVE_TIMERANGE_VALUE_FROM_KEYS[
          NINETY_DAYS.value as keyof typeof RELATIVE_TIMERANGE_VALUE_FROM_KEYS
        ] as keyof typeof DATE_RANGE_OPTIONS_FROM_VALUE
      ];
  const [timerange, setTimerange] =
    useState<ITimerangeOption>(initialTimeRange);
  const intervalDays = timerange.count;

  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      insight,
      insightParams: { intervalDays, truncate: true },
      sharingSecretToken,
      previewMode,
      skip: !isSetupValid,
    });

  const data = previewMode ? mockData : response?.data;

  const onViewButtonClick = () => {
    if (!sharingMode && !previewMode) {
      onOpen();
    }
  };

  const noData = data?.groups?.length === 0;

  return (
    <>
      <InsightCard>
        {(Card) => (
          <Card.Container insight={insight} id={insight.slug}>
            <Card.Header
              hasCaching={response?.hasCaching}
              screenshotMode={screenshotMode}
              showReportTitle={sharingMode}
              insight={insight}
              showActions={!sharingMode && !previewMode}
              sharingMode={sharingMode}
              config={config}
              report={report}
              refresh={onRefreshInsight}
              cachedAt={response?.cachedAt}
            >
              {timerange && (
                <TimeRangePicker
                  previewMode={previewMode}
                  timerange={timerange}
                  setTimeRange={setTimerange}
                  options={MOST_ACTIVE_USERS_OPTIONS}
                />
              )}
            </Card.Header>
            <Card.Body
              isLoading={!data || isLoading || isFetching}
              isPreviewMode={previewMode}
              isSetupValid={isSetupValid}
              error={error}
            >
              {noData && !isLoading ? (
                <Card.EmptyState />
              ) : (
                <Flex
                  p={5}
                  h={320}
                  id="loaded"
                  data-testid="loaded"
                  direction="column"
                >
                  {data?.groups?.map((group: IMostActiveGroup) => (
                    <GroupListSingleGroup
                      key={group.groupId}
                      id={group.groupId}
                      previewMode={previewMode}
                      traits={group.traits}
                      groupType={group.groupType}
                      description={`Used ${group.eventCount} ${pluralize(group.eventCount, "time", "times")} ${
                        group.dayCount === 1
                          ? `for ${group.dayCount} day`
                          : `over ${group.dayCount} days`
                      }`}
                    />
                  ))}
                </Flex>
              )}
            </Card.Body>
            {!sharingMode && !previewMode && !noData && !isLoading && (
              <Card.Footer topBorder={false}>
                <div className="flex w-full flex-col">
                  <div className="w-full px-4 py-0">
                    <div className="flex w-full items-center justify-between gap-2 rounded-lg bg-gray-50 p-4">
                      <div className="w-7/10">
                        <Text fontSize="sm">
                          These {activeAppObject?.pluralName.toLowerCase()} have
                          been the most active in the{" "}
                          <Text as="span" className="lowercase">
                            {timerange.label}
                          </Text>
                          . Look at their profiles to find common patterns
                          amongst them.
                        </Text>
                      </div>
                      <Button
                        className="w-3/20 rounded-lg"
                        onClick={onViewButtonClick}
                      >
                        <div className="p-4 text-sm font-semibold">
                          View {activeAppObject?.pluralName.toLowerCase()}
                        </div>
                      </Button>
                    </div>
                  </div>
                  <InsightReactions
                    reactions={response?.reactions}
                    insightId={insight.typeId}
                    reportId={Number(reportId)}
                  />
                </div>
              </Card.Footer>
            )}
          </Card.Container>
        )}
      </InsightCard>
      {!sharingMode && !previewMode && (
        <MostActiveGroupsList
          report={report}
          isOpen={isOpen}
          onClose={onClose}
          insight={insight}
          previewMode={previewMode}
          sharingSecretToken={sharingSecretToken}
        />
      )}
    </>
  );
};
