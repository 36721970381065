import { Flex, useToken } from "@chakra-ui/react";
import { ReferenceLine } from "recharts";

import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import {
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import TickText from "core/design-system/charts/TickText";
import colors from "core/design-system/constants/theme/colors";
import { CurrentPeriodTooltip } from "core/modules/reports/report-types/ActiveUsers/CurrentPeriodTooltip";
import { ICurrentPeriodLineChart } from "core/types/LineChart";

// @ts-nocheck

export const CurrentPeriodLineChart = ({
  data,
  unit,
  currentPeriodReferenceLineValue,
  currentPeriodTooltipText,
}: ICurrentPeriodLineChart) => {
  const [gray600] = useToken("colors", ["gray.600"]);

  return (
    <Flex justify="center" h={300}>
      <AreaChart
        margin={{ top: 30, left: 0, right: 50, bottom: 20 }}
        height="100%"
        data={data}
      >
        <defs>
          <linearGradient
            id="identifiedUsersGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="5%"
              stopColor={colors.purple[400]}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={colors.purple[100]}
              stopOpacity={0.4}
            />
          </linearGradient>
          <linearGradient
            id="currentPeriodGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors.gray[100]} stopOpacity={0.4} />
          </linearGradient>
        </defs>
        <CommonGrid />
        <CommonXAxis dataKey="x" />
        <CommonYAxis
          tick={({ x, ...props }) => (
            <TickText x={x - 5} {...props}>
              {props.payload.value} {`%`}
            </TickText>
          )}
        />
        <ReferenceLine
          x={currentPeriodReferenceLineValue}
          stroke={gray600}
          strokeDasharray="3 3"
        />
        <CommonTooltip
          content={
            <CurrentPeriodTooltip
              unit={unit}
              currentPeriodTooltipText={currentPeriodTooltipText}
            />
          }
        />
        <Area
          type="monotone"
          fillOpacity={1}
          fill="url(#identifiedUsersGradient)"
          dataKey="stickynessRatio"
          activeDot={false}
        />
        <Area
          type="monotone"
          fillOpacity={1}
          stroke={colors.gray[500]}
          fill="url(#currentPeriodGradient)"
          dataKey="currentStickynessRatio"
          activeDot={false}
        />
      </AreaChart>
    </Flex>
  );
};
