import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { DateRange } from "react-date-range";

import { Icon } from "core/design-system/components/Icon";

import "react-date-range/dist/styles.css";
import "core/modules/reports/Navbar/DateRangeSelector/theme.css";

const CustomDateRangeSection = ({
  dateRange,
  setDateRange,
  minDate,
  maxDate,
  ...styleProps
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getReadableDateRange = () => {
    const dateRangeStartTimestamp = moment(dateRange[0].startDate)
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");
    const dateRangeEndTimestamp = moment(dateRange[0].endDate)
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");

    return `${moment(dateRangeStartTimestamp).format("ll")} -
      ${moment(dateRangeEndTimestamp).format("ll")}`;
  };
  const readableDateRange = getReadableDateRange();

  return (
    <>
      <Button
        leftIcon={<Icon iconType="icon" name="pen" w="14px" h="14px" />}
        onClick={onOpen}
        {...styleProps}
      >
        {readableDateRange}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select a time interval</ModalHeader>
          <ModalBody>
            <Box>
              <div className="flex items-center justify-center">
                <div className="flex flex-col">
                  <Box>
                    <DateRange
                      ranges={dateRange}
                      minDate={minDate}
                      maxDate={maxDate}
                      onChange={(item) => {
                        setDateRange([item.selection]);
                      }}
                      moveRangeOnFirstSelection={false}
                      showDateDisplay={false}
                    />
                  </Box>
                </div>
              </div>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} colorScheme="black">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomDateRangeSection;
