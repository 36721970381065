import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export interface ICompanyCell {
  props: {
    appId: string;
    id?: string;
    name?: string;
  };
}

const CompanyCell = ({ props: { appId, id, name } }: ICompanyCell) => {
  if (!id) {
    return (
      <Flex align="flex-start">
        <Tooltip label="Pass the group_id to your track calls in order to link an event with a company.">
          <Text fontSize="sm">-</Text>
        </Tooltip>
      </Flex>
    );
  }

  if (!name) {
    return (
      <Link to={`/a/${appId}/group/${id}`}>
        <Tooltip label="The name trait is missing. This is caused by not passing a company name as part of your group calls.">
          <Flex align="flex-start" direction="column">
            <Text fontSize="sm" color="gray.600" noOfLines={1} maxW={300}>
              {id}
            </Text>
          </Flex>
        </Tooltip>
      </Link>
    );
  }

  return (
    <Link to={`/a/${appId}/group/${id}`}>
      <Flex align="flex-start" direction="column">
        <Text fontSize="sm" noOfLines={1} maxW={300}>
          {name}
        </Text>
      </Flex>
    </Link>
  );
};

export default CompanyCell;
