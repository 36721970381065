import {
  Combobox,
  ComboboxInput,
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  CodeBracketSquareIcon,
  HomeIcon,
  PresentationChartBarIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import { NoResultsFound } from "core/components/CommandMenu/NoResultsFound";
import { ResultOptions } from "core/components/CommandMenu/ResultOptions";
import { EventIcon } from "core/design-system/components/Icon/Custom/EventIcon";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useDebounce } from "core/hooks/useDebounce";
import { useFetchSearchResultQuery } from "core/models/search";

export interface IResult {
  type: string;
  path: string;
}

export interface IQuickNavigationItem {
  name: string;
  icon: React.ReactNode;
  path: string;
}

interface IBody {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const Body: React.FC<IBody> = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);
  const { id: appId } = useCurrentApp();

  const { data, isFetching } = useFetchSearchResultQuery({
    query: debouncedQuery,
    appId,
  });

  const quickNavigationItems = [
    {
      name: "Go to home",
      icon: (
        <HomeIcon
          className={"h-6 w-6 flex-none text-gray-600"}
          aria-hidden="true"
        />
      ),
      path: `/a/${appId}/home`,
    },
    {
      name: "Go to reports",
      icon: (
        <PresentationChartBarIcon
          className={"h-6 w-6 flex-none text-gray-600"}
          aria-hidden="true"
        />
      ),
      path: `/a/${appId}/reports`,
    },
    {
      name: "Go to events",
      icon: (
        <EventIcon
          className={"h-6 w-6 flex-none p-0.5 text-gray-600"}
          aria-hidden="true"
        />
      ),
      path: `/a/${appId}/events`,
    },
    {
      name: "Go to developers",
      icon: (
        <CodeBracketSquareIcon
          className={"h-6 w-6 flex-none text-gray-600"}
          aria-hidden="true"
        />
      ),
      path: `/a/${appId}/settings/developers`,
    },
  ];

  let filteredNavigationItems = quickNavigationItems;

  if (query) {
    filteredNavigationItems = quickNavigationItems.filter(
      (item: IQuickNavigationItem) =>
        item.name.toLowerCase().includes(query.toLowerCase()),
    );
  }

  return (
    <Transition
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-modal" onClose={setOpen}>
        <div className="fixed inset-0 z-modal w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <TransitionChild
            as={Fragment}
            enter="ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <DialogPanel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox<IResult>
                onChange={(result: IResult) => {
                  if (result.path !== null) {
                    setOpen(false);
                    navigate(result.path);
                  }
                }}
              >
                {({ activeOption }: { activeOption: IResult | null }) => (
                  <>
                    <div className="relative">
                      <MagnifyingGlassIcon
                        className="pointer-events-none absolute left-4 top-3.5 size-5 text-gray-500"
                        aria-hidden="true"
                      />
                      <ComboboxInput
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Search users, companies, reports..."
                        onChange={(event) => setQuery(event.target.value)}
                        autoFocus
                      />
                    </div>
                    <div className={query && !isFetching ? "h-112" : ""}>
                      <ResultOptions
                        activeOption={activeOption}
                        companies={query ? data?.companies : []}
                        groups={query ? data?.groups : []}
                        contacts={query ? data?.contacts : []}
                        reports={query ? data?.reports : []}
                        navigationItems={filteredNavigationItems}
                        query={query}
                        isFetching={isFetching}
                      />
                      {!isFetching &&
                        query !== "" &&
                        data?.companies?.length === 0 &&
                        data?.groups?.length === 0 &&
                        data?.contacts?.length === 0 &&
                        data?.reports?.length === 0 &&
                        filteredNavigationItems.length === 0 && (
                          <div
                            className="flex w-full items-center justify-center"
                            data-testid="cmdk-no-results-found"
                          >
                            <NoResultsFound />
                          </div>
                        )}
                    </div>
                  </>
                )}
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};
