import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { Calendar } from "react-date-range";

import { useDataDeletionRequests } from "core/hooks/useDataDeletionRequests";

interface IAppDeletionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RequestModal: React.FC<IAppDeletionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const today = new Date();
  const [endDate, setEndDate] = useState(today);
  const {
    isOpen: isPopoverOpen,
    onOpen: onPopoverOpen,
    onClose: onPopoverClose,
  } = useDisclosure();
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const { onCreateTimestampDataDeletionRequest } = useDataDeletionRequests();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete data</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack gap={2}>
            <Text color="gray.600">
              Delete all the data collected by June up until your selected date.
              Data deletions can take up to 30 days and are irreversible after
              completion.
            </Text>
            <Text pt={2} fontSize="sm">
              Delete data until
            </Text>
            <Popover
              isOpen={isPopoverOpen}
              onOpen={onPopoverOpen}
              onClose={onPopoverClose}
            >
              <PopoverTrigger>
                <Box>
                  <Button>{moment(endDate).format("D MMM YYYY")}</Button>
                </Box>
              </PopoverTrigger>
              <PopoverContent
                position="relative"
                left="-150px"
                borderRadius="lg"
              >
                <PopoverBody>
                  <Calendar
                    style={{ width: "100%" }}
                    date={endDate}
                    maxDate={today}
                    onChange={(date: Date) => {
                      setEndDate(date);
                      onPopoverClose();
                    }}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Text pt={2} fontSize="sm">
              Please type{" "}
              <Text as="span" fontWeight={"bold"}>
                delete data
              </Text>{" "}
              to confirm
            </Text>
            <Input
              onChange={(e) => {
                setIsDeleteDisabled(e.target.value === "delete data");
              }}
              placeholder={"delete data"}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="red"
            isDisabled={!isDeleteDisabled}
            onClick={() => {
              onCreateTimestampDataDeletionRequest(moment(endDate).format());
              onClose();
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
