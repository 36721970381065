import { KeyIcon } from "@heroicons/react/24/outline";
import React from "react";

import * as Integration from "@/modules/Settings/Integrations/Integration";
import { RUDDERSTACK } from "core/constants/apiKeys";
import { Icon } from "core/design-system/components/Icon";
import ApiKeys from "modules/Settings/ApiKeys";

const CONTENT: IntegrationMetadata = {
  title: "Rudderstack",
  description: "Send events and customer data to June",
  logoComponent: (
    <Icon
      iconType="logo"
      name="companyRudderStackLogo"
      color="#17176E"
      w="full"
      h="full"
    />
  ),
  docsUrl: "https://help.june.so/en/articles/6803296-rudderstack-integration",
};

const Rudderstack: React.FC = () => {
  return (
    <>
      <Integration.Container isExpanded>
        <Integration.Header metadata={CONTENT} />
        <Integration.Body>
          <Integration.Section>
            <Integration.Collapsable
              title={"Your write API keys"}
              headerIcon={<KeyIcon width={20} height={20} />}
            >
              <ApiKeys source={RUDDERSTACK} />
            </Integration.Collapsable>
          </Integration.Section>
        </Integration.Body>
      </Integration.Container>
    </>
  );
};

export default Rudderstack;
