import { useSelector } from "react-redux";

import { Spinner } from "@/Components/ui/spinner";
import { selector as appSelector } from "core/models/app";
import ReportList from "modules/Reports";

const Reports = () => {
  const { data: currentApp, isLoading } = useSelector(appSelector);

  if (!currentApp) return null;
  if (isLoading)
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size="medium" />
      </div>
    );

  return <ReportList appId={currentApp.id} />;
};

export default Reports;
