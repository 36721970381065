import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";

import { baseQuery } from "core/initializers/rtk";

export const hubspotObjectsApi = createApi({
  baseQuery,
  reducerPath: "hubspotObjectsApi",
  tagTypes: ["HubspotObjects"],
  endpoints: (builder) => ({
    createObject: builder.mutation<any, any>({
      query: ({ appId, groupId, objectType }) => ({
        url: `/hubspot/objects`,
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          groupId,
          objectType,
        }),
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    updateObject: builder.mutation<any, any>({
      query: ({ id, groupId, appId, objectType }) => ({
        url: `/hubspot/objects/${id}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          id,
          groupId,
          appId,
          objectType,
        }),
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    getObjects: builder.query<any, any>({
      query: ({ appId, groupId, objectType }) => ({
        url: `/hubspot/objects`,
        params: humps.decamelizeKeys({
          appId,
          groupId,
          objectType,
        }),
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
  }),
});

export const {
  useCreateObjectMutation,
  useUpdateObjectMutation,
  useGetObjectsQuery,
} = hubspotObjectsApi;
