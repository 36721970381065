import { ChevronDown } from "lucide-react";
import * as React from "react";

import { cx } from "helpers/cx";

interface TabsContextValue {
  value: string;
  onValueChange: (value: string) => void;
}

const TabsContext = React.createContext<TabsContextValue | undefined>(
  undefined,
);

interface PillTabsProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string;
  onValueChange: (value: string) => void;
}

const PillTabs = React.forwardRef<HTMLDivElement, PillTabsProps>(
  ({ value, onValueChange, children, ...props }, ref) => {
    return (
      <TabsContext.Provider value={{ value, onValueChange }}>
        <div ref={ref} {...props}>
          {children}
        </div>
      </TabsContext.Provider>
    );
  },
);
PillTabs.displayName = "PillTabs";

const PillTabsList = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  const context = React.useContext(TabsContext);
  if (!context) throw new Error("PillTabsList must be used within PillTabs");

  const triggers = React.Children.toArray(children).filter(
    (child): child is React.ReactElement => React.isValidElement(child),
  );

  return (
    <div className="grid grid-cols-1" ref={ref} {...props}>
      {/* Mobile Select Dropdown */}
      <div className="sm:hidden">
        <select
          value={context.value}
          onChange={(e) => context.onValueChange(e.target.value)}
          aria-label="Select a tab"
          className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-background py-2 pl-3 pr-8 text-sm ring-1 ring-inset ring-input focus:ring-2 focus:ring-primary"
        >
          {triggers.map((child) => (
            <option key={child.props.value} value={child.props.value}>
              {child.props.children}
            </option>
          ))}
        </select>
        <ChevronDown
          aria-hidden="true"
          className="pointer-events-none col-start-1 row-start-1 mr-2 h-4 w-4 self-center justify-self-end text-muted-foreground"
        />
      </div>

      {/* Desktop Pills */}
      <div className={cx("hidden space-x-2 sm:flex", className)}>
        {children}
      </div>
    </div>
  );
});
PillTabsList.displayName = "PillTabsList";

interface PillTabTriggerProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  value: string;
}

const PillTabTrigger = React.forwardRef<HTMLButtonElement, PillTabTriggerProps>(
  ({ className, value, ...props }, ref) => {
    const context = React.useContext(TabsContext);
    if (!context)
      throw new Error("PillTabTrigger must be used within PillTabs");

    const isActive = context.value === value;

    return (
      <button
        ref={ref}
        type="button"
        role="tab"
        aria-selected={isActive}
        onClick={() => context.onValueChange(value)}
        className={cx(
          "rounded-md px-3 py-2 text-sm font-medium transition-colors",
          isActive
            ? "bg-muted text-foreground"
            : "text-muted-foreground hover:bg-muted/50 hover:text-foreground",
          className,
        )}
        {...props}
      />
    );
  },
);
PillTabTrigger.displayName = "PillTabTrigger";

interface PillTabsContentProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string;
}

const PillTabsContent = React.forwardRef<HTMLDivElement, PillTabsContentProps>(
  ({ className, value, children, ...props }, ref) => {
    const context = React.useContext(TabsContext);
    if (!context)
      throw new Error("PillTabsContent must be used within PillTabs");

    if (context.value !== value) return null;

    return (
      <div
        ref={ref}
        role="tabpanel"
        className={cx(
          "mt-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
          className,
        )}
        {...props}
      >
        {children}
      </div>
    );
  },
);
PillTabsContent.displayName = "PillTabsContent";

export { PillTabs, PillTabsList, PillTabTrigger, PillTabsContent };
