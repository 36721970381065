import { Button, ComponentDefaultProps, Spinner } from "@chakra-ui/react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";

import { cx } from "helpers/cx";

export const Container: React.FC<ComponentDefaultProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cx(
        `relative min-h-[280px] rounded-lg border border-gray-100 bg-white shadow-sm`,
        className,
      )}
    >
      {children}
    </div>
  );
};

export const Header: React.FC<ComponentDefaultProps & { to?: string }> = ({
  children,
  to,
  onClick,
}) => {
  const { appId } = useParams();
  const navigate = useNavigate();
  return (
    <div className="col-span-1 gap-1 p-6 pb-0">
      <div className="flex flex-row justify-between">
        <div>{children}</div>
        {(to || onClick) && (
          <Button
            size="sm"
            rightIcon={<ArrowRightIcon className="h-4" />}
            colorScheme="purple"
            variant="ghost"
            onClick={() =>
              onClick ? onClick() : navigate(`/a/${appId}/${to}`)
            }
          >
            View
          </Button>
        )}
      </div>
    </div>
  );
};

export const Body: React.FC<ComponentDefaultProps> = ({
  children,
  className,
}) => {
  return <div className={cx("col-span-1 p-6", className)}>{children}</div>;
};

export const Footer: React.FC<ComponentDefaultProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cx(
        "col-span-1 my-auto border-t border-gray-100 px-6 py-3",
        className,
      )}
    >
      {children}
    </div>
  );
};

export const LoadingState: React.FC<ComponentDefaultProps> = () => {
  return (
    <div
      className={cx(
        "col-span-1 my-auto flex h-[150px] w-full items-center justify-center p-6",
      )}
    >
      <Spinner
        color="purple.300"
        thickness="3px"
        emptyColor="gray.200"
        speed="0.5s"
      />
    </div>
  );
};

export const EmptyStateContainer: React.FC<ComponentDefaultProps> = ({
  children,
}) => {
  return (
    <div className="flex h-full min-h-[173px] flex-col items-center justify-center">
      {children}
    </div>
  );
};

export const EmptyStateIconContainer: React.FC<ComponentDefaultProps> = ({
  children,
}) => {
  return (
    <div className="mb-2 rounded-full border border-gray-200 p-3 text-purple-500">
      {children}
    </div>
  );
};

export const EmptyStateTitle: React.FC<ComponentDefaultProps> = ({
  children,
}) => {
  return <h2 className="text-center text-sm font-semibold">{children}</h2>;
};

export const EmptyStateDescription: React.FC<ComponentDefaultProps> = ({
  children,
}) => {
  return <p className="mt-0.5 text-center text-sm text-gray-500">{children}</p>;
};

export const Title: React.FC<ComponentDefaultProps> = ({ children }) => {
  return <h2 className="font-semibold">{children}</h2>;
};

export const Description: React.FC<ComponentDefaultProps> = ({ children }) => {
  return <p className="text-sm text-gray-500">{children}</p>;
};
