import { Container } from "@chakra-ui/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { TabConfig, Tabs } from "core/components/Tabs/Tabs";
import { DocsLink } from "modules/Developers/DocsLink";
import { LogsContainer } from "modules/Developers/Logs/LogsContainer";
import { OverviewContainer } from "modules/Developers/Overview/OverviewContainer";
import { AppDeletedBanner } from "modules/GettingStarted/AppDeletedBanner";
import { EnvironmentsBanner } from "modules/GettingStarted/EnvironmentsBanner";
import DefaultHeader, {
  IDefaultHeaderProps,
} from "modules/Pages/DefaultHeader";

const tabs: TabConfig[] = [
  {
    title: "Overview",
    Component: () => (
      <div className="w-full">
        <OverviewContainer />
      </div>
    ),
  },
  {
    title: "Logs",
    Component: () => (
      <div className="w-full">
        <LogsContainer />
      </div>
    ),
  },
];

const Developers: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const section: IDefaultHeaderProps["section"] = {
    title: "Developer tools",
    description: "Test and debug your setup",
    sectionRight: DocsLink,
  };

  return (
    <Container maxW="container.xl">
      <EnvironmentsBanner />
      <AppDeletedBanner />
      <DefaultHeader section={section} mt={4} />
      <div className="mt-5">
        <Tabs
          tabs={tabs}
          value={searchParams.get("tab") ?? tabs[0].title}
          onChange={(tab) => {
            setSearchParams({ tab });
          }}
        />
      </div>
    </Container>
  );
};

export default Developers;
