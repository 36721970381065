import { Box } from "@chakra-ui/react";
import React from "react";

import InsightCard from "core/components/InsightCard/Index";
import { FeatureTable } from "core/components/ViewInsight/Graphs/FeatureTable";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { IReportInsight } from "core/types/Report";
import { Size } from "core/types/ViewInsight";

const mockData = {
  status: 200,
  data: [
    {
      name: "feed_opened",
      index: 0,
      eventCount: 11,
      userCount: 10,
      medianEventCount: 1.0,
    },
    {
      name: "events_opened",
      index: 1,
      eventCount: 23,
      userCount: 10,
      medianEventCount: 1.0,
    },
    {
      name: "reports_opened",
      index: 2,
      eventCount: 166,
      userCount: 10,
      medianEventCount: 1.0,
    },
    {
      name: "settings_opened",
      index: 3,
      eventCount: 342,
      userCount: 10,
      medianEventCount: 1.0,
    },
    {
      name: "pageviews_opened",
      index: 4,
      eventCount: 236,
      userCount: 10,
      medianEventCount: 1.0,
    },
    {
      name: "integrations_opened",
      index: 5,
      eventCount: 733,
      userCount: 10,
      medianEventCount: 1.0,
    },
    {
      name: "setup_opened",
      index: 6,
      eventCount: 853,
      userCount: 10,
      medianEventCount: 1.0,
    },
  ],
};

const FeatureUsageTable: React.FC<IReportInsight> = ({
  report,
  sharingSecretToken,
  previewMode,
  sharingMode,
  config,
  screenshotMode,
}) => {
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "feature-usage",
  );
  const isSetupValid = config.validateSetup(report.config);
  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      insight,
      report,
      sharingSecretToken,
      previewMode,
      skip: !isSetupValid,
    });

  const data = previewMode ? mockData.data : response?.data;
  if (!insight) return null;

  const noData = data?.length === 0;

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          />
          <Card.Body
            insight={insight}
            isLoading={!data || isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            {noData ? (
              <Card.EmptyState />
            ) : (
              <div className="flex w-full flex-col">
                <Box
                  pl={1}
                  pr={5}
                  py={5}
                  w="full"
                  maxH="510px"
                  overflow="scroll"
                  justifyItems="flex-start"
                >
                  <FeatureTable
                    data={data}
                    size={Size.Large}
                    truncate={false}
                  />
                </Box>
              </div>
            )}
          </Card.Body>
        </Card.Container>
      )}
    </InsightCard>
  );
};

export default FeatureUsageTable;
