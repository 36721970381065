import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";

import { baseQuery } from "core/initializers/rtk";

export interface ISalesforceSetting {
  id?: number;
  appId?: number;
  createdAt?: string;
  updatedAt?: string;
  accessToken?: string;
  refreshToken?: string;
  instanceUrl?: string;
  salesforceId?: string;
  tokenIssuedAt?: string;
  clientKey?: string;
  clientSecret?: string;
}

export const salesforceSettingsApi = createApi({
  baseQuery,
  reducerPath: "salesforceSettingsApi",
  tagTypes: ["SalesforceSettings"],
  endpoints: (builder) => ({
    getSetting: builder.query<any, any>({
      query: ({ appId }) => ({
        url: `/salesforce_settings`,
        params: humps.decamelizeKeys({ app_id: appId }),
      }),
      providesTags: ["SalesforceSettings"],
      transformResponse: (response: ISalesforceSetting) =>
        humps.camelizeKeys(response),
    }),
    createSetting: builder.mutation<
      void,
      { appId: number; setting: ISalesforceSetting }
    >({
      query: ({ appId, setting }) => ({
        url: `/salesforce_settings/`,
        method: "POST",
        params: humps.decamelizeKeys({ appId, ...setting }),
        body: humps.decamelizeKeys(setting),
      }),
      invalidatesTags: ["SalesforceSettings"],
    }),
    deleteSetting: builder.mutation<
      void,
      { appId: number; setting: ISalesforceSetting }
    >({
      query: ({ appId, setting }) => ({
        url: `/salesforce_settings/${setting.id}`,
        method: "DELETE",
        params: humps.decamelizeKeys({ appId }),
      }),
      invalidatesTags: ["SalesforceSettings"],
    }),
  }),
});

export const {
  useGetSettingQuery,
  useCreateSettingMutation,
  useDeleteSettingMutation,
} = salesforceSettingsApi;
