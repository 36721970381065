import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import moment from "moment";
import { useEffect, useState } from "react";

import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { readTraitValue } from "core/helpers/traits";
import { useHubspotSettings } from "core/hooks/useHubspotSettings";
import {
  useGetCompaniesQuery,
  useLazyCreateCompanyQuery,
  useLazyUpdateCompanyQuery,
} from "core/models/hubspot";
import { AppObjectType } from "core/types/AppObject";
import { IGroup } from "core/types/Group";

export interface IHSCompany extends Omit<IGroup, "traits"> {
  traits: { [key: string]: any }[];
}

export interface IHubspotCompany {
  id: string;
  properties: { name: string };
}

interface IUseHubspotParams {
  appId: number | string;
  company: IGroup;
  objectType: AppObjectType;
}

interface IHubspotResponse {
  companies: IHubspotCompany[];
  lastSyncedAt: string;
  createHubspotCompany: () => void;
  updateHubspotCompany: (companyId: string) => void;
  isLoadingCompanies: boolean;
  isCreatedCompanyLoading: boolean;
  isUpdatedCompanyLoading: boolean;
  refetchCompanies: () => void;
  fetchCompaniesError: FetchBaseQueryError | SerializedError | undefined;
  createCompanyError: FetchBaseQueryError | SerializedError | undefined;
  updateCompanyError: FetchBaseQueryError | SerializedError | undefined;
  canCreate: boolean;
}

export const useHubspotCompany = ({
  appId,
  company,
  objectType,
}: IUseHubspotParams): IHubspotResponse => {
  const name = readTraitValue(company.traits, "name");
  const domain = readTraitValue(company.traits, "domain");
  const [companies, setCompanies] = useState<IHubspotCompany[]>([]);
  const [lastSyncedAt, setLastSyncedAt] = useState<string>("");
  const { setting } = useHubspotSettings();
  const groupType =
    objectType === AppObjectType.Company ? GroupType.Company : GroupType.Group;
  const {
    data: fetchCompanies,
    isLoading: isLoadingCompanies,
    error: fetchCompaniesError,
    refetch: refetchCompanies,
  } = useGetCompaniesQuery({
    appId,
    name,
    groupId: company.id,
    groupType,
  });

  const [
    createCompany,
    {
      data: createdCompanies,
      isLoading: isCreatedCompanyLoading,
      error: createCompanyError,
    },
  ] = useLazyCreateCompanyQuery();

  const [
    updateCompany,
    {
      data: updatedCompanies,
      isLoading: isUpdatedCompanyLoading,
      error: updateCompanyError,
    },
  ] = useLazyUpdateCompanyQuery();

  useEffect(() => {
    if (fetchCompanies?.companies) {
      setCompanies(fetchCompanies.companies);
      setLastSyncedAt(moment.utc(fetchCompanies.lastSyncedAt).fromNow());
    }

    if (createdCompanies?.companies) {
      setCompanies(createdCompanies.companies);
      setLastSyncedAt(moment.utc(createdCompanies.lastSyncedAt).fromNow());
    }

    if (updatedCompanies?.companies) {
      setCompanies(updatedCompanies.companies);
      setLastSyncedAt(moment.utc(updatedCompanies.lastSyncedAt).fromNow());
    }
  }, [fetchCompanies, createdCompanies, updatedCompanies]);

  function createHubspotCompany() {
    createCompany({ appId, id: company.id, name, objectType });
  }

  function updateHubspotCompany(companyId: string) {
    updateCompany({ appId, groupId: company.id, companyId, objectType });
  }

  function canCreate() {
    if (objectType === AppObjectType.Company) return true;
    if (setting.companyIdentifier === "group_id") return true;
    if (setting.companyIdentifier === "name") return Boolean(name);

    return Boolean(domain);
  }

  return {
    companies,
    lastSyncedAt:
      (fetchCompanies?.lastSyncedAt ||
        createdCompanies?.lastSyncedAt ||
        updatedCompanies?.lastSyncedAt) &&
      lastSyncedAt,
    isLoadingCompanies,
    createHubspotCompany,
    updateHubspotCompany,
    isCreatedCompanyLoading,
    isUpdatedCompanyLoading,
    fetchCompaniesError,
    createCompanyError,
    updateCompanyError,
    refetchCompanies,
    canCreate: canCreate(),
  };
};
