import { Switch } from "@chakra-ui/react";
import React from "react";

import { useShareViewInsight } from "core/hooks/useShareViewInsight";
import { IViewInsight } from "core/models/viewInsights";
import CopyCode from "modules/ViewInsight/Share/CopyCode";

export const PublicLink: React.FC<{
  viewInsight: IViewInsight;
  groupId?: string | undefined;
}> = ({ viewInsight, groupId }) => {
  const { publicUrl, isSharing, onShareViewInsight, onUnshareViewInsight } =
    useShareViewInsight({ viewInsight, groupId });

  const embedCode = `<iframe
  width="1000"
  height="600"
  src="${publicUrl}"
  title="June"
  frameborder="0"
  allowfullscreen
></iframe>`;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium">Public sharing</p>
          <p className="text-sm text-gray-500">
            Publish and share link with anyone
          </p>
        </div>
        <Switch
          colorScheme="purple"
          isChecked={isSharing}
          onChange={() =>
            isSharing ? onUnshareViewInsight() : onShareViewInsight()
          }
        />
      </div>
      {isSharing && (
        <>
          <CopyCode
            sharingEnabled={isSharing}
            title="Copy public link"
            description="Anyone with the link can view this insight"
            code={publicUrl}
          />
          <CopyCode
            sharingEnabled={isSharing}
            title="Copy embed code"
            description="Use the code to embed this insight"
            code={embedCode}
          />
        </>
      )}
    </div>
  );
};
