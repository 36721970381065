import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useState } from "react";

import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import SmallAddIcon from "core/design-system/components/Icon/Interface/SmallAddIcon";
import { useGetPropertiesQuery } from "core/models/properties";

interface IPropertyComboboxProps {
  appId: number;
  eventId: number;
  property: string | undefined;
  setProperty: (property: string | undefined) => void;
  isAddMode?: boolean;
}

export const PropertyCombobox: React.FC<IPropertyComboboxProps> = ({
  appId,
  eventId,
  property,
  setProperty,
  isAddMode = false,
}) => {
  const { data: properties } = useGetPropertiesQuery({
    appId,
    eventId,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const filteredProperties = properties?.filter((prop) =>
    prop.property.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Popover>
      <PopoverButton>
        <Button
          color={isAddMode ? "purple.500" : "gray.900"}
          variant={isAddMode ? "ghost" : "solid"}
          bg={isAddMode ? undefined : "gray.100"}
          _hover={isAddMode ? undefined : { bg: "gray.200" }}
          border="none"
          borderRightRadius={isAddMode ? undefined : "0px"}
          leftIcon={
            isAddMode ? (
              <SmallAddIcon h={4} w={4} color="purple.500" />
            ) : undefined
          }
          rightIcon={!isAddMode ? <ChevronDownIcon /> : undefined}
          isDisabled={!properties || properties?.length === 0}
        >
          {isAddMode ? "Add a property" : property}
        </Button>
      </PopoverButton>
      <PopoverPanel>
        {({ close }) => (
          <>
            <Lifecycle onUnmount={() => setSearchTerm("")} />
            <Command>
              <CommandInput
                placeholder="Search properties..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <CommandList>
                {!properties ? (
                  <span className="px-2 py-1 sm:leading-4">Loading...</span>
                ) : (
                  <>
                    {filteredProperties?.length === 0 ? (
                      <span className="px-2 py-1 sm:leading-4">
                        No properties found
                      </span>
                    ) : (
                      filteredProperties?.map(({ property: propName }) => (
                        <CommandOption
                          key={propName}
                          value={() => {
                            setProperty(propName);
                            close();
                          }}
                        >
                          <span className="line-clamp-1 text-ellipsis sm:leading-4">
                            {propName}
                          </span>
                        </CommandOption>
                      ))
                    )}
                  </>
                )}
              </CommandList>
            </Command>
          </>
        )}
      </PopoverPanel>
    </Popover>
  );
};
