import {
  AccordionPanel,
  Box,
  Button,
  Flex,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React from "react";

import {
  BREAKDOWN_TYPE,
  CONVERSION_WINDOW_TYPE,
  ENUM_TYPE,
  MULTI_EVENT_TYPE,
  OCCURRENCES_TYPE,
  SINGLE_EVENT_TYPE,
} from "core/constants/report-setup";
import { useReportSetup } from "core/hooks/useReportSetup";
import { BreakdownPicker } from "core/modules/reports/setup/Editor/BreakdownPicker";
import { ConversionWindowPicker } from "core/modules/reports/setup/Editor/ConversionWindowPicker";
import EnumPicker from "core/modules/reports/setup/Editor/Enum";
import { MultiEventPicker } from "core/modules/reports/setup/Editor/Event";
import { OccurrencePicker } from "core/modules/reports/setup/Editor/OccurrencePicker";
import {
  ITemplateConfigSetup,
  ITemplateConfigSetupSection,
} from "core/types/TemplateConfig";

export const SetupAccordionBody: React.FC<{
  section: ITemplateConfigSetupSection;
  isExpanded: boolean;
  setOpenPanelIndex: (index: number | null) => void;
  index: number;
  requiredSections: ITemplateConfigSetup["requiredSections"];
  setup: ITemplateConfigSetup;
}> = ({
  section,
  isExpanded,
  setOpenPanelIndex,
  index,
  requiredSections,
  setup,
}) => {
  const { currentReport } = useReportSetup();
  const hasReachedEnd = index === requiredSections[requiredSections.length - 1];
  function onCollapseClick() {
    if (isExpanded && !hasReachedEnd) {
      setOpenPanelIndex(index + 1);
    }

    if (hasReachedEnd) {
      setOpenPanelIndex(null);
    }
  }

  const validationInput = Array.isArray(currentReport.config[section.configKey])
    ? currentReport.config[section.configKey]
    : [currentReport.config[section.configKey]];

  return (
    <AccordionPanel data-testid="setup-sections-accordion-item-body">
      <Flex direction="column">
        <Flex direction="column">
          {section.description && (
            <Text w="80%" fontSize="xs" color="gray.600" mt={-3}>
              {section.description}
            </Text>
          )}
          <Box pt={section.description ? 4 : 0}>
            {section.type === SINGLE_EVENT_TYPE && (
              <MultiEventPicker setup={setup} section={section} singleEvent />
            )}
            {section.type === MULTI_EVENT_TYPE && (
              <MultiEventPicker setup={setup} section={section} />
            )}
            {section.type === ENUM_TYPE && <EnumPicker section={section} />}
            {section.type === CONVERSION_WINDOW_TYPE && (
              <ConversionWindowPicker />
            )}
            {section.type === BREAKDOWN_TYPE && <BreakdownPicker />}
            {section.type === OCCURRENCES_TYPE && (
              <OccurrencePicker defaultOccurrences={section.occurrences} />
            )}
          </Box>
        </Flex>
        <Spacer />
        <Flex justifyContent="right">
          {section.required && currentReport.config[section.configKey] && (
            <Button
              isDisabled={
                section.validate &&
                !section.validate(validationInput.filter((x: any) => !!x))
              }
              colorScheme="purple"
              onClick={onCollapseClick}
            >
              {(hasReachedEnd && "Done") || "Next"}
            </Button>
          )}
        </Flex>
      </Flex>
    </AccordionPanel>
  );
};
