import { Spinner } from "@chakra-ui/react";
import { useState } from "react";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetEmailAlertsQuery } from "core/models/alerts";
import { DigestTable } from "modules/Settings/Email/DigestTable";

export const EnabledAlerts: React.FC = () => {
  const currentApp = useCurrentApp();
  const [page, setPage] = useState<number>(1);

  const { data: enabledAlerts, isLoading: enabledAlertsLoading } =
    useGetEmailAlertsQuery({
      appId: currentApp.id,
      page,
    });

  return (
    <div className="flex flex-col gap-4">
      {!enabledAlertsLoading && enabledAlerts ? (
        <DigestTable
          pagy={enabledAlerts.pagy}
          alerts={enabledAlerts.alerts}
          setPage={setPage}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};
