import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import { SnakeCasedProperties } from "type-fest";

import { IPinnedReport } from "core/types/PinnedReport";

export interface IndexResponse {
  pinnedReports: IPinnedReport[];
}

export const pinnedReportsApi = createApi({
  reducerPath: "pinnedReportsApi",
  tagTypes: ["PinnedReport"],
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_API_HOST }),
  endpoints: (builder) => ({
    getPinnedReports: builder.query<
      IndexResponse,
      { appId: number; location: string }
    >({
      query: ({ appId, location }) => ({
        url: `/pinned_reports`,
        params: humps.decamelizeKeys({ appId, location }),
        credentials: "include",
        mode: "cors",
      }),
      providesTags: ["PinnedReport"],
      transformResponse: (response: SnakeCasedProperties<IndexResponse>) =>
        humps.camelizeKeys(response) as IndexResponse,
    }),
    updatePinnedReport: builder.mutation<
      IPinnedReport,
      { appId: number; id: number; reportId: number }
    >({
      query: ({ appId, id, reportId }) => ({
        url: `/pinned_reports/${id}`,
        method: "PUT",
        params: humps.decamelizeKeys({ appId, reportId }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["PinnedReport"],
      transformResponse: (response: SnakeCasedProperties<IPinnedReport>) =>
        humps.camelizeKeys(response) as IPinnedReport,
    }),
  }),
});

export const { useGetPinnedReportsQuery, useUpdatePinnedReportMutation } =
  pinnedReportsApi;
export type PinnedReportsApiState = ReturnType<typeof pinnedReportsApi.reducer>;
