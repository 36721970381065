import { Skeleton } from "@chakra-ui/react";

import useSmartTrait from "core/hooks/useSmartTrait";
import { ComputedTraitEditor } from "routes/Settings/sections/ComputedTraitEditor";

export const ComputedTraitEditorContainer: React.FC<{
  traitId: number;
  onClose: () => void;
}> = ({ traitId, onClose }) => {
  const { trait, isLoading, onSave, onCompute, onSetLive, onDelete } =
    useSmartTrait({ traitIdArg: traitId });

  if (isLoading)
    return (
      <div className="flex flex-col gap-2 p-6">
        <div className="flex justify-between">
          <div className="flex flex-col gap-2">
            <Skeleton w="200px" h="20px" />
            <Skeleton w="300px" h="20px" />
          </div>
        </div>
        <div className="mt-10 flex flex-col gap-2">
          <Skeleton w="200px" h="20px" />
          <Skeleton w="full" h="20px" />
          <Skeleton w="full" h="20px" />
        </div>
        <div className="mt-10 flex flex-col gap-2">
          <Skeleton w="200px" h="20px" />
          <Skeleton w="full" h="20px" />
          <Skeleton w="full" h="20px" />
        </div>
        <div className="mt-10 flex flex-col gap-2">
          <Skeleton w="200px" h="20px" />
          <Skeleton w="full" h="20px" />
          <Skeleton w="full" h="20px" />
        </div>
        <div className="mt-10 flex flex-col gap-2">
          <Skeleton w="200px" h="20px" />
          <Skeleton w="full" h="20px" />
          <Skeleton w="full" h="20px" />
        </div>
        <div className="mt-10 flex flex-col gap-2">
          <Skeleton w="200px" h="20px" />
          <Skeleton w="full" h="20px" />
          <Skeleton w="full" h="20px" />
        </div>
      </div>
    );

  return (
    <ComputedTraitEditor
      key={trait?.updatedAt}
      compact
      trait={trait}
      onCompute={onCompute}
      onSave={onSave}
      onSetLive={onSetLive}
      onDelete={onDelete}
    />
  );
};
