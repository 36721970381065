import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Lifecycle } from "@june-so/ui";
import { Check } from "lucide-react";
import * as React from "react";

import { Avatar, AvatarFallback, AvatarImage } from "Components/ui/avatar";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetUsersQuery } from "core/models/users";
import { cx } from "helpers/cx";

interface UserComboboxProps {
  value?: string;
  onValueChange: (value: string) => void;
  disabled?: boolean;
}

export const UserCombobox: React.FC<UserComboboxProps> = ({
  value,
  onValueChange,
  disabled = false,
}) => {
  const [searchValue, setSearchValue] = React.useState("");
  const { id: appId } = useCurrentApp();
  const { data: users, isLoading } = useGetUsersQuery({ appId });
  const selectedUser = users?.find((user) => user.id.toString() === value);

  if (isLoading) {
    return (
      <div className="flex items-center gap-1 py-1 text-sm">
        <div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
      </div>
    );
  }

  if (disabled) {
    return (
      <div
        className={cx(
          "flex items-center gap-1 py-1 text-sm",
          !selectedUser ? "text-gray-500" : "text-gray-800",
        )}
      >
        {selectedUser && (
          <Avatar className="h-4 w-4">
            <AvatarImage className="h-4 w-4" src={selectedUser?.imageUrl} />
            <AvatarFallback className="h-4 w-4 bg-purple-100 text-xs text-purple-500">
              {selectedUser?.email?.charAt(0)}
            </AvatarFallback>
          </Avatar>
        )}
        {selectedUser?.email || "Unassigned"}
      </div>
    );
  }

  return (
    <Popover>
      <PopoverButton className="w-full">
        <button
          type="button"
          className="flex w-full cursor-pointer items-center justify-between gap-1 py-0.5 text-sm"
        >
          {selectedUser && (
            <Avatar className="h-4 w-4">
              <AvatarImage className="h-4 w-4" src={selectedUser?.imageUrl} />
              <AvatarFallback className="h-4 w-4 bg-purple-400 p-0.5 text-xs font-medium text-white">
                {selectedUser?.email?.charAt(0)}
              </AvatarFallback>
            </Avatar>
          )}
          <span
            className={cx(
              "truncate text-left",
              !selectedUser && "text-gray-500",
            )}
          >
            {selectedUser?.email || "Unassigned"}
          </span>
        </button>
      </PopoverButton>
      <PopoverPanel className="absolute z-50 mt-1 min-w-[200px]">
        {({ close }) => (
          <>
            <Lifecycle onUnmount={() => setSearchValue("")} />
            <Command className="flex min-h-[200px] flex-col overflow-hidden rounded-md border border-gray-200 bg-white shadow-lg">
              <CommandInput
                placeholder="Search users..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <CommandList className="flex-1 overflow-auto">
                {users
                  ?.filter((user) =>
                    user.email
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()),
                  )
                  ?.map((user) => (
                    <CommandOption
                      key={user.id}
                      value={() => {
                        onValueChange(String(user.id));
                        close();
                      }}
                      rightIcon={
                        value === user.id.toString() && (
                          <Check className="h-4 w-4 text-gray-800" />
                        )
                      }
                    >
                      <div className="flex items-center gap-2">
                        <Avatar className="h-5 w-5">
                          <AvatarImage
                            className="h-5 w-5"
                            src={user?.imageUrl}
                          />
                          <AvatarFallback className="h-5 w-5 bg-purple-400 text-xs font-medium text-white">
                            {user?.email?.charAt(0)}
                          </AvatarFallback>
                        </Avatar>
                        <span className="truncate">{user.email}</span>
                      </div>
                    </CommandOption>
                  ))}
                {users?.filter((user) =>
                  user.email.toLowerCase().includes(searchValue.toLowerCase()),
                ).length === 0 && (
                  <div className="px-2 py-1 text-sm text-gray-500">
                    No users found
                  </div>
                )}
              </CommandList>
            </Command>
          </>
        )}
      </PopoverPanel>
    </Popover>
  );
};
