import { DownloadIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { ClipboardInput } from "core/components/ClipboardInput";
import { DownloadGraph } from "core/components/DownloadGraph";
import { EmbeddedCode } from "core/components/ShareModal/private/EmbeddedCode";
import { ShareBox } from "core/components/ShareModal/private/ShareBox";
import { PublicIcon } from "core/design-system/components/Icon/Interface/PublicIcon";
import { useToast } from "core/hooks/useToast";
import {
  useRemovePublicAccessMutation as useRemoveInsightPublicAccessMutation,
  useShareInsightMutation,
} from "core/models/insights";
import {
  useRemovePublicAccessMutation as useRemoveReportPublicAccessMutation,
  useShareReportMutation,
} from "core/models/reports";
import { getLevelParam } from "core/modules/reports/utils";
import { IInsightSection } from "core/types/TemplateConfig";

interface IShareInsightProps {
  unit: "report" | "insight";
  title: string | React.ElementType;
  isOpen: boolean;
  isSharing: boolean;
  tokenData?: string;
  insight?: IInsightSection;
  isDownloadable?: boolean;
  onClose: () => void;
  config?: any;
  additionalConfigProps?: any;
}

export const ShareInsight = ({
  isOpen,
  onClose,
  title,
  tokenData,
  insight,
  isSharing,
  unit,
  isDownloadable,
  config,
  additionalConfigProps,
}: IShareInsightProps) => {
  const toast = useToast();
  const paramReportLevel = getLevelParam();
  const { reportId, appId } = useParams();
  const [token, setToken] = useState(tokenData);
  const [shareReport] = useShareReportMutation();
  const [shareInsight] = useShareInsightMutation();
  const [removeReportPublicAccess] = useRemoveReportPublicAccessMutation();
  const [removeInsightPublicAccess] = useRemoveInsightPublicAccessMutation();
  const [sharingEnabled, setSharingEnabled] = useState(isSharing);

  const BASE_URL = import.meta.env.VITE_APP_HOST || "http://localhost:3000";
  const publicUrl =
    unit === "insight"
      ? `${BASE_URL}/a/${appId}/report/${reportId}/${insight?.slug}/${token}?insight_id=${insight?.typeId}&level=${paramReportLevel}`
      : `${BASE_URL}/a/${appId}/report/${reportId}/${token}?level=${paramReportLevel}`;

  const getNewToken = () => {
    if (unit === "insight") {
      const params = { appId, reportId, insightType: insight?.typeId };
      shareInsight(params).then((response: any) =>
        setToken(response?.data?.token),
      );
    } else {
      const params = { appId, reportId };
      shareReport(params).then((response: any) =>
        setToken(response?.data?.token),
      );
    }
  };

  const togglePublicSharing = async (e: any) => {
    if (!e.target.checked) {
      if (unit === "insight") {
        const params = { appId, reportId, insightType: insight?.typeId };
        removeInsightPublicAccess(params).then((response: any) => {
          const error = response?.error;
          if (!error) {
            setSharingEnabled(false);
          } else {
            toast({
              title: "Error",
              description: error.error,
              status: "error",
              duration: 5000,
            });
          }
        });
      } else {
        const params = { appId, reportId };
        removeReportPublicAccess(params).then((response: any) => {
          const error = response?.error;
          if (!error) {
            setSharingEnabled(false);
          } else {
            toast({
              title: "Error",
              description: error.error,
              status: "error",
              duration: 5000,
            });
          }
        });
      }
    } else {
      getNewToken();
      setSharingEnabled(true);
    }
  };

  if (unit === "insight" && isDownloadable)
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="4xl" data-id-share-modal>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottom="1px solid"
            borderColor="gray.200"
            mb={2}
            fontWeight="bold"
          >
            <>Share {title}</>
          </ModalHeader>
          <ModalBody px={6}>
            <Tabs colorScheme="purple">
              <TabList>
                <Tab>
                  <PublicIcon fontSize="18px" mr={1} /> Share on the web
                </Tab>
                <Tab>
                  <DownloadIcon mr={1} /> Download image
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel px={0}>
                  <VStack spacing={8} align="stretch">
                    <Flex direction="column">
                      <Text color="gray.800" fontWeight="medium">
                        Public sharing
                      </Text>
                      <Text
                        color="gray.600"
                        fontSize="sm"
                        fontWeight="medium"
                        mb={2}
                      >
                        Publicly sharing this graph will make the it viewable by
                        anyone with the link
                      </Text>
                      <Flex gridGap={2} align="center">
                        <Flex>
                          <Switch
                            data-id-sharing-enabled={sharingEnabled}
                            size="md"
                            colorScheme="purple"
                            defaultChecked={sharingEnabled}
                            onChange={togglePublicSharing}
                          />
                        </Flex>
                        <Flex>
                          <Text
                            color="gray.600"
                            fontWeight="medium"
                            fontSize="sm"
                          >
                            {sharingEnabled ? "Enabled" : "Disabled"}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    {sharingEnabled && (
                      <Flex direction="column" gridGap={6}>
                        <Flex direction="column">
                          <Text color="gray.800" fontWeight="medium">
                            Copy public link
                          </Text>
                          <Text
                            color="gray.600"
                            fontSize="sm"
                            fontWeight="medium"
                            mb={2}
                          >
                            Anyone with the link can view this graph
                          </Text>
                          <ClipboardInput
                            value={publicUrl}
                            disabled={!sharingEnabled}
                          />
                        </Flex>
                        <Flex>
                          {unit === "insight" && (
                            <EmbeddedCode
                              sharingEnabled={sharingEnabled}
                              url={publicUrl}
                            />
                          )}
                        </Flex>
                      </Flex>
                    )}
                  </VStack>
                </TabPanel>
                <TabPanel px={0}>
                  <Text color="gray.800" fontWeight="medium">
                    Download an image of this graph
                  </Text>
                  <Text
                    color="gray.600"
                    fontSize="sm"
                    fontWeight="medium"
                    mb={4}
                  >
                    Download a snapshot image of this graph and share with
                    teammates, investors or celebrate on socials
                  </Text>
                  {insight ? (
                    <DownloadGraph
                      insight={insight}
                      config={config}
                      additionalConfigProps={additionalConfigProps}
                    />
                  ) : null}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    );

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      data-id-share-modal
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="medium" mb={1}>
          <>Share {title}</>
        </ModalHeader>
        <ModalBody px={6} paddingBottom={6}>
          <VStack spacing={6} align="stretch">
            <Grid
              py={1}
              border="1px solid"
              borderColor="gray.200"
              templateColumns={"1fr 8fr 1fr"}
              alignItems="center"
              borderRadius="md"
              boxShadow="xs"
            >
              <PublicIcon color="gray.400" fontSize={38} margin={"0 auto"} />
              <Text color="gray.800" fontWeight="medium">
                Share to web
                <Text color="gray.600" fontSize="sm" fontWeight="medium">
                  Publish and share link with anyone
                </Text>
              </Text>
              <Switch
                data-id-sharing-enabled={sharingEnabled}
                ml={1}
                size="md"
                colorScheme="purple"
                defaultChecked={sharingEnabled}
                onChange={togglePublicSharing}
              />
            </Grid>
            {sharingEnabled && (
              <ShareBox sharingEnabled={sharingEnabled}>
                <Text color="gray.800" fontWeight="medium" mb={1}>
                  Copy public link
                </Text>
                <Text color="gray.600" fontSize="sm" fontWeight="medium" mb={2}>
                  Anyone with the link can view this {unit}
                </Text>
                <ClipboardInput value={publicUrl} />
              </ShareBox>
            )}
            {unit === "insight" && (
              <EmbeddedCode sharingEnabled={sharingEnabled} url={publicUrl} />
            )}
          </VStack>
        </ModalBody>
        <ModalCloseButton />
        <ModalFooter>
          <Button onClick={onClose} colorScheme="black">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShareInsight;
