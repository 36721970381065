import React from "react";

import { IRetentionInsightUser } from "core/modules/reports/report-types/Retention/ChurningUsersContainer";

export const TotalEventsCell: React.FC<{
  props: IRetentionInsightUser;
  previewMode: boolean;
}> = ({ props, previewMode }) => {
  return <div className="text-sm">{props.totalEvents}</div>;
};
