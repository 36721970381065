import { CloseIcon } from "@chakra-ui/icons";
import { IconButton, Text } from "@chakra-ui/react";

import { useDemo } from "core/hooks/useDemo";
import { OnboardingState } from "core/types/App";
import { IUser } from "core/types/User";
import { inIframe } from "helpers/iframe";

export const DemoBanner: React.FC<{ user: IUser }> = ({ user }) => {
  const { toggleDemo } = useDemo({
    redirectPath:
      user.lastUsedApp.onboardingState === OnboardingState.ONBOARDING
        ? "getting-started"
        : "home",
  });

  if (inIframe()) return null;

  return (
    <div className="fixed z-overlay flex h-1 w-full justify-center bg-orange-400">
      <div className="flex h-6 items-center rounded-bl-md rounded-br-md bg-orange-400 pl-2">
        <Text
          color="white"
          fontSize="sm"
          fontWeight="semibold"
          data-testid="demo-banner-text"
          mr={0.5}
        >
          DEMO WORKSPACE
        </Text>
        <IconButton
          data-testid="demo-banner-close"
          onClick={toggleDemo}
          p={0}
          w="24px"
          minW="24px"
          h="24px"
          variant="unstyled"
          color="white"
          aria-label="Back to workspace"
          fontSize="10px"
          fontWeight="semibold"
          icon={<CloseIcon />}
          transition="transform .3s ease-in-out"
          _hover={{
            color: "gray.100",
            transform: "rotate(90deg)",
            bg: "none",
          }}
        />
      </div>
    </div>
  );
};
