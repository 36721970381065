import { DataDeletion } from "@/modules/Settings/DataDeletion";
import { WorkspaceDeletion } from "@/modules/Settings/WorkspaceDeletion";

export const DangerZone: React.FC = () => {
  return (
    <>
      <h2 className="text-base font-semibold text-gray-900">Danger zone</h2>
      <DataDeletion />
      <WorkspaceDeletion />
    </>
  );
};
