import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";

import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { GroupListSingleGroup } from "core/components/Group/GroupListSingleGroup";
import InsightCard from "core/components/InsightCard/Index";
import { InsightReactions } from "core/components/InsightReactions";
import { FeatureReleaseUsersEmptyState } from "core/design-system/components/Icon/Illustrations/FeatureReleaseUsersEmptyState";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { EngagedUsersList } from "core/modules/reports/report-types/FeatureRelease/EngagedUsersList";
import { isUserReportLevel } from "core/modules/reports/utils";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

export interface IEngageUser {
  userId?: string;
  groupId?: string;
  groupType?: number;
  traits: { [key: string]: string };
  streakInWeeks: number;
  numberOfTimesUsed: number;
}

export interface IEngagedUsersData {
  contacts?: IEngageUser[];
}

const mockData = {
  contacts: [
    {
      userId: "Josephine Edamon",
      groupId: "Josephine Edamon",
      streakInWeeks: 6,
      numberOfTimesUsed: 16,
      traits: { plan: "Pro " },
    },
    {
      userId: "Roy Carpent",
      groupId: "Roy Carpent",
      streakInWeeks: 6,
      numberOfTimesUsed: 10,
      traits: { plan: "Pro " },
    },
    {
      userId: "Anita James",
      groupId: "Anita James",
      streakInWeeks: 5,
      numberOfTimesUsed: 12,
      traits: { plan: "Pro " },
    },
    {
      userId: "Alice Terpantine",
      groupId: "Alice Terpantine",
      streakInWeeks: 5,
      numberOfTimesUsed: 10,
      traits: { plan: "Pro " },
    },
    {
      userId: "Gus Edams",
      groupId: "Gus Edams",
      streakInWeeks: 5,
      numberOfTimesUsed: 10,
      traits: { plan: "Pro " },
    },
  ],
};

export const EngagedUsersContainer: React.FC<IReportInsight> = ({
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken,
  report,
  config,
  reportLevel,
}) => {
  const isUserReport = isUserReportLevel(reportLevel);

  const unit = isUserReport ? "users" : "companies";
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === `engaged-${unit}`,
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { activeAppObject } = useAppObjects();

  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      insight,
      insightParams: { truncate: true },
      sharingSecretToken,
      previewMode,
      skip: sharingMode || !isSetupValid,
    });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: isUserReport ? 11 : 37,
    insightParams: {
      contactType: "engaged",
    },
    count: 1, // hard coded to 1 because we don't know count beforehand
  });

  const data = previewMode ? mockData : (response?.data as IEngagedUsersData);

  const onViewButtonClick = () => {
    if (!sharingMode && !previewMode) {
      onOpen();
    }
  };

  const onExportButtonClick = () => {
    if (!sharingMode && !previewMode) {
      requestCsvExport();
    }
  };

  const noData = data?.contacts?.length === 0;

  if (sharingMode) {
    return null;
  }

  return (
    <>
      <InsightCard>
        {(Card) => (
          <Card.Container insight={insight} id={insight.slug} colSpan={0.5}>
            <Card.Header
              hasCaching={response?.hasCaching}
              screenshotMode={screenshotMode}
              showReportTitle={sharingMode}
              insight={insight}
              showActions={!sharingMode && !previewMode}
              sharingMode={sharingMode}
              config={config}
              report={report}
              refresh={onRefreshInsight}
              cachedAt={response?.cachedAt}
            />
            <Card.Body
              isLoading={!data || isLoading || isFetching}
              isPreviewMode={previewMode}
              isSetupValid={isSetupValid}
              error={error}
            >
              {noData && !isLoading ? (
                <Flex
                  p={5}
                  h={440}
                  direction="column"
                  align="center"
                  justify="center"
                >
                  <FeatureReleaseUsersEmptyState />
                  <Text mt={5} fontSize="md" fontWeight="medium">
                    You released your feature 👏
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    Nobody used it more than once yet 😔
                  </Text>
                </Flex>
              ) : (
                <Flex
                  p={5}
                  h={previewMode ? "fit-content" : 350}
                  id="loaded"
                  direction="column"
                >
                  {data?.contacts?.map((contact: IEngageUser) => (
                    <>
                      {isUserReport ? (
                        <UserListSingleContact
                          key={contact.userId}
                          id={contact.userId || ""}
                          previewMode={previewMode}
                          traits={contact.traits}
                          description={`Used ${contact.numberOfTimesUsed.toString()} times over ${
                            contact.streakInWeeks
                          } weeks`}
                        />
                      ) : (
                        <GroupListSingleGroup
                          key={contact.userId}
                          id={contact.groupId || ""}
                          previewMode={previewMode}
                          traits={contact.traits}
                          groupType={contact.groupType}
                          description={`Used ${contact.numberOfTimesUsed.toString()} times over ${
                            contact.streakInWeeks
                          } weeks`}
                        />
                      )}
                    </>
                  ))}
                </Flex>
              )}
            </Card.Body>
            {!sharingMode && !previewMode && !noData && !isLoading && (
              <Card.Footer>
                <Flex w="100%" direction="column">
                  <Flex>
                    <Flex
                      px={4}
                      pt={4}
                      pb={0}
                      w="100%"
                      direction="column"
                      align="center"
                    >
                      <Text fontSize="sm">
                        Reach out to understand what else they need
                      </Text>
                      <Flex
                        mt={2}
                        px={3}
                        py={2}
                        pb={0}
                        w="100%"
                        justify="space-between"
                      >
                        <Button
                          w="48%"
                          borderRadius="lg"
                          onClick={onViewButtonClick}
                        >
                          <Text fontSize="sm" fontWeight="semibold">
                            View{" "}
                            {isUserReport
                              ? "Users"
                              : activeAppObject?.pluralName}
                          </Text>
                        </Button>
                        <Button
                          w="48%"
                          borderRadius="lg"
                          colorScheme="purple"
                          onClick={onExportButtonClick}
                        >
                          <Text fontSize="sm" fontWeight="semibold">
                            Export as CSV
                          </Text>
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                  <InsightReactions
                    reactions={response?.reactions}
                    insightId={insight.typeId}
                    reportId={report?.id}
                  />
                </Flex>
              </Card.Footer>
            )}
          </Card.Container>
        )}
      </InsightCard>
      {!sharingMode && !previewMode && (
        <EngagedUsersList
          unit={
            isUserReport ? "users" : activeAppObject?.pluralName.toLowerCase()
          }
          isOpen={isOpen}
          onClose={onClose}
          csvExportLoading={csvExportLoading}
          requestCsvExport={requestCsvExport}
          report={report}
          insight={insight}
        />
      )}
    </>
  );
};
