import {
  Flex,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ArrowTopRightOnSquareIcon,
  ChatBubbleLeftIcon,
  EnvelopeIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/outline";

import { DataSource } from "core/models/onboardingChecklist";
import { InviteTeam } from "modules/Onboarding/InviteTeam";

const DOCS: { [key in DataSource]: { name: string; url: string } } = {
  segment: {
    name: "Segment docs",
    url: "https://segment.com/docs/connections/destinations/catalog/june/",
  },
  sdk: {
    name: "June SDK docs",
    url: "https://www.june.so/docs",
  },
  rudderstack: {
    name: "Rudderstack docs",
    url: "https://www.rudderstack.com/docs/destinations/streaming-destinations/june/",
  },
  freshpaint: {
    name: "Freshpaint docs",
    url: "https://www.freshpaint.io/integration/june",
  },
};

export const Resources = ({ dataSource }: { dataSource: DataSource }) => {
  const {
    isOpen: isInviteModalOpen,
    onOpen: onInviteModalOpen,
    onClose: onInviteModalClose,
  } = useDisclosure();

  return (
    <Flex direction="column">
      <Flex
        w="100%"
        p={5}
        gap={5}
        direction="column"
        border="1px solid"
        borderRadius="lg"
        borderColor="gray.200"
      >
        <Link color="gray.800" href={DOCS[dataSource].url} target="_blank">
          <Flex gap={2} align="center">
            <ArrowTopRightOnSquareIcon className="h-5" />
            <Text fontSize="sm">{DOCS[dataSource].name}</Text>
          </Flex>
        </Link>
        <Link
          color="gray.800"
          href="https://june.wistia.com/medias/mzg18vl8he"
          target="_blank"
        >
          <Flex gap={2} align="center">
            <PlayCircleIcon className="h-5" />
            <Text fontSize="sm">Watch demo</Text>
          </Flex>
        </Link>
        {/* @ts-ignore */}
        <Link color="gray.800" onClick={() => window.Intercom("show")}>
          <Flex gap={2} align="center">
            <ChatBubbleLeftIcon className="h-5" />
            <Text fontSize="sm">Chat with us</Text>
          </Flex>
        </Link>
        <Link color="gray.800" onClick={onInviteModalOpen}>
          <Flex gap={2} align="center">
            <EnvelopeIcon className="h-5" />
            <Text fontSize="sm">Ask a teammate for help</Text>
          </Flex>
        </Link>
      </Flex>
      <Modal
        isOpen={isInviteModalOpen}
        onClose={onInviteModalClose}
        size="3xl"
        data-id-share-modal
      >
        <ModalOverlay />
        <ModalContent borderRadius="lg" p={10}>
          <InviteTeam page="getting_started" />
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </Flex>
  );
};
