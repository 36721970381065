import { Box, ComponentDefaultProps, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import {
  Bar as RechartsBar,
  BarChart as RechartsBarChart,
  Rectangle as RechartsRectangle,
  ResponsiveContainer as RechartsResponsiveContainer,
  Tooltip as RechartsTooltip,
} from "recharts";

import {
  addLoadedIdToElement,
  isAnimationDisabled,
  isScreenshotMode,
} from "core/design-system/constants/charts";
import colors from "core/design-system/constants/theme/colors";

// @ts-nocheck

interface IBarChart extends ComponentDefaultProps {
  data: any;
  showYAxis?: boolean;
}

interface CustomTooltipProps {
  label?: string;
  payload?: Array<{ payload: { contactsPercentage: number } }>;
  active?: boolean;
}

const CustomTooltip = ({ label, payload, active }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <Box bg="gray.700" px={3} py={2} borderRadius={5}>
        <Text fontSize="xs" color="gray.400">
          {label}
        </Text>
        <Text fontSize="sm" color="white">
          {payload[0].payload.contactsPercentage}%
        </Text>
      </Box>
    );
  }
  return null;
};

const BarChart = ({ children, data, showYAxis, ...props }: IBarChart) => {
  useEffect(() => {
    addLoadedIdToElement();
  }, []);

  return (
    <RechartsResponsiveContainer width="100%" height="100%">
      <RechartsBarChart
        width="100%"
        height="100%"
        data={data}
        margin={{ top: 30, left: showYAxis ? 0 : -40, right: 40, bottom: 10 }}
        {...props}
      >
        {children}
      </RechartsBarChart>
    </RechartsResponsiveContainer>
  );
};

class BarTooltip extends RechartsTooltip<number, string> {
  static defaultProps = {
    ...RechartsTooltip.defaultProps,
    // Customized props
    cursor: true,
    cursorStyle: { fill: colors.gray[50] },
    content: <CustomTooltip />,
  };
}

const BarRectangle = ({ ...props }) => {
  return <RechartsRectangle radius={[5, 5, 0, 0]} {...props} />;
};

class BarItem extends RechartsBar {
  static defaultProps = {
    ...RechartsBar.defaultProps,
    // Customized props
    shape: <BarRectangle radius={[5, 5, 0, 0]} />,
    barSize: 40,
    fill: "#C6C8FF",
    isAnimationActive: !isAnimationDisabled && !isScreenshotMode,
  };
}

export { BarChart, BarTooltip, BarItem, BarRectangle };
