import { useParams } from "react-router-dom";

import { useCompanyView } from "@/core/hooks/useCompanyView";
import { InsightEditorContainer } from "@/modules/Dashboard/InsightBuilder/EditorContainer";
import { JuneLoadingPage } from "@/modules/Dashboard/LoadingPage";
import { useGetViewInsightQuery } from "core/models/viewInsights";

export const EditObjectInsightRoute: React.FC = () => {
  const { appId, viewInsightId } = useParams();

  const { view, isLoadingView } = useCompanyView();
  const { data: viewInsight, isLoading: isLoadingViewInsight } =
    useGetViewInsightQuery({
      id: Number(viewInsightId),
      viewId: Number(view?.id),
      appId: Number(appId),
    });

  if (isLoadingViewInsight || isLoadingView) {
    return <JuneLoadingPage />;
  }

  if (!viewInsight) return <>View Insight not found</>;

  return (
    <div className="h-screen w-full overflow-hidden">
      <InsightEditorContainer viewInsight={viewInsight} />
    </div>
  );
};
