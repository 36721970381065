import { Button, Text } from "@chakra-ui/react";

import { useAppObjects } from "core/hooks/useAppObjects";

export const ClickableTooltipFooter: React.FC = () => {
  const { activeAppObject } = useAppObjects();

  return (
    <>
      {" "}
      <Button
        w="full"
        variant={"unstyled"}
        borderRadius="md"
        bg="gray.800"
        _hover={{ bg: "gray.700" }}
        color="gray.200"
        mt={2}
      >
        View {activeAppObject?.pluralName.toLowerCase()}
      </Button>
      <Text mt={2} fontSize="xs" color="gray.400">
        Click on the data point to see the list of{" "}
        {activeAppObject?.pluralName.toLowerCase()}
      </Text>
    </>
  );
};
