import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useContactDrilldownQuery } from "core/hooks/useContactDrilldownQuery";
import { useQueryParam } from "core/hooks/useQueryParam";
import { IViewInsight } from "core/models/viewInsights";
import { IDrilldownContact } from "core/types/Contact";
import { IReport } from "core/types/Report";

interface IUseDrilldownProps {
  report: IReport;
  groupId?: string | number;
  skip: boolean;
  insightType: number;
  insightParams?: { [key: string]: any };
  viewInsight?: IViewInsight;
}

const DEFAULT_PAGE_SIZE = 18;

export const useContactDrilldown = ({
  report,
  insightType,
  skip,
  insightParams,
  viewInsight,
}: IUseDrilldownProps) => {
  const { appId, groupId: encodedGroupId } = useParams<
    "appId" | "groupId"
  >() as {
    appId: string;
    groupId: string;
  };
  const groupIdQueryParam = useQueryParam("groupId");
  const groupId = groupIdQueryParam
    ? groupIdQueryParam
    : decodeURIComponent(encodedGroupId || "");

  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [hasMoreContacts, setHasMoreContacts] = useState(false);
  const [contacts, setContacts] = useState<IDrilldownContact[]>([]);
  const [metadata, setMetadata] = useState<{ [key: string]: any }>();
  const { response, isLoading, isFetching, error } = useContactDrilldownQuery({
    appId,
    report,
    groupId,
    insightParams: {
      page,
      limit: DEFAULT_PAGE_SIZE,
      searchQuery,
      ...insightParams,
    },
    insightType,
    skip,
    viewInsight,
  });

  useEffect(() => {
    if (response?.data) {
      if (response.data?.metadata) {
        setMetadata(response.data.metadata);
      }
      if (
        response.data?.contacts?.length === 0 ||
        response.data?.contacts?.length < DEFAULT_PAGE_SIZE
      ) {
        setHasMoreContacts(false);
      } else {
        setHasMoreContacts(true);
      }

      const allUsers =
        page === 0
          ? response?.data?.contacts
          : [...contacts, ...response?.data?.contacts];

      setContacts(allUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const loadMore = () => {
    if (hasMoreContacts) setPage(page + 1);
  };

  const onSearch = (query: string) => {
    setSearchQuery(query);
    setPage(0);
  };

  return {
    isLoading: isLoading || isFetching,
    error,
    hasMoreContacts,
    contacts,
    metadata,
    loadMore,
    searchQuery,
    setSearchQuery,
    onSearch,
    setPage,
  };
};
