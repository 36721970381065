import { Flex, useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";

import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import {
  CommonDot,
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import colors from "core/design-system/constants/theme/colors";
import { CustomTooltip } from "core/modules/reports/report-types/PowerUsers/CustomTooltip";
import { PowerUsersList } from "core/modules/reports/report-types/PowerUsers/PowerUsersList";

// @ts-nocheck

const WEEK = 0;

interface ILineChart {
  unit: string;
  data: any;
  dataKey: string;
  previewMode: boolean;
  sharingMode: boolean;
}

interface ILineChartData {
  x: string;
  y: number;
  date: Date;
}

export const LineChart = ({
  report,
  unit,
  data,
  previewMode,
  sharingMode,
  timerange,
}: ILineChart) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [powerUserCount, setPowerUserCount] = useState(0);
  const [date, setDate] = useState(null);

  const formattedData = data.reduce((formatted: ILineChartData[], row) => {
    formatted.push({
      x: row.humanizedDate,
      y: row.contactsCount,
      date: row.weekStartDate,
    });

    return formatted;
  }, []);

  const onDataPointClick = (_, index) => {
    setDate(index.payload.date);
    setPowerUserCount(parseInt(index.payload.y, 10));
    onOpen();
  };

  return (
    <Flex justify="center" h={300}>
      <AreaChart
        margin={{ top: 30, left: 0, right: 50, bottom: 20 }}
        height="100%"
        data={formattedData}
      >
        <defs>
          <linearGradient id="purpleGradient" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={colors.purple[400]}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={colors.purple[100]}
              stopOpacity={0.4}
            />
          </linearGradient>
          <linearGradient
            id="currentPeriodGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors.gray[100]} stopOpacity={0.4} />
          </linearGradient>
        </defs>
        <CommonGrid />
        <CommonXAxis dataKey="x" />
        <CommonYAxis />
        <CommonTooltip
          content={<CustomTooltip unit={unit} data={formattedData} />}
        />
        <Area
          dataKey="y"
          activeDot={
            <CommonDot r={7} onClick={onDataPointClick} cursor="pointer" />
          }
          type="monotone"
          fillOpacity={1}
          fill="url(#purpleGradient)"
        />
        <Area
          type="monotone"
          fillOpacity={1}
          fill="url(#currentPeriodGradient)"
          dataKey="currentY"
          stroke={colors.gray[500]}
          activeDot={
            <CommonDot r={7} onClick={onDataPointClick} cursor="pointer" />
          }
        />
      </AreaChart>
      {!previewMode && !sharingMode && date && (
        <PowerUsersList
          report={report}
          key={date}
          insightType={5}
          powerUsersCount={powerUserCount}
          daysOfUsage={Number(timerange.value)}
          intervalLength={WEEK}
          date={date}
          isOpen={isOpen}
          onClose={onClose}
          title={`There are ${powerUserCount} users who were active for ${timerange.label}`}
          description={`Week of ${moment(date).format(`MMM DD`)}`}
        />
      )}
    </Flex>
  );
};
