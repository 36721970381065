import { UseDisclosureReturn } from "@chakra-ui/react";

import { IViewInsight } from "@/core/models/viewInsights";
import { Drilldown } from "core/components/Drilldown";
import { Error } from "core/components/Error";
import { GroupListSingleGroup } from "core/components/Group/GroupListSingleGroup";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useGroupDrilldown } from "core/hooks/useGroupDrilldown";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { IReport } from "core/types/Report";

export interface IPowerGroup {
  groupId: string;
  groupType: number;
  traits: { [key: string]: string };
}

interface IPowerGroupsList
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  report: IReport;
  daysOfUsage: number;
  intervalLength: number;
  date: string;
  powerGroupsCount: number;
  viewInsight: IViewInsight;
}

export const PowerGroupsList: React.FC<IPowerGroupsList> = ({
  isOpen,
  onClose,
  report,
  daysOfUsage,
  intervalLength,
  powerGroupsCount,
  date,
  viewInsight,
}) => {
  const insightType = 9;
  const {
    groups: newGroups,
    isLoading,
    error,
    hasMoreGroups,
    loadMore,
  } = useGroupDrilldown({
    report,
    insightType,
    insightParams: {
      daysOfUsage,
      intervalLength,
      date,
    },
    skip: !isOpen,
    viewInsight,
  });
  const { activeAppObject } = useAppObjects();
  const appObject = viewInsight?.appObject || activeAppObject;

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: insightType,
    insightParams: {
      daysOfUsage,
      intervalLength,
      date,
    },
    count: powerGroupsCount,
  });

  const s = daysOfUsage > 1 ? "s" : "";

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              title={`There are ${powerGroupsCount} ${appObject?.pluralName.toLowerCase()} who were active for ${daysOfUsage} day${s}`}
              isLoading={isLoading}
            />
            <Drilldown.DownloadButton
              isDisabled={powerGroupsCount <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              items={newGroups}
              itemRenderer={({ item }: { item: IPowerGroup }) => (
                <GroupListSingleGroup
                  key={item.groupId}
                  id={item.groupId}
                  traits={item.traits}
                  groupType={item.groupType}
                />
              )}
              hasMore={hasMoreGroups}
              isLoading={isLoading}
              loadNext={loadMore}
              emptyStateText={`No power ${appObject?.pluralName.toLowerCase()}`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
