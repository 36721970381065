import React from "react";
import { useParams } from "react-router-dom";

import { ErrorPage } from "Components/Error/Page";
import { Loader } from "Components/Loader";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useGetViewQuery } from "core/models/views";
import { PublicView } from "core/modules/reports/PublicView";

const PublicViewRoute = () => {
  const { appId, viewId, sharingSecretToken, objectId } = useParams<{
    [key: string]: string;
  }>() as {
    appId: string;
    viewId: string;
    sharingSecretToken: string;
    objectId: string;
  };
  const groupIdQueryParam = useQueryParam("groupId");
  const groupId = groupIdQueryParam ? groupIdQueryParam : objectId;
  const {
    data: view,
    isLoading,
    isFetching,
    error,
  } = useGetViewQuery({
    appId: Number(appId),
    id: Number(viewId),
    sharingSecretToken,
    groupId,
  });

  if (isLoading || isFetching) return <Loader />;
  if (error) {
    return (
      <ErrorPage
        title="You don't have permissions to view this dashboard"
        action={{
          text: "Log in",
          href: `${import.meta.env.VITE_APP_HOST}/login`,
        }}
      />
    );
  }

  if (view) {
    return (
      <PublicView
        appId={Number(appId)}
        view={view}
        sharingSecretToken={sharingSecretToken}
      />
    );
  }

  return <ErrorPage />;
};

export default PublicViewRoute;
