import { createSlice, PayloadAction, ThunkDispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { AnyAction } from "redux";

import { DEMO_APP_ID } from "core/constants/appIds";
import * as api from "core/helpers/api";
import { Business, CompanySize, IApp, ReferralSource } from "core/types/App";
import { RootState } from "core/types/store";

export interface IAppState {
  isLoading: boolean;
  isCreating: boolean;
  isDeleting: boolean;
  isDeleted: boolean;
  data: IApp | null;
  errors: {
    fetching: string | null;
    deleting: string | null;
  };
}

const initialState: IAppState = {
  data: null,
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  isDeleted: false,
  errors: {
    fetching: null,
    deleting: null,
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetch: (state: IAppState) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: IAppState, { payload }: { payload: IApp }) => {
      state.isLoading = false;
      state.data = payload;
    },
    fetchError: (state: IAppState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.errors.fetching = payload.toString();
    },
    create: (state: IAppState) => {
      state.isCreating = true;
    },
    update: (state: IAppState) => {
      state.isLoading = true;
    },
    updateSuccess: (state: IAppState, { payload }: { payload: IApp }) => {
      state.isLoading = false;
      state.data = payload;
    },
    updateError: (state: IAppState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.errors.fetching = payload.toString();
    },
    destroy: (state: IAppState) => {
      state.isDeleting = true;
    },
    deleteAppSuccess: (state: IAppState) => {
      state.isDeleting = false;
      state.isDeleted = true;
    },
    deleteAppError: (state: IAppState, { payload }: PayloadAction<string>) => {
      state.isDeleting = false;
      state.errors.deleting = payload.toString();
    },
    createSuccess: (state: IAppState, { payload }: PayloadAction<IApp>) => {
      state.isCreating = false;
      state.data = payload;
    },
    setApp: (state: IAppState, { payload }: PayloadAction<IApp>) => {
      state.data = payload;
    },
    optimisticallyUpdateWeeklyDigestEnabled: (
      state: IAppState,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.data) {
        state.data.weeklyDigestEnabled = payload;
      }
    },
  },
});

export const {
  fetch,
  fetchSuccess,
  fetchError,
  create,
  createSuccess,
  setApp,
  updateSuccess,
  updateError,
  update,
  destroy,
  deleteAppSuccess,
  deleteAppError,
  optimisticallyUpdateWeeklyDigestEnabled,
} = appSlice.actions;
export const selector = (state: RootState) => state.app;
export const selectAppId = (state: RootState) => state.app.data?.id;
export const selectHasFeature = (featureName: string) => (state: RootState) =>
  Boolean(state.app?.data?.features?.includes(featureName));
export default appSlice.reducer;

export const fetchApp =
  ({ appId }: { appId: number }) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(fetch());
    try {
      const { data } = await api.fetchApp({ appId: String(appId), dispatch });
      dispatch(fetchSuccess(data));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchError(error.message));
      } else {
        dispatch(fetchError("An unknown error occurred"));
      }
    }
  };

export const updateApp =
  ({
    appId,
    name,
    timeZone,
    groupContextEnabled,
    enforceGoogleOauth,
    autoJoinCompanyWorkspaceEnabled,
    business,
    companySize,
    companyDomain,
    referralSource,
    hasRequestedAiAccess,
    aiOptedIn,
    defaultObjectId,
  }: {
    appId: number;
    name?: string;
    timeZone?: string;
    groupContextEnabled?: boolean;
    enforceGoogleOauth?: boolean;
    autoJoinCompanyWorkspaceEnabled?: boolean;
    business?: Business;
    companySize?: CompanySize;
    referralSource?: ReferralSource;
    companyDomain?: string;
    hasRequestedAiAccess?: boolean;
    aiOptedIn?: boolean;
    defaultObjectId?: number;
  }) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(update());
    try {
      const { data } = await api.updateApp({
        appId,
        name,
        timeZone,
        groupContextEnabled,
        enforceGoogleOauth,
        autoJoinCompanyWorkspaceEnabled,
        business,
        companySize,
        referralSource,
        companyDomain,
        hasRequestedAiAccess,
        aiOptedIn,
        defaultObjectId,
        dispatch,
      });
      dispatch(updateSuccess(data));
    } catch (error) {
      const err = error as { response?: { status?: number } };
      if (enforceGoogleOauth === true && err.response?.status === 422) {
        dispatch(
          updateError(
            "All your users have to have accounts with Google OAuth to enable this feature.",
          ),
        );
        return;
      }
      dispatch(updateError("Error updating app"));
    }
  };

export const createApp =
  ({ appName }: { appName: string }) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(create());
    const { data } = await api.createApp({ appName, dispatch });
    dispatch(createSuccess(data.app));
    dispatch(setCurrentApp({ app: data.app }));
    window.location.replace(`${window.location.origin}/a/${data.app.id}/home`);
  };

export const setCurrentApp =
  ({ app }: { app: IApp }) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(setApp(app));

    if (app.id !== DEMO_APP_ID) {
      api.updateLastUsedApp({ appId: String(app.id), dispatch });
    }
  };

export const deleteApp =
  ({ appId }: { appId: string }) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(destroy());
    try {
      await api.deleteApp({ appId, dispatch });
      dispatch(deleteAppSuccess());
    } catch (error) {
      if (error instanceof Error) {
        dispatch(deleteAppError(error.message));
      } else {
        dispatch(deleteAppError("An unknown error occurred"));
      }
    }
  };

export const updateLogo =
  ({ appId, logo }: { appId: number; logo: File }) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const data = new FormData();
    data.append("app[logo]", logo, logo.name);

    try {
      await axios.put(
        `${import.meta.env.VITE_API_HOST}/apps/${appId}/attach_logo`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        },
      );

      dispatch(fetchApp({ appId }));
    } catch (error) {
      console.error(error);
    }
  };

export const optimisticUpdateWeeklyDigestEnabled =
  ({ weeklyDigestEnabled }: { weeklyDigestEnabled: boolean }) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(optimisticallyUpdateWeeklyDigestEnabled(weeklyDigestEnabled));
  };
