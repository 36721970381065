import { Button, Flex, Switch, Text } from "@chakra-ui/react";
import { toPng } from "html-to-image";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { DownloadableGraphImage } from "core/components/DownloadableGraphImage";
import { useReportSetup } from "core/hooks/useReportSetup";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";

export interface IAdditionalConfigProps {
  initialTimeRange?: string;
  selectedWeek?: string;
  selectedMonth?: string;
}

export const DownloadGraph: React.FC<{
  insight: IInsightSection;
  config: ITemplateConfig;
  additionalConfigProps: IAdditionalConfigProps;
  togglesCentered?: boolean;
}> = ({ insight, config, additionalConfigProps, togglesCentered }) => {
  const { appId, reportId } = useParams<{
    appId: string;
    reportId: string;
  }>() as {
    appId: string;
    reportId: string;
  };

  const [showYAxis, setShowYAxis] = useState(true);
  const [showInProgress, setShowInProgress] = useState(false);
  const { currentReport } = useReportSetup();
  if (currentReport && currentReport.reportType === undefined) return null;

  function onDownload() {
    toPng(document.getElementById("downloadable-image")!, {
      pixelRatio: 5,
    }).then((dataUrl) => {
      const link = document.createElement("a");
      link.download = `${insight.slug}.png`;
      link.href = dataUrl;
      link.click();
      window.analytics.track(
        "downloaded_graph_image",
        {
          appId,
          reportId,
          insightSlug: insight.slug,
        },
        { context: { groupId: appId } },
      );
    });
  }

  return (
    <>
      <Flex justifyContent={togglesCentered ? "center" : ""} mb={4} gridGap={4}>
        {insight.hasYAxis && (
          <div className="flex">
            <Switch
              defaultChecked={showYAxis}
              colorScheme="purple"
              onChange={() => setShowYAxis(!showYAxis)}
            />
            <Text fontSize="sm" fontWeight="medium" as="span" ml={2}>
              Show Y axis
            </Text>
          </div>
        )}
        {insight.hasInProgress && (
          <div className="flex">
            <Switch
              defaultChecked={showInProgress}
              colorScheme="purple"
              onChange={() => setShowInProgress(!showInProgress)}
            />
            <Text fontSize="sm" fontWeight="medium" as="span" ml={2}>
              Show in progress
            </Text>
          </div>
        )}
      </Flex>
      <DownloadableGraphImage
        report={currentReport}
        config={config}
        insight={insight}
        showYAxis={showYAxis}
        additionalConfigProps={additionalConfigProps}
        showInProgress={showInProgress}
        screenshotMode={true}
      />
      <Button my={4} float="right" colorScheme="purple" onClick={onDownload}>
        Download image
      </Button>
    </>
  );
};
