import { IBlockComponent } from "core/components/AI/ConversationContainer";
import { Result } from "core/components/AI/Graph/Result";
import { Message } from "core/components/AI/Message";

export const MessageResult: React.FC<IBlockComponent> = ({
  delay,
  isReverse,
  ...props
}) => {
  return (
    <Message delay={delay} isReverse={isReverse} w="700px">
      <Result result={props.result} query={props.query} />
    </Message>
  );
};
