import { Tooltip } from "@chakra-ui/react";
import { useState } from "react";

import { DATE_RANGE_OPTIONS_FROM_VALUE } from "core/constants/timerange";
import { useEditDashboard } from "core/hooks/useEditDashboard";
import { IViewInsight } from "core/models/viewInsights";
import { ITimerangeOption } from "core/types/TimerangeOption";
import { cx } from "helpers/cx";

function isSelected(
  option: ITimerangeOption,
  timerange: ITimerangeOption,
  changed: boolean,
  viewInsight: IViewInsight | null,
) {
  if (changed) {
    return timerange.value === option.value;
  }

  if (viewInsight) {
    return (
      DATE_RANGE_OPTIONS_FROM_VALUE[viewInsight?.timerangeValue].value ===
      option.value
    );
  }
  return timerange.value === option.value;
}

export const TimeRangePicker = ({
  setTimeRange,
  timerange,
  options,
}: {
  previewMode: boolean;
  setTimeRange: (timerange: ITimerangeOption) => void;
  timerange: ITimerangeOption;
  options: ITimerangeOption[];
}) => {
  const [changed, setChanged] = useState(false);
  const { fullModeInsight } = useEditDashboard();

  return (
    <div className="flex items-center rounded-md bg-gray-100 px-0.5">
      {options.map((o: ITimerangeOption, index: number) => {
        const selected = isSelected(
          o,
          timerange,
          changed,
          fullModeInsight?.viewInsight,
        );

        return (
          <Tooltip
            key={index}
            cursor="pointer"
            label={o.label}
            shouldWrapChildren
            hasArrow
            placement="top"
          >
            <div
              key={o.value}
              data-testid="time-range-picker-item"
              className={cx(
                "flex cursor-pointer items-center rounded-md px-1.5 py-1 text-xs",
                selected
                  ? "h-5 bg-white shadow-sm"
                  : "bg-transparent text-gray-500",
              )}
              onClick={() => {
                setChanged(true);
                setTimeRange(o);
              }}
            >
              {o.short}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};
