import { ChatIcon } from "@chakra-ui/icons";

import { Action } from "core/components/AI/Action";
import { IBlockComponent } from "core/components/AI/ConversationContainer";
import { useAI } from "core/hooks/useAI";

export const PromptSuggestion: React.FC<IBlockComponent> = ({
  delay,
  isReverse,
  children,
}) => {
  const { ask } = useAI();
  return (
    <Action
      onClick={() => ask(children?.toString() || "")}
      delay={delay}
      isReverse={isReverse}
    >
      <ChatIcon mr={2} /> {children}
    </Action>
  );
};
