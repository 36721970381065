import { Center, ComponentDefaultProps, Skeleton } from "@chakra-ui/react";

export const Loader: React.FC<ComponentDefaultProps> = (props) => {
  return (
    <Center
      data-testid="highlight-card-loader"
      h="full"
      w="full"
      px={3}
      py={3}
      {...props}
    >
      <Skeleton h="full" w="full" />
    </Center>
  );
};
