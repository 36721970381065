import { MenuItem } from "@chakra-ui/react";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { AIContainer } from "core/components/AI";
import { AiOptInModal } from "core/components/AI/OptInModal";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { DEMO_APP_ID } from "core/constants/appIds";
import { baseUrl } from "core/constants/paywall";
import { useAI } from "core/hooks/useAI";
import { usePaywall } from "core/hooks/usePaywall";
import { useAiOptInMutation } from "core/models/apps";

export const AskAiItem: React.FC = () => {
  const { appId, reportId } = useParams();
  const { togglePrompt, aiOptedIn } = useAI();
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const { shouldBePaywalled } = usePaywall();
  const redirectUrl = `${baseUrl}/a/${appId}/reports/${reportId}`;
  const [aiOptIn] = useAiOptInMutation();

  if (Number(appId) === DEMO_APP_ID) return null;

  function handleClick() {
    if (shouldBePaywalled) return setShowPaywallModal(true);
    if (!aiOptedIn) return setShowTermsModal(true);

    togglePrompt(true);
  }

  function handleOptIn() {
    aiOptIn({ appId, aiOptedIn: true }).then(() => {
      window.location.reload();
    });
  }
  return (
    <MenuItem onClick={handleClick}>
      <div className="w-full">
        <div className="flex w-full items-center gap-2">
          <SparklesIcon className="h-4" />
          <p>Ask AI</p>
        </div>
        <AIContainer />
        {showPaywallModal && (
          <PaywallModal redirectUrl={redirectUrl}>
            {(paywallModal: IPaywallModalChildren) => (
              <paywallModal.Modal
                isOpen
                onClose={() => setShowPaywallModal(false)}
              />
            )}
          </PaywallModal>
        )}
        <AiOptInModal
          isOpen={showTermsModal}
          onClose={() => setShowTermsModal(false)}
          onAgree={handleOptIn}
        />
      </div>
    </MenuItem>
  );
};
