import { Select, Skeleton, Switch } from "@chakra-ui/react";

import { useToast } from "core/hooks/useToast";
import {
  IDigestInsight,
  useUpdateDigestInsightMutation,
} from "core/models/digestInsights";
import { useGetSearchReportsQuery } from "core/models/reports";
import { IReport } from "core/types/Report";

export const DigestInsightRow: React.FC<{ digestInsight: IDigestInsight }> = ({
  digestInsight,
}) => {
  const toast = useToast();
  const { data: reports, isFetching } = useGetSearchReportsQuery({
    appId: digestInsight.appId,
    query: undefined,
    labelIds: [],
    reportTypes: [digestInsight.reportType],
  });
  const [updateDigestInsight] = useUpdateDigestInsightMutation();

  async function handleReportChange(e: React.ChangeEvent<HTMLSelectElement>) {
    await updateDigestInsight({
      id: digestInsight.id,
      appId: digestInsight.appId,
      reportId: Number(e.target.value),
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Digest updated",
          description: `You have successfully updated the report for ${digestInsight.insight.name}`,
          duration: 5000,
        });
      });
  }

  async function handleToggle() {
    await updateDigestInsight({
      id: digestInsight.id,
      appId: digestInsight.appId,
      isEnabled: !digestInsight.isEnabled,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Digest updated",
          description: `You have successfully ${!digestInsight.isEnabled ? "enabled" : "disabled"} ${digestInsight.insight.name}`,
          duration: 5000,
        });
      });
  }

  return (
    <tr key={digestInsight.id}>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
        {digestInsight.insight.name}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
        <div className="flex gap-2">
          {isFetching ? (
            <Skeleton h="40px" w="150px" borderRadius="md" />
          ) : (
            <div className="flex w-1/2 flex-col">
              <Select
                value={digestInsight.reportId}
                onChange={handleReportChange}
                className="py-0"
                fontSize="sm"
                borderRadius="lg"
                focusBorderColor="purple"
                placeholder="Choose a report"
              >
                {reports?.map((report: IReport) => {
                  return <option value={report.id}>{report.name}</option>;
                })}
              </Select>
            </div>
          )}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
        <Switch
          isChecked={digestInsight.isEnabled}
          onChange={handleToggle}
          size="sm"
          className="ml-1"
          colorScheme="purple"
        />
      </td>
    </tr>
  );
};
