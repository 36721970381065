import { createSlice, Dispatch } from "@reduxjs/toolkit";

import { ErrorData, ErrorState } from "@/core/types/Error.d";

const initialState: ErrorState = {
  data: null,
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    show: (state, { payload }: { payload: ErrorData }) => {
      state.data = payload;
    },
  },
});

export const { show } = errorSlice.actions;
export const selector = (state: { error: ErrorState }) => state.error;
export const errorReducer = errorSlice.reducer;

export const showError =
  ({ title, action }: ErrorData) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(show({ title, action }));
  };
