import React, { useState } from "react";
import { toast } from "sonner";

import { Button } from "@/Components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/Components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/Components/ui/select";
import { useCurrentApp } from "@/core/hooks/useCurrentApp";
import { useDeleteUserMutation } from "@/core/models/users";
import { IAppUser } from "@/core/types/User";

interface IRemoveTeammateModalProps {
  member: IAppUser;
  onClose: () => void;
  isOpen: boolean;
  teamMembers: IAppUser[];
}

export const RemoveTeammateModal: React.FC<IRemoveTeammateModalProps> = ({
  member,
  onClose,
  isOpen,
  teamMembers,
}) => {
  const { id: appId } = useCurrentApp();
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteUser] = useDeleteUserMutation();

  const onDeleteUser = async () => {
    if (!selectedUserId) {
      toast.error("Please select a team member to reassign resources to");
      return;
    }

    setIsLoading(true);
    try {
      await deleteUser({
        userId: member.id,
        appId,
        reassignToUserId: selectedUserId,
      }).unwrap();
      toast.success("Account successfully suspended and resources reassigned");
      onClose();
    } catch (error) {
      console.error("Error removing user:", error);
      toast.error("Failed to remove user from workspace");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  const availableTeamMembers = teamMembers.filter(
    (user) => user.id !== member.id,
  );

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Remove {member.email} from the workspace</DialogTitle>
          <DialogDescription>
            You're about to remove {member.email} from this workspace. This will
            revoke their access and transfer their resources to another team
            member. To ensure a smooth transition, please select a new owner for
            their resources.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-2 py-4">
          <label
            htmlFor="reassign-select"
            className="text-sm font-medium text-gray-700"
          >
            Transfer ownership to
          </label>
          <Select
            onValueChange={(value) => setSelectedUserId(Number(value))}
            value={selectedUserId?.toString() || ""}
            disabled={isLoading}
          >
            <SelectTrigger id="reassign-select" className="w-full">
              <SelectValue placeholder="Choose a team member" />
            </SelectTrigger>
            <SelectContent>
              {availableTeamMembers.map((user) => (
                <SelectItem key={user.id} value={user.id.toString()}>
                  {user.email}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <p className="pt-4 text-sm text-gray-700">
            This action will reassign the following resources to the selected
            user:
          </p>
          <ul className="list-disc pl-5 text-sm text-gray-700">
            <li>Installed apps (e.g., HubSpot, Slack, Stripe)</li>
            <li>Charts & Reports</li>
            <li>Shared charts and dashboards</li>
            <li>Slack and email alerts</li>
          </ul>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={onDeleteUser}
            disabled={!selectedUserId || isLoading}
          >
            {isLoading ? "Removing..." : "Remove member"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
