import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Spinner,
} from "@chakra-ui/react";

import { SlackInsightAlertEditor } from "core/components/InsightCard/SlackInsightAlertEditor";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useGetAlertQuery } from "core/models/alerts";
import { IViewInsight } from "core/models/viewInsights";
import { AppObjectType } from "core/types/AppObject";
import { IReport } from "core/types/Report";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";

interface ISlackInsightEditorDrawerProps {
  config?: Partial<ITemplateConfig>;
  report?: Partial<IReport>;
  insight: IInsightSection;
  viewInsight?: IViewInsight;
  onClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
  appId: string;
  reportId: string;
}

export const SlackInsightEditorDrawer: React.FC<
  ISlackInsightEditorDrawerProps
> = ({
  insight,
  config = {},
  report = {},
  isOpen,
  onClose,
  isLoading: isLoadingProp,
  appId,
  reportId,
}) => {
  const { activeAppObject } = useAppObjects();
  const { data: existingAlert, isLoading: isLoadingAlert } = useGetAlertQuery({
    appId: Number(appId),
    reportId,
    insightId: Number(insight.typeId),
    alertType: 0,
    objectType: activeAppObject?.objectType || AppObjectType.User,
  });

  const isLoading = isLoadingProp || isLoadingAlert;

  return (
    <Drawer trapFocus={false} size="lg" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        {isLoading ? (
          <Flex justify="center" align="center" minH="200px">
            <Spinner />
          </Flex>
        ) : (
          <SlackInsightAlertEditor
            insight={insight}
            config={config as ITemplateConfig}
            report={report as IReport}
            appId={appId}
            reportId={reportId}
            existingAlert={existingAlert}
            onClose={onClose}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
};
