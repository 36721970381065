import moment from "moment";

import { IntervalType } from "core/components/CohortTable/CohortTable.types";
import { IntervalTypeNames } from "core/constants/timerange";

export const getCohortPrefix = (intervalType: IntervalType) => {
  switch (intervalType) {
    case IntervalTypeNames.DAY:
      return "on";
    case IntervalTypeNames.WEEK:
      return "week of";
    case IntervalTypeNames.MONTH:
      return "in";
    default:
      return "on";
  }
};

export const getIntervalDate = ({
  intervalType,
  startDate,
  endDate,
}: {
  intervalType: IntervalType;
  startDate: string;
  endDate: string;
}) => {
  switch (intervalType) {
    case "month": {
      return moment(startDate).format("MMM YYYY");
    }
    case "week": {
      if (endDate === "") return `${moment(startDate).format("DD MMM YYYY")}`;
      return `${moment(startDate).format("MMM DD")}-${moment(endDate).format("DD YYYY")}`;
    }
    default: {
      return moment(startDate).format("MMM DD YYYY");
    }
  }
};

export const unitTerminology = {
  company: {
    singular: "company",
    plural: "companies",
  },
  user: {
    singular: "user",
    plural: "users",
  },
};

export const formatRetentionDate = ({
  intervalType,
  startDate,
  endDate,
}: {
  intervalType: string;
  startDate: string;
  endDate: string;
}) => {
  switch (intervalType) {
    case "day":
      return moment.utc(startDate).format("Do MMMM, YYYY");
    case "week":
      return moment.utc(startDate).month() === moment.utc(endDate).month()
        ? moment.utc(startDate).format("Do - ") +
            moment.utc(endDate).format("Do MMMM, YYYY")
        : moment.utc(startDate).format("Do MMM - ") +
            moment.utc(endDate).format("Do MMMM, YYYY");
    case "month":
      return moment.utc(startDate).format("MMMM, YYYY");
    default:
      return moment.utc(startDate).format("Do MMMM, YYYY");
  }
};
