import { IViewInsight, useGetViewInsightQuery } from "core/models/viewInsights";
import { InsightBuilder } from "modules/ViewInsight/Modal/InsightBuilder";

export const InsightBuilderContainer: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  viewId: number;
  viewInsight: IViewInsight;
  setShowChooseExistingReportInsights?: (show: boolean) => void;
  isAddingNewInsight?: boolean;
  onRefetch?: () => Promise<unknown>;
}> = ({
  isOpen,
  onClose,
  viewId,
  viewInsight,
  setShowChooseExistingReportInsights,
  isAddingNewInsight,
  onRefetch,
}) => {
  const { data } = useGetViewInsightQuery({
    id: viewInsight.id,
    viewId: viewInsight.viewId,
    appId: viewInsight.appId,
  });

  if (!data) return null;

  return (
    <InsightBuilder
      isOpen={isOpen}
      onClose={onClose}
      viewId={viewId}
      viewInsight={data}
      setShowChooseExistingReportInsights={setShowChooseExistingReportInsights}
      isAddingNewInsight={isAddingNewInsight}
      onRefetch={onRefetch}
    />
  );
};
