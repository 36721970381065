import { Button, Center, Flex, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import { Link, useParams } from "react-router-dom";

import CompanyAvatar from "core/components/Avatars/CompanyAvatar";
import ContactAvatar from "core/components/Avatars/ContactAvatar";
import { Drilldown } from "core/components/Drilldown";
import { getCompanyName } from "core/helpers/companyName";
import { useClearbitLogo } from "core/hooks/useClearbitLogo";
import { useGroupDomain } from "core/hooks/useGroupDomain";
import { useGetInsightQuery } from "core/models/insights";
import { IMostEngagedCompany } from "core/modules/reports/report-types/GroupEngagement/CompaniesList";

export interface IGroupDrillDown {
  group: IMostEngagedCompany;
  isCompanyLevel: boolean;
  isOpen: boolean;
  onClose: () => void;
}

interface IContactInsight {
  id: string;
  email?: string;
  occurrences: number;
  appId: string;
  traits: { [key: string]: any };
}

const GroupDrillDown: React.FC<IGroupDrillDown> = ({
  group,
  isCompanyLevel,
  isOpen,
  onClose,
}) => {
  const { appId, reportId } = useParams<{ appId: string; reportId: string }>();
  const insightType = 2;
  const { data: response, isFetching } = useGetInsightQuery(
    {
      appId,
      reportId,
      insightType,
      groupId: group.groupId,
      groupType: isCompanyLevel ? "company" : "group",
    },
    { skip: !isOpen },
  );

  const { groupId, traits } = group;
  const domain = useGroupDomain({ group });
  const logoURL = useClearbitLogo({
    domain: isCompanyLevel ? groupId : domain,
  });
  const companyName = isCompanyLevel
    ? groupId
    : getCompanyName({ traits }) || "Unknown";
  const profileUrl = isCompanyLevel
    ? `/a/${appId}/company/${groupId}/`
    : `/a/${appId}/group/${encodeURIComponent(groupId)}`;

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <CompanyAvatar
              mb={4}
              group={{ id: groupId }}
              size="md"
              logoURL={logoURL}
            />
            <Drilldown.Title title={companyName} isLoading={isFetching} />
            <Text fontSize="sm" color="gray.600">
              {group.contacts?.length} user
              {group.contacts?.length === 1 ? "" : "s"}
            </Text>
            <Button mt={4} as={Link} to={profileUrl}>
              View company
            </Button>
          </Drilldown.Header>
          <Drilldown.Body>
            {isFetching ? (
              <Center h="100%" w="100%">
                <Spinner />
              </Center>
            ) : (
              <Flex direction="column">
                <Flex
                  borderRadius="lg"
                  p={4}
                  bg="gray.50"
                  justifyContent="space-between"
                >
                  <Flex>
                    <Text
                      fontSize="xs"
                      textTransform="uppercase"
                      color="gray.600"
                    >
                      User
                    </Text>
                  </Flex>
                  <Flex>
                    <Text
                      fontSize="xs"
                      textTransform="uppercase"
                      color="gray.600"
                    >
                      Event count
                    </Text>
                  </Flex>
                </Flex>
                {response?.data?.map((contact: IContactInsight) => {
                  return (
                    <Flex
                      _hover={{ bg: "#fcfcfc" }}
                      cursor="pointer"
                      py={4}
                      px={4}
                      borderRadius="lg"
                      justifyContent="space-between"
                      as={Link}
                      to={`/a/${appId}/contact/${encodeURIComponent(contact?.id)}`}
                    >
                      <Flex align="center">
                        <ContactAvatar mr={2} size="xs" contact={contact} />
                        <Text>{contact?.email || contact.id}</Text>
                      </Flex>
                      <Flex>{contact?.occurrences}</Flex>
                    </Flex>
                  );
                })}
              </Flex>
            )}
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};

export default GroupDrillDown;
