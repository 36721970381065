import { FilterType } from "core/constants/report-setup";
import { IAudienceFilters } from "core/types/Audience";

export function hasFiltersByFilterType(
  audienceFilters: IAudienceFilters,
  filterType: FilterType,
) {
  return audienceFilters?.filterGroups?.some((filterGroup) =>
    filterGroup.filters.some((filter) => filter.type === filterType),
  );
}
