import { Alert, CloseButton, useDisclosure } from "@chakra-ui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { DeletionRequestState } from "core/types/App";

const LOCAL_STORAGE_ENVIRONMENTS_BANNER = "app_deletion_banner";

export const AppDeletedBanner = () => {
  const app = useCurrentApp();
  const defaultIsOpen =
    localStorage.getItem(LOCAL_STORAGE_ENVIRONMENTS_BANNER) !== "true";
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen });
  const onCloseBanner = () => {
    localStorage.setItem(LOCAL_STORAGE_ENVIRONMENTS_BANNER, "true");
    onClose();
  };

  if (app.deletionRequestState === DeletionRequestState.NOT_REQUESTED)
    return null;
  if (!isVisible) return null;

  return (
    <Alert
      mt={4}
      py={2}
      px={4}
      bg="red.50"
      justifyContent="space-between"
      borderRadius="lg"
    >
      <div className="flex items-center gap-2">
        <ExclamationTriangleIcon className="h-5 w-5 text-red-600" />
        <div className="text-sm">
          This app has been marked for deletion and can no longer receive
          events.
        </div>
      </div>
      <CloseButton color="gray.600" onClick={onCloseBanner} />
    </Alert>
  );
};
