import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import { ReferenceLine } from "recharts";

import { TooltipBody } from "core/components/TooltipBody";
import {
  Scatter,
  ScatterCell,
  ScatterChart,
  ScatterGrid,
  ScatterTooltip,
  ScatterXAxis,
  ScatterYAxis,
} from "core/design-system/charts/Scatter";
import TickText from "core/design-system/charts/TickText";
import { Icon } from "core/design-system/components/Icon";
import templateColors from "core/design-system/constants/theme/colors";
import { getSerieColors } from "core/modules/reports/report-types/Explorer/ExplorationContainer";
import { ExplorationLegend } from "core/modules/reports/report-types/Explorer/ExplorationLegend";
import { IEvent } from "core/types/Event";
import { Size } from "core/types/ViewInsight";

interface IScatterPlotGraphDatum {
  uuid: string;
  frequency: number;
  index: number;
  name: IEvent["name"];
  popularity: number;
  type: IEvent["type"];
}

type ScatterPlotGraphData = IScatterPlotGraphDatum[];

interface IScatterPlotGraphProps {
  data: ScatterPlotGraphData;
}

const ScatterPlotGraph: React.FC<IScatterPlotGraphProps> = ({ data }) => {
  const [hoveringIndex, setHoveringIndex] = useState<string | undefined>(
    undefined,
  );

  const serieColors = getSerieColors(data);
  const series = data?.map((d, index) => ({
    ...d,
    color: serieColors[d.index],
  }));

  return (
    <>
      {series && series.length && (
        <Flex direction="column">
          <Flex direction="row" align="center" justify="flex-start" h="400px">
            <Flex
              ml={10}
              w="20px"
              direction="column"
              align="center"
              justify="center"
            >
              <Tooltip
                label={
                  <TooltipBody
                    header="Frequency"
                    text="Frequency is the ratio of the number of days an event was performed, divided by the number of days any events were performed in your product"
                  />
                }
                placement="top"
                borderRadius="lg"
              >
                <Box mb={6} transform="rotate(-90deg)">
                  <Icon iconType="icon" name="question-purple" />
                </Box>
              </Tooltip>
              <Text
                color="gray.600"
                fontSize="xs"
                textTransform="uppercase"
                transform="rotate(-90deg)"
                my={1}
                fontWeight="medium"
              >
                Frequency
              </Text>
            </Flex>
            <Box w="100%" h="100%">
              <ScatterChart>
                <ScatterGrid />
                {/* @ts-ignore */}
                <ScatterXAxis
                  tick={({
                    y,
                    ...props
                  }: {
                    y: number;
                    payload: { value: number };
                  }) => (
                    <TickText y={y + 10} {...props}>
                      {props.payload.value}%
                    </TickText>
                  )}
                  dataKey="popularity"
                  name="popularity"
                />
                {/* @ts-ignore */}
                <ScatterYAxis
                  dataKey="frequency"
                  name="frequency"
                  tick={({
                    x,
                    y,
                    ...props
                  }: {
                    x: number;
                    y: number;
                    payload: { value: number };
                  }) => (
                    <TickText x={x - 5} y={y + 5} {...props}>
                      {props.payload.value}%
                    </TickText>
                  )}
                />
                <ScatterTooltip />
                <ReferenceLine x="50" stroke={templateColors.gray[300]} />
                <ReferenceLine y={50} stroke={templateColors.gray[300]} />
                <Scatter data={series}>
                  {series.map((serie, index) => (
                    // @ts-ignore
                    <ScatterCell
                      key={`cell-${index}`}
                      fill={serie.color}
                      fillOpacity={
                        hoveringIndex !== undefined
                          ? String(serie.uuid) === String(hoveringIndex)
                            ? 1
                            : 0.2
                          : 1
                      }
                    />
                  ))}
                </Scatter>
              </ScatterChart>
            </Box>
          </Flex>
          <Flex mt={5} mb={4} justify="center" align="center">
            <Text
              fontWeight="medium"
              color="gray.600"
              fontSize="xs"
              textTransform="uppercase"
              my={1}
            >
              Popularity
            </Text>
            <>
              <Tooltip
                label={
                  <TooltipBody
                    header="Popularity"
                    text="Popularity is the ratio of the number of people who used this feature to the number of people who used any feature (not only the ones selected in this report)"
                  />
                }
                placement="top"
                borderRadius="lg"
              >
                <Box ml={1}>
                  <Icon iconType="icon" name="question-purple" />
                </Box>
              </Tooltip>
            </>
          </Flex>
          <div className="max-w-full overflow-x-scroll">
            <ExplorationLegend
              series={series}
              size={Size.Large}
              onHover={setHoveringIndex}
            />
          </div>
        </Flex>
      )}
    </>
  );
};

export default ScatterPlotGraph;
