import { Button, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { cx } from "@/helpers/cx";
import { CreateEventModal } from "core/components/Event/CreateEventModal";
import { List } from "core/components/List";
import { ErrorMessage } from "core/components/List/ErrorMessage";
import { PageContainer } from "core/components/PageContainer";
import { SearchBar } from "core/components/SearchBar";
import { Tab } from "core/components/Tabs/Tab";
import { PAGE, TRACK } from "core/constants/report-setup";
import { Ping } from "core/design-system/components/Ping";
import { useEventList } from "core/hooks/useEventList";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { GettingStartedBanner } from "modules/Home/GettingStartedBanner";
import DefaultHeader from "modules/Pages/DefaultHeader";

const SECTION = {
  name: "Events",
  title: "Events",
  description: "The events that you have sent",
  howItWorksArticle:
    "https://help.june.so/en/articles/5095720-track-in-product-basic-behaviors",
  howItWorksArticleId: "5095720",
  sectionRight: () => (
    <div className="flex items-center gap-2">
      <Button px={5} as={RouterLink} to="./feed" leftIcon={<Ping />}>
        View live feed
      </Button>
      <CreateEventModal />
    </div>
  ),
};

const Events = () => {
  const { shouldShowGettingStartedBanner } = useOnboardingChecklist();
  const [eventType, setEventType] = useState<number>(TRACK);
  const {
    listConfig,
    events,
    pagy,
    isLoading,
    error,
    skipPagination,
    currentPage,
    previousPage,
    nextPage,
    setPage,
    searchQuery,
    setSearchQuery,
  } = useEventList({ eventType });

  return (
    <PageContainer minW="container.lg" maxW="full">
      <Stack justify="flex-start" align="flex-start">
        <div
          className={cx(
            "flex w-full items-start justify-between",
            shouldShowGettingStartedBanner && "opacity-40 grayscale",
          )}
        >
          <DefaultHeader w="inherit" section={SECTION} />
        </div>
        <div className="mt-2 flex">
          <Tab
            key="events"
            isSelected={eventType === TRACK}
            onTabChange={() => {
              setPage(1);
              setSearchQuery("");
              setEventType(TRACK);
            }}
            pl={0}
          >
            <div className="flex gap-1">Events</div>
          </Tab>
          <Tab
            key="pageviews"
            isSelected={eventType === PAGE}
            onTabChange={() => {
              setPage(1);
              setSearchQuery("");
              setEventType(PAGE);
            }}
            pl={0}
          >
            <div className="flex gap-1">Pageviews</div>
          </Tab>
        </div>
      </Stack>
      {error ? (
        <ErrorMessage />
      ) : (
        <>
          <div className="flex pb-10">
            <div
              className={cx(
                "my-3 flex w-full flex-col",
                shouldShowGettingStartedBanner && "opacity-40 grayscale",
              )}
            >
              <SearchBar
                mb={4}
                searchQuery={searchQuery}
                onSearch={setSearchQuery}
                placeholder="Search your events by name..."
              />
              <List
                config={listConfig}
                isLoading={isLoading}
                rows={events as any[]}
                pagy={pagy}
                pagination={{
                  currentPage,
                  previousPage,
                  nextPage,
                }}
                hideAvatar={true}
                skipPagination={skipPagination}
              />
            </div>
          </div>
          {shouldShowGettingStartedBanner && <GettingStartedBanner />}
        </>
      )}
    </PageContainer>
  );
};

export default Events;
