import humps from "humps";

import {
  INTERVAL_TYPE_NUMBERS,
  IntervalTypeNames,
} from "core/constants/timerange";
import axios from "core/initializers/axios";
import { AppThunk } from "core/initializers/store";
import { slice } from "core/models/group/index";
import { IGroupActivity } from "core/types/Group";
import { IFilterEvents } from "modules/Contacts/Contact/ContactActivity";

interface IGroupActivityParams {
  appId: number | string;
  id: string;
  intervalType: IntervalTypeNames;
  filterEvents: IFilterEvents;
}

export const fetchOneGroupActivity =
  ({ appId, id, intervalType, filterEvents }: IGroupActivityParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.fetchingGroupActivity({ id }));
      const { data } = await axios.get<IGroupActivity>(
        `/groups/${id}/activity`,
        {
          params: humps.decamelizeKeys({
            appId,
            intervalType: INTERVAL_TYPE_NUMBERS[intervalType],
            filterEvents,
          }),
        },
      );

      dispatch(slice.actions.upsertOneGroupActivity({ id, data }));
    } catch (e: any) {
      const title = "Failed to fetch group activity";
      const description = e?.response?.data?.error;
      const error = `${title}: ${description}`;
      dispatch(slice.actions.fetchGroupActivityFailed({ id, error }));
    }
  };
