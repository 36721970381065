import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { Drilldown } from "core/components/Drilldown";
import { Error } from "core/components/Error";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { IPowerUsersList } from "core/modules/reports/report-types/PowerUsers/PowerUsers.types";

export const PowerUsersList = ({
  report,
  daysOfUsage,
  intervalLength,
  powerUsersCount,
  isOpen,
  onClose,
  title = "",
  description,
  insightType,
  date,
  viewInsight,
}: IPowerUsersList) => {
  const {
    contacts,
    isLoading,
    error,
    hasMoreContacts,
    loadMore,
    searchQuery,
    onSearch,
  } = useContactDrilldown({
    report,
    insightType: insightType || 2,
    skip: !isOpen,
    insightParams: { daysOfUsage, intervalLength, date },
    viewInsight,
  });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: insightType || 2,
    count: powerUsersCount,
    insightParams: {
      daysOfUsage,
      intervalLength,
      date,
    },
  });

  const s = daysOfUsage > 1 ? "s" : "";

  const onDrilldownClose = () => {
    onClose();
    onSearch("");
  };

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onDrilldownClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              isLoading={isLoading}
              title={
                title ||
                `There are ${powerUsersCount} people who were active for ${daysOfUsage} day${s}`
              }
              description={description}
            />
            <Drilldown.DownloadButton
              isDisabled={powerUsersCount <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
            <Drilldown.Search
              placeholder="Search your users by email address..."
              searchQuery={searchQuery}
              onSearch={onSearch}
              disabled={powerUsersCount <= 0}
            />
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              items={contacts}
              hasMore={hasMoreContacts}
              itemRenderer={({ item }) => (
                <UserListSingleContact
                  key={item.userId}
                  id={item.userId}
                  traits={item.traits}
                />
              )}
              loadNext={loadMore}
              isLoading={isLoading}
              emptyStateText={`No power user`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
