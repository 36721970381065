import { useNavigate, useParams } from "react-router-dom";

import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/Components/ui/resizable";
import { ViewLocation } from "@/core/hooks/useViews";
import { NavContainer } from "@/modules/Dashboard/InsightBuilder/Nav/NavContainer";
import { PreviewContainer } from "@/modules/ViewInsight/SQLEditor/PreviewContainer";
import { SetupContainer } from "@/modules/ViewInsight/SQLEditor/SetupContainer";
import { PageContainer } from "core/components/PageContainer";
import { useSqlEditor } from "core/hooks/useSqlEditor";
import { IViewInsight } from "core/models/viewInsights";

export const InsightEditorContainer: React.FC<{
  viewInsight: IViewInsight;
}> = ({ viewInsight }) => {
  const { appId, viewId, appObjectId, objectId } = useParams();
  const navigate = useNavigate();
  const sqlEditor = useSqlEditor({ viewInsight, execute: true });

  const onSave = async () => {
    if (viewInsight.view.location === ViewLocation.Home) {
      navigate(`/a/${appId}/home/${viewId}`);
    } else {
      navigate(`/a/${appId}/objects/${appObjectId}/object/${objectId}`);
    }
  };

  return (
    <PageContainer className="ml-1" maxW="full" py="0" containerless>
      <div className="flex h-full max-h-full w-full flex-col">
        <div className="flex flex-row items-center justify-between border-b border-gray-200 px-4 py-1">
          <NavContainer
            viewInsight={viewInsight}
            isUpdatingViewInsight={false}
            onCreate={sqlEditor.onCreate}
            onSave={onSave}
            onUpdateReport={() => {}}
          />
        </div>

        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={30}
            minSize={25}
            maxSize={40}
            className="p-2"
          >
            <SetupContainer
              prompt={sqlEditor.prompt}
              setPrompt={sqlEditor.setPrompt}
              query={sqlEditor.query}
              setQuery={sqlEditor.setQuery}
              suggestion={sqlEditor.suggestion}
              isPromptFetching={sqlEditor.isPromptFetching}
              isExecuteFetching={sqlEditor.isExecuteFetching}
              isPromptLoading={sqlEditor.isPromptLoading}
              isExecuteLoading={sqlEditor.isExecuteLoading}
              onPrompt={sqlEditor.onPrompt}
              onExecute={sqlEditor.onExecute}
              onApply={sqlEditor.onApply}
              onReject={sqlEditor.onReject}
              hasError={sqlEditor.hasError}
              showAIAssistant={sqlEditor.showAIAssistant}
              setShowAIAssistant={sqlEditor.setShowAIAssistant}
            />
          </ResizablePanel>
          <ResizableHandle showBorder />
          <ResizablePanel defaultSize={75}>
            <PreviewContainer
              data={sqlEditor.data}
              size={sqlEditor.size}
              setSize={sqlEditor.setSize}
              graphType={sqlEditor.graphType}
              setGraphType={sqlEditor.setGraphType}
              colorPalette={sqlEditor.colorPalette}
              setColorPalette={sqlEditor.setColorPalette}
              xAxis={sqlEditor.xAxis}
              setXAxis={sqlEditor.setXAxis}
              yAxis={sqlEditor.yAxis}
              setYAxis={sqlEditor.setYAxis}
              title={sqlEditor.title}
              setTitle={sqlEditor.setTitle}
              isLoadingData={
                sqlEditor.isExecuteFetching || sqlEditor.isExecuteLoading
              }
            />
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </PageContainer>
  );
};
