import {
  Button,
  ButtonProps,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import { CheckCircleIcon, Square2StackIcon } from "@heroicons/react/24/outline";
import { isString } from "lodash";
import React from "react";

import { TooltipBody } from "core/components/TooltipBody";

interface IClipboardButton extends ButtonProps {
  value: string;
  showLabel?: boolean;
  disabled?: boolean;
  copyText?: string;
}

export const PopoverCopy: React.FC<{
  text: string;
  children: React.ReactElement;
  disabled?: boolean;
}> = ({ text, children, disabled = false }) => {
  const { onCopy, hasCopied } = useClipboard(text);

  return disabled ? (
    <>{children}</>
  ) : (
    <div className="flex min-w-full items-center">
      <Popover trigger="hover" placement="right" openDelay={0} closeDelay={0}>
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContent
          background="none"
          border="none"
          boxShadow="none"
          px={3}
          ml={-4}
        >
          <PopoverBody px={0}>
            <Tooltip
              label={hasCopied ? "Copied" : "Copy"}
              placement="top"
              closeDelay={0}
              openDelay={0}
              closeOnPointerDown
              hasArrow
            >
              {!hasCopied ? (
                <Square2StackIcon
                  className="h-4 w-4"
                  onClick={(e) => {
                    e.preventDefault();
                    onCopy();
                  }}
                  aria-label="Copy"
                />
              ) : (
                <CheckCircleIcon className="h-4 w-4" />
              )}
            </Tooltip>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export const ClipboardButton = ({
  value,
  copyText,
  showLabel = true,
  disabled = false,
  ...props
}: IClipboardButton) => {
  const { onCopy, hasCopied } = useClipboard(value);
  const labeltext = hasCopied ? "Copied" : "Copy";
  return showLabel ? (
    <Button
      data-id-copy-button
      leftIcon={
        hasCopied ? (
          <CheckCircleIcon className="h-4 w-4" />
        ) : (
          <Square2StackIcon className="h-4 w-4" />
        )
      }
      size="fit"
      background="white"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onCopy();
      }}
      p={1}
      variant="ghost"
      color="purple.500"
      fontWeight="regular"
      fontSize="sm"
      disabled={disabled}
      {...props}
    >
      {labeltext}
    </Button>
  ) : (
    <Tooltip
      label={isString(copyText) ? <TooltipBody text={copyText} /> : undefined}
      placement="bottom"
      borderRadius="lg"
      closeDelay={100}
      hasArrow
      closeOnClick={false}
    >
      <IconButton
        mr={2}
        aria-label="Copy"
        disabled={disabled}
        icon={
          hasCopied ? (
            <CheckCircleIcon className="h-4 w-4" />
          ) : (
            <Square2StackIcon className="h-4 w-4" />
          )
        }
        size="fit"
        background="white"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onCopy();
        }}
        px={2}
        py={1}
        variant="ghost"
        color="purple.500"
        {...props}
      />
    </Tooltip>
  );
};
