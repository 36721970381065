import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import AnimatedToggle from "core/components/AnimatedToggle";
import { INSIGHTS_API } from "core/constants/features";
import useFlag from "core/hooks/useFlag";
import { IViewInsight } from "core/models/viewInsights";
import { APIContent } from "modules/ViewInsight/Share/ApiContent";
import { PublicLink } from "modules/ViewInsight/Share/PublicLink";
import { PublicQueryLink } from "modules/ViewInsight/Share/PublicQueryLink";

enum ShareOption {
  Embed = "embed",
  Api = "api",
}

interface IShareOption {
  label: string;
  value: string;
  isDisabled: boolean;
}

export const ShareModal: React.FC<{
  viewInsight: IViewInsight;
  isOpen: boolean;
  onClose: () => void;
}> = ({ viewInsight, isOpen, onClose }) => {
  const isSQL = viewInsight.query !== null;
  const hasInsightsAPI = useFlag(INSIGHTS_API);
  const { objectId } = useParams<{ objectId: string }>();

  const SHARE_OPTIONS: IShareOption[] = [
    {
      label: "Embed",
      value: ShareOption.Embed,
      isDisabled: false,
    },
    {
      label: "API",
      value: ShareOption.Api,
      isDisabled: !hasInsightsAPI,
    },
  ];

  const [shareOption, setShareOption] = useState<string>(
    SHARE_OPTIONS[0].value,
  );

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <div className="flex items-center gap-1">
            <GlobeAltIcon className="inline-block h-5 w-5" />
            <p className="text-lg font-medium">Share</p>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!isSQL && (
            <PublicLink
              viewInsight={viewInsight}
              groupId={objectId || undefined}
            />
          )}
          {isSQL && (
            <>
              {hasInsightsAPI && (
                <div className="flex pb-5">
                  <AnimatedToggle
                    items={SHARE_OPTIONS}
                    value={shareOption}
                    onChange={(shareOption) => {
                      setShareOption(shareOption);
                    }}
                    shouldAnimate={false}
                  />
                </div>
              )}
              {shareOption === ShareOption.Embed && (
                <PublicQueryLink
                  viewInsight={viewInsight}
                  groupId={objectId || undefined}
                />
              )}
              {shareOption === ShareOption.Api && (
                <APIContent viewInsight={viewInsight} />
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter gap={2}>
          <Button colorScheme="purple" onClick={onClose}>
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
