import { useSelector } from "react-redux";

import {
  GENEROUS_MAU_LIMIT,
  USAGE_BASED_PRICING,
} from "core/constants/features";
import { selector as appSelector } from "core/models/app";
import { IApp, Plan } from "core/types/App";

export const FREE_THRESHOLD = 1_000;
export const GENEROUS_FREE_THRESHOLD = 30_000;
export const GROWTH_THRESOLD = 2_000;
export const PRO_THRESOLD = 5_000;
export const LEGACY_PAYING_THRESHOLD = 10_000;

interface IuseMauThreshold {
  userCount: number;
  barColor: string;
  threshold: number;
  MAULimitForPlan: number;
  isApproachingLimit: boolean;
  isAboveLimit: boolean;
  startDate: string;
  endDate: string;
  additionalMAUs: number;
  isGuardrail: boolean;
  isTrialEnded: boolean;
  hasUsageBasedPricing: boolean;
}

export function getMAULimit({ app }: { app: IApp }) {
  const hasGenerousFreeMauLimit = app.features.includes(GENEROUS_MAU_LIMIT);

  if (app.plan === Plan.Free && !hasGenerousFreeMauLimit) return FREE_THRESHOLD;
  if (app.plan === Plan.Free && hasGenerousFreeMauLimit)
    return GENEROUS_FREE_THRESHOLD;
  if (app.plan === Plan.Growth) return GROWTH_THRESOLD;
  if (app.plan === Plan.Pro) return PRO_THRESOLD;

  return FREE_THRESHOLD;
}

export function useMauThreshold(): IuseMauThreshold {
  const { data: app } = useSelector(appSelector);
  if (!app)
    return {
      userCount: 0,
      barColor: "purple",
      threshold: FREE_THRESHOLD,
      MAULimitForPlan: FREE_THRESHOLD,
      isApproachingLimit: false,
      isAboveLimit: false,
      startDate: "",
      endDate: "",
      additionalMAUs: 0,
      isGuardrail: false,
      isTrialEnded: false,
      hasUsageBasedPricing: false,
    };

  const hasUsageBasedPricing = app.features.includes(USAGE_BASED_PRICING);
  const hasGenerousFreeMauLimit = app.features.includes(GENEROUS_MAU_LIMIT);
  const isFreePlan = app?.subscriptionState === "free";
  const MAULimitForPlan = getMAULimit({ app });
  const userCount =
    (hasUsageBasedPricing
      ? app?.currentBillingUserCount
      : app?.monthlyUserCount) || 0;
  const MAUIncluded =
    userCount < MAULimitForPlan
      ? MAULimitForPlan
      : Math.ceil(userCount / 1000) * 1000;
  const freePlanThreshold = hasGenerousFreeMauLimit
    ? GENEROUS_FREE_THRESHOLD
    : FREE_THRESHOLD;
  const threshold = isFreePlan
    ? freePlanThreshold
    : hasUsageBasedPricing
      ? MAUIncluded
      : LEGACY_PAYING_THRESHOLD;
  const isAboveLimit = userCount >= threshold;
  const isApproachingLimit =
    threshold - userCount < 100 && threshold - userCount > 0;
  const barColor = isAboveLimit
    ? "red"
    : isApproachingLimit
      ? "orange"
      : "purple";
  const MAURounded = Math.floor(userCount / 1000) * 1000;
  const hasAdditionalMAUs = MAURounded >= MAULimitForPlan;
  const additionalMAUs =
    (hasAdditionalMAUs ? MAURounded - MAULimitForPlan : 0) / 1000;
  const isGuardrail = app?.isCurrentBillingGuardrail;
  const isTrialEnded = app?.isTrialing && app?.noCardTrialEnded;

  return {
    userCount,
    barColor,
    threshold,
    MAULimitForPlan,
    isApproachingLimit,
    isAboveLimit,
    isGuardrail,
    startDate: app.billingPeriodStartDate,
    endDate: app.billingPeriodEndDate,
    additionalMAUs,
    isTrialEnded,
    hasUsageBasedPricing,
  };
}
