import { ComponentDefaultProps } from "@chakra-ui/react";

import { Card } from "@/Components/ui/card";

export interface IContainterProps extends ComponentDefaultProps {
  children: React.ReactNode;
  hoverProps?: { [key: string]: string | undefined };
}

export const Container: React.FC<IContainterProps> = ({
  children,
  hoverProps = {},
  ...props
}) => {
  return (
    <Card
      className="h-full max-h-full w-full max-w-full rounded-lg border-gray-100 bg-white shadow-md shadow-sm hover:cursor-pointer hover:border-gray-200 hover:shadow-lg hover:transition-all hover:duration-200"
      data-testid="highlight-card-container"
      {...props}
    >
      {children}
    </Card>
  );
};
