import { Button, Container } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import { cx } from "@/helpers/cx";
import { inIframe } from "@/helpers/iframe";
import { ErrorPage } from "Components/Error/Page";
import { Loader } from "Components/Loader";
import { CompanyJuneLogoIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { useGetViewInsightQuery } from "core/models/viewInsights";
import { AudienceViewInsightContainer } from "modules/ViewInsight/AudienceViewInsightContainer";
import { PublicViewInsight } from "modules/ViewInsight/PublicViewInsight";

const SignUpBanner = () => {
  if (inIframe()) return null;

  return (
    <div
      className="flex w-[985px] items-start justify-center"
      data-id-public-banner
      data-testid="public-banner"
    >
      <div className="mb-4 flex w-full items-center justify-between rounded-lg border border-gray-50 bg-white px-4 py-3 shadow-sm">
        <div className="flex items-center">
          <div className="flex">
            <CompanyJuneLogoIcon h={6} w={6} color="primary" />
          </div>
          <p className="ml-4 text-sm font-medium">
            Welcome to June! Create an account to edit and collaborate on this
            dashboard.
          </p>
        </div>
        <div className="flex items-center">
          <Button as="a" href="https://analytics.june.so/login" fontSize="sm">
            Log in
          </Button>
          <Button
            ml={4}
            as="a"
            href="https://analytics.june.so/start"
            fontSize="sm"
            colorScheme="purple"
          >
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};

export const PublicViewInsightRoute: React.FC = () => {
  const { appId, viewId, viewInsightId, objectId, sharingSecretToken } =
    useParams<{
      [key: string]: string;
    }>() as {
      appId: string;
      viewId: string;
      viewInsightId: string;
      objectId: string;
      sharingSecretToken: string;
    };

  const {
    data: viewInsight,
    isLoading,
    isFetching,
    error,
  } = useGetViewInsightQuery({
    appId: Number(appId),
    id: Number(viewInsightId),
    viewId: Number(viewId),
    sharingSecretToken,
    groupId: objectId,
  });

  if (isLoading || isFetching) return <Loader />;
  if (error) {
    return (
      <ErrorPage
        title="You don't have permissions to view this insight"
        action={{
          text: "Log in",
          href: `${import.meta.env.VITE_APP_HOST}/login`,
        }}
      />
    );
  }

  if (viewInsight) {
    return (
      <div
        data-testid="page-container"
        className={cx(
          "h-full min-h-[100vh] w-full min-w-[100vw] overflow-hidden bg-gray-50",
        )}
      >
        <div className="h-[99.9vh] p-2">
          <div className={cx("h-full w-full overflow-auto rounded-lg py-8")}>
            <Container
              px="60px"
              mx="auto"
              w={inIframe() ? "100%" : "auto"}
              maxW={inIframe() ? "100%" : "container.lg"}
              minW={inIframe() ? "auto" : "container.lg"}
            >
              <SignUpBanner />
              {viewInsight.audience ? (
                <AudienceViewInsightContainer
                  viewInsight={viewInsight}
                  isLoading={isLoading}
                  isFetching={isFetching}
                />
              ) : (
                <PublicViewInsight
                  key={viewInsight.id}
                  viewInsight={viewInsight}
                  sharingSecretToken={sharingSecretToken}
                  isAI={Boolean(viewInsight.queryId)}
                />
              )}
            </Container>
          </div>
        </div>
      </div>
    );
  }

  return <ErrorPage />;
};
