import { Box, Flex, StyleProps, Text } from "@chakra-ui/react";
import { useRef, useEffect } from "react";
import Typed from "typed.js";

import { JuneSpinner } from "@/core/components/JuneSpinner";

const LOADING_TEXTS = [
  "Grabbing your data...",
  "Crunching the numbers...",
  "Almost there...",
  "Wow! This is taking a while...",
];

export const LoadingChart: React.FC<StyleProps> = ({ ...styleProps }) => {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: LOADING_TEXTS,
      typeSpeed: 50,
      backSpeed: 30,
      showCursor: false,
      backDelay: 5000,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <Box
      textAlign="center"
      justifyContent="center"
      h="350px"
      bg="white"
      data-testid="insight-card-loading"
      pos="relative"
      {...styleProps}
    >
      <Box display="relative" w="100%" h="300px" mb={4}>
        <Flex
          h="100%"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <JuneSpinner />
          <Flex mt={2} direction="column">
            <Text
              h="30px"
              ref={el}
              fontSize="sm"
              fontWeight="semibold"
              color="gray.700"
            ></Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
