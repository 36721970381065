import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Button } from "@june-so/ui";
import { useState } from "react";

import { IAppObject } from "core/types/AppObject";

export enum TraitValueType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Date = "date",
}

interface ITraitWithConfig {
  id: number;
}

interface TypeData {
  valueCount: number;
}

export const TypeSummary: React.FC<{
  trait: ITraitWithConfig;
  type: TraitValueType;
  appObject: IAppObject;
  typeData?: TypeData;
}> = ({ trait, type, appObject, typeData }) => {
  const [showSamples, setShowSamples] = useState(false);

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {`${typeData?.valueCount ?? 0} ${typeData?.valueCount === 1 ? appObject.singularName.toLowerCase() : appObject.pluralName.toLowerCase()}`}{" "}
        have a valid {type.toLowerCase()} value
      </td>
      <td className="whitespace-nowrap py-4 text-sm text-gray-500">
        <Button
          variant="ghost"
          onClick={() => setShowSamples(!showSamples)}
          disabled={true}
        >
          Show {appObject.pluralName.toLowerCase()}
          <ChevronRightIcon className="h-4 w-4" />
        </Button>
      </td>
    </tr>
  );
};
