import { cx } from "class-variance-authority";
import { useParams } from "react-router-dom";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { IViewInsight } from "@/core/models/viewInsights";

export const AudienceIndicator: React.FC<{ viewInsight: IViewInsight }> = ({
  viewInsight,
}) => {
  const { groupId, objectId } = useParams();

  return (
    <div>
      {!(Boolean(groupId) || Boolean(objectId)) &&
        !Boolean(viewInsight?.audience) && (
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                className={cx(
                  "mr-2 w-1 rounded-full",
                  viewInsight?.query ? "h-[20px] " : "h-[35px]",
                  viewInsight?.useViewAudience
                    ? "bg-purple-500"
                    : "bg-gray-400",
                )}
              />
            </TooltipTrigger>
            <TooltipContent>
              <p>
                {groupId || objectId
                  ? `Uses ${viewInsight?.appObject?.singularName}'s users`
                  : viewInsight?.useViewAudience
                    ? "Uses dashboard's audience filters"
                    : "Uses insight's audience filters"}
              </p>
            </TooltipContent>
          </Tooltip>
        )}
    </div>
  );
};
