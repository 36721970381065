import { Button, Flex, Input } from "@chakra-ui/react";
import { useState } from "react";

import { IColorPalette } from "core/design-system/constants/theme/colors";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useLabels } from "core/hooks/useLabels";
import { ColorPicker, DEFAULT_COLORS } from "modules/Labels/ColorPicker";

export const CreateLabels: React.FC = () => {
  const [color, setColor] = useState<IColorPalette>(DEFAULT_COLORS[0]);
  const [labelName, setLabelName] = useState<string>("");

  const currentApp = useCurrentApp();
  const { createLabel } = useLabels({ appId: currentApp.id });

  const onCreateLabel = () => {
    setLabelName("");
    createLabel({
      appId: currentApp.id,
      name: labelName,
      color: color[500],
    });
  };

  return (
    <Flex
      data-testid="create-labels-container"
      p={4}
      mb={4}
      gridGap={2}
      bg="gray.100"
      borderRadius="lg"
    >
      <ColorPicker color={color} setColor={setColor} />
      <Input
        size="sm"
        placeholder="Label name"
        bg="white"
        borderRadius="lg"
        value={labelName}
        onChange={(e) => setLabelName(e.target.value)}
      />
      <Button
        px={8}
        colorScheme={"purple"}
        onClick={onCreateLabel}
        isDisabled={!labelName.length}
      >
        Create
      </Button>
    </Flex>
  );
};
