import { Button } from "@chakra-ui/button";
import { Box, Text } from "@chakra-ui/layout";
import copy from "copy-to-clipboard";

import { useToast } from "core/hooks/useToast";
import rawWidgetScript from "modules/MobileWidgets/Scriptable";

export default function InstallButton({ insightUrl }) {
  const toast = useToast();

  function copyRawScript(url) {
    // Replace the API constant and prep it for the user.
    const script = rawWidgetScript.replace(
      "const REPORT_URL = null",
      `const REPORT_URL = "${url}"`,
    );
    copy(script);
    toast({
      title: "Copied to clipboard",
    });
  }
  return (
    <div style={{ position: "relative" }}>
      {!insightUrl && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            background: "rgba(255, 255, 255, 0.9)",
            width: "125%",
            left: "-25px",
            height: "100%",
            top: 0,
            zIndex: 2,
          }}
        >
          <Box
            boxShadow="sm"
            backgroundColor="white"
            px={4}
            py={2}
            borderRadius={"full"}
          >
            <Text fontSize="sm" textColor="primary">
              Select an insight to continue.
            </Text>
          </Box>
        </div>
      )}
      <Button
        size={"lg"}
        colorScheme="purple"
        onClick={() => copyRawScript(insightUrl)}
      >
        Copy to clipboard
      </Button>
    </div>
  );
}
