import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "core/initializers/rtk";
import { ILabel } from "core/types/Label";

interface GetLabelsArgs {
  appId: number | string;
}

interface CreateLabelArgs {
  appId: number | string;
  name: string;
  color: string;
}

interface UpdateLabelArgs {
  id: number | string;
  appId: number | string;
  name: string;
  color: string;
}

interface DeleteLabelArgs {
  appId: number | string;
  id: number | string;
}

interface DeleteLabelResponse {
  status: number;
  message: string;
}

interface IBackendLabel {
  id: string;
  app_id: string;
  name: string;
  color: string;
}

export const labelsApi = createApi({
  baseQuery,
  reducerPath: "labelsApi",
  tagTypes: ["Label"],
  endpoints: (builder) => ({
    getLabels: builder.query<ILabel[], GetLabelsArgs>({
      query: ({ appId }) => ({
        url: "/labels",
        params: { app_id: appId },
      }),
      providesTags: ["Label"],
      transformResponse: (response: IBackendLabel[]): ILabel[] =>
        response.map((label) => ({
          id: label.id,
          appId: label.app_id,
          name: label.name,
          color: label.color,
        })),
    }),
    createLabel: builder.mutation<ILabel, CreateLabelArgs>({
      query: ({ appId, name, color }) => ({
        url: "/labels",
        method: "POST",
        body: { app_id: appId, name, color },
      }),
      invalidatesTags: ["Label"],
      transformResponse: (response: IBackendLabel): ILabel => ({
        id: response.id,
        appId: response.app_id,
        name: response.name,
        color: response.color,
      }),
    }),
    updateLabel: builder.mutation<ILabel, UpdateLabelArgs>({
      query: ({ id, appId, name, color }) => ({
        url: `/labels/${id}`,
        method: "PUT",
        body: { id, app_id: appId, name, color },
      }),
      invalidatesTags: ["Label"],
      transformResponse: (response: IBackendLabel): ILabel => ({
        id: response.id,
        appId: response.app_id,
        name: response.name,
        color: response.color,
      }),
    }),
    deleteLabel: builder.mutation<DeleteLabelResponse, DeleteLabelArgs>({
      query: ({ appId, id }) => ({
        url: `/labels/${id}`,
        method: "DELETE",
        params: { app_id: appId },
      }),
      invalidatesTags: ["Label"],
    }),
  }),
});

export const {
  useGetLabelsQuery,
  useCreateLabelMutation,
  useUpdateLabelMutation,
  useDeleteLabelMutation,
} = labelsApi;
