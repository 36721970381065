import { Flex, Stack, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";

import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { ErrorMessage } from "core/components/List/ErrorMessage";
import { PageContainer } from "core/components/PageContainer";
import { CreateButton as SavedAudienceCreateButton } from "core/components/SavedAudiences/CreateButton";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { usePeopleCount } from "core/hooks/usePeopleCount";
import { usePeopleList } from "core/hooks/usePeopleList";
import { Level } from "core/models/people";
import { AudienceFilters } from "core/modules/audience/Filters/AudienceFilters";
import { AppObjectType } from "core/types/AppObject";
import DefaultHeader from "modules/Pages/DefaultHeader";
import { ObjectList } from "modules/People/ObjectList";
import { PeopleSelect } from "modules/People/Select";

const SECTION = {
  name: "People",
  title: "People",
  description: "The customers that you have identified in your product",
  howItWorksArticle:
    "https://help.june.so/en/articles/8053235-how-audience-filters-work",
};

export const People: React.FC = () => {
  const {
    isOpen: isAudienceModalOpen,
    onClose: closeAudienceModal,
    onOpen: openAudienceModal,
  } = useDisclosure();

  const app = useCurrentApp();
  const { setActiveAppObject, activeAppObject } = useAppObjects();

  useEffect(() => {
    if (app?.defaultObject && !activeAppObject) {
      setActiveAppObject(app.defaultObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    onSetAudienceFilters,
    audienceFilters,
    searchQuery,
    setPage,
    setSearchQuery,
    listConfig,
    entities,
    pagy,
    isLoading,
    error,
    skipPagination,
    currentPage,
    previousPage,
    nextPage,
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
  } = usePeopleList({});
  const { matchedCount, totalCount, isLoadingCount } = usePeopleCount({
    audienceFilters,
    searchQuery,
    level:
      activeAppObject?.objectType === AppObjectType.User
        ? Level.User
        : Level.Group,
    groupType:
      activeAppObject?.objectType === AppObjectType.Group
        ? GroupType.Group
        : activeAppObject?.objectType === AppObjectType.Company
          ? GroupType.Company
          : undefined,
  });

  return (
    <div className="flex gap-1">
      <div className="w-full">
        <PageContainer
          className="w-full min-w-full max-w-full"
          px="60px"
          maxW="full"
          minW="container.lg"
          overflow="overflow-y-auto"
        >
          <DefaultHeader
            section={{
              ...SECTION,
              sectionRight: () => (
                <SavedAudienceCreateButton
                  onClick={openAudienceModal}
                  isDisabled={Boolean(audienceFilters.filterGroups?.length)}
                  audienceFilters={audienceFilters}
                  isOpen={isAudienceModalOpen}
                  onClose={closeAudienceModal}
                />
              ),
            }}
          />
          <Stack
            w="full"
            maxW="full"
            flex="1"
            justify="flex-start"
            align="flex-start"
          >
            <Flex
              data-id-contacts-list-container
              w="full"
              maxW="full"
              direction="column"
              justify="space-between"
            >
              <div className="mb-2 flex w-full items-start justify-between">
                <div className="mr-2">
                  <PeopleSelect
                    audienceFilters={audienceFilters}
                    searchQuery={searchQuery}
                    setPage={setPage}
                  />
                </div>
                <AudienceFilters
                  key={activeAppObject?.slug}
                  audienceFilters={audienceFilters}
                  setAudienceFilters={onSetAudienceFilters}
                  view={activeAppObject?.slug}
                />
              </div>
            </Flex>
          </Stack>
          <Flex w="full" maxW="full" flex="1">
            {activeAppObject?.objectType && error ? (
              <ErrorMessage />
            ) : (
              <>
                {activeAppObject && (
                  <ObjectList
                    appObject={activeAppObject}
                    listConfig={listConfig}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    entities={entities}
                    isLoading={isLoading}
                    pagy={pagy}
                    currentPage={currentPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    audienceFilters={audienceFilters}
                    skipPagination={skipPagination}
                    onSetAudienceFilters={onSetAudienceFilters}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                    maxHeight={"max-h-[81vh]"}
                    matchedCount={matchedCount}
                    totalCount={totalCount}
                    isLoadingCount={isLoadingCount}
                  />
                )}
              </>
            )}
          </Flex>
        </PageContainer>
      </div>
    </div>
  );
};
