import { EmojiPicker } from "@ferrucc-io/emoji-picker";

export interface IEmojiPickerContent {
  onSelect: (emoji: string) => void;
  onClose: () => void;
}

export const EmojiPickerContent: React.FC<IEmojiPickerContent> = ({
  onSelect,
  onClose,
}) => {
  return (
    <EmojiPicker
      onEmojiSelect={(emoji: string) => {
        onSelect(emoji);
        onClose();
      }}
    >
      <EmojiPicker.Header>
        <EmojiPicker.Input placeholder="Search emoji" autoFocus />
      </EmojiPicker.Header>
      <EmojiPicker.Group>
        <EmojiPicker.List />
      </EmojiPicker.Group>
    </EmojiPicker>
  );
};
