import { Input, InputGroup, Text } from "@chakra-ui/react";
import React from "react";

import { useReportSetup } from "core/hooks/useReportSetup";

export const OccurrencePicker: React.FC<{
  defaultOccurrences: number[][] | undefined;
}> = ({ defaultOccurrences }) => {
  const { currentReport, editOccurrences } = useReportSetup();
  const occurrences = currentReport?.config?.occurrences || defaultOccurrences;

  const handleChange = ({ target: { value, dataset } }: any) => {
    let newOccurrences = [...occurrences];
    let occurrence = [...newOccurrences[dataset.index]];
    occurrence[dataset.subindex] = value;
    newOccurrences[dataset.index] = occurrence;
    editOccurrences({ occurrences: newOccurrences });
  };

  return (
    <>
      {occurrences?.map((occurrence: number[], index: number) => {
        if (occurrence.length > 1) {
          return (
            <InputGroup
              key={index}
              size="sm"
              alignItems="center"
              width="240px"
              mb={1}
            >
              <Input
                data-index={index}
                data-subindex={0}
                value={occurrence[0]}
                onChange={handleChange}
                borderRadius="md"
              />
              <Text fontSize="sm" mx={2}>
                to
              </Text>
              <Input
                data-index={index}
                data-subindex={1}
                value={occurrence[1]}
                onChange={handleChange}
                borderRadius="md"
              />
              <Text fontSize="sm" mx={2}>
                times
              </Text>
            </InputGroup>
          );
        } else {
          return (
            <InputGroup
              key={index}
              size="sm"
              alignItems="center"
              width="240px"
              justifyContent="space-around"
            >
              <Text fontSize="sm" mr={2}>
                More than
              </Text>
              <Input
                data-index={index}
                borderRadius="md"
                data-subindex={0}
                width="112px"
                value={occurrence[0]}
                onChange={handleChange}
              />
              <Text fontSize="sm" mx={2}>
                times
              </Text>
            </InputGroup>
          );
        }
      })}
    </>
  );
};
