import { AnimatePresence } from "framer-motion";
import { ReactNode } from "react";

import { TextAlign } from "core/components/AI/Graph/DataTable/types";
import { Shadow } from "core/components/Table/People/Shadow";
import { cx } from "helpers/cx";

interface TableCellProps {
  children: ReactNode;
  isFirstColumn: boolean;
  showShadow: boolean;
  align: TextAlign;
  isHeader?: boolean;
  isLastRow?: boolean;
}

export const TableCell = ({
  children,
  isFirstColumn,
  showShadow,
  align,
  isHeader,
  isLastRow,
}: TableCellProps) => {
  const Tag = isHeader ? "th" : "td";

  return (
    <Tag
      className={cx(
        "relative whitespace-nowrap bg-white",
        isHeader
          ? "px-3 py-2 text-sm font-semibold text-gray-900"
          : "px-3 py-2",
        isFirstColumn && "sticky left-0 z-10",
        isFirstColumn &&
          showShadow &&
          "shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]",
        isLastRow && isFirstColumn && "rounded-bl-lg",
      )}
      style={{
        position: isFirstColumn ? "sticky" : undefined,
        left: isFirstColumn ? 0 : undefined,
      }}
    >
      {children}
      <AnimatePresence>
        {isFirstColumn && showShadow && (
          <Shadow height={isHeader ? "44" : "44"} />
        )}
      </AnimatePresence>
    </Tag>
  );
};
