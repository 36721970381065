import { Button, Spinner, UseDisclosureReturn } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import { Drilldown } from "core/components/Drilldown";
import { Error } from "core/components/Error";
import { List } from "core/components/List";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import {
  IRetentionInsightUser,
  listConfig,
} from "core/modules/reports/report-types/Retention/ChurningUsersContainer";
import { IReport } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

interface IChurningUsersListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  report: IReport;
  insight: IInsightSection;
  isUserReport: boolean;
  csvExportLoading: boolean;
  requestCsvExport: () => void;
}

export const ChurningUsersList: React.FC<IChurningUsersListProps> = ({
  report,
  insight,
  isUserReport,
  isOpen,
  onClose,
  csvExportLoading,
  requestCsvExport,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const { activeAppObject } = useAppObjects();
  const { contacts, isLoading, error, hasMoreContacts, loadMore } =
    useContactDrilldown({
      report,
      insightType: insight.typeId,
      skip: !appId || Number(appId) === 0 || !isOpen,
      insightParams: { limit: 18 },
    });
  const ChurningUsers = contacts as any as IRetentionInsightUser[];

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onClose} size="xl">
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title isLoading={isLoading} title={insight.title} />
            <Drilldown.DownloadButton
              isDisabled={isLoading || ChurningUsers?.length <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
          </Drilldown.Header>
          <Drilldown.Body isLoading={isLoading}>
            <List
              id="loaded"
              config={listConfig({
                previewMode: false,
                sharingMode: false,
                appObjectSingularName: activeAppObject?.singularName || "",
                isUserReport,
              })}
              rows={contacts}
              border={0}
              borderRadius={0}
              overflowY="scroll"
            />
            {hasMoreContacts && (
              <div className="flex w-full items-center justify-center">
                <Button isDisabled={isLoading} onClick={loadMore}>
                  {isLoading ? <Spinner /> : "Load more"}
                </Button>
              </div>
            )}
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
