import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import { EventBlock } from "core/components/EventBlock";
import { EventType } from "core/constants/report-setup";
import { IEventLog } from "core/types/EventLog";

const AnyEventNameCell = ({
  props: { event, properties },
}: {
  props: IEventLog;
}) => {
  if (event.type === EventType.PAGE) {
    const pageName = event.name || properties.path;
    return (
      <Flex minW="100px" align="center">
        <Flex align="flex-start" direction="column">
          <Tooltip label={pageName}>
            <Box
              px={2}
              py={1}
              maxWidth={220}
              bg="white"
              shadow="sm"
              borderRadius="sm"
              color="turquoise"
              fontWeight="medium"
              display="inline-flex"
            >
              <Text fontSize="xs" noOfLines={1} maxW="220px">
                {pageName}
              </Text>
            </Box>
          </Tooltip>
        </Flex>
      </Flex>
    );
  }

  return (
    <Link to={`/a/${event.appId}/event/${event.id}`}>
      <Flex minW="100px" align="center">
        <EventBlock fontSize="xs" name={event.name} tooltipText={event.name} />
      </Flex>
    </Link>
  );
};

export default AnyEventNameCell;
