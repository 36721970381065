import {
  Avatar,
  ComponentDefaultProps,
  Container,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";

import { ConversationBlock } from "@/core/components/AI/ConversationBlock";
import { Loader } from "@/core/components/AI/Messages/Loader";
import { PromptInput } from "@/core/components/AI/PromptInput";
import { CompanyJuneLogoSadIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoSadIcon";
import { CompanyJuneLogoTalkingIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoTalkingIcon";
import { CompanyJuneLogoThinkingIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoThinkingIcon";
import { useAI } from "core/hooks/useAI";
import { useUserAuth } from "core/hooks/useUserAuth";
import { From, IBlock, IConversationBlock } from "core/models/ai";

export interface IBlockComponent extends ComponentDefaultProps {
  delay: number;
  isReverse: boolean;
}

export const ConversationContainer: React.FC = () => {
  const {
    isVisible,
    conversationBlocks,
    togglePrompt,
    isExecutingPrompt,
    isExecutingQuery,
  } = useAI();
  const { currentUser } = useUserAuth();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  });

  return (
    <Modal
      size="4xl"
      isOpen={isVisible}
      onClose={() => togglePrompt(false)}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        id="modal-content"
        bg="rgb(255 255 255 / 99%)"
        borderRadius="xl"
      >
        <ModalCloseButton />
        <ModalBody p={0}>
          <Container
            ref={chatContainerRef}
            overflowY="scroll"
            maxW="850px"
            minH="80vh"
            pt={10}
            pb={5}
          >
            <Flex direction="column" gridGap={4}>
              {conversationBlocks.map(
                (conversationBlock: IConversationBlock) => (
                  <Flex
                    direction={
                      conversationBlock.from === From.June
                        ? "row"
                        : "row-reverse"
                    }
                  >
                    <ConversationBlock
                      isReverse={conversationBlock.from === From.User}
                      Avatar={
                        conversationBlock.from === From.June ? (
                          conversationBlock.hasError ? (
                            <CompanyJuneLogoSadIcon w="45px" h="45px" />
                          ) : (
                            <CompanyJuneLogoTalkingIcon w="45px" h="45px" />
                          )
                        ) : (
                          <Avatar
                            w="35px"
                            h="35px"
                            src={currentUser?.imageUrl}
                            name={currentUser?.firstName}
                          />
                        )
                      }
                    >
                      {conversationBlock.blocks.map(
                        (block: IBlock, index: number) => (
                          <block.Component
                            isReverse={conversationBlock.from === From.User}
                            delay={index + 1}
                            {...block.props}
                          >
                            {block.content}
                          </block.Component>
                        ),
                      )}
                    </ConversationBlock>
                  </Flex>
                ),
              )}
              {(isExecutingPrompt || isExecutingQuery) && (
                <Flex direction="row">
                  <ConversationBlock
                    delay={1}
                    isReverse={false}
                    Avatar={<CompanyJuneLogoThinkingIcon w="45px" h="45px" />}
                  >
                    <Loader isReverse={false} delay={2} />
                  </ConversationBlock>
                </Flex>
              )}
            </Flex>
          </Container>
        </ModalBody>
        <ModalFooter p={0}>
          <Container maxW="850px">
            <PromptInput />
          </Container>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
