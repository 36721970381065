import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
} from "@chakra-ui/react";

export const AxisSettings: React.FC<{
  axisLabel: string;
  axisKey: string;
  availableKeys: string[];
  setKey: (key: string) => void;
  onUpdateYAxis?: (key: string) => void;
  onUpdateXAxis?: (key: string) => void;
}> = ({
  axisLabel,
  axisKey,
  availableKeys,
  setKey,
  onUpdateYAxis,
  onUpdateXAxis,
}) => {
  return (
    <Flex align="center" gridGap={2}>
      <Flex>
        <Text color="black" fontSize="sm" fontWeight="semibold">
          {axisLabel}
        </Text>
      </Flex>
      <Flex>
        <Menu>
          <MenuButton
            bg="gray.200"
            _hover={{ bg: "gray.100" }}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            {axisKey ? axisKey : "Select a column"}
          </MenuButton>
          <MenuList>
            {availableKeys?.map((k: string) => (
              <MenuItem
                color="black"
                key={k}
                onClick={() => {
                  setKey(k);
                  if (onUpdateYAxis) onUpdateYAxis(k);
                  if (onUpdateXAxis) onUpdateXAxis(k);
                }}
              >
                {k}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};
