import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import moment from "moment";
import { useEffect, useState } from "react";

import {
  DEFAULT_EMAIL_VALUE,
  getContactEmail,
} from "core/helpers/contactEmail";
import {
  useGetContactsQuery,
  useLazyCreateContactQuery,
  useLazyUpdateContactQuery,
} from "core/models/hubspot";
import { IContact } from "core/types/Contact";

export interface IHSContact extends IContact {
  traits: { [key: string]: any };
}

export interface IHubspotContact {
  id: string;
  properties: { email: string };
}

interface IUseHubspotParams {
  appId: number | string;
  contact: IHSContact;
}

interface IHubspotResponse {
  contacts: IHubspotContact[];
  lastSyncedAt: string;
  createHubspotContact: () => void;
  updateHubspotContact: (contactId: string) => void;
  isLoadingContacts: boolean;
  isCreatedContactLoading: boolean;
  isUpdatedContactLoading: boolean;
  refetchContacts: () => void;
  fetchContactsError: FetchBaseQueryError | SerializedError | undefined;
  createContactError: FetchBaseQueryError | SerializedError | undefined;
  updateContactError: FetchBaseQueryError | SerializedError | undefined;
  canCreate: boolean;
}

export const useHubspotContact = ({
  appId,
  contact,
}: IUseHubspotParams): IHubspotResponse => {
  const email = getContactEmail(contact);
  const [contacts, setContacts] = useState<IHubspotContact[]>([]);
  const [lastSyncedAt, setLastSyncedAt] = useState<string>("");
  const {
    data: fetchContacts,
    isLoading: isLoadingContacts,
    refetch: refetchContacts,
    error: fetchContactsError,
  } = useGetContactsQuery({
    appId,
    email,
    userId: contact.id,
  });

  const [
    createContact,
    {
      data: createdContacts,
      isLoading: isCreatedContactLoading,
      error: createContactError,
    },
  ] = useLazyCreateContactQuery();

  const [
    updateContact,
    {
      data: updatedContacts,
      isLoading: isUpdatedContactLoading,
      error: updateContactError,
    },
  ] = useLazyUpdateContactQuery();

  useEffect(() => {
    if (fetchContacts?.contacts) {
      setContacts(fetchContacts.contacts);
      setLastSyncedAt(moment.utc(fetchContacts.lastSyncedAt).fromNow());
    }

    if (createdContacts?.contacts) {
      setContacts(createdContacts.contacts);
      setLastSyncedAt(moment.utc(createdContacts.lastSyncedAt).fromNow());
    }

    if (updatedContacts?.contacts) {
      setContacts(updatedContacts.contacts);
      setLastSyncedAt(moment.utc(updatedContacts.lastSyncedAt).fromNow());
    }
  }, [fetchContacts, createdContacts, updatedContacts]);

  function createHubspotContact() {
    createContact({ appId, id: contact.id, email });
  }

  function updateHubspotContact(contactId: string) {
    updateContact({ appId, userId: contact.id, contactId });
  }

  return {
    contacts,
    lastSyncedAt:
      (fetchContacts?.lastSyncedAt ||
        createdContacts?.lastSyncedAt ||
        updatedContacts?.lastSyncedAt) &&
      lastSyncedAt,
    isLoadingContacts,
    createHubspotContact,
    updateHubspotContact,
    isCreatedContactLoading,
    isUpdatedContactLoading,
    refetchContacts,
    fetchContactsError,
    createContactError,
    updateContactError,
    canCreate: email !== DEFAULT_EMAIL_VALUE && Boolean(email),
  };
};
