import { Image } from "@chakra-ui/react";
import React from "react";

import { IApp } from "core/types/App";

export const AppLogo: React.FC<{ currentApp: IApp }> = ({ currentApp }) => {
  if (currentApp.logo) {
    return (
      <Image
        alt="app logo"
        src={currentApp?.logo}
        h="24px"
        w="24px"
        ml={3}
        mr={1}
        borderRadius="md"
        borderWidth="2px"
        borderColor="gray.200"
        flexShrink={0}
      />
    );
  }

  return (
    <div className="ml-3 mr-1 h-[24px] w-[24px] flex-shrink-0 rounded-md border-gray-400 bg-purple-500 font-medium text-white">
      {currentApp?.name?.[0]}
    </div>
  );
};

export default AppLogo;
