import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import type { SnakeCasedPropertiesDeep } from "type-fest";

import { baseQuery } from "core/initializers/rtk";
import { IAudienceFilters } from "core/types/Audience";
import { IGroup } from "core/types/Group";
import { IPagy } from "core/types/Pagy";
import { transformFilterGroups } from "helpers/params";

interface IGetCompaniesArgs {
  appId: number;
  page: number;
  audienceFilters: IAudienceFilters;
  searchQuery: string;
  skipPagination?: boolean;
  sortBy?: string;
  sortOrder?: string;
}

export interface IGetCompaniesResponse {
  companies: IGroup[];
  pagy: IPagy;
  totalCount: number;
}
interface IGetCompanyProfileArgs {
  appId: number;
  id: string;
}

interface IDeleteCompanyArgs {
  appId: number;
  id: string;
}

interface IMergeCompanyArgs {
  appId: number;
  id: string;
  sourceGroupId: string;
}

export const companiesApi = createApi({
  baseQuery,
  reducerPath: "companiesApi",
  tagTypes: ["Company"],
  endpoints: (builder) => ({
    getCompanies: builder.query<IGetCompaniesResponse, IGetCompaniesArgs>({
      query: ({
        appId,
        page,
        audienceFilters,
        searchQuery,
        skipPagination,
        sortBy,
        sortOrder,
      }) => ({
        url: `/companies`,
        params: humps.decamelizeKeys({
          appId,
          page: page,
          filterGroups: JSON.stringify(
            transformFilterGroups(audienceFilters.filterGroups),
          ),
          joinOperator: audienceFilters.joinOperator,
          query: searchQuery,
          skip_pagination: skipPagination,
          sort_by: sortBy,
          sort_order: sortOrder,
        }),
      }),
      providesTags: ["Company"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetCompaniesResponse>,
      ): IGetCompaniesResponse => {
        const camelizedResponse = humps.camelizeKeys(
          response,
        ) as IGetCompaniesResponse;

        camelizedResponse.companies = camelizedResponse.companies.map(
          (group) => {
            const { traits, ...rest } = group;
            return {
              ...rest,
              traits:
                response.companies.find((c) => c.id === group.id)?.traits ||
                traits,
            };
          },
        );

        return camelizedResponse;
      },
    }),
    getCompanyProfile: builder.query<IGroup, IGetCompanyProfileArgs>({
      query: ({ appId, id }) => ({
        url: `/companies/${id}/profile`,
        params: {
          app_id: appId,
        },
      }),
      providesTags: ["Company"],
      transformResponse: (response: SnakeCasedPropertiesDeep<IGroup>): IGroup =>
        humps.camelizeKeys(response) as IGroup,
    }),
    deleteCompany: builder.mutation<IGroup, IDeleteCompanyArgs>({
      query: ({ appId, id }) => ({
        url: `/companies/${id}`,
        method: "DELETE",
        params: humps.decamelizeKeys({ appId }),
      }),
      invalidatesTags: ["Company"],
      transformResponse: (response: SnakeCasedPropertiesDeep<IGroup>): IGroup =>
        humps.camelizeKeys(response) as IGroup,
    }),
    mergeCompany: builder.mutation<IGroup, IMergeCompanyArgs>({
      query: ({ appId, id, sourceGroupId }) => ({
        url: `/companies/${id}/merge`,
        method: "POST",
        params: humps.decamelizeKeys({ appId, sourceGroupId }),
      }),
      invalidatesTags: ["Company"],
      transformResponse: (response: SnakeCasedPropertiesDeep<IGroup>): IGroup =>
        humps.camelizeKeys(response) as IGroup,
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useGetCompanyProfileQuery,
  useLazyGetCompanyProfileQuery,
  useDeleteCompanyMutation,
  useMergeCompanyMutation,
} = companiesApi;
