import { IntervalType } from "core/types/Filters";
import { ITimerangeOption } from "core/types/TimerangeOption";

export const RELATIVE = 0;
export const ABSOLUTE = 1;
export const INTERVAL = 2;

export const CUSTOM_DATE: ITimerangeOption = {
  value: "fixed",
  label: "Custom",
  short: "Custom",
  icon: "pen",
  type: ABSOLUTE,
};

export const TODAY: ITimerangeOption = {
  value: "ONE_DAY",
  label: "Today",
  short: "24h",
  type: RELATIVE,
  icon: "calendar",
  count: 1,
};

export const ONE_DAY: ITimerangeOption = {
  short: "24h",
  value: "ONE_DAY",
  label: "24 hours",
  type: RELATIVE,
  icon: "calendar",
  timerangeValue: 86400,
};

export const SEVEN_DAYS: ITimerangeOption = {
  value: "SEVEN_DAYS",
  label: "7 days",
  short: "7d",
  icon: "calendar",
  count: 7,
  type: RELATIVE,
  timerangeValue: 604800,
};

export const FOURTEEN_DAYS: ITimerangeOption = {
  value: "FOURTEEN_DAYS",
  label: "14 days",
  short: "14d",
  icon: "calendar",
  count: 14,
  type: RELATIVE,
  timerangeValue: 1209600,
};

export const THIRTY_DAYS: ITimerangeOption = {
  value: "THIRTY_DAYS",
  label: "30 days",
  short: "30d",
  icon: "calendar",
  count: 30,
  type: RELATIVE,
  timerangeValue: 2592000,
};

export const NINETY_DAYS: ITimerangeOption = {
  value: "THREE_MONTHS",
  label: "3 months",
  short: "3m",
  icon: "calendar",
  count: 90,
  type: RELATIVE,
  timerangeValue: 7776000,
};

export const SIX_MONTHS: ITimerangeOption = {
  value: "SIX_MONTHS",
  label: "6 months",
  short: "6m",
  icon: "calendar",
  type: RELATIVE,
  count: 180,
  timerangeValue: 15552000,
};

export const ONE_YEAR: ITimerangeOption = {
  value: "TWELVE_MONTHS",
  label: "12 months",
  short: "1y",
  icon: "calendar",
  type: RELATIVE,
  count: 365,
  timerangeValue: 31536000,
};

export const FIVE_YEARS: ITimerangeOption = {
  value: "FIVE_YEARS",
  label: "5 years",
  short: "5y",
  icon: "calendar",
  type: RELATIVE,
  count: 1825,
  timerangeValue: 157680000,
};

export const POWER_USER_ONE_DAY: ITimerangeOption = {
  value: "1",
  label: "1 day or more",
  short: "1d",
  icon: "calendar",
  type: RELATIVE,
};

export const POWER_USER_TWO_DAYS: ITimerangeOption = {
  value: "2",
  label: "2 days or more",
  short: "2d",
  icon: "calendar",
  type: RELATIVE,
};

export const POWER_USER_THREE_DAYS: ITimerangeOption = {
  value: "3",
  label: "3 days or more",
  short: "3d",
  icon: "calendar",
  type: RELATIVE,
};

export const POWER_USER_FOUR_DAYS: ITimerangeOption = {
  value: "4",
  label: "4 days or more",
  short: "4d",
  icon: "calendar",
  type: RELATIVE,
};

export const POWER_USER_FIVE_DAYS: ITimerangeOption = {
  value: "5",
  label: "5 days or more",
  short: "5d",
  icon: "calendar",
  type: RELATIVE,
};

export const RELATIVE_OPTIONS = [
  ONE_DAY,
  SEVEN_DAYS,
  FOURTEEN_DAYS,
  THIRTY_DAYS,
  NINETY_DAYS,
  SIX_MONTHS,
  ONE_YEAR,
];

export const ABSOLUTE_OPTIONS = [CUSTOM_DATE];

export enum RELATIVE_TIMERANGE_VALUE_FROM_KEYS {
  ONE_DAY = 86400,
  SEVEN_DAYS = 604800,
  FOURTEEN_DAYS = 1209600,
  THIRTY_DAYS = 2592000,
  FOUR_WEEKS = 2419200,
  EIGHT_WEEKS = 4838400,
  TWELVE_WEEKS = 7257600,
  THREE_MONTHS = 7776000,
  SIX_MONTHS = 15552000,
  TWELVE_MONTHS = 31536000,
  FIVE_YEARS = 157680000,
}

export const DATE_RANGE_OPTIONS_FROM_VALUE = {
  86400: ONE_DAY,
  604800: SEVEN_DAYS,
  1209600: FOURTEEN_DAYS,
  2592000: THIRTY_DAYS,
  7776000: NINETY_DAYS,
  15552000: SIX_MONTHS,
  31536000: ONE_YEAR,
  157680000: FIVE_YEARS,
} as { [key: number]: ITimerangeOption };

export const DEFAULT_GROUPED_OPTIONS = [
  {
    label: "Dynamic",
    options: RELATIVE_OPTIONS,
  },
  {
    label: "Absolute",
    options: ABSOLUTE_OPTIONS,
  },
];

export const DAU_OPTIONS: ITimerangeOption[] = [
  TODAY,
  SEVEN_DAYS,
  FOURTEEN_DAYS,
  THIRTY_DAYS,
  NINETY_DAYS,
  SIX_MONTHS,
  ONE_YEAR,
  FIVE_YEARS,
];
export const DAU_GROUPED_OPTIONS = [
  {
    label: "Dynamic",
    options: DAU_OPTIONS,
  },
];
export const WAU_OPTIONS = [
  FOURTEEN_DAYS,
  THIRTY_DAYS,
  NINETY_DAYS,
  SIX_MONTHS,
  ONE_YEAR,
  FIVE_YEARS,
];
export const WAU_GROUPED_OPTIONS = [
  {
    label: "Dynamic",
    options: WAU_OPTIONS,
  },
];
export const MAU_OPTIONS = [NINETY_DAYS, SIX_MONTHS, ONE_YEAR, FIVE_YEARS];
export const MAU_GROUPED_OPTIONS = [
  {
    label: "Dynamic",
    options: MAU_OPTIONS,
  },
];
export const MOST_ACTIVE_USERS_OPTIONS = [
  TODAY,
  SEVEN_DAYS,
  FOURTEEN_DAYS,
  THIRTY_DAYS,
  NINETY_DAYS,
  SIX_MONTHS,
  ONE_YEAR,
];
export const MOST_ACTIVE_USERS_GROUPED_OPTIONS = [
  {
    label: "Dynamic",
    options: MOST_ACTIVE_USERS_OPTIONS,
  },
];

export const POWER_USER_OPTIONS: ITimerangeOption[] = [
  POWER_USER_ONE_DAY,
  POWER_USER_TWO_DAYS,
  POWER_USER_THREE_DAYS,
  POWER_USER_FOUR_DAYS,
  POWER_USER_FIVE_DAYS,
];

export const SLIPPING_AWAY_OPTIONS: ITimerangeOption[] = [
  SEVEN_DAYS,
  FOURTEEN_DAYS,
  THIRTY_DAYS,
  NINETY_DAYS,
];
export const SLIPPING_AWAY_GROUPED_OPTIONS = [
  {
    label: "Dynamic",
    options: SLIPPING_AWAY_OPTIONS,
  },
];

export const DAY = 0;
export const WEEK = 1;
export const MONTH = 2;
export const HOUR = 3;
export const MINUTE = 4;
export const SECOND = 5;

export const INTERVAL_TYPES = {
  DAY: DAY,
  WEEK: WEEK,
  MONTH: MONTH,
} as { [key: string]: IntervalType };

export enum IntervalTypeNames {
  SECOND = "second",
  MINUTE = "minute",
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export const INTERVAL_TYPE_NAMES: Record<number, string> = {
  [DAY]: IntervalTypeNames.DAY,
  [WEEK]: IntervalTypeNames.WEEK,
  [MONTH]: IntervalTypeNames.MONTH,
};

export const CONVERSION_WINDOW_INTERVAL_TYPE_NAMES: Record<number, string> = {
  [SECOND]: IntervalTypeNames.SECOND,
  [MINUTE]: IntervalTypeNames.MINUTE,
  [HOUR]: IntervalTypeNames.HOUR,
  [DAY]: IntervalTypeNames.DAY,
  [WEEK]: IntervalTypeNames.WEEK,
  [MONTH]: IntervalTypeNames.MONTH,
};

export const INTERVAL_TYPE_NUMBERS: Record<string, number> = {
  [IntervalTypeNames.DAY]: DAY,
  [IntervalTypeNames.WEEK]: WEEK,
  [IntervalTypeNames.MONTH]: MONTH,
};

export const CONVERSION_WINDOW_INTERVAL_TYPE_NUMBERS: Record<string, number> = {
  [IntervalTypeNames.SECOND]: SECOND,
  [IntervalTypeNames.MINUTE]: MINUTE,
  [IntervalTypeNames.HOUR]: HOUR,
  [IntervalTypeNames.DAY]: DAY,
  [IntervalTypeNames.WEEK]: WEEK,
  [IntervalTypeNames.MONTH]: MONTH,
};

export const getTimeRangeFromValue = (
  value: number | string,
): ITimerangeOption => {
  return DATE_RANGE_OPTIONS_FROM_VALUE[
    value as keyof typeof DATE_RANGE_OPTIONS_FROM_VALUE
  ];
};
