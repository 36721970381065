import { ViewIcon } from "@chakra-ui/icons";
import { Flex, Link, StyleProps, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";

import EventAlertUseCaseModal from "core/components/Slack/EventAlertUseCaseModal";
import OpenBookIcon from "core/design-system/components/Icon/Interface/OpenBookIcon";

interface IHeaderProps extends StyleProps {
  title?: string | React.ReactNode;
  description?: string;
  getStartedText?: string;
  getStartedLink?: string;
  howToSetupText?: string;
  howToSetupLink?: string;
  useCases?: boolean;
}

export const Header = ({
  title = "Setup Analysis",
  ...props
}: IHeaderProps) => {
  const {
    description,
    getStartedText,
    getStartedLink,
    howToSetupText,
    howToSetupLink,
    useCases,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        direction="column"
        px={8}
        py={4}
        justifyContent="space-between"
        alignItems="flex-start"
        mb={4}
        {...props}
      >
        <Text mt={-1} fontSize="lg" fontWeight="bold" color="gray.800">
          {title}
        </Text>
        <Flex>
          {getStartedLink && (
            <Flex alignItems="center">
              <OpenBookIcon />
              <Text ml={1} fontWeight="bold" color="purple.500">
                <Link href={getStartedLink} isExternal>
                  {getStartedText}
                </Link>
              </Text>
            </Flex>
          )}
          {howToSetupText && (
            <Flex fontSize="sm" alignItems="center">
              <OpenBookIcon color="purple.500" />
              <Text ml={1} fontWeight="medium" color="purple.500">
                <Link href={howToSetupLink} isExternal>
                  {howToSetupText}
                </Link>
              </Text>
            </Flex>
          )}
          {useCases && (
            <Flex alignItems="center">
              <ViewIcon
                ml={4}
                fontSize="sm"
                fontWeight="medium"
                color="purple.500"
              />
              <Text
                onClick={onOpen}
                as="button"
                ml={1}
                fontSize="sm"
                fontWeight="medium"
                color="purple.500"
                _hover={{ textDecoration: "underline" }}
              >
                See them in action
              </Text>
              <EventAlertUseCaseModal isOpen={isOpen} onClose={onClose} />
            </Flex>
          )}
        </Flex>
      </Flex>
      {description ? (
        <Flex px={8} py={1}>
          <Text color="gray.600">{description}</Text>
        </Flex>
      ) : undefined}
    </>
  );
};
