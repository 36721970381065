import { Flex } from "@chakra-ui/react";
import { useState } from "react";

import {
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { Line, LineChart } from "core/design-system/charts/LineChart";
import TickText from "core/design-system/charts/TickText";
import { CustomTooltip } from "core/modules/reports/report-types/Explorer/CustomTooltip";
import { ExplorationLegend } from "core/modules/reports/report-types/Explorer/ExplorationLegend";
import { Size } from "core/types/ViewInsight";

export interface ISerie {
  color: string;
  data: { x: string; y: number; eventProperties?: string[] }[];
  max: number;
  mean: number;
  min: number;
  name: string;
  p90: number;
  total: number;
  uuid: string;
}

interface IExplorationGraph {
  series: ISerie[];
  showYAxis: boolean;
}

export const ExplorationGraph: React.FC<IExplorationGraph> = ({
  series,
  showYAxis,
}) => {
  const [hoveringIndex, setHoveringIndex] = useState<string | undefined>(
    undefined,
  );

  if (!series || series.length < 1) {
    return null;
  }

  return (
    <Flex direction="column" justify="center" h={350}>
      <LineChart
        h="sm"
        w="100%"
        margin={{ top: 30, left: showYAxis ? 0 : -30, right: 60, bottom: 20 }}
      >
        <CommonGrid />
        {/* @ts-ignore */}
        <CommonTooltip content={<CustomTooltip />} />
        {/* @ts-ignore */}
        <CommonXAxis
          type="category"
          allowDuplicatedCategory={false}
          dataKey="x"
        />
        {/* @ts-ignore */}
        <CommonYAxis
          // @ts-ignore
          tick={({ x, ...props }) =>
            showYAxis && (
              // @ts-ignore
              <TickText x={x - 5} {...props}>
                {props.payload.value}
              </TickText>
            )
          }
        />
        {series.map((serie, idx) => (
          <Line
            key={idx}
            dot={false}
            dataKey="y"
            data={serie.data}
            name={serie.name}
            stroke={serie.color}
            strokeWidth={2}
            strokeOpacity={
              hoveringIndex !== undefined
                ? String(serie.uuid) === String(hoveringIndex)
                  ? 1
                  : 0.2
                : 1
            }
            isAnimationActive={false}
          />
        ))}
      </LineChart>
      <div className="mb-5 max-w-full overflow-x-scroll">
        <ExplorationLegend
          series={series}
          size={Size.Large}
          onHover={setHoveringIndex}
        />
      </div>
    </Flex>
  );
};
