import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ComponentDefaultProps,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";

import { Icon } from "core/design-system/components/Icon";
import CopyIcon from "core/design-system/components/Icon/Interface/CopyIcon";
import { renderToast } from "core/helpers/toast";
import {
  useDeleteReportMutation,
  useDuplicateReportMutation,
} from "core/models/reports";
import { IReport } from "core/types/Report";
import NameEditorModal from "modules/Reports/List/NameEditorModal";

export type IReportAction = "delete" | "duplicate" | "rename";

interface IReportActionsMenuProps extends ComponentDefaultProps {
  report: IReport;
  actions?: IReportAction[];
  onAfterDelete?: () => void;
  onAfterDuplicate?: (duplicatedReportId: number) => void;
  iconProps?: ComponentDefaultProps;
}

interface IDeleteDialogProps {
  report: IReport;
  isOpen: boolean;
  cancelRef: React.RefObject<any>;
  onClose: () => void;
  onDeleteReport: () => void;
}

const DeleteDialog: React.FC<IDeleteDialogProps> = ({
  report,
  isOpen,
  onClose,
  cancelRef,
  onDeleteReport,
}) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete {report.name}
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={onDeleteReport} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export const ReportActionsMenu: React.FC<IReportActionsMenuProps> = ({
  report,
  actions = ["delete", "duplicate", "rename"],
  onAfterDelete,
  onAfterDuplicate,
  iconProps,
  ...styleProps
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [renameModal, showRenameModal] = useState(false);
  const [duplicateReport] = useDuplicateReportMutation();
  const [deleteReport] = useDeleteReportMutation();

  const onDeleteReport = async () => {
    if (report.appId && report.id) {
      await deleteReport({ appId: report.appId, reportId: report.id })
        .unwrap()
        .then((data) => {
          renderToast({ action: "delete", status: "success" });
          if (onAfterDelete) {
            onAfterDelete();
          }
        })
        .catch(() => renderToast({ action: "delete", status: "error" }));
      onClose();
    }
  };

  const onDuplicateReport = async () => {
    if (report.appId && report.id) {
      duplicateReport({
        appId: report.appId,
        reportId: report.id,
      })
        .unwrap()
        .then((data) => {
          renderToast({ action: "duplicate", status: "success" });
          if (onAfterDuplicate && data) {
            onAfterDuplicate(data.id);
          }
        })
        .catch(() => renderToast({ action: "duplicate", status: "error" }));
    }
  };

  return (
    <>
      <Menu>
        <MenuButton as={Button} variant="ghost" ml={6} {...styleProps}>
          <Icon
            iconType="icon"
            name="more"
            {...iconProps}
            _hover={{ color: "gray.800" }}
          />
        </MenuButton>
        <MenuList boxShadow="md" minWidth="140px">
          {actions.includes("rename") && (
            <>
              <NameEditorModal
                isOpen={renameModal}
                toggle={() => showRenameModal(false)}
                report={report}
              />
              <MenuItem
                onClick={() => {
                  showRenameModal(!renameModal);
                }}
              >
                <Flex justify="flex-start" alignItems="center">
                  <Icon
                    w={3.5}
                    iconType="icon"
                    name="edit"
                    mr={2}
                    color="gray.600"
                  />
                  <Text>Rename</Text>
                </Flex>
              </MenuItem>
            </>
          )}

          {actions.includes("duplicate") && (
            <MenuItem onClick={onDuplicateReport}>
              <Flex justify="flex-start" alignItems="center">
                <CopyIcon w="18px" color="gray.600" mr={1} fontSize="xl" />
                <Text>Duplicate</Text>
              </Flex>
            </MenuItem>
          )}

          {actions.includes("delete") && (
            <>
              <MenuItem onClick={onOpen}>
                <Flex justify="flex-start" alignItems="center">
                  <Icon
                    w={3.5}
                    iconType="icon"
                    name="delete"
                    mr={2}
                    color="gray.600"
                  />
                  <Text>Delete</Text>
                </Flex>
              </MenuItem>
              <DeleteDialog
                onDeleteReport={onDeleteReport}
                report={report}
                isOpen={isOpen}
                onClose={onClose}
                cancelRef={cancelRef}
              />
            </>
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export default ReportActionsMenu;
