import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/16/solid";
import { CellContext } from "@tanstack/react-table";
import moment from "moment-timezone";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { Prefix } from "core/components/Table/People/Column/Prefix";
import { Suffix } from "core/components/Table/People/Column/Suffix";
import { ChangeIcon } from "core/components/Traits/Trait";
import { ComputationType } from "core/constants/traits";
import { useTimeZone } from "core/hooks/useCurrentApp";
import { IContact } from "core/types/Contact";
import { IGroup } from "core/types/Group";
import { ITableTraitConfig } from "core/types/TableTraitConfig";
import { UserCombobox } from "core/UserCombobox";

const isNumber = (value: string) => !isNaN(Number(value));

export const CellWithTimezone: React.FC<{
  info: CellContext<IContact | IGroup, unknown>;
  traitConfig: ITableTraitConfig;
}> = ({ info, traitConfig }) => {
  const timeZone = useTimeZone();
  const value = (info.getValue() || "") as string;
  const domainPattern = /^(https?:\/\/)?[\w.-]+\.[a-z]{2,}(\/\S*)?$/i;

  if (domainPattern.test(value)) {
    const url = value.startsWith("http") ? value : `https://${value}`;
    return (
      <a
        className="text-sm underline"
        target="_blank"
        href={url}
        rel="noreferrer"
      >
        <Prefix
          prefix={traitConfig?.computedTrait?.prefix}
          hasValue={!!value}
        />
        {value}
        <Suffix
          suffix={traitConfig?.computedTrait?.suffix}
          hasValue={!!value}
        />
      </a>
    );
  } else if (traitConfig.computedTrait?.dataType === "workspace_member") {
    return <UserCombobox value={value} onValueChange={() => {}} disabled />;
  } else if (value === "true") {
    return <CheckCircleIcon className="h-4 w-4 text-green-400" />;
  } else if (value === "false") {
    return <XCircleIcon className="h-4 w-4 text-red-400" />;
  } else if (
    traitConfig?.computedTrait?.config?.computationType ===
    ComputationType.EventCountChange
  ) {
    return (
      <div className="flex items-center gap-1">
        <ChangeIcon value={value} />
        {Math.abs(Number(value)).toString()}%
      </div>
    );
  } else if (
    traitConfig?.computedTrait?.config?.computationType ===
    ComputationType.Ratio
  ) {
    return (
      <div className="flex items-center">
        {Math.abs(Number(value)).toString()}
        {traitConfig.computedTrait.config.isPercentage && "%"}
      </div>
    );
  } else if (
    traitConfig?.computedTrait?.config?.computationType ===
    ComputationType.UserCount
  ) {
    return (
      <div className="flex items-center gap-1">
        {(value || "0").toLocaleLowerCase()} user{Number(value) > 1 ? "s" : ""}
      </div>
    );
  } else if (
    traitConfig?.computedTrait?.config?.computationType ===
    ComputationType.DayCount
  ) {
    return (
      <div className="flex items-center gap-1">
        {(value || "0").toLocaleLowerCase()} day{Number(value) > 1 ? "s" : ""}
      </div>
    );
  } else if (
    traitConfig?.computedTrait?.config?.computationType ===
      ComputationType.FirstSeen ||
    traitConfig?.computedTrait?.config?.computationType ===
      ComputationType.LastSeen
  ) {
    if (moment(value).isValid()) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div className="flex cursor-pointer items-center gap-1">
                {moment.utc(value).tz(timeZone).fromNow()}
              </div>
            </TooltipTrigger>
            <TooltipContent>
              {moment.utc(value).tz(timeZone).format("MMM DD, YYYY HH:mm:ss z")}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    } else {
      return <>-</>;
    }
  } else if (value && isNumber(value)) {
    return (
      <p>
        <Prefix
          prefix={traitConfig?.computedTrait?.prefix}
          hasValue={!!value}
        />
        {Number(value).toLocaleString()}
        <Suffix
          suffix={traitConfig?.computedTrait?.suffix}
          hasValue={!!value}
        />
      </p>
    );
  }
  return (
    <>
      <Prefix prefix={traitConfig?.computedTrait?.prefix} hasValue={!!value} />
      {value}
      <Suffix suffix={traitConfig?.computedTrait?.suffix} hasValue={!!value} />
    </>
  );
};
