import { useState } from "react";

import { TimerangePicker } from "core/components/SmartTrait/TimerangePicker";
import { TimerangeType } from "core/constants/report-setup";
import { ComputationType } from "core/constants/traits";
import { EventFilter } from "core/modules/reports/setup/Audience/EventFilter";
import { IFilter } from "core/types/Filters.d";
import { ITraitConfig, ITraitWithConfig } from "core/types/Trait";

export const Timerange: React.FC<{
  trait: ITraitWithConfig;
  onChangeTimerange: (
    timerange: Pick<
      ITraitConfig,
      "timeRangeType" | "intervalValue" | "intervalType"
    >,
  ) => void;
  onChangeStartingEvent: (event: IFilter) => void;
  isPaywalled?: boolean;
}> = ({
  trait,
  onChangeTimerange,
  onChangeStartingEvent,
  isPaywalled = false,
}) => {
  const [timerangeType, setTimerangeType] = useState(
    trait.config.timeRangeType,
  );

  return (
    <div className="flex gap-2">
      <TimerangePicker
        key={trait.config.computationType}
        timerangeType={timerangeType}
        setTimerangeType={setTimerangeType}
        onChange={(val) =>
          onChangeTimerange({
            timeRangeType: val.type,
            intervalValue: val.interval,
            intervalType: val.intervalType,
          })
        }
        value={{
          type: trait.config.timeRangeType,
          interval: trait.config.intervalValue,
          intervalType: trait.config.intervalType,
        }}
        isAllTimeDisabled={
          trait.config.computationType === ComputationType.EventCountChange
        }
        isRelativeFirstDisabled={
          trait.config.computationType !== ComputationType.Boolean
        }
        isPaywalled={isPaywalled}
      />
      {timerangeType === TimerangeType.RELATIVE_FIRST && (
        <div className="flex items-center gap-2">
          <p className="text-sm">since</p>
          <EventFilter
            filter={trait.config.startingEvent}
            filterIndex={0}
            onSaveFilter={(newFilter) => onChangeStartingEvent(newFilter)}
            enableTimerange={false}
            enableLevelPicker={false}
            enableEventProperties={true}
            enableEventOccurrence={false}
            canRemove={false}
            hasSmartEvents={false}
            showDownArrow={false}
            textProps="font-medium"
          />
        </div>
      )}
    </div>
  );
};
