import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import { SnakeCasedProperties } from "type-fest";

import { baseQuery } from "core/initializers/rtk";
import { Plan } from "core/types/App";

export const paymentApi = createApi({
  baseQuery,
  reducerPath: "paymentApi",
  tagTypes: ["Payment"],
  endpoints: (builder) => ({
    fetchCheckout: builder.query({
      query: ({
        appId,
        redirectUrl,
        plan,
      }: {
        appId: number;
        redirectUrl: string;
        plan: Plan;
      }) => ({
        url: "/payments/checkout",
        params: humps.decamelizeKeys({ appId, redirectUrl, plan }),
      }),
      transformResponse: (
        response: SnakeCasedProperties<{ sessionUrl: string }>,
      ): { sessionUrl: string } =>
        humps.camelizeKeys(response) as { sessionUrl: string },
    }),
    fetchBillingPortal: builder.query({
      query: (appId: number) => ({
        url: "/payments/portal",
        params: humps.decamelizeKeys({ appId }),
      }),
      transformResponse: (
        response: SnakeCasedProperties<{ sessionUrl: string }>,
      ): { sessionUrl: string } =>
        humps.camelizeKeys(response) as { sessionUrl: string },
    }),
  }),
});

export const {
  useLazyFetchCheckoutQuery,
  useFetchBillingPortalQuery,
  useLazyFetchBillingPortalQuery,
} = paymentApi;
