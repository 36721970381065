import { Box, StyleProps, Text } from "@chakra-ui/react";
import React from "react";

export const Error: React.FC<StyleProps> = (styleProps) => {
  return (
    <Box position="absolute" w="100vw" h="100vh" p={32} {...styleProps}>
      <Text fontSize="2xl" fontWeight="bold">
        There was an error on our side
      </Text>
      <Text>We just got notified and will be working on a fix</Text>
    </Box>
  );
};

export default Error;
