import { Button, Tooltip } from "@chakra-ui/react";
import { Navigate, NavLink } from "react-router-dom";

import { IListConfig } from "core/components/List/List.types";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { TraitLevelCell } from "core/components/SmartTrait/TraitLevelCell";
import { TraitNameCell } from "core/components/SmartTrait/TraitNameCell";
import { TraitsList } from "core/components/SmartTrait/TraitsList";
import { TraitStatusCell } from "core/components/SmartTrait/TraitStatusCell";
import {
  InfoBody,
  InfoCard,
  InfoHeader,
  InfoReadMoreLink,
} from "core/design-system/components/InfoCard";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetComputedTraitsQuery } from "core/models/computedTraits";
import { Plan } from "core/types/App";
import * as Settings from "modules/Settings/Integrations/Integration";

export const config: IListConfig = {
  resourceName: "computed traits",
  columns: [
    {
      title: "Trait",
      name: "trait",
      align: "left",
      Component: TraitNameCell,
    },
    {
      title: "Type",
      name: "type",
      align: "left",
      width: 200,
      Component: TraitLevelCell,
    },
    {
      title: "Status",
      name: "status",
      align: "left",
      width: 200,
      Component: TraitStatusCell,
    },
  ],
};

export const ComputedTraits: React.FC = () => {
  const app = useCurrentApp();
  const { data, isFetching } = useGetComputedTraitsQuery({ appId: app.id });
  const activeSmartTraits =
    data?.filter((trait) => trait.isComputed && !trait.isArchived) || [];
  const isDisabled =
    activeSmartTraits.length >= app.computedTraitLimit || isFetching;

  if (window.location.pathname.includes("settings/computed-traits")) {
    return <Navigate to={`/a/${app.id}/integrations/computed-traits`} />;
  }

  return (
    <Settings.Container>
      <Settings.Header
        metadata={{
          title: "Computed Traits",
          description: "Create and update traits automatically based on events",
          docsUrl: "https://help.june.so/en/articles/8394436-smart-traits",
        }}
        ActionComponent={() => (
          <Tooltip
            hasArrow
            shouldWrapChildren
            label={
              isDisabled
                ? `You have reached the number of computed traits. Reach out to sales to get more`
                : ""
            }
          >
            <PaywallPopover
              feature="computed traits"
              plan={Plan.Pro}
              redirect={`settings/computed-traits`}
            >
              <Button
                as={NavLink}
                to={`/a/${app.id}/settings/computed-traits/new`}
                colorScheme={"purple"}
                onClick={(e) => (isDisabled ? e.preventDefault() : null)}
                isDisabled={isDisabled}
              >
                New trait
              </Button>
            </PaywallPopover>
          </Tooltip>
        )}
      />
      <Settings.Section h="100vh">
        <div className="flex h-full flex-col">
          <div className="mt-6">
            <InfoCard>
              <InfoHeader>What is a computed trait?</InfoHeader>
              <InfoBody>
                Computed traits are traits that are computed based on events.
                They are computed daily and can be incredibly useful to
                understand product usage across users and companies, both in
                June and in your{" "}
                <p className="inline font-semibold text-black">CRM</p>.
              </InfoBody>
              <InfoReadMoreLink
                href="https://help.june.so/en/articles/8394436-computed-traits"
                articleId="8394436"
              />
            </InfoCard>
          </div>
          <div className="flex">
            {!isFetching && (
              <TraitsList
                traits={activeSmartTraits}
                isLoading={isFetching}
                mt={6}
              />
            )}
          </div>
        </div>
      </Settings.Section>
    </Settings.Container>
  );
};
