import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { IUseCompanyProps, useCompany } from "core/hooks/useCompany";
import { useGroup } from "core/hooks/useGroup";
import { IGroup } from "core/types/Group";

export interface IUseGroupBanner {
  groupId: string | null;
  groupType: string | null;
}

export const useGroupBanner = ({ groupId, groupType }: IUseGroupBanner) => {
  const { appId } = useParams();
  const [entity, setEntity] = useState<IGroup | IUseCompanyProps | null>(null);
  const { fetchGroup, group } = useGroup({
    id: groupId || "",
  });

  const { getCompanyProfile, company } = useCompany({
    appId: Number(appId),
    id: groupId || "",
  });

  useEffect(() => {
    if (groupType === "company") {
      getCompanyProfile({ id: groupId || "", appId: Number(appId) });
    }

    if (groupType === "group") {
      fetchGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, fetchGroup, groupType]);

  useEffect(() => {
    if (company) {
      setEntity(company);
    }

    if (group) {
      setEntity(group);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, company]);

  return {
    entity,
  };
};
