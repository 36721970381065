import {
  Badge,
  ComponentDefaultProps,
  Flex,
  Skeleton,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { ContactBody } from "core/components/Contact/ContactBody";
import { Icon } from "core/design-system/components/Icon";
import { useContact } from "core/hooks/useContact";

interface IContactWithDataProps extends ComponentDefaultProps {
  id: string;
  isNewUser?: string;
  description?: string;
  truncateName?: boolean;
  hideHoverBorder?: boolean;
  hideExternalLinkIcon?: boolean;
  target?: string;
}

export const ContactWithData: React.FC<IContactWithDataProps> = ({
  id,
  isNewUser,
  description,
  p,
  truncateName,
  hideHoverBorder,
  hideExternalLinkIcon,
  target = "_blank",
  ...props
}) => {
  const { appId } = useParams<{
    appId: string;
  }>() as {
    appId: string;
  };

  const { contact, isLoadingContact, contactError, fetchContact } = useContact({
    appId,
    id,
  });
  useEffect(() => {
    fetchContact();
  }, [fetchContact]);

  if (isLoadingContact) {
    return (
      <Flex
        p={p}
        align="center"
        justify="space-between"
        border="1px"
        borderRadius="lg"
        borderColor="white"
        _hover={{
          borderColor: hideHoverBorder ? "" : "gray.200",
          cursor: "pointer",
        }}
      >
        <Flex
          data-testid="selector-contact-loading-skeleton"
          justify="flex-start"
          align="center"
        >
          <Skeleton borderRadius="full" w={6} h={6} mr={4} />
          <Skeleton w={24} h={4} />
        </Flex>
        {!hideExternalLinkIcon && (
          <Icon
            mr={1}
            h={4}
            w={4}
            iconType="icon"
            cursor="pointer"
            color="gray.600"
            name="external-link"
          />
        )}
      </Flex>
    );
  }

  if (contactError || !contact) {
    return (
      <Tooltip label="This user hasn't been identified" placement="bottom">
        <Flex
          p={p}
          align="center"
          justify="space-between"
          border="1px"
          borderRadius="lg"
          borderColor="white"
          _hover={{ borderColor: hideHoverBorder ? "" : "gray.200" }}
          data-testid="selector-not-clickable-contact"
        >
          <ContactBody
            data-testid="selector-unidentified-contact"
            userId={id}
            description={description}
          />
          <Flex align="center">
            {isNewUser === "yes" && (
              <Badge colorScheme="purple">New user</Badge>
            )}
            {!hideExternalLinkIcon && (
              <Icon
                ml={4}
                mr={1}
                h={4}
                w={4}
                iconType="icon"
                cursor="pointer"
                color="gray.300"
                name="external-link"
              />
            )}
          </Flex>
        </Flex>
      </Tooltip>
    );
  }

  return (
    <Link
      to={`/a/${contact.appId}/contact/${encodeURIComponent(contact.id)}`}
      target={target}
      rel="noopener noreferrer"
    >
      <Flex
        p={p}
        align="center"
        justify="space-between"
        border="1px"
        borderRadius="lg"
        borderColor="white"
        _hover={{
          borderColor: hideHoverBorder ? "" : "gray.200",
          cursor: "pointer",
        }}
        data-testid="selector-clickable-contact"
        {...props}
      >
        <ContactBody
          contact={contact}
          description={description}
          truncateName={truncateName}
        />
        <Flex align="center">
          {isNewUser === "yes" && <Badge colorScheme="purple">New user</Badge>}
          {!hideExternalLinkIcon && (
            <Icon
              ml={4}
              mr={1}
              h={4}
              w={4}
              iconType="icon"
              cursor="pointer"
              color="gray.600"
              name="external-link"
            />
          )}
        </Flex>
      </Flex>
    </Link>
  );
};
