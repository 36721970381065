import { Flex, Switch, Text, Tooltip } from "@chakra-ui/react";

import { TooltipBody } from "core/components/TooltipBody";
import { FEATURE_RELEASE } from "core/constants/report-configurations";
import { SETTINGS_TYPE } from "core/constants/report-setup";
import { useReportSetup } from "core/hooks/useReportSetup";
import { isUserReportLevel } from "core/modules/reports/utils";
import { IOption } from "core/types/Option";
import { IReport } from "core/types/Report";
import {
  ITemplateConfig,
  ITemplateConfigSetupSection,
} from "core/types/TemplateConfig";

interface ISettingOption extends IOption {
  identifier: string;
}

export const ReportSettings = ({
  config,
  report,
}: {
  config: ITemplateConfig;
  report: IReport;
}) => {
  const { currentReport, updateReportSetup, updateSetting, updateMailSetting } =
    useReportSetup();
  const isUserLevel = isUserReportLevel();
  const setup = config.setup;
  const settings = currentReport?.config?.settings || {};

  const mailSetting = report?.reportMailSetting;

  const settingsConfig = config?.setup?.setupSections.find(
    (section: ITemplateConfigSetupSection) => section.type === SETTINGS_TYPE,
  );

  const settingsOptions = settingsConfig?.options?.filter(
    (option: IOption) =>
      "identifier" in option &&
      (option.isCompanyOption ? !isUserLevel : true) &&
      (option.isUserOption ? isUserLevel : true),
  ) as ISettingOption[];

  if (!settingsConfig && !mailSetting) {
    return <></>;
  }

  function onChangeSetting(setting: { [key: string]: boolean }) {
    updateSetting({ setting, configKey: "settings" });
    updateReportSetup({ setup });
  }

  return (
    <div className="flex flex-col justify-between gap-2">
      {settingsOptions?.map(
        ({ type, identifier, tooltip, displayName }, index: number) => {
          switch (type) {
            case "boolean":
              return (
                <Tooltip
                  isDisabled={!tooltip}
                  label={<TooltipBody text={tooltip ?? ""} />}
                  placement="top"
                  borderRadius="lg"
                  hasArrow
                >
                  <div className="flex items-center" key={index}>
                    <div className="flex">
                      <Switch
                        className="ml-1"
                        size="sm"
                        colorScheme="purple"
                        isChecked={!settings[identifier]}
                        onChange={(e) =>
                          onChangeSetting({
                            [identifier]: !settings[identifier],
                          })
                        }
                      />
                    </div>
                    <div className="flex">
                      <Text className="ml-2 truncate text-sm font-medium text-gray-700">
                        {displayName}
                      </Text>
                    </div>
                  </div>
                </Tooltip>
              );
            default:
              return null;
          }
        },
      )}
      {mailSetting && report.reportType === FEATURE_RELEASE && (
        <Tooltip
          label={
            <div className="flex flex-col space-y-2">
              <div className="text-gray-600">Track your launch 🎉</div>

              <p className="text-pretty">
                Get an email summary of how your launch is going after 1 day, 1
                week and 1 month.
                <br />
                <br />
                Celebrate in-app when you reach a user and usage goal.
              </p>
            </div>
          }
          placement="bottom"
          borderRadius="lg"
          hasArrow
        >
          <div className="flex items-center gap-2 rounded-lg border px-3 py-1.5 shadow-sm">
            <Flex>
              <Switch
                size="sm"
                colorScheme="purple"
                isChecked={mailSetting.isEnabled}
                onChange={(e) => updateMailSetting(e.currentTarget.checked)}
              />
            </Flex>
            <Flex>
              <Text noOfLines={1} fontSize="sm">
                Launch mode
              </Text>
            </Flex>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
