import { PlusIcon } from "@heroicons/react/16/solid";
import {
  ArrowRightLeftIcon,
  CalendarIcon,
  ChevronDown,
  HashIcon,
  ListIcon,
  MinusIcon,
  TextIcon,
  UsersIcon,
} from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import { Button } from "Components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "Components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "Components/ui/dropdown-menu";
import { Input } from "Components/ui/input";
import { Label } from "Components/ui/label";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { useAppObjects } from "core/hooks/useAppObjects";
import { DataTypeString, useCreateTraitMutation } from "core/models/traits";
import { ITraitOption } from "core/types/Trait";
import { TraitOption } from "routes/Settings/Objects/TraitOption";

export const COLOR_PALETTES = [
  colors.purple,
  colors.red,
  colors.green,
  colors.blue,
  colors.yellow,
  colors.orange,
  colors.teal,
  colors.pink,
];

export const COLOR_NAME_TO_PALETTE = {
  purple: colors.purple,
  red: colors.red,
  green: colors.green,
  blue: colors.blue,
  yellow: colors.yellow,
  orange: colors.orange,
  teal: colors.teal,
  pink: colors.pink,
} as { [key in string]: IColorPalette };

export const AddTraitsModal: React.FC = () => {
  const { appId, objectId } = useParams();
  const { appObjects } = useAppObjects();
  const object = appObjects.find((o) => o.id === Number(objectId));
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [options, setOptions] = useState<ITraitOption[]>([]);
  const [dataType, setDataType] = useState<DataTypeString>(
    DataTypeString.UNASSIGNED,
  );
  const [createTrait] = useCreateTraitMutation();

  function onAddOption() {
    setOptions([
      ...options,
      { id: Math.random(), label: "", color: "purple", value: "" },
    ]);
  }

  function onCreateTrait() {
    createTrait({
      appId: Number(appId),
      appObjectId: Number(objectId),
      name,
      dataType,
      options,
    });
    setIsOpen(false);
    setName("");
    setDataType(DataTypeString.UNASSIGNED);
    toast.success("Trait created", {
      description: `Trait will be available on your ${object?.pluralName.toLocaleLowerCase()} in a few minutes`,
    });
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger>
        <Button variant="outline" className="h-full items-center gap-1 text-sm">
          <PlusIcon className="h-4 w-4" />
          Create trait
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add trait</DialogTitle>
          <DialogDescription>
            Add a trait to your {object?.pluralName.toLocaleLowerCase()}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-3">
          <Label>Name</Label>
          <Input
            size="sm"
            placeholder="Trait name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Label>Data type</Label>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button
                variant="outline"
                className="w-full justify-between capitalize"
              >
                {dataType}
                <ChevronDown className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => setDataType(DataTypeString.UNASSIGNED)}
              >
                <MinusIcon className="h-4 w-4 text-gray-500" />
                Unassigned
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => setDataType(DataTypeString.STRING)}
              >
                <TextIcon className="h-4 w-4 text-gray-500" />
                String
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => setDataType(DataTypeString.NUMBER)}
              >
                <HashIcon className="h-4 w-4 text-gray-500" />
                Number
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => setDataType(DataTypeString.BOOLEAN)}
              >
                <ArrowRightLeftIcon className="h-4 w-4 text-gray-500" />
                Boolean
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => setDataType(DataTypeString.DATE)}
              >
                <CalendarIcon className="h-4 w-4 text-gray-500" />
                Date
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => setDataType(DataTypeString.WORKSPACE_MEMBER)}
              >
                <UsersIcon className="h-4 w-4 text-gray-500" />
                Workspace member
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => setDataType(DataTypeString.OPTIONS)}
              >
                <ListIcon className="h-4 w-4 text-gray-500" />
                Options
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          {dataType === DataTypeString.OPTIONS && (
            <div className="flex flex-col gap-2">
              <Label>Options</Label>
              {options.map((option) => (
                <TraitOption
                  key={option.id}
                  option={option}
                  onUpdateOptionColor={(option, color) =>
                    setOptions(
                      options.map((o) =>
                        o.id === option.id ? { ...o, color } : o,
                      ),
                    )
                  }
                  onUpdateOptionLabel={(option, label) =>
                    setOptions(
                      options.map((o) =>
                        o.id === option.id
                          ? {
                              ...o,
                              label: label,
                              value: label
                                .toLocaleLowerCase()
                                .split(` `)
                                .join(`_`),
                            }
                          : o,
                      ),
                    )
                  }
                />
              ))}
              <Button className="flex" variant="outline" onClick={onAddOption}>
                Add option
              </Button>
            </div>
          )}
        </div>
        <DialogFooter className="mt-4">
          <Button onClick={onCreateTrait}>Create</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
