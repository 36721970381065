import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAppObject } from "core/types/AppObject";

export interface IAppObjectState {
  activeAppObject: IAppObject | null;
  appObjects: IAppObject[];
}

const initialState: IAppObjectState = {
  activeAppObject: null,
  appObjects: [],
};

export const slice = createSlice({
  name: "appObjects",
  initialState,
  reducers: {
    setActiveAppObject: (
      state,
      action: PayloadAction<{ activeAppObject: IAppObject }>,
    ) => {
      state.activeAppObject = action.payload.activeAppObject;
    },
    setAppObjects: (
      state,
      action: PayloadAction<{ appObjects: IAppObject[] }>,
    ) => {
      state.appObjects = action.payload.appObjects;
    },
  },
});

export default slice.reducer;
