import { Flex } from "@chakra-ui/react";

import { IEvent } from "core/types/Event";
import { EventActionsMenu } from "modules/Events/List/EventActionsMenu";

interface IReportNameActionsCellProps {
  props: IEvent;
  mutate: () => void;
}

export const EventActionsCell: React.FC<IReportNameActionsCellProps> = ({
  props: event,
  mutate,
}) => {
  return (
    <Flex>
      <EventActionsMenu event={event} />
    </Flex>
  );
};
