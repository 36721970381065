import { Box, Button, Flex, Skeleton, Text } from "@chakra-ui/react";

import { IListConfig } from "core/components/List/List.types";
import { Cell } from "core/components/List/private/Cell";
import { LoadingListItem } from "core/components/List/private/LoadingItem";
import { Icon } from "core/design-system/components/Icon";
import ChevronRightIcon from "core/design-system/components/Icon/Interface/ChevronRightIcon";

export const LoadingList = ({
  config,
  hideAvatar = false,
}: {
  config: IListConfig;
  hideAvatar?: boolean;
}) => {
  return (
    <Box>
      <Flex
        px={6}
        borderTopRightRadius="sm"
        borderTopLeftRadius="sm"
        borderBottomWidth="1px"
        justify="space-between"
      >
        {config.columns.map((column, index: number) => (
          <Cell
            key={index}
            rowIndex={index}
            textAlign={column.align || "left"}
            text={column.title}
            name={column.name}
            color="gray.700"
            fontWeight="semibold"
            fontSize="xs"
            tooltip={column.tooltip || undefined}
            sortable={false}
          />
        ))}
      </Flex>
      <LoadingListItem hideAvatar={hideAvatar} />
      <LoadingListItem hideAvatar={hideAvatar} />
      <LoadingListItem hideAvatar={hideAvatar} />
      <LoadingListItem hideAvatar={hideAvatar} />
      <LoadingListItem hideAvatar={hideAvatar} />
      <LoadingListItem hideAvatar={hideAvatar} />
      <LoadingListItem hideAvatar={hideAvatar} />
      <LoadingListItem hideAvatar={hideAvatar} />
      <LoadingListItem hideAvatar={hideAvatar} />
      <LoadingListItem hideAvatar={hideAvatar} />
      <LoadingListItem hideAvatar={hideAvatar} />
      <Flex justify="space-between" h={16}>
        <Flex align="center" px={4} color="gray.600">
          <Text>
            <Skeleton
              borderRadius="lg"
              h={2}
              w={16}
              startColor="gray.200"
              endColor="gray.300"
            />
          </Text>
        </Flex>
        <Flex align="center" px={4}>
          <Button
            variant="ghost"
            colorScheme="purple"
            fontWeight="normal"
            isDisabled
          >
            <Icon iconType="icon" name="chevron-left" />
            <Text pr={1}>Previous</Text>
          </Button>
          <Button
            variant="ghost"
            fontWeight="normal"
            colorScheme="purple"
            isDisabled
          >
            <Flex align="center">
              <Text pr={1}>Next</Text>
              <ChevronRightIcon />
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
