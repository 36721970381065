import { Flex } from "@chakra-ui/react";
import React from "react";

import { ILabel } from "core/types/Label";
import { LabelPill } from "modules/Labels/LabelPill";

interface ILabelNameCellProps {
  props: ILabel;
}

export const LabelNameCell: React.FC<ILabelNameCellProps> = ({ props }) => {
  return (
    <Flex>
      <LabelPill label={props} />
    </Flex>
  );
};
