import { LockIcon } from "@chakra-ui/icons";
import {
  BuildingOffice2Icon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import AnimatedToggle from "core/components/AnimatedToggle";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { baseUrl } from "core/constants/paywall";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { usePaywall } from "core/hooks/usePaywall";
import { useReportSetup } from "core/hooks/useReportSetup";
import { getAppObject } from "core/modules/appObjects/utils";
import { getLevelParam } from "core/modules/reports/utils";
import { AppObjectType, IAppObject } from "core/types/AppObject";

const getIcon = (value: AppObjectType) => {
  if (value === AppObjectType.Group) {
    return <UserGroupIcon className="h-3" />;
  }
  if (value === AppObjectType.Company) {
    return <BuildingOffice2Icon className="h-3" />;
  }
  return <UserIcon className="h-3" />;
};

export const ReportLevelToggle: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const navigate = useNavigate();
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const { currentReport } = useReportSetup();
  const { appObjects, activeAppObject, setActiveAppObject } = useAppObjects();
  const level = getLevelParam();
  const appObject = getAppObject(appObjects, level);
  if (appObject) setActiveAppObject(appObject);
  const handleReportLevelChange = (appObject: IAppObject) => {
    setActiveAppObject(appObject);
    navigate(`/a/${appId}/report/${currentReport.id}?level=${appObject.slug}`);
  };
  const { shouldBePaywalled } = usePaywall();
  const redirectUrl = `${baseUrl}/a/${appId}/reports/${currentReport.id}`;

  if (!appObjects) {
    return <></>;
  }

  const toggleOptions =
    appObjects
      ?.filter((option) => option.isEnabled)
      .map((appObject) => ({
        value: appObject.slug,
        label: appObject.pluralName,
        endIcon:
          shouldBePaywalled && ["company", "group"].includes(appObject.slug) ? (
            <LockIcon />
          ) : (
            getIcon(appObject.objectType)
          ),
      })) ?? [];

  return (
    <>
      <AnimatedToggle
        items={toggleOptions}
        value={activeAppObject?.slug || ""}
        onChange={(slug) => {
          const appObject = getAppObject(appObjects, slug);
          if (
            shouldBePaywalled &&
            appObject &&
            ["company", "group"].includes(appObject.slug)
          ) {
            return setShowPaywallModal(true);
          }
          if (appObject) handleReportLevelChange(appObject);
        }}
      />
      {showPaywallModal && (
        <PaywallModal redirectUrl={redirectUrl}>
          {(paywallModal: IPaywallModalChildren) => (
            <paywallModal.Modal
              isOpen
              onClose={() => setShowPaywallModal(false)}
            />
          )}
        </PaywallModal>
      )}
    </>
  );
};
