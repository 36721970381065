import { Box, Center, Image, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

import { ITemplateConfigSetupExampleSection } from "core/types/TemplateConfig";

interface IExamplesSectionProps {
  exampleSection?: ITemplateConfigSetupExampleSection;
  index: number;
  openPanelIndex: number;
}

const ExamplesSection: React.FC<IExamplesSectionProps> = ({
  exampleSection,
  openPanelIndex,
  index,
}) => {
  if (!exampleSection) return null;
  if (index !== openPanelIndex) return null;

  return (
    <motion.div
      initial={{ y: -15, opacity: 0.1 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0, duration: 0.3 }}
    >
      <Box data-testid="setup-examples-section">
        <Center flexDirection="column">
          <Text fontSize="md" fontWeight="semibold">
            {exampleSection.title}
          </Text>
          <Text fontSize="sm">{exampleSection.description}</Text>
          <Image
            borderRadius="lg"
            alignItems="center"
            justifyContent="flex-end"
            src={`${exampleSection.illustration}`}
            bgRepeat="no-repeat"
            bgPosition="center"
            bgSize="contain"
            h="300px"
            w="300px"
            my={4}
          />
          <Text mb={2} fontSize="sm">
            Track event example
          </Text>
          <Text
            borderRadius="md"
            boxShadow="sm"
            fontSize="sm"
            bg="white"
            px={2}
            py={1}
            color="purple.500"
          >
            {exampleSection.event}
          </Text>
        </Center>
      </Box>
    </motion.div>
  );
};

export default ExamplesSection;
