import {
  EllipsisVerticalIcon,
  DocumentDuplicateIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { Button } from "@/Components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/Components/ui/dropdown-menu";
import {
  IView,
  useDeleteViewMutation,
  useDuplicateViewMutation,
} from "core/models/views";

export const ViewMenu = ({ view }: { view: IView }) => {
  const navigate = useNavigate();
  const [duplicateView] = useDuplicateViewMutation();
  const [deleteView] = useDeleteViewMutation();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="mt-1"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <EllipsisVerticalIcon className="h-3 w-3 text-gray-900" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[160px]">
        <DropdownMenuItem
          className="cursor-pointer gap-2 text-xs"
          onClick={(e) => {
            e.stopPropagation();
            duplicateView({
              id: view.id,
              appId: view.appId,
            })
              .unwrap()
              .then((data) => {
                toast.success("Dashboard duplicated successfully");
                if (data) {
                  navigate(`/a/${view.appId}/home/${data.id}`);
                }
              })
              .catch(() => {
                toast.error("Failed to duplicate dashboard");
              });
          }}
        >
          <DocumentDuplicateIcon className="h-3 w-3 text-gray-600" />
          Duplicate
        </DropdownMenuItem>
        <DropdownMenuItem
          className="cursor-pointer gap-2 text-xs"
          onClick={(e) => {
            e.stopPropagation();
            deleteView({
              id: view.id,
              appId: view.appId,
            })
              .unwrap()
              .then(() => {
                toast.success("Dashboard deleted successfully");
                navigate(`/a/${view.appId}/home`);
              })
              .catch(() => {
                toast.error("Failed to delete dashboard");
              });
          }}
        >
          <TrashIcon className="h-3 w-3 text-gray-600" />
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
