import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import type { SnakeCasedPropertiesDeep } from "type-fest";

import { IContact } from "core/types/Contact";
import { IGroup } from "core/types/Group";

export interface IContactsListResponse {
  contacts: IContact[];
}

export interface IGroupsListResponse {
  groups: IGroup[];
}

export interface ICompaniesListResponse {
  companies: IGroup[];
}

export const audienceListApi = createApi({
  reducerPath: "audienceListApi",
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_API_HOST }),
  endpoints: (builder) => ({
    userSearch: builder.query<
      IContactsListResponse,
      { appId: number; query: string }
    >({
      query: ({ appId, query }) => ({
        url: `/contacts/search`,
        params: { app_id: appId, query },
        credentials: "include",
        mode: "cors",
      }),
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IContactsListResponse>,
      ): IContactsListResponse =>
        humps.camelizeKeys(response) as IContactsListResponse,
    }),
    groupSearch: builder.query<
      IGroupsListResponse,
      { appId: number; query: string }
    >({
      query: ({ appId, query }) => ({
        url: `/groups/search`,
        params: { app_id: appId, query },
        credentials: "include",
        mode: "cors",
      }),
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGroupsListResponse>,
      ): IGroupsListResponse =>
        humps.camelizeKeys(response) as IGroupsListResponse,
    }),
    companySearch: builder.query<
      ICompaniesListResponse,
      { appId: number; query: string }
    >({
      query: ({ appId, query }) => ({
        url: `/companies/search`,
        params: { app_id: appId, query },
        credentials: "include",
        mode: "cors",
      }),
      transformResponse: (
        response: SnakeCasedPropertiesDeep<ICompaniesListResponse>,
      ): ICompaniesListResponse =>
        humps.camelizeKeys(response) as ICompaniesListResponse,
    }),
  }),
});

export const {
  useUserSearchQuery,
  useGroupSearchQuery,
  useCompanySearchQuery,
} = audienceListApi;
