import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { startCase } from "lodash";
import { useParams } from "react-router-dom";

import { Button } from "@/Components/ui/button";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenu,
} from "@/Components/ui/dropdown-menu";
import {
  GraphTypeToIcon,
  InsightTypeConfigs,
} from "@/core/hooks/useEditViewInsight";
import { IAppObject } from "@/core/types/AppObject";
import { GraphType, Measure } from "@/core/types/ViewInsight";

export const GraphTypeSelector: React.FC<{
  graphType: GraphType;
  onSelect: (graphType: GraphType) => void;
  measure: Measure;
  appObject: IAppObject;
}> = ({ graphType, onSelect, measure, appObject }) => {
  const { objectId } = useParams();
  const shouldShowGraphType = (graphType: GraphType) => {
    return (
      Object.keys(InsightTypeConfigs[measure].graphTypes).includes(graphType) &&
      InsightTypeConfigs?.[measure]?.graphTypes?.[
        graphType
      ]?.appObjectTypes?.includes(appObject.objectType)
    );
  };

  const getGraphTypeDropdownItems = () => {
    return Object.values(GraphType)
      .filter((g) =>
        g === GraphType.ContributionGraph ? Boolean(objectId) : true,
      )
      .reverse()
      .map((graphType) => {
        if (shouldShowGraphType(graphType)) {
          return {
            icon: GraphTypeToIcon[graphType].solid,
            label: startCase(graphType.split(`_`).join(` `)),
            value: graphType,
          };
        }

        return null;
      });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex items-center gap-1">
          <Button variant="secondary" size="sm" className="w-full">
            <div className="flex w-full flex-row items-center justify-between">
              <div className="flex flex-row items-center gap-2">
                <span>{GraphTypeToIcon[graphType].solid}</span>
                <span className="text-sm font-medium">
                  {startCase(graphType.split(`_`).join(` `))}
                </span>
              </div>
              <ChevronDownIcon className="h-2 w-2" />
            </div>
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {getGraphTypeDropdownItems().map(
          (item) =>
            item && (
              <DropdownMenuItem
                key={item.value}
                onClick={() => onSelect(item.value)}
                className="flex cursor-pointer items-center gap-2"
              >
                <span className="text-gray-600">{item.icon}</span>
                <span className="text-sm font-medium">{item.label}</span>
              </DropdownMenuItem>
            ),
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
