import { Box, Flex, Text } from "@chakra-ui/react";

import { ClickableTooltipContainer } from "core/design-system/charts/Tooltip/ClickableTooltipContainer";
import { ClickableTooltipFooter } from "core/design-system/charts/Tooltip/ClickableTooltipFooter";
import { PercentChange } from "core/design-system/components/PercentChange";
import colors from "core/design-system/constants/theme/colors";

interface ICustomTooltipProps {
  label: string;
  payload: any[];
  active: boolean;
  unit: string;
  previewMode: boolean;
  onClick: (_: any, payload: any) => void;
}

export const CustomTooltip: React.FC<ICustomTooltipProps> = ({
  label,
  payload,
  active,
  unit,
  previewMode,
  onClick,
}) => {
  const data = payload?.[0]?.payload;
  if (active && data) {
    return (
      <ClickableTooltipContainer
        isClickable={!previewMode}
        onClick={() => {
          if (!previewMode) {
            onClick(undefined, { payload: data });
          }
        }}
      >
        <Text fontSize="xs" color="gray.400" mb={2}>
          {label}{" "}
          {typeof data.currentY === "number" && !data.hideY && (
            <Text as="span" fontSize="xs" color="white" fontWeight="semibold">
              (in progress)
            </Text>
          )}
        </Text>
        {typeof data.y === "number" && (
          <Flex align="center">
            <Box
              h={2}
              w={2}
              mr={2}
              bg={colors.purple[500]}
              borderRadius="100%"
            ></Box>
            <Text fontSize="sm" color="white">
              {data.y} {unit}
            </Text>
          </Flex>
        )}
        {typeof data.currentY === "number" && !data.hideY && (
          <Flex align="center">
            <Box
              h={2}
              w={2}
              mr={2}
              bg={colors.gray[500]}
              borderRadius="100%"
            ></Box>
            <Text fontSize="sm" color="white">
              {data.currentY} {unit}
            </Text>
          </Flex>
        )}
        {data.previousDate && (
          <PercentChange
            ml={3}
            percentChange={data.growth}
            changeText={`vs ${data.previousDate}`}
          />
        )}
        {!previewMode && <ClickableTooltipFooter />}
      </ClickableTooltipContainer>
    );
  }
  return null;
};
