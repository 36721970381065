import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  BuildingOffice2Icon,
  ChevronDownIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { DateRange } from "react-date-range";

import { PageEvent } from "core/components/Event/PageEvent";
import { ANY, FIRST, PAGE, TRACK } from "core/constants/events";
import { PROPERTY } from "core/constants/filters";
import {
  EXACTLY,
  FREQUENCY_MATCHER_LABELS,
  LESS_THAN,
  MORE_THAN,
  TIMERANGE_TYPE_LABELS,
  TimerangeType,
} from "core/constants/report-setup";
import {
  DAY,
  INTERVAL_TYPE_NAMES,
  MONTH,
  WEEK,
} from "core/constants/timerange";
import { IS } from "core/constants/traitFilterComponents";
import { Icon } from "core/design-system/components/Icon";
import { getReadableTimerange } from "core/helpers/timerange";
import { uuid } from "core/helpers/uuid";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useReportSetup } from "core/hooks/useReportSetup";
import { NumberInputFilter } from "core/modules/reports/setup/Audience/NumberInputFilter";
import { PropertyEditor } from "core/modules/reports/setup/Editor/PropertyEditor";
import { PropertySelectMenu } from "core/modules/reports/setup/Editor/PropertySelectMenu";
import { TrackEventDropdown } from "core/modules/reports/setup/Menu/TrackEventDropdown";
import { IEvent } from "core/types/Event";
import { IEventProperty } from "core/types/EventProperty";
import { IFilter, IntervalType } from "core/types/Filters.d";
import { cx } from "helpers/cx";

export interface IDateRangeItem {
  selection: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
}

interface IDateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface IEventFilterProps {
  filter: IFilter;
  filterIndex: number;
  filterGroupIndex?: number;
  onRemoveFilter?: (filter: IFilter) => void;
  onSaveFilter?: (filter: IFilter) => void;
  enableTimerange?: boolean;
  enableLevelPicker?: boolean;
  enableEventProperties?: boolean;
  enableEventOccurrence?: boolean;
  canRemove?: boolean;
  hasSmartEvents?: boolean;
  smartEvents?: IEvent[];
  showDownArrow?: boolean;
  textProps?: string;
  isDisabled?: boolean;
  disabledTooltipText?: string;
  onUpdateReport?: () => void;
}

const FREQUENCY_MATCHER = [LESS_THAN, MORE_THAN, EXACTLY];
export const INTERVAL_OPTIONS = [DAY, WEEK, MONTH] as IntervalType[];

export const EventFilter: React.FC<IEventFilterProps> = ({
  filter,
  onRemoveFilter,
  onSaveFilter,
  filterIndex,
  filterGroupIndex = 0,
  enableTimerange = true,
  enableLevelPicker = true,
  enableEventProperties = true,
  enableEventOccurrence = true,
  canRemove = true,
  hasSmartEvents = true,
  smartEvents = undefined,
  showDownArrow = true,
  textProps = undefined,
  isDisabled = false,
  disabledTooltipText,
  onUpdateReport,
}) => {
  const { groupAppObject } = useAppObjects();
  const [currentFilter, setCurrentFilter] = useState<IFilter>(filter);
  const isUsingSmartEventFilter =
    Number(currentFilter.body?.eventId) === ANY ||
    Number(currentFilter.body?.eventId) === FIRST;
  const comparisonType =
    currentFilter?.body?.comparisonType || FREQUENCY_MATCHER[0];
  const times = currentFilter?.body?.times || 0;
  const interval = currentFilter?.body?.interval || 0;
  const timerangeType =
    currentFilter?.body?.timerangeType !== undefined
      ? (currentFilter?.body?.timerangeType as TimerangeType)
      : TimerangeType.ALL_TIME;

  const createInitialEvent = (
    currentFilter: IFilter,
    isUsingSmartEventFilter: boolean,
  ): IEvent => {
    if (currentFilter.type === PAGE) {
      return {
        id: uuid(),
        type: PAGE,
        properties: currentFilter.body.properties,
      } as unknown as IEvent;
    }

    return {
      id: currentFilter.body.id,
      type: isUsingSmartEventFilter ? FIRST : TRACK,
      properties: currentFilter.body.properties || [],
      name: currentFilter.body.eventName,
    } as unknown as IEvent;
  };

  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(() =>
    createInitialEvent(currentFilter, isUsingSmartEventFilter),
  );

  const intervalType = (currentFilter?.body?.intervalType ||
    DAY) as IntervalType;
  let startDate: Date =
    currentFilter?.body?.startDate === undefined
      ? new Date(new Date().setDate(new Date().getDate() - 7))
      : new Date(currentFilter?.body?.startDate);
  let endDate: Date =
    currentFilter?.body?.endDate === undefined
      ? new Date()
      : new Date(currentFilter?.body?.endDate);
  const initialCustomDate: IDateRange[] = [
    {
      startDate,
      endDate,
      key: "selection",
    },
  ];
  const [customDateRange, setCustomDateRange] =
    useState<IDateRange[]>(initialCustomDate);
  const [isUserEvent, setIsUserEvent] = useState<boolean>(
    filter.body.isUserEvent !== undefined ? filter.body.isUserEvent : true,
  );

  const readableTimerange = getReadableTimerange({
    startTimestamp: customDateRange[0].startDate,
    endTimestamp: customDateRange[0].endDate,
  });

  const { updateAudienceFilter } = useReportSetup();

  const updateEventFilter = (
    filter: IFilter,
    key: string,
    value: string | number,
    callback?: (filter: IFilter) => void,
  ) => {
    const _filter = {
      ...filter,
      body: { ...currentFilter.body, [key]: value },
    };
    updateAudienceFilter({ filterGroupIndex, filter: _filter });
    setCurrentFilter(_filter);
    callback && callback(_filter);
  };

  function onDone() {
    onSaveFilter && onSaveFilter(currentFilter);
    selectedEvent && onUpdateFilter(selectedEvent);
    onUpdateReport && onUpdateReport();
  }

  const updateUserEventTimerange = (
    filter: IFilter,
    startDate: string,
    endDate: string,
  ) => {
    const _filter = {
      ...filter,
      body: {
        ...currentFilter.body,
        timerangeType: TimerangeType.ABSOLUTE,
        startDate: startDate,
        endDate: endDate,
      },
    };
    updateAudienceFilter({ filterGroupIndex, filter: _filter });
    setCurrentFilter(_filter);
    onUpdateReport && onUpdateReport();
  };

  const onUpdateFilter = (event: IEvent) => {
    let _filter;

    if (event.type === PAGE) {
      _filter = {
        ...filter,
        body: {
          ...currentFilter.body,
          properties: event.properties,
        },
      };
    } else {
      _filter = {
        ...filter,
        body: {
          ...currentFilter.body,
          eventId: event.id,
          eventName: event.name,
          properties: event.properties,
          comparisonType,
          times,
          interval,
          intervalType,
          name: event.name,
          id: event.id,
          isUserEvent,
          type: event.type,
        },
      };
    }
    setSelectedEvent({ ...event, properties: event.properties || [] });
    updateAudienceFilter({ filterGroupIndex, filter: _filter });
    setCurrentFilter(_filter);
    onSaveFilter && onSaveFilter(_filter);
  };

  function onChangeEventLevel(isUserEvent: boolean) {
    const _filter = {
      ...currentFilter,
      body: { ...currentFilter.body, isUserEvent },
    };
    updateAudienceFilter({ filterGroupIndex, filter: _filter });
    setCurrentFilter(_filter);
    setIsUserEvent(isUserEvent);
    onSaveFilter && onSaveFilter(_filter);
  }

  function onSelectProperty({
    event,
    property,
  }: {
    event: IEvent;
    property: string;
  }) {
    const existingProperties = event.properties || [];
    const _event = {
      ...event,
      properties: [
        ...existingProperties,
        {
          body: {
            uuid: uuid(),
            property,
            comparisonType: IS.toString(),
            value: "",
            adding: true,
          },
          type: PROPERTY,
        },
      ],
    };
    setSelectedEvent(_event);
    onSaveFilter && onSaveFilter(currentFilter);
  }

  function onRemoveProperty({
    event,
    property,
  }: {
    event: IEvent;
    property: IEventProperty;
  }) {
    const _event = {
      ...event,
      properties: event.properties.filter(
        (p) => p.body.uuid !== property.body.uuid,
      ),
    };
    setSelectedEvent(_event);
    onUpdateFilter(_event);
  }

  function onChangeTrackProperty({
    key,
    value,
    event,
    property,
    selected,
  }: {
    key: string;
    value: string;
    event: IEvent;
    property: IEventProperty;
    selected: boolean;
  }) {
    const _updatedProperties = event.properties.map((p) => {
      if (p.body.uuid === property.body.uuid)
        return { ...p, body: { ...p.body, [key]: value }, adding: false };
      return { ...p, adding: false };
    });
    const _event = {
      ...event,
      properties: _updatedProperties,
    };
    setSelectedEvent(_event);
    selected && onUpdateFilter(_event);
    onUpdateReport && onUpdateReport();
  }

  function onChangeTrackPropertyValue(
    e: React.FormEvent<HTMLInputElement>,
    event: IEvent,
    property: IEventProperty,
    selected: boolean,
  ) {
    const value = e.currentTarget.value;
    onChangeTrackProperty({ event, property, key: "value", value, selected });
  }

  function onChangePropComparison({
    newComparison,
    property,
  }: {
    newComparison: string;
    property: IEventProperty;
  }) {
    if (!selectedEvent) return;

    onChangeTrackProperty({
      event: selectedEvent,
      property,
      key: "comparisonType",
      value: newComparison,
      selected: true,
    });
  }

  const onFrequencyMatcherChange = (matcher: number) => {
    updateEventFilter(currentFilter, "comparisonType", matcher);
  };

  const onFrequencyChange = (_times: string) => {
    updateEventFilter(currentFilter, "times", Number(_times));
  };

  const onIntervalChange = (_interval: string) => {
    updateEventFilter(currentFilter, "interval", Number(_interval));
  };

  const onAllTimerangeTypeChange = () => {
    updateEventFilter(filter, "timerangeType", TimerangeType.ALL_TIME);
  };

  const onRelativeTimerangeTypeChange = () => {
    updateEventFilter(filter, "timerangeType", TimerangeType.RELATIVE);
  };

  const onAbsoluteTimerangeTypeChange = () => {
    updateUserEventTimerange(
      filter,
      customDateRange[0].startDate.toISOString(),
      customDateRange[0].endDate.toISOString(),
    );
  };

  const onIntervalTypeChange = (_intervalType: number) => {
    updateEventFilter(currentFilter, "intervalType", _intervalType);
  };

  const onRemoveEvent = () => {
    onRemoveFilter && onRemoveFilter(filter);
  };

  const onPageEventChangeProperty = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    const _event = {
      ...selectedEvent,
      properties: [
        {
          ...selectedEvent?.properties[0],
          body: {
            ...selectedEvent?.properties[0].body,
            property: event.currentTarget.innerText,
          },
        },
      ],
    } as IEvent;

    setSelectedEvent(_event);
    onUpdateFilter(_event);
  };

  const onPageEventChangeComparison = (comparison: string) => {
    const _event = {
      ...selectedEvent,
      properties: [
        {
          ...selectedEvent?.properties[0],
          body: {
            ...selectedEvent?.properties[0].body,
            comparisonType: comparison,
          },
        },
      ],
    } as IEvent;

    setSelectedEvent(_event);
    onUpdateFilter(_event);
  };

  const onPageEventChangeValue = (value: string) => {
    const _event = {
      ...selectedEvent,
      properties: [
        {
          ...selectedEvent?.properties[0],
          body: {
            ...selectedEvent?.properties[0].body,
            value,
          },
        },
      ],
    } as IEvent;

    setSelectedEvent(_event);
    onUpdateFilter(_event);
  };

  return (
    <Tooltip
      label={isDisabled ? disabledTooltipText : ""}
      shouldWrapChildren
      hasArrow
    >
      <div
        className={cx(
          "flex flex-wrap items-stretch",
          isDisabled && "opacity-30",
        )}
        data-testid="audience-event-filters"
      >
        {enableLevelPicker && (
          <Menu>
            <Tooltip
              placement="top"
              label={
                isUserEvent
                  ? "User performed event"
                  : `${groupAppObject?.singularName} performed event`
              }
              hasArrow
              shouldWrapChildren
            >
              <MenuButton
                h={"full"}
                px={0}
                borderLeftRadius={filterIndex === 0 ? "lg" : 0}
                borderRightRadius={0}
                bg="transparent"
                border="none"
                noOfLines={1}
                fontSize="sm"
                fontWeight="medium"
                color="gray.900"
                cursor="pointer"
                shadow="none"
                as={Button}
              >
                <Flex w="full" align="center" pl={3}>
                  {isUserEvent ? (
                    <UserIcon className="h-4" />
                  ) : (
                    <BuildingOffice2Icon className="h-4" />
                  )}
                </Flex>
              </MenuButton>
            </Tooltip>
            <MenuList>
              <MenuItem onClick={() => onChangeEventLevel(true)} fontSize="sm">
                <UserIcon className="ml-1 mr-2 h-4" /> User performed event
              </MenuItem>
              <MenuItem onClick={() => onChangeEventLevel(false)} fontSize="sm">
                <BuildingOffice2Icon className="ml-1 mr-2 h-4" />
                {groupAppObject?.singularName} performed event
              </MenuItem>
            </MenuList>
          </Menu>
        )}

        {selectedEvent &&
          (selectedEvent?.type === TRACK ||
            selectedEvent?.type === FIRST ||
            selectedEvent?.type === ANY) && (
            <div className="flex">
              <TrackEventDropdown
                borderLeftRadius={!enableLevelPicker ? "md" : 0}
                borderRightRadius={
                  isUsingSmartEventFilter ||
                  (!enableEventProperties &&
                    !enableEventOccurrence &&
                    !enableTimerange &&
                    !canRemove)
                    ? "md"
                    : "none"
                }
                shadow="none"
                _hover={{ bg: "transparent" }}
                pl={0}
                defaultIsOpen={false}
                onSelectEvent={onUpdateFilter}
                label={currentFilter?.body?.eventName}
                hasSmartEvents={hasSmartEvents}
                smartEvents={smartEvents}
                showDownArrow={showDownArrow}
                textProps={textProps}
              />
              {enableEventProperties &&
                (!selectedEvent.properties ||
                  (selectedEvent.properties.length === 0 && (
                    <PropertySelectMenu
                      key={selectedEvent.id}
                      event={selectedEvent}
                      onSelectProperty={onSelectProperty}
                      borderRightRadius={
                        enableEventOccurrence || canRemove ? "none" : "md"
                      }
                    />
                  )))}
              <PropertyEditor
                event={selectedEvent}
                onRemoveProperty={onRemoveProperty}
                onChangePropertyComparison={(newComparison, property) =>
                  onChangePropComparison({ newComparison, property })
                }
                onChangeTrackPropertyValue={onChangeTrackPropertyValue}
                onClose={() => onUpdateFilter(selectedEvent)}
              />
            </div>
          )}

        {selectedEvent && selectedEvent.type === PAGE && (
          <PageEvent
            property={selectedEvent.properties[0].body.property}
            comparison={selectedEvent.properties[0].body.comparisonType}
            value={selectedEvent.properties[0].body.value}
            onChangeProperty={onPageEventChangeProperty}
            onChangeComparison={onPageEventChangeComparison}
            onChangeValue={onPageEventChangeValue}
          />
        )}

        {enableEventOccurrence && !isUsingSmartEventFilter && (
          <Menu matchWidth closeOnSelect={false} onClose={onDone}>
            <MenuButton
              fontSize="sm"
              fontWeight="medium"
              borderRightRadius={!enableTimerange ? "md" : 0}
              borderLeftRadius="none"
              border="none"
              bg="gray.50"
              as={Button}
              shadow="none"
              borderRadius={canRemove ? 0 : "md"}
            >
              {FREQUENCY_MATCHER_LABELS[comparisonType]} {times} times
            </MenuButton>
            <MenuList minW="180px" pb={0}>
              <RadioGroup
                pb={2}
                onChange={(f) => onFrequencyMatcherChange(Number(f))}
                value={String(comparisonType)}
                colorScheme={"purple"}
              >
                <Stack px={4} py={2}>
                  {FREQUENCY_MATCHER.map((f, i) => (
                    <div key={i}>
                      <Radio key={i} value={String(f)} py="1px">
                        <Text fontSize="sm">{FREQUENCY_MATCHER_LABELS[f]}</Text>
                      </Radio>
                      {f === comparisonType && (
                        <NumberInputFilter
                          w="full"
                          onChange={onFrequencyChange}
                          defaultValue={times}
                          bg="white"
                          border="0.5px solid"
                          borderColor="gray.200"
                          borderRadius="md"
                          size="sm"
                        />
                      )}
                    </div>
                  ))}
                </Stack>
              </RadioGroup>
              <Button
                onClick={onDone}
                borderRadius="md"
                colorScheme="purple"
                variant="ghost"
                w="full"
                borderTopRadius="none"
                borderTop="1px solid"
                borderColor="gray.200"
                pt={0.5}
              >
                Done
              </Button>
            </MenuList>
          </Menu>
        )}
        {enableTimerange && (
          <Menu matchWidth closeOnSelect={false} onClose={onDone}>
            <MenuButton
              data-testid="audience-event-filter-matcher"
              fontSize="sm"
              fontWeight="medium"
              borderRadius={0}
              border="none"
              bg="transparent"
              shadow="none"
              as={Button}
            >
              {TIMERANGE_TYPE_LABELS[timerangeType]}{" "}
              {timerangeType === TimerangeType.RELATIVE && (
                <>
                  {interval} {INTERVAL_TYPE_NAMES[intervalType]}
                  {interval > 1 ? "s" : ""}
                </>
              )}
              {timerangeType === TimerangeType.ABSOLUTE && (
                <>{readableTimerange}</>
              )}
            </MenuButton>
            <MenuList minW="180px" pb={0}>
              <RadioGroup
                pb={2}
                value={String(timerangeType)}
                colorScheme={"purple"}
              >
                <Stack px={4} py={2}>
                  <Radio
                    key={TimerangeType.ALL_TIME}
                    value={String(TimerangeType.ALL_TIME)}
                    py="1px"
                    onClick={() => onAllTimerangeTypeChange()}
                  >
                    <Text fontSize="sm">
                      {TIMERANGE_TYPE_LABELS[TimerangeType.ALL_TIME]}
                    </Text>
                  </Radio>
                  <Radio
                    key={TimerangeType.RELATIVE}
                    value={String(TimerangeType.RELATIVE)}
                    py="1px"
                    onClick={() => onRelativeTimerangeTypeChange()}
                  >
                    <Text fontSize="sm">
                      {TIMERANGE_TYPE_LABELS[TimerangeType.RELATIVE]}
                    </Text>
                  </Radio>
                  {timerangeType === TimerangeType.RELATIVE && (
                    <>
                      <NumberInputFilter
                        hasInterval={timerangeType === TimerangeType.RELATIVE}
                        onChange={onIntervalChange}
                        defaultValue={interval}
                        bg="white"
                        border="0.5px solid"
                        borderColor="gray.200"
                        borderRadius="md"
                        size="sm"
                        w="full"
                      />
                      <Menu closeOnSelect closeOnBlur matchWidth>
                        <MenuButton
                          borderRadius="md"
                          fontSize="sm"
                          as={Button}
                          _hover={{ bg: "white" }}
                          fontWeight="normal"
                          data-testid="user-event-filter-relative-interval-button"
                        >
                          <Flex
                            align="center"
                            gridGap={2}
                            justifyContent="space-between"
                          >
                            <Flex>
                              {INTERVAL_TYPE_NAMES[intervalType]}
                              {interval > 1 ? "s" : ""}
                            </Flex>
                            <Flex>
                              <ChevronDownIcon style={{ height: "15px" }} />
                            </Flex>
                          </Flex>
                        </MenuButton>
                        <MenuList minW="100px">
                          <MenuOptionGroup
                            defaultValue={String(intervalType)}
                            type="radio"
                          >
                            {INTERVAL_OPTIONS.map((i) => (
                              <MenuItem
                                key={i}
                                data-testid={`user-event-filter-interval-item-${i}`}
                                fontSize="sm"
                                onClick={() => onIntervalTypeChange(i)}
                                value={i}
                              >
                                {INTERVAL_TYPE_NAMES[i]}
                                {interval > 1 ? "s" : ""}
                              </MenuItem>
                            ))}
                          </MenuOptionGroup>
                        </MenuList>
                      </Menu>
                    </>
                  )}
                  <Radio
                    key={TimerangeType.ABSOLUTE}
                    value={String(TimerangeType.ABSOLUTE)}
                    py="1px"
                    onClick={() => onAbsoluteTimerangeTypeChange()}
                  >
                    <Text fontSize="sm">
                      {TIMERANGE_TYPE_LABELS[TimerangeType.ABSOLUTE]}
                    </Text>
                  </Radio>
                  {timerangeType === TimerangeType.ABSOLUTE && (
                    <Popover flip placement="top">
                      <PopoverTrigger>
                        <Button
                          fontSize="sm"
                          _hover={{ bg: "white" }}
                          data-testid="user-event-filter-absolute-interval-button"
                        >
                          {readableTimerange}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent minW="360px">
                        <PopoverBody>
                          <DateRange
                            ranges={customDateRange}
                            maxDate={new Date()}
                            onChange={(item: IDateRangeItem) => {
                              setCustomDateRange([item.selection]);

                              updateUserEventTimerange(
                                filter,
                                item.selection.startDate.toISOString(),
                                item.selection.endDate.toISOString(),
                              );
                            }}
                            moveRangeOnFirstSelection={false}
                            showDateDisplay={false}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  )}
                </Stack>
              </RadioGroup>
              <Button
                onClick={onDone}
                borderRadius="md"
                colorScheme="purple"
                variant="ghost"
                w="full"
                borderTopRadius="none"
                borderTop="1px solid"
                borderColor="gray.200"
                pt={0.5}
              >
                Done
              </Button>
            </MenuList>
          </Menu>
        )}
        {canRemove && (
          <Tooltip label="Remove filter" placement="top" hasArrow>
            <div
              onClick={onRemoveEvent}
              className="flex h-full cursor-pointer rounded-r-md bg-gray-50 p-3 text-gray-500 hover:bg-gray-100 hover:text-black"
            >
              <Icon iconType="icon" name="close" h={2} w={2} />
            </div>
          </Tooltip>
        )}
      </div>
    </Tooltip>
  );
};
