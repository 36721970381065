export const USAGE_BASED_PRICING = "usage-based-pricing";
export const TIME_ZONES = "time-zones";
export const NEW_EXPLORER = "new-explorer";
export const SMART_TRAITS_MANUAL_COMPUTE = "smart-traits-manual-compute";
export const NOT_MIGRATED = "not-migrated";
export const AI_SUMMARY = "ai-summary";
export const CRM_RELIABILITY = "crm-reliability";
export const FAVOURITES = "favourites";
export const GENEROUS_MAU_LIMIT = "generous-mau-limit";
export const FIVE_MINUTE_CRM_SYNC = "five-minute-crm-sync";
export const SALESFORCE = "salesforce";
export const UNIFIED_PRICING = "unified-pricing";
export const PAYWALLED = "paywalled";
export const INSIGHTS_API = "insights-api";
export const REVERSE_SYNC = "reverse-sync";
export const AUDIENCE_VIEW_INSIGHTS = "audience-view-insights";
export const NEW_COMPANY_PROFILE = "new-company-profile";
export const STRIPE_INTEGRATION = "stripe-integration";
export const NEW_PROFILE = "new-profile";
export const KILL_REPORTS = "kill-reports";
export const PROFILE_SHARING = "profile-sharing";
export const NO_REPORTS = "no-reports";

// DO NOT REMOVE
// The following feature flags override the paywall
export const HUBSPOT = "hubspot";
export const ATTIO = "attio";
export const SMART_TRAITS = "smart-traits";
export const FUNNEL_SETUP = "funnel-setup";
export const FIRST_TOUCHPOINT = "first-touchpoint";
