import { Button, Flex, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Link } from "react-router-dom";

import { Icon } from "core/design-system/components/Icon";
import { validateEmail } from "core/helpers/validateEmail";
import { useToast } from "core/hooks/useToast";
import axios from "core/initializers/axios";

export const ForgotPassword = () => {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const onSignUp = (event: any) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setError("Please provide a valid email address");
      return;
    }

    axios
      .post(
        `${import.meta.env.VITE_API_HOST}/auth/reset-password/request`,
        {
          email,
        },
        { withCredentials: true },
      )
      .then(() => {
        toast({
          title: "Password reset link sent",
          description: "Check your inbox for a link",
          duration: 6000,
        });
      })
      .catch((error) =>
        toast({
          title: "Error",
          description: error.response.data.error,
          status: "error",
          duration: 6000,
        }),
      );
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", margin: "2rem" }}>
      <div>
        <Flex mb={8} justify="center">
          <Icon w={32} h={16} iconType="logo" name="companyJuneFullLogo" />
        </Flex>
        <Text fontSize="xl" fontWeight="bold" textAlign="center" mb={4}>
          Forgot your password
        </Text>
        {error ? (
          <Flex
            w="400px"
            align="center"
            bg="red.50"
            borderRadius="lg"
            color="red.500"
            px={4}
            py={2}
            mb={4}
          >
            <Icon iconType="icon" name="warning" mr={2} />
            <Text>{error}</Text>
          </Flex>
        ) : undefined}
        <Flex justify="center">
          <form onSubmit={onSignUp}>
            <div>
              <Input
                size="lg"
                width="448px"
                placeholder="email"
                type="text"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <Flex direction="column" mt={4}>
              <Flex pt={8} justify="flex-end">
                <Button colorScheme="black" type="submit" size="md">
                  Continue
                </Button>
              </Flex>
              <Flex justify="flex-end" pt={2}>
                <Text mr={1}>or</Text>
                <Link to="/login">
                  <Text color="primary">back to login</Text>
                </Link>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </div>
    </div>
  );
};
