import InsightCard from "core/components/InsightCard/Index";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import DauMau from "core/modules/reports/report-types/ActiveUsers/DauMau";
import { dauMauMockData } from "core/modules/reports/report-types/ActiveUsers/mockData";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

export const DauMauContainer = ({
  report,
  previewMode,
  sharingMode,
  screenshotMode,
  config,
  sharingSecretToken,
}: IReportInsight) => {
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "dau-mau",
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      insight,
      sharingSecretToken,
      previewMode,
      skip: !isSetupValid,
    });

  const data = previewMode ? dauMauMockData : response?.data;

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            tooltipText="Percentage of monthly users that use the product on a daily basis"
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          />
          <Card.Body
            isLoading={!data || isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            <DauMau
              data={data}
              previewMode={previewMode}
              sharingMode={sharingMode}
            />
          </Card.Body>
        </Card.Container>
      )}
    </InsightCard>
  );
};
