import React from "react";

import { IRetentionInsightUser } from "core/modules/reports/report-types/Retention/ChurningUsersContainer";
import { pluralize } from "helpers/pluralize";

export const UsedOverCell: React.FC<{
  props: IRetentionInsightUser;
  previewMode: boolean;
}> = ({ props, previewMode }) => {
  return (
    <div className="text-sm">
      {props.numberOfActiveIntervals}{" "}
      {pluralize(
        Number(props.numberOfActiveIntervals),
        props.intervalType,
        `${props.intervalType}s`,
      )}
    </div>
  );
};
