import { Spinner } from "@/Components/ui/spinner";
import { cn } from "@/lib/utils";
import { CompanyJuneLogoIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";

export const JuneSpinner: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div className={cn("relative", className)}>
      <div className="absolute left-[13px] top-[6px] z-docked rounded-full bg-white">
        <Spinner size="extraSmall" color="purple" className="relative" />
      </div>
      <div className="absolute left-[14px] top-[6px] h-[18px] w-[18px] rotate-[80deg] transform bg-[#fff]" />
      <CompanyJuneLogoIcon color="#6968F4" h={10} w={10} />
    </div>
  );
};
