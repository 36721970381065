import { Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import { ErrorPage } from "Components/Error/Page";
import { Loader } from "Components/Loader";
import { useGetViewInsightQuery } from "core/models/viewInsights";
import { AudienceViewInsightContainer } from "modules/ViewInsight/AudienceViewInsightContainer";
import { PublicViewInsight } from "modules/ViewInsight/PublicViewInsight";

export const PublicViewInsightScreenshotRoute: React.FC = () => {
  const { appId, viewId, viewInsightId, groupId, sharingSecretToken } =
    useParams<{
      [key: string]: string;
    }>() as {
      appId: string;
      viewId: string;
      viewInsightId: string;
      groupId: string;
      sharingSecretToken: string;
    };

  const {
    data: viewInsight,
    isLoading,
    isFetching,
    error,
  } = useGetViewInsightQuery({
    appId: Number(appId),
    id: Number(viewInsightId),
    viewId: Number(viewId),
    sharingSecretToken,
    groupId,
  });

  if (isLoading || isFetching) return <Loader />;
  if (error) {
    return (
      <ErrorPage
        title="You don't have permissions to view this insight"
        action={{
          text: "Log in",
          href: `${import.meta.env.VITE_APP_HOST}/login`,
        }}
      />
    );
  }

  if (viewInsight) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Grid templateColumns={`repeat(3, 1fr)`} gridGap={5}>
          <div className="p-5" id="downloadable-image">
            {viewInsight.audience ? (
              <GridItem
                w="full"
                rowSpan={3}
                colSpan={3}
                minW="915px"
                maxW="915px"
                minH="600px"
              >
                <AudienceViewInsightContainer
                  viewInsight={viewInsight}
                  isLoading={isLoading}
                  isFetching={isFetching}
                />
              </GridItem>
            ) : (
              <PublicViewInsight
                key={viewInsight.id}
                viewInsight={viewInsight}
                sharingSecretToken={sharingSecretToken}
                isAI={Boolean(viewInsight.queryId)}
              />
            )}
          </div>
        </Grid>
      </div>
    );
  }

  return <ErrorPage />;
};
