import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import moment from "moment";

import GoalBadge from "core/modules/reports/Achievements/GoalBadge";
import { Goal } from "core/modules/reports/Achievements/types";
import { getGoalName } from "core/modules/reports/Achievements/utils";

interface BadgeCardProps extends BoxProps {
  goal: Goal;
  unit: string;
  isCompanyLevel: boolean;
  timestamp: string;
}

export const BadgeCard: React.FC<BadgeCardProps> = ({
  goal,
  unit,
  isCompanyLevel,
  timestamp,
  ...props
}) => {
  return (
    <Box
      mt="-1px"
      px={4}
      py={0}
      w="full"
      border="1px solid"
      rounded="md"
      borderColor="gray.200"
      {...props}
    >
      <Flex align="center" justifyContent="space-between">
        <Flex align="center">
          <GoalBadge
            marginTop={2}
            marginRight={2}
            isCompanyLevel={isCompanyLevel}
            goal={goal}
            width={"30px"}
          />
          <Text fontSize="md" fontWeight="md">
            {getGoalName(goal, unit)}
          </Text>
        </Flex>
        <Flex>
          <Text fontSize="sm" fontWeight="md" color="gray.600">
            {moment(timestamp).format("Do MMM, YYYY [at] h:mm a")}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default BadgeCard;
