import React from "react";

import { ILabel } from "core/types/Label";
import { IReport } from "core/types/Report";
import { ReportLabelsBadge } from "modules/Reports/List/ReportLabelsBadge";

interface IReportProps extends IReport {
  setSelectedLabels: (labels: ILabel[]) => void;
  selectedLabels: ILabel[];
}

interface IReportLabelsCellProps {
  props: IReportProps;
}

export const ReportLabelsCell: React.FC<IReportLabelsCellProps> = ({
  props,
}) => {
  const { labels, setSelectedLabels } = props;

  return (
    <ReportLabelsBadge setSelectedLabels={setSelectedLabels} labels={labels} />
  );
};
