import { Box, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import { IReport } from "core/types/Report";
import { EditedByUser } from "modules/Report/Setup/EditedByUser";

interface IReportNameCellProps {
  props: IReport;
}

export const ReportNameCell: React.FC<IReportNameCellProps> = ({ props }) => {
  const { appId, id, name, createdAt, savedAt, lastUpdatedBy, createdBy } =
    props;

  return (
    <Link to={`/a/${appId}/report/${id}`}>
      <Flex align="center">
        <Flex align="flex-start" direction="column">
          <Box>
            <Text
              fontSize="sm"
              _hover={{ color: "primary" }}
              fontWeight="medium"
              maxW="600px"
              mb={1}
            >
              {name}
            </Text>
            {lastUpdatedBy && savedAt ? (
              <EditedByUser
                user={lastUpdatedBy}
                timestamp={savedAt}
                action="Updated"
                showAvatar={false}
              />
            ) : createdBy && createdAt ? (
              <EditedByUser
                user={createdBy}
                timestamp={createdAt}
                action="Created"
                showAvatar={false}
              />
            ) : (
              <Text color="gray.600" fontSize="xs">
                Created {moment.utc(createdAt).fromNow()}
              </Text>
            )}
          </Box>
        </Flex>
      </Flex>
    </Link>
  );
};
