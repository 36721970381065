import { ComponentDefaultProps } from "@chakra-ui/react";

import { CardHeader } from "@/Components/ui/card";

export const Header: React.FC<ComponentDefaultProps> = ({
  children,
  ...props
}) => {
  return (
    <CardHeader
      className="max-w-full px-3 pb-0 pt-3"
      data-testid="view-insight-card-header"
      {...props}
    >
      {children}
    </CardHeader>
  );
};
