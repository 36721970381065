import { Tooltip } from "@chakra-ui/react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/16/solid";
import React from "react";

const LONG_VALUE_LIMIT = 32;

export const LongValueWrapper = (props: { value: string }) => {
  if (props.value.length > LONG_VALUE_LIMIT) {
    return (
      <Tooltip
        label={props.value}
        placement="top"
        fontSize="xs"
        px={2}
        bg="white"
        color="gray.800"
        border="1px solid"
        borderColor="gray.200"
        shadow="none"
      >
        <span>{props.value.slice(0, LONG_VALUE_LIMIT)}...</span>
      </Tooltip>
    );
  }

  if (props.value === "true") {
    return <CheckCircleIcon className="h-4 w-4 text-green-400" />;
  } else if (props.value === "false") {
    return <XCircleIcon className="h-4 w-4 text-red-400" />;
  }
  return <>{props.value}</>;
};
