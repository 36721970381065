import { Center, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Loader } from "Components/Loader";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { baseUrl } from "core/constants/paywall";
import { getReportConfig } from "core/helpers/getReportConfig";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useReportSetup } from "core/hooks/useReportSetup";
import { getLevelParam, isUserReportLevel } from "core/modules/reports/utils";
import { IReport } from "core/types/Report";
import { storeLocalViewedReportRecord } from "modules/Discover/Cards/MostRecentlyViewedReports";
import { ReportLayoutContainer } from "modules/Report/LayoutContainer";

const Report = () => {
  const { appId, reportId } = useParams<{
    appId: string;
    reportId: string;
  }>() as {
    appId: string;
    reportId: string;
  };

  const dispatch = useDispatch();
  const { currentReport, isUpdating, isLoading, fetchReport, serializeKey } =
    useReportSetup();

  const [report, setReport] = useState<IReport | null>(null);

  const navigate = useNavigate();

  const paramReportLevel = getLevelParam();
  const isUserLevel = isUserReportLevel();
  if (!paramReportLevel) {
    navigate(`/a/${appId}/report/${reportId}?level=user`);
  }

  const currentApp = useCurrentApp();

  const handleClosePaywallModal = () => {
    navigate(`/a/${appId}/reports`);
  };

  if (report) {
    document.title = `June - ${report.name ? report.name : ""}`;
  }

  useEffect(() => {
    fetchReport();
    setReport(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appId, reportId]);

  useEffect(() => {
    if (currentReport?.id && currentReport?.id.toString() === reportId) {
      setReport(currentReport as IReport);
    }
  }, [appId, reportId, currentReport]);

  if (isUpdating || isLoading) return <Loader />;

  if ((!isLoading && !report) || report?.reportType === undefined) {
    return (
      <Center w="100vw" h="100vh">
        <Text fontSize="lg">This report does not exist.</Text>
      </Center>
    );
  }

  if (!currentApp) return null;

  const config = getReportConfig(report.reportType)!;
  const redirectUrl = `${baseUrl}/a/${currentApp.id}/report/${reportId}`;

  storeLocalViewedReportRecord(appId, report);

  if (
    !currentApp?.isPaying &&
    !currentApp?.isTrialing &&
    !currentApp?.isPaymentOverdue &&
    !isUserLevel
  )
    return (
      <PaywallModal redirectUrl={redirectUrl}>
        {(paywallModal: IPaywallModalChildren) => (
          <paywallModal.Modal isOpen onClose={handleClosePaywallModal} />
        )}
      </PaywallModal>
    );

  return (
    <ReportLayoutContainer
      key={serializeKey}
      report={report}
      templateConfig={config}
    />
  );
};

export default Report;
