import { createIcon } from "@chakra-ui/icons";
import React from "react";

const InstantIcon = createIcon({
  path: (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0974 15.4324L17.0827 27.0097C16.3481 28.0697 14.6854 27.5511 14.6854 26.2604V18.6297H7.98539C6.92405 18.6297 6.29872 17.4377 6.90405 16.5644L14.9187 4.98707C15.6534 3.92707 17.3161 4.44574 17.3161 5.73641V13.3671H24.016C25.076 13.3671 25.7014 14.5591 25.0974 15.4324Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});

export default InstantIcon;
