import { StyleProps } from "@chakra-ui/react";

import avatars from "core/design-system/components/Icon/avatars";

interface IAvatarIcon extends StyleProps {
  name: string;
}

export const AvatarIcon = ({ name, ...props }: IAvatarIcon) => {
  const iconSvg = avatars[name as keyof typeof avatars];

  const IconComponent = iconSvg;
  return <IconComponent {...props} />;
};
