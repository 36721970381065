import { Grid, GridItem, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";

import { Error } from "core/components/Error";
import InsightCard from "core/components/InsightCard/Index";
import { HeaderInsightStat } from "core/design-system/components/HeaderInsightStat";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { mockNewGroupsChangesData } from "core/modules/reports/report-types/NewGroups/mockData";
import { NewGroupsList } from "core/modules/reports/report-types/NewGroups/NewGroupsList";
import { IReportInsight } from "core/types/Report";

type INewGroupsChangesData = {
  count: number;
  insightChange: number;
  date: string;
  humanizedDate: string;
}[];

export const HeaderInsight: React.FC<IReportInsight> = ({
  report,
  config,
  sharingMode,
  previewMode,
  screenshotMode,
  sharingSecretToken,
}) => {
  const {
    isOpen: isAudiencePanelOpen,
    onOpen: onAudienceOpen,
    onClose: onAudienceClose,
  } = useDisclosure();
  const insight = config.insights.find(
    ({ slug }) => slug === "latest-companies",
  );
  const isSetupValid = config.validateSetup(report.config);
  const [humanizedDate, setHumanizedDate] = useState("");
  const [date, setDate] = useState("");
  const [intervalType, setIntervalType] = useState(0);
  const [newGroupsCount, setNewGroupsCount] = useState(0);
  const insightType = 7;

  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      previewMode,
      insightType,
      insightParams: {
        intervalType: 0,
      },
      sharingSecretToken,
      skip: !isSetupValid,
    });

  if (!insight) return null;

  if (error) {
    return (
      <InsightCard>
        {(Card) => (
          <Card.Container id="adoption-error">
            <Error
              position="relative"
              w="100%"
              h="200px"
              background="white"
              p={16}
              borderRadius="lg"
            />
          </Card.Container>
        )}
      </InsightCard>
    );
  }

  const data = previewMode
    ? mockNewGroupsChangesData
    : (response?.data as INewGroupsChangesData);
  const usageIntervals = [
    { interval: "day", currentPeriod: "today", comparisonPeriod: "yesterday" },
    {
      interval: "week",
      currentPeriod: "this week",
      comparisonPeriod: "last week",
    },
    {
      interval: "month",
      currentPeriod: "this month",
      comparisonPeriod: "last month",
    },
  ];

  const onButtonClick = (intervalType: number) => {
    if (!sharingMode && !previewMode) {
      onAudienceOpen();
      setIntervalType(intervalType);
      setDate(data[intervalType].date);
      setHumanizedDate(data[intervalType].humanizedDate);
      setNewGroupsCount(data[intervalType].count);
    }
  };

  return (
    <>
      <InsightCard>
        {(Card) => (
          <Card.Container id={insight.slug} insight={insight}>
            <Card.Header
              hasCaching={response?.hasCaching}
              screenshotMode={screenshotMode}
              showReportTitle={sharingMode}
              insight={insight}
              showActions={!sharingMode && !previewMode}
              sharingMode={sharingMode}
              config={config}
              report={report}
              refresh={onRefreshInsight}
              cachedAt={response?.cachedAt}
            />
            <Card.Body
              isLoading={!data || isLoading || isFetching}
              isPreviewMode={previewMode}
              isSetupValid={isSetupValid}
              error={error}
            >
              <Grid templateColumns="repeat(3, 1fr)" id="loaded">
                {data?.map((changes, index: number) => (
                  <GridItem>
                    <HeaderInsightStat
                      key={index}
                      label={`New ${usageIntervals[index].currentPeriod}`}
                      mainCount={changes.count}
                      isLoading={isLoading || isFetching}
                      percentChange={changes.insightChange}
                      changeText={`vs ${usageIntervals[index].comparisonPeriod}`}
                      changeTooltip={`This time ${usageIntervals[index].comparisonPeriod}`}
                      firstStat={index === 0}
                      lastStat={index === usageIntervals.length - 1}
                      buttonText={"View"}
                      onButtonClick={() => onButtonClick(index)}
                    />
                  </GridItem>
                ))}
              </Grid>
            </Card.Body>
          </Card.Container>
        )}
      </InsightCard>
      {!sharingMode && !previewMode && (
        <NewGroupsList
          report={report}
          isOpen={isAudiencePanelOpen}
          onClose={onAudienceClose}
          humanizedDate={humanizedDate}
          date={date}
          newGroupsCount={newGroupsCount}
          intervalType={intervalType}
        />
      )}
    </>
  );
};
