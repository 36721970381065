import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Center,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";

import { CompanyJuneLogoIcon } from "@/core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { ErrorAction } from "@/core/types/Error.d";

interface ErrorPageProps extends BoxProps {
  title: string;
  action: ErrorAction;
}

export const ErrorPage = ({ title, action, ...props }: ErrorPageProps) => {
  return (
    <Center bg="gray.100" h="100vh" w="100%" data-testid="error">
      <Box w="500px" textAlign="center" {...props}>
        <CompanyJuneLogoIcon mb={8} h="50px" w="50px" />
        <Text
          lineHeight="1.2"
          color="#2A2A63"
          fontSize="6xl"
          fontWeight="extrabold"
        >
          {title}
        </Text>
        <ButtonGroup mt={10} spacing="6">
          {action.href ? (
            <Button
              as="a"
              href={action.href}
              borderColor="gray.400"
              bg="transparent"
              size="lg"
            >
              {action.text}
            </Button>
          ) : (
            <Button
              onClick={action.onClick}
              borderColor="gray.400"
              bg="transparent"
              size="lg"
            >
              {action.text}
            </Button>
          )}
          <Button
            onClick={() => window.Intercom("show")}
            _hover={{ bg: "#8686F9" }}
            color="white"
            bg="#6868F7"
            size="lg"
          >
            Chat with us
          </Button>
        </ButtonGroup>
      </Box>
    </Center>
  );
};

ErrorPage.propTypes = {
  title: PropTypes.string,
  action: PropTypes.object,
};

ErrorPage.defaultProps = {
  title: "Something went wrong on our side",
  action: {
    text: "Reload",
    href: null,
    onClick: () => window.location.reload(),
  },
};
