import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useGetViewInsightQuery } from "@/core/models/viewInsights";
import { JuneLoadingPage } from "@/modules/Dashboard/LoadingPage";
import { InsightEditorContainer } from "@/modules/Dashboard/SQLBuilder/EditorContainer";
import { useNavigation } from "core/hooks/useNavigation";

export const EditSQLRoute: React.FC = () => {
  const { appId, viewId, viewInsightId } = useParams();
  const { navigation, toggleSidebar } = useNavigation();

  const { data: viewInsight, isLoading } = useGetViewInsightQuery({
    appId: Number(appId),
    viewId: Number(viewId),
    id: Number(viewInsightId),
  });

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
  }, [navigation.isOpen, toggleSidebar]);

  if (isLoading || !viewInsight) return <JuneLoadingPage />;

  return (
    <div className="h-screen w-full overflow-hidden">
      <InsightEditorContainer viewInsight={viewInsight} />
    </div>
  );
};
