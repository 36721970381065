import React from "react";

import { LineContainer } from "core/components/LineContainer";
import {
  DAU_OPTIONS,
  INTERVAL_TYPES,
  SEVEN_DAYS,
  getTimeRangeFromValue,
} from "core/constants/timerange";
import { useQueryParam } from "core/hooks/useQueryParam";
import { dauMockData } from "core/modules/reports/report-types/ActiveUsers/mockData";
import NewActiveUsersLineChart from "core/modules/reports/report-types/ActiveUsers/NewActiveUsersLineChart";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

export const DauContainer: React.FC<IReportInsight> = ({
  config,
  additionalConfigProps,
  showInProgress,
  viewInsight,
  insightConfig,
  headless,
  ...props
}) => {
  const insight =
    insightConfig ||
    (config.insights.find(
      ({ slug }: { slug: string }) => slug === "dau",
    ) as IInsightSection);

  const timerangeParam = useQueryParam("insight_timerange");
  const initialTimeRange = viewInsight?.timerangeValue
    ? getTimeRangeFromValue(viewInsight.timerangeValue)
    : timerangeParam
      ? getTimeRangeFromValue(parseInt(timerangeParam, 10))
      : SEVEN_DAYS;

  return (
    <LineContainer
      LineChart={NewActiveUsersLineChart}
      insight={insight}
      initialTimeRange={initialTimeRange.value}
      timeRangeOptions={DAU_OPTIONS}
      intervalType={INTERVAL_TYPES.DAY}
      keyAccessor={"data"}
      intervalPeriod={"ONE_DAY"}
      mockData={dauMockData}
      config={config}
      additionalConfigProps={additionalConfigProps}
      showInProgress={showInProgress}
      {...props}
      headless={headless}
      report={props.report || viewInsight.report}
    />
  );
};
