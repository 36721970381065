import { CurrentPeriodLineChart } from "core/modules/reports/report-types/ActiveUsers/CurrentPeriodLineChart";
import { splitCurrentPeriodAndFormatData } from "core/modules/reports/report-types/ActiveUsers/lineChartHelpers";
import { ISticknessRatioData } from "core/types/LineChart";

const DauWau = ({
  data,
  previewMode,
  sharingMode,
}: {
  data: ISticknessRatioData;
  previewMode: boolean;
  sharingMode: boolean;
}) => {
  const [startOfWeek, formattedData] = splitCurrentPeriodAndFormatData(
    data.data,
  );

  return (
    <div className="flex flex-col rounded-lg bg-white">
      <div className="flex flex-col justify-center rounded-t-lg">
        {data?.data && (
          <CurrentPeriodLineChart
            unit="% DAU / WAU"
            graphId="DAU / WAU"
            data={formattedData}
            currentPeriodReferenceLineValue={startOfWeek}
            currentPeriodTooltipText="The ratio is incomplete because the WAU for the current week changes every day."
            withDrawer={false}
            previewMode={previewMode}
            sharingMode={sharingMode}
          />
        )}
      </div>
    </div>
  );
};

export default DauWau;
