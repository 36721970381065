import { Button } from "@chakra-ui/react";
import { motion } from "framer-motion";

import { IBlockComponent } from "core/components/AI/ConversationContainer";

export const Action: React.FC<IBlockComponent> = ({
  delay,
  isReverse,
  children,
  ...props
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: isReverse ? -20 : 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{
        duration: 0.8,
        delay: delay / 2,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      exit={{ opacity: 0, x: isReverse ? -20 : 20 }}
    >
      <Button colorScheme="purple" {...props}>
        {children}
      </Button>
    </motion.div>
  );
};
