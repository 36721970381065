import { ClockIcon } from "@heroicons/react/24/outline";
import moment from "moment";

import { TraitIcon } from "core/components/Traits/TraitIcon";
import { Icon } from "core/design-system/components/Icon";
import { AvatarIcon } from "core/design-system/components/Icon/AvatarIcon";
import {
  generateRandomAnimalName,
  getContactAvatar,
  hashStringToNumber,
  IGetContactAvatarProps,
} from "core/helpers/contactAvatar";
import { getContactEmail } from "core/helpers/contactEmail";
import { IContact } from "core/types/Contact";

interface IUserPreview {
  user: IContact;
}

export const UserPreview: React.FC<IUserPreview> = ({ user }) => {
  const avatarURL = getContactAvatar(user as IGetContactAvatarProps);
  const userId =
    typeof user?.id === "number" ? user?.id : hashStringToNumber(user?.id);
  const email = getContactEmail(user as IContact);

  return (
    <div className="hidden h-112 w-1/2 flex-none flex-col overflow-y-auto py-4 sm:flex">
      <div className="flex-none p-6 text-center">
        {avatarURL ? (
          <img
            src={avatarURL}
            alt=""
            className="mx-auto h-16 w-16 rounded-full"
          />
        ) : (
          <AvatarIcon h={16} w={16} name={generateRandomAnimalName(userId)} />
        )}
        <h2 className="mt-3 text-lg font-semibold text-gray-900">{email}</h2>
      </div>
      <div className="flex flex-auto flex-col justify-between px-6">
        <table className="w-full table-fixed">
          <tbody>
            <tr>
              <td className="w-1/2 truncate py-1 text-sm text-gray-600">
                <div className="flex items-center gap-1">
                  <ClockIcon className="h-4" />
                  First seen
                </div>
              </td>
              <td className="w-1/2 truncate py-1 text-sm">
                {user?.firstSeenAt
                  ? moment.utc(user?.firstSeenAt).format("DD MMM, YYYY")
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td className="w-1/2 truncate py-1 text-sm text-gray-600">
                <div className="flex items-center gap-1">
                  <ClockIcon className="h-4" />
                  Last seen
                </div>
              </td>
              <td className="w-1/2 truncate py-1 text-sm">
                {user?.lastSeenAt
                  ? moment.utc(user?.lastSeenAt).format("DD MMM, YYYY")
                  : "N/A"}
              </td>
            </tr>
            {Object.entries(user?.traits)
              .slice(0, 4)
              .map(([key, value]) => {
                return (
                  <>
                    <tr>
                      <td className="w-1/2 py-1 text-sm text-gray-600">
                        <div className="flex items-center gap-1">
                          <TraitIcon traitName={key} color="text-gray-600" />
                          <div className="w-5/6 truncate">{key}</div>
                        </div>
                      </td>
                      <td className="w-1/2 truncate py-1 text-sm">{value}</td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
        <button
          type="button"
          className="mt-6 w-full rounded-md bg-purple-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          View user
          <Icon name="enter" iconType="icon" ml={2} />
        </button>
      </div>
    </div>
  );
};
