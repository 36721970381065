import { Button, ComponentDefaultProps, Tooltip } from "@chakra-ui/react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";

import SelectMenu from "core/components/Select";
import { ANY, FIRST } from "core/constants/events";
import { WrapArrow } from "core/modules/reports/setup/Editor/WrapArrow";
import { IEvent } from "core/types/Event";
import { ITemplateConfigSetupSection } from "core/types/TemplateConfig";

interface IFilterSelectMenuProps extends ComponentDefaultProps {
  event: IEvent;
  setPropertyFilterEventId: (propertyFilterEventId: string) => void;
  setFrequencyFilterEventId: (frequencyFilterEventId: string) => void;
  section: ITemplateConfigSetupSection;
}

export const FilterSelectMenu: React.FC<IFilterSelectMenuProps> = ({
  event,
  setPropertyFilterEventId,
  setFrequencyFilterEventId,
  setBreakdownFilterEventId,
  section,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  if (event.type === ANY || event.type === FIRST) return null;

  return (
    <SelectMenu>
      {(Select) => (
        <Select.Container isOpen={isOpen} returnFocusOnClose={false}>
          <Tooltip label="Add a filter" shouldWrapChildren hasArrow>
            <div className="relative flex items-center">
              <WrapArrow direction="left" position="20" />
              <Select.Button
                data-testid="event-filter-select-menu"
                rightIcon={undefined}
                border="none"
                bg="gray.50"
                _hover={{ bg: "gray.100" }}
                color="gray.900"
                onClick={() => setIsOpen(!isOpen)}
                px={3}
                showChevron={false}
                {...props}
              >
                <AdjustmentsHorizontalIcon style={{ height: "15px" }} />
              </Select.Button>
            </div>
          </Tooltip>
          <Select.Body w="150px">
            <Button
              data-testid="event-filter-property-button"
              onClick={() => {
                if (!event.uuid) return;
                setPropertyFilterEventId(event.uuid);
                setIsOpen(false);
              }}
              variant="ghost"
              w="full"
              borderRadius={0}
              justifyContent="flex-start"
            >
              Add a property
            </Button>
            {section?.eventOccurrences && (
              <Button
                data-testid="event-filter-frequency-button"
                onClick={() => {
                  if (!event.uuid) return;
                  setFrequencyFilterEventId(event.uuid);
                  setIsOpen(false);
                }}
                variant="ghost"
                w="full"
                borderRadius={0}
                justifyContent="flex-start"
              >
                Add a frequency
              </Button>
            )}
          </Select.Body>
        </Select.Container>
      )}
    </SelectMenu>
  );
};
