import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  ComponentDefaultProps,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { InlineWidget } from "react-calendly";
import { Link, useParams } from "react-router-dom";

import ConnectDataSourceIllustration from "core/design-system/components/Icon/Illustrations/ConnectDataSourceIllustration.svg";
import IdentifyUsersIllustration from "core/design-system/components/Icon/Illustrations/IdentifyUsersIllustration.svg";
import TrackBehavioursIllustration from "core/design-system/components/Icon/Illustrations/TrackBehavioursIllustration.svg";
import { CompanyJuneLogoIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { IOnboardingChecklist } from "core/models/onboardingChecklist";
import { OnboardingCheck } from "modules/Onboarding/SetupSources/OnboardingCheck";

interface IStep {
  title: string;
  description: string;
  checklistKey: string;
  illustration: string;
}

const REQUIRED_STEPS = [
  {
    title: "Connect a data source",
    description: "Start sending usage data to June",
    checklistKey: "connectedDataSource",
    illustration: ConnectDataSourceIllustration,
  },
  {
    title: "Identify your users",
    description: `Know who’s using your product`,
    checklistKey: "isIdentifyingUsers",
    illustration: IdentifyUsersIllustration,
  },
  {
    title: "Track behaviours",
    description: "Know what users do in your product",
    checklistKey: "isSendingEvents",
    illustration: TrackBehavioursIllustration,
  },
] as IStep[];

const Step: React.FC<{ step: IStep; isComplete: boolean; appId: string }> = ({
  step,
  isComplete,
  appId,
}) => {
  return (
    <Flex
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
      direction="column"
    >
      <Flex
        px={3}
        py={2}
        align="center"
        gridGap={2}
        justifyContent="space-between"
      >
        <Flex align="center">
          <OnboardingCheck isComplete={isComplete} />
          <Flex direction="column">
            <Text fontSize="sm" fontWeight="medium">
              {step.title}
            </Text>
            <Text fontSize="xs" color="gray.500">
              {step.description}
            </Text>
          </Flex>
        </Flex>
        <Flex>
          <Button
            as={Link}
            to={`/a/${appId}/getting-started`}
            colorScheme="purple"
            variant="ghost"
          >
            <ArrowForwardIcon />
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <Image src={step.illustration} />
      </Flex>
    </Flex>
  );
};

function getBooleanKeyValuePairs(onboardingChecklist: IOnboardingChecklist) {
  const result = {} as { [key: string]: boolean };
  Object.entries(onboardingChecklist).forEach(([key, value]) => {
    if (typeof value === "boolean") {
      result[key] = value;
    }
  });
  return result;
}

export const GettingStartedBanner: React.FC<ComponentDefaultProps> = ({
  ...props
}) => {
  const { appId } = useParams<{ appId: string }>() as {
    appId: string;
  };
  const { business } = useCurrentApp();
  const { onboardingChecklist } = useOnboardingChecklist();
  const isB2B = business === "b2b";
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!onboardingChecklist) return null;

  const checklist = getBooleanKeyValuePairs(onboardingChecklist) as unknown as {
    [key: string]: boolean;
  };

  return (
    <Center>
      <Flex
        bottom="20px"
        pos="fixed"
        bg="white"
        borderRadius="lg"
        justifyContent="space-between"
        align="center"
        px={4}
        py={3.5}
        boxShadow="md"
        direction="column"
        border="1px solid"
        borderColor="gray.100"
        gridGap={4}
        w="container.lg"
        mx="auto"
        {...props}
      >
        <Flex w="full" align="center" justifyContent="space-between">
          <Flex gridGap={3} align="center">
            {isB2B ? (
              <Image
                src="https://june-changelog.s3.eu-central-1.amazonaws.com/dscf5588_2_6dcd0ce29c.jpg"
                h="40px"
                w="40px"
                borderRadius="full"
              />
            ) : (
              <CompanyJuneLogoIcon h="35px" w="35px" />
            )}
            <Flex direction="column">
              <Text
                color={isB2B ? "gray.600" : "gray.700"}
                fontSize={isB2B ? "xs" : "sm"}
                fontWeight="medium"
              >
                {isB2B ? "Alberto from June 👋" : `You’re almost there!`}
              </Text>
              <Text fontSize="sm" color="gray.700">
                You're just a few steps away from getting the most out of June,
                or{" "}
                <Text
                  cursor="pointer"
                  as="span"
                  onClick={onOpen}
                  color="purple.500"
                >
                  chat with me
                </Text>
              </Text>
            </Flex>
          </Flex>
          <Flex>
            <Button
              as={Link}
              to={`/a/${appId}/getting-started`}
              colorScheme="purple"
              rightIcon={<ArrowForwardIcon />}
            >
              Get started
            </Button>
          </Flex>
        </Flex>
        <Flex w="full" justifyContent="space-between" gridGap={4}>
          {REQUIRED_STEPS.map((step, index) => (
            <Step
              key={index}
              step={step}
              isComplete={checklist[step.checklistKey]}
              appId={appId}
            />
          ))}
        </Flex>
      </Flex>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Book a call</ModalHeader>
          <ModalBody>
            <InlineWidget url="https://calendly.com/june-420/june-onboarding?hide_event_type_details=1&hide_landing_page_details=1&hide_gdpr_banner=1" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Center>
  );
};
