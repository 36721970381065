import {
  Box,
  Flex,
  HStack,
  Image,
  Progress,
  Skeleton,
  Spacer,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useMemo } from "react";

import HelpCircle from "core/design-system/components/Icon/Interface/HelpCircle";
import BadgeProgression from "core/modules/reports/Achievements/BadgeProgression";
import GoalTooltip from "core/modules/reports/Achievements/GoalTooltip";
import {
  ProgressionState,
  Track,
} from "core/modules/reports/Achievements/types";
import {
  getCompletionState,
  getGoalName,
  getHelpText,
} from "core/modules/reports/Achievements/utils";

export const TrackProgress: React.FC<{
  isLoading: boolean;
  metricText: string;
  track: Track;
  state: ProgressionState;
  unit: string;
  isCompanyLevel: boolean;
  onAnimationFinish?: () => void;
}> = ({
  isLoading,
  metricText,
  track,
  state,
  unit,
  isCompanyLevel,
  onAnimationFinish,
}) => {
  const {
    level,
    currentGoal,
    nextGoal,
    percentComplete,
    isFinished: isTrackFinished,
  } = state[track];

  const isGameFinished = useMemo(() => getCompletionState(state), [state]);

  return (
    <Flex direction="column" alignItems="left">
      <HStack alignItems="start">
        <Flex align="center" w="full">
          <Text fontWeight="600" color="gray.600" fontSize="sm">
            Overall {track}
          </Text>
          <Tooltip
            px={2}
            py={1}
            label={getHelpText(track, unit)}
            placement="right"
            borderRadius="lg"
          >
            <HelpCircle h={4} w={4} color="gray.600" ml={1} />
          </Tooltip>
        </Flex>
        <HStack>
          {!isLoading ? (
            <BadgeProgression
              badgeProps={{ width: 8 }}
              track={track}
              currentLevel={level}
              isCompanyLevel={isCompanyLevel}
              onAnimationFinish={onAnimationFinish}
              state={state}
            />
          ) : (
            <Flex h="48px"></Flex>
          )}
        </HStack>
      </HStack>
      <Text mb={4} fontSize="2xl" fontWeight={500} mt={-4}>
        {isLoading ? <Skeleton w={12}>...</Skeleton> : metricText}
      </Text>
      <Flex mb={2}>
        <Tooltip label="Previous goal" placement="top" hasArrow>
          <Text display="block" fontWeight="600" color="gray.500" fontSize="xs">
            {isLoading ? (
              <Skeleton height={4}>X out of XX</Skeleton>
            ) : (
              getGoalName(currentGoal, unit)
            )}
          </Text>
        </Tooltip>
        <Spacer />
        <Tooltip label="Next goal" placement="top" hasArrow>
          <Text display="block" fontWeight="600" color="gray.500" fontSize="xs">
            {isLoading ? (
              <Skeleton height={4}>Goal: X xxxxxx</Skeleton>
            ) : (
              getGoalName(nextGoal, unit)
            )}
          </Text>
        </Tooltip>
      </Flex>
      <Flex alignItems="center">
        {isTrackFinished && !isGameFinished && <Box h="24px"></Box>}
        {!isTrackFinished && (
          <GoalTooltip goal={currentGoal} unit={unit}>
            <Image w="6" h="6" mr={4} src={currentGoal.badgeIcon} />
          </GoalTooltip>
        )}
        <Tooltip
          label={`${percentComplete}% until next goal`}
          placement="top"
          hasArrow
        >
          <Box w="full">
            <Progress
              w="full"
              size="sm"
              colorScheme={track === Track.ADOPTION ? "green" : "purple"}
              rounded="full"
              value={percentComplete}
            />
          </Box>
        </Tooltip>
        {!isTrackFinished && (
          <GoalTooltip goal={nextGoal} unit={unit}>
            <Image w="6" h="6" ml={4} src={nextGoal.badgeIcon} />
          </GoalTooltip>
        )}
      </Flex>
    </Flex>
  );
};
export default TrackProgress;
