import { useAppObjects } from "core/hooks/useAppObjects";
import { Integration } from "core/models/dataMappings";
import { CrmSyncSettingsRow } from "modules/Settings/Crm/SyncSettings/Row";
import { CrmSyncSettingsTable } from "modules/Settings/Crm/SyncSettings/Table";

export const Settings: React.FC = () => {
  const { userAppObject, companyAppObject, groupAppObject } = useAppObjects();
  return (
    <CrmSyncSettingsTable>
      {userAppObject && (
        <CrmSyncSettingsRow
          appObject={userAppObject}
          integration={Integration.Salesforce}
          comingSoon
        />
      )}
      {companyAppObject && (
        <CrmSyncSettingsRow
          appObject={companyAppObject}
          integration={Integration.Salesforce}
          comingSoon
        />
      )}
      {groupAppObject && (
        <CrmSyncSettingsRow
          appObject={groupAppObject}
          integration={Integration.Salesforce}
        />
      )}
    </CrmSyncSettingsTable>
  );
};
