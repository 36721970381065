import { IListConfig } from "core/components/List/List.types";
import { EventType } from "core/constants/report-setup";
import { ActivityStatus } from "modules/Events/List/ActivityStatus";
import { ActivityTooltip } from "modules/Events/List/ActivityTooltip";
import AlertsCell from "modules/Events/List/AlertsCell";
import { EventActionsCell } from "modules/Events/List/EventActionsCell";
import EventFrequencyCell from "modules/Events/List/EventFrequencyCell";
import EventNameCell from "modules/Events/List/EventNameCell";
import { GroupContextStatus } from "modules/Events/List/GroupContextStatus";
import { GroupContextTooltip } from "modules/Events/List/GroupContextTooltip";
import { PageNameCell } from "modules/Events/List/PageNameCell";
import { TotalCount } from "modules/Events/List/TotalCount";
import { UniqueCount } from "modules/Events/List/UniqueCount";

export const trackConfig: IListConfig = {
  resourceName: "events",
  eventType: EventType.TRACK,
  columns: [
    {
      title: "",
      name: "totalCount",
      width: 30,
      Component: EventFrequencyCell,
      align: "right",
    },
    {
      title: "Event name",
      name: "email",
      width: 500,
      Component: EventNameCell,
      align: "left",
    },
    {
      title: "Count",
      name: "totalCount",
      width: 100,
      Component: TotalCount,
      align: "right",
      tooltip: "Number of times the event was triggered",
    },
    {
      title: "User triggered",
      name: "isActive",
      width: 200,
      Component: ActivityStatus,
      align: "right",
      TooltipComponent: ActivityTooltip,
    },
    {
      title: "Group context",
      name: "groupId",
      width: 80,
      Component: GroupContextStatus,
      align: "right",
      TooltipComponent: GroupContextTooltip,
    },
    {
      title: "",
      name: "",
      width: 250,
      maxWidth: 250,
      Component: AlertsCell,
      align: "right",
    },
    {
      title: "",
      name: "",
      width: 250,
      maxWidth: 250,
      Component: EventActionsCell,
      align: "right",
    },
  ],
};

export const pageConfig: IListConfig = {
  resourceName: "events",
  eventType: EventType.PAGE,
  columns: [
    {
      title: "",
      name: "totalCount",
      width: 30,
      Component: EventFrequencyCell,
      align: "right",
    },
    {
      title: "Pageview",
      name: "email",
      width: 910,
      Component: PageNameCell,
      align: "left",
    },
    {
      title: "Total count",
      name: "totalCount",
      width: 250,
      Component: TotalCount,
      align: "right",
      tooltip: "Total number of times this page was visited",
    },
    {
      title: "Visitor count",
      name: "uniqueCount",
      width: 250,
      Component: UniqueCount,
      align: "right",
      tooltip: "Number of unique visitors",
    },
  ],
};
