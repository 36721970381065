import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  ComponentDefaultProps,
} from "@chakra-ui/react";
import React, { useRef } from "react";

interface IConfirmationModal extends ComponentDefaultProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  body: string;
  onConfirmation?: () => void;
}

export const ConfirmationModal: React.FC<IConfirmationModal> = ({
  isOpen,
  onClose,
  onConfirmation,
  title,
  body,
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <>
      <AlertDialog
        leastDestructiveRef={ref}
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody fontSize="sm" py={0}>
            {body}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onConfirmation} colorScheme="purple" ml={3}>
              Yes, I'm sure
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
