import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Code,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";

import { GetStarted } from "@/modules/Settings/Imports/GetStarted";
import { ImportTasks } from "@/modules/Settings/Imports/ImportTasks";
import CustomDateRangeSection from "core/components/CustomDateRangeSection";
import { MIXPANEL } from "core/constants/apiKeys";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useImportTasks } from "core/hooks/useImportTasks";
import { useCreateMixpanelImportTaskMutation } from "core/models/importTasks";
import { ICreateMixpanelImportTask } from "core/types/ImportTask";

export const ImportForm: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
  const currentApp = useCurrentApp();
  const appId = currentApp.id;

  const toast = useToast();

  const [projectId, setProjectId] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [secretKey, setSecretKey] = useState<string>();
  const [regionEU, setRegionEU] = useState(false);
  const [importTillFirstSegmentEvent, setImportTillFirstSegmentEvent] =
    useState(true);

  const checkboxGroupDefaultValue = ["import_till_first_segment_event"];
  const isProjectIdInvalid =
    typeof projectId === "undefined" ? undefined : !projectId;
  const isUsernameInvalid =
    typeof username === "undefined"
      ? undefined
      : !username?.endsWith("mp-service-account");
  const isSecretKeyInvalid =
    typeof secretKey === "undefined" ? undefined : !secretKey;

  const onRegionEUCheckboxChange = (e: any) => {
    setRegionEU(e.target.checked);
  };

  const onCheckboxChange = (e: any) => {
    setImportTillFirstSegmentEvent(e.target.checked);
  };

  const { mixpanelImportTasks } = useImportTasks({
    appId,
    sourceName: MIXPANEL,
  });

  let today = new Date();
  let startDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7,
  );
  let endDate = new Date();
  const initialImportDateRange = [
    {
      startDate,
      endDate,
      key: "selection",
    },
  ];
  const [importDateRange, setImportDateRange] = useState(
    initialImportDateRange,
  );

  const [createMixpanelImportTask, { isLoading }] =
    useCreateMixpanelImportTaskMutation();
  if (!mixpanelImportTasks) {
    return null;
  }

  const saveImportTaskConfig = async () => {
    if (projectId && username && secretKey) {
      const startDate = moment(importDateRange[0].startDate)
        .startOf("day")
        .format("YYYY-MM-DD");
      const endDate = moment(importDateRange[0].endDate)
        .endOf("day")
        .format("YYYY-MM-DD");

      const importTaskToCreate: ICreateMixpanelImportTask = {
        appId,
        config: {
          projectId: projectId.trim(),
          username: username.trim(),
          secretKey: secretKey.trim(),
          region: regionEU ? "eu" : "",
          startDate,
          endDate,
          importTillFirstSegmentEvent,
        },
      };

      createMixpanelImportTask(importTaskToCreate)
        .unwrap()
        .then(() => {
          toast({
            title: "Success",
            description: "Your Mixpanel data import has started successfully",
            status: "success",
          });
          onClose && onClose();
        })
        .catch(() => {
          toast({
            title: "Could not start importing your data",
            description:
              "The credentials you provided are invalid. Please try again.",
            status: "error",
          });
        });
    }
  };

  const hasQueuedMixpanelImportTask = mixpanelImportTasks.some(
    (task) => task.queued,
  );

  return (
    <div className="flex flex-col gap-2" data-testid="import-form-container">
      <Text fontSize="sm" color="gray.600" mb={4}>
        We use Mixpanel's{" "}
        <Link
          href="https://developer.mixpanel.com/reference/raw-event-export"
          color="black"
        >
          Export API
        </Link>{" "}
        to export your data. Please note, this is a one-off import.
      </Text>
      {mixpanelImportTasks.length > 0 && (
        <ImportTasks key={MIXPANEL} tasks={mixpanelImportTasks} />
      )}
      {!hasQueuedMixpanelImportTask && (
        <>
          <GetStarted sourceName={MIXPANEL} />
          <Flex direction="column" mt={6} gap={3}>
            <Box>
              <Text pb={1} fontSize="xs" color="gray.600">
                Project ID
              </Text>
              <FormControl isInvalid={isProjectIdInvalid}>
                <Input
                  data-testId="project-id-input"
                  placeholder="Project ID"
                  onChange={(e) => setProjectId(e.target.value)}
                />
                {isProjectIdInvalid && (
                  <FormErrorMessage fontSize="xs">
                    Project ID cannot be empty
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box>
              <Text pb={1} fontSize="xs" color="gray.600">
                Username
              </Text>
              <FormControl isInvalid={isUsernameInvalid}>
                <Input
                  data-testId="username-input"
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}
                />
                {isUsernameInvalid && (
                  <FormErrorMessage fontSize="xs">
                    Username should end with{" "}
                    <Code ml={1} fontSize="xs" colorScheme="red">
                      mp-service-account
                    </Code>
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box>
              <Text pb={1} fontSize="xs" color="gray.600">
                Secret key
              </Text>
              <InputGroup size="md">
                <FormControl isInvalid={isSecretKeyInvalid}>
                  <Input
                    data-testId="secret-key-input"
                    placeholder="Secret key"
                    type="text"
                    onChange={(e) => setSecretKey(e.target.value)}
                  />
                  {isSecretKeyInvalid && (
                    <FormErrorMessage fontSize="xs">
                      Secret key cannot be empty
                    </FormErrorMessage>
                  )}
                </FormControl>
              </InputGroup>
            </Box>
            <Box>
              <CheckboxGroup>
                <Checkbox
                  pt={1}
                  colorScheme="purple"
                  onChange={onRegionEUCheckboxChange}
                >
                  <Text fontSize="xs" color="gray.600">
                    Use Mixpanel EU servers (if you are enrolled in their EU
                    Data Residency) 🇪🇺
                  </Text>
                </Checkbox>
              </CheckboxGroup>
            </Box>
            <Box>
              <Text pb={1} fontSize="xs" color="gray.600">
                Import date range
              </Text>
              <Flex direction="column">
                <CustomDateRangeSection
                  w="fit-content"
                  dateRange={importDateRange}
                  setDateRange={setImportDateRange}
                  minDate={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() - 1),
                    )
                  }
                  maxDate={new Date()}
                />
                {currentApp?.segmentConnected && (
                  <>
                    <Text pt={1} fontSize="sm" color="gray.600">
                      We started receiving events from your Segment on{" "}
                      <Text as="span" fontWeight="bold">
                        {moment(currentApp.firstSegmentTokenCreatedAt).format(
                          "D MMMM YYYY",
                        )}
                      </Text>
                      . You can choose to import your Mixpanel data till that
                      date using the checkbox below. Not doing so might lead to
                      duplicates in your June data.
                    </Text>
                    <CheckboxGroup defaultValue={checkboxGroupDefaultValue}>
                      <Checkbox
                        pt={1}
                        colorScheme="purple"
                        value="import_till_first_segment_event"
                        onChange={onCheckboxChange}
                      >
                        <Text fontSize="xs">
                          Import till the date June started receiving events
                          from Segment
                        </Text>
                      </Checkbox>
                    </CheckboxGroup>
                  </>
                )}
              </Flex>
            </Box>
            <Flex pt={1}>
              <Button
                isLoading={isLoading}
                data-testid="import-button"
                colorScheme="green"
                isDisabled={
                  !projectId?.length || !username?.length || !secretKey?.length
                }
                onClick={saveImportTaskConfig}
              >
                Import
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </div>
  );
};
