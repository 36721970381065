import { ACTIVE_USERS_CONFIG } from "@/core/constants/report-configurations/report-types/activeUsers";
import { ENGAGEMENT_CONFIG } from "@/core/constants/report-configurations/report-types/engagement";
import EXPLORER_CONFIG from "@/core/constants/report-configurations/report-types/explorer";
import FEATURE_RELEASE_CONFIG from "@/core/constants/report-configurations/report-types/featureRelease";
import FUNNEL_CONFIG from "@/core/constants/report-configurations/report-types/funnel";
import MILESTONES_CONFIG from "@/core/constants/report-configurations/report-types/milestones";
import NEW_USERS_CONFIG from "@/core/constants/report-configurations/report-types/newUsers";
import POWER_USERS_CONFIG from "@/core/constants/report-configurations/report-types/powerUsers";
import RETENTION_CONFIG from "@/core/constants/report-configurations/report-types/retention";
import SLIPPING_AWAY_CONFIG from "@/core/constants/report-configurations/report-types/slippingAway";
import { ITemplateConfig } from "@/core/types/TemplateConfig";

export const AI = -1;
export const FEATURE_AUDIT = 0;
export const RETENTION = 1;
export const ENGAGEMENT = 2;
export const FUNNEL = 3;
export const ACTIVE_USERS = 4;
export const SLIPPING_AWAY = 5;
export const POWER_USERS = 6;
export const FEATURE_RELEASE = 8;
export const NEW_USERS = 15;
export const EXPLORER = 18;
export const MILESTONES = 22;

export enum ReportType {
  AI,
  FEATURE_AUDIT,
  EXPLORER,
  RETENTION,
  ENGAGEMENT,
  FUNNEL,
  ACTIVE_USERS,
  SLIPPING_AWAY,
  POWER_USERS,
  FEATURE_RELEASE,
  NEW_USERS,
  MILESTONES,
}

export const REPORT_CONFIGS = [
  ACTIVE_USERS_CONFIG,
  ENGAGEMENT_CONFIG,
  EXPLORER_CONFIG,
  FEATURE_RELEASE_CONFIG,
  FUNNEL_CONFIG,
  MILESTONES_CONFIG,
  NEW_USERS_CONFIG,
  POWER_USERS_CONFIG,
  RETENTION_CONFIG,
  SLIPPING_AWAY_CONFIG,
] as ITemplateConfig[];

export const INSIGHT_CONFIGS = REPORT_CONFIGS.map((config) => config?.insights)
  .flat()
  .filter((config) => config !== undefined);

export const ALL_REPORT_CONFIGURATIONS = [
  ACTIVE_USERS_CONFIG,
  ENGAGEMENT_CONFIG,
  EXPLORER_CONFIG,
  FEATURE_RELEASE_CONFIG,
  FUNNEL_CONFIG,
  NEW_USERS_CONFIG,
  POWER_USERS_CONFIG,
  RETENTION_CONFIG,
  SLIPPING_AWAY_CONFIG,
  MILESTONES_CONFIG,
];

export const FEATURED_REPORT_TYPES = [
  FEATURE_RELEASE_CONFIG,
  FUNNEL_CONFIG,
  ENGAGEMENT_CONFIG,
];
export const CACHEABLE_REPORT_TYPES = [
  ACTIVE_USERS,
  FEATURE_RELEASE,
  RETENTION,
  MILESTONES,
  NEW_USERS,
  FUNNEL,
  POWER_USERS,
  EXPLORER,
  SLIPPING_AWAY,
  ENGAGEMENT,
];
export const PERSISTED_TIMERANGE_REPORT_TYPES = [
  ENGAGEMENT,
  EXPLORER,
  FUNNEL,
  MILESTONES,
  RETENTION,
  SLIPPING_AWAY,
];
