import { Center, ComponentDefaultProps, Tooltip } from "@chakra-ui/react";
import { CodeBracketSquareIcon, MoonIcon } from "@heroicons/react/24/solid";

import { HighlightCard } from "core/components/ViewInsight";
import { SimpleAreaChart } from "core/components/ViewInsight/Graphs/SimpleAreaChart";
import { SimpleBarChart } from "core/components/ViewInsight/Graphs/SimpleBarChart";
import { SimpleTable } from "core/components/ViewInsight/Graphs/SimpleTable";
import {
  COLOR_NAME_TO_PALETTE,
  GRID_WIDTH_TO_SIZE,
} from "core/hooks/useEditViewInsight";
import { useSqlEditor } from "core/hooks/useSqlEditor";
import { IViewInsight } from "core/models/viewInsights";
import { IQuery } from "core/types/Query";
import { IHightlight } from "core/types/TemplateConfig";
import { GraphType, Size } from "core/types/ViewInsight";
import { formatValue } from "lib/format";

interface IPublicAIViewInsightCard extends ComponentDefaultProps {
  viewInsight: IViewInsight;
  highlight: IHightlight;
  sharingSecretToken?: string;
}

export const PublicAIViewInsightCard: React.FC<IPublicAIViewInsightCard> = ({
  viewInsight,
  highlight,
  sharingSecretToken,
}) => {
  const { data, isExecuteFetching, isExecuteLoading } = useSqlEditor({
    viewInsight,
    execute: true,
    sharingSecretToken,
  });
  const isLoading = isExecuteFetching || isExecuteLoading;

  const isTable = viewInsight.graphType === GraphType.Table;
  const isAreaChart = viewInsight.graphType === GraphType.Area;
  const isBarChart = viewInsight.graphType === GraphType.Bar;
  const color = COLOR_NAME_TO_PALETTE[viewInsight?.color as string];

  function transformGraphData() {
    if (!data) return [];

    return data?.result?.data?.map((point: { [key: string]: string }) => {
      return {
        x: formatValue(point[(viewInsight.query as IQuery)?.graphXAxis]),
        y: formatValue(point[(viewInsight.query as IQuery)?.graphYAxis]),
      };
    });
  }

  const isEmpty = !data?.result?.data?.length;

  return (
    <HighlightCard>
      {(hc) => (
        <hc.Container
          onMouseEnter={() => {}}
          onMouseLeave={() => {}}
          onClick={() => {}}
          data-testid="view-insight-card"
          hoverProps={{ cursor: "default" }}
        >
          <div className="flex h-full w-full flex-col">
            <hc.Header>
              <hc.Title
                title={viewInsight.title || viewInsight.insight.name}
                description={""}
                highlight={highlight}
                shouldBePaywalled={false}
                showHighlightDescription={false}
                isLoading={isLoading}
                icon={
                  <Tooltip hasArrow label="SQL insight">
                    <CodeBracketSquareIcon className="h-4 text-gray-500" />
                  </Tooltip>
                }
                color={color}
                viewInsight={viewInsight}
              />
            </hc.Header>
            <hc.Body
              isLoading={isLoading}
              maxW="full"
              h="full"
              overflowY="auto"
            >
              {!isEmpty && (
                <>
                  {isTable && (
                    <SimpleTable
                      columns={data?.result?.columns}
                      data={data?.result?.data}
                      size={GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] as Size}
                    />
                  )}
                  {isAreaChart && (
                    <SimpleAreaChart
                      data={transformGraphData()}
                      color={COLOR_NAME_TO_PALETTE[viewInsight.color]}
                      size={GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] as Size}
                    />
                  )}
                  {isBarChart && (
                    <SimpleBarChart
                      data={transformGraphData()}
                      color={COLOR_NAME_TO_PALETTE[viewInsight.color]}
                      size={GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] as Size}
                    />
                  )}
                </>
              )}
              {isEmpty && (
                <Center ml={6} w="full" h="full">
                  <div className="flex flex-col items-center gap-4">
                    <div className="rounded  bg-purple-50 p-2">
                      <MoonIcon className="h-4 text-purple-500" />
                    </div>
                    <div className="flex flex-col items-center">
                      <p className="text-xs font-medium">Nothing to see here</p>
                    </div>
                  </div>
                </Center>
              )}
            </hc.Body>
          </div>
        </hc.Container>
      )}
    </HighlightCard>
  );
};
