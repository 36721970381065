import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Body as CommandMenuBody } from "core/components/CommandMenu/Body";

interface KeyboardEvent {
  key: string;
  metaKey?: boolean;
}

export const CommandMenu = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && e.metaKey) {
        setOpen(true);
      }

      if (e.key === "Escape" || e.key === "Esc") {
        setOpen(false);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [navigate]);

  return <CommandMenuBody open={open} setOpen={setOpen} />;
};
