import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";

import { useCrmSyncSettings } from "core/hooks/useCrmSyncSettings";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Integration } from "core/models/dataMappings";
import { useGetSupportedHubspotObjectsQuery } from "core/models/hubspot";
import { IAppObject } from "core/types/AppObject";

export const SelectObject: React.FC<{
  appObject: IAppObject;
}> = ({ appObject }) => {
  const { id: appId } = useCurrentApp();
  const { data } = useGetSupportedHubspotObjectsQuery({ appId });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const { onUpdateCrmSyncSetting, crmSyncSetting: setting } =
    useCrmSyncSettings({
      integration: Integration.Hubspot,
      objectType: appObject.objectType,
    });

  const [object, setObject] = useState<{
    objectName: string;
    objectSlug: string;
  }>({
    objectName: setting?.objectName || "",
    objectSlug: setting?.objectSlug || "",
  });

  function onSelectObject({
    objectName,
    objectSlug,
  }: {
    objectName: string;
    objectSlug: string;
  }) {
    if (setting?.objectName && setting?.objectSlug) {
      setObject({ objectName, objectSlug });
      onOpen();
    } else if (setting) {
      setObject({ objectName, objectSlug });
      onUpdateCrmSyncSetting(setting.id, { objectName, objectSlug });
    }
  }

  return (
    <div className="flex justify-between pb-4 pt-2 text-sm">
      <div className="flex flex-col gap-1">
        <div>Select HubSpot object</div>
        <div className="text-gray-600">
          Select which HubSpot object June{" "}
          {appObject.pluralName.toLocaleLowerCase()} should sync to
        </div>
      </div>
      <div>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            bg="gray.50"
            border="none"
          >
            {object.objectName ? object.objectName : "Choose an object"}
          </MenuButton>
          <MenuList>
            {data?.map(({ objectName, objectSlug }) => (
              <MenuItem
                key={objectSlug}
                onClick={() => {
                  onSelectObject({ objectName, objectSlug });
                }}
              >
                {objectName}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </div>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Change object
            </AlertDialogHeader>

            <AlertDialogBody fontSize="sm">
              Changing the HubSpot object will remove all of your
              {` `}
              {appObject.singularName.toLowerCase()} mappings
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="purple"
                onClick={() => {
                  onClose();
                  onUpdateCrmSyncSetting(Number(setting?.id), {
                    objectName: object.objectName,
                    objectSlug: object.objectSlug,
                  });
                }}
                ml={3}
              >
                Change
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};
