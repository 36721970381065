import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { isNumber } from "lodash";
import moment from "moment";

import InsightCard from "core/components/InsightCard/Index";
import {
  BarChart,
  BarItem,
  BarRectangle,
} from "core/design-system/charts/BarChart";
import {
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import TickText from "core/design-system/charts/TickText";
import colors from "core/design-system/constants/theme/colors";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { completionRateMockData } from "core/modules/reports/report-types/GroupMilestones/mockData/completionRate";
import { isUserReportLevel } from "core/modules/reports/utils";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

type MilestoneKey =
  | "milestone1"
  | "milestone2"
  | "milestone3"
  | "milestone4"
  | "milestone5";

const COLORS = {
  milestone1: colors.purple[300],
  milestone2: colors.orange[300],
  milestone3: colors.teal[300],
  milestone4: colors.yellow[300],
  milestone5: colors.blue[300],
};

interface IMilestoneData {
  milestonesOverview: {
    name: string;
    milestone: MilestoneKey;
    maxCohortSize: number;
  }[];
  completionRates: {
    date: string;
    cohortSize: number;
  } & { [key: string]: string | number }[];
}

export const Dot = ({ color, ...props }: { color: string }) => {
  return (
    <Box
      display="inline-block"
      mr={2}
      h="8px"
      w="8px"
      borderRadius="full"
      bg={color}
      {...props}
    />
  );
};

const MilestoneConversion = ({
  data,
  milestone,
  isUserReport,
}: {
  data: IMilestoneData["completionRates"][0];
  milestone: MilestoneKey;
  isUserReport: boolean;
}) => {
  if (!isNumber(data[`${milestone}ConversionPercentage`])) return null;

  return (
    <Text mt={2} fontSize="sm" color="white">
      <Dot color={COLORS[milestone]} />
      {data[`${milestone}ConversionPercentage`]}% {data[milestone]}
      <Text as="span" ml={1} fontSize="xs" color="gray.400">
        ({data[`${milestone}ConvertedCount`]}{" "}
        {isUserReport ? "users" : "companies"})
      </Text>
    </Text>
  );
};

const BarChartTooltip = ({
  payload,
  active,
  isUserReport,
}: {
  payload: { payload: IMilestoneData["completionRates"][0] }[];
  active: boolean;
  isUserReport: boolean;
}) => {
  const { activeAppObject } = useAppObjects();
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <Box maxW="350px" bg="gray.700" p={3} borderRadius={5}>
        <Text fontSize="sm" color="gray.400">
          Week of {data["date"]}
        </Text>
        <Text my={1} fontSize="xs" color="white">
          Here's how many {activeAppObject?.pluralName.toLocaleLowerCase()}{" "}
          completed each milestone out of {data["cohortSize"]} sign ups this
          week
        </Text>
        <MilestoneConversion
          isUserReport={isUserReport}
          data={data}
          milestone="milestone1"
        />
        <MilestoneConversion
          isUserReport={isUserReport}
          data={data}
          milestone="milestone2"
        />
        <MilestoneConversion
          isUserReport={isUserReport}
          data={data}
          milestone="milestone3"
        />
        <MilestoneConversion
          isUserReport={isUserReport}
          data={data}
          milestone="milestone4"
        />
        <MilestoneConversion
          isUserReport={isUserReport}
          data={data}
          milestone="milestone5"
        />
      </Box>
    );
  }
  return null;
};

export const CompletionRateContainer: React.FC<IReportInsight> = ({
  report,
  sharingSecretToken = null,
  previewMode,
  sharingMode,
  config,
  screenshotMode,
  showYAxis,
  showInProgress,
  reportLevel,
}) => {
  const isUserReport = isUserReportLevel(reportLevel);
  const insight =
    (config.insights.find(({ slug }) =>
      isUserReport
        ? slug === "milestones-completion-rate"
        : slug === "milestones-completion-rate-companies",
    ) as IInsightSection) || {};
  const isSetupValid = config.validateSetup(report.config);
  const {
    response: data,
    isFetching,
    error,
    onRefreshInsight,
  } = useInsightDataFetching({
    report,
    insight,
    insightParams: {
      milestone: insight?.context?.name,
      completedWithin: 1,
    },
    sharingSecretToken,
    previewMode,
    skip: !isSetupValid,
  });

  const graphData = previewMode
    ? (completionRateMockData as unknown as IMilestoneData)
    : (data?.data as IMilestoneData);
  const completionRateData = graphData?.completionRates?.map((rate) => ({
    ...rate,
    date: moment(rate.date).format(`Do MMM`),
  }));

  if (!showInProgress && showInProgress !== undefined && completionRateData) {
    completionRateData.pop();
  }

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug}>
          <Card.Header
            hasCaching={data?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={data?.cachedAt}
          />
          <Card.Body
            insight={insight}
            isLoading={!data || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            <Flex h="250px" id="loaded">
              <BarChart
                showYAxis={showYAxis}
                width={500}
                height={250}
                data={completionRateData}
              >
                {/* @ts-ignore */}
                <CommonXAxis dataKey="date" />
                {/* @ts-ignore */}
                <CommonYAxis
                  /* @ts-ignore */
                  tick={({ x, ...props }) =>
                    showYAxis && (
                      /* @ts-ignore */
                      <TickText x={x - 5} {...props}>
                        {props.payload.value}
                      </TickText>
                    )
                  }
                />
                {/* @ts-ignore */}
                <CommonTooltip
                  content={<BarChartTooltip isUserReport={isUserReport} />}
                />
                <CommonGrid />
                {graphData?.milestonesOverview?.map(({ milestone }, index) => {
                  return (
                    <BarItem
                      key={index}
                      dataKey={`${milestone}ConvertedCount`}
                      barSize={8}
                      fill={COLORS[milestone]}
                      shape={<BarRectangle radius={[2, 2, 0, 0]} />}
                    />
                  );
                })}
              </BarChart>
            </Flex>
            <Flex
              px={32}
              mb={5}
              w="full"
              align="center"
              justifyContent="space-evenly"
            >
              {graphData?.milestonesOverview?.map(
                ({ milestone, name }, index: number) => {
                  return (
                    <Tooltip label={name} placement="bottom" hasArrow>
                      <Flex key={index} align="center" px={2}>
                        <Dot color={COLORS[milestone]} />
                        <Text noOfLines={1} fontSize="xs" color="gray.700">
                          {name}
                        </Text>
                      </Flex>
                    </Tooltip>
                  );
                },
              )}
            </Flex>
          </Card.Body>
          {insight.description && !screenshotMode && (
            <Card.Footer bg="white">
              <Card.DescriptionAccordion insight={insight} />
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
};
