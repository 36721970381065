import { Box, Center, Flex, Spinner, StyleProps, Text } from "@chakra-ui/react";

import { ISDK, SDKLanguage } from "modules/Onboarding/SetupSources/SDKs";
import CodeBlock from "modules/Sdk/CodeBlock";

interface IScriptGeneratorProps extends StyleProps {
  sdk: ISDK;
  sdks: ISDK[];
  codeKey: string;
  language?: SDKLanguage;
  token: string | null;
  theme?: "light" | "dark";
  showHeader?: boolean;
  onSelectSdk?: (sdk: ISDK) => void;
  onReplace?: (code: string) => void;
  headerContainerProps?: StyleProps;
  headerItemProps?: StyleProps;
  containerProps?: StyleProps;
}

export const ScriptGenerator: React.FC<IScriptGeneratorProps> = ({
  sdk,
  sdks,
  codeKey,
  token,
  language = "javascript",
  theme = "dark",
  showHeader = false,
  onSelectSdk,
  onReplace,
  headerContainerProps,
  headerItemProps,
  containerProps,
  ...styleProps
}) => {
  if (sdk[codeKey as keyof ISDK] === undefined) {
    return null;
  }

  const borderProps = containerProps
    ? containerProps
    : showHeader
      ? { border: "1px solid", borderColor: "gray.200", borderRadius: "lg" }
      : {};

  const raw =
    token &&
    (sdk[codeKey as keyof ISDK] || "").replace(`YOUR_WRITE_KEY`, token);
  const code = onReplace ? onReplace(raw) : raw;

  return (
    <Flex direction="column" {...borderProps}>
      {showHeader && (
        <Flex
          borderBottom="1px solid"
          borderColor="gray.200"
          w="full"
          py={4}
          px={6}
          gap={2}
          fontSize="sm"
          alignItems="center"
          {...headerContainerProps}
        >
          {sdks.map((_sdk, i) => (
            <Flex
              background={sdk === _sdk ? "gray.100" : "none"}
              key={i}
              px={2}
              py={1}
              fontWeight={sdk === _sdk ? "medium" : "normal"}
              color={sdk === _sdk ? "gray.800" : "gray.700"}
              _hover={{ bg: "gray.200" }}
              cursor="pointer"
              onClick={() => {
                if (onSelectSdk) onSelectSdk(_sdk);
              }}
              borderRadius="lg"
              {...headerItemProps}
            >
              {_sdk.name}
            </Flex>
          ))}
        </Flex>
      )}

      <Box my={2} {...styleProps}>
        {token && sdk[codeKey as keyof ISDK] && (
          <Text fontSize="xs">
            <CodeBlock theme={theme} language={language} raw={code} />
          </Text>
        )}
        {!token && (
          <Center>
            <Spinner size="sm" />
          </Center>
        )}
      </Box>
    </Flex>
  );
};

export default ScriptGenerator;
