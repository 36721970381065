import { useLocation } from "react-router-dom";

import InstallSteps from "modules/MobileWidgets/InstallSteps";
import MobileWidgetsLayout from "modules/MobileWidgets/MobileWidgetsLayout";

export const MobileWidgetInstall = () => {
  const location = useLocation();
  const qs = new URLSearchParams(location.search);

  const insightUrl = qs.get("insight_url");
  if (!insightUrl) {
    return (
      <div>
        You must provide an insight_url to generate a Scriptable script.
      </div>
    );
  }
  const decodedInsightUrl = decodeURIComponent(insightUrl);

  return (
    <MobileWidgetsLayout>
      <InstallSteps startStep={4} insightUrl={decodedInsightUrl} />
    </MobileWidgetsLayout>
  );
};
