import { Tooltip } from "@chakra-ui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Link, useParams } from "react-router-dom";

import { getGroupName } from "core/helpers/contactName";
import { useGroupBanner } from "core/hooks/useGroupBanner";

export const GroupBanner: React.FC<{ groupId: string; groupType: string }> = ({
  groupId,
  groupType,
}) => {
  const { appId } = useParams();
  const { entity } = useGroupBanner({ groupId, groupType });

  if (!entity) return null;

  function handleClick() {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    params.delete("groupId");
    url.search = params.toString();
    window.history.pushState({}, "", url.toString());
    window.location.reload();
  }

  return (
    <div className="flex gap-x-6 rounded-md bg-purple-500 px-6 py-2.5">
      <p className="text-sm font-medium leading-6 text-white">
        You are viewing this for
        <Link
          className="mx-1 cursor-pointer underline decoration-dotted"
          to={`/a/${appId}/${groupType === "group" ? "group" : "company"}/${groupId}/`}
        >
          {entity && groupType === "group" ? getGroupName(entity) : entity?.id}
        </Link>
        users only
      </p>
      <div className="flex flex-1 justify-end">
        <Tooltip hasArrow label="Exit company mode">
          <button type="button" onClick={handleClick}>
            <XMarkIcon className="h-5 text-white" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
