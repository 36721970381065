import { ComponentDefaultProps } from "@chakra-ui/react";

import { EventIcon } from "core/design-system/components/Icon/Custom/EventIcon";

interface IEmptySetupProps extends ComponentDefaultProps {
  IconComponent?: React.ElementType;
}

const EmptySetup: React.FC<IEmptySetupProps> = ({
  IconComponent = EventIcon,
  helpText,
  ...props
}) => {
  return (
    <div className="flex h-[300px] w-full items-center justify-center">
      <div className="flex flex-col items-center gap-1 text-center">
        <div className="mb-3 rounded-full border p-3">
          <IconComponent className="size-6 text-purple-500" />
        </div>
        <p className="text-sm font-medium">{helpText}</p>
      </div>
    </div>
  );
};

export default EmptySetup;
