import { useParams } from "react-router-dom";

import { ReportType } from "core/constants/report-configurations";
import { useCreateReportMutation } from "core/models/report";

export function useReport() {
  const { appId } = useParams();
  const [createReport, { data: createdReport }] = useCreateReportMutation();

  function onCreateReport(reportType: ReportType, reportName: string) {
    createReport({ appId, reportType, name: reportName });
  }

  return { createdReport, onCreateReport };
}
