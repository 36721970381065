import { Button, Spinner } from "@chakra-ui/react";
import { CodeBracketSquareIcon, SparklesIcon } from "@heroicons/react/20/solid";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import colors from "core/design-system/constants/theme/colors";

const CodeEditor = React.lazy(() => import("@uiw/react-textarea-code-editor"));

export const AIAssist: React.FC<{
  prompt: string;
  suggestion: string | null;
  isPromptFetching: boolean;
  isPromptLoading: boolean;
  setPrompt: (prompt: string) => void;
  onApply: () => void;
  onReject: () => void;
  onPrompt: () => void;
  showAIAssistant: boolean;
  setShowAIAssistant: (showAIAssistant: boolean) => void;
}> = ({
  prompt,
  suggestion,
  isPromptFetching,
  isPromptLoading,
  setPrompt,
  onApply,
  onReject,
  onPrompt,
  showAIAssistant,
  setShowAIAssistant,
}) => {
  return (
    <AnimatePresence>
      {showAIAssistant ? (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2 }}
          className="rounded-md bg-white"
        >
          <div className="flex flex-col">
            <div className="flex w-full items-center justify-between border-b border-gray-200 px-2 py-1">
              <div className="flex items-center gap-x-1">
                <SparklesIcon className="h-4 w-4 text-purple-500" />
                <p className="text-sm font-medium">AI Assistant</p>
              </div>
              <div className="flex items-center">
                <Button
                  size="xs"
                  colorScheme="purple"
                  variant="ghost"
                  fontWeight="bold"
                  onClick={() => setShowAIAssistant(false)}
                >
                  _
                </Button>
                <Button
                  key={(isPromptLoading || isPromptFetching).toString()}
                  size="xs"
                  colorScheme="purple"
                  variant="ghost"
                  isLoading={isPromptLoading || isPromptFetching}
                  onClick={onPrompt}
                >
                  Ask
                </Button>
              </div>
            </div>
            <div className="relative">
              <div className="overflow-hidden rounded-lg ring-0 focus-within:ring-0 ">
                <textarea
                  id="prompt"
                  name="prompt"
                  rows={2}
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder="Ask June AI to help you with the query"
                  className="block w-full resize-none border-0 bg-transparent p-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="px-4 pb-4 pt-0">
            <AnimatePresence>
              {suggestion && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                  className="flex flex-col rounded-md bg-[#2b2b2b] p-2"
                >
                  <div className="flex w-full items-center justify-between border-b border-gray-800 pb-1">
                    <div className="flex items-center gap-x-1">
                      <CodeBracketSquareIcon className="h-4 w-4 text-purple-500" />
                      <p className="text-xs font-medium text-white">
                        Suggestion
                      </p>
                    </div>
                    <div className="flex items-center">
                      <Button
                        size="xs"
                        variant="ghost"
                        colorScheme="white"
                        color="white"
                        _hover={{ bg: "gray.900" }}
                        onClick={onReject}
                        isDisabled={isPromptLoading || isPromptFetching}
                      >
                        Reject
                      </Button>
                      <Button
                        size="xs"
                        variant="ghost"
                        colorScheme="white"
                        color="white"
                        _hover={{ bg: "gray.900" }}
                        onClick={onApply}
                        isDisabled={isPromptLoading || isPromptFetching}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                  {isPromptFetching ? (
                    <div className="flex items-center justify-center rounded-md bg-[#2b2b2b] py-4">
                      <Spinner size="sm" />
                    </div>
                  ) : (
                    <CodeEditor
                      value={suggestion}
                      language="sql"
                      data-color-mode="dark"
                      padding={15}
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        height: "100%",
                        fontSize: 12,
                        backgroundColor: colors.black,
                        fontFamily:
                          "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                      }}
                    />
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.4 }}
          className="w-[120px] cursor-pointer rounded-md bg-white"
          onClick={() => setShowAIAssistant(true)}
        >
          <div className="flex flex-col">
            <div className="flex w-full items-center justify-between px-2 py-1">
              <div className="flex items-center gap-x-1">
                <SparklesIcon className="h-4 w-4 text-purple-500" />
                <p className="text-sm font-medium">AI Assistant</p>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
