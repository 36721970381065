import { Tooltip } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";

import { FilterType } from "core/constants/report-setup";
import { DataTypeString } from "core/models/traits";
import { TraitMenu } from "core/modules/audience/Filters/TraitMenu";

export interface IFilterMenuProps {
  onAddEventFilter?: (filterType: FilterType) => void;
  onAddTraitFilter?: (
    filterType: FilterType,
    trait: string,
    dataType: DataTypeString,
  ) => void;
  isEventFilterDisabled?: boolean;
}

export const AddFilterButton: React.FC<IFilterMenuProps> = ({
  onAddEventFilter,
  onAddTraitFilter,
  isEventFilterDisabled,
}) => {
  return (
    <Popover className="relative">
      <Tooltip
        placement="top"
        label="Add filter to this group"
        hasArrow
        shouldWrapChildren
      >
        <PopoverButton data-testid="audience-add-filter-button">
          <div className="flex items-center rounded-r-lg border-l border-gray-100 bg-gray-50 p-2 text-gray-500 hover:text-black">
            <PlusIcon className="h-4" />
          </div>
        </PopoverButton>
      </Tooltip>
      <PopoverPanel>
        {({ close }) => (
          <TraitMenu
            onAddTraitFilter={(filterType, trait, dataType) => {
              onAddTraitFilter && onAddTraitFilter(filterType, trait, dataType);
              close();
            }}
            onAddEventFilter={(filterType) => {
              onAddEventFilter && onAddEventFilter(filterType);
              close();
            }}
            isEventFilterDisabled={isEventFilterDisabled}
          />
        )}
      </PopoverPanel>
    </Popover>
  );
};
