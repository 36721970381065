import { useState } from "react";

import { thousandsToK } from "@/core/helpers/thousands";
import {
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
  CommonCustomTooltip,
} from "core/design-system/charts/Common";
import { Line, LineChart } from "core/design-system/charts/LineChart";
import TickText from "core/design-system/charts/TickText";
import { useChartData } from "core/modules/reports/report-types/Explorer/ExplorationContainer";
import { ExplorationLegend } from "core/modules/reports/report-types/Explorer/ExplorationLegend";
import { Size } from "core/types/ViewInsight";

interface ISerie {
  color: string;
  data: { x: string; y: number; eventProperties?: string[] }[];
  max: number;
  mean: number;
  min: number;
  name: string;
  p90: number;
  total: number;
  uuid: string;
}

interface ISimpleStackedLineChart {
  data: ISerie[];
  size: Size;
  showYAxis: boolean;
  TooltipComponent?: React.FC<any>;
  tooltipProps?: any;
}

export const simplifyXAxisTicks = (
  data: { data: { [key: string]: object }[] }[],
  dataKey: string,
) => {
  if (!data || data.length === 0 || !data[0].data || data[0].data.length === 0)
    return {
      firstTick: "",
      lastTick: "",
    };
  const firstTick = data && data.length && data[0].data[0][dataKey];
  const lastTick =
    data && data.length && data[0].data[data[0].data.length - 1][dataKey];
  return {
    firstTick,
    lastTick,
  };
};

export const getAllXAxisTicks = (
  data: { data: { [key: string]: object }[] }[],
  dataKey: string,
): string[] => {
  if (
    !data ||
    data.length === 0 ||
    !data[0].data ||
    data[0].data.length === 0
  ) {
    return [];
  }

  // Assuming all series have the same x-axis values
  return data[0].data.map((item) => String(item[dataKey]));
};

export const SimpleStackedLineChart: React.FC<ISimpleStackedLineChart> = ({
  data,
  size,
  showYAxis,
  TooltipComponent = CommonCustomTooltip,
  tooltipProps,
}) => {
  const { series } = useChartData({ data });
  const [hoveringIndex, setHoveringIndex] = useState<string | undefined>(
    undefined,
  );

  if (!series || series.length < 1) {
    return null;
  }
  const { firstTick, lastTick } = simplifyXAxisTicks(series, "x");
  const { firstTick: firstYTick, lastTick: lastYTick } = simplifyXAxisTicks(
    series,
    "y",
  );
  const allTicks = getAllXAxisTicks(series, "x");

  return (
    <div className="flex h-full w-full flex-col justify-between">
      <div className="flex h-5/6 w-full flex-col">
        <LineChart
          h="sm"
          w="100%"
          margin={{ top: 5, left: 0, right: 0, bottom: 0 }}
        >
          <CommonGrid horizontal={true} />
          {/* @ts-ignore */}
          <CommonTooltip content={<TooltipComponent {...tooltipProps} />} />
          {/* @ts-ignore */}
          <CommonXAxis
            type="category"
            allowDuplicatedCategory={false}
            dataKey="x"
            tickCount={2}
            interval="preserveStartEnd"
            ticks={
              [Size.Medium, Size.Large].includes(size)
                ? allTicks
                : [firstTick, lastTick]
            }
          />
          {/* @ts-ignore */}
          <CommonYAxis
            // @ts-ignore
            width={35}
            // @ts-ignore
            tick={({ x, ...props }) => (
              <TickText x={x - 5} {...props}>
                {thousandsToK(props.payload.value)}
              </TickText>
            )}
            ticks={[firstYTick, lastYTick]}
          />
          {series.map((serie, idx) => {
            return (
              <Line
                key={idx}
                dot={false}
                dataKey="y"
                data={serie.data}
                name={serie.name}
                stroke={serie.color}
                strokeWidth={1.5}
                strokeOpacity={
                  hoveringIndex !== undefined
                    ? String(serie.uuid) === String(hoveringIndex)
                      ? 1
                      : 0.2
                    : 1
                }
              />
            );
          })}
        </LineChart>
      </div>
      <div className="max-w-full overflow-x-scroll">
        <ExplorationLegend
          series={series}
          size={size || Size.Small}
          onHover={setHoveringIndex}
        />
      </div>
    </div>
  );
};
