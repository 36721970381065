import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { useToast } from "core/hooks/useToast";
import { useCreateEventMutation } from "core/models/events";

export const CreateEventModal = () => {
  const { appId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [name, setName] = useState("");
  const [createEvent] = useCreateEventMutation();
  const toast = useToast();

  function handleClose() {
    onClose();
    setName("");
  }

  function onCreate() {
    createEvent({ appId: Number(appId), name })
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        toast({
          title: "Failed to create event",
          description: error.data.message,
          status: "error",
        });
      });
  }

  return (
    <div>
      <Button
        onClick={onOpen}
        colorScheme="purple"
        leftIcon={<PlusIcon className="h-4 w-4" />}
      >
        New event
      </Button>
      {isOpen && (
        <Modal size="lg" isOpen={isOpen} onClose={handleClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create new event</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="flex flex-col gap-y-3">
                <p className="text-sm font-medium">Event name</p>
                <div className="flex flex-col gap-y-2">
                  <Input
                    placeholder="Event name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <p className="text-xs text-gray-600">
                    Make sure it's descriptive, e.g. June has "Report created"
                    as an event.
                  </p>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="flex items-center gap-2">
                <Button variant="ghost" onClick={handleClose}>
                  Cancel
                </Button>
                <Button colorScheme="purple" onClick={onCreate}>
                  Create
                </Button>
              </div>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};
