import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";

import { Button } from "@/Components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useViewInsightActions } from "core/hooks/useViewInsightActions";
import { useViewInsightManagement } from "core/hooks/useViewInsightManagement";
import { AppObjectType } from "core/types/AppObject";
import { IInsightSection } from "core/types/TemplateConfig";

interface IAddToViewActionProps {
  insight: IInsightSection;
  reportId: string;
}

export interface IViewActionItem {
  displayName: string;
  value: string;
  isAdded: boolean;
  viewInsightId: number | null;
  canAddInsightToView: boolean;
}

export const AddToViewAction: React.FC<IAddToViewActionProps> = ({
  insight,
  reportId,
}) => {
  const { id } = useCurrentApp();
  const { activeAppObject } = useAppObjects();

  const { items, isLoading, searchValue, setSearchValue, hasBeenPinned } =
    useViewInsightActions({
      appId: id,
      reportId,
      insightId: insight.typeId,
    });

  const { handleAddOrRemoveInsight } = useViewInsightManagement({
    appId: id,
    reportId,
    insightId: insight.typeId,
    objectType: activeAppObject?.objectType ?? AppObjectType.User,
  });

  return (
    <>
      <div className="flex items-center justify-center">
        <Popover className="relative">
          <PopoverButton>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon" className="text-gray-600">
                  {hasBeenPinned ? (
                    <CheckCircleIcon className="size-5 text-purple-500" />
                  ) : (
                    <PlusIcon className="size-5" />
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent>Add to dashboard</TooltipContent>
            </Tooltip>
          </PopoverButton>
          <PopoverPanel>
            {({ close }) => (
              <>
                <Lifecycle onUnmount={() => setSearchValue("")} />
                <Command>
                  <CommandInput
                    placeholder="Search dashboards..."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                  <CommandList>
                    {isLoading ? (
                      <span className="px-2 py-1 sm:leading-4">Loading...</span>
                    ) : (
                      <>
                        {items.length === 0 && (
                          <span className="px-2 py-1 sm:leading-4">
                            No dashboards found
                          </span>
                        )}
                        {items.map((item, index) => (
                          <CommandOption
                            disabled={
                              !item.canAddInsightToView && !item.isAdded
                            }
                            key={index}
                            value={() => {
                              close();
                              handleAddOrRemoveInsight({
                                isAdded: item.isAdded,
                                viewInsightId: item.viewInsightId,
                                viewId: item.value,
                                viewName: item.displayName,
                              });
                            }}
                            rightIcon={
                              item.isAdded && (
                                <CheckIcon className="h-4 w-4 text-gray-800" />
                              )
                            }
                          >
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="w-full">
                                    <span className="line-clamp-1 text-ellipsis sm:leading-4">
                                      {item.displayName}
                                    </span>
                                  </div>
                                </TooltipTrigger>
                                {!item.canAddInsightToView && !item.isAdded && (
                                  <TooltipContent>
                                    Maximum insights added. Remove one to add
                                    another.
                                  </TooltipContent>
                                )}
                              </Tooltip>
                            </TooltipProvider>
                          </CommandOption>
                        ))}
                      </>
                    )}
                  </CommandList>
                </Command>
              </>
            )}
          </PopoverPanel>
        </Popover>
      </div>
    </>
  );
};
