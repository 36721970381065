import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import { SnakeCasedProperties } from "type-fest";

import { IApp } from "core/types/App";

export const appsApi = createApi({
  reducerPath: "appsApi",
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_API_HOST }),
  tagTypes: ["Apps"],
  endpoints: (builder) => ({
    getApps: builder.query<IApp[], void>({
      query: () => ({
        url: `/apps`,
        credentials: "include",
        mode: "cors",
      }),
      transformResponse: (response: SnakeCasedProperties<IApp[]>) =>
        humps.camelizeKeys(response) as IApp[],
    }),
    getAppsWithDomain: builder.query({
      query: ({ domain }) => ({
        url: `/apps`,
        params: humps.decamelizeKeys({ domain }),
        credentials: "include",
        mode: "cors",
      }),
      transformResponse: (response: SnakeCasedProperties<IApp[]>) =>
        humps.camelizeKeys(response) as IApp[],
    }),
    aiOptIn: builder.mutation({
      query: ({ appId, aiOptedIn }) => ({
        url: `/apps/${appId}/ai_opt_in`,
        method: "POST",
        params: humps.decamelizeKeys({ id: appId, aiOptedIn }),
        credentials: "include",
        mode: "cors",
      }),
      transformResponse: (response: SnakeCasedProperties<IApp[]>) =>
        humps.camelizeKeys(response) as IApp[],
    }),
    requestMigration: builder.mutation({
      query: ({ appId }) => ({
        url: `/apps/${appId}/request_migration`,
        method: "POST",
        params: humps.decamelizeKeys({ id: appId }),
        credentials: "include",
        mode: "cors",
      }),
      transformResponse: (response: SnakeCasedProperties<IApp[]>) =>
        humps.camelizeKeys(response) as IApp[],
    }),
    requestDataExport: builder.mutation({
      query: ({ appId }) => ({
        url: `/apps/${appId}/request_data_export`,
        method: "POST",
        params: humps.decamelizeKeys({ id: appId }),
        credentials: "include",
        mode: "cors",
      }),
      transformResponse: (response: SnakeCasedProperties<IApp[]>) =>
        humps.camelizeKeys(response) as IApp[],
    }),
    weeklyDigestOptIn: builder.mutation({
      query: ({ appId, weeklyDigestEnabled }) => ({
        url: `/apps/${appId}/weekly_digest_opt_in`,
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          weeklyDigestEnabled,
        }),
        credentials: "include",
        mode: "cors",
      }),
    }),
    bookACall: builder.mutation({
      query: ({
        appId,
        onboarding,
      }: {
        appId: number;
        onboarding: boolean;
      }) => ({
        url: `/apps/${appId}/call_booked`,
        method: "PUT",
        params: humps.decamelizeKeys({ id: appId, onboarding }),
        credentials: "include",
        mode: "cors",
      }),
    }),
  }),
});

export const {
  useGetAppsWithDomainQuery,
  useGetAppsQuery,
  useAiOptInMutation,
  useRequestMigrationMutation,
  useRequestDataExportMutation,
  useBookACallMutation,
  useWeeklyDigestOptInMutation,
} = appsApi;
