import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import type { SnakeCasedProperties, SnakeCasedPropertiesDeep } from "type-fest";

import { baseQuery } from "core/initializers/rtk";
import { IAudience, IAudienceFilters } from "core/types/Audience";
import { IFilter, JoinOperatorValue } from "core/types/Filters.d";
import { IPagy } from "core/types/Pagy";
import { transformFilterGroups } from "helpers/params";

interface IGetAudiencesArgs {
  appId: number;
  query?: string;
  page?: number;
  pinned?: boolean;
}

interface IGetRecommendedFiltersArgs {
  appId: number;
}

interface IGetAudienceArgs {
  appId: number;
  audienceId: number;
}

interface IGetAudienceCountArgs {
  appId: number;
  audienceFilters: IAudienceFilters;
  level: string;
  groupId?: string;
  token?: string;
  viewInsightId?: number;
}

interface IGetAudienceCountResponse {
  count: number;
  totalCount: number;
  status: number;
}

interface IDeleteAudienceResponse {
  status: number;
}

export type IGetAudiencesResponse = {
  audiences: IAudience[];
  pagy: IPagy;
};

type IGetRecommendedFiltersResponse = {
  recommendedFilters: IFilter[];
};

interface ISaveAudienceArgs {
  appId: number;
  name: string;
  description: string;
  audienceFilters: IAudienceFilters;
  appObjectId?: number;
}

interface IUpdateAudienceArgs {
  audienceId: number;
  appId: number;
  name?: string;
  description?: string | null;
  audienceFilters?: IAudienceFilters;
  appObjectId?: number;
}

interface IDuplicateAudienceArgs {
  appId: number;
  audienceId: number;
}

export const audiencesApi = createApi({
  baseQuery,
  reducerPath: "audiencesApi",
  tagTypes: ["Audience", "Filter", "CrmSyncSetting"],
  endpoints: (builder) => ({
    getAudiences: builder.query<IGetAudiencesResponse, IGetAudiencesArgs>({
      query: ({ appId, query, page, pinned }) => ({
        url: "/audiences",
        params: { app_id: appId, query, page, pinned },
      }),
      providesTags: ["Audience"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetAudiencesResponse>,
      ): IGetAudiencesResponse =>
        humps.camelizeKeys(response) as IGetAudiencesResponse,
    }),
    getRecommendedFilters: builder.query<
      IGetRecommendedFiltersResponse,
      IGetRecommendedFiltersArgs
    >({
      query: ({ appId }) => ({
        url: "/audiences/recommended",
        params: { app_id: appId },
      }),
      providesTags: ["Filter"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetRecommendedFiltersResponse>,
      ): IGetRecommendedFiltersResponse =>
        humps.camelizeKeys(response) as IGetRecommendedFiltersResponse,
    }),
    getAudience: builder.query<IAudience, IGetAudienceArgs>({
      query: ({ appId, audienceId }) => ({
        url: `/audiences/${audienceId}`,
        params: { app_id: appId, audience_id: audienceId },
      }),
      providesTags: ["Audience"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IAudience>,
      ): IAudience => humps.camelizeKeys(response) as IAudience,
    }),
    getAudienceCount: builder.query<
      IGetAudienceCountResponse,
      IGetAudienceCountArgs
    >({
      query: ({
        appId,
        audienceFilters,
        level,
        groupId,
        token,
        viewInsightId,
      }) => ({
        url: `/audiences/count`,
        params: {
          app_id: appId,
          filter_groups: JSON.stringify(
            transformFilterGroups(audienceFilters.filterGroups),
          ),
          join_operator: audienceFilters.joinOperator,
          level: level,
          group_id: groupId,
          token,
          view_insight_id: viewInsightId,
        },
      }),
      providesTags: ["Audience"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetAudienceCountResponse>,
      ): IGetAudienceCountResponse =>
        humps.camelizeKeys(response) as IGetAudienceCountResponse,
    }),
    deleteAudience: builder.mutation<IDeleteAudienceResponse, IGetAudienceArgs>(
      {
        query: ({ appId, audienceId }) => ({
          url: `/audiences/${audienceId}`,
          method: "DELETE",
          params: { app_id: appId },
        }),
        invalidatesTags: ["Audience"],
        transformResponse: (
          response: SnakeCasedProperties<IDeleteAudienceResponse>,
        ) => humps.camelizeKeys(response) as IDeleteAudienceResponse,
      },
    ),
    saveAudience: builder.mutation<IAudience, ISaveAudienceArgs>({
      query: ({ appId, name, description, audienceFilters, appObjectId }) => ({
        url: "/audiences",
        method: "POST",
        params: {
          app_id: appId,
          name,
          description,
          filter_groups: JSON.stringify(
            transformFilterGroups(audienceFilters.filterGroups),
          ),
          join_operator: audienceFilters.joinOperator,
          app_object_id: appObjectId,
        },
      }),
      invalidatesTags: ["Audience"],
      transformResponse: (response: SnakeCasedProperties<IAudience>) =>
        humps.camelizeKeys(response) as IAudience,
    }),
    duplicateAudience: builder.mutation<IAudience, IDuplicateAudienceArgs>({
      query: ({ appId, audienceId }) => ({
        url: `/audiences/${audienceId}/duplicate`,
        method: "PUT",
        params: {
          app_id: appId,
        },
      }),
      invalidatesTags: ["Audience"],
      transformResponse: (response: SnakeCasedProperties<IAudience>) =>
        humps.camelizeKeys(response) as IAudience,
    }),
    updateAudience: builder.mutation<IAudience, IUpdateAudienceArgs>({
      query: ({
        appId,
        name,
        description,
        audienceId,
        audienceFilters,
        appObjectId,
      }) => ({
        url: `/audiences/${audienceId}`,
        method: "PUT",
        body: humps.decamelizeKeys({
          appId: appId,
          name,
          description,
          filterGroups: JSON.stringify(
            transformFilterGroups(audienceFilters?.filterGroups || []),
          ),
          joinOperator: audienceFilters?.joinOperator || JoinOperatorValue.AND,
          appObjectId,
        }),
      }),
      invalidatesTags: ["Audience", "CrmSyncSetting"],
      transformResponse: (response: SnakeCasedProperties<IAudience>) =>
        humps.camelizeKeys(response) as IAudience,
    }),
    pinAudience: builder.mutation<
      IAudience,
      { appId: number; audienceId: number; pinned: boolean }
    >({
      query: ({ appId, audienceId, pinned }) => ({
        url: `/audiences/${audienceId}/pin`,
        method: "PUT",
        body: humps.decamelizeKeys({
          appId,
          id: audienceId,
          pinned,
        }),
      }),
      invalidatesTags: ["Audience"],
      transformResponse: (response: SnakeCasedProperties<IAudience>) =>
        humps.camelizeKeys(response) as IAudience,
    }),
  }),
});

export const {
  useLazyGetAudiencesQuery,
  useGetAudiencesQuery,
  useGetRecommendedFiltersQuery,
  useGetAudienceQuery,
  useLazyGetAudienceQuery,
  useGetAudienceCountQuery,
  useDuplicateAudienceMutation,
  useDeleteAudienceMutation,
  useSaveAudienceMutation,
  useUpdateAudienceMutation,
  usePinAudienceMutation,
} = audiencesApi;
