import { Divider } from "@chakra-ui/react";

import { useGetChangelogsQuery } from "core/models/changelogs";
import { MenuItem } from "modules/HelpCenter/MenuItem";

export const Changelogs: React.FC = () => {
  const { data: changelogs, isLoading, error } = useGetChangelogsQuery();

  if (error || isLoading) {
    return null;
  }

  return (
    <>
      {changelogs?.map((changelog, index) => (
        <MenuItem
          key={index}
          href={`https://changelog.june.so/changelogs/${changelog.slug}`}
          subtitle={`${new Date(changelog.publishedAt).toLocaleDateString(
            "en-US",
            {
              month: "short",
              day: "numeric",
            },
          )} release notes`}
          label={changelog.title}
        />
      ))}
      <MenuItem href="https://changelog.june.so" label="View more.." />
      <Divider />
    </>
  );
};
