import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Divider,
} from "@chakra-ui/react";

import { PaywallPopover } from "core/components/Paywall/Popover";
import { useEditDashboard } from "core/hooks/useEditDashboard";
import { useEditViewInsight } from "core/hooks/useEditViewInsight";
import { IViewInsight } from "core/models/viewInsights";
import { Plan } from "core/types/App";
import { State } from "core/types/ViewInsight";
import { PreviewContainer } from "modules/ViewInsight/Audience/PreviewContainer";
import { SetupContainer } from "modules/ViewInsight/Audience/SetupContainer";

export const AudienceModal: React.FC<{
  viewInsight: IViewInsight;
  onClose: () => void;
  isOpen: boolean;
}> = ({ viewInsight, onClose, isOpen }) => {
  const { onUpdateInsight } = useEditViewInsight({
    viewInsight,
    loadReport: false,
  });
  const { clearOpenedViewInsight } = useEditDashboard();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent maxH="98vh" minH="98vh" w="99vw" borderRadius="lg">
        <ModalHeader>Add audience insight</ModalHeader>
        <ModalCloseButton />
        <ModalBody h="full" display="flex" flexDirection="column">
          <div className="flex h-full w-full flex-grow gap-x-4">
            <div className="flex w-3/4 flex-grow flex-col">
              <PreviewContainer
                key={viewInsight.audience?.updatedAt}
                viewInsight={viewInsight}
                onUpdateInsight={onUpdateInsight}
              />
            </div>
            <div className="flex w-1/4 flex-grow flex-col">
              <div className="flex flex-grow flex-col">
                <SetupContainer
                  viewInsight={viewInsight}
                  onUpdateInsight={onUpdateInsight}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <Divider mt={2} />
        <ModalFooter>
          <div className="flex w-full items-center justify-between ">
            <div />
            <div>
              {viewInsight.persisted ? (
                <Button
                  colorScheme="purple"
                  onClick={async () => {
                    await onUpdateInsight({
                      ...viewInsight,
                      state: State.Persisted,
                      audienceId: viewInsight.audience?.id,
                    });
                    clearOpenedViewInsight();
                    onClose();
                  }}
                >
                  Done
                </Button>
              ) : (
                <>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                  <PaywallPopover
                    feature="SQL insights"
                    redirect="home"
                    plan={Plan.Pro}
                  >
                    <Button
                      colorScheme="purple"
                      onClick={async () => {
                        await onUpdateInsight({
                          state: State.Persisted,
                          audienceId: viewInsight.audience?.id,
                        });
                        clearOpenedViewInsight();
                        onClose();
                      }}
                    >
                      Add
                    </Button>
                  </PaywallPopover>
                </>
              )}
            </div>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
