import React from "react";
import { Navigate } from "react-router-dom";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import useSmartTrait from "core/hooks/useSmartTrait";
import { ComputedTraitEditor } from "routes/Settings/sections/ComputedTraitEditor";

export const NewComputedTrait: React.FC = () => {
  const app = useCurrentApp();
  const { onCreate } = useSmartTrait({});

  if (window.location.pathname.includes("settings/computed-traits/new")) {
    return <Navigate to={`/a/${app.id}/integrations/computed-traits/new`} />;
  }

  return <ComputedTraitEditor onSave={onCreate} />;
};
