import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { scale } from "chroma-js";
import { orderBy } from "lodash";

import TickText from "core/design-system/charts//TickText";
import {
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { Line, LineChart } from "core/design-system/charts/LineChart";
import colors from "core/design-system/constants/theme/colors";

const getAggregatedData = (data: {
  data: {
    x: string;
    y: number;
  }[];
}) => {
  if (data?.data?.length === 1)
    return [
      {
        x: "W0",
        y: 0,
      },
      {
        x: "W1",
        y: 0,
      },
    ];

  return data?.data || [];
};

const useRetentionSeries = ({ data, selectedCohorts, selectedAggregated }) => {
  const cohorts = data.retentionDataByCohort.filter((co) =>
    selectedCohorts.includes(co.id),
  );
  const areMultipleCohortsSelected = selectedCohorts.length > 0;

  const aggregatedData = {
    name: "Aggregated",
    color: areMultipleCohortsSelected ? colors.red[500] : colors.purple[400],
    data: data.retentionData[0].data,
  };

  const cohortColors = selectedAggregated
    ? scale([colors.purple[400], colors.purple[100]])
        .mode("lch")
        .colors(selectedCohorts.length)
    : scale([
        colors.templateColors.orange.primary,
        colors.templateColors.purple.primary,
        colors.templateColors.green.primary,
        colors.templateColors.red.primary,
        colors.templateColors.pink.primary,
      ])
        .mode("lch")
        .colors(selectedCohorts.length - 1);

  const cohortsData = cohorts.map((cohort, idx) => ({
    ...cohort,
    color: cohortColors[idx],
  }));

  const domain = data.retentionData[0].data.map((d) => d.x);

  return {
    aggregatedData,
    cohortsData,
    domain,
  };
};

const CustomTooltip = ({ label, payload, active, ...props }) => {
  if (active && payload && payload.length) {
    return (
      <Box bg="gray.700" px={3} py={2} borderRadius={5} {...props}>
        <Text fontSize="xs" color="gray.400">
          {label}
        </Text>
        {orderBy(payload, "payload.y", "desc").map((p, idx) => (
          <Stack direction="row" spacing="2" align="center">
            <Box h="2" w="2" bg={p.color} borderRadius={10}></Box>
            <Text key={idx} fontSize="sm" color="white">
              {p.payload.y.toFixed(1)}% retention
            </Text>
            <Text color="gray.400" fontSize="xs">
              {p.name}
            </Text>
          </Stack>
        ))}
      </Box>
    );
  }
  return null;
};

export const RetentionGraph = ({
  data,
  selectedCohorts,
  selectedAggregated,
  showYAxis = true,
}) => {
  const { aggregatedData, cohortsData, domain } = useRetentionSeries({
    data,
    selectedCohorts,
    selectedAggregated,
  });

  return (
    <Flex
      pt={5}
      direction="row"
      align="center"
      justify="flex-start"
      h="300px"
      w="100%"
    >
      <LineChart
        margin={{ top: 20, left: showYAxis ? 0 : -20, right: 40, bottom: 20 }}
        height={300}
      >
        <CommonGrid />
        {/* @ts-ignore */}
        <CommonXAxis
          domain={domain}
          type="category"
          allowDuplicatedCategory={false}
          dataKey="x"
        />
        {/* @ts-ignore */}
        <CommonYAxis
          tick={({ x, ...props }) =>
            showYAxis && (
              <TickText x={x - 5} {...props}>
                {props.payload.value} %
              </TickText>
            )
          }
        />
        {/* @ts-ignore */}
        <CommonTooltip content={<CustomTooltip />} />
        {cohortsData.map((cohort, idx) => (
          <Line
            dot={false}
            key={idx}
            dataKey="y"
            data={cohort.data}
            name={cohort.id}
            stroke={cohort.color}
            strokeWidth={1.5}
          />
        ))}
        {selectedAggregated && (
          <Line
            dot={false}
            dataKey="y"
            data={getAggregatedData(aggregatedData)}
            stroke={aggregatedData.color}
            strokeDasharray={selectedCohorts.length > 0 ? "3 3" : null}
            name={aggregatedData.name}
            strokeWidth={1.5}
          />
        )}
      </LineChart>
    </Flex>
  );
};
