import { Button, Flex, useDisclosure } from "@chakra-ui/react";

import { useDataDeletionRequests } from "core/hooks/useDataDeletionRequests";
import { HistoryModal } from "modules/Settings/DataDeletion/HistoryModal";
import { RequestModal } from "modules/Settings/DataDeletion/RequestModal";

export const DataDeletion: React.FC = () => {
  const {
    isOpen: isHistoryOpen,
    onOpen: onHistoryOpen,
    onClose: onHistoryClose,
  } = useDisclosure();
  const {
    isOpen: isRequestOpen,
    onOpen: onRequestOpen,
    onClose: onRequestClose,
  } = useDisclosure();

  const { dataDeletionRequests } = useDataDeletionRequests();

  return (
    <>
      <div className="align-center flex w-full justify-between">
        <div className="flex flex-col">
          <div className="mb-1 text-sm font-semibold">Delete data</div>
          <div className="text-xs">
            Delete all the data collected by June up until your selected date.
          </div>
        </div>
        <Flex>
          {dataDeletionRequests?.length > 0 && (
            <Button mt={2} mr={4} px={4} onClick={onHistoryOpen}>
              <div className="text-sm">View history</div>
            </Button>
          )}
          <Button colorScheme={"red"} mt={2} px={4} onClick={onRequestOpen}>
            <div className="text-sm">Delete data</div>
          </Button>
        </Flex>
      </div>
      <HistoryModal isOpen={isHistoryOpen} onClose={onHistoryClose} />
      <RequestModal isOpen={isRequestOpen} onClose={onRequestClose} />
    </>
  );
};
