import { Combobox } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

import { AvatarIcon } from "core/design-system/components/Icon/AvatarIcon";
import { classNames } from "core/helpers/classNames";
import {
  generateRandomAnimalName,
  getContactAvatar,
  hashStringToNumber,
  IGetContactAvatarProps,
} from "core/helpers/contactAvatar";
import { getContactEmail } from "core/helpers/contactEmail";
import { IContact } from "core/types/Contact";

export const UserResultOption: React.FC<{
  result: IContact;
  active: boolean;
}> = ({ result, active }) => {
  const avatarURL = getContactAvatar(result as IGetContactAvatarProps);
  const userId =
    typeof result?.id === "number"
      ? result?.id
      : hashStringToNumber(result?.id);
  const email = getContactEmail(result as IContact);

  return (
    <>
      {avatarURL ? (
        <img
          src={avatarURL}
          alt=""
          className="h-6 w-6 flex-none rounded-full"
        />
      ) : (
        <AvatarIcon h={6} w={6} name={generateRandomAnimalName(userId)} />
      )}

      <span className="ml-3 flex-auto truncate">{email}</span>
      {active && (
        <ChevronRightIcon
          className="ml-3 size-5 flex-none text-gray-400"
          aria-hidden="true"
        />
      )}
    </>
  );
};

export const UserResultOptions: React.FC<{ searchResults: IContact[] }> = ({
  searchResults,
}) => {
  return (
    <>
      {searchResults.map((result: IContact) => (
        <Combobox.Option
          as="div"
          key={result.id}
          value={result}
          className={({ active }) =>
            classNames(
              "flex cursor-default select-none items-center rounded-md p-2",
              active && "bg-gray-100 text-gray-900",
            )
          }
        >
          {({ active }) => (
            <UserResultOption result={result as IContact} active={active} />
          )}
        </Combobox.Option>
      ))}
    </>
  );
};
