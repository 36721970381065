import { SmallAddIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Spinner,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { Icon } from "core/design-system/components/Icon";
import { useAudiences } from "core/hooks/useAudiences";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useReportSetup } from "core/hooks/useReportSetup";
import { AddSavedAudienceButton } from "core/modules/reports/setup/Audience/AddSavedAudienceButton";
import { SavedAudienceTooltipPreview } from "core/modules/reports/setup/Audience/SavedAudienceTooltipPreview";
import { IAudience } from "core/types/Audience";

export const SavedAudiences: React.FC<{
  onUpdateReport?: () => void;
}> = ({ onUpdateReport }) => {
  const currentApp = useCurrentApp();
  const { currentReport, addSavedAudience } = useReportSetup();
  const { audiences, isLoading } = useAudiences({ appId: currentApp.id });
  const savedAudiences = audiences?.audiences || [];

  function onSelectSavedAudience({ audience }: { audience: IAudience }) {
    addSavedAudience({ audience });
    onUpdateReport && onUpdateReport();
  }

  const isExistingSavedAudience = (audience: IAudience) => {
    if (!currentReport) return false;

    return currentReport?.audience?.name !== audience.name;
  };

  if (!currentApp || !currentReport) {
    return null;
  }

  const visibleSavedAudiences = savedAudiences.filter((audience: IAudience) =>
    isExistingSavedAudience(audience),
  );

  const hasAudienceFilters = currentReport?.audience?.filterGroups?.some(
    (filterGroup) => filterGroup?.filters?.length > 0,
  );
  let tooltipLabel: string | undefined;
  if (currentReport?.audience?.isSavedAudience) {
    tooltipLabel = "You can only use one Saved Audience at a time";
  } else if (!currentReport?.audience?.isSavedAudience && hasAudienceFilters) {
    tooltipLabel =
      "You can only use a Saved Audience or custom filters, not both";
  }

  const isAddingSavedAudienceDisabled =
    currentReport?.audience?.isSavedAudience ||
    (!currentReport?.audience?.isSavedAudience && hasAudienceFilters);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrap data-testid="saved-audiences-container">
      {visibleSavedAudiences
        .slice(0, 5)
        .map((audience: IAudience, index: number) => (
          <WrapItem key={index}>
            <Tooltip
              w="full"
              maxW="900px"
              placement="bottom"
              hasArrow
              shouldWrapChildren
              label={
                isAddingSavedAudienceDisabled ? (
                  tooltipLabel
                ) : (
                  <SavedAudienceTooltipPreview audience={audience} />
                )
              }
            >
              <Button
                isDisabled={isAddingSavedAudienceDisabled}
                borderRadius="lg"
                bg="gray.100"
                border="none"
                color="gray.900"
                _hover={{ bg: "gray.200" }}
                onClick={() => {
                  onSelectSavedAudience({ audience });
                }}
              >
                <Flex align={"center"} justify={"space-between"}>
                  <Flex pr={2} align={"center"} color="black">
                    <Icon
                      iconType="icon"
                      name="audiences"
                      h={4}
                      w={4}
                      mr={1.5}
                    />
                    <Text
                      fontSize={"sm"}
                      fontWeight="semibold"
                      data-testid="saved-audience-name"
                      mr={1}
                    >
                      {audience.name}
                    </Text>
                  </Flex>
                  <SmallAddIcon h={4} w={4} />
                </Flex>
              </Button>
            </Tooltip>
          </WrapItem>
        ))}
      <WrapItem>
        <AddSavedAudienceButton
          isDisabled={isAddingSavedAudienceDisabled}
          onSelectSavedAudience={onSelectSavedAudience}
        />
      </WrapItem>
    </Wrap>
  );
};
