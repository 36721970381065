import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const BadgeShape: React.FC<{
  empty?: boolean;
  color?: IconProps["color"];
  width?: IconProps["width"];
}> = ({ empty, color, width }) => {
  return (
    <Icon
      height="auto"
      color={empty ? "gray.100" : color}
      viewBox="0 0 40 48"
      width={width}
    >
      <path
        d="M0.909088 16.7767V29.1794V29.2267C0.909079 30.732 0.90907 31.9287 1.00173 32.9067C1.09727 33.915 1.29613 34.7622 1.73402 35.5558C2.17192 36.3493 2.77778 36.9605 3.57155 37.5638C4.34143 38.1489 5.34195 38.7653 6.60032 39.5406L6.64 39.565L15.648 45.1148L15.6875 45.1391C16.5582 45.6755 17.2693 46.1137 17.888 46.4129C18.5346 46.7254 19.146 46.9207 19.8255 46.9235C20.505 46.9263 21.118 46.7366 21.7671 46.4295C22.3883 46.1356 23.103 45.7036 23.978 45.1747L24.0177 45.1507L33.275 39.5549L33.315 39.5307C34.5917 38.7591 35.6066 38.1456 36.3877 37.5614C37.193 36.9592 37.8079 36.347 38.2528 35.5485C38.6977 34.7501 38.8998 33.8961 38.9968 32.879C39.091 31.8925 39.0909 30.6846 39.0909 29.1652V29.1175V16.7767V16.7046C39.0909 13.2507 39.091 10.5634 38.8165 8.47093C38.5367 6.33712 37.9566 4.68293 36.6945 3.38893C35.4324 2.09494 33.819 1.50022 31.7377 1.21334C29.6967 0.932014 27.0755 0.932024 23.7067 0.932038H23.6364H16.3636H16.2933C12.9245 0.932024 10.3033 0.932014 8.26236 1.21334C6.18109 1.50022 4.56763 2.09494 3.30549 3.38893C2.04336 4.68293 1.46328 6.33712 1.18346 8.47093C0.909066 10.5634 0.909075 13.2507 0.909088 16.7046V16.7767Z"
        fill="currentColor"
        stroke="white"
        stroke-width="2"
      />
    </Icon>
  );
};
