import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

import { ILabel } from "core/types/Label";

interface ILabelPillProps {
  label: ILabel;
  setSelectedLabels?: (labels: ILabel[]) => void;
}

export const LabelPill: React.FC<ILabelPillProps> = ({
  label,
  setSelectedLabels,
}) => {
  return (
    <Flex
      data-testid="label-pill-container"
      h={6}
      ml={1}
      px={1.5}
      py={0.5}
      align="center"
      border="1px"
      borderColor="gray.200"
      borderRadius="2xl"
      cursor="pointer"
      onClick={() => {
        if (!setSelectedLabels) return;

        setSelectedLabels([label]);
      }}
    >
      <Box bg={label.color} minW={2} w={2} h={2} borderRadius="50%" />
      <Tooltip hasArrow placement="bottom" label={label.name}>
        <Text
          data-testid="label-name-cell-text"
          ml={2}
          fontSize="xs"
          color="gray.700"
          noOfLines={1}
        >
          {label.name}
        </Text>
      </Tooltip>
    </Flex>
  );
};
