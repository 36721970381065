import { createIcon } from "@chakra-ui/icons";
import React from "react";

const TrashIcon = createIcon({
  viewBox: "0 0 32 32",
  path: (
    <path
      d="M29 6H21C21 4.67392 20.4732 3.40215 19.5355 2.46447C18.5979 1.52678 17.3261 1 16 1C14.6739 1 13.4021 1.52678 12.4645 2.46447C11.5268 3.40215 11 4.67392 11 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11H4L6.57 28.86C6.6534 29.4478 6.94409 29.9865 7.38966 30.3789C7.83523 30.7713 8.40634 30.9916 9 31H22.84C23.4388 30.9987 24.0172 30.7817 24.469 30.3886C24.9208 29.9956 25.2158 29.4529 25.3 28.86L27.86 11H29C29.2652 11 29.5196 10.8946 29.7071 10.7071C29.8946 10.5196 30 10.2652 30 10V7C30 6.73478 29.8946 6.48043 29.7071 6.29289C29.5196 6.10536 29.2652 6 29 6ZM12.56 26.07H12.44C12.0585 26.0948 11.6826 25.9679 11.3942 25.7169C11.1058 25.466 10.9282 25.1113 10.9 24.73L10.26 15.63C10.2352 15.2485 10.3621 14.8726 10.6131 14.5842C10.864 14.2958 11.2187 14.1182 11.6 14.09H11.72C12.1015 14.0652 12.4774 14.1921 12.7658 14.4431C13.0542 14.694 13.2318 15.0487 13.26 15.43L13.9 24.53C13.9248 24.9115 13.7979 25.2873 13.5469 25.5758C13.296 25.8642 12.9413 26.0418 12.56 26.07ZM21.09 24.74C21.0618 25.1213 20.8842 25.476 20.5958 25.7269C20.3074 25.9779 19.9315 26.1048 19.55 26.08H19.43C19.0487 26.0518 18.694 25.8742 18.4431 25.5858C18.1921 25.2973 18.0652 24.9215 18.09 24.54L18.73 15.44C18.7582 15.0587 18.9358 14.704 19.2242 14.4531C19.5126 14.2021 19.8885 14.0752 20.27 14.1H20.39C20.7713 14.1282 21.126 14.3058 21.3769 14.5942C21.6279 14.8826 21.7548 15.2585 21.73 15.64L21.09 24.74ZM16 4C16.5304 4 17.0391 4.21071 17.4142 4.58579C17.7893 4.96086 18 5.46957 18 6H14C14 5.46957 14.2107 4.96086 14.5858 4.58579C14.9609 4.21071 15.4696 4 16 4Z"
      fill="currentColor"
    />
  ),
});

export default TrashIcon;
