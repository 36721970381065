import { useState } from "react";
import { useParams } from "react-router-dom";

import ApiKeys from "@/modules/Settings/ApiKeys";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { useGetChartQuery } from "core/models/debugger";
import { Chart } from "modules/Developers/Logs/Chart";
import { CompleteSetup } from "modules/Developers/Overview/CompleteSetup";
import { Issues } from "modules/Developers/Overview/Issues";

export const OverviewContainer: React.FC = () => {
  const { appId } = useParams();
  const [days, setDays] = useState(7);
  const { data: chartData } = useGetChartQuery({ appId: Number(appId), days });
  const { onboardingChecklist } = useOnboardingChecklist();

  return (
    <div className="grid grid-cols-2 gap-10">
      <div className="flex flex-col gap-10">
        <Chart
          chartData={chartData ?? { result: [] as never }}
          days={days}
          setDays={setDays}
        />
        <div>
          <div className="mb-1 flex flex-row items-center justify-between border-b pb-4">
            <p className="text-lg font-semibold">Issues</p>
          </div>
          <Issues />
        </div>
      </div>
      <div className="flex flex-col gap-10">
        <div>
          <div className="mb-0 flex flex-row items-center justify-between border-b pb-4">
            <p className="text-lg font-semibold">Complete setup</p>
          </div>
          <CompleteSetup />
        </div>
        <div>
          <div className="mb-4 flex flex-row items-center justify-between border-b pb-4">
            <p className="text-lg font-semibold">Write API keys</p>
          </div>
          {onboardingChecklist?.dataSource && (
            <ApiKeys
              disableActions={true}
              source={onboardingChecklist?.dataSource}
            />
          )}
        </div>
      </div>
    </div>
  );
};
