import { SyntheticEvent } from "react";
import { useParams } from "react-router-dom";

import { useEditDashboard } from "@/core/hooks/useEditDashboard";
import { FUNNEL, REPORT_CONFIGS } from "core/constants/report-configurations";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  COLOR_NAME_TO_PALETTE,
  GRID_WIDTH_TO_SIZE,
} from "core/hooks/useEditViewInsight";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { usePaywall } from "core/hooks/usePaywall";
import { useToast } from "core/hooks/useToast";
import {
  IViewInsight,
  useAddViewInsightMutation,
  useDeleteViewInsightMutation,
} from "core/models/viewInsights";
import { AppObjectType } from "core/types/AppObject";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";
import { Interval } from "core/types/ViewInsight";
import { INTERVAL_TYPES } from "modules/ViewInsight/Builder/Preview/Container";
import { ViewInsightCard } from "modules/ViewInsight/CardContainer";
import { IViewInsightContainer } from "modules/ViewInsight/ViewInsight";
import { ViewInsightComponent } from "modules/ViewInsight/ViewInsightComponent";

export function getReportConfigByType(reportType: number) {
  return REPORT_CONFIGS.find((config) => config.reportType === reportType);
}

function findInsightByTypeId(
  reportConfig: ITemplateConfig,
  insightTypeId: number | undefined,
) {
  return reportConfig.insights.find(
    (insight: IInsightSection) => insight.typeId === insightTypeId,
  );
}

export function getInsightConfig(
  reportType: number,
  insightTypeId: number | undefined,
): IInsightSection | undefined | null {
  const reportConfig = getReportConfigByType(reportType);
  return reportConfig
    ? findInsightByTypeId(reportConfig, insightTypeId)
    : undefined;
}

export function buildData(
  insightConfig: IInsightSection | undefined | null,
  viewInsight: IViewInsight,
  response: any,
) {
  return insightConfig?.view
    ? insightConfig?.view?.transform(response?.data, viewInsight.interval)
    : [];
}

export const ViewInsightContainer: React.FC<IViewInsightContainer> = ({
  view,
  viewInsight,
  isAdding,
  isAdded,
  viewId,
  isBlurred,
  onSetViewInsight,
  sharingSecretToken,
  isSlackInsight,
}) => {
  const toast = useToast();
  const { openedInsight } = useEditDashboard();
  const { audience: internalUsersAudience } = useCurrentApp();
  const { shouldBePaywalled } = usePaywall();
  const {
    appId,
    viewId: paramViewId,
    groupId,
    objectId,
    appObjectId,
  } = useParams();
  const { appObjects } = useAppObjects();
  const insightAppObject = appObjects.find(
    (appObject) => appObject.id === Number(appObjectId),
  );
  const _viewId = viewId ? viewId : paramViewId;
  const [deleteViewInsight] = useDeleteViewInsightMutation();
  const [addViewInsight] = useAddViewInsightMutation();

  function getAudience() {
    if (
      (Boolean(groupId) || Boolean(objectId)) &&
      viewInsight?.view?.useInternalUsers
    ) {
      return internalUsersAudience;
    }
    return viewInsight.useViewAudience ? viewInsight.view.audience : undefined;
  }

  const params = {
    report: viewInsight.report,
    view,
    viewInsight,
    insightType: viewInsight.insightId,
    insightParams: {
      ...viewInsight.insight?.defaultParams,
      interval_type:
        INTERVAL_TYPES[viewInsight.interval as unknown as Interval],
      size: GRID_WIDTH_TO_SIZE[viewInsight.gridWidth],
      breakdown_enabled:
        viewInsight.insight.reportType === FUNNEL &&
        Boolean(viewInsight.report.config.breakdown)
          ? false
          : undefined,
      aggregation: viewInsight.aggregation,
      graphType: viewInsight.graphType,
    },
    sharingSecretToken,
    previewMode: false,
    groupType:
      groupId || objectId
        ? insightAppObject?.slug
        : viewInsight?.appObject?.slug,
    timerangeType: viewInsight?.timerangeType,
    timerangeValue: viewInsight?.timerangeValue,
    timerangeStartTimestamp: viewInsight?.timerangeStartTimestamp,
    timerangeEndTimestamp: viewInsight?.timerangeEndTimestamp,
    audience: getAudience(),
    skip: openedInsight?.viewInsight?.id
      ? openedInsight?.viewInsight?.id !== viewInsight.id
      : false,
  };

  const { response, isLoading, isFetching } = useInsightDataFetching(params);
  const { isSetupComplete } = useOnboardingChecklist();

  const insightConfig = getInsightConfig(
    viewInsight.insight.reportType,
    viewInsight.insightId,
  );

  if (viewInsight.insightId === undefined) return null;

  const data = buildData(insightConfig, viewInsight, response);
  const highlight =
    response?.data?.highlight ??
    insightConfig?.view?.highlight?.(response?.data) ??
    {};
  const timerange = insightConfig?.view?.timerange
    ? insightConfig?.view?.timerange(viewInsight)
    : "";

  const isPaywalled =
    (shouldBePaywalled &&
      viewInsight?.appObject?.objectType &&
      [AppObjectType.Company, AppObjectType.Group].includes(
        viewInsight?.appObject?.objectType,
      )) ||
    false;
  const filter =
    isPaywalled || isBlurred
      ? "blur(10px)"
      : !isSetupComplete
        ? "grayscale(0.8)"
        : "none";
  const color = COLOR_NAME_TO_PALETTE[viewInsight?.color as string];

  return (
    <ViewInsightCard
      viewInsight={viewInsight}
      isAdding={isAdding}
      isAdded={isAdded}
      isBlurred={isBlurred}
      isPaywalled={isPaywalled}
      isEmpty={data?.length === 0}
      groupId={groupId || objectId}
      highlight={highlight}
      title={viewInsight.title || response?.reportName}
      showHighlightDescription
      description={viewInsight.insight.name}
      isLoading={isLoading}
      isFetching={isFetching}
      timerange={!isAdding && !isBlurred && timerange ? timerange : ""}
      hasTimerangePicker={insightConfig?.view?.hasTimerangePicker}
      dateRangeOptions={insightConfig?.view?.dateRangeOptions}
      reachedLimit={false}
      isSlackInsight={isSlackInsight}
      onDelete={(e: SyntheticEvent) => {
        e.preventDefault();
        deleteViewInsight({
          id: viewInsight.id,
          appId: viewInsight.appId,
        }).then(() => {
          toast({
            title: "Insight removed",
            description: "The insight has been removed from the view",
            status: "success",
          });
        });
      }}
      onAdd={(e: SyntheticEvent) => {
        e.preventDefault();
        addViewInsight({
          appId: Number(appId),
          viewId: Number(_viewId),
          reportId: Number(viewInsight.reportId),
          insightId: Number(viewInsight.insightId),
          objectType: viewInsight?.appObject?.objectType,
        }).then(() => {
          toast({
            title: "Insight added",
            description: "The insight has been added to the view",
            status: "success",
          });
        });
      }}
      color={color}
      onSetViewInsight={onSetViewInsight}
    >
      <ViewInsightComponent
        viewInsight={viewInsight}
        data={data}
        isSlackInsight={isSlackInsight}
        filter={filter}
        opacity={!isSetupComplete ? 0.4 : undefined}
      />
    </ViewInsightCard>
  );
};
