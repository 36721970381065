import { Route, Routes } from "react-router-dom";

import { Error } from "@/Components/Error";
import { TooltipProvider } from "@/Components/ui/tooltip";
import routes from "@/routes";
import { Toasts } from "Components/Toasts";

export const UnauthenticatedApp = () => {
  return (
    <>
      <TooltipProvider>
        <Toasts />
        <Error />
        <Routes>
          {[...routes.indifferentRoutes, ...routes.unauthenticatedRoutes].map(
            ({ path, component: Component }, index) => {
              return <Route path={path} element={<Component />} key={index} />;
            },
          )}
        </Routes>
      </TooltipProvider>
    </>
  );
};
