import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import type { SnakeCasedPropertiesDeep } from "type-fest";

import { baseQuery } from "core/initializers/rtk";
import { Integration } from "core/models/dataMappings";
import { IAppObject, AppObjectType } from "core/types/AppObject";

export enum CrmSyncStatus {
  Enqueued = "enqueued",
  Started = "started",
  Failed = "failed",
  Succeeded = "succeeded",
}

export enum CrmSyncTriggerType {
  Automatic = "automatic",
  Manual = "manual",
}

export enum CrmSyncType {
  Incremental = "incremental",
  Full = "full",
}

export interface ICrmSync {
  id: number;
  appId: number;
  appObject: IAppObject;
  status: CrmSyncStatus;
  integration: Integration;
  triggerType: CrmSyncTriggerType;
  syncType: CrmSyncType;
  createdAt: string;
  updatedAt: string;
  objectsSyncedCount: number;
  duration: string;
  totalObjectsCount: number;
}

export const crmSyncsApi = createApi({
  baseQuery,
  reducerPath: "crmSyncsApi",
  tagTypes: ["CrmSyncs"],
  endpoints: (builder) => ({
    getLastCrmSync: builder.query<
      ICrmSync,
      { appId: number; integration: Integration; objectType: AppObjectType }
    >({
      query: ({ appId, integration, objectType }) => ({
        url: `/crm_syncs/last_sync`,
        params: humps.decamelizeKeys({ appId, integration, objectType }),
      }),
      providesTags: ["CrmSyncs"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<ICrmSync>,
      ): ICrmSync => humps.camelizeKeys(response) as ICrmSync,
    }),
    triggerSync: builder.mutation<
      void,
      {
        appId: number;
        integration: Integration;
        objectType: AppObjectType;
        triggerType: CrmSyncTriggerType;
        syncType: CrmSyncType;
      }
    >({
      query: ({ appId, integration, objectType, triggerType, syncType }) => ({
        url: `/crm_syncs/trigger_sync`,
        method: "POST",
        body: humps.decamelizeKeys({
          appId,
          integration,
          objectType,
          triggerType,
          syncType,
        }),
      }),
      invalidatesTags: ["CrmSyncs"],
    }),
  }),
});

export const {
  useGetLastCrmSyncQuery,
  useLazyGetLastCrmSyncQuery,
  useTriggerSyncMutation,
} = crmSyncsApi;
