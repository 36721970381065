import {
  Button,
  Center,
  ComponentDefaultProps,
  Tooltip,
} from "@chakra-ui/react";
import {
  CodeBracketSquareIcon,
  LockClosedIcon,
  PencilSquareIcon,
} from "@heroicons/react/16/solid";
import { MoonIcon } from "@heroicons/react/24/solid";
import { Maximize2 } from "lucide-react";
import React, { SyntheticEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Tooltip as ShadTooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/Components/ui/tooltip";
import { FEATURE_RELEASE } from "@/core/constants/report-configurations";
import { ViewLocation } from "@/core/hooks/useViews";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { Container, Header, Body, Title } from "core/components/ViewInsight";
import { ABSOLUTE } from "core/constants/timerange";
import { useIsEditingView } from "core/contexts/EditingViewContext";
import { IColorPalette } from "core/design-system/constants/theme/colors";
import { useEditDashboard } from "core/hooks/useEditDashboard";
import { GRID_WIDTH_TO_SIZE } from "core/hooks/useEditViewInsight";
import { useInsightTitle } from "core/hooks/useInsightTitle";
import { useNavigateToReport } from "core/hooks/useNavigateToReport";
import { useToast } from "core/hooks/useToast";
import { useViewInsightTimerange } from "core/hooks/useViewInsightTimerange";
import { IQueryResult } from "core/models/sql";
import { IViewInsight } from "core/models/viewInsights";
import { TimerangePicker } from "core/modules/reports/Timerange/TimerangePicker";
import { Plan } from "core/types/App";
import { IHightlight } from "core/types/TemplateConfig";
import { IGroupedTimerangeOption } from "core/types/TimerangeOption";
import { GraphType, Measure, Size } from "core/types/ViewInsight";
import { FullInsightModal } from "modules/ViewInsight/FullInsightModal";
import { OverflowMenu } from "modules/ViewInsight/OverflowMenu";
import {
  FEATURE_WEEKLY_USAGE_INSIGHT_IDS,
  hasDrillDowns,
} from "modules/ViewInsight/ViewInsightComponent";

interface IViewInsightCard extends ComponentDefaultProps {
  viewInsight: IViewInsight;
  isPaywalled: boolean;
  paywallPlan?: Plan;
  paywallFeature?: string;
  isEmpty: boolean;
  isLoading: boolean;
  isFetching: boolean;
  showHighlightDescription: boolean;
  reachedLimit: boolean;
  description?: string;
  title: string;
  timerange: string;
  hasTimerangePicker?: boolean;
  dateRangeOptions?: IGroupedTimerangeOption[];
  onDelete: (e: SyntheticEvent) => void;
  onAdd: (e: SyntheticEvent) => void;
  // optional
  highlight?: IHightlight;
  isAdding?: boolean;
  isAdded?: boolean;
  isBlurred?: boolean;
  isAI?: boolean;
  groupId?: string;
  color?: IColorPalette;
  result?: IQueryResult;
  isSlackInsight?: boolean;
  bodyProps?: ComponentDefaultProps;
}

function hasFullInsightView(viewInsight: IViewInsight) {
  if (viewInsight.query) return true;

  if (
    viewInsight.report.reportType === FEATURE_RELEASE &&
    [
      FEATURE_WEEKLY_USAGE_INSIGHT_IDS.USER_DAILY,
      FEATURE_WEEKLY_USAGE_INSIGHT_IDS.USER_WEEKLY,
      FEATURE_WEEKLY_USAGE_INSIGHT_IDS.USER_MONTHLY,
      FEATURE_WEEKLY_USAGE_INSIGHT_IDS.GROUP_DAILY,
      FEATURE_WEEKLY_USAGE_INSIGHT_IDS.GROUP_WEEKLY,
      FEATURE_WEEKLY_USAGE_INSIGHT_IDS.GROUP_MONTHLY,
    ].includes(viewInsight.insightId as number)
  ) {
    return false;
  }

  if (
    [GraphType.ContributionGraph].includes(viewInsight.graphType as GraphType)
  ) {
    return true;
  }

  if (
    viewInsight.measure === Measure.Features &&
    viewInsight.graphType === GraphType.Table
  ) {
    return true;
  }

  if (!viewInsight.measure) {
    return true;
  }

  return false;
}

export const ViewInsightCardContainer = ({
  viewInsight,
  isAI,
  isAdding,
  isAdded,
  isBlurred,
  isPaywalled,
  paywallPlan = Plan.Growth,
  paywallFeature = "company insights",
  isEmpty,
  groupId,
  highlight,
  description,
  showHighlightDescription,
  title,
  isLoading,
  isFetching,
  timerange,
  hasTimerangePicker,
  dateRangeOptions,
  reachedLimit,
  onDelete,
  onAdd,
  color,
  children,
  result,
  isSlackInsight,
  bodyProps,
  ...props
}: IViewInsightCard & {
  children: React.ReactNode;
}) => {
  const { appId, appObjectId, objectId, viewId } = useParams();
  const toast = useToast();
  const pluralName = viewInsight?.appObject?.pluralName;
  const level = viewInsight?.appObject?.slug;
  const insightTitle = useInsightTitle({ title, name: pluralName });
  const insightDescription = useInsightTitle({
    title: description || "",
    name: pluralName,
  });
  const isEditing = useIsEditingView();

  const {
    onOpenFullModeViewInsight,
    onCloseFullModeViewInsight,
    fullModeInsight,
  } = useEditDashboard();

  const {
    label,
    timerangeType,
    relativeTimerange,
    customTimerange,
    readableCustomTimerange,
    setCustomTimerange,
    saveTimerange,
  } = useViewInsightTimerange(viewInsight);
  const { navigateToReport } = useNavigateToReport({ viewInsight });
  const navigate = useNavigate();

  function openFullModeViewInsight() {
    onOpenFullModeViewInsight(viewInsight);
  }

  const onCardClick = (e: SyntheticEvent) => {
    if (hasDrillDowns(viewInsight)) {
      return;
    }

    if (!isAdding && !isEditing && !isPaywalled && viewInsight.audience) {
      return navigate(
        `/a/${viewInsight.appId}/audience/${viewInsight.audience.id}`,
      );
    }

    if (isEditing && viewInsight.measure) {
      navigate(
        `/a/${appId}/dashboard/${viewInsight.view.id}/graph/${viewInsight.id}`,
      );
      return;
    }

    if (!isAdding && !isEditing && !isPaywalled && !isBlurred) {
      onOpenFullModeViewInsight(viewInsight);
      return;
    }

    if (isAdding && reachedLimit && !isAdded) {
      return toast({
        title: "Limit reached",
        description:
          "You have reached the limit of insights you can pin on this view.",
        status: "error",
      });
    } else if (isAdding && !isAdded) {
      return onAdd(e);
    } else if (isAdding && isAdded) {
      return onDelete(e);
    }

    navigateToReport({
      groupId: groupId,
      level: level,
      skipNavigate: isBlurred,
    });
  };

  const onEditClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    if (viewInsight.view.location === ViewLocation.Company) {
      if (viewInsight.query) {
        navigate(
          `/a/${appId}/objects/${appObjectId}/object/${objectId}/sql/${viewInsight.id}`,
        );
      } else {
        navigate(
          `/a/${appId}/objects/${appObjectId}/object/${objectId}/graph/${viewInsight.id}`,
        );
      }
    } else {
      if (viewInsight.query) {
        navigate(`/a/${appId}/dashboard/${viewId}/sql/${viewInsight.id}`);
      } else {
        navigate(`/a/${appId}/dashboard/${viewId}/graph/${viewInsight.id}`);
      }
    }
  };

  return (
    <>
      <Container
        cursor={isEditing ? "move" : "pointer"}
        onClick={onCardClick}
        data-testid="view-insight-card"
        {...props}
      >
        <div className="flex h-full w-full flex-col">
          <Header>
            <Title
              key={viewInsight.aggregation}
              title={insightTitle}
              description={isAI ? "" : insightDescription}
              highlight={highlight}
              shouldBePaywalled={isBlurred || isPaywalled}
              showHighlightDescription={showHighlightDescription}
              isLoading={isLoading}
              viewInsight={viewInsight}
              icon={
                isAI ? (
                  <Tooltip hasArrow label="SQL insight">
                    <CodeBracketSquareIcon className="h-4 text-gray-500" />
                  </Tooltip>
                ) : null
              }
              color={color}
            >
              <div className="flex w-full items-center justify-end">
                {!isSlackInsight && hasFullInsightView(viewInsight) && (
                  <ShadTooltip>
                    <TooltipTrigger asChild>
                      <div
                        className="relative top-[1px] rounded-sm px-2 py-1 hover:bg-gray-100"
                        onClick={openFullModeViewInsight}
                      >
                        <Maximize2 className="h-[13px] w-[13px] text-gray-500" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Open full insight</p>
                    </TooltipContent>
                  </ShadTooltip>
                )}
                {!(isPaywalled || isBlurred || isSlackInsight) &&
                  !isEditing &&
                  Boolean(viewInsight.measure) && (
                    <Tooltip hasArrow label="Edit insight" placement="top">
                      <Button
                        colorScheme="gray"
                        variant="ghost"
                        size="xs"
                        top="1px"
                        onClick={onEditClick}
                      >
                        <PencilSquareIcon className="h-4 min-h-4 w-4 min-w-4 text-gray-500" />
                        {GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] !==
                        Size.Small ? (
                          <p className="ml-1 text-xs text-gray-500">Edit</p>
                        ) : (
                          <></>
                        )}
                      </Button>
                    </Tooltip>
                  )}

                {!isSlackInsight &&
                  !isPaywalled &&
                  !isAdding &&
                  !isEditing &&
                  hasTimerangePicker && (
                    <TimerangePicker
                      label={label}
                      options={dateRangeOptions || []}
                      timerangeType={timerangeType}
                      relativeTimerange={relativeTimerange}
                      customTimerange={customTimerange}
                      setCustomTimerange={setCustomTimerange}
                      saveTimerange={saveTimerange}
                      size="small"
                      tooltipText={
                        timerangeType === ABSOLUTE
                          ? readableCustomTimerange
                          : undefined
                      }
                      footerText="Insights are in UTC time"
                    />
                  )}
                {!isSlackInsight && !isEditing && !isAdding && (
                  <OverflowMenu viewInsight={viewInsight} result={result} />
                )}

                {(isPaywalled || isBlurred) && !isEditing && (
                  <PaywallPopover
                    feature={paywallFeature}
                    redirect={`group/${groupId}`}
                    plan={paywallPlan}
                  >
                    <LockClosedIcon className="h-4 w-4 text-gray-500" />
                  </PaywallPopover>
                )}
              </div>
            </Title>
          </Header>
          <div className="flex-1">
            <Body isLoading={isLoading || isFetching} {...bodyProps}>
              {!isEmpty && children}
              {isEmpty && (
                <Center w="full" h="full">
                  <div className="flex flex-col items-center gap-4">
                    <div className="rounded  bg-purple-50 p-2">
                      <MoonIcon className="h-4 text-purple-500" />
                    </div>
                    <div className="flex flex-col items-center">
                      <p className="text-xs font-medium">Nothing to see here</p>
                      <p className="text-xs text-gray-600">
                        {hasTimerangePicker
                          ? "Try and increase the time range"
                          : "Come back later to see this insight"}
                      </p>
                    </div>
                  </div>
                </Center>
              )}
            </Body>
          </div>
        </div>
      </Container>
      {fullModeInsight?.viewInsight && (
        <FullInsightModal
          viewInsight={fullModeInsight.viewInsight}
          isOpen={fullModeInsight.viewInsight?.id === viewInsight?.id}
          onClose={() => onCloseFullModeViewInsight()}
          isBlurred={isBlurred}
          groupId={groupId}
        />
      )}
    </>
  );
};

export const ViewInsightCard: React.FC<IViewInsightCard> = ({
  isEmpty,
  children,
  isSlackInsight,
  ...props
}) => {
  return (
    <ViewInsightCardContainer
      isEmpty={isEmpty}
      isSlackInsight={isSlackInsight}
      {...props}
    >
      {!isEmpty && children}
    </ViewInsightCardContainer>
  );
};
