import {
  Box,
  Button,
  DrawerBody,
  DrawerHeader,
  Flex,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";

import { SlackInsightActionButton } from "core/components/InsightCard/SlackInsightActionButton";
import { SlackChannels } from "core/components/Slack/SlackChannels";
import { CompanyJuneLogoIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { useEditSlackInsightAlert } from "core/hooks/useEditSlackInsightAlert";
import { useInsightTitle } from "core/hooks/useInsightTitle";
import { useSlack } from "core/hooks/useSlack";
import { IAlert } from "core/types/Alert";
import { IReport } from "core/types/Report";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";
import { Frequency } from "modules/SlackInsightEditor/Frequency";
import { Timerange } from "modules/SlackInsightEditor/Timerange";

interface ISlackInsightAlertEditorProps {
  insight: IInsightSection;
  config: ITemplateConfig;
  report: IReport;
  appId: string;
  reportId: string;
  existingAlert: IAlert | undefined;
  onClose: () => void;
}

export const SlackInsightAlertEditor: React.FC<
  ISlackInsightAlertEditorProps
> = ({ insight, config, report, appId, reportId, existingAlert, onClose }) => {
  const Section = insight.section;
  const { hasSlackAppInstalled } = useSlack();
  const insightTitle = useInsightTitle({ title: insight.title });
  const {
    form,
    alertState,
    hasChanges,
    isLoading,
    onCancel,
    onConnectSlack,
    onSave,
    onUpdate,
    onPause,
  } = useEditSlackInsightAlert({
    reportId,
    insight,
    existingAlert,
  });

  const getActionHandler = (
    alertState: "NEW" | "PAUSED" | "MODIFIED" | "ACTIVE",
  ) => {
    switch (alertState) {
      case "NEW":
        return onSave;
      case "PAUSED":
        return onSave;
      case "MODIFIED":
        return onUpdate;
      case "ACTIVE":
        return onPause;
    }
  };

  return (
    <>
      <DrawerHeader>
        <div className="flex items-center justify-between border-b border-gray-200 pb-4">
          <div className="flex items-center">
            <p className="text-lg">
              {existingAlert ? "Edit" : "Create"} Slack alert
            </p>
          </div>
          {form.Subscribe({
            selector: (state) => ({
              slackChannelId: state.values.slackChannelId,
              canSubmit: state.canSubmit,
            }),
            children: ({ slackChannelId, canSubmit }) => (
              <Flex gridGap={2}>
                <Button
                  variant="ghost"
                  colorScheme="purple"
                  isDisabled={!hasChanges}
                  onClick={() => onCancel(onClose)}
                >
                  Cancel
                </Button>
                <SlackInsightActionButton
                  isLoading={isLoading}
                  isEnabled={!!slackChannelId && canSubmit}
                  isSlackConnected={hasSlackAppInstalled}
                  onConnectSlack={onConnectSlack}
                  onAction={getActionHandler(alertState)}
                  actionState={alertState}
                />
              </Flex>
            ),
          })}
        </div>
      </DrawerHeader>
      <DrawerBody>
        <div className="flex flex-col gap-5">
          <div className="flex flex-col">
            <Text mb={0} fontWeight="semibold">
              Preview
            </Text>
            <Text mb={2} fontSize="sm" color="gray.600">
              This is a preview of the graph you will receive in your chosen
              Slack channel
            </Text>
            <Flex bg="purple.100" borderRadius="lg" p={3} direction="column">
              <Flex bg="white" borderRadius="lg" direction="column">
                <Flex direction="column" px={6} mb={2}>
                  <Flex
                    direction="row"
                    align="center"
                    mt={1}
                    justifyContent="flex-start"
                  >
                    <Flex
                      justifyContent="center"
                      p={1}
                      mr={2}
                      border="1px solid"
                      borderRadius="lg"
                      borderColor="gray.200"
                      mt={3}
                    >
                      <CompanyJuneLogoIcon fontSize={26} />
                    </Flex>
                    <Text fontWeight="semibold">June</Text>
                    <Text
                      ml={2}
                      fontSize="xs"
                      color="gray.600"
                      bg="gray.100"
                      borderRadius="md"
                      textAlign="center"
                      px={1}
                      textTransform="uppercase"
                    >
                      App
                    </Text>
                    <Text ml={2} fontSize="xs" color="gray.600">
                      {format(new Date(), "HH:mm")}
                    </Text>
                  </Flex>
                  <Flex ml={10} pl={1}>
                    <Text fontSize="sm" mt={-3}>
                      Here's your{" "}
                      <Text
                        as="span"
                        fontWeight="semibold"
                        textTransform="lowercase"
                      >
                        {insightTitle}
                      </Text>{" "}
                      insight from your{" "}
                      <Text
                        as="span"
                        fontWeight="semibold"
                        textTransform="lowercase"
                      >
                        {report.name}
                      </Text>{" "}
                      report
                    </Text>
                  </Flex>
                </Flex>
                <Flex w="100%">
                  {Section && (
                    <Section
                      report={report}
                      appId={appId}
                      config={config}
                      reportId={report.id}
                      reload={() => {}}
                      openSetup={() => {}}
                      sharingMode={false}
                      reloadGraph
                      previewMode
                      screenshotMode
                      showYAxis
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </div>

          <div className="flex flex-col">
            <Text mb={0} fontWeight="semibold">
              Slack channel
            </Text>
            <Text mb={2} fontSize="sm" color="gray.600">
              The channel you wish to receive the graph in
            </Text>
            {!hasSlackAppInstalled ? (
              <Box>
                <Button
                  onClick={onConnectSlack}
                  variant="ghost"
                  colorScheme="purple"
                >
                  Connect Slack to select a channel
                </Button>
              </Box>
            ) : (
              form.Field({
                name: "slackChannelId",
                children: (field) => (
                  <SlackChannels
                    showLabel={false}
                    defaultValue={field.state.value ?? ""}
                    onChange={(ch) => field.handleChange(ch)}
                  />
                ),
              })
            )}
          </div>

          {form.Field({
            name: "frequency",
            children: (field) => (
              <Frequency
                alertChanges={{
                  ...form.state.values,
                  id: existingAlert?.id ?? 0,
                  created: !!existingAlert,
                  appId: Number(appId),
                  alertableId: Number(reportId),
                  setup: existingAlert?.setup ?? {},
                  alertableType: "Report",
                  enrichmentEnabled: false,
                  threshold: 0,
                  thresholdType: 0,
                }}
                setAlertChanges={(alert) => field.handleChange(alert.frequency)}
              />
            ),
          })}

          {insight.timerangeOptions &&
            form.Field({
              name: "insightTimerange",
              children: (field) => (
                <Timerange
                  timerangeOptions={insight.timerangeOptions ?? []}
                  alertChanges={{
                    ...form.state.values,
                    id: existingAlert?.id ?? 0,
                    created: !!existingAlert,
                    appId: Number(appId),
                    alertableId: Number(reportId),
                    setup: existingAlert?.setup ?? {},
                    alertableType: "Report",
                    enrichmentEnabled: false,
                    threshold: 0,
                    thresholdType: 0,
                  }}
                  setAlertChanges={(alert) =>
                    field.handleChange(alert.insightTimerange)
                  }
                />
              ),
            })}
        </div>
      </DrawerBody>
    </>
  );
};
