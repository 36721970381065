import { Flex, HStack, ComponentDefaultProps } from "@chakra-ui/react";
import React from "react";

export const Toggle: React.FC<ComponentDefaultProps> = ({
  children,
  ...props
}) => {
  return (
    <HStack
      bg="gray.100"
      w="320px"
      h="40px"
      spacing={1}
      p={0.5}
      borderRadius="lg"
      {...props}
    >
      {children}
    </HStack>
  );
};

interface IToggleButton extends ComponentDefaultProps {
  isSelected: boolean;
  isDisabled?: boolean;
  onToggle: () => void;
}

export const ToggleButton: React.FC<IToggleButton> = ({
  isSelected,
  isDisabled = false,
  onToggle,
  children,
  ...props
}) => {
  const selectedStyles = {
    bg: "white",
    color: "gray.800",
    fontWeight: "medium",
    shadow: "sm",
  };
  const unselectedStyles = {
    bg: "transparent",
    color: "gray.600",
    fontWeight: "400",
    shadow: "none",
  };

  return (
    <Flex
      userSelect="none"
      cursor={isDisabled ? "not-allowed" : "pointer"}
      alignItems="center"
      justifyContent="center"
      w="50%"
      h="full"
      fontSize="sm"
      transition="all 0.3s ease-out"
      borderRadius="lg"
      data-testid={isSelected ? "toggle-selected" : "toggle-unselected"}
      {...props}
      onClick={() => {
        if (!isDisabled && !isSelected) onToggle();
      }}
      {...(isSelected ? selectedStyles : unselectedStyles)}
    >
      {children}
    </Flex>
  );
};
