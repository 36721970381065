import { useParams } from "react-router-dom";

import { useSegment } from "core/hooks/useSegment";
import { StepLink } from "modules/Developers/Overview/StepLink";

export const ConnectSegmentAction = () => {
  const { appId } = useParams();
  const { hasConnectedSegment } = useSegment();

  if (hasConnectedSegment) return <StepLink step="connectedDataSource" />;

  return (
    <a
      href={`${import.meta.env.VITE_API_HOST}/auth/segment`}
      onClick={() => {
        window.analytics.track(
          "connect_segment_clicked",
          {
            appId,
          },
          { context: { groupId: appId } },
        );
      }}
      className="cursor-pointer text-sm text-purple-500"
    >
      Connect
    </a>
  );
};
