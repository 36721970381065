import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import { JuneSpinner } from "@/core/components/JuneSpinner";
import { PageContainer } from "core/components/PageContainer";
import { getReportConfig } from "core/helpers/getReportConfig";
import { useReport } from "core/hooks/useReport";

export const CreateReportRoute = () => {
  const { appId, reportType } = useParams<{
    appId: string;
    reportType: string;
  }>() as {
    appId: string;
    reportType: string;
  };
  const queryParams = new URLSearchParams(window.location.search);
  const eventId = queryParams.get("eventId");
  const config = getReportConfig(Number(reportType))!;
  const { onCreateReport, createdReport } = useReport();

  useEffect(() => {
    if (config.title && reportType && !createdReport) {
      onCreateReport(Number(reportType), config.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.title]);

  if (!createdReport)
    return (
      <PageContainer>
        <div className="flex h-screen items-center justify-center">
          <JuneSpinner />
        </div>
      </PageContainer>
    );

  if (eventId)
    return (
      <Navigate
        to={`/a/${appId}/report/${createdReport.report.id}/setup?eventId=${eventId}`}
      />
    );

  return <Navigate to={`/a/${appId}/report/${createdReport.report.id}`} />;
};
