import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StyleProps,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef } from "react";

import { Icon } from "core/design-system/components/Icon";
import CopyIcon from "core/design-system/components/Icon/Interface/CopyIcon";
import { renderToast } from "core/helpers/toast";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  useDeleteAudienceMutation,
  useDuplicateAudienceMutation,
} from "core/models/audiences";
import { IAudience } from "core/types/Audience";

interface IAudienceActionsMenuProps extends StyleProps {
  audience: IAudience;
}

interface IDeleteDialogProps {
  audience: IAudience;
  isOpen: boolean;
  cancelRef: React.RefObject<any>;
  onClose: () => void;
  onDeleteAudience: () => void;
}

const DeleteDialog: React.FC<IDeleteDialogProps> = ({
  audience,
  onDeleteAudience,
  isOpen,
  onClose,
  cancelRef,
}) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete {audience.name}
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={onDeleteAudience} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export const AudienceActionsMenu: React.FC<IAudienceActionsMenuProps> = ({
  audience,
  ...styleProps
}) => {
  const { id: appId } = useCurrentApp();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [deleteAudience] = useDeleteAudienceMutation();

  const [duplicateAudience] = useDuplicateAudienceMutation();

  const onDuplicateAudience = async () => {
    if (!audience.id) return;

    await duplicateAudience({ appId, audienceId: audience.id })
      .unwrap()
      .then(() => {
        renderToast({
          action: "duplicate",
          status: "success",
          resource: "audience",
        });
      })
      .catch(() =>
        renderToast({
          action: "duplicate",
          status: "error",
          resource: "audience",
        }),
      );
  };

  const onDeleteAudience = async () => {
    if (!audience.id) return;

    await deleteAudience({ appId, audienceId: audience.id })
      .unwrap()
      .then(() => {
        renderToast({
          action: "delete",
          status: "success",
          resource: "audience",
        });
      })
      .catch(() =>
        renderToast({
          action: "delete",
          status: "error",
          resource: "audience",
        }),
      );
    onClose();
  };

  return (
    <>
      <Menu>
        <MenuButton as={Button} variant="ghost" ml={6} {...styleProps}>
          <Icon iconType="icon" name="more" />
        </MenuButton>
        <MenuList boxShadow="md" minWidth="140px">
          <MenuItem onClick={onDuplicateAudience}>
            <Flex justify="flex-start" alignItems="center">
              <CopyIcon w="18px" color="gray.600" mr={1} fontSize="xl" />
              <Text fontWeight="medium" fontSize="sm" color="black">
                Duplicate
              </Text>
            </Flex>
          </MenuItem>
          <MenuItem onClick={onOpen}>
            <Flex justify="flex-start" alignItems="center">
              <Icon
                w={3.5}
                iconType="icon"
                name="delete"
                mr={2}
                color="black"
              />
              <Text fontWeight="medium" fontSize="sm" color="black">
                Delete
              </Text>
            </Flex>
          </MenuItem>
          <DeleteDialog
            onDeleteAudience={onDeleteAudience}
            audience={audience}
            isOpen={isOpen}
            onClose={onClose}
            cancelRef={cancelRef}
          />
        </MenuList>
      </Menu>
    </>
  );
};

export default AudienceActionsMenu;
