import { Box, Center, Text } from "@chakra-ui/react";

import CelebrateIcon from "core/design-system/components/Icon/Interface/CelebrateIcon";
import InstantIcon from "core/design-system/components/Icon/Interface/InstantIcon";
import KeepControlIcon from "core/design-system/components/Icon/Interface/KeepControlIcon";
import TeamIcon from "core/design-system/components/Icon/Interface/TeamIcon";

export const Install: React.FC = () => {
  return (
    <div className="flex items-center gap-4 py-6">
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-row gap-4">
          <div>
            <InstantIcon color="primary" h={7} w={7} />
          </div>
          <div className="flex flex-col">
            <Text fontSize="sm" fontWeight="bold">
              View and create contacts, leads, accounts and more from June
            </Text>
            <Text fontSize="sm" fontWeight="normal" color="gray.600">
              View and create Salesforce contacts and accounts, right in June
              along with the rest of their product usage.
            </Text>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div>
            <CelebrateIcon h={7} w={7} />
          </div>
          <div className="flex flex-col">
            <Text fontSize="sm" fontWeight="bold">
              Automatically sync contacts, leads, accounts and more
            </Text>
            <Text fontSize="sm" fontWeight="normal" color="gray.600">
              Automatically create and update Salesforce contacts, leads,
              accounts and more when they get created and updated in June.
            </Text>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div>
            <KeepControlIcon h={7} w={7} />
          </div>
          <div className="flex flex-col">
            <Text fontSize="sm" fontWeight="bold">
              Control what data is sent to Salesforce from June
            </Text>
            <Text fontSize="sm" fontWeight="normal" color="gray.600">
              Map the June usage data you want to send to your team in
              Salesforce so it's always up to date.
            </Text>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div>
            <TeamIcon color="purple.500" h={7} w={7} />
          </div>
          <div className="flex flex-col">
            <Text fontSize="sm" fontWeight="bold">
              Rally your team behind your most important metrics
            </Text>
            <Text fontSize="sm" fontWeight="normal" color="gray.600">
              Get everyone up to speed with what's happening inside your
              company, even your Sales and Customer Success teams.
            </Text>
          </div>
        </div>
      </div>
      <div className="flex w-full">
        <Center w="100%">
          <Box
            borderRadius="lg"
            h="350px"
            w="100%"
            bgImage={`url('/empty-sf.png')`}
            bgRepeat="no-repeat"
            bgPosition="center"
            bgSize="contain"
          />
        </Center>
      </div>
    </div>
  );
};
