import { Text, Box } from "@chakra-ui/react";
import React from "react";
import {
  ResponsiveContainer as RechartsResponsiveContainer,
  ScatterChart as RechartsScatterChart,
  Scatter as RechartsScatter,
  XAxis as RechartsXAxis,
  YAxis as RechartsYAxis,
  CartesianGrid as RechartsCartesianGrid,
  Tooltip as RechartsTooltip,
  Cell as RechartsCell,
} from "recharts";

import { addLoadedIdToElement } from "core/design-system/constants/charts";
import templateColors from "core/design-system/constants/theme/colors";

export const colors = [
  "rgba(224, 180, 241, 1)",
  "rgba(237, 181, 180, 1)",
  "rgba(181, 240, 220, 1)",
  "#FDF3D9",
  "#DEE1FC",
  "#D63A35",
  "#666CEF",
  "#9DBF9E",
  "#F4D35E",
  "#EBEBD3",
  "#F9B5AC",
  "#C1666B",
  "#48A9A6",
];

const PERCENTAGE_TICKS = [0, 25, 50, 75, 100];

function CustomTooltip({ payload, active }) {
  if (active && payload && payload.length) {
    return (
      <Box bg="gray.700" px={3} py={2} borderRadius={5}>
        <Text fontSize="sm" pb={1} color="white" fontWeight="bold">
          {payload[0].payload.name}
        </Text>
        <Text fontSize="xs" color="gray.400">
          Frequency:{" "}
          <Text as="span" fontWeight="bold">
            {payload[0].payload.frequency}%
          </Text>
        </Text>
        <Text fontSize="xs" color="gray.400">
          Popularity:{" "}
          <Text as="span" fontWeight="bold">
            {payload[0].payload.popularity}%
          </Text>
        </Text>
      </Box>
    );
  }
  return null;
}

function ScatterChart({ children, ...props }) {
  return (
    <RechartsResponsiveContainer width="100%" height="100%">
      <ScatterPlot {...props}>{children}</ScatterPlot>
    </RechartsResponsiveContainer>
  );
}

class ScatterPlot extends RechartsScatterChart {
  static defaultProps = {
    ...RechartsScatterChart.defaultProps,
    // Customized props
    animationBegin: 0,
    animationDuration: 0,
    width: "100%",
    height: "100%",
    margin: {
      top: 30,
      right: 60,
      bottom: 0,
      left: 0,
    },
  };
}

class Scatter extends RechartsScatter {
  static defaultProps = {
    ...RechartsScatter.defaultProps,
    // Customized props
    shape: <circle r="10" />,
  };

  componentDidMount() {
    setTimeout(() => {
      addLoadedIdToElement();
    }, 2000);
  }

  render() {
    return <RechartsScatter {...this.props} />;
  }
}

class ScatterCell extends RechartsCell {
  static defaultProps = {
    ...RechartsCell.defaultProps,
    // Customized props
    width: "20px",
    height: "20px",
    style: { opacity: 1 },
  };
}

class ScatterGrid extends RechartsCartesianGrid {
  static defaultProps = {
    ...RechartsCartesianGrid.defaultProps,
    // Customized props
    vertical: false,
    strokeDasharray: "3 3",
    stroke: templateColors.gray[300],
    onAnimationEnd: () => addLoadedIdToElement(),
  };
}

class ScatterTooltip extends RechartsTooltip {
  static defaultProps = {
    ...RechartsTooltip.defaultProps,
    // Customized props
    content: <CustomTooltip />,
    cursor: { stroke: templateColors.gray[100] },
  };
}

class ScatterXAxis extends RechartsXAxis {
  static defaultProps = {
    ...RechartsXAxis.defaultProps,
    // Customized props
    axisLine: { stroke: templateColors.gray[300] },
    tickLine: false,
    type: "number",
    ticks: PERCENTAGE_TICKS,
  };
}

class ScatterYAxis extends RechartsYAxis {
  static defaultProps = {
    ...RechartsYAxis.defaultProps,
    // Customized props
    axisLine: false,
    tickLine: false,
    type: "number",
    ticks: PERCENTAGE_TICKS,
  };
}

export {
  ScatterChart,
  Scatter,
  ScatterGrid,
  ScatterXAxis,
  ScatterYAxis,
  ScatterCell,
  ScatterTooltip,
};
