import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { TRAIT_JOIN_OPERATOR } from "core/constants/traits";
import { useToast } from "core/hooks/useToast";
import {
  useArchiveTraitMutation,
  useComputeTraitMutation,
  useCreateTraitMutation,
  useLazyGetComputedTraitQuery,
  useUpdateTraitMutation,
} from "core/models/computedTraits";
import { ITraitWithConfig } from "core/types/Trait";

export default function useSmartTrait({
  traitIdArg,
  onCreated,
}: {
  traitIdArg?: number;
  onCreated?: (trait: ITraitWithConfig) => void;
}) {
  const { appId, traitId: traitIdParam } = useParams();
  const traitId = traitIdArg !== undefined ? traitIdArg : traitIdParam;
  const [getTrait, { data: trait, isLoading, isFetching }] =
    useLazyGetComputedTraitQuery();

  const navigate = useNavigate();
  const toast = useToast();

  const [updateTrait] = useUpdateTraitMutation();
  const [computeTrait] = useComputeTraitMutation();
  const [archive] = useArchiveTraitMutation();
  const [createTrait] = useCreateTraitMutation();

  useEffect(() => {
    if (traitId) {
      getTrait({
        appId: Number(appId),
        traitId: Number(traitId),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traitId]);

  function onCreate(newTrait: ITraitWithConfig) {
    createTrait({
      appId: Number(appId),
      trait: {
        name: newTrait.name,
        description: newTrait.description,
        level: newTrait.level,
        isLive: true,
        config: {
          computationType: newTrait.config.computationType,
          joinOperator: TRAIT_JOIN_OPERATOR.AND,
          filters: newTrait.config.filters,
          timeRangeType: newTrait.config.timeRangeType,
          intervalType: newTrait.config.intervalType,
          intervalValue: newTrait.config.intervalValue,
          startingEvent: newTrait.config.startingEvent,
          numeratorTrait: newTrait.config.numeratorTrait,
          denominatorTrait: newTrait.config.denominatorTrait,
          isPercentage: newTrait.config.isPercentage,
        },
      },
    })
      .unwrap()
      .then((trait) => {
        onCreated
          ? onCreated(trait)
          : navigate(`/a/${appId}/settings/computed-traits/${trait.id}`);
      })
      .catch((e) => {
        const description = e?.data?.error;

        toast({
          title: "Failed to create trait",
          description,
          status: "error",
        });
      });
  }

  function onCompute() {
    computeTrait({
      appId: Number(appId),
      traitId: Number(traitId),
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Your trait was computed",
          description: "",
          status: "success",
        });
      })
      .catch(() => {
        toast({
          title: "Failed to compute trait",
          status: "error",
        });
      });
  }

  function onSave(updatedTrait: ITraitWithConfig) {
    const toUpdate = {
      id: updatedTrait.id,
      name: updatedTrait.name,
      description: updatedTrait.description,
      useInternalUsers: updatedTrait.useInternalUsers,
      level: updatedTrait.level,
      config: {
        computationType: updatedTrait.config.computationType,
        joinOperator: TRAIT_JOIN_OPERATOR.AND,
        filters: updatedTrait.config.filters,
        timeRangeType: updatedTrait.config.timeRangeType,
        intervalType: updatedTrait.config.intervalType,
        intervalValue: updatedTrait.config.intervalValue,
        startingEvent: updatedTrait.config.startingEvent,
        numeratorTrait: updatedTrait.config.numeratorTrait,
        denominatorTrait: updatedTrait.config.denominatorTrait,
        isPercentage: updatedTrait.config.isPercentage,
      },
    };
    updateTrait({
      appId: Number(appId),
      trait: toUpdate,
    })
      .unwrap()
      .catch(() => {
        toast({
          title: "Failed to update trait",
          status: "error",
        });
      });
  }

  function onSetLive(isLive: boolean) {
    updateTrait({
      appId: Number(appId),
      trait: { id: Number(traitId), isLive },
    })
      .unwrap()
      .then(() => {
        toast({
          title: isLive
            ? "Your trait was set live successfully"
            : "Your trait was turned off successfully",
          status: "success",
        });
      })
      .catch(() => {
        toast({
          title: "Failed to update trait's status",
          status: "error",
        });
      });
  }

  function onDelete() {
    archive({ appId: Number(appId), traitId: Number(traitId) })
      .unwrap()
      .then(() => {
        navigate(`/a/${appId}/settings/computed-traits`);
      });
  }

  return {
    trait,
    isLoading: isLoading || isFetching,
    onCompute,
    onCreate,
    onSave,
    onSetLive,
    onDelete,
  };
}
