import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

import { ClipboardButton } from "core/components/ClipboardButton";
import { IDENTIFY } from "core/constants/events";

interface IPropertyList {
  eventType?: number;
  eventName?: string;
  properties: { [key: string]: any };
}

export const PropertyList: React.FC<IPropertyList> = ({
  eventType,
  eventName,
  properties,
}) => {
  const propertyNames = properties ? Object.keys(properties) : [];
  const hasProperties = propertyNames.length > 0;
  const title = eventType === IDENTIFY ? "Traits" : "Properties";

  if (hasProperties)
    return (
      <Box>
        {eventName && (
          <Text mb={4} fontSize="sm">
            {eventName}
          </Text>
        )}
        <Flex mb={2} alignItems="center">
          <Text fontSize="xs" fontWeight="medium" mr={1}>
            {title}
          </Text>
          <ClipboardButton
            value={JSON.stringify(properties)}
            showLabel={false}
            copyText={`Copy ${title}`}
            bg="transparent"
            color="white"
            size="sm"
            _hover={{
              bg: "gray.500",
            }}
          />
        </Flex>
        {propertyNames.map((propertyName, index) => (
          <Text key={index} fontSize="xs" fontWeight="medium" color="gray.400">
            {propertyName}:{" "}
            <Text as="span" color="white">
              {JSON.stringify(properties[propertyName])}
            </Text>
          </Text>
        ))}
      </Box>
    );

  return (
    <Box>
      {eventName && (
        <Text mb={4} fontSize="sm">
          {eventName}
        </Text>
      )}
      <Text fontSize="xs">No {title.toLowerCase()} available</Text>
    </Box>
  );
};

export default PropertyList;
