import React from "react";

import { DataPoint } from "@/modules/ViewInsight/ViewInsightComponent";
import { FunnelStep } from "core/components/ViewInsight/Graphs/FunnelStep";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { IViewInsight } from "core/models/viewInsights";
import { IBarData } from "core/types/BarChart";
import { Size } from "core/types/ViewInsight";

export const FunnelOverviewGraph: React.FC<{
  viewInsight: IViewInsight;
  data: IBarData[];
  color?: IColorPalette;
  size?: Size;
  onClickDataPoint?: (dataPoint: DataPoint) => void;
}> = ({
  viewInsight,
  data = [],
  color = colors.purple,
  size = Size.Small,
  onClickDataPoint,
}) => {
  const formattedData = data.map((item) => {
    return {
      index: item.index,
      label: item.humanizedName,
      dropped: {
        onClick: () => {},
        count: item.droppedCount,
        unit: viewInsight?.appObject?.singularName.toLowerCase() || "",
      },
      conversion: {
        onClick: () => {},
        count: item.convertedCount,
        unit: viewInsight?.appObject?.singularName.toLowerCase() || "",
        percentage: item.conversionPercentage,
        percentageFromPreviousStep: item.conversionPercentageFromPreviousStep,
        breakdown: item?.breakdown,
      },
      medianTimeTaken: item.medianTimeTaken || "",
      averageTimeTaken: item.averageTimeTaken || "",
      barItems: data.length,
    };
  });

  return (
    <div
      data-testid="funnel-body"
      className="relative max-h-[200px] w-full  overflow-auto [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden"
    >
      {formattedData?.map(
        (barItem, index) =>
          barItem && (
            <FunnelStep
              key={index}
              item={barItem}
              color={color}
              size={size}
              onClick={(dataPoint) => {
                onClickDataPoint?.({
                  ...dataPoint,
                  eventIndex: index,
                  report: viewInsight.report,
                });
              }}
            />
          ),
      )}
    </div>
  );
};
