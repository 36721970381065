import { InfoOutlineIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { Box, Divider, Flex, Progress, Spacer, Text } from "@chakra-ui/react";
import React from "react";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useMauThreshold } from "core/hooks/useMauThreshold";

export const MauLimit: React.FC<{
  overrideBarColor?: string;
  isUnlimited?: boolean;
}> = ({ overrideBarColor, isUnlimited }) => {
  const currentApp = useCurrentApp();
  const {
    userCount,
    barColor,
    isAboveLimit,
    isApproachingLimit,
    threshold,
    isGuardrail,
  } = useMauThreshold();
  const color = overrideBarColor || barColor;
  if (!(isAboveLimit || isApproachingLimit)) return null;

  return (
    <Box mt={4} borderRadius="lg" border="1px solid" borderColor="gray.200">
      <Box bg={`${color}.50`} p={5}>
        <Flex mb={2} w="100%" direction="row">
          <Flex>
            <Text fontSize="sm">
              {isAboveLimit && !isUnlimited && `You've reached your limit`}
            </Text>
            <Text fontSize="sm">
              {isApproachingLimit &&
                !isUnlimited &&
                `You're about to reach your limit`}
            </Text>
            <Text fontSize="sm" display="flex" gap={1} alignItems="center">
              {isGuardrail ? <WarningTwoIcon color="warning.500" /> : null}
              {isUnlimited || (!isApproachingLimit && !isAboveLimit)
                ? `Your MAU usage for the current period`
                : ""}
              {isGuardrail ? (
                <Text fontWeight="semibold"> with the events guardrail*</Text>
              ) : null}
            </Text>
          </Flex>
          <Spacer />
          <Flex>
            <Text fontSize="sm">
              <Text as="span" color="gray.900" fontWeight="medium">
                {userCount.toLocaleString()}
                {` `}
              </Text>
              /{" "}
              <Text as="span" color="gray.900" fontWeight="medium">
                {currentApp.committeeMember
                  ? "Unlimited"
                  : threshold.toLocaleString()}
              </Text>{" "}
              MAU
            </Text>
          </Flex>
        </Flex>
        <Progress
          value={(userCount / threshold) * 100}
          size="sm"
          colorScheme={color}
          borderRadius="sm"
          bg={`${color}.100`}
        />
      </Box>
      <Divider />
      {isAboveLimit && (
        <Box px={5} py={2}>
          <Text color="gray.600" fontSize="xs">
            <InfoOutlineIcon /> We will continue to process your events, but
            your access to the product will be restricted
          </Text>
        </Box>
      )}
    </Box>
  );
};
