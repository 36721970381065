import { Skeleton } from "@chakra-ui/react";
import {
  closestCenter,
  DragEndEvent,
  DndContext,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  Disclosure,
  DisclosurePanel,
  DisclosureButton,
} from "@headlessui/react";
import { PlusIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useSidebarCollapse } from "core/hooks/useSidebarCollapse";
import { useViews, ViewLocation } from "core/hooks/useViews";
import {
  IView,
  useAddViewMutation,
  useBatchUpdateOrderMutation,
  usePinViewMutation,
  useUpdateViewMutation,
} from "core/models/views";
import { ItemContainer } from "modules/Navigation/Sidebar/ItemContainer";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";
import { SortableView } from "modules/Navigation/Sidebar/SortableView";

export const Views = () => {
  const { views: viewsData, isLoadingViews } = useViews({
    location: ViewLocation.Home,
    pinned: true,
  });
  const [views, setViews] = useState<IView[]>(viewsData || []);
  const pathArray = window.location.pathname.split(`/`);
  const viewId = pathArray.includes("home")
    ? pathArray[pathArray.indexOf("home") + 1]
    : "";
  const { id: appId } = useCurrentApp();
  const { isViewsCollapsed, toggleViewsCollapse } = useSidebarCollapse();
  const [batchUpdateOrder] = useBatchUpdateOrderMutation();
  const [pinView] = usePinViewMutation();
  const [updateView] = useUpdateViewMutation();
  const [addView] = useAddViewMutation();
  const navigate = useNavigate();

  const onAddDashboard = async function () {
    const { data } = (await addView({
      appId,
      name: "Untitled",
      pin: false,
    })) as { data: IView };
    navigate(`/a/${appId}/home/${data?.id}`);
    toast.success(`Dashboard created`, {
      description: `You can now start adding insights to this dashboard.`,
    });
  };

  useEffect(() => {
    if (viewsData) {
      setViews(viewsData);
    }
  }, [viewsData]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setViews((views) => {
        const oldIndex = views.findIndex((view) => view.id === active.id);
        const newIndex = views.findIndex((view) => view.id === over?.id);

        const reorderedViews = arrayMove(views, oldIndex, newIndex).map(
          (view, index) => ({
            ...view,
            order: index,
          }),
        );

        batchUpdateOrder({
          appId: Number(appId),
          views: reorderedViews,
        });

        return reorderedViews;
      });
    }
  }

  function handleUnpin(view: IView) {
    pinView({
      appId: Number(appId),
      viewId: Number(view.id),
      pinned: !view.pinned,
    });
  }

  function handleEmojiClick(emoji: string, viewId: number) {
    updateView({
      id: Number(viewId),
      appId: Number(appId),
      emoji: emoji,
    });
  }

  return (
    <>
      <Disclosure defaultOpen={!isViewsCollapsed}>
        {({ open }) => (
          <>
            <DisclosureButton
              onClick={toggleViewsCollapse}
              className="group flex w-full items-center justify-between px-4 text-left font-medium text-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
            >
              <span className="text-xs font-medium">Dashboards</span>
              <motion.div animate={{ rotate: open ? 0 : -90 }}>
                <ChevronDownIcon className="h-4 w-4  text-gray-500 group-hover:block group-hover:text-gray-500" />
              </motion.div>
            </DisclosureButton>

            <AnimatePresence>
              <DisclosurePanel className="text-sm text-gray-500">
                <motion.div
                  animate={{ opacity: 1, height: "auto" }}
                  initial={{ opacity: 0, height: 0 }}
                  exit={{ opacity: 0, height: 0 }}
                  className="flex flex-col gap-1"
                >
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={views}
                      strategy={verticalListSortingStrategy}
                    >
                      {isLoadingViews ? (
                        <div className="flex flex-col gap-2 px-4">
                          <Skeleton height="20px" rounded="md" />
                          <Skeleton height="20px" rounded="md" />
                          <Skeleton height="20px" rounded="md" />
                        </div>
                      ) : (
                        views?.map((view) => (
                          <SortableView
                            viewId={viewId}
                            view={view}
                            onUnpin={handleUnpin}
                            onEmojiClick={handleEmojiClick}
                          />
                        ))
                      )}
                    </SortableContext>
                  </DndContext>
                  <ItemContainer
                    className="group relative flex justify-between px-2 py-1"
                    onClick={onAddDashboard}
                  >
                    <div className="-ml-1 flex items-center">
                      <div className="ml-0.5 line-clamp-1 flex max-w-[210px] items-center gap-[10px]">
                        <PlusIcon className="ml-0.5 h-4 w-4 text-black" />
                        <ItemText className="max-w-[210px] text-ellipsis">
                          Add dashboard
                        </ItemText>
                      </div>
                    </div>
                  </ItemContainer>
                  <ItemContainer
                    to={`/a/${appId}/views`}
                    className="group relative flex justify-between px-2"
                  >
                    <div className="-ml-1 flex items-center">
                      <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                        <p className="text-[13px] text-gray-600">View all</p>
                      </div>
                    </div>
                  </ItemContainer>
                </motion.div>
              </DisclosurePanel>
            </AnimatePresence>
          </>
        )}
      </Disclosure>
    </>
  );
};
