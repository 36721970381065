import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverProps,
  PopoverTrigger,
  ComponentDefaultProps,
  Text,
} from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

const SelectContainer: React.FC<PopoverProps> = ({ children, ...props }) => {
  return (
    <Popover closeOnBlur={true} {...props}>
      {children}
    </Popover>
  );
};

interface ISelectButton extends ComponentDefaultProps {
  showChevron?: boolean;
}

const SelectButton: React.FC<ISelectButton> = ({
  children,
  showChevron = true,
  ...props
}) => {
  return (
    <PopoverTrigger>
      <Button noOfLines={1} {...props}>
        {children}
        {showChevron && <ChevronDownIcon ml={2} float="right" />}
      </Button>
    </PopoverTrigger>
  );
};

const SelectHeader: React.FC<ComponentDefaultProps> = ({
  children,
  ...props
}) => {
  return <PopoverHeader {...props}>{children}</PopoverHeader>;
};

const SelectAnchor: React.FC<ComponentDefaultProps> = ({
  children,
  ...props
}) => {
  return <PopoverAnchor {...props}>{children}</PopoverAnchor>;
};

const SelectBody: React.FC<ComponentDefaultProps> = ({
  children,
  w = "200px",
  ...props
}) => {
  return (
    <PopoverContent borderColor="gray.50" borderWidth="1px" maxW={w}>
      <PopoverBody
        boxShadow="md"
        maxH="200px"
        overflowY="auto"
        overflowX="hidden"
        bg="white"
        px={0}
        py={1}
        {...props}
      >
        {children}
      </PopoverBody>
    </PopoverContent>
  );
};

export const SelectItem = <T,>({
  value,
  isSelected,
  isDisabled,
  onClick,
  children,
  ...props
}: PropsWithChildren<{
  onClick: (selected: T) => void;
  isSelected: boolean;
  isDisabled?: boolean;
  value: T;
  width?: string;
}>) => {
  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (typeof child === "string") {
      return (
        <Text
          maxW="100%"
          userSelect="none"
          noOfLines={1}
          key={index}
          {...props}
        >
          {child}
        </Text>
      );
    }
    return child;
  });

  return (
    <Flex
      _hover={{ bg: "gray.100" }}
      fontSize="sm"
      bg="white"
      px={2}
      py={1}
      cursor={isDisabled ? "default" : "pointer"}
      justifyContent="space-between"
      alignItems="center"
      onClick={() => onClick(value)}
      width="full"
    >
      <Flex width="full">
        <Text as="span" color={isDisabled ? "gray.500" : "gray.900"} {...props}>
          {childrenWithProps}
        </Text>
      </Flex>
    </Flex>
  );
};

const SelectMenu: React.FC<{
  children: ({
    Container,
    Button,
    Header,
    Body,
    Item,
  }: {
    Container: typeof SelectContainer;
    Anchor: typeof SelectAnchor;
    Button: typeof SelectButton;
    Header: typeof SelectHeader;
    Body: typeof SelectBody;
    Item: typeof SelectItem;
  }) => React.ReactElement;
}> = ({ children }) => {
  return children({
    Container: SelectContainer,
    Anchor: SelectAnchor,
    Button: SelectButton,
    Header: SelectHeader,
    Body: SelectBody,
    Item: SelectItem,
  });
};

export default SelectMenu;
