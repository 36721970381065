import React from "react";

import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { IRetentionInsightUser } from "core/modules/reports/report-types/Retention/ChurningUsersContainer";

export const UserCell: React.FC<{
  props: IRetentionInsightUser;
  previewMode: boolean;
}> = ({ props, previewMode }) => {
  return (
    <UserListSingleContact
      p={0}
      key={props.userId}
      id={props.userId || ""}
      traits={props.traits}
      previewMode={previewMode}
      truncateName={true}
      hideHoverBorder={true}
      hideExternalLinkIcon={true}
    />
  );
};
