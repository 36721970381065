import { Button, Card, CardBody, CardHeader } from "@chakra-ui/react";

import gongLogo from "modules/Groups/Group/OverviewDemo/gong-logo.webp";

export const OpenConversations = () => {
  return (
    <Card
      shadow="none"
      border="1px solid"
      borderColor="gray.200"
      h="full"
      p="0"
    >
      <CardHeader pt={4} pb={2} fontWeight="semibold">
        <div className="flex w-full items-center justify-between">
          <p>Latest conversation</p>
        </div>
      </CardHeader>
      <CardBody pt={2} pb={4}>
        <div className="flex items-center gap-2">
          <div className="flex h-8 w-8 items-center justify-center rounded-md border border-gray-200">
            <img className="size-6" src={gongLogo} alt="avatar" />
          </div>
          <div className="flex flex-col gap-1 py-2">
            <div className="flex flex-col items-start gap-0.5">
              <p className="text-xs text-gray-500">
                Tuesday Dec 10, 12:00 PM 2024
              </p>
              <p className="text-sm font-medium">Nate {`<>`} Alberto</p>
            </div>
          </div>
          <div className="flex flex-col gap-1 py-2"></div>
        </div>
        <div className="flex flex-col gap-0.5">
          <span className="text-xs text-gray-500">Latest action items</span>
          <div className="rounded-md bg-gray-100 p-2 text-sm">
            <p>
              Try out these new alert and digest features to streamline account
              management.
            </p>
            <p>Reach out with any questions or feedback!</p>
          </div>
          <div className="flex justify-end pt-2">
            <Button size="xs" variant="ghost">
              View transcript
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
