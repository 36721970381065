import { ComponentDefaultProps } from "@chakra-ui/react";
import React from "react";

import { Body, IBodyProps } from "core/components/ViewInsight/Body";
import {
  Container,
  IContainterProps,
} from "core/components/ViewInsight/Container";
import { Header } from "core/components/ViewInsight/Header";
import { Loader } from "core/components/ViewInsight/Loader";
import { ITitleProps, Title } from "core/components/ViewInsight/Title";

export const HighlightCard: React.FC<{
  children: ({
    Container,
    Header,
    Body,
    Loader,
    Title,
  }: {
    Container: React.FC<IContainterProps>;
    Header: React.FC<ComponentDefaultProps>;
    Body: React.FC<IBodyProps>;
    Loader: React.FC<ComponentDefaultProps>;
    Title: React.FC<ITitleProps>;
  }) => React.ReactElement;
}> = ({ children }) => {
  return children({
    Container,
    Header,
    Body,
    Loader,
    Title,
  });
};

export { Container, Header, Body, Loader, Title };
