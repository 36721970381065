import { useForm } from "@tanstack/react-form";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import { IAlertStateEnum, IAlertFrequencyEnum } from "core/constants/alert";
import { SLACK_REDIRECT_URL_KEY } from "core/constants/integrations";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  useCreateAlertMutation,
  useUpdateAlertMutation,
} from "core/models/alerts";
import { IAlert } from "core/types/Alert";
import { AppObjectType } from "core/types/AppObject";
import { IInsightSection } from "core/types/TemplateConfig";

type AlertState = "NEW" | "PAUSED" | "ACTIVE" | "MODIFIED";

interface AlertFormState {
  slackChannelId: string;
  frequency: IAlertFrequencyEnum;
  insightTimerange?: number;
  state: IAlertStateEnum;
  preferredUtcHour: number;
}

interface AlertPayload {
  appId: string;
  reportId: string;
  insightId: string;
  objectType: AppObjectType;
  frequency: IAlertFrequencyEnum;
  slackChannelId: string;
  insightTimerange?: number;
  state: IAlertStateEnum;
  preferredUtcHour: number;
}

export function useEditSlackInsightAlert({
  reportId,
  insight,
  existingAlert,
}: {
  reportId: string;
  insight: IInsightSection;
  existingAlert?: IAlert;
}) {
  const { appId } = useParams();
  const insightId = Number(insight.typeId);
  const { activeAppObject } = useAppObjects();
  const [updateAlert, { isLoading: isUpdating }] = useUpdateAlertMutation();
  const [createAlert, { isLoading: isCreating }] = useCreateAlertMutation();

  const form = useForm<AlertFormState>({
    defaultValues: {
      slackChannelId: existingAlert?.slackChannelId ?? "",
      frequency: existingAlert?.frequency ?? IAlertFrequencyEnum.Daily,
      insightTimerange: existingAlert?.insightTimerange,
      state: existingAlert?.state ?? IAlertStateEnum.Disabled,
      preferredUtcHour: existingAlert?.preferredUtcHour ?? 9,
    },
    validators: {
      onChange: ({ value }) => {
        if (!value.slackChannelId) {
          return "A Slack channel is required";
        }
        if (value.preferredUtcHour < 0 || value.preferredUtcHour > 23) {
          return "Hour must be between 0 and 23";
        }
        return undefined;
      },
    },
    onSubmit: async ({ value }) => {
      if (!value.slackChannelId) {
        toast.error("Select a Slack channel", {
          description: "You need to select a Slack channel to enable the alert",
        });
        return;
      }

      const payload: AlertPayload = {
        appId: String(appId),
        reportId: String(reportId),
        insightId: String(insightId),
        objectType: activeAppObject?.objectType || AppObjectType.User,
        frequency: value.frequency,
        slackChannelId: value.slackChannelId,
        insightTimerange: value.insightTimerange,
        state: value.state,
        preferredUtcHour: value.preferredUtcHour,
      };

      try {
        if (existingAlert?.id) {
          await updateAlert({
            id: existingAlert.id,
            alert: payload,
          });
          toast.success("Alert updated", {
            description: "Your slack notification was updated successfully.",
          });
        } else {
          await createAlert(payload);
          toast.success("Alert created", {
            description: "You will now receive this alert",
          });
        }
      } catch (error) {
        toast.error("Error updating alert", {
          description: "An error occurred while updating the alert",
        });
      }
    },
  });

  const getAlertState = (): AlertState => {
    const formState = form.state.values;
    if (!formState) return "NEW";
    if (!existingAlert) return "NEW";

    const hasChanges =
      formState.slackChannelId !== existingAlert.slackChannelId ||
      formState.frequency !== existingAlert.frequency ||
      formState.insightTimerange !== existingAlert.insightTimerange ||
      formState.preferredUtcHour !== existingAlert.preferredUtcHour;

    if (formState.state === IAlertStateEnum.Disabled) return "PAUSED";
    if (formState.state === IAlertStateEnum.Enabled) {
      return hasChanges ? "MODIFIED" : "ACTIVE";
    }
    return "NEW";
  };

  const onSave = async () => {
    form.setFieldValue("state", IAlertStateEnum.Enabled);
    await form.handleSubmit();
  };

  const onPause = async () => {
    form.setFieldValue("state", IAlertStateEnum.Disabled);
    await form.handleSubmit();
  };

  const onUpdate = async () => {
    await form.handleSubmit();
  };

  const onCancel = (onClose: () => void) => {
    if (existingAlert) {
      form.reset({
        slackChannelId: existingAlert.slackChannelId ?? "",
        frequency: existingAlert.frequency ?? IAlertFrequencyEnum.Daily,
        insightTimerange: existingAlert.insightTimerange,
        state: existingAlert.state ?? IAlertStateEnum.Disabled,
        preferredUtcHour: existingAlert.preferredUtcHour ?? 9,
      });
    }
    onClose();
  };

  const onConnectSlack = () => {
    localStorage.setItem(
      SLACK_REDIRECT_URL_KEY,
      `/a/${appId}/report/${reportId}?showSlack=true&insight=${insight.slug}`,
    );
    window.location.replace(`${import.meta.env.VITE_API_HOST}/auth/slack`);
  };

  const alertState = getAlertState();
  const hasChanges =
    alertState === "MODIFIED" ||
    (alertState === "NEW" && form.state.values?.slackChannelId);

  return {
    form,
    alertState,
    hasChanges,
    isLoading: isUpdating || isCreating,
    onCancel,
    onSave,
    onPause,
    onUpdate,
    onConnectSlack,
  };
}
