import { Flex } from "@chakra-ui/react";
import React from "react";

import { EventType } from "core/constants/report-setup";
import { IFeatureAuditData } from "core/modules/reports/report-types/FeatureAudit/GraphContainer";
import GraphInsight from "core/modules/reports/report-types/FeatureAudit/GraphInsight";

const GraphInsights: React.FC<IFeatureAuditData> = ({ data }) => {
  if (data) {
    const featureAuditBuckets = {
      core: [],
      lowFrequency: [],
      lowPopularity: [],
      clutter: [],
    } as { [key: string]: { name: string; type: EventType }[] };

    data.forEach(({ popularity, frequency, name, type }) => {
      if (popularity >= 50 && frequency >= 50) {
        featureAuditBuckets.core.push({ name, type });
      } else if (popularity < 50 && frequency >= 50) {
        featureAuditBuckets.lowPopularity.push({ name, type });
      } else if (popularity >= 50 && frequency < 50) {
        featureAuditBuckets.lowFrequency.push({ name, type });
      } else if (popularity < 25 && frequency < 25) {
        featureAuditBuckets.clutter.push({ name, type });
      }
    });

    return (
      <Flex my={6} px={8} direction="column" gridGap={4}>
        <GraphInsight
          bucketEvents={featureAuditBuckets.core}
          text="The majority of your customers use this feature very frequently.
            It's one of the core reasons why your users love the product. If
            you can make improvements here, it will delight most of your
            users."
          iconName="insightFeatureAuditCore"
          indicatorText="Great"
          indicatorColor="green"
        />
        <GraphInsight
          bucketEvents={featureAuditBuckets.lowPopularity}
          text="This feature is frequently used by a minority of your users.
            There is a good chance that other users can benefit from this
            feature but are not aware of it. This is a great place to run
            experiments to improve the 'discoverability' of this feature."
          iconName="insightFeatureAuditLowPopularity"
          indicatorText="Low popularity"
          indicatorColor="yellow"
        />
        <GraphInsight
          bucketEvents={featureAuditBuckets.lowFrequency}
          text="The majority of your customers use this feature but not very
            regularly. If you think this is a core feature, we recommend
            identifying the changes to get people to use it more frequently."
          iconName="insightFeatureAuditLowFrequency"
          indicatorText="Low frequency"
          indicatorColor="yellow"
        />
        <GraphInsight
          bucketEvents={featureAuditBuckets.clutter}
          text="This feature isn't used much. It may cost more in maintenance
            compared to the value it delivers to your users. You may
            consider discontinuing this feature. Exceptions should be made
            for non-removable core features such as a 'settings' menu."
          iconName="insightFeatureAuditClutter"
          indicatorText="To improve"
          indicatorColor="red"
        />
      </Flex>
    );
  }

  return null;
};

export default GraphInsights;
