import { Flex, StyleProps } from "@chakra-ui/react";
import React from "react";

import { OnlineDot } from "core/components/Avatars/OnlineDot";
import { AvatarIcon } from "core/design-system/components/Icon/AvatarIcon";
import AnonymousIcon from "core/design-system/components/Icon/Interface/AnonymousIcon";
import {
  generateRandomAnimalName,
  getContactAvatar,
  hashStringToNumber,
  IGetContactAvatarProps,
} from "core/helpers/contactAvatar";
import { IContact } from "core/types/Contact";

export interface IContactAvatar extends StyleProps {
  contact?: IContact;
  size?: string;
  isOnline?: boolean;
  previewMode?: boolean;
}

const ContactAvatar: React.FC<IContactAvatar> = ({
  contact,
  previewMode = false,
  size = "lg",
  ml = 0,
  isOnline = false,
  ...props
}) => {
  const avatarURL = getContactAvatar(contact as IGetContactAvatarProps);
  const colors = ["yellow", "green", "red", "blue", "purple", "gray"];
  const isMedium = size === "md";
  const isSmall = size === "sm";
  const isExtraSmall = size === "xs";

  const containerSize =
    (isMedium && 16) || (isSmall && 6) || (isExtraSmall && 5) || 24;
  const avatarSize =
    (isMedium && 10) || (isSmall && 5) || (isExtraSmall && 4) || 16;

  if ((!contact?.isIdentified || contact?.isAnonymous) && !previewMode) {
    return (
      <Flex
        align="center"
        justify="center"
        h={containerSize}
        w={containerSize}
        ml={ml}
        borderRadius="100%"
        flexShrink={0}
        {...props}
      >
        <AnonymousIcon fontSize={isSmall ? 32 : 24} />
      </Flex>
    );
  }

  const contactId =
    typeof contact?.id === "number"
      ? contact?.id
      : hashStringToNumber(contact?.id);
  const hasAnonymousIcon = !contactId;
  const hasAnimalIcon = Boolean(!avatarURL && contactId);
  const bg = contactId
    ? `${colors[contactId % colors.length]}.100`
    : "gray.200";

  if (avatarURL)
    return (
      <Flex
        h={containerSize}
        w={containerSize}
        bgImage={avatarURL}
        position={"relative"}
        align="center"
        justify="center"
        borderRadius="full"
        flexShrink={0}
        // For PNG logos
        bgColor={"white"}
        bgRepeat={"no-repeat"}
        style={{
          backgroundImage: `url(${avatarURL})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
        {...props}
      >
        {isOnline && <OnlineDot />}
      </Flex>
    );

  return (
    <Flex
      bg={bg}
      position={"relative"}
      align="center"
      justify="center"
      h={containerSize}
      w={containerSize}
      ml={ml}
      borderRadius="100%"
      flexShrink={0}
      {...props}
    >
      {hasAnimalIcon && (
        <AvatarIcon
          h={avatarSize}
          w={avatarSize}
          name={generateRandomAnimalName(contactId)}
        />
      )}
      {hasAnonymousIcon && <AnonymousIcon fontSize={isSmall ? 32 : 24} />}
      {isOnline && <OnlineDot />}
    </Flex>
  );
};

export default ContactAvatar;
