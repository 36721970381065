import { useNavigate, useParams } from "react-router-dom";

import { UseInternalUsersToggle } from "@/core/components/App/UseInternalUsersToggle";
import { AddMenu } from "@/modules/Views/AddMenu";
import { IView } from "core/models/views";

export const ObjectContentActions: React.FC<{
  view: IView;
  isLoadingView: boolean;
  updateView: (view: IView) => void;
  isUpdatingView: boolean;
}> = ({ view, isLoadingView, updateView, isUpdatingView }) => {
  const { appId, appObjectId, objectId } = useParams();
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-1">
      {view && (
        <UseInternalUsersToggle
          isEnabled={view.useInternalUsers}
          isLoading={isLoadingView || isUpdatingView}
          onChange={(useInternalUsers) => {
            updateView({
              ...view,
              useInternalUsers,
            });
          }}
        />
      )}
      <AddMenu
        className="text-gray-800"
        onAddInsightClick={() => {
          navigate(
            `/a/${appId}/objects/${appObjectId}/object/${objectId}/graph/new`,
          );
        }}
        onAddSQLClick={() => {
          navigate(
            `/a/${appId}/objects/${appObjectId}/object/${objectId}/sql/new`,
          );
        }}
      />
    </div>
  );
};
