import { AlienIcon } from "core/design-system/components/Icon/Avatars/AlienIcon";
import { AnonymousIcon } from "core/design-system/components/Icon/Avatars/AnonymousIcon";
import { BearIcon } from "core/design-system/components/Icon/Avatars/BearIcon";
import { CatIcon } from "core/design-system/components/Icon/Avatars/CatIcon";
import { DogIcon } from "core/design-system/components/Icon/Avatars/DogIcon";
import { DragonIcon } from "core/design-system/components/Icon/Avatars/DragonIcon";
import { FoxIcon } from "core/design-system/components/Icon/Avatars/FoxIcon";
import { GhostIcon } from "core/design-system/components/Icon/Avatars/GhostIcon";
import { GiraffeIcon } from "core/design-system/components/Icon/Avatars/GiraffeIcon";
import { KoalaIcon } from "core/design-system/components/Icon/Avatars/KoalaIcon";
import { MonkeyIcon } from "core/design-system/components/Icon/Avatars/MonkeyIcon";
import { PandaIcon } from "core/design-system/components/Icon/Avatars/PandaIcon";
import { PigIcon } from "core/design-system/components/Icon/Avatars/PigIcon";
import { RobotIcon } from "core/design-system/components/Icon/Avatars/RobotIcon";
import { RoosterIcon } from "core/design-system/components/Icon/Avatars/RoosterIcon";
import { TigerIcon } from "core/design-system/components/Icon/Avatars/TigerIcon";

const iconPaths = {
  anonymous: AnonymousIcon,
  alien: AlienIcon,
  bear: BearIcon,
  cat: CatIcon,
  dog: DogIcon,
  dragon: DragonIcon,
  fox: FoxIcon,
  ghost: GhostIcon,
  koala: KoalaIcon,
  monkey: MonkeyIcon,
  panda: PandaIcon,
  pig: PigIcon,
  robot: RobotIcon,
  rooster: RoosterIcon,
  tiger: TigerIcon,
  giraffe: GiraffeIcon,
};

export default iconPaths;
