import { Select } from "@chakra-ui/react";

import { FRESHPAINT, RUDDERSTACK, SDK, SEGMENT } from "core/constants/apiKeys";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { DataSource } from "core/models/onboardingChecklist";

const DATA_SOURCES: { value: DataSource; label: string }[] = [
  {
    value: SEGMENT,
    label: "Segment",
  },
  {
    value: SDK,
    label: "June SDK",
  },
  {
    value: RUDDERSTACK,
    label: "Rudderstack",
  },
  {
    value: FRESHPAINT,
    label: "Freshpaint",
  },
];
export const SelectDataSource: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const { onboardingChecklist, updateOnboardingChecklist } =
    useOnboardingChecklist({
      pollingEnabled: true,
    });

  const selectedDataSource = onboardingChecklist?.dataSource;

  if (!selectedDataSource) {
    return null;
  }

  return (
    <Select
      size="sm"
      value={selectedDataSource}
      onChange={(event) =>
        updateOnboardingChecklist({
          appId,
          step: "data_source",
          value: event.currentTarget.value as DataSource,
        })
      }
    >
      {DATA_SOURCES.map(({ value, label }) => (
        <option value={value}>{label}</option>
      ))}
    </Select>
  );
};
