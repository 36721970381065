import { Container } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { PageContainer } from "core/components/PageContainer";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { Ping } from "core/design-system/components/Ping";
import EventFeedList from "modules/Feed/List/EventFeedList";
import DefaultHeader from "modules/Pages/DefaultHeader";

const Feed = () => {
  const section = {
    name: "Live feed",
    title: (
      <div className="flex items-center gap-2">
        <Ping />
        Live feed
      </div>
    ),
    description: "A live feed of your track and page events",
    howItWorksArticle:
      "https://help.june.so/en/articles/5095720-track-in-product-basic-behaviors",
  };
  const { appId } = useParams<{ appId: string }>();

  return (
    <PageContainer>
      <Container maxW="container.xl">
        <div className="flex flex-col gap-4">
          <Breadcrumbs
            pages={[
              { name: "Events", path: `/a/${appId}/events`, current: false },
              { name: "Feed", path: `/a/${appId}/events/feed`, current: true },
            ]}
          />
          <DefaultHeader section={section} />
        </div>
        <EventFeedList showEventName />
      </Container>
    </PageContainer>
  );
};

export default Feed;
