import { Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import React from "react";

import { SelectChannel } from "core/components/Slack/SelectChannel";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetSlackChannelsQuery } from "core/models/slackApps";

interface ISlackChannelsProps {
  defaultValue: string | number;
  onChange: (channelId: string) => void;
  showLabel?: boolean;
}

export interface ISlackChannel {
  name: string;
  id: string;
  isMember: boolean;
}

interface IOption {
  value: ISlackChannel["id"];
  label: string;
}

export const SlackChannels: React.FC<ISlackChannelsProps> = ({
  defaultValue,
  onChange,
  showLabel = true,
}) => {
  const currentApp = useCurrentApp();
  const { data, isLoading } = useGetSlackChannelsQuery({
    appId: currentApp.id,
  });

  if (!data || isLoading)
    return (
      <Flex height="64px" direction="column">
        {showLabel && (
          <SkeletonText borderRadius="md" width="100px" pb={3} noOfLines={1} />
        )}
        <Skeleton borderRadius="md" w="170px" height="34px" />
      </Flex>
    );

  const options =
    data.channels?.map((channel: ISlackChannel) => ({
      value: channel.id,
      label: channel.name,
    })) || [];

  const defaultOption = options.find(
    (option: IOption) => option.value === defaultValue,
  );

  return (
    <SelectChannel
      key={defaultOption?.value}
      options={options}
      defaultOption={defaultOption}
      onChange={onChange}
    />
  );
};
