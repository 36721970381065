import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import type { SnakeCasedProperties } from "type-fest";

import { baseQuery } from "core/initializers/rtk";
import { IEvent } from "core/types/Event";
import { IPagy } from "core/types/Pagy";

export interface IEventRow {
  alertEnabled: boolean;
  isReceivingGroupContext: boolean;
  appId: number;
  id: number;
  isActive: boolean;
  name: string;
  totalCount: number;
  type: number;
  firstSeenAt: string;
  createdAt?: string;
  implementationState: "not_implemented" | "implemented";
}

interface GetEventsArgs {
  appId: number | string;
  page: number | string;
  query: string;
}

interface GetMostRecentEventArgs {
  appId: number | string;
}

interface IGetEventsResponse {
  events: IEventRow[];
  pagy: IPagy;
}

interface GetEventArgs {
  eventId: string | number;
  appId: number;
}

export interface ImplementedStatus {
  eventName: string;
  received: boolean;
  ingested: boolean;
}

interface CanRenameResponse {
  canRename: boolean;
  lastSentAt: string;
}

export const eventsApi = createApi({
  baseQuery,
  reducerPath: "eventsApi",
  tagTypes: ["Event"],
  endpoints: (builder) => ({
    getEvent: builder.query<IEvent, GetEventArgs>({
      query: ({ appId, eventId }) => ({
        url: `/events/${eventId}`,
        params: { app_id: appId },
      }),
      providesTags: ["Event"],
      transformResponse: (response: SnakeCasedProperties<IEvent>): IEvent =>
        humps.camelizeKeys(response) as IEvent,
    }),
    getCanRenameEvent: builder.query<CanRenameResponse, GetEventArgs>({
      query: ({ appId, eventId }) => ({
        url: `/events/${eventId}/can_rename`,
        params: { app_id: appId },
      }),
      transformResponse: (
        response: SnakeCasedProperties<CanRenameResponse>,
      ) => {
        return humps.camelizeKeys(response) as CanRenameResponse;
      },
    }),
    exportEventCsv: builder.mutation<
      IEvent,
      { appId: number; eventId: number }
    >({
      query: ({ appId, eventId }) => ({
        url: `/events/${eventId}/csv_exports`,
        method: "POST",
        params: { app_id: appId },
      }),
    }),
    renameEvent: builder.mutation<
      IEvent,
      { appId: number; eventId: number; newEventId: number }
    >({
      query: ({ appId, eventId, newEventId }) => ({
        url: `/events/${eventId}/rename`,
        method: "PUT",
        params: { app_id: appId, new_event_id: newEventId },
      }),
      invalidatesTags: ["Event"],
    }),
    createEvent: builder.mutation<void, { appId: number; name: string }>({
      query: ({ appId, name }) => ({
        url: `/events`,
        method: "POST",
        params: { app_id: appId, name },
      }),
      invalidatesTags: ["Event"],
    }),
    updateEvent: builder.mutation<
      { appId: number; id: number; isActive?: boolean },
      { appId: number; eventId: number; isActive?: boolean }
    >({
      query: ({ appId, eventId, isActive }) => ({
        url: `/events/${eventId}`,
        method: "PUT",
        params: { id: eventId, app_id: appId, is_active: isActive },
      }),
      invalidatesTags: ["Event"],
    }),
    getEvents: builder.query<IGetEventsResponse, GetEventsArgs>({
      query: ({ appId, page, query }) => ({
        url: "/events",
        params: { app_id: appId, page, query },
      }),
      providesTags: ["Event"],
      transformResponse: (
        response: SnakeCasedProperties<IGetEventsResponse>,
      ): IGetEventsResponse =>
        humps.camelizeKeys(response) as IGetEventsResponse,
    }),
    getMostRecentEvents: builder.query<
      IGetEventsResponse,
      GetMostRecentEventArgs
    >({
      query: ({ appId }) => ({
        url: `/events/most_recent`,
        params: { app_id: appId },
      }),
      providesTags: ["Event"],
      transformResponse: (response: IGetEventsResponse): IGetEventsResponse =>
        humps.camelizeKeys(response) as IGetEventsResponse,
    }),
    deleteEvent: builder.mutation<IEvent, { appId: number; eventId: number }>({
      query: ({ appId, eventId }) => ({
        url: `/events/${eventId}`,
        method: "DELETE",
        params: humps.decamelizeKeys({ appId, eventId }),
      }),
      invalidatesTags: ["Event"],
    }),
    getImplementedStatus: builder.query<
      { isImplemented: boolean; implementedStatus: ImplementedStatus[] },
      { appId: number; event: string }
    >({
      query: ({ appId, event }) => {
        return {
          url: "/events/implemented",
          params: humps.decamelizeKeys({ appId, event }),
        };
      },
      transformResponse: (
        response: SnakeCasedProperties<{
          isImplemented: boolean;
          implementedStatus: ImplementedStatus[];
        }>,
      ): { isImplemented: boolean; implementedStatus: ImplementedStatus[] } =>
        humps.camelizeKeys(response) as {
          isImplemented: boolean;
          implementedStatus: ImplementedStatus[];
        },
    }),
  }),
});

export const {
  useGetEventQuery,
  useGetCanRenameEventQuery,
  useGetEventsQuery,
  useRenameEventMutation,
  useExportEventCsvMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useLazyGetEventQuery,
  useLazyGetEventsQuery,
  useLazyGetMostRecentEventsQuery,
  useLazyGetImplementedStatusQuery,
  useCreateEventMutation,
} = eventsApi;
