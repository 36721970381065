import { Skeleton, Spinner } from "@chakra-ui/react";
import { ArrowUpRightIcon } from "@heroicons/react/16/solid";
import { ClockIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { Button } from "Components/ui/button";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useCrmSyncSettings } from "core/hooks/useCrmSyncSettings";
import { IHubspotCompany } from "core/hooks/useHubspotCompany";
import { IHubspotContact } from "core/hooks/useHubspotContact";
import { IAttioObjectResponse } from "core/models/attio";
import { Integration } from "core/models/dataMappings";
import {
  useCreateObjectMutation,
  useGetObjectsQuery,
  useUpdateObjectMutation,
} from "core/models/objects/crm";
import { AppObjectType, IAppObject } from "core/types";

function getHubspotLink(
  workspaceIdentifier: string,
  objects: IHubspotContact[] | IHubspotCompany[],
  appObject: IAppObject,
  objectSlug: string,
) {
  if (appObject.objectType === AppObjectType.User) {
    return `https://app-eu1.hubspot.com/contacts/${workspaceIdentifier}/contact/${objects?.[0]?.id}`;
  } else if (appObject.objectType === AppObjectType.Company) {
    return `https://app-eu1.hubspot.com/contacts/${workspaceIdentifier}/company/${objects?.[0]?.id}`;
  }

  if (objectSlug)
    return `https://app-eu1.hubspot.com/contacts/${workspaceIdentifier}/${objectSlug}/${objects?.[0]?.id}`;

  return `https://app-eu1.hubspot.com/contacts/${workspaceIdentifier}/company/${objects?.[0]?.id}`;
}

function getAttioLink(
  workspaceIdentifier: string,
  objects: IAttioObjectResponse[],
  metadata: { route: string } | null,
) {
  return `https://app.attio.com/${workspaceIdentifier}${metadata?.route}${objects?.[0]?.id?.recordId}`;
}

export const CrmSection: React.FC<{
  integration: Integration;
  isInstalled: boolean;
  workspaceIdentifier?: string;
}> = ({ integration, isInstalled, workspaceIdentifier }) => {
  const { appId, appObjectId, objectId } = useParams();
  const navigate = useNavigate();
  const { activeAppObject } = useAppObjects();
  const { groupCrmSyncSettings } = useCrmSyncSettings({
    integration: Integration.Hubspot,
    objectType: activeAppObject?.objectType as AppObjectType,
  });

  const {
    data: fetchedObjects,
    isLoading: isFetchingObjects,
    error: fetchError,
  } = useGetObjectsQuery({
    appId: Number(appId),
    appObjectId: Number(appObjectId),
    objectId: String(objectId),
    integration,
  });

  const [
    createObject,
    { data: createdObjects, isLoading: isCreatingObject, error: createError },
  ] = useCreateObjectMutation();
  const [
    updateObject,
    { data: updatedObjects, isLoading: isUpdatingObject, error: updateError },
  ] = useUpdateObjectMutation();

  if (!isInstalled)
    return (
      <div className="flex h-full flex-col gap-y-5">
        <Button
          onClick={() =>
            navigate(`/a/${appId}/integrations/${integration.toLowerCase()}`)
          }
          variant="outline"
          size="sm"
        >
          Install integration
        </Button>
      </div>
    );

  if (fetchError)
    return (
      <div className="flex flex-col gap-y-2">
        <p className="text-sm text-gray-600">
          Error fetching from{" "}
          <p className="inline-block capitalize">{integration}</p>. Please make
          sure you have completed the integration setup.
        </p>
        <Button
          variant="outline"
          onClick={() =>
            navigate(
              `/a/${appId}/integrations/${integration.toLowerCase()}/${activeAppObject?.objectType}`,
            )
          }
          size="sm"
        >
          Finish setup
        </Button>
      </div>
    );
  if (createError)
    return (
      <div className="flex flex-col gap-y-2">
        <p className="text-sm text-gray-600">
          Error creating object in{" "}
          <p className="inline-block capitalize">{integration}</p>.
        </p>
        <Button
          variant="outline"
          onClick={() => {
            createObject({
              appId: Number(appId),
              appObjectId: Number(appObjectId),
              objectId: String(objectId),
              integration,
            })
              .unwrap()
              .then(() => {
                toast.success("Object created");
              })
              .catch(() => {
                toast.error("Failed to create object");
              });
          }}
          size="sm"
        >
          {isCreatingObject ? <Spinner size="sm" /> : <>Try again</>}
        </Button>
      </div>
    );
  if (updateError)
    return (
      <div className="flex flex-col gap-y-2">
        <p className="text-sm text-gray-600">
          Error resyncing object in{" "}
          <p className="inline-block capitalize">{integration}</p>
        </p>
        <Button
          variant="outline"
          onClick={() => {
            const updatePromises = objects.map((object) =>
              updateObject({
                appId: Number(appId),
                appObjectId: Number(appObjectId),
                objectId: String(objectId),
                integration,
                crmId:
                  integration === Integration.Hubspot
                    ? (object as IHubspotContact | IHubspotCompany).id
                    : (object as IAttioObjectResponse).id.recordId,
              }).unwrap(),
            );

            Promise.all(updatePromises)
              .then(() => {
                toast.success("Resynced", {
                  description: (
                    <p className="text-sm text-gray-600">
                      Objects resynced in{" "}
                      <p className="inline-block capitalize">{integration}</p>
                    </p>
                  ),
                });
              })
              .catch(() => {
                toast.error("Error", {
                  description: (
                    <p className="text-sm text-gray-600">
                      Failed to resync objects
                    </p>
                  ),
                });
              });
          }}
          size="sm"
        >
          {isUpdatingObject ? <Spinner size="sm" /> : "Try again"}
        </Button>
      </div>
    );

  if (isFetchingObjects)
    return (
      <div className="flex h-full flex-col gap-y-5">
        <Skeleton height="20px" width="full" rounded="md" />
      </div>
    );

  if (!fetchedObjects)
    return (
      <div className="flex h-full flex-col gap-y-5">
        <p className="text-sm text-gray-600">No data</p>
      </div>
    );

  const objects =
    createdObjects?.objects ||
    updatedObjects?.objects ||
    fetchedObjects.objects;
  const metadata =
    createdObjects?.metadata ||
    updatedObjects?.metadata ||
    fetchedObjects.metadata;

  const crmEntity =
    createdObjects?.crmEntity ||
    updatedObjects?.crmEntity ||
    fetchedObjects.crmEntity;

  if (objects.length === 0) {
    return (
      <div className="flex h-full flex-col gap-y-5">
        <div className="flex items-center gap-x-2">
          <p className="text-sm text-gray-600">No object found in CRM</p>
        </div>
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            createObject({
              appId: Number(appId),
              appObjectId: Number(appObjectId),
              objectId: String(objectId),
              integration,
            })
              .unwrap()
              .then(() => {
                toast.success("Object created");
              })
              .catch(() => {
                toast.error("Failed to create object");
              });
          }}
        >
          {isCreatingObject ? (
            <Spinner size="sm" />
          ) : (
            <>
              Create in{" "}
              <p className="-ml-1 inline-block capitalize">{integration}</p>
            </>
          )}
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-2">
      <p className="text-sm lowercase text-gray-600">
        {objects.length}{" "}
        {objects.length > 1
          ? activeAppObject?.pluralName
          : activeAppObject?.singularName}{" "}
        found in <p className="inline-block capitalize">{integration}</p>
      </p>
      <div className="flex flex-col gap-y-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            activeAppObject &&
              workspaceIdentifier &&
              window.open(
                integration === Integration.Hubspot
                  ? getHubspotLink(
                      workspaceIdentifier,
                      objects as IHubspotContact[] | IHubspotCompany[],
                      activeAppObject,
                      groupCrmSyncSettings?.objectSlug || "",
                    )
                  : getAttioLink(
                      workspaceIdentifier,
                      objects as IAttioObjectResponse[],
                      metadata,
                    ),
                "_blank",
              );
          }}
        >
          View in
          <p className="-ml-1 inline-block capitalize">{integration}</p>
          <ArrowUpRightIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            const updatePromises = objects.map((object) =>
              updateObject({
                appId: Number(appId),
                appObjectId: Number(appObjectId),
                objectId: String(objectId),
                integration,
                crmId:
                  integration === Integration.Hubspot
                    ? (object as IHubspotContact | IHubspotCompany).id
                    : (object as IAttioObjectResponse).id.recordId,
              }).unwrap(),
            );

            Promise.all(updatePromises)
              .then(() => {
                toast.success("Resynced", {
                  description: (
                    <p className="text-sm text-gray-600">
                      Objects resynced in{" "}
                      <p className="inline-block capitalize">{integration}</p>
                    </p>
                  ),
                });
              })
              .catch(() => {
                toast.error("Error", {
                  description: (
                    <p className="text-sm text-gray-600">
                      Failed to resync objects
                    </p>
                  ),
                });
              });
          }}
        >
          {isUpdatingObject ? <Spinner size="sm" /> : "Sync now"}
        </Button>
        <div className="flex items-center gap-x-1">
          <ClockIcon className="inline-block size-3.5 text-gray-600" />
          <p className="text-xs text-gray-600">
            Last synced {moment(crmEntity?.updatedAt).fromNow()}
          </p>
        </div>
      </div>
    </div>
  );
};
