import { Button } from "@chakra-ui/react";

interface ISlackInsightActionButtonProps {
  isLoading: boolean;
  isEnabled: boolean;
  isSlackConnected: boolean;
  onConnectSlack: () => void;
  onAction: () => void;
  actionState: "NEW" | "PAUSED" | "ACTIVE" | "MODIFIED";
}

export const SlackInsightActionButton: React.FC<
  ISlackInsightActionButtonProps
> = ({
  isLoading,
  isEnabled,
  isSlackConnected,
  onConnectSlack,
  onAction,
  actionState,
}) => {
  if (!isSlackConnected) {
    return (
      <Button
        isLoading={isLoading}
        onClick={onConnectSlack}
        colorScheme="green"
      >
        Connect Slack
      </Button>
    );
  }

  switch (actionState) {
    case "NEW":
      return (
        <Button
          isLoading={isLoading}
          onClick={onAction}
          isDisabled={!isEnabled}
          colorScheme="green"
        >
          Set live
        </Button>
      );
    case "PAUSED":
      return (
        <Button
          isLoading={isLoading}
          onClick={onAction}
          isDisabled={!isEnabled}
          colorScheme="green"
        >
          Set live
        </Button>
      );
    case "MODIFIED":
      return (
        <Button
          isLoading={isLoading}
          onClick={onAction}
          isDisabled={!isEnabled}
          colorScheme="green"
        >
          Update
        </Button>
      );
    case "ACTIVE":
      return (
        <Button
          isLoading={isLoading}
          onClick={onAction}
          isDisabled={!isEnabled}
        >
          Pause
        </Button>
      );
  }
};
