import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  ComponentDefaultProps,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import {
  CodeBracketIcon,
  DocumentIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

import colors from "core/design-system/constants/theme/colors";
import { useImplemented } from "core/hooks/useImplemented";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { useReportSetup } from "core/hooks/useReportSetup";
import useSdkToken from "core/hooks/useSdkToken";
import { ImplementedStatus } from "core/models/events";
import { InviteTeam } from "modules/Onboarding/InviteTeam";
import { SDKs } from "modules/Onboarding/SetupSources/SDKs";
import ScriptGenerator from "modules/Sdk/ScriptGenerator";

const CodeEditor = React.lazy(() => import("@uiw/react-textarea-code-editor"));

interface IEventCodeSnippet extends ComponentDefaultProps {
  entity: string;
  eventName: string;
}

function implementedStatusIcon(status: ImplementedStatus) {
  if (!status.received || !status.ingested)
    return (
      <Spinner
        thickness="2px"
        speed="1.2s"
        emptyColor="gray.200"
        color="purple.500"
        size="sm"
      />
    );

  return <CheckCircleIcon className="h-5 text-green-500" />;
}

function implementedStatusBgColor(status: ImplementedStatus) {
  if (!status.received) return "#FFFBF3";
  if (status.received && !status.ingested) return colors.purple[50];

  return colors.green[50];
}

function implementedStatusText(status: ImplementedStatus) {
  if (!status.received) return "Waiting for data...";
  if (status.received && !status.ingested)
    return `We've received your event! Hang tight, almost there...`;

  return "All set";
}

export const EventCodeSnippet: React.FC<IEventCodeSnippet> = ({
  entity,
  eventName,
  ...props
}) => {
  const { appId } = useParams();
  const { token } = useSdkToken(Number(appId));
  const { sdk, setSdk } = useOnboardingChecklist();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { invalidateInsights, fetchReport } = useReportSetup();
  const event = eventName || "";
  const { isImplemented, implementedStatus: status } = useImplemented({
    event,
  });
  const [isSetupComplete, setIsSetupComplete] = useState<boolean>(
    Boolean(status?.ingested),
  );
  const hasReportBeenFetched = useMemo(
    () => isSetupComplete,
    [isSetupComplete],
  );

  useEffect(() => {
    setIsSetupComplete(Boolean(status?.ingested));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status?.ingested]);

  useEffect(() => {
    if (isSetupComplete && !hasReportBeenFetched) {
      setTimeout(() => {
        invalidateInsights();
        fetchReport();
      }, 1700);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetupComplete]);

  if (isImplemented || !status) return <></>;

  return (
    <AnimatePresence>
      {!isSetupComplete && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{
            y: -50,
            opacity: 0,
            transition: { delay: 1.5, duration: 0.5 },
          }}
          className={`relative my-5 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow ${props.className}`}
        >
          <div
            style={{ background: implementedStatusBgColor(status) }}
            className="flex flex-row items-center justify-between px-5 py-3"
          >
            <div className="flex flex-row items-center space-x-2">
              {implementedStatusIcon(status)}
              <p className="text-sm font-medium">
                {implementedStatusText(status)}
              </p>
            </div>
          </div>
          <motion.div
            exit={{
              height: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
          >
            <div className="space-y-5 px-5 py-4">
              <p className="font-medium">
                Add the following code snippet to track this {entity}
              </p>
              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        px={4}
                        py={3}
                        bg="gray.50"
                        _active={{ bg: "gray.50" }}
                        _focus={{ bg: "gray.50" }}
                        className={"flex flex-row justify-between"}
                        _hover={{ background: "gray.100" }}
                      >
                        <p className="text-sm">
                          <CodeEditor
                            value={`analytics.track('${event}')`}
                            language="js"
                            data-color-mode="light"
                            readOnly
                            padding={0}
                            style={{
                              borderRadius: "0px",
                              padding: 0,
                              width: "100%",
                              fontSize: 12,
                              background: "transparent",
                              fontFamily:
                                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                            }}
                          />
                        </p>
                        <div className="flex flex-row items-center space-x-2">
                          <p className="text-sm">
                            {isExpanded ? "Hide" : "View more"}
                          </p>
                          <AccordionIcon />
                        </div>
                      </AccordionButton>
                      <AccordionPanel p={0}>
                        {isExpanded && <hr />}
                        <ScriptGenerator
                          p={0}
                          m={0}
                          sdks={SDKs}
                          sdk={sdk}
                          onSelectSdk={setSdk}
                          codeKey="featureScript"
                          token={token}
                          language={sdk.installLanguage}
                          theme="light"
                          showHeader={true}
                          onReplace={(code) =>
                            code.replace("FEATURE_EVENT_NAME", event)
                          }
                          containerProps={{ borderRadius: 0 }}
                          headerContainerProps={{ py: 2, px: 3 }}
                        />
                        {isExpanded && (
                          <div className="flex flex-row items-center justify-between px-3 py-3">
                            <span />
                            <div className="flex flex-row">
                              <Button
                                variant="ghost"
                                colorScheme="purple"
                                leftIcon={<DocumentIcon className="h-4" />}
                                as={Link}
                                to={`https://june.so/docs`}
                                target="_blank"
                              >
                                Documentation
                              </Button>

                              <Button
                                variant="ghost"
                                colorScheme="purple"
                                as={Link}
                                leftIcon={<CodeBracketIcon className="h-4" />}
                                to={`/a/${appId}/settings/developers`}
                              >
                                Developer tools
                              </Button>
                            </div>
                          </div>
                        )}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
              <div className="space-y-2">
                <p className="text-sm font-medium">
                  Get help from someone on your team
                </p>
                <div className="flex flex-row space-x-3">
                  <Button
                    onClick={onOpen}
                    leftIcon={<EnvelopeIcon className="h-4" />}
                  >
                    Send invite
                  </Button>
                </div>
              </div>
            </div>
          </motion.div>
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="3xl"
            data-id-share-modal
          >
            <ModalOverlay />
            <ModalContent borderRadius="lg" p={10}>
              <InviteTeam page="onboarding_welcome" />
              <ModalCloseButton />
            </ModalContent>
          </Modal>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
