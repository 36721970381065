import { Box, Text } from "@chakra-ui/react";

import { CompanyJuneLogoIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { useInsightTitle } from "core/hooks/useInsightTitle";
import { IReport } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

export const DownloadableGraphImage: React.FC<{
  report: IReport;
  showYAxis: boolean;
  showInProgress: boolean;
  screenshotMode: boolean;
  insight: IInsightSection;
  config: any;
  additionalConfigProps?: {
    selectedWeek?: string;
    selectedMonth?: string;
    initialTimeRange?: string;
  };
  sharingSecretToken?: string;
}> = ({
  report,
  insight,
  config,
  showYAxis,
  showInProgress,
  screenshotMode,
  sharingSecretToken,
  additionalConfigProps,
}) => {
  const insightTitle = useInsightTitle({ title: insight.title });

  return (
    <Box id="downloadable-image">
      <Box borderRadius="lg" bg="gray.50">
        <Text
          textTransform="capitalize"
          pt={4}
          color="gray.700"
          textAlign="center"
          fontWeight="bold"
          fontSize="lg"
        >
          {insightTitle}
        </Text>
        <Box px={8} py={4}>
          <insight.section
            report={report}
            boxShadow="lg"
            config={config}
            screenshotMode={screenshotMode}
            showYAxis={showYAxis}
            additionalConfigProps={additionalConfigProps}
            showInProgress={showInProgress}
            sharingSecretToken={sharingSecretToken}
            showTooltips={false}
          />
          <Box mt={4} p={1} mx="auto" w="200px">
            <Text
              color="gray.700"
              fontWeight="semibold"
              fontSize="xs"
              textAlign="center"
            >
              Powered by June <CompanyJuneLogoIcon ml={1} fontSize={20} />
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
