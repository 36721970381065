import { Box, Divider, Progress } from "@chakra-ui/react";
import React from "react";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useImportTasks } from "core/hooks/useImportTasks";
import { IImportTask } from "core/types/ImportTask";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";

export const ImportProgress: React.FC<{}> = () => {
  const currentApp = useCurrentApp();
  const { importTasks } = useImportTasks({ appId: currentApp.id });

  if (!importTasks) return null;

  const unfinishedImportTasks = importTasks.filter(
    (importTask: IImportTask) => !importTask.finished && !importTask.failed,
  );

  if (!unfinishedImportTasks.length) return null;

  const lastImportTask =
    unfinishedImportTasks[unfinishedImportTasks.length - 1];

  const getProgressString = () => {
    switch (true) {
      case lastImportTask.queued:
        return "Import starting...";
      case lastImportTask.started:
        return `Importing...`;
      default:
        return "";
    }
  };

  const progressString = getProgressString();

  return (
    <>
      <Box mx={3} px={2} py={2}>
        <div className="flex items-start gap-2">
          <div className="flex w-5 items-center justify-center">
            {lastImportTask.sourceName === "amplitude" && (
              <img
                className="h-4 w-4"
                src="/modules/sidebar/amplitude.svg"
                alt="Amplitude"
              />
            )}
            {lastImportTask.sourceName === "mixpanel" && (
              <img
                className="h-4 w-4"
                src="/modules/sidebar/mixpanel.svg"
                alt="Mixpanel"
              />
            )}
          </div>
          <div className="flex w-full flex-col">
            <ItemText>{progressString}</ItemText>

            {!lastImportTask.queued && (
              <>
                <Progress
                  width="100%"
                  borderRadius="sm"
                  mt={2}
                  colorScheme="purple"
                  size="xs"
                  value={lastImportTask.progress}
                />
              </>
            )}
          </div>
        </div>
      </Box>
      <div className="mx-3 mb-2">
        <Divider />
      </div>
    </>
  );
};
