import { DownloadIcon } from "@chakra-ui/icons";
import { Button, ButtonProps, Text } from "@chakra-ui/react";
import React from "react";

interface DrilldownDownloadButtonProps extends ButtonProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
}
export const DrilldownDownloadButton: React.FC<
  DrilldownDownloadButtonProps
> = ({ isDisabled, isLoading, onClick, ...props }) => {
  return (
    <Button
      my={4}
      px={4}
      py={2}
      borderRadius="lg"
      colorScheme="purple"
      isDisabled={isDisabled}
      onClick={onClick}
      isLoading={isLoading}
      aria-label="Export as CSV"
      leftIcon={<DownloadIcon />}
      data-testid="drilldown-download-button"
      {...props}
    >
      <Text fontSize="sm">Export as CSV</Text>
    </Button>
  );
};
