import { Select, Text } from "@chakra-ui/react";

import { RELATIVE_TIMERANGE_VALUE_FROM_KEYS } from "core/constants/timerange";
import { IAlert } from "core/types/Alert";
import { ITimerangeOption } from "core/types/TimerangeOption";

export const Timerange: React.FC<{
  alertChanges: IAlert;
  setAlertChanges: (alert: IAlert) => void;
  timerangeOptions: ITimerangeOption[];
}> = ({ setAlertChanges, alertChanges, timerangeOptions }) => {
  const handleTimerangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAlertChanges({
      ...alertChanges,
      insightTimerange: parseInt(e.target.value),
    });
  };

  return (
    <div className="flex flex-col">
      <div className="flex gap-1">
        <Text mb={0} fontWeight="semibold">
          Timerange
        </Text>
      </div>
      <Text mb={2} fontSize="sm" color="gray.600">
        The timerange of the insight
      </Text>
      <Select
        size="sm"
        width="200px"
        placeholder="Select timerange"
        value={alertChanges.insightTimerange?.toString() || ""}
        onChange={handleTimerangeChange}
        data-testid="timerange-select"
      >
        {timerangeOptions.map((option) => (
          <option
            key={option.short}
            value={
              RELATIVE_TIMERANGE_VALUE_FROM_KEYS[
                option.value as keyof typeof RELATIVE_TIMERANGE_VALUE_FROM_KEYS
              ]
            }
            data-testid={`alert-insight-timerange-${option.short}-option`}
          >
            {option.short}
          </option>
        ))}
      </Select>
    </div>
  );
};
