import { Button } from "@chakra-ui/react";
import moment from "moment";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { useDisclosure } from "@/core/hooks/useDisclosure";
import { SingleEventAlertDrawer } from "@/modules/Settings/Slack/SingleEventAlertDrawer";
import { SlackInsightEditorDrawer } from "core/components/InsightCard/SlackInsightEditorDrawer";
import { FREQUENCIES } from "core/constants/alert";
import { IAlertWithAlertable } from "core/models/alerts";
import { useGetSlackChannelsByIdQuery } from "core/models/slackApps";
import { AlertWithAlertableSchema } from "core/types/Alert";
import { EditorDrawer } from "modules/Audiences/Alerts/EditorDrawer";
import {
  getInsightConfig,
  getReportConfigByType,
} from "modules/ViewInsight/ViewInsightContainer";

const getAlertableDescription = (alert: IAlertWithAlertable): string => {
  const parsedAlert = AlertWithAlertableSchema.safeParse(alert);
  if (!parsedAlert.success) {
    console.error("Invalid alert data:", parsedAlert.error);
    return "Unknown";
  }

  switch (parsedAlert.data.alertableType) {
    case "Report":
      return parsedAlert.data.alertable.name;
    case "Audience":
      return "Audience";
    case "Event":
      return "Event";
    case "ViewInsight":
      return parsedAlert.data.alertable.insight.name;
    default:
      return "Unknown";
  }
};

const getAlertableTitle = (alert: IAlertWithAlertable): string => {
  const parsedAlert = AlertWithAlertableSchema.safeParse(alert);
  if (!parsedAlert.success) {
    console.error("Invalid alert data:", parsedAlert.error);
    return "Unknown Alert";
  }

  if (
    parsedAlert.data.alertableType === "Report" &&
    parsedAlert.data.insightId !== undefined &&
    parsedAlert.data.appObject
  ) {
    const insight = getInsightConfig(
      parsedAlert.data.alertable.reportType,
      parsedAlert.data.insightId,
    );

    if (insight?.title) {
      return insight.title.replace(
        /{APP_OBJECT_PLURAL_NAME}/,
        parsedAlert.data.appObject.pluralName.toLowerCase(),
      );
    }
  }

  switch (parsedAlert.data.alertableType) {
    case "Report":
      return parsedAlert.data.alertable.name;
    case "Audience":
      return parsedAlert.data.alertable.name;
    case "Event":
      return parsedAlert.data.alertable.title;
    case "ViewInsight":
      return parsedAlert.data.alertable.insight.name;
    default:
      return "Unknown Alert";
  }
};

export const AlertRow: React.FC<{
  alert: IAlertWithAlertable;
}> = ({ alert }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const title = getAlertableTitle(alert);
  const description = getAlertableDescription(alert);

  const { data: slackChannel, isLoading: isLoadingSlackChannel } =
    useGetSlackChannelsByIdQuery({
      channelId: alert.slackChannelId || "",
      appId: alert.appId,
    });

  const frequency = FREQUENCIES.find((f) => f.value === alert.frequency)?.label;

  const parsedAlert = AlertWithAlertableSchema.safeParse(alert);
  if (!parsedAlert.success) {
    console.error("Invalid alert data:", parsedAlert.error);
    return null;
  }

  const insight =
    parsedAlert.data.alertableType === "Report" &&
    parsedAlert.data.insightId !== undefined
      ? getInsightConfig(
          parsedAlert.data.alertable.reportType,
          parsedAlert.data.insightId,
        )
      : undefined;
  const reportConfig =
    parsedAlert.data.alertableType === "Report"
      ? getReportConfigByType(parsedAlert.data.alertable.reportType)
      : undefined;

  return (
    <>
      <tr>
        <td className="flex flex-col whitespace-nowrap py-1 text-sm">
          <div className="text-gray-900">{title}</div>
          <div className="whitespace-nowrap text-xs text-gray-500">
            {description}
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-900">
          {isLoadingSlackChannel ? (
            <div className="flex h-[20px] w-[72px] animate-pulse flex-row items-center justify-center space-x-5">
              <div className="h-full w-full rounded-md bg-gray-300"></div>
            </div>
          ) : slackChannel ? (
            `#${slackChannel.name}`
          ) : (
            "Channel not found"
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-900">
          {frequency}
        </td>
        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-900">
          {moment.utc(alert.lastSentAt).fromNow() || "Never"}
        </td>
        <td className="relative whitespace-nowrap py-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          {parsedAlert.data.alertableType === "ViewInsight" ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button colorScheme="purple" variant="ghost" isDisabled={true}>
                  Edit
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                This alert can only be edited from the dashboard page
              </TooltipContent>
            </Tooltip>
          ) : (
            <Button colorScheme="purple" variant="ghost" onClick={onOpen}>
              Edit
            </Button>
          )}
        </td>
      </tr>
      {parsedAlert.data.alertableType === "Report" && insight && (
        <SlackInsightEditorDrawer
          config={reportConfig}
          report={parsedAlert.data.alertable}
          insight={insight}
          isOpen={isOpen}
          onClose={onClose}
          isLoading={false}
          appId={String(parsedAlert.data.appId)}
          reportId={String(parsedAlert.data.alertable.id)}
        />
      )}
      {parsedAlert.data.alertableType === "Event" && (
        <SingleEventAlertDrawer
          alert={parsedAlert.data}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      {parsedAlert.data.alertableType === "Audience" && (
        <EditorDrawer
          isOpen={isOpen}
          onClose={onClose}
          audienceId={String(parsedAlert.data.alertable.id)}
        />
      )}
    </>
  );
};
