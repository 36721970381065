import { Text, Flex } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";

import { CompanyJuneLogoIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";

const Message = ({ body, ...props }) => {
  return (
    <Flex
      px={4}
      py={4}
      w="100%"
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      bg={"white"}
      {...props}
    >
      <Flex>
        <CompanyJuneLogoIcon h={8} w={8} />
      </Flex>
      <Flex ml={4} direction="column" w="100%">
        <Flex align="center">
          <Text fontWeight="semibold">June</Text>
          <Flex
            bg="gray.100"
            borderRadius="md"
            style={{ padding: "1px 3px" }}
            mx={2}
            align="center"
          >
            <Text
              color="gray.600"
              fontWeight="bold"
              fontSize="10px"
              textTransform="uppercase"
            >
              App
            </Text>
          </Flex>
          <Text color="gray.600" fontSize="sm">
            {" "}
            10:37 AM
          </Text>
        </Flex>
        {body || (
          <Text color="gray.600">June was added to the #activity channel</Text>
        )}
      </Flex>
    </Flex>
  );
};

Message.defaultProps = {
  body: undefined,
};

Message.propTypes = {
  body: PropTypes.string,
};

export default Message;
