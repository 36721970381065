import { Stack } from "@chakra-ui/react";
import { Button } from "@june-so/ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { List } from "core/components/List";
import { PageContainer } from "core/components/PageContainer";
import { SearchBar } from "core/components/SearchBar";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import usePagination from "core/hooks/usePagination";
import { ViewLocation } from "core/hooks/useViews";
import { IView, useAddViewMutation, useGetViewsQuery } from "core/models/views";
import { DefaultHeader } from "modules/Pages/DefaultHeader";
import { config } from "modules/Views/List/config";

export const Views: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const [searchQuery, setSearchQuery] = useState("");
  const { currentPage, previousPage, nextPage } = usePagination({
    searchQuery,
  });
  const { data, isLoading, isFetching } = useGetViewsQuery({
    appId,
    page: currentPage,
    query: searchQuery,
    location: ViewLocation.Home,
    pinned: false,
  });
  const views = data?.views || [];
  const [addView] = useAddViewMutation();
  const navigate = useNavigate();

  const onCreate = async function () {
    const { data } = (await addView({
      appId,
      name: "Untitled",
      pin: false,
    })) as { data: IView };
    navigate(`/a/${appId}/home/${data?.id}`);
    toast.success(`Dashboard created`, {
      description: `You can now start adding insights to this dashboard.`,
    });
  };

  return (
    <PageContainer>
      <div className="flex flex-col gap-2">
        <DefaultHeader
          section={{
            title: "Dashboards",
            description:
              "Dashboards are custom pages where you can add insights",
            sectionRight: () => (
              <Button color="purple" onClick={onCreate}>
                Create dashboard
              </Button>
            ),
          }}
        />
      </div>
      <Stack justify="flex-start" align="flex-start" mb={4}>
        <SearchBar
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Search a dashboard by name..."
        />
      </Stack>
      <List
        isLoading={isLoading || isFetching}
        pagy={data?.pagy}
        config={{ ...config, resourceName: "dashboard" }}
        rows={views}
        pagination={{
          currentPage,
          previousPage,
          nextPage,
        }}
      />
    </PageContainer>
  );
};
