import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { USER_TRAIT_FILTER } from "core/constants/report-setup";
import { useReportSetup } from "core/hooks/useReportSetup";
import { TraitKeyPicker } from "core/modules/reports/setup/Breakdown/TraitKeyPicker";
import { IBreakdown } from "core/types/Breakdown";

export const TraitPicker: React.FC<{
  breakdown: IBreakdown;
}> = ({ breakdown }) => {
  const [traitPickerOpen, setTraitPickerOpen] = useState(false);
  const isUserTrait = breakdown?.traitType === USER_TRAIT_FILTER;
  const { addBreakdown, removeBreakdown } = useReportSetup();

  useEffect(() => {
    if (breakdown.adding && !breakdown.trait) {
      setTraitPickerOpen(true);
    }
  }, [breakdown]);

  function onTraitSelected(trait: string) {
    const _breakdown = {
      ...breakdown,
      adding: false,
      trait,
    };
    addBreakdown({ breakdown: _breakdown });
  }

  function onBreakdownRemoved() {
    removeBreakdown();
  }

  return (
    <Flex>
      {!breakdown.trait && (
        <TraitKeyPicker
          isOpen={traitPickerOpen}
          isUserTrait={isUserTrait}
          onOpen={() => setTraitPickerOpen(true)}
          onRemoveFilter={onBreakdownRemoved}
          onTraitSelected={onTraitSelected}
        />
      )}
    </Flex>
  );
};
