import { Button, Flex, Link, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import InsightCard from "core/components/InsightCard/Index";
import { InsightReactions } from "core/components/InsightReactions";
import { FeatureReleaseUsersEmptyState } from "core/design-system/components/Icon/Illustrations/FeatureReleaseUsersEmptyState";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { ReferrersList } from "core/modules/reports/report-types/NewUsers/ReferrersList";
import { ReferrersTable } from "core/modules/reports/report-types/NewUsers/ReferrersTable";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

export interface IReferrer {
  referrerDomain: string;
  usersCount: number;
}

export interface IReferrersData {
  referrers?: IReferrer[];
  insightSlug?: "referrers" | "signup-referrers";
}

const mockData = {
  referrers: [
    { referrerDomain: "google.com", usersCount: 439 },
    { referrerDomain: "linkedin.com", usersCount: 324 },
    { referrerDomain: "producthunt.com", usersCount: 291 },
    { referrerDomain: "facebook.com", usersCount: 140 },
    { referrerDomain: "news.ycombinator.com", usersCount: 53 },
    { referrerDomain: "dribbble.com", usersCount: 18 },
  ],
} as IReferrersData;

interface IReferrersInsight extends IReportInsight {
  insightSlug: "referrers" | "signup-referrers";
}

export const ReferrersContainer: React.FC<IReferrersInsight> = ({
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken,
  report,
  config,
  insightSlug,
}) => {
  const { reportId } = useParams<{ reportId: string }>() as {
    reportId: string;
  };
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === insightSlug,
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      insight,
      report,
      sharingSecretToken,
      previewMode,
      skip: !isSetupValid,
    });

  const data = previewMode ? mockData : (response?.data as IReferrersData);
  const referrers = data?.referrers;
  const noData = !data || referrers?.length === 0;
  const viewAll = Boolean(referrers?.length && referrers.length > 5);

  return (
    <>
      <InsightCard>
        {(Card) => (
          <Card.Container insight={insight} id={insight.slug} colSpan={0.5}>
            <Card.Header
              hasCaching={response?.hasCaching}
              screenshotMode={screenshotMode}
              showReportTitle={sharingMode}
              insight={insight}
              showActions={!sharingMode && !previewMode}
              sharingMode={sharingMode}
              config={config}
              report={report}
              refresh={onRefreshInsight}
              cachedAt={response?.cachedAt}
            />
            <Card.Body
              isLoading={!data || isLoading || isFetching}
              isPreviewMode={previewMode}
              isSetupValid={isSetupValid}
              error={error}
            >
              {noData && !isLoading ? (
                <Flex p={5} h={400} direction="row" align="center">
                  <Flex mx="auto" direction="column" align="center">
                    <FeatureReleaseUsersEmptyState />
                    <Text mt={5} fontSize="md" fontWeight="medium">
                      There is no data to show for this insight
                    </Text>
                    <Text mt={1} fontSize="sm" color="gray.600">
                      Wait for your first user or{" "}
                      <Link
                        href="https://help.june.so/en/articles/5983110-new-users#h_3cd363ce1d"
                        isExternal
                      >
                        {" "}
                        learn how to set up
                      </Link>
                    </Text>
                  </Flex>
                </Flex>
              ) : (
                <Flex h={400} direction="column" align="center">
                  <ReferrersTable
                    referrers={referrers?.slice(0, 5)}
                    insightSlug={insightSlug}
                  />
                </Flex>
              )}
            </Card.Body>
            {!sharingMode && !previewMode && !noData && !isLoading && (
              <Card.Footer>
                <Flex w="100%" direction="column">
                  <Flex>
                    <Flex
                      px={4}
                      pt={4}
                      pb={0}
                      w="100%"
                      direction="column"
                      align="center"
                    >
                      <Text fontSize="sm">
                        These are the most visited sites bringing{" "}
                        {insightSlug === "referrers" ? "visitors" : "users"}
                      </Text>
                      <Flex
                        mt={2}
                        px={3}
                        py={2}
                        pb={0}
                        w="100%"
                        justify="space-between"
                      >
                        <Button
                          w="48%"
                          borderRadius="lg"
                          as="a"
                          href="https://www.june.so/blog/how-to-grow-b2b-saas-startup"
                          target="_blank"
                        >
                          <Text fontSize="sm" fontWeight="semibold">
                            Read playbook
                          </Text>
                        </Button>
                        <Button
                          w="48%"
                          borderRadius="lg"
                          colorScheme="purple"
                          disabled={sharingMode || previewMode}
                          onClick={onOpen}
                          data-testid="view-all-referrers"
                        >
                          <Text fontSize="sm" fontWeight="semibold">
                            View All
                          </Text>
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                  <InsightReactions
                    reactions={response?.reactions}
                    insightId={insight.typeId}
                    reportId={Number(reportId)}
                  />
                </Flex>
              </Card.Footer>
            )}
          </Card.Container>
        )}
      </InsightCard>
      {!sharingMode && !previewMode && viewAll && referrers && (
        <ReferrersList
          isOpen={isOpen}
          onClose={onClose}
          initialReferrers={referrers}
          insight={insight}
          insightSlug={insightSlug}
        />
      )}
    </>
  );
};
