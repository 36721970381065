import { Flex } from "@chakra-ui/react";
import React from "react";

import { IEventRow } from "core/models/events";
import EventFrequencyBars from "core/modules/reports/setup/Menu/EventFrequencyBars";

const EventFrequencyCell = ({ props: event }: { props: IEventRow }) => {
  return (
    <Flex align="center">
      <Flex align="flex-start" direction="column">
        <EventFrequencyBars event={event} tooltipPlacement="top" />
      </Flex>
    </Flex>
  );
};

export default EventFrequencyCell;
