import { Flex, Text } from "@chakra-ui/react";
import { IEventRow } from "modules/Events/List/EventsList";
import React from "react";

import { Icon } from "core/design-system/components/Icon";

export const GroupContextStatus = ({ props: event }: { props: IEventRow }) => {
  const { isReceivingGroupContext } = event;

  return (
    <Flex justify="flex-end" align="center">
      {isReceivingGroupContext ? (
        <Icon
          h={3}
          w={3}
          iconType="icon"
          name="check"
          color="success.400"
          data-testid="check-icon"
        />
      ) : (
        <Text fontSize="sm">-</Text>
      )}
    </Flex>
  );
};
