import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { Features } from "core/components/Stripe/Features";
import { Settings } from "core/components/Stripe/Settings";
import { STRIPE_INTEGRATION } from "core/constants/features";
import { trackEvent } from "core/helpers/trackEvent";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import useFlag from "core/hooks/useFlag";
import {
  useDeleteStripeAppMutation,
  useGetStripeAppsQuery,
} from "core/models/stripeApps";
import { UninstallModal } from "modules/Settings/Integrations/Hubspot/UninstallModal";
import {
  Body,
  Container,
  Header,
  IntegrationMetadata,
  Section,
} from "modules/Settings/Integrations/Integration";

const CONTENT: IntegrationMetadata = {
  title: "Stripe",
  description: "Send revenue data to June",
  docsUrl: "https://help.june.so/en/articles/10430832-stripe-integration",
  logoComponent: (
    <img className="rounded-sm" src="/stripe.svg" alt="Stripe logo" />
  ),
  isBeta: true,
};
export const Stripe: React.FC = () => {
  const {
    isOpen: isConfirmationOpen,
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
  } = useDisclosure();
  const { id } = useCurrentApp();
  const [searchParams] = useSearchParams();
  const isTestMode = searchParams.get("isTestMode") === "true";
  const hasStripeIntegration = useFlag(STRIPE_INTEGRATION);
  const { data: stripeApps, isLoading } = useGetStripeAppsQuery({
    appId: id,
  });
  const isConnected =
    Number(stripeApps?.stripeApps?.length) > 0 &&
    Number(stripeApps?.stripeApps[0]?.id) !== undefined;

  const handleConnect = () => {
    trackEvent({ eventName: "clicked_install_stripe", appId: id });
    // Use the test mode URL for beta
    window.location.href = isTestMode
      ? `https://marketplace.stripe.com/oauth/v2/channellink*AZPebOBqbwAAAF7U%23EhcKFWFjY3RfMUpQVXhrR1hxZHY0c0ZKcA/authorize?client_id=ca_LF174Y6LX3QIjmOzkDKmk9n80UnSHaB8&redirect_uri=https://backend.june.so/auth/stripe/callback`
      : `https://marketplace.stripe.com/oauth/v2/channellink*AZPebOBqbwAAAF7U%23EhcKFWFjY3RfMUpQVXhrR1hxZHY0c0ZKcA/authorize?client_id=ca_LF17rU9bzKAioQ5X05bMGeaGCBAkwNvl&redirect_uri=https://backend.june.so/auth/stripe/callback`;
  };

  const [deleteStripeApp] = useDeleteStripeAppMutation();

  const handleUninstall = async () => {
    trackEvent({
      eventName: "clicked_stripe_uninstall",
      appId: id,
    });
    try {
      await deleteStripeApp({
        id: stripeApps?.stripeApps[0]?.id!,
        appId: id,
      });
      trackEvent({ eventName: "uninstalled_stripe", appId: id });
    } catch (error) {
      console.error("Failed to uninstall Stripe:", error);
    }
  };

  const handleRequestAccess = () => {
    window.Intercom(
      "showNewMessage",
      "Hi! I'd like to get access to the Stripe integration in June. Could you help me with that?",
    );
  };

  return (
    <Container isExpanded>
      <Header
        metadata={CONTENT}
        ActionComponent={() => (
          <>
            {isLoading ? (
              <Spinner />
            ) : isConnected ? (
              <>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<EllipsisVerticalIcon className="h-5 w-5" />}
                    colorScheme="gray"
                  />
                  <MenuList boxShadow="lg">
                    <MenuItem
                      icon={<TrashIcon className="h-4 w-4" />}
                      color="red.500"
                      fontWeight="medium"
                      onClick={onOpenConfirmation}
                    >
                      Uninstall
                    </MenuItem>
                  </MenuList>
                </Menu>

                <UninstallModal
                  isOpen={isConfirmationOpen}
                  onClose={onCloseConfirmation}
                  onUninstall={handleUninstall}
                  integration="stripe"
                  message="By doing so, you'll remove the Stripe integration and stop syncing Stripe data into June."
                />
              </>
            ) : hasStripeIntegration ? (
              <Button onClick={handleConnect} colorScheme="purple">
                {isTestMode ? "Connect Stripe (Test mode)" : "Connect Stripe"}
              </Button>
            ) : (
              <Button onClick={handleRequestAccess} colorScheme="purple">
                Request access
              </Button>
            )}
          </>
        )}
      />
      <Body>
        {isConnected ? (
          <Section>
            <Settings />
          </Section>
        ) : (
          <Section>
            <Features />
          </Section>
        )}
      </Body>
    </Container>
  );
};
