import { Language } from "prism-react-renderer";

import ApiIcon from "modules/Onboarding/assets/api.svg";
import BrowserIcon from "modules/Onboarding/assets/browser.svg";
import NodeIcon from "modules/Onboarding/assets/node.svg";
import PythonIcon from "modules/Onboarding/assets/python.svg";
import ReactIcon from "modules/Onboarding/assets/react.svg";
import RubyIcon from "modules/Onboarding/assets/ruby.svg";

export type SDKLanguage = Language | "html" | "ruby";

export interface ISDK {
  id: string;
  language: SDKLanguage;
  installLanguage?: SDKLanguage;
  icon?: any;
  name: string;
  installScript?: string;
  importScript?: string;
  useScript?: string;
  useHook?: string;
  identifyCallScript?: string;
  groupCallScript?: string;
  trackCallScript?: string;
  featureScript?: string;
}

export const SDKs: ISDK[] = [
  {
    id: "browser",
    language: "javascript",
    installLanguage: "html",
    icon: BrowserIcon,
    name: "Browser",
    installScript: `<script>
    window.analytics = {};
    function juneify(writeKey) {
        window.analytics._writeKey = writeKey;
        var script = document.createElement("script");
        script.type = "application/javascript";
        script.onload = function () {
            window.analytics.page();
        }
        script.src = "https://unpkg.com/@june-so/analytics-next/dist/umd/standalone.js";
        var first = document.getElementsByTagName('script')[0];
        first.parentNode.insertBefore(script, first);
    }
    juneify("YOUR_WRITE_KEY");
</script>`,
    identifyCallScript: `window.analytics.identify('USER_ID', {
  email: 'test@example.com',
  // Optional
  name: 'Joe Bloggs',
  avatar: 'https://avatar.com/asd809sdhoif9as10nc29.png'
  // Add anything else about the user here
});`,
    groupCallScript: `//In the following example, it's important to call the .identify call before the .group call, because the .group call will attach the current identified user.

  window.analytics.group('GROUP_ID', {
  name: 'Acme Inc',
  // Optional
  avatar: 'https://avatar.com/asd809sdhoif9as10nc29.png'
  // Add anything else about the company here
});`,
    trackCallScript: `//In the following example, it's important to call the .identify call before the .group call, because the .group call will attach the current identified user.
window.analytics.track('Signed In', {
  browser: 'chrome'
  }, {
  // Add the GROUP_ID here to track this event on behalf of a company
  context: { groupId: 'GROUP_ID' }
});`,
    featureScript: `<!-- Install tag -->
<script>
  window.analytics = {};
  function juneify(writeKey) {
      window.analytics._writeKey = writeKey;
      var script = document.createElement("script");
      script.type = "application/javascript";
      script.onload = function () { window.analytics.page() }
      script.src = "https://unpkg.com/@june-so/analytics-next/dist/umd/standalone.js";
      var first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
  }
  juneify("YOUR_WRITE_KEY");
</script>

<!-- Add identify call if you haven't already -->
window.analytics.identify('USER_ID', { email: 'test@example.com' });

<!-- Add track call -->
window.analytics.track('FEATURE_EVENT_NAME');`,
  },
  {
    id: "react",
    language: "javascript",
    installLanguage: "bash",
    icon: ReactIcon,
    name: "React",
    installScript: `npm install @june-so/analytics-next --save`,
    importScript: `// Import June SDK:

// Near the entrypoint of your app, instantiate AnalyticsBrowser:
let analytics = AnalyticsBrowser.load({
  writeKey: 'YOUR_WRITE_KEY',
});`,
    useScript: `// Implement a hook that can be used throughout your React app:


export function useJune(writeKey) {
  const [analytics, setAnalytics] = useState(undefined);

  useEffect(() => {
    const loadAnalytics = async () => {
      let response = AnalyticsBrowser.load({ 
        writeKey: 'YOUR_WRITE_KEY' 
      });
      setAnalytics(response);
    };
    loadAnalytics();
  }, []);

  return analytics;
}`,
    useHook: `// **If you want to use NextJS check our docs**

//Now you can use the hook in any component, like:


function PurchaseButton() {
  const analytics = useJune('YOUR_WRITE_KEY');

  const trackPurchase = useCallback(() => {
    if (analytics) analytics.track('Purchase Event');
  }, [analytics]);

  return <button onClick={trackPurchase}>Purchase coat</button>;
}`,
    identifyCallScript: `analytics.identify('USER_ID', {
  email: 'test@example.com',
  // Optional
  name: 'Joe Bloggs',
  avatar: 'https://avatar.com/asd809sdhoif9as10nc29.png'
  // Add anything else about the user here
});`,
    groupCallScript: `analytics.group('GROUP_ID',
{
  name: 'Acme Inc',
  // Optional
  avatar: 'https://avatar.com/asd809sdhoif9as10nc29.png'
  // Add anything else about the company here
});`,
    trackCallScript: `analytics.track('Signed In',
  { browser: 'chrome' },
  // Add the GROUP_ID here to track this event on behalf of the company
  { context: { groupId: 'YOUR_GROUP_ID' } 
});`,
    featureScript: `# Install
# npm install @june-so/analytics-next --save

# Import June SDK

# Near the entrypoint of your app, instantiate AnalyticsBrowser
let analytics = AnalyticsBrowser.load({ writeKey: 'YOUR_WRITE_KEY' });

# Add identify call on sign up / log in if you haven't already
analytics.identify('USER_ID', { email: 'test@example.com' });

# Track call
analytics.track('FEATURE_EVENT_NAME');`,
  },
  {
    id: "node",
    language: "javascript",
    installLanguage: "bash",
    icon: NodeIcon,
    name: "Node JS",
    installScript: `npm install @june-so/analytics-node --save`,
    importScript: `// Import June SDK:

// Instantiate the client:
const analytics = new Analytics('YOUR_WRITE_KEY');`,
    identifyCallScript: `analytics.identify({
    userId: 'USER_ID',
    traits: {
      email: 'test@example.com',
      // Optional
      name: 'Joe Bloggs',
      avatar: 'https://avatar.com/asd809sdhoif9as10nc29.png'
      // Add anything else about the user here
      }
  });`,
    groupCallScript: `analytics.group({
    userId: 'USER_ID',
    groupId: 'GROUP_ID',
    traits: {
      // Optional
      avatar: 'https://avatar.com/asd809sdhoif9as10nc29.png'
      // Add anything else about the company here
      },
  });`,
    trackCallScript: `analytics.track({
  userId: 'USER_ID',
  event: 'Signed In',
  properties: {
    browser: 'chrome'
  },
  // Add the GROUP_ID here to track this event on behalf of the company
  context: { groupId: 'YOUR_GROUP_ID' }
});`,
    featureScript: `# Install
# npm install @june-so/analytics-node --save

# Import June SDK

# Instantiate Analytics
const analytics = new Analytics('YOUR_WRITE_KEY');

# Add identify call on sign up / log in if you haven't already
analytics.identify({ userId: 'USER_ID', traits: { email: 'test@example.com' } });

# Track call
analytics.track({ userId: 'USER_ID', event: 'FEATURE_EVENT_NAME' })`,
  },
  {
    id: "ruby",
    language: "ruby",
    installLanguage: "bash",
    icon: RubyIcon,
    name: "Ruby SDK",
    installScript: `gem install june-analytics-ruby`,
    importScript: `require 'june/analytics'

Analytics = June::Analytics.new({
  write_key: 'YOUR_WRITE_KEY'
})`,
    useScript: `// If you're using Rails, you can create a config/initializers/analytics_ruby.rb file and add the following code:

require 'june/analytics'

Analytics = June::Analytics.new({
  write_key: 'YOUR_WRITE_KEY',
  on_error: proc { |_status, msg| print msg },
  stub: !Rails.env.production?
})
    `,
    identifyCallScript: `Analytics.identify(
    user_id: 'USER_ID',
    traits: { 
      email: "test@example.com",
      # Optional
      name: "Joe Bloggs",
      avatar: "https://avatar.com/d78979as8hlsa9088f.png"
      # Add anything else about the user here
    }
  )`,
    groupCallScript: `Analytics.group(
    user_id: 'USER_ID',
    group_id: 'GROUP_ID',
    traits: {
      name: "Acme Inc",
      # Optional
      avatar: "https://avatar.com/d78979as8hlsa9088f.png"
      # Add anything else about the company here
    }
  )`,
    trackCallScript: `Analytics.track(
  user_id: 'USER_ID',
  event: 'Signed In',
  properties: {
    browser: 'chrome'
  },
  # Add the GROUP_ID here to track this event on behalf of the company
  context: { 
    groupId: 'YOUR_GROUP_ID' 
  }
)`,
    featureScript: `# Install
# gem install june-analytics-ruby

# Instantiate
# If you're using Rails, you can create a config/initializers/analytics_ruby.rb file
require 'june/analytics'

Analytics = June::Analytics.new({ write_key: 'YOUR_WRITE_KEY' })

# Add identify call on sign up / log in if you haven't already
Analytics.identify(user_id: 'USER_ID', traits: { email: "test@example.com" })

# Track call
Analytics.track(user_id: 'USER_ID', event: 'FEATURE_EVENT_NAME')`,
  },
  {
    id: "python",
    language: "python",
    installLanguage: "bash",
    icon: PythonIcon,
    name: "Python SDK",
    installScript: `pip install june-analytics-python`,
    importScript: `from june import analytics

analytics.write_key = "YOUR_WRITE_KEY"`,
    identifyCallScript: `analytics.identify(
    user_id="USER_ID",
    traits={
      "email": "test@example.com",
      # Optional
      "name": "Joe Bloggs",
      "avatar": "https://avatar.com/0sd9fsd8y97a0sf99asd.png",
      # Add anything else about the user here
      },
  )`,
    groupCallScript: `analytics.group(
    user_id="USER_ID",
    group_id="GROUP_ID",
    traits={
      "name": "Acme Inc",
      # Optional
      "avatar": "https://avatar.com/0sd9fsd8y97a0sf99asd.png",
      # Add anything else about the company here
    },
  )`,
    trackCallScript: `analytics.track(
  user_id="USER_ID",
  event="Signed In",
  properties={"browser": "chrome"},
  # Add the GROUP_ID here to track this event on behalf of the company
  context={"groupId": "YOUR_GROUP_ID"},
)`,
    featureScript: `# Install
# pip install june-analytics-python

# Import
from june import analytics

analytics.write_key = "YOUR_WRITE_KEY"

# Add identify call on sign up / log in if you haven't already
analytics.identify(user_id="USER_ID", traits={ "email": "test@example.com" })

# Track call
analytics.track(user_id="USER_ID", event="FEATURE_EVENT_NAME")`,
  },
  {
    id: "api",
    language: "bash",
    icon: ApiIcon,
    name: "HTTP API",
    installScript: `//June API requires authentication to access the API. You can authenticate your request by providing your write key in the request header.

Authorization: "Basic YOUR_WRITE_KEY"

// check out or docs for examples (Go, PHP, Java, JS)`,
    identifyCallScript: `curl https://api.june.so/sdk/identify
-H "Content-Type: application/json"
-H "Authorization: Basic YOUR_WRITE_KEY"
-X POST
    
  {
    "userId": "USER_ID",
    "groupId": "GROUP_ID",
    "traits": {
      "email": "test@example.com",
      # Optional
      "name": "Joe Bloggs",
      "avatar": "https://avatar.com/0sd9fsd8y97a0sf99asd.png",
      # Add anything else about the user here
    },
    "timestamp": "2012-12-02T00:31:38.208Z"
  }
          `,
    groupCallScript: `curl https://api.june.so/sdk/group
-H "Content-Type: application/json"
-H "Authorization: Basic YOUR_WRITE_KEY"
-X POST
    
  {
    "userId": "USER_ID",
    "groupId": "GROUP_ID",
    "traits": {
      "name": "Acme Inc"
      },
    "timestamp": "2012-12-02T00:31:38.208Z"
  }
          `,
    trackCallScript: `curl https://api.june.so/sdk/track
-H "Content-Type: application/json"
-H "Authorization: Basic YOUR_WRITE_KEY"
-X POST
    
  {
    "userId": "USER_ID",
    "event": "Signed in",
    "properties": {
      "browser": "chrome",
      },
    "context": {
        "groupId": "GROUP_ID",
      },
    "timestamp": "2012-12-02T00:31:38.208Z"
  }
          `,
    featureScript: `# Add identify call on sign up / log in if you haven't already
curl https://api.june.so/sdk/identify
-H "Content-Type: application/json"
-H "Authorization: Basic YOUR_WRITE_KEY"
-X POST
{
  "userId": "USER_ID",
  "traits": {
    "email": "test@example.com",
  }
}

# Track call
curl https://api.june.so/sdk/track
-H "Content-Type: application/json"
-H "Authorization: Basic YOUR_WRITE_KEY"
-X POST
    
{
  "userId": "USER_ID",
  "event": "FEATURE_EVENT_NAME"
}`,
  },
];
