import { Issue } from "core/helpers/event-validation/issue";
import { isDefinedString, isId } from "core/helpers/event-validation/utils";

export const validateIdentify = (payload: any): Issue[] => {
  const issues: Issue[] = [];

  if (!isId(payload["userId"])) {
    issues.push({
      level: "error",
      message: "Missing user id",
    });
  }

  if (!isDefinedString(payload["timestamp"])) {
    issues.push({
      level: "error",
      message: "Missing timestamp",
    });
  }

  if (!isDefinedString(payload["traits"]?.["email"])) {
    issues.push({
      level: "warning",
      message: "Missing email",
    });
  }

  return issues;
};
