import { CloseIcon } from "@chakra-ui/icons";
import { Button, ComponentDefaultProps, Text, Tooltip } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";

import { ANY, FIRST } from "core/constants/events";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import useRequest from "core/hooks/useRequest";
import { WrapArrow } from "core/modules/reports/setup/Editor/WrapArrow";
import { IEvent } from "core/types/Event";
import { ITemplateConfigSetupSection } from "core/types/TemplateConfig";
import { cx } from "helpers/cx";

interface IPropertySelectProps extends ComponentDefaultProps {
  event: IEvent;
  section?: ITemplateConfigSetupSection;
  onSelectProperty: ({
    event,
    property,
  }: {
    event: IEvent;
    property: string;
  }) => void;
  setPropertyFilterEventId?: (propertyFilterEventId: string) => void;
}

export const PropertySelectMenu: React.FC<IPropertySelectProps> = ({
  event,
  section,
  onSelectProperty,
  setPropertyFilterEventId,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState("");

  const currentApp = useCurrentApp();
  const { data, isLoading } = useRequest({
    url: "/properties",
    params: { appId: currentApp.id, eventId: event.id },
    withCredentials: true,
  });

  const hasNoProperties = data?.length === 0;

  const properties = data
    ?.map(({ property }: { property: string }) => property)
    .filter(
      (property: string) =>
        property.toLowerCase().includes(searchValue.toLowerCase()) &&
        property !== "",
    );

  if (isLoading) return null;
  if (event.type === ANY || event.type === FIRST) return null;

  if (hasNoProperties) {
    return (
      <Tooltip label="This event has no properties" placement="bottom">
        <Button
          color="gray.500"
          background="gray.50"
          border="none"
          borderRightRadius="md"
          cursor="not-allowed"
          borderLeft="none"
          borderLeftRadius="none"
          {...props}
        >
          <AdjustmentsHorizontalIcon style={{ height: "15px" }} />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Popover>
      {({ close }) => (
        <>
          <PopoverButton
            className={cx(
              "h-full max-h-[33px] items-center bg-gray-50 text-sm font-medium hover:bg-gray-100",
              section?.eventOccurrences &&
                "bg-red-100 pl-2 text-red-600 hover:bg-red-200",
              !section?.eventOccurrences && "px-2",
            )}
          >
            <div className="flex h-full items-center gap-2">
              <div className="flex items-center">
                <WrapArrow direction="left" position="28" />
                {section?.eventOccurrences ? (
                  "Choose a property"
                ) : (
                  <AdjustmentsHorizontalIcon
                    style={{ height: "15px", zIndex: 0 }}
                  />
                )}
                {section?.eventOccurrences ? (
                  <Tooltip label="Remove property" hasArrow placement="top">
                    <div
                      className="h-full p-2 text-red-600 hover:bg-red-200"
                      onClick={() =>
                        setPropertyFilterEventId && setPropertyFilterEventId("")
                      }
                    >
                      <CloseIcon w={2} h={2} />
                    </div>
                  </Tooltip>
                ) : null}
                <WrapArrow position="28" />
              </div>
            </div>
          </PopoverButton>
          <PopoverPanel className="flex w-full flex-col">
            <Lifecycle onUnmount={() => setSearchValue("")} />
            <Command>
              <CommandInput
                placeholder="Search..."
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <CommandList>
                {properties && properties.length > 0 ? (
                  properties.map((property: string, index: number) => (
                    <CommandOption
                      key={index}
                      value={property}
                      onClick={() => {
                        onSelectProperty({ event, property });
                        close();
                      }}
                    >
                      {property}
                    </CommandOption>
                  ))
                ) : (
                  <Text fontSize="sm" color="gray.700" p={2}>
                    No property found
                  </Text>
                )}
              </CommandList>
            </Command>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};
