import { Flex } from "@chakra-ui/react";

import { IReport } from "core/types/Report";
import ReportActionsMenu from "modules/Reports/List/ReportActionsMenu";

interface IReportNameActionsCellProps {
  props: IReport;
  mutate: () => void;
}

const ReportActionsCell: React.FC<IReportNameActionsCellProps> = ({
  props: report,
  mutate,
}) => {
  return (
    <Flex>
      <ReportActionsMenu report={report} mutate={mutate} />
    </Flex>
  );
};

export default ReportActionsCell;
