import { PROPERTY } from "core/constants/filters";
import { IS } from "core/constants/traitFilterComponents";
import { uuid } from "core/helpers/uuid";

export const FIRST = -2;
export const ANY = -1;
export const PAGE = 0;
export const SCREEN = 1;
export const TRACK = 2;
export const IDENTIFY = 3;
export const GROUP = 4;

export const EVENT_TYPES = [PAGE, SCREEN, TRACK, IDENTIFY, GROUP];
export const EVENT_TYPE_NAMES = [
  "page",
  "screen",
  "track",
  "identify",
  "group",
];

export const FIRST_EVENT = {
  eventId: FIRST,
  eventName: "First event",
  id: FIRST,
  name: "First event",
  type: FIRST,
};

export const ANY_EVENT = {
  eventId: ANY,
  eventName: "Any event",
  id: ANY,
  name: "Any event",
  type: ANY,
};

export const PAGE_EVENT = {
  eventId: ANY,
  eventName: "Any event",
  id: ANY,
  name: "Any event",
  type: ANY,
};

export const PAGE_EVENT_DEFAULT_FILTER = {
  properties: [
    {
      body: {
        property: "url",
        comparisonType: IS.toString(),
        value: "https://example.com",
      },
      type: PROPERTY,
    },
  ],
};

export const createEvent = (eventType: number) => {
  switch (eventType) {
    case FIRST:
      return {
        uuid: uuid(),
        type: eventType,
        properties: [],
        id: FIRST,
        name: "First event",
      };
    case ANY:
      return {
        uuid: uuid(),
        type: eventType,
        properties: [],
        id: ANY,
        name: "Any event",
      };
    case TRACK:
      return { uuid: uuid(), type: eventType, adding: true, properties: [] };
    case PAGE:
      return {
        uuid: uuid(),
        type: eventType,
        ...PAGE_EVENT_DEFAULT_FILTER,
        id: uuid(),
      };
    default:
      return { uuid: uuid(), type: eventType, adding: true, properties: [] };
  }
};

export const SMART_EVENTS = [FIRST_EVENT, ANY_EVENT];
