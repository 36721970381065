import { SelectObject } from "@/modules/Settings/Integrations/Attio/SelectObject";
import { useCrmSyncSettings } from "core/hooks/useCrmSyncSettings";
import { Integration } from "core/models/dataMappings";
import { IAppObject } from "core/types/AppObject";
import { MappingData } from "modules/Settings/Crm/SyncSettings/MappingData";

export const AttioUserData: React.FC<{
  appObject: IAppObject;
}> = ({ appObject }) => {
  const {
    onUpdateCrmSyncSetting,
    crmSyncSetting: setting,
    isUpdatingCrmSyncSetting,
  } = useCrmSyncSettings({
    integration: Integration.Attio,
    objectType: appObject.objectType,
  });

  return (
    <>
      <SelectObject
        appObject={appObject}
        isLoading={isUpdatingCrmSyncSetting}
        onUpdateCrmSyncSetting={onUpdateCrmSyncSetting}
        setting={setting}
      />
      <MappingData appObject={appObject} integration={Integration.Attio} />
    </>
  );
};
