import { GridItem, Skeleton } from "@chakra-ui/react";

import { REPORT_CONFIGS } from "core/constants/report-configurations";
import { ViewLocation } from "core/hooks/useViews";
import { IViewInsight, useGetViewInsightQuery } from "core/models/viewInsights";
import { IView } from "core/models/views";
import { IQuery } from "core/types/Query";
import { AIViewInsightContainer } from "modules/ViewInsight/AIViewInsightContainer";
import { AudienceViewInsightContainer } from "modules/ViewInsight/AudienceViewInsightContainer";
import { GRID_TO_PX } from "modules/ViewInsight/Builder/LayoutContainer";
import { ViewInsightContainer } from "modules/ViewInsight/ViewInsightContainer";

export function getReportConfigByType(reportType: number) {
  return REPORT_CONFIGS.find((config) => config.reportType === reportType);
}

export interface IViewInsightContainer {
  location: ViewLocation;
  viewInsight: IViewInsight;
  view: IView;
  query?: IQuery;
  isAdding?: boolean;
  isAdded?: boolean;
  viewId?: number;
  isBlurred?: boolean;
  isAI?: boolean;
  onSetViewInsight?: (viewInsight: IViewInsight) => void;
  sharingSecretToken?: string;
  skipFetch?: boolean;
  isSlackInsight?: boolean;
  execute?: boolean;
  skip?: boolean;
}

export const ViewInsight: React.FC<IViewInsightContainer> = ({
  location,
  viewInsight,
  view,
  query,
  isAdding,
  isAdded,
  viewId,
  isBlurred,
  isAI,
  onSetViewInsight,
  sharingSecretToken,
  skipFetch,
  isSlackInsight,
}) => {
  const {
    data: latestViewInsight,
    isLoading,
    isFetching,
  } = useGetViewInsightQuery(
    {
      id: viewInsight.id,
      viewId: viewInsight.viewId,
      appId: viewInsight.appId,
      sharingSecretToken,
    },
    {
      skip: skipFetch,
    },
  );

  if (!latestViewInsight || isLoading || isFetching)
    return (
      <GridItem
        key={viewInsight?.gridWidth}
        rowSpan={viewInsight?.gridHeight}
        colSpan={viewInsight?.gridWidth}
        h={GRID_TO_PX[viewInsight?.gridHeight as number]}
        minW={GRID_TO_PX[viewInsight?.gridWidth as number]}
        minH={GRID_TO_PX[viewInsight?.gridHeight as number]}
      >
        <Skeleton borderRadius="lg" minH="full" minW="full" />
      </GridItem>
    );

  if (latestViewInsight.audience)
    return (
      <GridItem
        key={latestViewInsight?.gridWidth}
        rowSpan={latestViewInsight?.gridHeight}
        colSpan={latestViewInsight?.gridWidth}
        h={GRID_TO_PX[latestViewInsight?.gridHeight as number]}
        minW={GRID_TO_PX[latestViewInsight?.gridWidth as number]}
        minH={GRID_TO_PX[latestViewInsight?.gridHeight as number]}
      >
        <AudienceViewInsightContainer
          viewInsight={latestViewInsight}
          isLoading={isLoading}
          isFetching={isFetching}
          isSlackInsight={isSlackInsight}
        />
      </GridItem>
    );

  if (isAI)
    return (
      <GridItem
        key={latestViewInsight?.gridWidth}
        rowSpan={latestViewInsight?.gridHeight}
        colSpan={latestViewInsight?.gridWidth}
        h="315px"
        minW={GRID_TO_PX[latestViewInsight?.gridWidth as number]}
        minH="315px"
      >
        <AIViewInsightContainer
          location={location}
          viewInsight={latestViewInsight}
          isAdding={isAdding}
          isAdded={isAdded}
          viewId={viewId}
          view={view}
          isBlurred={isBlurred}
          isAI={isAI}
          isSlackInsight={isSlackInsight}
          query={query}
        />
      </GridItem>
    );

  return (
    <GridItem
      key={latestViewInsight?.updatedAt}
      rowSpan={latestViewInsight?.gridHeight}
      colSpan={latestViewInsight?.gridWidth}
      h="315px"
      minW={GRID_TO_PX[latestViewInsight?.gridWidth as number]}
      minH="315px"
    >
      <ViewInsightContainer
        key={latestViewInsight?.updatedAt}
        location={location}
        viewInsight={latestViewInsight}
        isAdding={isAdding}
        isAdded={isAdded}
        viewId={viewId}
        isBlurred={isBlurred}
        onSetViewInsight={onSetViewInsight}
        view={view}
        isSlackInsight={isSlackInsight}
      />
    </GridItem>
  );
};
