import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import { thousandsToK } from "core/helpers/thousands";

interface UniqueCountProps {
  props: {
    uniqueCount: number;
  };
}

export const UniqueCount: React.FC<UniqueCountProps> = ({ props }) => {
  return (
    <Flex justifyContent="flex-end">
      <Text fontSize="sm" color="gray.600">
        {thousandsToK(props.uniqueCount)}
      </Text>
    </Flex>
  );
};
