import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, Link, Spinner, Text } from "@chakra-ui/react";
import { capitalize } from "lodash";
import React from "react";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useImportTasks } from "core/hooks/useImportTasks";
import { IImportTask } from "core/types/ImportTask";

export const ImportTasks: React.FC<{ tasks: IImportTask[] }> = ({ tasks }) => {
  const { id: appId } = useCurrentApp();
  const { deleteImportTask } = useImportTasks({ appId });

  return (
    <Box data-testId="import-task-in-progress-container">
      {tasks.map((task: IImportTask, index: number) => {
        if (task.failed) {
          return (
            <Flex
              align="center"
              bg="gray.50"
              p={4}
              borderRadius="lg"
              gap={4}
              mt={2}
            >
              <Flex direction="column">
                <Flex align="center" gridGap={4}>
                  <Flex
                    p={2}
                    w={8}
                    h={8}
                    borderRadius="100%"
                    justify="center"
                    align="center"
                    bg="red.100"
                  >
                    <CloseIcon color="red" h={2.5} w={2.5} />
                  </Flex>
                  <Flex direction="column">
                    <Flex>
                      <Text fontSize="sm" fontWeight="medium">
                        Your {capitalize(task.sourceName)} import failed
                      </Text>
                    </Flex>
                    <Flex>
                      <Text fontSize="xs" color="gray.600">
                        Dates: {task.config.startDate} to {task.config.endDate}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>

                <Text ml={12} fontSize="sm" color="gray.600" mt={2}>
                  Something went wrong.{" "}
                  <Link
                    onClick={() => deleteImportTask({ appId, id: task.id })}
                    cursor="pointer"
                    colorScheme="purple"
                  >
                    Request a new import
                  </Link>{" "}
                  or reach out to our{" "}
                  <Link
                    onClick={() => window.Intercom("show")}
                    cursor="pointer"
                    className="openMessenger"
                    colorScheme="purple"
                  >
                    support.
                  </Link>
                </Text>
              </Flex>
            </Flex>
          );
        }

        if (task.finished) {
          return (
            <Flex
              align="center"
              bg="gray.50"
              p={4}
              borderRadius="lg"
              gap={4}
              mt={2}
            >
              <Flex
                p={2}
                w={8}
                h={8}
                borderRadius="100%"
                justify="center"
                align="center"
                bg="green.100"
              >
                <CheckIcon h={3} w={3} color="green.300" />
              </Flex>
              <Flex direction="column" gridGap={1}>
                <Flex>
                  <Text fontSize="sm" fontWeight="medium">
                    Your data has been successfully imported
                  </Text>
                </Flex>
                <Flex>
                  <Text fontSize="xs" color="gray.600">
                    Dates: {task.config.startDate} to {task.config.endDate}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          );
        }

        return (
          <Flex
            align="center"
            bg="gray.50"
            p={4}
            borderRadius="lg"
            gap={4}
            mt={2}
          >
            <Flex direction="column">
              <Flex align="center" gridGap={4}>
                <Flex
                  p={2.5}
                  borderRadius="100%"
                  justify="center"
                  align="center"
                  bg="yellow.200"
                >
                  <Spinner color="yellow.500" size="xs" />
                </Flex>
                <Flex direction="column">
                  <Flex>
                    <Text fontSize="sm" fontWeight="medium">
                      {capitalize(task.sourceName)} import in progress
                    </Text>
                  </Flex>
                  <Flex>
                    <Text fontSize="xs" color="gray.600">
                      Dates: {task.config.startDate} to {task.config.endDate}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Text ml={12} mt={2} fontSize="sm" color="gray.600">
                Once it's processed, you'll get an email confirmation. Depending
                on the size of your data, imports can take up to a few hours.
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </Box>
  );
};
