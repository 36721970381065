import { IBarItem } from "@src/core/types/BarChart";

export const FunnelTooltip: React.FC<{ item: IBarItem; index: number }> = ({
  item,
  index,
}) => {
  return (
    <div className="flex w-full min-w-[200px] flex-col gap-1 p-1">
      <div className="flex items-center justify-between">
        <p className="text-gray-500">{index !== 0 ? "Converted" : "Entered"}</p>
        <p className="font-mono text-xs font-medium">{item.conversion.count}</p>
      </div>
      {index !== 0 && (
        <div className="flex items-center justify-between">
          <p className="text-gray-500">Dropped off</p>
          <p className="font-mono text-xs font-medium">
            {item?.dropped?.count}
          </p>
        </div>
      )}
      <div className="flex items-center justify-between">
        <p className="text-gray-500">Conversion so far</p>
        <p className="font-mono text-xs font-medium">
          {item.conversion.percentage}%
        </p>
      </div>
      {index !== 0 && (
        <div className="flex items-center justify-between">
          <p className="text-gray-500">Conversion from previous</p>
          {item.conversion.percentageFromPreviousStep ? (
            <p className="font-mono text-xs font-medium">
              {item.conversion.percentageFromPreviousStep}%
            </p>
          ) : (
            "-"
          )}
        </div>
      )}
      {index !== 0 && (
        <div className="flex items-center justify-between">
          <p className="text-gray-500">Median time from previous</p>
          {item.medianTimeTaken ? (
            <p className="font-mono text-xs font-medium">
              {item.medianTimeTaken}
            </p>
          ) : (
            "-"
          )}
        </div>
      )}
      {index !== 0 && (
        <div className="flex items-center justify-between">
          <p className="text-gray-500">Average time from previous</p>
          {item.averageTimeTaken ? (
            <p className="font-mono text-xs font-medium">
              {item.averageTimeTaken}
            </p>
          ) : (
            "-"
          )}
        </div>
      )}
    </div>
  );
};
