import { CheckIcon, ExternalLinkIcon, LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ComponentDefaultProps,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback } from "react";

import { UnifiedPricing } from "core/components/Paywall/UnifiedPricing";
import { WorkspaceSwitcher } from "core/components/Paywall/WorkspaceSwitcher";
import { GENEROUS_MAU_LIMIT, UNIFIED_PRICING } from "core/constants/features";
import { useBilling } from "core/hooks/useBilling";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import useFlag from "core/hooks/useFlag";
import {
  FREE_THRESHOLD,
  GENEROUS_FREE_THRESHOLD,
  useMauThreshold,
} from "core/hooks/useMauThreshold";
import { usePaywall } from "core/hooks/usePaywall";
import { usePaywallModal } from "core/hooks/usePaywallModal";
import { useLazyFetchCheckoutQuery } from "core/models/payment";
import { Plan } from "core/types/App";

export const PaywallUpgradeHeader: React.FC<{}> = ({ ...props }) => {
  const app = useCurrentApp();
  if (app.noCardTrialEnded) {
    return (
      <Text fontWeight="bold" fontSize="3xl">
        Unlock higher MAUs and premium features
      </Text>
    );
  }
  return (
    <Text fontWeight="bold" fontSize="3xl">
      Try our plans for{" "}
      <Text as="span" color="purple.500">
        free
      </Text>{" "}
    </Text>
  );
};

export const PaywallUpgradeSubheader: React.FC<{}> = ({ ...props }) => {
  const { isTrialEnded } = useMauThreshold();

  if (isTrialEnded)
    return (
      <Text color="gray.700" fontSize="sm" fontWeight="normal" px={64}>
        Don't worry, you can get right back to where you left off.
        <br />
        Got questions?{" "}
        <Text
          cursor="pointer"
          as="span"
          color="purple.500"
          onClick={() => window.Intercom("show")}
        >
          Chat with us 👋
        </Text>
      </Text>
    );

  return (
    <Text color="gray.700" fontSize="sm" fontWeight="normal" px={56}>
      Track more monthly active users, access company-level analytics, as well
      as our premium features. Got questions?{" "}
      <Text
        cursor="pointer"
        as="span"
        color="purple.500"
        onClick={() => window.Intercom("show")}
      >
        Chat with us 👋
      </Text>
    </Text>
  );
};

export const DefaultCallToActionComponent: React.FC<{}> = ({ ...props }) => {
  return <></>;
};

const GROWTH_PLAN_FEATURES = [
  "Everything in the Free plan",
  "Company level analytics",
  "Custom insights with June AI",
  "Multiple home views",
  "2,000 active users per month included",
  "$15 for every additional 1,000",
  "AI powered email and domain enrichment",
  "1:1 product analytics coaching",
];

const PRO_PLAN_FEATURES = [
  "Everything in the Growth plan",
  "Computed traits",
  "Automatically sync data to HubSpot or Attio",
  "Priority support",
];

interface IPlan {
  plan: Plan;
  features: string[];
  price: number | string;
}

interface IUpgradeButton extends ComponentDefaultProps {
  tooltipText: string;
}

interface IModal extends ComponentDefaultProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export interface IPaywallModalChildren extends ComponentDefaultProps {
  Modal: React.FC<IModal>;
  UpgradeButton: React.FC<IUpgradeButton>;
}

export interface IPaywallModal extends ComponentDefaultProps {
  children: ({
    Modal,
    UpgradeButton,
  }: IPaywallModalChildren) => React.ReactElement;
  redirectUrl: string;
  isCloseable?: boolean;
  HeaderTextComponent?: React.FC;
  SubheaderTextComponent?: React.FC;
  CallToActionComponent?: React.FC;
  showOverlay?: boolean;
  showIcon?: boolean;
}

interface IPlanBreakdown extends IPlan {
  currentPlan: Plan;
  onRedirect: () => void;
  hasTrialed?: boolean;
}

function planCTA(currentPlan: Plan, plan: Plan, hasTrialed: boolean = false) {
  if (hasTrialed) {
    if (currentPlan === Plan.Pro) {
      if (plan === Plan.Free) return "Downgrade to Free";
      if (plan === Plan.Growth) return "Downgrade to Growth";
      if (plan === Plan.Pro) return "Continue on Pro";
    }
    if (currentPlan === Plan.Growth) {
      if (plan === Plan.Free) return "Downgrade to Free";
      if (plan === Plan.Growth) return "Continue on Growth";
      if (plan === Plan.Pro) return "Talk to sales";
    }
    if (currentPlan === Plan.Free) {
      if (plan === Plan.Free) return "Current plan";
      if (plan === Plan.Growth) return "Upgrade to Growth";
      if (plan === Plan.Pro) return "Talk to sales";
    }
  }

  if (currentPlan === plan) return "Current plan";

  if (currentPlan === Plan.Free) {
    if (plan === Plan.Growth) return "Start 7 day free trial";
    if (plan === Plan.Pro) return "Talk to sales";
  }
  if (currentPlan === Plan.Pro) {
    if (plan === Plan.Free) return "Downgrade to Free";
    if (plan === Plan.Growth) return "Downgrade to Growth";
  }
  if (currentPlan === Plan.Growth) {
    if (plan === Plan.Free) return "Downgrade to Free";
    if (plan === Plan.Pro) return "Talk to sales";
  }
}

function planIcon(plan: Plan) {
  if (plan === Plan.Free) return <></>;
  if (plan === Plan.Growth) return <ExternalLinkIcon />;
  if (plan === Plan.Pro) return <ExternalLinkIcon />;
}

const PlanBreakdown: React.FC<IPlanBreakdown> = ({
  plan,
  features,
  price,
  onRedirect,
  currentPlan,
  hasTrialed,
}) => {
  return (
    <Flex
      borderRadius="lg"
      mt={5}
      p={5}
      border="1px solid"
      borderColor={currentPlan === plan ? "purple.500" : "gray.200"}
      direction="column"
      justifyContent="space-between"
      minW="300px"
    >
      <Flex pb={10} direction="column" gridGap={1}>
        <Text fontSize="2xl" fontWeight="bold">
          <Text as="span" textTransform="capitalize">
            {plan.split(`_plan`).join("")}
          </Text>
        </Text>
        <Text fontSize="lg" fontWeight="semibold" mb={5}>
          {typeof price === "number" ? (
            <>
              ${price}{" "}
              <Text as="span" fontSize="sm" fontWeight="normal">
                / month
              </Text>
            </>
          ) : (
            <>{price}</>
          )}
        </Text>
        {features.map((feature, index) => (
          <Flex key={index} direction="row" align="center">
            <CheckIcon mr={2} h={3} color="green.500" />
            <Text fontSize="sm" color="gray.600">
              {feature}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Flex w="full">
        <Button
          py={5}
          size="md"
          w="full"
          colorScheme={plan === Plan.Pro ? "purple" : "gray"}
          onClick={onRedirect}
          rightIcon={planIcon(plan)}
          isDisabled={
            plan === Plan.Free
              ? currentPlan === Plan.Free // Disable the Free plan column if on free plan
              : currentPlan === plan && !hasTrialed // For Growth and Pro plan, only disable if they haven't trialed
          }
        >
          {planCTA(currentPlan, plan, hasTrialed)}
        </Button>
      </Flex>
    </Flex>
  );
};

export const PaywallModal: React.FC<IPaywallModal> = ({
  children,
  redirectUrl,
  HeaderTextComponent = PaywallUpgradeHeader,
  SubheaderTextComponent = PaywallUpgradeSubheader,
  CallToActionComponent = DefaultCallToActionComponent,
  isCloseable = false,
  showOverlay = true,
  showIcon = true,
}) => {
  const [fetchCheckout] = useLazyFetchCheckoutQuery();
  const {
    isOpen: _isOpen,
    onOpen: _onOpen,
    onClose: _onClose,
  } = useDisclosure();
  const currentApp = useCurrentApp();
  const { plan: currentPlan } = usePaywall();
  const { redirectToBillingPortal } = useBilling();
  const hasTrialed = currentApp.noCardTrialEnded;
  const hasGenerousFreeMauLimit =
    currentApp.features.includes(GENEROUS_MAU_LIMIT);
  const freePlanThreshold = hasGenerousFreeMauLimit
    ? GENEROUS_FREE_THRESHOLD
    : FREE_THRESHOLD;

  const { isModalClosable } = usePaywallModal();

  const FREE_PLAN_FEATURES = [
    "All templates",
    "Auto generated reports",
    "Unlimited seats",
    `${freePlanThreshold.toLocaleString()} active users per month included`,
    "User level analytics",
    "Insights and benchmarks",
    "Slack integration",
    "Saved audiences",
  ];

  const hasUnifiedPricing = useFlag(UNIFIED_PRICING);

  const PLANS = [
    {
      plan: "free_plan",
      features: FREE_PLAN_FEATURES,
      price: 0,
    },
    {
      plan: "growth",
      features: GROWTH_PLAN_FEATURES,
      price: 149,
    },
    {
      plan: "pro",
      features: PRO_PLAN_FEATURES,
      price: "Custom pricing",
    },
  ] as IPlan[];

  const redirectToCal = useCallback(async () => {
    window.open("https://cal.com/team/june/pro-plan", "_blank")?.focus();
  }, []);

  return children({
    Modal: ({ isOpen, onClose, ...props }: ComponentDefaultProps) => {
      return (
        <Modal
          isCentered
          onEsc={
            isModalClosable || isCloseable ? onClose || _onClose : () => {}
          }
          onOverlayClick={
            isModalClosable || isCloseable ? onClose || _onClose : () => {}
          }
          isOpen={isOpen || _isOpen}
          onClose={
            isModalClosable || isCloseable ? onClose || _onClose : () => {}
          }
          size="5xl"
          trapFocus={false}
          {...props}
        >
          {showOverlay && <ModalOverlay />}
          <ModalContent pb={0} className="overflow-hidden" {...props}>
            {isModalClosable || isCloseable ? (
              <ModalCloseButton className="short:mt-4 tall:text-white" />
            ) : (
              <>
                <WorkspaceSwitcher />
              </>
            )}
            <ModalHeader
              borderTopRadius="md"
              className="short:hidden"
              bg="linear-gradient(146deg, rgba(163,151,240,1) 32%, rgba(211,134,222,1) 100%)"
            >
              <Box
                className="short:hidden"
                borderRadius="lg"
                h="80px"
                w="100%"
                bgImage={`url('/paywall_3d_logo.png')`}
                bgRepeat="no-repeat"
                bgPosition="center"
                bgSize="contain"
              />
            </ModalHeader>
            <Box
              className="h-1.5 w-full tall:hidden"
              bg="linear-gradient(146deg, rgba(163,151,240,1) 32%, rgba(211,134,222,1) 100%)"
            />
            <ModalBody pb={5} bg="gray.50">
              {hasUnifiedPricing ? (
                <UnifiedPricing />
              ) : (
                <>
                  <Flex
                    className="mt-5 short:mt-2"
                    textAlign="center"
                    direction="column"
                    gridGap={2}
                  >
                    <HeaderTextComponent />
                    <SubheaderTextComponent />
                  </Flex>
                  <Box>
                    <CallToActionComponent />
                  </Box>
                  <Flex gridGap={5}>
                    {PLANS.map((plan) => (
                      <PlanBreakdown
                        hasTrialed={currentApp.noCardTrialEnded}
                        currentPlan={currentPlan}
                        onRedirect={() => {
                          if (plan.plan === Plan.Pro) {
                            window.analytics.track(
                              "talk_to_sales_clicked",
                              {
                                app_id: currentApp.id,
                              },
                              { context: { groupId: currentApp.id } },
                            );
                            redirectToCal();
                          } else if (plan.plan === Plan.Free && !hasTrialed)
                            redirectToBillingPortal();
                          else
                            fetchCheckout({
                              appId: currentApp.id,
                              redirectUrl,
                              plan: plan.plan,
                            }).then((data) => {
                              const sessionUrl = data?.data?.sessionUrl;
                              if (sessionUrl)
                                window.location.replace(sessionUrl);
                            });
                        }}
                        {...plan}
                      />
                    ))}
                  </Flex>
                </>
              )}

              <Text align="center" mt={5} fontSize="xs" color="gray.600">
                Checkout is handled by{" "}
                <Text as="span" color="gray.700" fontWeight="medium">
                  Stripe
                </Text>
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      );
    },

    UpgradeButton: ({ children, tooltipText, ...props }) => {
      function handleClick() {
        window.analytics.track(
          "paywall_upgrade_clicked",
          {
            appId: currentApp.id,
          },
          { context: { groupId: currentApp.id } },
        );

        if (_onOpen) _onOpen();
      }

      return (
        <Tooltip label={tooltipText} hasArrow placement="top">
          <Button
            onClick={handleClick}
            colorScheme="purple"
            rightIcon={showIcon ? <LockIcon /> : undefined}
            {...props}
          >
            {children}
          </Button>
        </Tooltip>
      );
    },
  });
};
