import { Spinner } from "@chakra-ui/react";
import { BoltIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";

import { FilterType } from "core/constants/report-setup";
import { useGetTraitsQuery } from "core/models/traits";
import { TraitLevel } from "core/modules/audience/Filters/TraitMenu";

interface SelectedTraitProps {
  trait: string;
  onRemove: () => void;
  level: TraitLevel;
}

export const SelectedTrait: React.FC<SelectedTraitProps> = ({
  trait,
  onRemove,
  level,
}) => {
  const { appId } = useParams();

  const filterType =
    level === TraitLevel.GROUP_LEVEL
      ? FilterType.GroupFilter
      : level === TraitLevel.COMPANY_LEVEL
        ? FilterType.CompanyFilter
        : FilterType.UserTrait;

  const { data: traits, isLoading } = useGetTraitsQuery({
    appId: Number(appId),
    filterType,
  });

  if (isLoading && !traits) {
    return <Spinner />;
  }

  const traitModel = traits?.find((t) => t.trait === trait);

  return (
    <div>
      <span className="inline-flex items-center gap-2 rounded-lg bg-gray-50 px-2 py-1 text-sm font-medium text-gray-700">
        {traitModel?.isComputed && (
          <BoltIcon className="h-4 w-4 text-purple-500" />
        )}
        {traitModel?.trait}
        <XMarkIcon
          className="h-4 w-4 cursor-pointer text-gray-500 hover:text-gray-700"
          onClick={onRemove}
        />
      </span>
    </div>
  );
};
