import { DigestInsightRow } from "@/modules/Settings/Digest/DigestInsightRow";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  IDigestInsight,
  useGetDigestInsightsQuery,
} from "core/models/digestInsights";

// import { useCurrentApp } from "core/hooks/useCurrentApp";

export const DigestInsights: React.FC = () => {
  const currentApp = useCurrentApp();
  const { data: digestInsights } = useGetDigestInsightsQuery({
    appId: currentApp.id,
  });

  return (
    <div className="flex flex-col">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  style={{ width: "50%" }}
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Insight
                </th>
                <th
                  scope="col"
                  style={{ width: "45%" }}
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Report
                </th>
                <th
                  scope="col"
                  style={{ width: "5%" }}
                  className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                >
                  Enabled
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {digestInsights?.map((digestInsight: IDigestInsight) => (
                <DigestInsightRow digestInsight={digestInsight} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
