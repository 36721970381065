import React from "react";

import { LineContainer } from "core/components/LineContainer";
import {
  getTimeRangeFromValue,
  INTERVAL_TYPES,
  MAU_OPTIONS,
  NINETY_DAYS,
} from "core/constants/timerange";
import { mauMockData } from "core/modules/reports/report-types/ActiveUsers/mockData";
import NewActiveUsersLineChart from "core/modules/reports/report-types/ActiveUsers/NewActiveUsersLineChart";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

export const MauContainer: React.FC<IReportInsight> = ({
  config,
  additionalConfigProps,
  showInProgress,
  viewInsight,
  insightConfig,
  headless,
  ...props
}) => {
  const insight =
    insightConfig ||
    (config.insights.find(
      ({ slug }: { slug: string }) => slug === "mau",
    ) as IInsightSection);

  const initialTimeRange = viewInsight?.timerangeValue
    ? getTimeRangeFromValue(viewInsight.timerangeValue)
    : NINETY_DAYS;

  return (
    <LineContainer
      LineChart={NewActiveUsersLineChart}
      insight={insight}
      initialTimeRange={initialTimeRange.value}
      timeRangeOptions={MAU_OPTIONS}
      intervalType={INTERVAL_TYPES.MONTH}
      keyAccessor={"data"}
      intervalPeriod={"THIRTY_DAYS"}
      mockData={mauMockData}
      config={config}
      additionalConfigProps={additionalConfigProps}
      showInProgress={showInProgress}
      {...props}
      headless={headless}
      report={props.report || viewInsight.report}
    />
  );
};
