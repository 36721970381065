import React from "react";

import { ClipboardButton } from "core/components/ClipboardButton";
import { ShareBox } from "core/components/ShareModal/private/ShareBox";

interface ICopyCode {
  sharingEnabled: boolean;
  title: string;
  description: string;
  code: string;
}

export const CopyCode = ({
  sharingEnabled,
  title,
  description,
  code,
}: ICopyCode) => {
  return (
    <ShareBox sharingEnabled={sharingEnabled}>
      <p className="text-sm font-medium text-gray-800">{title}</p>
      <p className="text-sm font-medium text-gray-500">{description}</p>
      <div className="relative mt-4 w-full rounded-md bg-gray-100 px-4 py-2">
        <div className="flex items-start gap-2">
          <pre className="flex-1 overflow-x-auto whitespace-pre-wrap break-all font-mono text-sm text-gray-700">
            {code}
          </pre>
          <div className="mr-[-15px]">
            <ClipboardButton
              bg="transparent"
              value={code}
              disabled={!sharingEnabled}
              showLabel={false}
            />
          </div>
        </div>
      </div>
    </ShareBox>
  );
};

export default CopyCode;
