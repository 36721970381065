import {
  BuildingOffice2Icon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Button } from "@june-so/ui";

import { AppObjectType, IAppObject } from "core/types/AppObject";

export const EmptyState: React.FC<{
  appObject: IAppObject;
  hasFilters: boolean;
  onClearFilters?: () => void;
}> = ({ appObject, hasFilters, onClearFilters }) => {
  return (
    <div className="flex min-h-[600px] min-w-full flex-col items-center justify-center gap-1">
      <div className="mb-2 rounded-full border border-gray-200 p-3">
        {appObject.objectType === AppObjectType.User && (
          <UserIcon className="h-6 w-6 text-purple-500" />
        )}
        {appObject.objectType === AppObjectType.Company && (
          <BuildingOffice2Icon className="h-6 w-6 text-purple-500" />
        )}
        {appObject.objectType === AppObjectType.Group && (
          <UsersIcon className="h-6 w-6 text-purple-500" />
        )}
      </div>
      <p className="font-medium">
        No {appObject.pluralName.toLowerCase()} found
      </p>
      {hasFilters && (
        <div className="flex flex-col items-center gap-2">
          <p className="text-sm text-gray-800">
            No {appObject.pluralName.toLowerCase()} found that match your
            filters
          </p>
          <Button onClick={onClearFilters} color="purple">
            Clear filters
          </Button>
        </div>
      )}
    </div>
  );
};
