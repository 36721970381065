import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { CodeBracketIcon } from "@heroicons/react/24/outline/index";
import { useState } from "react";

import { AppNavLink } from "Components/AppNavLink";
import { PageContainer } from "core/components/PageContainer";
import AmplitudeLogo from "core/design-system/components/Icon/Illustrations/AmplitudeLogo.svg";
import MixpanelLogo from "core/design-system/components/Icon/Illustrations/MixpanelLogo.svg";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useNavigation } from "core/hooks/useNavigation";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import {
  DataSource,
  IOnboardingChecklist,
} from "core/models/onboardingChecklist";
import { CompleteSetup } from "modules/GettingStarted/CompleteSetup";
import { CompleteSetupFooter } from "modules/GettingStarted/CompleteSetupFooter";
import {
  ConnectSource,
  DATA_SOURCE_OPTIONS,
  IOption,
} from "modules/GettingStarted/ConnectSource";
import { DemoCard } from "modules/GettingStarted/DemoCard";
import { Imports } from "modules/GettingStarted/Imports";
import { Resources } from "modules/GettingStarted/Resources";
import { ScheduleCall } from "modules/GettingStarted/ScheduleCall";
import { OnboardingCheck } from "modules/Onboarding/SetupSources/OnboardingCheck";
import { ISDK } from "modules/Onboarding/SetupSources/SDKs";
import DefaultHeader from "modules/Pages/DefaultHeader";

export interface IGettingStartedPanel {
  dataSource?: DataSource;
  sdk: ISDK;
  setSdk: (sdk: ISDK) => void;
  isExpanded: boolean;
}

const SECTION = {
  name: "Getting started",
  title: "Getting started",
  description: "Everything you need to set up June and become a data expert",
  sectionRight: () => (
    <AppNavLink to={"/settings/developers"}>
      <Button leftIcon={<CodeBracketIcon className="h-4" />}>
        Developer tools
      </Button>
    </AppNavLink>
  ),
};

const CONNECTED_DATA_SOURCE_KEY = "connectedDataSource";
const IS_IDENTIFYING_USERS_KEY = "isIdentifyingUsers";
const IS_SENDING_EVENTS_KEY = "isSendingEvents";
const IMPORT_REQUESTED_KEY = "importRequested";
const CALL_BOOKED_KEY = "callBooked";

type Section = {
  title: string;
  optional?: boolean;
  keys: string[];
  visibileFor?: "b2b";
  Panel: React.FC<IGettingStartedPanel>;
  Footer?: React.FC;
};

const GETTING_STARTED_SECTIONS: Section[] = [
  {
    keys: [CONNECTED_DATA_SOURCE_KEY],
    title: "Connect a data source",
    Panel: ConnectSource,
  },
  {
    keys: [IS_IDENTIFYING_USERS_KEY, IS_SENDING_EVENTS_KEY],
    title: "Complete setup",
    Panel: CompleteSetup,
    Footer: CompleteSetupFooter,
  },
  {
    keys: [IMPORT_REQUESTED_KEY],
    title: "Import historical data from Amplitude or Mixpanel",
    Panel: Imports,
    optional: true,
  },
  {
    keys: [CALL_BOOKED_KEY],
    title: "Schedule your onboarding call",
    Panel: ScheduleCall,
    visibileFor: "b2b",
  },
];

const GettingStarted: React.FC = () => {
  const { business } = useCurrentApp();
  const { onboardingChecklist, dataSource, sdk, setSdk } =
    useOnboardingChecklist();
  const [openPanel, setOpenPanel] = useState<number>(-1);
  const dataSourceLabel = DATA_SOURCE_OPTIONS.find(
    (o: IOption) => o.value === dataSource,
  )?.label as string | undefined;

  const isB2B = business === "b2b";
  const { navigation, toggleSidebar } = useNavigation();
  const navigationIsOpen = navigation.isOpen;

  if (navigationIsOpen) toggleSidebar();

  return (
    <>
      <PageContainer>
        <div className="flex justify-between">
          <DefaultHeader section={SECTION} />
        </div>

        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
          gap={6}
          mb={8}
        >
          <GridItem colSpan={2}>
            <Accordion index={openPanel} allowToggle>
              {GETTING_STARTED_SECTIONS.filter(
                ({ visibileFor }) => !(visibileFor === "b2b" && !isB2B),
              ).map(({ title, optional, keys, Panel, Footer }, index) => {
                const isOpen = openPanel === index;
                const isComplete = keys
                  .map(
                    (k) =>
                      onboardingChecklist &&
                      onboardingChecklist[k as keyof IOnboardingChecklist],
                  )
                  .every(Boolean);
                return (
                  <AccordionItem
                    key={index}
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="lg"
                    boxShadow="none"
                    mb={4}
                  >
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton
                          p={6}
                          _hover={{ backgroundColor: "none" }}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          onClick={() => {
                            isOpen ? setOpenPanel(-1) : setOpenPanel(index);
                          }}
                        >
                          <div className="flex items-center">
                            <OnboardingCheck isComplete={isComplete} />
                            <div className="flex items-center">
                              <p className="flex-shrink-0 text-left">{title}</p>
                              {keys.includes(IMPORT_REQUESTED_KEY) && (
                                <div className="ml-2 hidden space-x-1 xl:flex">
                                  <img alt="" src={AmplitudeLogo} />
                                  <img alt="" src={MixpanelLogo} />
                                </div>
                              )}
                              {optional && (
                                <div className="ml-4 rounded-md bg-gray-50 px-2 py-1">
                                  <p className="text-sm text-gray-600">
                                    optional
                                  </p>
                                </div>
                              )}
                              {keys.includes(CONNECTED_DATA_SOURCE_KEY) && (
                                <div className="ml-4 rounded-md bg-purple-50 px-2 py-1 text-purple-500">
                                  <p className="text-sm font-normal">
                                    {dataSourceLabel}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={0} px={6}>
                          <Panel
                            isExpanded={isExpanded}
                            dataSource={dataSource}
                            sdk={sdk}
                            setSdk={setSdk}
                          />
                        </AccordionPanel>
                        {Footer && isOpen && <Footer />}
                      </>
                    )}
                  </AccordionItem>
                );
              })}
            </Accordion>
          </GridItem>
          <GridItem colSpan={{ base: 2, xl: 1 }}>
            <Flex w="full" direction={"column"} gap={5}>
              <Resources dataSource={dataSource} />
              <DemoCard />
            </Flex>
          </GridItem>
        </Grid>
      </PageContainer>
    </>
  );
};

export default GettingStarted;
