import { QuestionIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import ContactAvatar from "core/components/Avatars/ContactAvatar";
import {
  getContactEmail,
  IGetContactEmailProps,
} from "core/helpers/contactEmail";
import { getContactName, IGetContactNameProps } from "core/helpers/contactName";
import { IContact } from "core/types/Contact";

interface IContactBodyProps {
  contact?: IContact;
  userId?: string;
  description?: string | React.ReactNode;
  truncateName?: boolean;
  previewMode?: boolean;
  maxW?: number;
}

export const ContactBody: React.FC<IContactBodyProps> = ({
  contact,
  userId = "",
  description = "",
  truncateName = false,
  previewMode = false,
  maxW = 200,
}) => {
  const { appId } = useParams<{ appId: string }>() as {
    appId: string;
  };

  if (previewMode) {
    return (
      <Box>
        <Flex justify="start" align="center">
          <Flex>
            <ContactAvatar
              previewMode={previewMode}
              size="sm"
              contact={{ id: userId, appId, traits: {} }}
            />
            <Flex direction="column" ml={3} mt={1}>
              <Text fontSize="sm">{userId}</Text>
              {description && (
                <Text color="gray.600" fontSize="xs">
                  {description}
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    );
  }

  const name = getContactName(contact as IGetContactNameProps);
  const email = getContactEmail(contact as IGetContactEmailProps);
  const hasEmail = email && email !== "Unknown";
  const hasName = name && name !== "Unknown";
  const id = contact?.id;
  const isIdentified = contact?.isIdentified;
  const isAnonymous = contact?.isAnonymous;

  return (
    <Box>
      <Flex justify="center" align="center" position="relative">
        <Flex align="center">
          <ContactAvatar size="xs" contact={contact} />
        </Flex>
        <Flex>
          <Flex
            position="absolute"
            left="20px"
            top="-5px"
            ml={1}
            align="center"
            gridGap={1}
            flexWrap="wrap"
          >
            {isAnonymous && (
              <Tooltip
                hasArrow
                placement="top"
                label={
                  <Flex p={2} direction="column">
                    <Text fontWeight="bold" mb={2}>
                      Anonymous user
                    </Text>
                    <Text>
                      This is an anonymous user with id{" "}
                      <Text
                        fontWeight="semibold"
                        fontStyle="italic"
                        borderRadius={"md"}
                        p={1}
                        as="span"
                        color="gray.400"
                      >
                        {id}
                      </Text>
                    </Text>
                  </Flex>
                }
              >
                <Flex align="center">
                  <QuestionIcon color="purple.400" mr={1} mb={0.5} w={3.5} />
                </Flex>
              </Tooltip>
            )}
            {!isIdentified && isIdentified !== undefined && !isAnonymous && (
              <Tooltip
                hasArrow
                placement="top"
                label={
                  <Flex p={2} direction="column">
                    <Text fontWeight="bold" mb={2}>
                      Missing identify call
                    </Text>
                    <Text>
                      We have received a track event which was performed by this
                      user, but the user is missing an identify call. Make sure
                      the user with id{" "}
                      <Text
                        fontWeight="semibold"
                        fontStyle="italic"
                        borderRadius={"md"}
                        p={1}
                        as="span"
                        color="gray.400"
                      >
                        {id}
                      </Text>{" "}
                      is sending an identify call.
                    </Text>
                  </Flex>
                }
              >
                <Flex align="center">
                  <WarningTwoIcon color="yellow.400" mr={1} mb={0.5} w={3.5} />
                </Flex>
              </Tooltip>
            )}
          </Flex>
        </Flex>
        <Flex>
          <Tooltip placement="bottom" label={name} isDisabled={!truncateName}>
            <Flex direction="column" ml={3}>
              <Flex align="center" gridGap={1}>
                <Text
                  color={
                    (!hasEmail || !hasName) && !isIdentified
                      ? "gray.600"
                      : "gray.900"
                  }
                  fontSize="sm"
                  noOfLines={1}
                  maxW={truncateName ? maxW : undefined}
                >
                  {hasEmail ? email : hasName ? name : id}
                </Text>
              </Flex>
              {description && (
                <Text color="gray.600" fontSize="xs">
                  {description}
                </Text>
              )}
            </Flex>
          </Tooltip>
        </Flex>
      </Flex>
    </Box>
  );
};
