import { Button, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { List } from "core/components/List";
import { IListColumn } from "core/components/List/List.types";
import { IEventRow } from "core/models/events";
import { EventProperty, useGetPropertiesQuery } from "core/models/properties";
import { PropertyDrawer } from "modules/Events/List/PropertyDrawer";

const PropertyName: React.FC<{ props: EventProperty }> = ({ props }) => {
  return <Text fontSize="sm">{props.property}</Text>;
};

const PropertyCount: React.FC<{ props: EventProperty }> = ({ props }) => {
  return <Text fontSize="sm">{props.count}</Text>;
};

const PropertyAction: React.FC<{
  additionalProps: {
    setProperty: (property: string) => void;
    setIsDrawerOpen: (isOpen: boolean) => void;
  };
  props: { property: string };
}> = ({ additionalProps, props: { property } }) => {
  return (
    <Button
      colorScheme="purple"
      variant="ghost"
      fontSize="sm"
      onClick={() => {
        additionalProps.setIsDrawerOpen(true);
        additionalProps.setProperty(property);
      }}
    >
      View values
    </Button>
  );
};

export const Properties: React.FC<{ event: IEventRow }> = ({ event }) => {
  const { appId, eventId } = useParams<{ appId: string; eventId: string }>();
  const [property, setProperty] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { data: properties, isFetching } = useGetPropertiesQuery({
    appId: Number(appId),
    eventId: Number(eventId),
  });

  const config = {
    resourceName: "properties",
    columns: [
      {
        title: "Properties",
        name: "properties",
        align: "left",
        Component: PropertyName,
      },
      {
        title: "Values",
        name: "count",
        align: "left",
        Component: PropertyCount,
      },
      {
        title: "",
        name: "action",
        align: "right",
        Component: PropertyAction,
        additionalProps: {
          setProperty,
          setIsDrawerOpen,
        },
      },
    ] as IListColumn[],
  };

  if (properties?.length === 0)
    return (
      <Text color="gray.600">No properties received for {event?.name}</Text>
    );

  return (
    <Flex w="full" justifyContent="space-between">
      <List
        isLoading={isFetching}
        rows={properties || []}
        config={config}
        mb={4}
        w="full"
      />
      {property && (
        <PropertyDrawer
          property={property}
          isDrawerOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}
    </Flex>
  );
};
