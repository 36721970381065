import { formatDistanceToNow } from "date-fns";
import { Clock3Icon } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@/Components/ui/button";
import { Spinner } from "@/Components/ui/spinner";
import { ViewLocation } from "@/core/hooks/useViews";
import { IViewInsight } from "@/core/models/viewInsights";
import { Breadcrumbs } from "modules/Dashboard/InsightBuilder/Nav/Breadcrumbs";

export const NavContainer: React.FC<{
  viewInsight: IViewInsight;
  isUpdatingViewInsight: boolean;
  onCreate: () => void;
  onSave: () => void;
  onUpdateReport: () => void;
}> = ({
  viewInsight,
  isUpdatingViewInsight,
  onCreate,
  onSave,
  onUpdateReport,
}) => {
  const navigate = useNavigate();
  const { appId, appObjectId, objectId } = useParams();
  return (
    <>
      <Breadcrumbs viewInsight={viewInsight} />
      <div className="flex flex-row items-center gap-4">
        <div className="flex flex-row items-center gap-2">
          {isUpdatingViewInsight ? (
            <Spinner size="extraSmall" />
          ) : (
            <Clock3Icon className="h-3 w-3 text-gray-600" />
          )}
          <p className="text-xs text-gray-600">
            {isUpdatingViewInsight
              ? `Saving...`
              : `Last updated ${formatDistanceToNow(new Date(viewInsight.updatedAt), { addSuffix: true })}`}
          </p>
        </div>
        {viewInsight.persisted && (
          <Button
            size="sm"
            className="py-1"
            onClick={async () => {
              await onUpdateReport();
              onCreate();
              onSave();
            }}
          >
            Done
          </Button>
        )}

        {!viewInsight.persisted && (
          <div className="flex flex-row items-center gap-2">
            <Button
              size="sm"
              variant="outline"
              onClick={async () => {
                if (viewInsight.view.location === ViewLocation.Home) {
                  navigate(`/a/${appId}/home/${viewInsight?.view.id}`);
                } else {
                  navigate(
                    `/a/${appId}/objects/${appObjectId}/object/${objectId}`,
                  );
                }
              }}
            >
              Cancel
            </Button>

            <Button
              size="sm"
              onClick={async () => {
                await onUpdateReport();
                await onCreate();
                onSave();
              }}
            >
              {viewInsight.view.location === ViewLocation.Home
                ? "Add to dashboard"
                : "Add to profile"}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
