import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  DataTypeString,
  DataTypeToString,
  ITraitKeyResponse,
  useGetTraitQuery,
  useUpdateTraitMutation,
} from "core/models/traits";
import { AppObjectType, IAppObject } from "core/types/AppObject";
import { ITraitOption } from "core/types/Trait";
import { TraitOption } from "routes/Settings/Objects/TraitOption";

export enum TraitLevel {
  USER_LEVEL = 0,
  GROUP_LEVEL = 1,
  COMPANY_LEVEL = 2,
}

export const OBJECT_TYPE_TO_TRAIT_LEVEL = {
  [AppObjectType.User]: TraitLevel.USER_LEVEL,
  [AppObjectType.Group]: TraitLevel.GROUP_LEVEL,
  [AppObjectType.Company]: TraitLevel.COMPANY_LEVEL,
};

export const TraitEditModal: React.FC<{
  trait: ITraitKeyResponse;
  isOpen: boolean;
  onClose: () => void;
  appObject: IAppObject;
}> = ({ trait, isOpen, onClose, appObject }) => {
  const { appId } = useParams();
  const [type, setType] = useState<DataTypeString | undefined>(undefined);
  const [prefix, setPrefix] = useState<string | undefined>(undefined);
  const [suffix, setSuffix] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<ITraitOption[]>([]);

  const level = OBJECT_TYPE_TO_TRAIT_LEVEL[appObject.objectType];

  const { data: traitData, isLoading } = useGetTraitQuery({
    appId: Number(appId),
    trait: trait.trait,
    level,
  });

  useEffect(() => {
    if (!traitData) return;

    setType(traitData.dataType);
    setPrefix(traitData.prefix);
    setSuffix(traitData.suffix);
    setOptions(traitData.traitOptions);
  }, [traitData]);

  const [updateTrait] = useUpdateTraitMutation();

  function onAddOption() {
    setOptions([
      ...options,
      { uuid: Math.random(), label: "", value: "", color: "purple" },
    ]);
  }

  function onUpdate() {
    if (!traitData) return;

    updateTrait({
      appId: Number(appId),
      id: traitData.id,
      dataType: type,
      prefix: prefix || "",
      suffix: suffix || "",
      traitOptions: options,
    });
  }

  return (
    <Modal size="lg" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Edit trait {` `} {trait.trait}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex flex-col gap-2">
            <p className="text-sm font-medium">Prefix</p>
            {isLoading ? (
              <Skeleton w="full" borderRadius="lg" h="35px" />
            ) : (
              <input
                type="text"
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-1 focus:-outline-offset-1 focus:outline-indigo-600 sm:text-sm/6"
              />
            )}
            <p className="text-sm font-medium">Suffix</p>
            {isLoading ? (
              <Skeleton w="full" borderRadius="lg" h="35px" />
            ) : (
              <input
                type="text"
                value={suffix}
                onChange={(e) => setSuffix(e.target.value)}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-1 focus:-outline-offset-1 focus:outline-indigo-600 sm:text-sm/6"
              />
            )}
            <p className="text-sm font-medium">Data type</p>
            {isLoading ? (
              <Skeleton w="full" borderRadius="lg" h="35px" />
            ) : (
              <Select
                isDisabled={trait?.isComputed}
                size="md"
                value={type?.toLowerCase().replace(" ", "_")}
                onChange={(e) => setType(e.target.value as DataTypeString)}
              >
                {Object.values(DataTypeToString).map((dataType) => (
                  <option value={dataType.toLowerCase().replace(" ", "_")}>
                    {dataType}
                  </option>
                ))}
              </Select>
            )}
            {type === DataTypeString.OPTIONS && (
              <div className="mt-2 flex flex-col gap-2">
                <p className="text-sm font-medium">Options</p>
                {options?.map((option) => {
                  return (
                    <TraitOption
                      key={option.id}
                      option={option}
                      onUpdateOptionColor={(option, color) =>
                        setOptions(
                          options.map((o) =>
                            o.uuid
                              ? o.uuid === option.uuid
                                ? { ...o, color }
                                : o
                              : o.id === option.id
                                ? { ...o, color }
                                : o,
                          ),
                        )
                      }
                      onUpdateOptionLabel={(option, label) =>
                        setOptions(
                          options.map((o) =>
                            o.uuid
                              ? o.uuid === option.uuid
                                ? { ...o, label }
                                : o
                              : o.id === option.id
                                ? { ...o, label }
                                : o,
                          ),
                        )
                      }
                    />
                  );
                })}
                <Button
                  className="flex"
                  variant="outline"
                  onClick={onAddOption}
                >
                  Add option
                </Button>
              </div>
            )}
          </div>
        </ModalBody>

        <ModalFooter>
          {traitData && (
            <Link
              target="_blank"
              to={`/a/${appId}/settings/objects/${appObject.id}/traits/${traitData.id}/debugger`}
            >
              <Button size="sm">Debugger</Button>
            </Link>
          )}
          <Button
            ml={2}
            colorScheme="purple"
            size="sm"
            onClick={() => {
              onUpdate();
              onClose();
            }}
          >
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
