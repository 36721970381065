import { Button, ComponentDefaultProps, Input } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

import { Command } from "core/design-system/components/Command";
import { usePropertyValues } from "core/hooks/usePropertyValues";
import { PropertyValuePicker } from "core/modules/reports/setup/Editor/PropertyValuePicker";
import { IEvent } from "core/types/Event";
import { IEventProperty } from "core/types/EventProperty";

interface PropertyValueProps extends ComponentDefaultProps {
  event: IEvent;
  prop: IEventProperty;
  onChangeTrackPropertyValue: (
    e: React.ChangeEvent<HTMLInputElement>,
    event: IEvent,
    prop: IEventProperty,
    selected: boolean,
  ) => void;
  appId: number;
  showPropertyValues: boolean;
  onClose: () => void;
}

export const PropertyValue = ({
  onChangeTrackPropertyValue,
  prop,
  event,
  appId,
  showPropertyValues,
  onClose,
  ...props
}: PropertyValueProps) => {
  const { data, isLoading, hasMore, loadMore, searchTerm, setSearchTerm } =
    usePropertyValues({
      appId,
      eventId: event.id,
      property: prop.body.property,
    });

  const showInput = !showPropertyValues;

  return (
    <div>
      {showInput ? (
        <Popover>
          {({ close }) => (
            <>
              <PopoverButton className="rounded px-1 py-0.5 font-semibold hover:bg-gray-200">
                {prop?.body?.value || "Select value"}
              </PopoverButton>
              <PopoverPanel className="flex w-full flex-col">
                <Command>
                  <Input
                    borderRadius="md"
                    focusBorderColor="purple.500"
                    size="sm"
                    value={prop?.body?.value}
                    onChange={(e) =>
                      onChangeTrackPropertyValue(e, event, prop, false)
                    }
                    {...props}
                  />
                  <Button
                    onClick={() => {
                      close();
                      onClose();
                    }}
                  >
                    Done
                  </Button>
                </Command>
              </PopoverPanel>
            </>
          )}
        </Popover>
      ) : (
        <PropertyValuePicker
          isLoading={isLoading}
          hasMore={hasMore}
          propertyValues={data}
          onChangeTrackPropertyValue={onChangeTrackPropertyValue}
          event={event}
          property={prop}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onLoadMore={loadMore}
        />
      )}
    </div>
  );
};
