import { Flex, Link, Switch, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { useDispatch } from "react-redux";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { updateApp } from "core/models/app";

export const GroupContextSetting: React.FC = () => {
  const currentApp = useCurrentApp();
  const dispatch = useDispatch();

  const toggleGroupContext = () => {
    const groupContextEnabled = !currentApp.groupContextEnabled;
    dispatch(updateApp({ appId: currentApp.id, groupContextEnabled }));
  };

  return (
    <div className="flex w-full flex-col gap-3">
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex flex-col gap-1">
          <p className="text-sm">Use group context</p>
          <p className="text-sm text-gray-500">
            If you're already passing the 'groupId' in your track calls, turn on
            this setting for more accurate company reports.{" "}
            <Link
              fontSize="sm"
              isExternal
              href="https://www.june.so/docs/quickstart/track-event"
            >
              Learn more
            </Link>
          </p>
        </div>

        <Tooltip
          closeDelay={800}
          isDisabled={currentApp.receivingGroupContext}
          shouldWrapChildren
          label={
            <Flex
              p={2}
              gap={1}
              borderRadius="lg"
              fontSize="sm"
              direction="column"
              justify="center"
            >
              <Text fontWeight="semibold">Why is this disabled?</Text>
              <Text>
                Looks like you're not tying events performed by a user to a
                specific company. Make sure to implement the group context.
              </Text>
            </Flex>
          }
        >
          <Switch
            isChecked={currentApp.groupContextEnabled}
            ml={1}
            size="md"
            colorScheme="purple"
            onChange={toggleGroupContext}
            disabled={!currentApp.receivingGroupContext}
          />
        </Tooltip>
      </div>
    </div>
  );
};
