import React, { createContext, useContext, ReactNode } from "react";
import { useSelector } from "react-redux";

import { selector as editingPinnedReportsSelector } from "core/models/pinnedReports/selectors";

const EditingViewContext = createContext<boolean>(false);

export const EditingViewProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isEditing } = useSelector(editingPinnedReportsSelector);

  return (
    <EditingViewContext.Provider value={isEditing}>
      {children}
    </EditingViewContext.Provider>
  );
};

export const useIsEditingView = () => useContext(EditingViewContext);
