import {
  Box,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";
import moment from "moment/moment";
import { useEffect, useMemo, useState } from "react";
import Confetti from "react-dom-confetti";

import BadgeCard from "core/modules/reports/Achievements/BadgeCard";
import BadgeProgression from "core/modules/reports/Achievements/BadgeProgression";
import {
  Goal,
  ProgressionState,
  Track,
} from "core/modules/reports/Achievements/types";
import { getTimestamps } from "core/modules/reports/Achievements/utils";

const CONFETTI_CONFIG = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

function getGoalHeading(
  goals: Goal[],
  isFinished: boolean,
  isFirstLoad: boolean,
  isCompanyLevel?: boolean,
): string {
  if (goals.length === 0) return "Achievement get!";
  if (isFinished) return "All goals achieved";
  return "Your most recent feature goals";
}

const MAX_TTL_OF_ACHIEVED_GOALS = 60;

export const GoalReachedModal: React.FC<{
  isFinished: boolean;
  isFirstLoad: boolean;
  goals: Goal[];
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
  unit: string;
  isCompanyLevel: boolean;
  state: ProgressionState;
}> = ({
  isFirstLoad,
  isFinished,
  goals,
  isOpen,
  onClose,
  isCompanyLevel,
  state,
}) => {
  const [blastConfetti, setBlastConfetti] = useState<boolean>(false);
  const { level: currentAdoptionLevel } = state[Track.ADOPTION];
  const { level: currentUsageLevel } = state[Track.USAGE];
  const adoptionGoals = goals
    .filter((goal: Goal) => goal.track === Track.ADOPTION)
    .filter((goal: Goal, index: number) => {
      const goalTimestamp = getTimestamps(state, goal.track)[index];
      return (
        moment().diff(moment(goalTimestamp, "days")) < MAX_TTL_OF_ACHIEVED_GOALS
      );
    });
  const usageGoals = goals
    .filter((goal: Goal) => goal.track === Track.USAGE)
    .filter((goal: Goal, index: number) => {
      const goalTimestamp = getTimestamps(state, goal.track)[index];
      return (
        moment().diff(moment(goalTimestamp, "days")) < MAX_TTL_OF_ACHIEVED_GOALS
      );
    });

  const latestAdoptonGoals =
    adoptionGoals && adoptionGoals.length > 0
      ? [adoptionGoals[adoptionGoals.length - 1]]
      : [];
  const latestUsageGoals =
    usageGoals && usageGoals.length > 0
      ? [usageGoals[usageGoals.length - 1]]
      : [];

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setBlastConfetti(true);
      }, 500);
    }
  }, [isOpen]);

  const goalHeading = useMemo(
    () => getGoalHeading(goals, isFinished, isFirstLoad, isCompanyLevel),
    [goals, isFinished, isFirstLoad, isCompanyLevel],
  );

  if (!latestAdoptonGoals.length && !latestUsageGoals.length) return null;

  return (
    <Modal isCentered size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent position="relative" rounded="lg">
        <ModalCloseButton />
        <ModalBody py={8}>
          <Center py={8} pb={4}>
            <Confetti active={blastConfetti} config={CONFETTI_CONFIG} />
            <Flex gridGap={2}>
              <Flex>
                <BadgeProgression
                  badgeProps={{ width: "60px" }}
                  track={Track.ADOPTION}
                  currentLevel={currentAdoptionLevel}
                  isCompanyLevel={isCompanyLevel}
                  isReversed
                  isSlanted
                  isStackFlipped
                />
              </Flex>
              <Flex direction="row-reverse">
                <BadgeProgression
                  badgeProps={{ width: "60px" }}
                  track={Track.USAGE}
                  currentLevel={currentUsageLevel}
                  isCompanyLevel={isCompanyLevel}
                  isStackFlipped
                  isSlanted
                />
              </Flex>
            </Flex>
          </Center>
          <Center textAlign="center">
            <Box>
              <Text mb={2} fontWeight={600} fontSize="lg">
                {goalHeading}
              </Text>
              <Text mb={6} color="gray.600">
                {isFinished
                  ? "Congratulations! This is an awesome achievement. You and your team should be proud."
                  : "We set some adoption goals for your feature. Great work so far!"}
              </Text>
            </Box>
          </Center>
          <Flex direction="column">
            {latestAdoptonGoals.map((goal: Goal, index: number) => (
              <BadgeCard
                key={goal.name}
                goal={goal}
                unit={unit}
                isCompanyLevel={isCompanyLevel}
                timestamp={getTimestamps(state, goal.track)[index]}
                borderTopRadius={index === 0 ? "md" : "none"}
                borderBottomRadius={
                  latestUsageGoals.length === 0 ? "md" : "none"
                }
              />
            ))}
            {latestUsageGoals.map((goal: Goal, index: number) => (
              <BadgeCard
                key={goal.name}
                goal={goal}
                unit={unit}
                isCompanyLevel={isCompanyLevel}
                timestamp={getTimestamps(state, goal.track)[index]}
                borderTopRadius={
                  latestAdoptonGoals.length === 0 ? "md" : "none"
                }
                borderBottomRadius={
                  index === usageGoals.length - 1 ? "md" : "none"
                }
              />
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GoalReachedModal;
