import {
  Box,
  Flex,
  StyleProps,
  Text,
  Tooltip,
  useDisclosure,
  UseRadioGroupReturn,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { Chart } from "core/components/ActivityLineChart/Chart";
import { ContactEventList } from "core/components/ActivityLineChart/ContactEventList";
import { FilterEvents } from "core/components/ActivityLineChart/FilterEvents";
import { GroupEventList } from "core/components/ActivityLineChart/GroupEventList";
import { RadioCard } from "core/components/ActivityLineChart/RadioCard";
import { LoadingChart } from "core/components/InsightCard/LoadingChart";
import { IntervalTypeNames } from "core/constants/timerange";
import { thousandsToK } from "core/helpers/thousands";
import { Unit } from "core/types/Unit";
import { IFilterEvents } from "modules/Contacts/Contact/ContactActivity";

interface ActivityLineChartProps extends StyleProps {
  id: string;
  getRadioProps: UseRadioGroupReturn["getRadioProps"];
  intervalType: IntervalTypeNames;
  totalEvents: number;
  data: { x: string; y: number }[];
  isLoading: boolean;
  filterEvents: IFilterEvents;
  setFilterEvents: (filterEvents: IFilterEvents) => void;
  unit?: Unit;
}

export const ActivityLineChart: React.FC<ActivityLineChartProps> = ({
  id,
  totalEvents,
  data,
  getRadioProps,
  intervalType,
  isLoading,
  filterEvents,
  setFilterEvents,
  unit = Unit.Users,
  ...styleProps
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [eventsCount, setEventsCount] = useState(0);
  const [humanizedDate, setHumanizedDate] = useState("");
  const [date, setDate] = useState("");

  const intervalTypeOptions = [
    IntervalTypeNames.DAY,
    IntervalTypeNames.WEEK,
    IntervalTypeNames.MONTH,
  ];

  let timePeriod;
  switch (intervalType) {
    case IntervalTypeNames.DAY:
      timePeriod = "last 30 days";
      break;
    case IntervalTypeNames.WEEK:
      timePeriod = "last 12 weeks";
      break;
    case IntervalTypeNames.MONTH:
      timePeriod = "last 1 year";
      break;
    default:
      timePeriod = "last 30 days";
  }

  const unitName = Unit.Companies ? `Company's` : `User's`;

  const onDataPointClick = (_: any, index: any) => {
    setEventsCount(index.payload.y);
    setHumanizedDate(index.payload.x);
    setDate(index.payload.date);
    onOpen();
  };

  return (
    <Flex
      direction="column"
      borderRadius="lg"
      bg="white"
      w="100%"
      padding={6}
      boxShadow="sm"
      border="1px solid"
      borderColor="gray.50"
      {...styleProps}
    >
      <Flex justify="space-between">
        <Text fontWeight={"medium"} mr={2}>
          Event activity
        </Text>
        <Flex mb={4} justify="flex-end">
          <Flex
            p={0.5}
            color="gray.600"
            background={"gray.50"}
            border="none"
            borderBottom={0}
            borderRadius="md"
          >
            {intervalTypeOptions.map((value) => {
              return (
                <RadioCard
                  key={value}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getRadioProps({ value })}
                >
                  {value}
                </RadioCard>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      <FilterEvents
        filterEvents={filterEvents}
        setFilterEvents={setFilterEvents}
      />
      {isLoading ? (
        <LoadingChart borderRadius="md" h="300" mt={4} />
      ) : (
        <Box mt={4}>
          <Tooltip
            label={`${unitName} total amount of events for the ${timePeriod}`}
            hasArrow
            placement="right"
          >
            <Text fontSize="4xl" width={"fit-content"}>
              {thousandsToK(totalEvents)}
            </Text>
          </Tooltip>
          <Chart data={data} onDataPointClick={onDataPointClick} />
        </Box>
      )}
      {unit === Unit.Users ? (
        <ContactEventList
          id={id}
          isOpen={isOpen}
          onClose={onClose}
          eventsCount={eventsCount}
          date={date}
          humanizedDate={humanizedDate}
          intervalType={intervalType}
          filterEvents={filterEvents}
        />
      ) : (
        <GroupEventList
          id={id}
          isOpen={isOpen}
          onClose={onClose}
          eventsCount={eventsCount}
          date={date}
          humanizedDate={humanizedDate}
          intervalType={intervalType}
          filterEvents={filterEvents}
        />
      )}
    </Flex>
  );
};
