import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { startCase } from "lodash";
import { Dice1Icon, Dice3Icon, Dice2Icon } from "lucide-react";

import { Button } from "@/Components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/Components/ui/dropdown-menu";
import { Size } from "@/core/types/ViewInsight";

const SizeToIcon = {
  [Size.Small]: <Dice1Icon className="h-4 w-4" />,
  [Size.Medium]: <Dice2Icon className="h-4 w-4" />,
  [Size.Large]: <Dice3Icon className="h-4 w-4" />,
};

export const InsightSizeSelector: React.FC<{
  size: Size;
  onSelect: (size: Size) => void;
}> = ({ size, onSelect }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex items-center gap-1">
          <Button variant="secondary" size="sm" className="w-full">
            <div className="flex w-full flex-row items-center justify-between">
              <div className="flex items-center gap-1">
                <span className="text-gray-600">{SizeToIcon[size]}</span>
                <span className="text-sm font-medium">{startCase(size)}</span>
              </div>
              <ChevronDownIcon className="h-2 w-2" />
            </div>
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {Object.values(Size).map((size) => (
          <DropdownMenuItem
            key={size}
            onClick={() => onSelect(size as Size)}
            className="flex cursor-pointer items-center gap-2"
          >
            <div className="flex items-center gap-1">
              <span className="text-gray-600">{SizeToIcon[size]}</span>
              <span className="text-sm font-medium">{startCase(size)}</span>
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
