import { extendTheme } from "@chakra-ui/react";

import components from "core/design-system/components";
import colors from "core/design-system/constants/theme/colors";
import sizes, { baseSizes } from "core/design-system/constants/theme/sizes";
import typography from "core/design-system/constants/theme/typography";

const space = baseSizes;

const config = {
  initialColorMode: "light",
  cssVarPrefix: "june",
};

const shadows = {
  sm: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
  outline: "0 0 0 3px rgba(66, 153, 225, 0.6)",
  inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
  none: "none",
  base: "0px 2px 1px rgba(13, 19, 27, 0.05), 0px 0px 1px rgba(13, 19, 27, 0.25)",
  subtle: "0px 1px 0px rgba(13, 19, 27, 0.05)",
};

const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
};

const zIndices = {
  hide: -1,
  auto: "auto",
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
};

export const radii = {
  none: "0",
  sm: "0.125rem",
  rounded: "0.25rem",
  md: "0.375rem",
  lg: "0.5rem",
  full: "9999px",
};

const opacity = {
  0: "0",
  "20%": "0.2",
  "40%": "0.4",
  "60%": "0.6",
  "80%": "0.8",
  "100%": "1",
};

const borders = {
  none: 0,
  "1px": "1px solid",
  "2px": "2px solid",
  "4px": "4px solid",
};

const buttons = {
  primary: {
    color: "white",
    bg: "primary",
  },
  secondary: {
    color: "white",
    bg: "secondary",
  },
};

const overrides = {
  ...typography,
  config,
  breakpoints,
  zIndices,
  radii,
  opacity,
  borders,
  colors,
  sizes,
  shadows,
  space,
  buttons,
  components,
};

const juneTheme = extendTheme(overrides);

export default juneTheme;
