import {
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

import { Size } from "core/types/ViewInsight";
import { formatValue } from "lib/format";

const MAX_ROW_COUNT = 6;

const COLUMN_COLUMN_PER_SIZE = {
  [Size.Small]: 2,
  [Size.Medium]: 3,
  [Size.Large]: 5,
};

const MOCK_COLUMNS = [
  { name: "name", type: "String" },
  { name: "count", type: "UInt64" },
];

const MOCK_DATA = [
  {
    name: "landing",
    count: 100,
  },

  {
    name: "checkout",
    count: 100,
  },

  {
    name: "signup",
    count: 100,
  },
  {
    name: "home",
    count: 100,
  },
  {
    name: "login",
    count: 100,
  },
];

export const SimpleTable: React.FC<{
  columns: { name: string; type: string; label?: string }[];
  data: { [key: string]: string | number | object }[];
  size?: Size;
  useMockData?: boolean;
}> = ({ columns, data, size = Size.Small, useMockData = false }) => {
  const tableColumns = useMockData ? MOCK_COLUMNS : columns;
  const tableData = useMockData ? MOCK_DATA : data;

  if (
    (tableColumns.length === 1 && tableColumns[0].type.includes("Int")) ||
    tableColumns[0].type.includes("Float")
  ) {
    return (
      <Center h="full" w="full" ml={3} mb={4}>
        <Flex align="center" direction="column">
          <Text fontWeight="medium" noOfLines={1} fontSize="5xl" id="loaded">
            {Number(Object.values(tableData[0])[0]).toLocaleString()}
          </Text>
        </Flex>
      </Center>
    );
  }
  return (
    <TableContainer h="full" w="full">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            {tableColumns
              ?.slice(0, COLUMN_COLUMN_PER_SIZE[size])
              .map((column, index) => {
                return (
                  <Th
                    pl={0}
                    key={index}
                    maxW={`${315 / tableColumns.slice(0, COLUMN_COLUMN_PER_SIZE[size]).length}px`}
                  >
                    <p className="text-lowercase max-w-full truncate text-xs font-medium capitalize text-black">
                      {(
                        column.label || column.name.split(`_`).join(" ")
                      ).toLowerCase()}
                    </p>
                  </Th>
                );
              })}
          </Tr>
        </Thead>
        <Tbody id="loaded">
          {tableData?.slice(0, MAX_ROW_COUNT).map((row, rowIndex) => {
            return (
              <Tr key={rowIndex}>
                {tableColumns
                  .slice(0, COLUMN_COLUMN_PER_SIZE[size])
                  .map((column, colIndex) => {
                    const cellValue = row[column.name];
                    return (
                      <Td
                        maxW={`${315 / tableColumns.slice(0, COLUMN_COLUMN_PER_SIZE[size]).length}px`}
                        pl={0}
                        key={colIndex}
                      >
                        <Text noOfLines={1} fontSize="xs">
                          {typeof cellValue === "object"
                            ? JSON.stringify(cellValue)
                            : formatValue(cellValue as string)}
                        </Text>
                      </Td>
                    );
                  })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
