import React from "react";

import { LineContainer } from "core/components/LineContainer";
import {
  INTERVAL_TYPES,
  WAU_OPTIONS,
  NINETY_DAYS,
  getTimeRangeFromValue,
} from "core/constants/timerange";
import { wauMockData } from "core/modules/reports/report-types/ActiveUsers/mockData";
import NewActiveUsersLineChart from "core/modules/reports/report-types/ActiveUsers/NewActiveUsersLineChart";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

export const WauContainer: React.FC<IReportInsight> = ({
  config,
  additionalConfigProps,
  showInProgress,
  viewInsight,
  insightConfig,
  headless,
  ...props
}) => {
  const insight =
    insightConfig ||
    (config.insights.find(
      ({ slug }: { slug: string }) => slug === "wau",
    ) as IInsightSection);

  const initialTimeRange = viewInsight?.timerangeValue
    ? getTimeRangeFromValue(viewInsight.timerangeValue)
    : NINETY_DAYS;

  return (
    <LineContainer
      LineChart={NewActiveUsersLineChart}
      insight={insight}
      initialTimeRange={initialTimeRange.value}
      timeRangeOptions={WAU_OPTIONS}
      intervalType={INTERVAL_TYPES.WEEK}
      keyAccessor={"data"}
      intervalPeriod={"SEVEN_DAYS"}
      mockData={wauMockData}
      config={config}
      additionalConfigProps={additionalConfigProps}
      showInProgress={showInProgress}
      {...props}
      headless={headless}
      report={props.report || viewInsight.report}
    />
  );
};
