import { Grid, GridItem } from "@chakra-ui/react";

import { Error } from "core/components/Error";
import InsightCard from "core/components/InsightCard/Index";
import { HeaderInsightStat } from "core/design-system/components/HeaderInsightStat";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { featureUsageHeaderMockData } from "core/modules/reports/report-types/FeatureRelease/mockData";
import { isUserReportLevel } from "core/modules/reports/utils";
import { IReportInsight } from "core/types/Report";

type IFeatureUsageInsightsData = {
  count: number;
  change: number;
}[];

export const FeatureUsageInsights: React.FC<IReportInsight> = ({
  report,
  config,
  reportLevel,
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken,
}) => {
  const isUserReport = isUserReportLevel(reportLevel);
  const insightSlug = isUserReport ? "latest-usage" : "latest-company-usage";
  const insight = config.insights.find(({ slug }) => slug === insightSlug);
  const isSetupValid = config.validateSetup(report.config);
  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      sharingSecretToken,
      report,
      insightType: insight?.typeId,
      previewMode,
      skip: !isSetupValid,
    });

  const data = (
    previewMode ? featureUsageHeaderMockData : response?.data
  ) as IFeatureUsageInsightsData;

  if (!insight) return null;

  if (error) {
    return (
      <InsightCard>
        {(Card) => (
          <Card.Container id="adoption-error">
            <Error
              position="relative"
              w="100%"
              h="200px"
              background="white"
              p={16}
              borderRadius="lg"
            />
          </Card.Container>
        )}
      </InsightCard>
    );
  }

  const usageIntervals = [
    { interval: "day", currentPeriod: "today", comparisonPeriod: "yesterday" },
    {
      interval: "week",
      currentPeriod: "this week",
      comparisonPeriod: "last week",
    },
    {
      interval: "month",
      currentPeriod: "this month",
      comparisonPeriod: "last month",
    },
  ];

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container id={insight.slug} insight={insight}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          />
          <Card.Body
            isLoading={!data || isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            <Grid templateColumns="repeat(3, 1fr)" id="loaded">
              {data?.map((changes, index: number) => (
                <GridItem>
                  <HeaderInsightStat
                    key={index}
                    label={`Times used ${usageIntervals[index].currentPeriod}`}
                    headingTooltip={`Number of times the feature was used ${usageIntervals[index].currentPeriod}`}
                    mainCount={changes.count}
                    isLoading={isLoading || isFetching}
                    percentChange={changes.change}
                    changeText={`vs ${usageIntervals[index].comparisonPeriod}`}
                    firstStat={index === 0}
                    lastStat={index === usageIntervals.length - 1}
                    data-testid={`insight-stat-body-${usageIntervals[index].interval}`}
                  />
                </GridItem>
              ))}
            </Grid>
          </Card.Body>
        </Card.Container>
      )}
    </InsightCard>
  );
};
