import { Box, Flex, Stack, StyleProps, Text } from "@chakra-ui/react";
import { orderBy } from "lodash";
import moment from "moment";

import { TRAIT_COMPARISON_TYPES } from "core/constants/traitFilterComponents";
import {
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { Line, LineChart } from "core/design-system/charts/LineChart";
import TickText from "core/design-system/charts/TickText";
import colors from "core/design-system/constants/theme/colors";
import { classNames } from "core/helpers/classNames";
import { IDebuggerChart } from "core/models/debugger";
import { ICustomTooltipProps as ITooltipProps } from "core/types/CustomTooltip";
import { IEventProperty } from "core/types/EventProperty";

const LINE_COLORS = [colors.green, colors.red, colors.blue, colors.purple];

type ICustomTooltipProps = ITooltipProps & StyleProps;

const CustomTooltip: React.FC<ICustomTooltipProps> = ({
  label,
  payload,
  active,
  ...props
}) => {
  if (active && payload && payload.length) {
    return (
      <Box bg="gray.700" px={3} py={2} borderRadius="lg" {...props}>
        <Text fontSize="xs" color="gray.400" mb={2}>
          {moment(label).format(`Do MMM, 'YY`)}
        </Text>
        {orderBy(payload, "payload.y", "desc")?.map((p, idx) => (
          <Stack direction="row" spacing="2" align="center" key={idx}>
            <Box h="2" w="2" bg={p.color} borderRadius="lg"></Box>
            <Text key={idx} fontSize="sm" color="white">
              {p.payload.count}{" "}
              <Text as="span" fontSize="xs">
                {p.unit}
              </Text>
            </Text>
            <Text color="gray.400" fontSize="xs"></Text>
            {p.payload.eventProperties?.map(
              (eventProperty: IEventProperty, idx: number) => (
                <Text
                  as="span"
                  color="gray.600"
                  fontWeight="medium"
                  fontSize="xs"
                  key={idx}
                >
                  {eventProperty.body.property}{" "}
                  <Text as="u" fontSize="xs">
                    {
                      TRAIT_COMPARISON_TYPES[
                        Number(eventProperty.body.comparisonType)
                      ]?.name
                    }
                  </Text>{" "}
                  {eventProperty.body.value}
                </Text>
              ),
            )}
          </Stack>
        ))}
      </Box>
    );
  }
  return null;
};

export const Chart: React.FC<{
  chartData: IDebuggerChart;
  days: number;
  setDays: (d: number) => void;
}> = ({ chartData, days, setDays }) => {
  return (
    <Flex direction="column">
      <div className="mb-2 flex flex-row items-center justify-between border-b pb-4">
        <p className="text-lg font-semibold">Data over time</p>
        <div className="shadow-sm">
          <button
            className={classNames(
              "relative inline-flex items-center rounded-l-md bg-white px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:ring-1",
              days === 7 && "text-purple-500",
              days === 7 && "font-semibold",
            )}
            onClick={() => setDays(7)}
          >
            7D
          </button>
          <button
            className={classNames(
              "relative -ml-px inline-flex items-center bg-white px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:ring-1 focus-visible:ring-1",
              days === 14 && "text-purple-500",
              days === 14 && "font-semibold",
            )}
            onClick={() => setDays(14)}
          >
            14D
          </button>
          <button
            className={classNames(
              "relative -ml-px inline-flex items-center bg-white px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-1",
              days === 30 && "text-purple-500",
              days === 30 && "font-semibold",
            )}
            onClick={() => setDays(30)}
          >
            30D
          </button>
          <button
            className={classNames(
              "relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:ring-1 focus-visible:ring-1",
              days === 60 && "text-purple-500",
              days === 60 && "font-semibold",
            )}
            onClick={() => setDays(60)}
          >
            60D
          </button>
        </div>
      </div>

      <div className="h-[180px] w-full">
        <LineChart
          h="sm"
          w="100%"
          margin={{ top: 30, left: -27, right: 0, bottom: 10 }}
        >
          <CommonGrid />
          {/* @ts-ignore */}
          <CommonTooltip content={<CustomTooltip />} />
          {/* @ts-ignore */}
          <CommonXAxis
            type="category"
            allowDuplicatedCategory={false}
            dataKey="date" // @ts-ignore
            tick={({ x, y, ...props }) => (
              // @ts-ignore
              <TickText x={x} y={y + 25} {...props}>
                {moment(props.payload.value).format(`Do MMM, 'YY`)}
              </TickText>
            )}
          />
          {/* @ts-ignore */}
          <CommonYAxis />
          {Object.keys(chartData?.result).map((type: string, i: number) => (
            <Line
              key={i}
              dot={false}
              dataKey="count"
              data={chartData?.result?.[type]}
              strokeWidth={1.5}
              stroke={LINE_COLORS[i][600]}
              unit={type}
              type="linear"
            />
          ))}
        </LineChart>
      </div>
    </Flex>
  );
};
