import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { CohortTable } from "core/components/CohortTable";
import {
  ICohortContainer,
  ICohortData,
  ValueType,
} from "core/components/CohortTable/CohortTable.types";
import InsightCard from "core/components/InsightCard/Index";
import FullScreenIcon from "core/design-system/components/Icon/Interface/FullScreenIcon";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { useReportSetup } from "core/hooks/useReportSetup";
import { CohortUnitSwitcher } from "core/modules/reports/report-types/Retention/CohortUnitSwitcher";
import mockData from "core/modules/reports/report-types/Retention/MockData/MockCohortData";

export const CohortContainer = ({
  sharingSecretToken = null,
  config,
  report,
  previewMode,
  sharingMode,
  screenshotMode,
}: ICohortContainer) => {
  const {
    isOpen: isFullScreenViewOpen,
    onOpen: onOpenFullscreenView,
    onClose: onCloseFullscreenView,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [valueType, setValueType] = useState<ValueType>("percentage");

  const { serializeKey, invalidateInsights } = useReportSetup();

  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "acquisition-cohort",
  );
  const isSetupValid = config.validateSetup(report.config);
  const {
    response,
    isLoading: insightLoading,
    isFetching: insightFetching,
    error,
    refetch: insightRefetch,
    onRefreshInsight,
  } = useInsightDataFetching({
    insight,
    report,
    sharingSecretToken,
    previewMode,
    skip: !isSetupValid,
  });

  const data = (previewMode ? mockData : response?.data) as ICohortData;

  const refetch = () => {
    insightRefetch();
  };

  useEffect(() => {
    setIsLoading(insightLoading || insightFetching);
  }, [insightLoading, insightFetching]);

  useEffect(() => {
    if (!previewMode && isSetupValid) {
      invalidateInsights();
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializeKey]);

  const noData = data && data?.cohortData && data?.cohortData?.length < 2;

  return (
    <>
      <InsightCard>
        {(Card: any) => (
          <Card.Container insight={insight} id={insight.slug}>
            <Card.Header
              hasCaching={response?.hasCaching}
              screenshotMode={screenshotMode}
              showReportTitle={sharingMode}
              insight={insight}
              showActions={!sharingMode && !previewMode}
              sharingMode={sharingMode}
              config={config}
              report={report}
              refresh={onRefreshInsight}
              cachedAt={response?.cachedAt}
            >
              {!previewMode && (
                <>
                  <CohortUnitSwitcher
                    onChange={(value: ValueType) =>
                      setValueType(value as ValueType)
                    }
                    value={valueType}
                  />

                  <Button
                    onClick={onOpenFullscreenView}
                    variant={"ghost"}
                    ml={2}
                  >
                    <FullScreenIcon color="gray.600" h={4} w={4} />
                  </Button>
                </>
              )}
            </Card.Header>
            <Card.Body
              isLoading={!data || insightLoading || insightFetching}
              isPreviewMode={previewMode}
              isSetupValid={isSetupValid}
              error={error}
            >
              {noData ? (
                <Card.EmptyState />
              ) : (
                <CohortTable
                  report={report}
                  key={serializeKey}
                  unit={"user"}
                  data={data}
                  valueType={valueType}
                  previewOrSharingMode={previewMode || sharingMode}
                />
              )}
            </Card.Body>
            {insight.description && !screenshotMode && (
              <Card.Footer bg="white">
                <Card.DescriptionAccordion insight={insight} />
              </Card.Footer>
            )}
          </Card.Container>
        )}
      </InsightCard>
      <Modal
        isOpen={isFullScreenViewOpen}
        onClose={onCloseFullscreenView}
        size="full"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {data && !isLoading && (
              <CohortTable
                key={serializeKey}
                report={report}
                unit={"user"}
                data={data}
                valueType={valueType}
                previewOrSharingMode={previewMode || sharingMode}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
