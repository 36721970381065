import { BellAlertIcon as BellAlertIcon20 } from "@heroicons/react/20/solid";
import { BellAlertIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "Components/ui/button";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { useViewAlert } from "core/hooks/useViewAlert";
import { useGetAppObjectQuery } from "core/models/appObjects";
import { Plan } from "core/types/App";
import { ViewAlertEditorDrawer } from "modules/Groups/Group/ViewAlertEditorDrawer";

export const ObjectDigestButton = () => {
  const { appId, appObjectId, objectId } = useParams();
  const { data: appObject } = useGetAppObjectQuery({
    appId: Number(appId),
    id: Number(appObjectId),
  });
  const { isEnabled } = useViewAlert({
    groupId: objectId || "",
    appObjectType: appObject?.objectType,
  });
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <PaywallPopover feature="company digests" redirect="home" plan={Plan.Pro}>
        <Button
          variant="ghost"
          size="sm"
          className="px-2"
          onClick={() => setIsOpen(true)}
        >
          {isEnabled ? (
            <BellAlertIcon20 className="size-4 text-gray-700" />
          ) : (
            <BellAlertIcon className="size-4 text-gray-700" />
          )}
        </Button>
      </PaywallPopover>
      {appObject && (
        <ViewAlertEditorDrawer
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          groupId={objectId || ""}
          appObjectType={appObject?.objectType}
        />
      )}
    </>
  );
};
