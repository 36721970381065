import { Flex, StyleProps } from "@chakra-ui/react";

export const ColumnHeader: React.FC<
  StyleProps & { children: React.ReactNode }
> = ({ children, ...props }) => {
  return (
    <Flex p={2} align="center" justify="flex-start" {...props}>
      <p className="text-uppercase text-xs font-bold text-gray-600">
        {children}
      </p>
    </Flex>
  );
};
