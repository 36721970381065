import { Box, Button } from "@chakra-ui/react";
import React, { useState } from "react";

import colors from "core/design-system/constants/theme/colors";
import { IQuery } from "core/types/Query";

const CodeEditor = React.lazy(() => import("@uiw/react-textarea-code-editor"));

export const SQLEditor: React.FC<{
  query: IQuery;
  executeQuery: (query: IQuery) => Promise<unknown>;
  updateQuery: (query: IQuery) => Promise<unknown>;
}> = ({ query, executeQuery, updateQuery }) => {
  const [sqlQuery, setSqlQuery] = useState(query?.query || "");

  async function onUpdateSql() {
    if (query.id) await updateQuery({ ...query, query: sqlQuery });
  }

  return (
    <Box
      position="relative"
      w="full"
      borderBottom="1px solid"
      borderColor="gray.300"
    >
      <CodeEditor
        value={sqlQuery}
        language="sql"
        placeholder="Write some SQL..."
        onChange={(e) => setSqlQuery(e.target.value)}
        data-color-mode="light"
        padding={15}
        style={{
          borderRadius: "0px",
          width: "100%",
          fontSize: 12,
          backgroundColor: colors.gray[50],
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
      />
      <Button
        position="absolute"
        right="10px"
        bottom="10px"
        colorScheme="purple"
        variant="ghost"
        onClick={async () => {
          await onUpdateSql();
          await executeQuery(query);
        }}
      >
        Run
      </Button>
    </Box>
  );
};
