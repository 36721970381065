import { Switch } from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { AiOptInModal } from "core/components/AI/OptInModal";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { baseUrl } from "core/constants/paywall";
import { useAI } from "core/hooks/useAI";
import { usePaywall } from "core/hooks/usePaywall";
import { useAiOptInMutation } from "core/models/apps";

export const AiOptIn: React.FC = () => {
  const { appId } = useParams();
  const { aiOptedIn } = useAI();
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const { shouldBePaywalled } = usePaywall();
  const [aiOptIn] = useAiOptInMutation();
  const redirectUrl = `${baseUrl}/a/${appId}/settings`;

  const toggleAiOptIn = () => {
    if (!aiOptedIn && shouldBePaywalled) return setShowPaywallModal(true);
    if (!aiOptedIn) return setShowTermsModal(true);
    aiOptIn({ appId, aiOptedIn: false }).then(() => {
      window.location.reload();
    });
  };

  const handleOptIn = () => {
    aiOptIn({ appId, aiOptedIn: true }).then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      <div>
        <div className="align-center flex w-full justify-between rounded-lg">
          <div className="flex flex-col">
            <div className="mb-1 text-sm font-semibold">Ask AI opt-in</div>
            <div className="text-xs">
              Opt into using our GPT-4 enabled features.
            </div>
          </div>
          <div>
            <Switch
              isChecked={aiOptedIn}
              ml={1}
              size="md"
              colorScheme="purple"
              onChange={toggleAiOptIn}
            />
          </div>
        </div>
      </div>
      {showPaywallModal && (
        <PaywallModal redirectUrl={redirectUrl}>
          {(paywallModal: IPaywallModalChildren) => (
            <paywallModal.Modal
              isOpen
              onClose={() => setShowPaywallModal(false)}
            />
          )}
        </PaywallModal>
      )}
      <AiOptInModal
        isOpen={showTermsModal}
        onClose={() => setShowTermsModal(false)}
        onAgree={handleOptIn}
      />
    </>
  );
};
