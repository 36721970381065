import { RadioGroup } from "@headlessui/react";

import { ValueType } from "core/components/CohortTable/CohortTable.types";
import { cx } from "helpers/cx";

interface ICohortUnitSwitcherProps {
  value: ValueType;
  onChange: (value: ValueType) => void;
}

export const CohortUnitSwitcher: React.FC<ICohortUnitSwitcherProps> = ({
  value,
  onChange,
}) => {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className="border-1 flex rounded-md border-gray-200"
    >
      <RadioGroup.Option value="percentage">
        {({ checked }) => (
          <span
            className={cx(
              "cursor-pointer rounded-bl-md rounded-tl-md border px-2 py-1 text-sm",
              checked
                ? "border-l border-purple-400 bg-purple-100 text-purple-600"
                : "border-r-0 border-gray-200 bg-white text-gray-600",
            )}
          >
            %
          </span>
        )}
      </RadioGroup.Option>
      <RadioGroup.Option value="count">
        {({ checked }) => (
          <span
            className={cx(
              "cursor-pointer rounded-br-md rounded-tr-md border px-2 py-1 text-sm",
              checked
                ? "border-l border-purple-400 bg-purple-100 text-purple-600"
                : "border-l-0 border-gray-200 bg-white text-gray-600",
            )}
          >
            #
          </span>
        )}
      </RadioGroup.Option>
    </RadioGroup>
  );
};
