import { Box, Button, Flex, Input, Switch, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useUserAuth } from "core/hooks/useUserAuth";
import { updateApp } from "core/models/app";

export const EnableJoinAutomatically: React.FC = () => {
  const { domain: currentUserEmailDomain } = useUserAuth();
  const {
    id: appId,
    autoJoinCompanyWorkspaceEnabled,
    companyDomain,
  } = useCurrentApp();
  const [domain, setDomain] = useState(companyDomain);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setDomain(companyDomain);
  }, [appId, companyDomain, setDomain]);

  useEffect(() => {
    if (showError) setShowError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain]);

  const dispatch = useDispatch();

  const onToggleJoinAutomatically = () => {
    dispatch(
      updateApp({
        appId,
        autoJoinCompanyWorkspaceEnabled: !autoJoinCompanyWorkspaceEnabled,
      }),
    );
  };

  function onChangeCompanyDomain() {
    if (!domain) return;

    if (currentUserEmailDomain !== domain) {
      setShowError(true);
    } else {
      dispatch(updateApp({ appId, companyDomain: domain }));
    }
  }

  return (
    <Box borderWidth="1px" borderColor="gray.100" borderRadius="md" p={4}>
      <Flex>
        <Box>
          <Flex align="center" gridGap={4} mb={2}>
            <Flex>
              <Switch
                isChecked={autoJoinCompanyWorkspaceEnabled}
                size="md"
                colorScheme="purple"
                onChange={onToggleJoinAutomatically}
              />
            </Flex>
            <Flex>
              <Text fontSize="md" fontWeight="semibold">
                Team members can automatically join
              </Text>
            </Flex>
          </Flex>

          <Box ml="50px" fontSize="sm" color="gray.600">
            When someone signs up for June using an email that matches the below
            domain, they will be automatically added to this workspace.
          </Box>

          <Input
            mt={5}
            w="50%"
            ml="50px"
            placeholder='Your company domain (e.g. "notion.so")'
            defaultValue={domain}
            onChange={(e: any) => setDomain(e.currentTarget.value)}
          />
          {companyDomain !== domain && (
            <Button ml={3} onClick={onChangeCompanyDomain} colorScheme="purple">
              {companyDomain ? "Verify" : "Add"}
            </Button>
          )}
          {showError && (
            <Text
              mt={2}
              ml="50px"
              fontSize="xs"
              fontWeight="semibold"
              color="red.500"
            >
              Domain does not match the domain of your email
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
