import { ComponentDefaultProps } from "@chakra-ui/react";

import { FavouriteObjectType, IFavourite } from "@/core/models/favourites";
import { CompanyItem } from "@/modules/Navigation/Sidebar/Favourites/CompanyItem";
import { ContactItem } from "@/modules/Navigation/Sidebar/Favourites/ContactItem";
import { GroupItem } from "@/modules/Navigation/Sidebar/Favourites/GroupItem";
import { ReportItem } from "@/modules/Navigation/Sidebar/Favourites/ReportItem";
import { ViewItem } from "@/modules/Navigation/Sidebar/Favourites/ViewItem";

export const Item: React.FC<
  { favourite: IFavourite } & ComponentDefaultProps
> = ({ favourite }) => {
  if (favourite.objectType === FavouriteObjectType.User) {
    return <ContactItem favourite={favourite} />;
  }
  if (favourite.objectType === FavouriteObjectType.Company) {
    return <CompanyItem favourite={favourite} />;
  }
  if (favourite.objectType === FavouriteObjectType.Group) {
    return <GroupItem favourite={favourite} />;
  }
  if (favourite.objectType === FavouriteObjectType.Report) {
    return <ReportItem favourite={favourite} />;
  }
  if (favourite.objectType === FavouriteObjectType.View) {
    return <ViewItem favourite={favourite} />;
  }
  return <div>Item</div>;
};
