import { Box } from "@chakra-ui/react";

import Badge, { BadgeProps } from "core/modules/reports/Achievements/Badge";
import GoalTooltip from "core/modules/reports/Achievements/GoalTooltip";
import {
  Goal,
  ProgressionState,
} from "core/modules/reports/Achievements/types";
import { getTrackColorScheme } from "core/modules/reports/Achievements/utils";

export const GoalBadge: React.FC<
  {
    goal: Goal;
    isCompanyLevel: boolean;
    marginTop?: string | number;
    marginRight?: string | number;
    state?: ProgressionState;
    goalLevel?: number;
  } & Partial<BadgeProps>
> = ({
  goal,
  isCompanyLevel,
  marginTop,
  marginRight,
  state,
  goalLevel,
  ...badgeProps
}) => {
  const { badgeIcon: icon, track } = goal;

  return (
    <GoalTooltip
      state={state}
      goal={goal}
      isCompanyLevel={isCompanyLevel}
      goalLevel={goalLevel}
    >
      <Box marginTop={marginTop} marginRight={marginRight}>
        <Badge color={getTrackColorScheme(track)} icon={icon} {...badgeProps} />
      </Box>
    </GoalTooltip>
  );
};

export default GoalBadge;
