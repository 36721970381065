import { withInView } from "core/components/InsightCard/withInView";
import { CohortTable } from "core/components/ViewInsight/Graphs/CohortTable";
import { List } from "core/components/ViewInsight/Graphs/List";
import { SimpleAreaChart } from "core/components/ViewInsight/Graphs/SimpleAreaChart";
import defaultTemplateConfig from "core/constants/report-configurations/report-types/defaultTemplateConfig";
import {
  ANY,
  AUDIENCE_TYPE,
  ENUM_TYPE,
  FIRST,
  MULTI_EVENT_TYPE,
  OR,
  PAGE,
  SETTINGS_TYPE,
  SINGLE_EVENT_TYPE,
  TRACK,
} from "core/constants/report-setup";
import { Template } from "core/constants/templates";
import { DEFAULT_GROUPED_OPTIONS } from "core/constants/timerange";
import { CohortContainer as GroupCohortContainer } from "core/modules/reports/report-types/GroupRetention/CohortContainer";
import { GraphContainer as GroupGraphContainer } from "core/modules/reports/report-types/GroupRetention/GraphContainer";
import {
  ChurningUsersContainer,
  IRetentionInsightUser,
} from "core/modules/reports/report-types/Retention/ChurningUsersContainer";
import { CohortContainer } from "core/modules/reports/report-types/Retention/CohortContainer";
import { GraphContainer } from "core/modules/reports/report-types/Retention/GraphContainer";
import { TopRetainedUsersContainer } from "core/modules/reports/report-types/Retention/TopRetainedUsersContainer";
import { IEvent } from "core/types/Event";
import { ITemplateConfig } from "core/types/TemplateConfig";
import { ITrait } from "core/types/Trait";
import { pluralize } from "helpers/pluralize";

function transformCohortData(data: any) {
  if (!data) return [];
  return data?.cohortData
    ?.map((item: { data: any; startDate: string; endDate: string }) => {
      let startDate = new Date(item.startDate);
      let endDate = new Date(item.endDate);

      let startDateFormat =
        startDate.toLocaleString("default", { month: "short" }) +
        " " +
        startDate.getDate();
      let endDateFormat =
        endDate.toLocaleString("default", { month: "short" }) +
        " " +
        endDate.getDate();

      return {
        id: `${startDateFormat} - ${endDateFormat}`,
        intervalType: data.intervalType,
        data: item.data.map(
          (d: { week: string; retention: number; startDate: string }) => {
            return {
              x: `W${d.week}`,
              y: Math.round(d.retention * 10) / 10, // to round to one decimal place
              ...d,
              intervalStartDate: d.startDate,
              startDate: item.startDate,
              data: item.data,
            };
          },
        ),
        startDate: item.startDate,
        endDate: item.endDate,
      };
    })
    .reverse();
}

export const buildRetentionHighlight = (data: any) => {
  if (!data) return { value: 0, unit: "%" };

  const intervalType = data?.intervalType;
  const rawData = data?.retentionData?.[0]?.data ?? [];
  const lastIndexNonZero = rawData.reduceRight(
    (acc: number, curr: { x: string; y: number }, index: number) =>
      curr.y !== 0 && acc === -1 ? index : acc,
    -1,
  );
  const retentionData =
    lastIndexNonZero !== -1 ? rawData.slice(0, lastIndexNonZero + 1) : [];

  if (retentionData.length >= 4 && intervalType === "month") {
    return {
      value: retentionData[3]?.y,
      description: `after 3 ${intervalType}s`,
      unit: "%",
    };
  } else if (retentionData.length >= 5 && intervalType === "week") {
    return {
      value: retentionData[4]?.y,
      description: `after 4 ${intervalType}s`,
      unit: "%",
    };
  } else if (retentionData.length >= 8 && intervalType === "day") {
    return {
      value: retentionData[7]?.y,
      description: `after 7 ${intervalType}s`,
      unit: "%",
    };
  } else if (retentionData.length === 0) {
    return { value: 0, unit: "%" };
  } else {
    const lastNonZeroData = retentionData[retentionData.length - 1];
    const intervalName =
      retentionData.length === 2 ? intervalType : `${intervalType}s`;

    return {
      value: Math.round(lastNonZeroData?.y),
      description: `after ${retentionData.length - 1} ${intervalName}`,
      unit: "%",
    };
  }
};

const LazyGraphContainer = withInView(GraphContainer);
const LazyGroupGraphContainer = withInView(GroupGraphContainer);

export const retention: ITemplateConfig = {
  ...defaultTemplateConfig,
  route: "retention",
  reportType: 1,
  title: "Retention",
  template: Template.Retention,
  requiredEvents: ["Core events for your product", "Sign up"],
  sections: {
    user: [
      CohortContainer,
      ChurningUsersContainer,
      TopRetainedUsersContainer,
      LazyGraphContainer,
    ],
    company: [
      GroupCohortContainer,
      ChurningUsersContainer,
      TopRetainedUsersContainer,
      LazyGroupGraphContainer,
    ],
  },
  insights: [
    {
      typeId: 0,
      slug: "retention-graph",
      title: "Retention graph",
      section: GraphContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about how we calculate the graph above",
        content:
          "We calculate the above retention graph by taking a weighted average (where the weight is the number of users of a cohort) of the weekly retention for each cohort in your acquisition cohort table. We consider a user retained only if at least one of the events in the report setup was triggered within the chosen time range.",
        link: "https://help.june.so/en/articles/5978485-user-retention",
      },
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        transform: (data) =>
          data?.retentionData?.[0]?.data?.map(({ x, y }: any) => ({ x, y })),
        highlight: buildRetentionHighlight,
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 7,
      slug: "churning-users",
      title: "Churning users",
      section: ChurningUsersContainer,
      shareable: true,
      slackAlertAvailable: true,
      copyable: false,
      downloadable: false,
      view: {
        level: "user",
        Component: List,
        transform: (data) => {
          return data?.contacts
            ?.slice(0, 4)
            ?.map((entity: IRetentionInsightUser) => {
              const traits = entity?.traits as ITrait;
              return {
                id: entity?.userId,
                displayName: traits?.["email"],
                avatar: traits?.["avatar"],
                description: `Last seen ${new Date(entity?.lastEventDate).toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" })}`,
              };
            });
        },
      },
    },
    {
      typeId: 8,
      slug: "churning-groups",
      title: "Churning {APP_OBJECT_PLURAL_NAME}",
      section: ChurningUsersContainer,
      shareable: true,
      copyable: false,
      downloadable: false,
      slackAlertAvailable: true,
      view: {
        level: "group",
        Component: List,
        transform: (data) => {
          return data?.contacts
            ?.slice(0, 4)
            ?.map((entity: IRetentionInsightUser) => {
              const traits = entity?.traits as ITrait;
              return {
                id: entity?.groupId,
                displayName: traits?.["name"],
                description: `Last seen ${new Date(entity?.lastEventDate).toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" })}`,
              };
            });
        },
      },
    },
    {
      typeId: 3,
      slug: "top-retained-users",
      title: "Most retained users",
      section: TopRetainedUsersContainer,
      shareable: true,
      copyable: false,
      downloadable: false,
      slackAlertAvailable: true,
      view: {
        level: "user",
        Component: List,
        transform: (data) => {
          return data?.contacts
            ?.slice(0, 4)
            ?.map((entity: IRetentionInsightUser) => {
              const traits = entity?.traits as ITrait;
              return {
                id: entity?.userId,
                displayName: traits?.["email"],
                avatar: traits?.["avatar"],
                description: `User over ${entity?.numberOfActiveIntervals} ${pluralize(
                  Number(entity?.numberOfActiveIntervals),
                  entity?.intervalType,
                  `${entity?.intervalType}s`,
                )}`,
              };
            });
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 6,
      slug: "top-retained-groups",
      title: "Most retained {APP_OBJECT_PLURAL_NAME}",
      section: TopRetainedUsersContainer,
      shareable: true,
      copyable: false,
      downloadable: false,
      slackAlertAvailable: true,
      view: {
        level: "group",
        Component: List,
        transform: (data) => {
          return data?.contacts
            ?.slice(0, 4)
            ?.map((entity: IRetentionInsightUser) => {
              const traits = entity?.traits as ITrait;
              return {
                id: entity?.groupId,
                displayName: traits?.["name"],
                avatar: traits?.["avatar"],
                description: `User over ${entity?.numberOfActiveIntervals} ${pluralize(
                  Number(entity?.numberOfActiveIntervals),
                  entity?.intervalType,
                  `${entity?.intervalType}s`,
                )}`,
              };
            });
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 1,
      slug: "acquisition-cohort",
      title: "Acquisition cohorts",
      section: CohortContainer,
      shareable: true,
      downloadable: false,
      description: {
        title: "Learn more about how we calculate the above cohorts",
        content:
          "The number of identified users that came back to your app on a particular period (day, week, month) over the total number of identified users that triggered the start event within the same cohort. Every user belongs to a specific cohort depending on when the start event was triggered for the first time.",
      },
      view: {
        level: "user",
        Component: CohortTable,
        transform: (data) => transformCohortData(data),
        highlight: buildRetentionHighlight,
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 4,
      slug: "company-retention-graph",
      title: "Retention graph",
      section: GroupGraphContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about how we calculate the graph above",
        content:
          "We calculate the above retention graph by taking a weighted average (where the weight is the number of companies of a cohort) of the weekly retention for each cohort in your acquisition cohort table. We consider a company retained only if at least one of the events in the report setup was triggered by a user in that company within the chosen time range.",
        link: "https://help.june.so/en/articles/5978485-user-retention",
      },
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        transform: (data) =>
          data?.retentionData?.[0]?.data?.map(({ x, y }: any) => ({ x, y })),
        highlight: buildRetentionHighlight,
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 5,
      slug: "company-acquisition-cohort",
      title: "Acquisition cohorts",
      section: GroupCohortContainer,
      description: {
        title: "Learn more about how we calculate the above cohorts",
        content:
          "The number of companies that came back to your app on a particular period (day, week, month) over the total number of companies that triggered the start event within the same cohort. Every company belongs to a specific cohort depending on when the start event was triggered for the first time by a user in that company.",
      },
      shareable: true,
      downloadable: false,
      view: {
        level: "group",
        Component: CohortTable,
        transform: (data) => transformCohortData(data),
        highlight: buildRetentionHighlight,
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
  ],
  subHeading: "User retention",
  subtitle: "Overview of your user retention since they signed up",
  aboutTemplate:
    "Learn how your users retain since they started using your product. If you don’t have a unique event when users sign up, you should use the feature retention template instead.",
  howItWorksText: "Learn how it works",
  howItWorksLink: "https://help.june.so/en/articles/5978485-user-retention",
  howToSetupText: "Learn how to set up retention",
  howToSetupLink: "https://help.june.so/en/articles/5978485-user-retention",
  hasSmartEvents: true,
  hasDateRangeSection: true,
  dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
  setup: {
    requiredSections: [0, 1],
    eventOperator: OR,
    setupSections: [
      {
        id: "sign_up_event",
        configKey: "startingEvent",
        title: "Signup event",
        description:
          "Select an event users trigger when they sign up for your product.",
        type: SINGLE_EVENT_TYPE,
        supportedEventTypes: [FIRST, TRACK, PAGE],
        required: true,
        validate: (events) =>
          events &&
          events.length > 0 &&
          events.length ===
            events.map((event) => event?.id).filter((x) => x).length,
      },
      {
        id: "retention_event",
        configKey: "events",
        title: "Retention events",
        description: "Add events that show some activity in your product.",
        type: MULTI_EVENT_TYPE,
        supportedEventTypes: [ANY, TRACK, PAGE],
        required: true,
        validate: (events) =>
          events &&
          events.length > 0 &&
          events.length ===
            events.map((event) => event?.id).filter((x) => x).length,
      },
      {
        id: "frequency",
        configKey: "desiredFrequency",
        title: "Frequency of usage",
        description:
          "Is your product something people use daily, weekly or monthly?",
        type: ENUM_TYPE,
        options: [
          {
            name: "Daily",
            value: 0,
            type: "enum",
          },
          {
            name: "Weekly",
            value: 1,
            default: true,
            type: "enum",
          },
          {
            name: "Monthly",
            value: 2,
            type: "enum",
          },
        ],
      },
      {
        id: "audience",
        configKey: "audience",
        title: "Audience",
        description: "Select an audience for your report",
        type: AUDIENCE_TYPE,
        supportedUserTypes: ["user"],
      },
      {
        id: "settings",
        configKey: "settings",
        title: "Settings",
        description: "Select the settings for your report",
        type: SETTINGS_TYPE,
        options: [
          {
            type: "boolean",
            isUserOption: true,
            identifier: "removeWeekends",
            displayName: "Weekends",
          },
        ],
      },
    ],
  },
  validateSetup: (config) =>
    config &&
    config?.startingEvent &&
    config?.events &&
    config?.events?.length > 0 &&
    config?.events?.some((e: IEvent) => e.name),
};

export default retention;
