import { ComponentDefaultProps } from "@chakra-ui/react";

import { EventIcon } from "core/design-system/components/Icon/Custom/EventIcon";

interface IEmptyStateProps extends ComponentDefaultProps {
  IconComponent?: React.ElementType;
  title?: string;
  description?: string;
}

const EmptyState: React.FC<IEmptyStateProps> = ({
  IconComponent = EventIcon,
  title = "There's no data for this insight",
  description = "Make sure you selected the correct events and timerange",
  ...props
}) => {
  return (
    <div className="flex h-[300px] w-full items-center justify-center">
      <div className="flex flex-col items-center gap-1 text-center">
        {IconComponent && (
          <div className="mb-3 rounded-full border p-3">
            <IconComponent className="size-6 text-purple-500" />
          </div>
        )}
        <p className="text-sm font-medium">{title}</p>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default EmptyState;
