import { ComponentDefaultProps, Tooltip } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Bars2Icon } from "@heroicons/react/24/outline";
import { ColumnResizeMode, Header, Table } from "@tanstack/react-table";
import { AnimatePresence, motion } from "framer-motion";
import React, { CSSProperties, useState } from "react";

import { Shadow } from "core/components/Table/People/Shadow";
import { bgColor } from "core/hooks/usePeopleTableColumns";
import { useTableTraitConfig } from "core/hooks/useTableTraitConfig";
import { IAppObject } from "core/types/AppObject";
import { IContact } from "core/types/Contact";
import { IGroup } from "core/types/Group";
import { cx } from "helpers/cx";

export const Th: React.FC<
  {
    header: Header<IGroup | IContact, unknown>;
    headerIndex: number;
    showShadow: boolean;
    isSortedBy: boolean;
    table: Table<IGroup | IContact>;
    columnResizeMode: ColumnResizeMode;
    onResized: (size: number) => void;
    appObject: IAppObject;
    highlightSorting?: boolean;
  } & ComponentDefaultProps
> = ({
  header,
  headerIndex,
  children,
  showShadow,
  isSortedBy,
  table,
  columnResizeMode,
  onResized,
  appObject,
  highlightSorting = true,
}) => {
  const [animate, setAnimate] = useState(false);
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    });

  const { tableTraitConfig } = useTableTraitConfig(
    appObject.objectType,
    header.column.id,
  );

  const style: CSSProperties = {
    position: isDragging
      ? "relative"
      : headerIndex === 0
        ? "sticky"
        : "relative",
    transform: CSS.Translate.toString(transform),
    transition: "width transform 0.2s ease-in-out",
    zIndex: headerIndex === 0 ? 3 : isDragging ? 3 : 2,
    width: header.getSize(),
  };

  return (
    <th
      className={cx(
        "min-w-full cursor-pointer text-left text-sm font-medium capitalize text-gray-900 hover:bg-gray-50",
        header.column.getIsPinned() === "left"
          ? "sticky left-0 block rounded-tl-lg border-r"
          : "",
        highlightSorting ? bgColor(tableTraitConfig, isSortedBy) : "bg-white",
      )}
      colSpan={header.colSpan}
      onMouseEnter={() => setAnimate(true)}
      onMouseLeave={() => setAnimate(false)}
      style={style}
      ref={setNodeRef}
    >
      <div
        className="relative flex min-w-full justify-between"
        style={{ width: header.getSize() }}
      >
        <div className="flex min-w-full items-center">
          {![0].includes(headerIndex) && animate && (
            <motion.div
              className="absolute left-3 z-10 flex cursor-pointer items-center bg-gray-50"
              key={headerIndex}
              animate={{ opacity: 1, top: 8 }}
              initial={{ opacity: 0, top: -2, left: 10 }}
              exit={{ opacity: 0, top: 0 }}
              transition={{ duration: 0.2 }}
              {...attributes}
              {...listeners}
              style={{ cursor: isDragging ? "grabbing" : "grab" }}
            >
              <Bars2Icon className="h-4 w-4" />
            </motion.div>
          )}
          {children}
          <AnimatePresence>
            {headerIndex === 0 && showShadow && <Shadow />}
          </AnimatePresence>
        </div>
        <Tooltip label="Resize">
          <div
            {...{
              onDoubleClick: () => header.column.resetSize(),
              onMouseDown: header.getResizeHandler(),
              onMouseUp: () => onResized(header.getSize()),
              onTouchStart: header.getResizeHandler(),
              style: {
                transform:
                  columnResizeMode === "onEnd" && header.column.getIsResizing()
                    ? `translateX(${
                        (table.options.columnResizeDirection === "rtl"
                          ? -1
                          : 1) *
                        (table.getState().columnSizingInfo.deltaOffset ?? 0)
                      }px)`
                    : "",
              },
            }}
            className={cx(
              `resizer ltr absolute -right-[2px] top-0 h-[33px] w-[3px] cursor-col-resize hover:bg-purple-500`,
              header.column.getIsResizing() ? "bg-purple-500" : "",
            )}
          />
        </Tooltip>
      </div>
    </th>
  );
};
