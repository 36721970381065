import { Button, Tooltip } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useEffect, useState } from "react";

import { TraitOption } from "core/components/Traits/TraitOption";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import { Icon } from "core/design-system/components/Icon";
import { ITempDataMapping } from "core/hooks/useDataMappings";
import { ITraitKeyResponse } from "core/models/traits";

const GROUP_ID_TRAIT_OPTION: ITraitKeyResponse = {
  trait: "group_id",
  valueCount: 0,
};

const JUNE_PROFILE_URL_TRAIT_OPTION: ITraitKeyResponse = {
  trait: "june_profile_url",
  valueCount: 0,
};

type AddMappingProps = {
  addMapping: (mapping: ITempDataMapping) => void;
  traits: ITraitKeyResponse[];
  isDisabled?: boolean;
  showGroupIdOption?: boolean;
  groupIdOptionDisabled?: boolean;
  juneProfileUrlOptionDisabled?: boolean;
};

export const AddMapping = ({
  traits,
  addMapping,
  isDisabled,
  showGroupIdOption = true,
  groupIdOptionDisabled = false,
  juneProfileUrlOptionDisabled = false,
}: AddMappingProps) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredTraits, setFilteredTraits] =
    useState<ITraitKeyResponse[]>(traits);

  useEffect(() => {
    setFilteredTraits(traits);
    setSearchQuery("");
  }, [traits]);

  useEffect(() => {
    if (searchQuery) {
      const results = traits.filter(({ trait }) =>
        trait.toLowerCase().includes(searchQuery),
      );
      setFilteredTraits(results);
    } else {
      setFilteredTraits(traits);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <div className="mt-2">
      <Popover className="relative">
        <PopoverButton>
          <Button
            variant="ghost"
            colorScheme="purple"
            isDisabled={isDisabled}
            leftIcon={<Icon iconType="icon" name="add" h={3} w={3} pb={0.5} />}
          >
            Add mapping
          </Button>
        </PopoverButton>
        <PopoverPanel className="flex flex-col">
          {({ close }) => (
            <>
              <Lifecycle onUnmount={() => setSearchQuery("")} />
              <Command>
                <CommandInput
                  placeholder="Search traits..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <CommandList>
                  {filteredTraits.length === 0 && (
                    <span className="px-2 py-1 text-sm sm:leading-4">
                      No traits found
                    </span>
                  )}
                  {showGroupIdOption && (
                    <Tooltip
                      label={
                        groupIdOptionDisabled
                          ? "This trait has already been used"
                          : ""
                      }
                      hasArrow
                      placement="right"
                      shouldWrapChildren
                    >
                      <CommandOption
                        value={() => {
                          if (groupIdOptionDisabled) return;
                          addMapping({
                            source: GROUP_ID_TRAIT_OPTION.trait,
                            isTemp: true,
                            isComputed: null,
                            isRequired: null,
                            isUnique: null,
                            id: null,
                            appId: null,
                            destination: null,
                            isEditable: null,
                            appObject: null,
                            isMatchingIdentifier: null,
                          });
                          close();
                        }}
                      >
                        <TraitOption
                          isDisabled={groupIdOptionDisabled}
                          trait={GROUP_ID_TRAIT_OPTION}
                        />
                      </CommandOption>
                    </Tooltip>
                  )}
                  <Tooltip
                    label={
                      juneProfileUrlOptionDisabled
                        ? "This trait has already been used"
                        : ""
                    }
                    hasArrow
                    placement="right"
                    shouldWrapChildren
                  >
                    <CommandOption
                      value={() => {
                        if (juneProfileUrlOptionDisabled) return;
                        addMapping({
                          source: JUNE_PROFILE_URL_TRAIT_OPTION.trait,
                          isTemp: true,
                          isComputed: null,
                          isRequired: null,
                          isUnique: null,
                          id: null,
                          appId: null,
                          destination: null,
                          isEditable: null,
                          appObject: null,
                          isMatchingIdentifier: null,
                        });
                        close();
                      }}
                    >
                      <TraitOption
                        isDisabled={juneProfileUrlOptionDisabled}
                        trait={JUNE_PROFILE_URL_TRAIT_OPTION}
                      />
                    </CommandOption>
                  </Tooltip>
                  {filteredTraits.map((trait: ITraitKeyResponse) => (
                    <CommandOption
                      value={() => {
                        addMapping({
                          source: trait.trait,
                          isTemp: true,
                          id: null,
                          appId: null,
                          destination: null,
                          isEditable: null,
                          appObject: null,
                          isMatchingIdentifier: null,
                          isRequired: null,
                          isUnique: null,
                          isComputed: null,
                        });
                        close();
                      }}
                    >
                      <TraitOption trait={trait} />
                    </CommandOption>
                  ))}
                </CommandList>
              </Command>
            </>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  );
};
