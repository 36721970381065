export const simplifyXAxisTicks = (data: any, dataKey: string) => {
  if (!data?.length) {
    return {
      firstTick: undefined,
      lastTick: undefined,
    };
  }

  if (dataKey === "y") {
    const values = data.map((item: { [key: string]: number }) => item[dataKey]);
    return {
      firstTick: Math.min(...values),
      lastTick: Math.max(...values),
    };
  }

  const labelCounts = data.reduce(
    (acc: { [key: string]: number }, item: any) => {
      const label = item[dataKey];
      acc[label] = (acc[label] || 0) + 1;
      return acc;
    },
    {},
  );

  const hasDuplicates = (Object.values(labelCounts) as number[]).some(
    (count: number) => count > 1,
  );

  if (hasDuplicates && data[0].date) {
    data.forEach((item: any) => {
      if (labelCounts[item[dataKey]] > 1) {
        const date = new Date(item.date);
        item[dataKey] =
          `${item[dataKey]} ${date.getFullYear().toString().slice(2)}`;
      }
    });
  }

  return {
    firstTick: data[0][dataKey],
    lastTick: data[data.length - 1][dataKey],
  };
};

export const yAxisWidth = (lastTick: number) => {
  const yTickSize = String(lastTick).split("").length;
  const yTickWidth = yTickSize === 1 ? 22 : yTickSize === 2 ? 25 : 35;
  return yTickWidth;
};
