import { Box, Button } from "@chakra-ui/react";
import copy from "copy-to-clipboard";
import Highlight, { defaultProps, Language } from "prism-react-renderer";
import githubTheme from "prism-react-renderer/themes/github";
import oceanicTheme from "prism-react-renderer/themes/oceanicNext";
import { useState } from "react";

import { SDKLanguage } from "modules/Onboarding/SetupSources/SDKs";

interface ICodeBlockProps {
  language: SDKLanguage;
  raw: string;
  wrapped?: boolean;
  theme?: "light" | "dark";
}

export const WRAPPER_PROPS = {
  dark: {
    background: "purple.900",
    py: 5,
    px: 4,
    fontSize: "sm",
    rounded: "lg",
  },
  light: {
    background: "gray.50",
    py: 5,
    px: 4,
    fontSize: "sm",
    borderBottomRadius: "lg",
  },
};

export const Highlighter: React.FC<{
  code: string;
  language: SDKLanguage;
  theme?: "light" | "dark";
}> = ({ code, language, theme = "dark" }) => {
  const codeTheme = theme === "dark" ? oceanicTheme : githubTheme;
  const customTheme = {
    ...codeTheme,
    plain: {
      ...codeTheme.plain,
      backgroundColor: WRAPPER_PROPS[theme].background,
    },
  };

  return (
    <Highlight
      {...defaultProps}
      theme={customTheme}
      code={code}
      language={language as Language}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={{ ...style, overflowX: "auto" }}>
          {tokens.map((line, i) => (
            <article key={i} {...getLineProps({ line, key: i })}>
              <div className="line-no">{i + 1}</div>
              <div className="line-content">
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            </article>
          ))}
        </pre>
      )}
    </Highlight>
  );
};

const CodeBlock: React.FC<ICodeBlockProps> = ({
  wrapped = true,
  language = "javascript",
  raw = "",
  theme = "dark",
}) => {
  const [copied, setCopied] = useState(false);

  function copyRawScript(rawText: string) {
    copy(rawText);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }

  return (
    <article>
      <Box
        position="relative"
        {...(wrapped ? WRAPPER_PROPS[theme] : { fontSize: "xs" })}
      >
        <Highlighter code={raw} language={language} theme={theme} />
        <Button
          size={"xs"}
          colorScheme={theme === "dark" ? "whiteAlpha" : "purple"}
          position="absolute"
          top={3}
          right={3}
          onClick={() => copyRawScript(raw)}
        >
          {copied ? "Copied!" : "Copy"}
        </Button>
      </Box>
    </article>
  );
};

export default CodeBlock;
