import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

import { IEventRow } from "core/models/events";
import { Header } from "core/modules/reports/setup/Setup/Header";
import EventAlerts from "modules/Events/List/EventAlerts";

interface INotificationsSectionProps {
  event: IEventRow;
  onClose: () => void;
  howToSetupText: string;
  howToSetupLink: string;
}

export const NotificationsSection: React.FC<INotificationsSectionProps> = ({
  event,
  onClose,
  howToSetupText = "",
  howToSetupLink = "",
}) => {
  return (
    <Flex
      direction="column"
      justify="space-between"
      h="100%"
      overflowY={"scroll"}
    >
      <Flex direction="column" justify="flex-start">
        <Header
          title={
            <Text>
              Slack alert for{" "}
              <Text as="span" fontWeight="semibold" color="purple.500">
                {event?.name}
              </Text>
            </Text>
          }
          howToSetupText={howToSetupText}
          howToSetupLink={howToSetupLink}
          useCases
        />
        <Box mx={8} bg="white">
          <EventAlerts eventId={event.id} onClose={onClose} />
        </Box>
      </Flex>
    </Flex>
  );
};
