import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import { ITraitWithConfig } from "core/types/Trait";

export const TraitNameCell: React.FC<{ props: ITraitWithConfig }> = ({
  props: trait,
}) => {
  return (
    <Flex direction="column" gap={1}>
      <div className="flex items-center gap-2">
        <Text fontSize="sm" fontWeight="medium">
          {trait.name}
        </Text>
        {!trait.isEditable && (
          <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Read only
          </span>
        )}
      </div>
      <Text fontSize="sm" color="gray.600">
        {trait.description}
      </Text>
    </Flex>
  );
};
