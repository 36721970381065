import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import { EventBlock } from "core/components/EventBlock";
import { EventType } from "core/constants/report-setup";
import { Icon } from "core/design-system/components/Icon";

interface IGraphInsightProps {
  bucketEvents: { name: string; type: EventType }[];
  text: string;
  iconName: any;
  indicatorText: string;
  indicatorColor: string;
}

const GraphInsight: React.FC<IGraphInsightProps> = ({
  bucketEvents,
  text,
  iconName,
  indicatorText,
}) => {
  if (!bucketEvents.length) return null;

  return (
    <Flex p={4} direction="column" bg="gray.50" borderRadius="lg">
      <Flex>
        <Text fontWeight="semibold" fontSize="sm">
          {indicatorText}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex direction="column" w="70%">
          <Text fontSize="sm" color="gray.700">
            {text}
          </Text>
        </Flex>
        <Flex direction="column">
          <Flex>
            <Icon iconType="illustration" h="80px" w="150px" name={iconName} />
          </Flex>
        </Flex>
      </Flex>
      <Flex mt={2} flexWrap="wrap">
        {bucketEvents.map(({ name, type }) => (
          <EventBlock
            key={name}
            name={name}
            mr={2}
            mb={1}
            color={type === EventType.PAGE ? "turquoise" : "primary"}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default GraphInsight;
