import { Accordion } from "core/design-system/components/Accordion";
import Alert from "core/design-system/components/Alert";
import Badge from "core/design-system/components/Badge";
import Button from "core/design-system/components/Button";
import Drawer from "core/design-system/components/Drawer";
import Input from "core/design-system/components/Input";
import Link from "core/design-system/components/Link";
import Menu from "core/design-system/components/Menu";
import Progress from "core/design-system/components/Progress";
import { Select } from "core/design-system/components/Select";
import Spinner from "core/design-system/components/Spinner";
import Switch from "core/design-system/components/Switch";
import Table from "core/design-system/components/Table";
import Tooltip from "core/design-system/components/Tooltip";

const Components = {
  Button,
  Tooltip,
  Spinner,
  Switch,
  Badge,
  Progress,
  Input,
  Link,
  Table,
  Drawer,
  Alert,
  Menu,
  Accordion,
  Select,
};

export default Components;
