import { Box, Flex, Text } from "@chakra-ui/react";

import { ICohortTable } from "core/components/CohortTable/CohortTable.types";
import { unitTerminology } from "core/components/CohortTable/helpers";
import { Cell } from "core/components/CohortTable/private/Cell";
import { ColumnHeader } from "core/components/CohortTable/private/ColumnHeader";

export const Table = ({
  unit,
  valueType,
  intervalType,
  hideWeekends,
  intervalCount,
  cohortData,
  setCohortStartDate,
  setCohortIntervalStartDate,
  setRetainedUsersCount,
  setDroppedUsersCount,
  previewOrSharingMode,
  onAudienceOpen,
}: ICohortTable) => {
  return (
    <Flex direction="column" overflowX="scroll">
      <ColumnHeader textAlign="center">
        Number of {intervalType}s later your {unitTerminology[unit].plural} were
        retained
      </ColumnHeader>
      <Flex pr={4}>
        <Flex direction="column" overflowX="scroll">
          <Flex py={2}>
            {intervalCount.map((startDate, index) => (
              <Flex
                key={index}
                w="70px"
                flexShrink={0}
                justify="center"
                align="center"
                bg="gray.50"
                borderRadius="md"
                mr={2}
              >
                <Text fontSize="sm" fontWeight="medium" color="gray.600">
                  {startDate}
                </Text>
              </Flex>
            ))}
          </Flex>
          <Box>
            {cohortData.map((row, rowIndex) => (
              <Flex flexShrink={0} key={rowIndex}>
                {row.data.map((rowData, index) => {
                  return (
                    <Cell
                      key={index}
                      unit={unit}
                      valueType={valueType}
                      rowData={rowData}
                      intervalType={intervalType}
                      hideWeekends={Boolean(hideWeekends && row?.isWeekend)}
                      cohortIntervalDate={row.startDate}
                      setCohortStartDate={setCohortStartDate}
                      setCohortIntervalStartDate={setCohortIntervalStartDate}
                      setRetainedUsersCount={setRetainedUsersCount}
                      setDroppedUsersCount={setDroppedUsersCount}
                      previewOrSharingMode={previewOrSharingMode}
                      onAudienceOpen={onAudienceOpen}
                    />
                  );
                })}
              </Flex>
            ))}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
