import { Avatar, Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

import UserActionsMenu from "core/components/Teams/UserActionsMenu";
import GoogleIcon from "core/design-system/components/Icon/Logos/GoogleIcon";
import { IAppUser } from "core/types/User";

interface ITeammateProps {
  member: IAppUser;
  mutateUserList: () => void;
  showActions?: boolean;
}

const emailUsername = (emailAddress: string) => emailAddress.split("@")[0];

export const Teammate: React.FC<ITeammateProps> = ({
  member,
  mutateUserList,
  showActions = true,
}) => {
  return (
    <Box
      _notLast={{ borderBottomColor: "gray.100", borderBottomWidth: "1px" }}
      _even={{ bg: "gray.50" }}
      p={3}
      _last={{ borderBottomLeftRadius: "md", borderBottomRightRadius: "md" }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex align="center">
          <Flex align="center" justify="flex-start" mr={2}>
            <Avatar
              size="sm"
              name={emailUsername(member.email)}
              src={member.imageUrl}
            />
          </Flex>
          <Text fontSize="sm" color="gray.900">
            {member.email}
          </Text>
          <Text fontSize="sm" color="gray.600" ml={2}>
            - {member.membershipState === 0 && <>Active</>}
            {member.membershipState === 1 && <>Invited</>}
            {member.membershipState === 2 && <>Inactive email</>}
          </Text>
        </Flex>
        <Flex align={"center"} justify={"flex-end"} gap={2}>
          {member.usesGoogleOauth && (
            <Tooltip label="This user is using Google Oauth">
              <GoogleIcon h={6} w={6} />
            </Tooltip>
          )}
          {showActions && (
            <UserActionsMenu member={member} mutateUserList={mutateUserList} />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Teammate;
