import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { TagIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { IColorPalette } from "core/design-system/constants/theme/colors";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useLabels } from "core/hooks/useLabels";
import {
  useAddReportLabelMutation,
  useRemoveReportLabelMutation,
} from "core/models/reports";
import { ILabel } from "core/types/Label";
import { ColorPicker, DEFAULT_COLORS } from "modules/Labels/ColorPicker";

interface ISelectLabelsProps {
  appId: number;
  reportId: number;
  labels: ILabel[];
  selectedLabels: ILabel[];
  setSelectedLabels: (labels: ILabel[]) => void;
}

export const SelectLabels: React.FC<ISelectLabelsProps> = ({
  appId,
  reportId,
  labels,
  selectedLabels,
  setSelectedLabels,
}) => {
  const currentApp = useCurrentApp();
  const [addReportLabel] = useAddReportLabelMutation();
  const [removeReportLabel] = useRemoveReportLabelMutation();
  const [labelsSearchQuery, setLabelsSearchQuery] = useState("");
  const { createLabel } = useLabels({ appId: currentApp.id });
  const [color, setColor] = useState<IColorPalette>(DEFAULT_COLORS[0]);

  const addLabel = async (label: ILabel) => {
    setSelectedLabels([...selectedLabels, label]);
    await addReportLabel({
      appId,
      reportId,
      labelId: label.id,
    });
  };

  const removeLabel = async (label: ILabel) => {
    setSelectedLabels(selectedLabels?.filter((l) => l.id !== label.id));
    await removeReportLabel({
      appId,
      reportId,
      labelId: label.id,
    });
  };

  const onCreateLabel = () => {
    setLabelsSearchQuery("");
    createLabel({
      appId: currentApp.id,
      name: labelsSearchQuery,
      color: color[500],
    });
  };

  const emptyLabels = labels.length === 0;

  const visibleLabels =
    labelsSearchQuery !== ""
      ? labels.filter((l) =>
          l.name.toLowerCase().includes(labelsSearchQuery.toLowerCase()),
        )
      : labels;

  return (
    <>
      <Menu closeOnSelect={false} closeOnBlur={true}>
        <Tooltip label="Add labels">
          <MenuButton ml={1} size={"sm"} as={Button} variant="ghost">
            <TagIcon className="h-4" />
          </MenuButton>
        </Tooltip>
        <MenuList zIndex={"popover"} bg="white" minWidth="250px" pt={0}>
          {emptyLabels ? (
            <Flex p={10} align={"center"} direction="column">
              <Text fontSize="sm">You have no labels</Text>
              <Link to={`/a/${currentApp.id}/settings/labels`}>
                <Text fontSize="xs" color="primary">
                  Create a label →
                </Text>
              </Link>
            </Flex>
          ) : (
            <>
              <Flex px={4} py={2} align="center" justify="space-between">
                <Text fontSize="xs" fontWeight="medium">
                  Labels
                </Text>
                <Link to={`/a/${currentApp.id}/settings/labels`}>
                  <Text fontSize="xs" color="primary">
                    Edit labels →
                  </Text>
                </Link>
              </Flex>
              <Divider />
              <Flex p={2}>
                <Input
                  borderRadius="lg"
                  placeholder="Search labels"
                  size="sm"
                  value={labelsSearchQuery}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setLabelsSearchQuery(e.currentTarget.value)
                  }
                />
              </Flex>
              <Box overflow="auto" maxHeight={"sm"}>
                {visibleLabels.map((label: ILabel) => (
                  <Box py={1.5} px={3} className="hover:bg-gray-100">
                    <Checkbox
                      w="100%"
                      colorScheme={"purple"}
                      key={label.name}
                      isChecked={selectedLabels?.some((l) => l.id === label.id)}
                      size="sm"
                      onChange={(e) => {
                        if (e.target.checked) {
                          addLabel(label);
                        } else {
                          removeLabel(label);
                        }
                      }}
                    >
                      <Flex align="center">
                        <Box
                          bg={label.color}
                          width={2}
                          height={2}
                          borderRadius="50%"
                        />
                        <Text ml={2} fontSize="sm" color="gray.700">
                          {label.name}
                        </Text>
                      </Flex>
                    </Checkbox>
                  </Box>
                ))}
              </Box>
              {visibleLabels.length === 0 && (
                <Flex justifyContent="center" w="full" px={2} gridGap={1}>
                  <ColorPicker color={color} setColor={setColor} />
                  <Button
                    ml={1}
                    onClick={onCreateLabel}
                    w="full"
                    colorScheme="purple"
                    variant="ghost"
                  >
                    Create new label
                  </Button>
                </Flex>
              )}
            </>
          )}
        </MenuList>
      </Menu>
    </>
  );
};
