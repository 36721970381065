import { IPowerUserData } from "core/modules/reports/report-types/PowerUsers/PowerUsers.types";

export const l7MockData: IPowerUserData = {
  date: "2021-01-25T00:00:00.000Z",
  powerUsers: [
    {
      days: 1,
      contactsPercentage: 54.1,
      contactsCount: 254,
    },
    {
      days: 2,
      contactsPercentage: 25,
      contactsCount: 186,
    },
    {
      days: 3,
      contactsPercentage: 12.5,
      contactsCount: 67,
    },
    {
      days: 4,
      contactsPercentage: 8.3,
      contactsCount: 54,
    },
    {
      days: 5,
      contactsPercentage: 0,
      contactsCount: 0,
    },
    {
      days: 6,
      contactsPercentage: 0,
      contactsCount: 0,
    },
    {
      days: 7,
      contactsPercentage: 0,
      contactsCount: 0,
    },
  ],
};

export const l30MockData: IPowerUserData = {
  date: "2021-01-25T00:00:00.000Z",
  powerUsers: [
    {
      days: 1,
      contactsPercentage: 17,
      contactsCount: 120,
    },
    {
      days: 2,
      contactsPercentage: 10,
      contactsCount: 54,
    },
    {
      days: 3,
      contactsPercentage: 7,
      contactsCount: 36,
    },
    {
      days: 4,
      contactsPercentage: 5,
      contactsCount: 28,
    },
    {
      days: 5,
      contactsPercentage: 4,
      contactsCount: 21,
    },
    {
      days: 6,
      contactsPercentage: 4,
      contactsCount: 21,
    },
    {
      days: 7,
      contactsPercentage: 3,
      contactsCount: 17,
    },
    {
      days: 8,
      contactsPercentage: 3,
      contactsCount: 17,
    },
    {
      days: 9,
      contactsPercentage: 3,
      contactsCount: 17,
    },
    {
      days: 10,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 11,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 12,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 13,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 14,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 15,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 16,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 17,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 18,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 19,
      contactsPercentage: 1,
      contactsCount: 3,
    },
    {
      days: 20,
      contactsPercentage: 1,
      contactsCount: 3,
    },
    {
      days: 21,
      contactsPercentage: 1,
      contactsCount: 3,
    },
    {
      days: 22,
      contactsPercentage: 1,
      contactsCount: 3,
    },
    {
      days: 23,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 24,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 25,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 26,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 27,
      contactsPercentage: 2,
      contactsCount: 12,
    },
    {
      days: 28,
      contactsPercentage: 3,
      contactsCount: 17,
    },
    {
      days: 29,
      contactsPercentage: 3,
      contactsCount: 17,
    },
    {
      days: 30,
      contactsPercentage: 4,
      contactsCount: 21,
    },
    {
      days: 31,
      contactsPercentage: 7,
      contactsCount: 18,
    },
  ],
};

export const PowerUsersWeekOnWeekMockData = {
  powerUsers: [
    {
      humanizedDate: "Apr 04 - Apr 10",
      weekStartDate: "2022-04-04",
      contactsCount: 14,
    },
    {
      humanizedDate: "Apr 11 - Apr 17",
      weekStartDate: "2022-04-11",
      contactsCount: 25,
    },
    {
      humanizedDate: "Apr 18 - Apr 24",
      weekStartDate: "2022-04-18",
      contactsCount: 12,
    },
    {
      humanizedDate: "Apr 25 - May 01",
      weekStartDate: "2022-04-25",
      contactsCount: 5,
    },
    {
      humanizedDate: "May 02 - May 08",
      weekStartDate: "2022-05-02",
      contactsCount: 4,
    },
    {
      humanizedDate: "May 09 - May 15",
      weekStartDate: "2022-05-09",
      contactsCount: 5,
    },
    {
      humanizedDate: "May 16 - May 22",
      weekStartDate: "2022-05-16",
      contactsCount: 4,
    },
    {
      humanizedDate: "May 23 - May 29",
      weekStartDate: "2022-05-23",
      contactsCount: 2,
    },
    {
      humanizedDate: "May 30 - Jun 05",
      weekStartDate: "2022-05-30",
      contactsCount: 2,
    },
    {
      humanizedDate: "Jun 06 - Jun 12",
      weekStartDate: "2022-06-06",
      contactsCount: 2,
    },
    {
      humanizedDate: "Jun 13 - Jun 19",
      weekStartDate: "2022-06-13",
      contactsCount: 1,
    },
    {
      humanizedDate: "Jun 20 - Jun 26",
      weekStartDate: "2022-06-20",
      contactsCount: 4,
    },
    {
      humanizedDate: "Jun 27 - Jul 03",
      weekStartDate: "2022-06-27",
      contactsCount: 1,
    },
  ],
};
