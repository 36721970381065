import React from "react";

import { Button } from "@/Components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/Components/ui/dropdown-menu";
import { RemoveTeammateModal } from "@/core/components/Teams/RemoveTeammateModal";
import { Icon } from "@/core/design-system/components/Icon";
import { useCurrentApp } from "@/core/hooks/useCurrentApp";
import { useDisclosure } from "@/core/hooks/useDisclosure";
import { useGetUsersQuery } from "@/core/models/users";
import { IAppUser } from "@/core/types/User";

interface IUserActionsMenuProps {
  member: IAppUser;
}

const UserActionsMenu: React.FC<IUserActionsMenuProps> = ({ member }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id: appId } = useCurrentApp();
  const { data: teamMembers = [] } = useGetUsersQuery({ appId });

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <Icon iconType="icon" name="more" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[250px]">
          <DropdownMenuItem onClick={onOpen}>Suspend account</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <RemoveTeammateModal
        member={member}
        isOpen={isOpen}
        onClose={onClose}
        teamMembers={teamMembers}
      />
    </>
  );
};

export default UserActionsMenu;
