import { Navigate, Outlet } from "react-router-dom";

import { PageContainer } from "core/components/PageContainer";
import { DEMO_APP_ID } from "core/constants/appIds";
import { useCurrentApp } from "core/hooks/useCurrentApp";

export const Integrations: React.FC = () => {
  const { id: appId } = useCurrentApp();

  if (Number(appId) === DEMO_APP_ID) {
    return <Navigate to={`/a/${appId}/home`} />;
  }

  return (
    <PageContainer>
      <Outlet />
    </PageContainer>
  );
};
