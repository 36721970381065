import { createIcon } from "@chakra-ui/icons";

export const CompanyJuneLogoTalkingIcon = createIcon({
  path: (
    <svg
      width="47"
      height="48"
      viewBox="0 0 47 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.6498 11.1279C37.0409 7.69723 33.7477 5.40651 30.2943 6.01143L16.6267 8.40553C14.9683 8.69602 13.494 9.62909 12.5281 10.9994L7.30455 18.4104C6.33867 19.7807 5.96032 21.4761 6.25274 23.1236L8.66271 36.7012C9.27163 40.1319 12.5648 42.4226 16.0182 41.8177L29.6858 39.4236C31.3442 39.1331 32.8185 38.2 33.7844 36.8297L39.0079 29.4187C39.9738 28.0484 40.3522 26.353 40.0598 24.7055L37.6498 11.1279ZM37.5743 25.232C37.9396 27.2904 36.5561 29.2533 34.484 29.6162L20.7275 32.0259C18.6555 32.3888 16.6796 31.0144 16.3142 28.956L13.8886 15.2901C13.5233 13.2317 14.9068 11.2688 16.9788 10.9058L30.7353 8.49615C32.8074 8.1332 34.7833 9.50763 35.1486 11.566L37.5743 25.232Z"
        fill="#6868F7"
      />
      <path
        d="M19.4755 18.5627C19.2429 18.6037 19.0173 18.5476 18.7985 18.3944C18.5796 18.2412 18.4494 18.0462 18.4076 17.8095C18.3659 17.5727 18.4223 17.3491 18.5771 17.1388L19.7837 15.4156C19.8794 15.2788 19.9813 15.1769 20.0894 15.1099C20.1975 15.0429 20.3197 14.9974 20.4561 14.9733C20.5925 14.9493 20.7245 14.95 20.8521 14.9755C20.9796 15.0009 21.1118 15.0615 21.2486 15.1573L22.9718 16.3639C23.1906 16.5171 23.3205 16.71 23.3615 16.9425C23.4025 17.175 23.3464 17.4007 23.1932 17.6195C23.0399 17.8383 22.845 17.9686 22.6082 18.0104C22.3714 18.0521 22.1479 17.9956 21.9375 17.8409L20.8298 17.0652L20.0541 18.173C19.9009 18.3918 19.708 18.5217 19.4755 18.5627Z"
        fill="#6868F7"
      />
      <path
        d="M28.7817 16.9218C28.545 16.9636 28.3214 16.9071 28.111 16.7524C27.8934 16.6058 27.7635 16.4129 27.7213 16.1737C27.6791 15.9346 27.7353 15.7089 27.8896 15.4967L29.0962 13.7735C29.192 13.6368 29.295 13.5347 29.4052 13.4673C29.5153 13.3999 29.6381 13.3543 29.7733 13.3305C29.9027 13.3076 30.0322 13.3088 30.1617 13.3339C30.2912 13.359 30.4244 13.4195 30.5611 13.5152L32.2843 14.7218C32.5032 14.875 32.6334 15.07 32.6752 15.3068C32.7169 15.5435 32.6605 15.7671 32.5057 15.9775C32.3592 16.1951 32.1663 16.325 31.9271 16.3672C31.688 16.4094 31.4623 16.3532 31.2501 16.1989L30.1423 15.4232L29.3667 16.531C29.2135 16.7498 29.0185 16.8801 28.7817 16.9218Z"
        fill="#6868F7"
      />
      <path
        d="M31.0016 25.701C29.938 26.5358 28.7436 27.0701 27.4182 27.3038C26.0928 27.5375 24.7934 27.4429 23.5201 27.0201C22.2468 26.5973 21.1746 25.8873 20.3035 24.8901C20.0682 24.6439 20.0012 24.4039 20.1023 24.1703C20.2035 23.9367 20.4151 23.7915 20.7372 23.7347L32.4711 21.6657C32.7932 21.6089 33.0483 21.6718 33.2363 21.8545C33.4244 22.0371 33.45 22.2843 33.3132 22.5961C32.8357 23.8311 32.0651 24.8661 31.0016 25.701Z"
        fill="#6868F7"
      />
    </svg>
  ),
  viewBox: "0 0 47 48",
});
