import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  UseToastOptions,
} from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { BanknotesIcon, CalendarIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";

import { LastSyncStatus } from "core/components/Stripe/LastSyncStatus";
import { StripeAudience } from "core/components/Stripe/StripeAudience";
import { TraitOption } from "core/components/Traits/TraitOption";
import { FilterType } from "core/constants/filters";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useToast } from "core/hooks/useToast";
import { useGetAppObjectsQuery } from "core/models/appObjects";
import {
  useGetStripeAppsQuery,
  useUpdateStripeAppMutation,
} from "core/models/stripeApps";
import { useGetTraitsQuery } from "core/models/traits";
import { AudienceCountPreview } from "core/modules/reports/setup/Audience/CountPreview";
import { AppObjectType } from "core/types";
import { IAudienceFilters, JoinOperatorValue } from "core/types/Audience";
import { ComparisonType } from "core/types/Filters";

const STRIPE_TRAITS = [
  {
    icon: <BanknotesIcon className="h-4 w-4 text-gray-500" />,
    name: "stripe_total_spend",
    value: "$1,333",
  },
  {
    icon: <BanknotesIcon className="h-4 w-4 text-gray-500" />,
    name: "stripe_last_payment_amount",
    value: "$233",
  },
  {
    icon: <CalendarIcon className="h-4 w-4 text-gray-500" />,
    name: "stripe_last_payment_date",
    value: "1 Feb 2025",
  },
  {
    icon: <BanknotesIcon className="h-4 w-4 text-gray-500" />,
    name: "stripe_number_of_payments",
    value: "12",
  },
];

export const Settings = () => {
  const { id: appId } = useCurrentApp();
  const { data: stripeApps } = useGetStripeAppsQuery({ appId });
  const { data: appObjects } = useGetAppObjectsQuery({ appId });
  const [updateStripeApp] = useUpdateStripeAppMutation();
  const toast = useToast();

  const stripeApp = stripeApps?.stripeApps[0];
  const selectedAppObject = appObjects?.find(
    (obj) => obj.id === stripeApp?.appObjectId,
  );

  const { data: traits } = useGetTraitsQuery({
    appId,
    filterType:
      selectedAppObject?.slug === "user"
        ? FilterType.UserTrait
        : FilterType.GroupFilter,
    isActive: true,
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredTraits, setFilteredTraits] = useState(traits);
  const [audienceFilters, setAudienceFilters] = useState<IAudienceFilters>({
    filterGroups: stripeApp?.sourceTrait
      ? [
          {
            filters: [
              {
                type:
                  selectedAppObject?.slug === "user"
                    ? FilterType.UserTrait
                    : FilterType.GroupFilter,
                body: {
                  trait: stripeApp?.sourceTrait,
                  value: "",
                  comparison_type: ComparisonType.HAS_ANY_VALUE,
                },
              },
            ],
            joinOperator: JoinOperatorValue.AND,
          },
        ]
      : [],
    joinOperator: JoinOperatorValue.AND,
  });

  useEffect(() => {
    if (searchQuery) {
      const results = traits?.filter(({ trait }) =>
        trait.toLowerCase().includes(searchQuery),
      );
      setFilteredTraits(results);
    } else {
      setFilteredTraits(traits);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, traits]);

  if (!stripeApp) return null;

  const successToast: UseToastOptions = {
    status: "success",
    title: "Stripe app updated successfully",
    description: "The updated settings will take effect on the next sync",
  };

  const handleAppObjectChange = async (appObjectId: number) => {
    await updateStripeApp({
      id: stripeApp.id!,
      appId,
      appObjectId,
    })
      .unwrap()
      .then(() => {
        toast(successToast);
      });
  };

  const handleSourceTraitChange = async (trait: string) => {
    setAudienceFilters({
      filterGroups: [
        {
          filters: [
            {
              type:
                selectedAppObject?.slug === "user"
                  ? FilterType.UserTrait
                  : FilterType.GroupFilter,
              body: {
                trait,
                value: "",
                comparison_type: ComparisonType.HAS_ANY_VALUE,
              },
            },
          ],
          joinOperator: JoinOperatorValue.AND,
        },
      ],
      joinOperator: JoinOperatorValue.AND,
    });

    await updateStripeApp({
      id: stripeApp.id!,
      appId,
      sourceTrait: trait,
    })
      .unwrap()
      .then(() => {
        toast(successToast);
      });
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center gap-2">
        <LastSyncStatus />
        <div className="h-4 w-px bg-gray-200" />
        <StripeAudience />
      </div>

      <Divider my={4} />

      <div className="flex flex-col gap-3">
        <div>
          <h3 className="text-base font-semibold leading-7 text-black">
            General
          </h3>
        </div>
      </div>

      <div className="flex items-center justify-between pb-4 pt-2 text-sm">
        <div className="flex flex-col gap-1">
          <div>Select object to sync</div>
          <div className="text-gray-600">
            Choose which object type to sync with Stripe
          </div>
        </div>
        <div>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              bg="gray.50"
              border="none"
            >
              {selectedAppObject?.pluralName || "Choose an object"}
            </MenuButton>
            <MenuList>
              {appObjects
                ?.filter((obj) => obj.objectType !== AppObjectType.Company)
                .map((obj) => (
                  <MenuItem
                    key={obj.id}
                    onClick={() => handleAppObjectChange(obj.id)}
                    fontSize="sm"
                  >
                    {obj.pluralName}
                  </MenuItem>
                ))}
            </MenuList>
          </Menu>
        </div>
      </div>

      <Divider mt={2} mb={4} />

      <div className="flex items-center justify-between pb-4 pt-2 text-sm">
        <div className="flex flex-col gap-1">
          <div>Select matching trait</div>
          <div className="text-gray-600">
            Which trait contains the Stripe customer ID?
          </div>
        </div>
        <div className="flex flex-col items-end gap-2">
          <Popover className="relative">
            <PopoverButton className="max-w-full">
              <Button
                variant="ghost"
                size="sm"
                borderRadius="lg"
                bg="gray.50"
                color={stripeApp.sourceTrait ? "black" : "gray.600"}
                maxW="full"
                minW="88px"
              >
                <div className="flex w-full items-center justify-center gap-1">
                  <p className="truncate">
                    {stripeApp.sourceTrait || "Choose a trait"}
                  </p>
                </div>
              </Button>
            </PopoverButton>
            <PopoverPanel className="flex flex-col">
              {({ close }) => (
                <>
                  <Lifecycle onUnmount={() => {}} />
                  <Command>
                    <CommandInput
                      placeholder="Search traits..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <CommandList>
                      {filteredTraits?.map((trait) => (
                        <CommandOption
                          key={trait.trait}
                          value={() => {
                            handleSourceTraitChange(trait.trait);
                            close();
                          }}
                        >
                          <TraitOption trait={trait} />
                        </CommandOption>
                      ))}
                    </CommandList>
                  </Command>
                </>
              )}
            </PopoverPanel>
          </Popover>

          {audienceFilters.filterGroups.length > 0 && (
            <AudienceCountPreview
              audienceFilters={audienceFilters}
              appObject={selectedAppObject}
              showAvatars={false}
            />
          )}
        </div>
      </div>

      <Divider mt={2} mb={4} />

      <div className="flex flex-col gap-3">
        <div>
          <h3 className="text-base font-semibold leading-7 text-black">
            Sync data
          </h3>
        </div>
      </div>

      <div className="flex flex-col pb-4 pt-2 text-sm">
        <div className="flex flex-col gap-1">
          <div>Traits that will be created</div>
          <div className="text-gray-600">
            The following traits will be created and updated automatically
          </div>
        </div>
        <div className="flex items-start justify-between gap-10">
          <div className="mt-4 flex w-2/3 flex-col">
            <Divider />
            {STRIPE_TRAITS.map((trait) => (
              <>
                <div className="flex items-center gap-2 py-4" key={trait.name}>
                  <div>{trait.icon}</div>
                  <div>{trait.name}</div>
                </div>
                <Divider />
              </>
            ))}
          </div>

          <div className="flex w-1/3 flex-col gap-3 rounded-lg border bg-gray-50 p-4">
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <div className="font-medium">Preview</div>
              </div>

              <div className="flex flex-col gap-3 rounded-lg border bg-white">
                <div className="flex items-center border-b border-gray-200 p-4">
                  <img
                    src="https://www.google.com/favicon.ico"
                    alt="Google logo"
                    className="mr-2 h-10 w-10"
                  />
                  <p className="text-lg font-semibold">Google</p>
                </div>

                <div className="flex flex-col gap-2 px-4 pb-4">
                  <p className="font-medium">Stripe</p>

                  <div className="flex flex-col gap-2">
                    {STRIPE_TRAITS.map((trait) => (
                      <>
                        <Divider />
                        <div className="flex items-center justify-between">
                          <div
                            className="flex items-center gap-2"
                            key={trait.name}
                          >
                            <div>{trait.icon}</div>
                            <div>{trait.name}</div>
                          </div>
                          <div>{trait.value}</div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
