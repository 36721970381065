import { motion } from "framer-motion";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetAudiencesQuery } from "core/models/audiences";
import { IAudience } from "core/types/Audience";
import { ItemContainer } from "modules/Navigation/Sidebar/ItemContainer";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";

export const Audiences = () => {
  const currentApp = useCurrentApp();
  const { data, isLoading } = useGetAudiencesQuery({
    appId: Number(currentApp.id),
    pinned: true,
  });
  const pathArray = window.location.pathname.split(`/`);
  const audienceId = pathArray.includes("audience")
    ? pathArray[pathArray.indexOf("audience") + 1]
    : "";

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col gap-1">
          <div className="mx-3 flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
            <div className="h-full w-full rounded-md bg-gray-200"></div>
          </div>
          <div className="mx-3 flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
            <div className="h-full w-full rounded-md bg-gray-300"></div>
          </div>
          <div className="mx-3 flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
            <div className="h-full w-full rounded-md bg-gray-200"></div>
          </div>
        </div>
      ) : (
        <motion.div
          animate={{ opacity: 1, height: "auto" }}
          initial={{ opacity: 0, height: 0 }}
          exit={{ opacity: 0, height: 0 }}
          className="flex flex-col gap-1"
        >
          <ItemContainer
            isCurrent={window.location.href.includes(`/people`)}
            to={`/a/${currentApp.id}/people`}
            className="group relative flex justify-between px-2"
          >
            <div className="-ml-1 flex items-center">
              <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                <ItemText className="max-w-[210px] text-ellipsis">All</ItemText>
              </div>
            </div>
          </ItemContainer>
          {data?.audiences?.map((audience: IAudience) => {
            return (
              <ItemContainer
                key={audience.id}
                isCurrent={String(audienceId) === String(audience.id)}
                to={`/a/${currentApp.id}/audience/${audience.id}`}
                className="group relative flex justify-between px-2"
              >
                <div className="-ml-1 flex items-center">
                  <div className="ml-0.5 line-clamp-1 max-w-[280px]">
                    <ItemText className="max-w-[280px] text-ellipsis">
                      {audience.name}
                    </ItemText>
                  </div>
                </div>
              </ItemContainer>
            );
          })}
          <ItemContainer
            to={`/a/${currentApp.id}/audiences`}
            className="group relative flex justify-between px-2"
          >
            <div className="-ml-1 flex items-center">
              <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                <p className="text-[13px] text-gray-600">View all</p>
              </div>
            </div>
          </ItemContainer>
        </motion.div>
      )}
    </>
  );
};
