import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import { SnakeCasedPropertiesDeep } from "type-fest";

import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { baseQuery } from "core/initializers/rtk";
import { IAudienceFilters } from "core/types/Audience";
import { IEventLog } from "core/types/EventLog";
import { IPagy } from "core/types/Pagy";

interface GetEventLogsArgs {
  appId: number;
  page: number;
  audienceFilters?: IAudienceFilters;
  contactId?: string;
  groupId?: string;
  groupType?: GroupType;
  eventName?: string;
  query?: string;
}

interface GetEventLogsResponse {
  eventLogs: IEventLog[];
  pagy: IPagy;
}

export const eventLogsApi = createApi({
  baseQuery,
  reducerPath: "eventLogsApi",
  tagTypes: ["EventLog"],
  endpoints: (builder) => ({
    getEventLogs: builder.query<GetEventLogsResponse, GetEventLogsArgs>({
      query: ({
        appId,
        page,
        contactId,
        groupId,
        groupType,
        eventName,
        audienceFilters,
        query,
      }) => {
        return {
          url: "/event_logs",
          params: {
            app_id: appId,
            page,
            query,
            contact_id: contactId,
            group_id: groupId,
            group_type: groupType,
            event_name: eventName,
            filter_groups: JSON.stringify(
              humps.decamelizeKeys(audienceFilters?.filterGroups || []),
            ),
            join_operator: audienceFilters?.joinOperator,
          },
        };
      },
      providesTags: ["EventLog"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<GetEventLogsResponse>,
      ): GetEventLogsResponse =>
        humps.camelizeKeys(response) as GetEventLogsResponse,
    }),
  }),
});

export const { useGetEventLogsQuery } = eventLogsApi;
