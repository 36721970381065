import { Box, Button, Spinner, Text } from "@chakra-ui/react";
import {
  AdjustmentsHorizontalIcon,
  BoltIcon,
  StarIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Navigate, useParams } from "react-router-dom";

import * as Integration from "@/modules/Settings/Integrations/Integration";
import UninstallButton from "@/modules/Settings/Integrations/Slack/UninstallButton";
import { SLACK_REDIRECT_URL_KEY } from "core/constants/integrations";
import { LogoType } from "core/design-system/components/Icon/Icon.types";
import useRequest from "core/hooks/useRequest";
import { EnabledAlerts as EnabledSlackAlerts } from "modules/Settings/Slack/EnabledAlerts";

const CONTENT: Integration.IntegrationMetadata = {
  title: "Slack",
  description: "Get notifications and alerts from your reports",
  logo: "companySlackLogo" as LogoType,
  docsUrl:
    "https://help.june.so/en/articles/4803772-receive-a-report-digest-in-slack",
};

export const Slack: React.FC = () => {
  const { appId } = useParams();
  const {
    data: slackApp,
    isLoading,
    mutate,
  } = useRequest({
    url: `/slack_apps`,
    params: { appId },
    withCredentials: true,
  });
  const onConnectSlack = () => {
    localStorage.setItem(
      SLACK_REDIRECT_URL_KEY,
      `/a/${appId}/integrations/slack`,
    );
    window.location.replace(`${import.meta.env.VITE_API_HOST}/auth/slack`);
  };

  if (window.location.pathname.includes("settings/integrations/slack")) {
    return <Navigate to={`/a/${appId}/integrations/slack`} />;
  }

  const isConnected = slackApp?.length > 0;

  return (
    <>
      <Integration.Container isExpanded>
        <Integration.Header
          metadata={CONTENT}
          ActionComponent={() =>
            isConnected ? (
              <UninstallButton mutate={mutate} appId={appId} />
            ) : (
              <Button onClick={onConnectSlack} colorScheme="purple">
                Connect Slack
              </Button>
            )
          }
        />
        <Integration.Body>
          <Integration.Section
            title={"Slack settings"}
            description={"Configure how do you send alerts to your Slack"}
          >
            {isLoading ? (
              <Spinner />
            ) : isConnected ? (
              <EnabledSlackAlerts />
            ) : (
              <div className="flex items-center gap-2">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-row gap-4">
                    <div className="flex">
                      <BoltIcon className="h-7 w-7 text-purple-500" />
                    </div>
                    <div className="flex flex-col">
                      <Text fontSize="sm" fontWeight="bold">
                        Instant notifications
                      </Text>
                      <Text fontSize="sm" fontWeight="sm" color="gray.600">
                        Get instant alerts when a user does something in your
                        product
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex">
                      <StarIcon className="h-7 w-7 text-purple-500" />
                    </div>
                    <div className="flex flex-col">
                      <Text fontSize="sm" fontWeight="bold">
                        Celebrate when milestones are hit
                      </Text>
                      <Text fontSize="sm" fontWeight="sm" color="gray.600">
                        Get notified when a user signs up or converts to paid.
                        The possibilities are endless.
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex">
                      <AdjustmentsHorizontalIcon className="h-7 w-7 text-purple-500" />
                    </div>
                    <div className="flex flex-col">
                      <Text fontSize="sm" fontWeight="bold">
                        Stay in control when you get alerted
                      </Text>
                      <Text fontSize="sm" fontWeight="sm" color="gray.600">
                        Control when to receive alerts. Choose from daily,
                        weekly, monthly or simply in real time.
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex">
                      <UserGroupIcon className="h-7 w-7 text-purple-500" />
                    </div>
                    <div className="flex flex-col">
                      <Text fontSize="sm" fontWeight="bold">
                        Rally your team behind your most important metrics
                      </Text>
                      <Text fontSize="sm" fontWeight="sm" color="gray.600">
                        Get everyone up to speed with what's happening inside
                        your company.
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="flex w-full items-center justify-center">
                    <Box
                      borderRadius="lg"
                      h="400px"
                      w="100%"
                      bgImage={`url('/slack_illustration.png')`}
                      bgRepeat="no-repeat"
                      bgPosition="center"
                      bgSize="contain"
                    />
                  </div>
                </div>
              </div>
            )}
          </Integration.Section>
        </Integration.Body>
      </Integration.Container>
    </>
  );
};
