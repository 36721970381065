import { Text } from "@chakra-ui/react";

import { IOption } from "core/types/Option";
import { ITemplateConfigSetupSection } from "core/types/TemplateConfig";

interface IEnumPillProps {
  section: ITemplateConfigSetupSection;
  option: IOption;
}

const EnumPill: React.FC<IEnumPillProps> = ({ section, option }) => {
  if (!section || !section.options) return null;

  const selectedOption = section.options.find(
    (_option) => option.value === _option.value,
  );
  return (
    <Text
      bg="purple.50"
      color="purple.500"
      fontSize="sm"
      fontWeight="medium"
      px={3}
      py={1}
      borderRadius={4}
    >
      {selectedOption && selectedOption.name}
    </Text>
  );
};

export default EnumPill;
