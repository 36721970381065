import { IListConfig } from "core/components/List/List.types";
import { LabelActionCell } from "modules/Labels/List/LabelActionCell";
import { LabelNameCell } from "modules/Labels/List/LabelNameCell";

export const config: IListConfig = {
  resourceName: "labels",
  columns: [
    {
      title: "Label",
      name: "label",
      width: 300,
      align: "left",
      Component: LabelNameCell,
    },
    {
      title: "Actions",
      name: "actions",
      width: 100,
      align: "right",
      Component: LabelActionCell,
    },
  ],
};
