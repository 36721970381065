import { useEffect, useState } from "react";

import { useViews, ViewLocation } from "core/hooks/useViews";
import { IView } from "core/models/views";

export const useCompanyView = () => {
  const [view, setView] = useState<IView | null>();
  const { views, isLoadingViews } = useViews({
    location: ViewLocation.Company,
    pinned: false,
  });
  useEffect(() => {
    if (views && views?.length > 0) {
      setView(views[0]);
    }
  }, [views]);

  return {
    view,
    isLoadingView: isLoadingViews,
  };
};
