import { Button, Flex, Spinner, UseDisclosureReturn } from "@chakra-ui/react";
import React from "react";

import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { Drilldown } from "core/components/Drilldown";
import { Error } from "core/components/Error";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import { IMostActiveUser } from "core/modules/reports/report-types/ActiveUsers/MostActiveUsersContainer";
import { IReport } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";
import { pluralize } from "helpers/pluralize";

interface IMostActiveUsersListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  report: IReport;
  csvExportLoading: boolean;
  requestCsvExport: () => void;
  insight: IInsightSection;
  intervalDays?: number | undefined;
}

export const MostActiveUsersList: React.FC<IMostActiveUsersListProps> = ({
  report,
  isOpen,
  onClose,
  csvExportLoading,
  requestCsvExport,
  insight,
  intervalDays,
}) => {
  const { contacts, isLoading, error, hasMoreContacts, loadMore } =
    useContactDrilldown({
      report,
      insightType: 8,
      skip: !isOpen,
      insightParams: {
        intervalDays,
      },
    });
  const mostActiveUsers = contacts as IMostActiveUser[];

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title title={insight.title} isLoading={isLoading} />
            <Drilldown.DownloadButton
              isDisabled={mostActiveUsers.length <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
          </Drilldown.Header>
          <Drilldown.Body>
            {mostActiveUsers.map((contact) => (
              <UserListSingleContact
                key={contact.userId}
                id={contact.userId}
                traits={contact.traits}
                description={`Used ${contact.eventCount} ${pluralize(contact.eventCount, "time", "times")} ${
                  contact?.numberOfActiveDays === 1
                    ? `for ${contact?.numberOfActiveDays} day`
                    : `over ${contact?.numberOfActiveDays} days`
                }`}
              />
            ))}
            {hasMoreContacts && (
              <Flex justify="center">
                <Button isDisabled={isLoading} onClick={loadMore}>
                  {isLoading ? <Spinner /> : "Load more"}
                </Button>
              </Flex>
            )}
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
