import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { TraitIcon } from "core/components/Traits/TraitIcon";
import { ComputationType } from "core/constants/traits";
import { AccountScoreCard } from "modules/Groups/Group/OverviewDemo/AccountScoreCard";
import { FeatureTableDemo } from "modules/Groups/Group/OverviewDemo/FeatureTableDemo";
import { OpenConversations } from "modules/Groups/Group/OverviewDemo/OpenConversations";
import { OpenTickets } from "modules/Groups/Group/OverviewDemo/OpenTickets";

interface OverviewProps {
  group: any; // TODO: Add proper type
}

export const Overview: React.FC<OverviewProps> = ({ group }) => {
  return (
    <div className="flex flex-col">
      <Grid
        h="full"
        templateRows="repeat(5, 1fr)"
        templateColumns="repeat(4, 1fr)"
        gap={4}
      >
        <GridItem rowSpan={5} colSpan={1}>
          <AccountScoreCard />
        </GridItem>
        <GridItem rowSpan={5} colSpan={1}>
          <OpenConversations />
        </GridItem>
        <GridItem rowSpan={5} colSpan={1}>
          <OpenTickets />
        </GridItem>
        <GridItem rowSpan={5} colSpan={1}>
          <Card
            shadow="none"
            border="1px solid"
            borderColor="gray.200"
            h="full"
            p="0"
          >
            <CardHeader pt={4} pb={2} fontWeight="semibold">
              <div className="flex w-full items-center justify-between">
                <p>Properties</p>
                <Button size="sm" variant="ghost">
                  Edit
                </Button>
              </div>
            </CardHeader>
            <CardBody pt={2} pb={4}>
              <div className="mb-4 grid grid-cols-1">
                <input
                  id="trait"
                  name="trait"
                  type="text"
                  placeholder="Search trait..."
                  className="col-start-1 row-start-1 block w-full rounded-md bg-white py-1 pl-10 pr-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:pl-9 sm:text-sm/6"
                />
                <MagnifyingGlassIcon
                  aria-hidden="true"
                  className="pointer-events-none col-start-1 row-start-1 ml-3 size-4 self-center text-gray-400 sm:size-4"
                />
              </div>
              <div className="flex w-full flex-col gap-3">
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-2">
                    <TraitIcon
                      traitName="Name"
                      computationType={ComputationType.Boolean}
                    />
                    <p className="text-sm font-medium">Name</p>
                  </div>
                  <p className="text-sm text-gray-500">
                    {group?.name || "Unknown"}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-2">
                    <TraitIcon
                      traitName="Domain"
                      computationType={ComputationType.Boolean}
                    />
                    <p className="text-sm font-medium">Domain</p>
                  </div>
                  <p className="text-sm text-gray-500">
                    {group?.domain || "Unknown"}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-2">
                    <TraitIcon
                      traitName="Domain"
                      computationType={ComputationType.UserCount}
                    />
                    <p className="text-sm font-medium">Total seats</p>
                  </div>
                  <p className="text-sm text-gray-500">
                    {group?.totalSeats || 0}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-2">
                    <TraitIcon
                      traitName="Domain"
                      computationType={ComputationType.UserCount}
                    />
                    <p className="text-sm font-medium">Active seats</p>
                  </div>
                  <p className="text-sm text-gray-500">
                    {group?.activeSeats || 0}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-2">
                    <TraitIcon
                      traitName="Domain"
                      computationType={ComputationType.EventCountChange}
                    />
                    <p className="text-sm font-medium">Usage</p>
                  </div>
                  <p className="text-sm text-gray-500">
                    {group?.usage || "0%"}
                  </p>
                </div>
              </div>
              <p className="mt-4 cursor-pointer text-sm font-medium text-gray-500">
                + 25 more
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem rowSpan={5} colSpan={2}>
          <FeatureTableDemo />
        </GridItem>
      </Grid>
    </div>
  );
};
