import { Navigate, useParams } from "react-router-dom";

import { DEMO_APP_ID } from "core/constants/appIds";
import { BookCall } from "modules/Onboarding/BookCall";

export const Schedule = () => {
  const { appId } = useParams<{ appId?: string }>();

  if (Number(appId) === DEMO_APP_ID) {
    return <Navigate to={`/a/${appId}/home`} />;
  }

  return <BookCall />;
};
