import { createIcon } from "@chakra-ui/icons";
import React from "react";

export const SlackIconDisabled = createIcon({
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 14.2504V14.2504C21 14.9964 20.396 15.6004 19.65 15.6004H15.15C14.404 15.6004 13.8 14.9964 13.8 14.2504V14.2504C13.8 13.5044 14.404 12.9004 15.15 12.9004H19.65C20.396 12.9004 21 13.5044 21 14.2504Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8 18.3008H15.15C15.896 18.3008 16.5 18.9048 16.5 19.6508V19.6508C16.5 20.3968 15.896 21.0008 15.15 21.0008V21.0008C14.404 21.0008 13.8 20.3968 13.8 19.6508V18.3008Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 3V3C14.996 3 15.6 3.604 15.6 4.35V8.85C15.6 9.596 14.996 10.2 14.25 10.2V10.2C13.504 10.2 12.9 9.596 12.9 8.85V4.35C12.9 3.604 13.504 3 14.25 3Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3 10.2V8.85C18.3 8.104 18.904 7.5 19.65 7.5V7.5C20.396 7.5 21 8.104 21 8.85V8.85C21 9.596 20.396 10.2 19.65 10.2H18.3Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9.75039V9.75039C3 9.00439 3.604 8.40039 4.35 8.40039H8.85C9.596 8.40039 10.2 9.00439 10.2 9.75039V9.75039C10.2 10.4964 9.596 11.1004 8.85 11.1004H4.35C3.604 11.1004 3 10.4964 3 9.75039Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2 5.7H8.85C8.104 5.7 7.5 5.096 7.5 4.35C7.5 3.604 8.104 3 8.85 3C9.596 3 10.2 3.604 10.2 4.35V5.7Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 21.0008V21.0008C9.004 21.0008 8.4 20.3968 8.4 19.6508V15.1508C8.4 14.4048 9.004 13.8008 9.75 13.8008V13.8008C10.496 13.8008 11.1 14.4048 11.1 15.1508V19.6508C11.1 20.3968 10.496 21.0008 9.75 21.0008Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.7 13.8008V15.1508C5.7 15.8968 5.096 16.5008 4.35 16.5008C3.604 16.5008 3 15.8968 3 15.1508C3 14.4048 3.604 13.8008 4.35 13.8008H5.7Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
