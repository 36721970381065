import { Switch, Tooltip } from "@chakra-ui/react";
import { useState } from "react";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useToast } from "core/hooks/useToast";
import { useUserAuth } from "core/hooks/useUserAuth";
import { useWeeklyDigestOptInMutation } from "core/models/users";

export const WeeklyDigestOptIn: React.FC = () => {
  const toast = useToast();
  const app = useCurrentApp();
  const { currentUser } = useUserAuth();
  const [weeklyDigestOptedIn, setWeeklyDigestOptedIn] = useState<
    boolean | undefined
  >(currentUser?.weeklyDigestEnabled);
  const [weeklyDigestOptIn] = useWeeklyDigestOptInMutation();

  const toggleDigest = () => {
    const newWeeklyDigestOptedInState = !weeklyDigestOptedIn;
    setWeeklyDigestOptedIn(newWeeklyDigestOptedInState);
    weeklyDigestOptIn({
      appId: app.id,
      weeklyDigestOptedIn: newWeeklyDigestOptedInState,
    })
      .then(() => {
        if (newWeeklyDigestOptedInState) {
          toast({
            title: "You've been opted in",
            status: "success",
            description:
              "You'll now receive weekly digest of most active users every Monday.",
          });
        } else {
          toast({
            title: "You've been opted out",
            status: "success",
            description: "You'll no longer receive weekly digest.",
          });
        }
      })
      .catch(() => {
        toast({
          title: "Uh oh! something went wrong on our side",
          description:
            "Please try again. If it still doesn't work, reach out to us on support",
          status: "error",
        });
        setWeeklyDigestOptedIn(weeklyDigestOptedIn);
      });
  };

  return (
    <div className="align-center flex w-full justify-between rounded-lg">
      <div className="flex flex-col">
        <div className="mb-1 text-sm font-semibold text-gray-900">
          Weekly digest opt-in
        </div>
        <div className="text-sm text-gray-600">
          Receive digest of most active users and companies every Monday
        </div>
      </div>
      <div className="pr-[37px]">
        <Tooltip
          label="The digest is disabled for your workspace."
          placement="top"
          isDisabled={app.weeklyDigestEnabled}
        >
          <Switch
            data-testid="weekly-digest-opt-in"
            isChecked={weeklyDigestOptedIn}
            onChange={toggleDigest}
            isDisabled={!app.weeklyDigestEnabled}
            size="sm"
            className="ml-1"
            colorScheme="purple"
          />
        </Tooltip>
      </div>
    </div>
  );
};
