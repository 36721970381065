import { Card, CardBody, CardHeader } from "@chakra-ui/react";

import AnimatedToggle from "core/components/AnimatedToggle";
import { AudienceTable } from "core/components/ViewInsight/Graphs/AudienceTable";
import { IViewInsight } from "core/models/viewInsights";
import { cx } from "helpers/cx";
import { GRID_TO_PX } from "modules/ViewInsight/Builder/LayoutContainer";

export const PreviewContainer: React.FC<{
  viewInsight: IViewInsight;
  onUpdateInsight: (update: Partial<IViewInsight>) => void;
}> = ({ viewInsight, onUpdateInsight }) => {
  return (
    <div className="relative flex h-full w-full flex-grow flex-col">
      <div className="flex w-full flex-grow flex-col justify-center overflow-auto rounded-lg bg-gradient-to-b from-gray-50 to-gray-100 p-5">
        <div
          className={cx(
            "min-h-[315px] pb-10",
            viewInsight.gridHeight !== undefined &&
              `h-[${GRID_TO_PX[viewInsight.gridHeight]}]`,
          )}
        >
          <Card className="h-full">
            <CardHeader pb={0}>
              <p className="text-lg font-semibold">
                {viewInsight.audience?.name}
                {` `}
                {viewInsight.appObject?.pluralName.toLowerCase()}
              </p>
            </CardHeader>
            <CardBody pt={0}>
              <AudienceTable viewInsight={viewInsight} />
            </CardBody>
          </Card>
          <div className="flex w-full justify-center">
            <div className="absolute bottom-4">
              <AnimatedToggle
                items={[
                  {
                    label: "Small",
                    value: "1",
                  },
                  {
                    label: "Medium",
                    value: "2",
                  },
                  {
                    label: "Large",
                    value: "3",
                  },
                ]}
                value={viewInsight.gridHeight?.toString() || "1"}
                containerProps={{
                  bg: "gray.200",
                }}
                onChange={(val, e) => {
                  e.preventDefault();
                  onUpdateInsight({ gridHeight: parseInt(val) });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
