import React, { useEffect, useState } from "react";

import { LineContainer } from "core/components/LineContainer";
import SelectMenu from "core/components/Select";
import {
  DAU_OPTIONS,
  INTERVAL_TYPES,
  MAU_OPTIONS,
  WAU_OPTIONS,
} from "core/constants/timerange";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useQueryParam } from "core/hooks/useQueryParam";
import { weeklyUsageMockData } from "core/modules/reports/report-types/FeatureRelease/mockData";
import LineChart from "core/modules/reports/report-types/FeatureRelease/Usage/UsageLineChart";
import { isUserReportLevel } from "core/modules/reports/utils";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

// @ts-nocheck

const TIME_RANGE_PICKER_OPTIONS = {
  daily: {
    label: "Daily",
    value: "daily",
    intervalPeriod: "ONE_DAY",
    initialTimeRange: "SEVEN_DAYS",
    timeRangeOptions: DAU_OPTIONS,
    intervalType: INTERVAL_TYPES.DAY,
  },
  weekly: {
    label: "Weekly",
    value: "weekly",
    intervalPeriod: "SEVEN_DAYS",
    initialTimeRange: "THREE_MONTHS",
    timeRangeOptions: WAU_OPTIONS,
    intervalType: INTERVAL_TYPES.WEEK,
  },
  monthly: {
    label: "Monthly",
    value: "monthly",
    intervalPeriod: "THIRTY_DAYS",
    initialTimeRange: "THREE_MONTHS",
    timeRangeOptions: MAU_OPTIONS,
    intervalType: INTERVAL_TYPES.MONTH,
  },
};

const IntervalPicker: React.FC<{
  usageInterval: "daily" | "weekly" | "monthly";
  setUsageInterval: void;
}> = ({ usageInterval, setUsageInterval }) => {
  const label = Object.values(TIME_RANGE_PICKER_OPTIONS).find(
    (o) => o.value === usageInterval,
  ).label;
  return (
    <SelectMenu>
      {(Select) => (
        <Select.Container>
          <Select.Button w="140px" mx={2}>
            {label}
          </Select.Button>
          <Select.Body w="140px">
            {Object.values(TIME_RANGE_PICKER_OPTIONS).map((o) => {
              return (
                <Select.Item
                  key={o.value}
                  value={o}
                  isSelected={usageInterval === o.value}
                  onClick={(o) => setUsageInterval(o.value)}
                >
                  {o.label}
                </Select.Item>
              );
            })}
          </Select.Body>
        </Select.Container>
      )}
    </SelectMenu>
  );
};

export const FeatureUsageContainer: React.FC<IReportInsight> = ({
  config,
  report,
  reportLevel,
  ...props
}) => {
  const [usageInterval, setUsageInterval] = useState("weekly");
  const isUserReport = isUserReportLevel(reportLevel);
  const insightIdQueryParam = useQueryParam("insightId");
  const unit = isUserReport ? "user" : "company";
  const insight = config.insights.find((i: IInsightSection) => {
    if (insightIdQueryParam) return i.typeId === Number(insightIdQueryParam);
    return i.slug === `${usageInterval}-${unit}-usage`;
  });
  const { activeAppObject } = useAppObjects();

  useEffect(() => {
    if (insightIdQueryParam === undefined || !insight) return;

    if (insight?.slug.includes("daily")) setUsageInterval("daily");
    if (insight?.slug.includes("weekly")) setUsageInterval("weekly");
    if (insight?.slug.includes("monthly")) setUsageInterval("monthly");
  }, [insightIdQueryParam, insight]);

  return (
    <LineContainer
      key={usageInterval}
      LineChart={LineChart}
      report={report}
      insight={insight}
      initialTimeRange={
        TIME_RANGE_PICKER_OPTIONS[usageInterval].initialTimeRange
      }
      timeRangeOptions={
        TIME_RANGE_PICKER_OPTIONS[usageInterval].timeRangeOptions
      }
      intervalType={TIME_RANGE_PICKER_OPTIONS[usageInterval].intervalType}
      keyAccessor={"usageInTimeRange"}
      intervalPeriod={TIME_RANGE_PICKER_OPTIONS[usageInterval].intervalPeriod}
      mockData={weeklyUsageMockData}
      config={config}
      actions={
        <IntervalPicker
          usageInterval={usageInterval}
          setUsageInterval={setUsageInterval}
        />
      }
      unit={isUserReport ? "users" : activeAppObject?.pluralName.toLowerCase()}
      {...props}
    />
  );
};
