import { ComponentDefaultProps } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import { cx } from "helpers/cx";

interface ItemContainerProps extends ComponentDefaultProps {
  children: React.ReactNode;
  className?: string;
  to?: string;
  onClick?: () => void;
  isCurrent?: boolean;
  isNew?: boolean;
}

export const ItemContainer: React.FC<ItemContainerProps> = ({
  children,
  className,
  to,
  onClick,
  isCurrent,
  isNew,
  ...props
}) => {
  return to ? (
    <Link
      to={to}
      onClick={onClick}
      className={cx(
        "mx-3 flex items-center gap-2 rounded-md px-1.5 py-[2px] hover:bg-gray-200",
        className,
        isCurrent && "bg-gray-200",
      )}
      {...props}
    >
      {children}
      {isNew && (
        <span className="text-xs font-semibold text-blue-500">New</span>
      )}
    </Link>
  ) : (
    <div
      onClick={onClick}
      className={cx(
        "mx-3 flex cursor-pointer items-center gap-2 rounded-md px-1.5 py-[2px] hover:bg-gray-200",
        className,
        isCurrent && "bg-gray-200",
      )}
      {...props}
    >
      {children}
      {isNew && (
        <span className="text-xs font-semibold text-blue-500">New</span>
      )}
    </div>
  );
};
