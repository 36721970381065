import { Flex, Switch, Tooltip } from "@chakra-ui/react";

import { useToast } from "core/hooks/useToast";
import { useUpdateEventMutation } from "core/models/events";

export const ActivityStatus: React.FC<{
  props: {
    id: number;
    name: string;
    appId: number;
    isActive: boolean;
  };
}> = ({ props }) => {
  const { id, appId, name, isActive } = props;
  const [updateEvent, { isLoading: isUpdatingEvent }] =
    useUpdateEventMutation();
  const toast = useToast();

  async function handleChange() {
    await updateEvent({
      appId,
      eventId: id,
      isActive: !isActive,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Event activity updated",
          description: `You have successfully updated event ${name} to ${
            !isActive ? "automated" : "user triggered"
          }`,
          duration: 5000,
        });
      });
  }

  return (
    <Tooltip
      hasArrow
      placement="right"
      label={`Mark this event as ${isActive ? "automated" : "user triggered"}`}
    >
      <Flex align="center" justifyContent="flex-end">
        <Switch
          onChange={handleChange}
          isDisabled={isUpdatingEvent}
          colorScheme="purple"
          isChecked={isActive}
        />
      </Flex>
    </Tooltip>
  );
};
