import { Flex, Text, Tooltip } from "@chakra-ui/react";
import moment from "moment";
import React from "react";

const SentAtDateCell = ({
  props: { timestamp },
}: {
  props: { timestamp: string };
}) => {
  return (
    <Tooltip
      label={moment.utc(timestamp).format("MMMM Do, YYYY [at] h:mm:ss a")}
    >
      <Flex minW="120px" maxW="120px">
        <Text noOfLines={1} fontSize="sm" color="gray.600">
          {`${moment.utc(timestamp).fromNow()}`}
        </Text>
      </Flex>
    </Tooltip>
  );
};

export default SentAtDateCell;
