import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";

import SelectMenu from "core/components/Select";
import {
  COMPARISON_TO_LABEL,
  EXACTLY,
  LESS_THAN,
  MORE_THAN,
} from "core/constants/eventFilterComponents";
import { uuid } from "core/helpers/uuid";
import { WrapArrow } from "core/modules/reports/setup/Editor/WrapArrow";
import { IEvent } from "core/types/Event";

export const OCCURRENCES_TYPE = 6;

interface IFrequencySelectProps {
  event: IEvent;
  setFrequencyFilterEventId: (frequencyFilterEventId: string) => void;
  onUpdateEventFrequency: (event: IEvent) => void;
  onRemoveEventFrequency: (event: IEvent) => void;
}

export const FrequencySelectMenu: React.FC<IFrequencySelectProps> = ({
  event,
  setFrequencyFilterEventId,
  onUpdateEventFrequency,
  onRemoveEventFrequency,
}) => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [frequency, setFrequency] = useState<string>(
    event.occurrences?.body?.times || "1",
  );
  const [comparison, setComparison] = useState<number>(
    event.occurrences?.body?.comparisonType || MORE_THAN,
  );

  function onFrequencyChange(value: string) {
    if (value === "") {
      setFrequency("");
    } else {
      setFrequency(String(value));
    }
  }

  function onComparisonChange(value: number) {
    setComparison(Number(value));
  }

  return (
    <SelectMenu>
      {(Select) => (
        <Select.Container
          isOpen={isFilterMenuOpen}
          onOpen={() => setIsFilterMenuOpen(true)}
          onClose={() => setIsFilterMenuOpen(false)}
          returnFocusOnClose={false}
        >
          <div className="relative flex items-center">
            <WrapArrow direction="left" />
            <Select.Button
              data-testid="frequency-select-menu-button"
              rightIcon={undefined}
              border="none"
              borderRadius={0}
              bg="gray.50"
              _hover={{ bg: "gray.100" }}
              color="gray.900"
              pl={5}
              pr={0}
              showChevron={false}
            >
              {event?.properties?.length ? "and" : ""} is triggered{" "}
              {COMPARISON_TO_LABEL[comparison]}{" "}
              <Text as="span" fontWeight="bold">
                {frequency}
              </Text>{" "}
              times
              <Tooltip placement="top" label="Remove frequency filter" hasArrow>
                <CloseIcon
                  data-testid="frequency-select-menu-remove-button"
                  bg="gray.50"
                  color="gray.900"
                  onClick={() => {
                    onRemoveEventFrequency(event);
                    setFrequencyFilterEventId("");
                  }}
                  w={2}
                  h={2}
                  mx={3}
                />
              </Tooltip>
            </Select.Button>
          </div>
          <Select.Body borderColor="gray.50" boxShadow="md" w="180px" p={2}>
            <RadioGroup
              value={comparison}
              onChange={(nextValue: string) =>
                onComparisonChange(Number(nextValue))
              }
            >
              <Stack p={3}>
                <Radio
                  data-testid={`frequency-select-menu-option-${EXACTLY}`}
                  role="radio"
                  _focus={{ boxShadow: "none" }}
                  colorScheme="purple"
                  value={EXACTLY}
                >
                  <Text
                    fontSize="xs"
                    textTransform="uppercase"
                    fontWeight="medium"
                    color="gray.600"
                  >
                    is
                  </Text>
                </Radio>
                {comparison === EXACTLY && (
                  <Input
                    data-testid={`frequency-select-menu-option-${EXACTLY}`}
                    role="radio"
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      onFrequencyChange(e.currentTarget.value)
                    }
                    value={frequency}
                    min="0"
                    type="number"
                    size="sm"
                  />
                )}
                <Radio
                  data-testid={`frequency-select-menu-option-${MORE_THAN}`}
                  role="radio"
                  _focus={{ boxShadow: "none" }}
                  colorScheme="purple"
                  value={MORE_THAN}
                >
                  <Text
                    fontSize="xs"
                    textTransform="uppercase"
                    fontWeight="medium"
                    color="gray.600"
                  >
                    more than
                  </Text>
                </Radio>
                {comparison === MORE_THAN && (
                  <Input
                    data-testid={`frequency-select-menu-option-${MORE_THAN}`}
                    role="radio"
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      onFrequencyChange(e.currentTarget.value)
                    }
                    min="0"
                    value={frequency}
                    type="number"
                    size="sm"
                  />
                )}
                <Radio
                  data-testid={`frequency-select-menu-option-${LESS_THAN}`}
                  role="radio"
                  _focus={{ boxShadow: "none" }}
                  colorScheme="purple"
                  value={LESS_THAN}
                >
                  <Text
                    fontSize="xs"
                    textTransform="uppercase"
                    fontWeight="medium"
                    color="gray.600"
                  >
                    less than
                  </Text>
                </Radio>
                {comparison === LESS_THAN && (
                  <Input
                    data-testid={`frequency-select-menu-option-${LESS_THAN}`}
                    role="radio"
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      onFrequencyChange(e.currentTarget.value)
                    }
                    min="0"
                    value={frequency}
                    type="number"
                    size="sm"
                  />
                )}
              </Stack>
            </RadioGroup>
            <Button
              data-testid="frequency-select-menu-done-button"
              w="full"
              onClick={() => {
                onUpdateEventFrequency({
                  ...event,
                  occurrences: {
                    body: {
                      uuid: uuid(),
                      comparisonType: comparison,
                      times: frequency,
                    },
                    type: OCCURRENCES_TYPE,
                    adding: false,
                  },
                });
                setIsFilterMenuOpen(false);
              }}
            >
              Done
            </Button>
          </Select.Body>
        </Select.Container>
      )}
    </SelectMenu>
  );
};
