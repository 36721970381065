import { IPowerGroupData } from "core/modules/reports/report-types/PowerGroups/PowerGroups.types";

export const l7MockData: IPowerGroupData = {
  date: "2021-01-25T00:00:00.000Z",
  powerGroups: [
    {
      days: 1,
      groupsPercentage: 54.1,
      groupsCount: 254,
    },
    {
      days: 2,
      groupsPercentage: 25,
      groupsCount: 186,
    },
    {
      days: 3,
      groupsPercentage: 12.5,
      groupsCount: 67,
    },
    {
      days: 4,
      groupsPercentage: 8.3,
      groupsCount: 54,
    },
    {
      days: 5,
      groupsPercentage: 0,
      groupsCount: 0,
    },
    {
      days: 6,
      groupsPercentage: 0,
      groupsCount: 0,
    },
    {
      days: 7,
      groupsPercentage: 0,
      groupsCount: 0,
    },
  ],
};

export const l30MockData: IPowerGroupData = {
  date: "2021-01-25T00:00:00.000Z",
  powerGroups: [
    {
      days: 1,
      groupsPercentage: 17,
      groupsCount: 120,
    },
    {
      days: 2,
      groupsPercentage: 10,
      groupsCount: 54,
    },
    {
      days: 3,
      groupsPercentage: 7,
      groupsCount: 36,
    },
    {
      days: 4,
      groupsPercentage: 5,
      groupsCount: 28,
    },
    {
      days: 5,
      groupsPercentage: 4,
      groupsCount: 21,
    },
    {
      days: 6,
      groupsPercentage: 4,
      groupsCount: 21,
    },
    {
      days: 7,
      groupsPercentage: 3,
      groupsCount: 17,
    },
    {
      days: 8,
      groupsPercentage: 3,
      groupsCount: 17,
    },
    {
      days: 9,
      groupsPercentage: 3,
      groupsCount: 17,
    },
    {
      days: 10,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 11,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 12,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 13,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 14,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 15,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 16,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 17,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 18,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 19,
      groupsPercentage: 1,
      groupsCount: 3,
    },
    {
      days: 20,
      groupsPercentage: 1,
      groupsCount: 3,
    },
    {
      days: 21,
      groupsPercentage: 1,
      groupsCount: 3,
    },
    {
      days: 22,
      groupsPercentage: 1,
      groupsCount: 3,
    },
    {
      days: 23,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 24,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 25,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 26,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 27,
      groupsPercentage: 2,
      groupsCount: 12,
    },
    {
      days: 28,
      groupsPercentage: 3,
      groupsCount: 17,
    },
    {
      days: 29,
      groupsPercentage: 3,
      groupsCount: 17,
    },
    {
      days: 30,
      groupsPercentage: 4,
      groupsCount: 21,
    },
    {
      days: 31,
      groupsPercentage: 7,
      groupsCount: 18,
    },
  ],
};
