import moment from "moment";

import {
  ABSOLUTE,
  CUSTOM_DATE,
  DATE_RANGE_OPTIONS_FROM_VALUE,
  RELATIVE,
} from "core/constants/timerange";

export const getReadableTimerange = ({ startTimestamp, endTimestamp }) => {
  const timerangeStartTimestamp = moment(startTimestamp)
    .startOf("day")
    .format("YYYY-MM-DDTHH:mm:ss");
  const timerangeEndTimestamp = moment(endTimestamp)
    .endOf("day")
    .format("YYYY-MM-DDTHH:mm:ss");

  return `${moment(timerangeStartTimestamp).format("ll")} - ${moment(timerangeEndTimestamp).format("ll")}`;
};

export const getInitialTimerange = ({ timerangeType, timerangeValue }) => {
  if (timerangeType === ABSOLUTE) return CUSTOM_DATE;
  if (timerangeType === RELATIVE)
    return DATE_RANGE_OPTIONS_FROM_VALUE[timerangeValue];
};
