import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ErrorPage } from "@/Components/Error/Page";
import { Loader } from "Components/Loader";
import { DownloadableGraphImage } from "core/components/DownloadableGraphImage";
import { INSIGHT_CONFIGS } from "core/constants/report-configurations";
import { getReportConfig } from "core/helpers/getReportConfig";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useGetAppObjectsQuery } from "core/models/appObjects";
import { useLazyGetReportQuery } from "core/models/reports";
import { getAppObject } from "core/modules/appObjects/utils";
import PublicInsight from "core/modules/reports/PublicInsight";
import { getLevelParam } from "core/modules/reports/utils";
import { IReport } from "core/types/Report";

const PublicInsightRoute = () => {
  const { appId, reportId, insightSlug, sharingSecretToken } = useParams<{
    [key: string]: string;
  }>() as {
    appId: string;
    reportId: string;
    insightSlug: string;
    sharingSecretToken: string;
  };
  const insightId = useQueryParam("insight_id");
  const isScreenshotMode = useQueryParam("screenshot_mode") === "true";

  // We want the insight fetching to be backwards compatible with the old insight slugs
  // See this customer workflow for more information: https://github.com/juneHQ/june/issues/11039
  // app_id: 8371 is affected by this issue
  const backwardsCompatibleInsightId =
    insightSlug === "exploration" && insightId === "0" ? 2 : Number(insightId);

  const { data } = useGetAppObjectsQuery({
    appId: Number(appId),
    reportId: Number(reportId),
    insightType: Number(backwardsCompatibleInsightId),
    sharingSecretToken,
  });
  const { appObjects, setAppObjects, setActiveAppObject } = useAppObjects();

  useEffect(() => {
    if (data) setAppObjects(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const level = getLevelParam();
  const appObject = getAppObject(appObjects, level);
  if (appObject) setActiveAppObject(appObject);

  const insight = INSIGHT_CONFIGS.find(
    ({ slug, slackAlertAvailable, shareable, typeId }) => {
      if (insightId !== "" && insightId !== null) {
        return (
          slug === insightSlug &&
          typeId === Number(backwardsCompatibleInsightId) &&
          (slackAlertAvailable || shareable)
        );
      } else {
        return slug === insightSlug && (slackAlertAvailable || shareable);
      }
    },
  );

  const insightType = insight?.typeId;
  const [report, setReport] = useState<IReport>();
  const [getReport, { data: response, error, isLoading }] =
    useLazyGetReportQuery();

  useEffect(() => {
    if (typeof backwardsCompatibleInsightId !== "undefined") {
      getReport({
        appId: parseInt(appId, 10),
        reportId: parseInt(reportId, 10),
        insightType: backwardsCompatibleInsightId,
        sharingSecretToken,
      });
    }
  }, [
    backwardsCompatibleInsightId,
    appId,
    reportId,
    sharingSecretToken,
    getReport,
  ]);

  useEffect(() => {
    if (response) {
      setReport(response);
    }
  }, [response]);

  if (typeof insightType === "undefined") {
    return (
      <ErrorPage
        title="You don't have permissions to view this report"
        action={{
          text: "Log in",
          href: `${import.meta.env.VITE_APP_HOST}/login`,
        }}
      />
    );
  }

  if (isLoading) return <Loader />;

  if (
    error ||
    !report ||
    typeof report?.reportType === "undefined" ||
    !insight
  ) {
    return (
      <ErrorPage
        title="You don't have permissions to view this report"
        action={{
          text: "Log in",
          href: `${import.meta.env.VITE_APP_HOST}/login`,
        }}
      />
    );
  }

  // @ts-ignore
  if (window.Intercom) {
    // @ts-ignore
    window.Intercom("update", {
      hide_default_launcher: true,
    });
  }

  const Section = insight.section;

  if (isScreenshotMode && Section) {
    return (
      <PublicInsight>
        <DownloadableGraphImage
          report={report}
          config={getReportConfig(report.reportType)!}
          insight={insight}
          sharingSecretToken={sharingSecretToken}
          showYAxis
          showInProgress
          screenshotMode
        />
      </PublicInsight>
    );
  }

  if (Section) {
    return (
      <PublicInsight>
        <Section
          report={report}
          appId={appId}
          config={getReportConfig(report.reportType)!}
          reportId={report.id}
          sharingMode={true}
          sharingSecretToken={sharingSecretToken}
          showYAxis
        />
      </PublicInsight>
    );
  }

  return <ErrorPage />;
};

export default PublicInsightRoute;
