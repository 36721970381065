import { ALL_REPORT_CONFIGURATIONS } from "core/constants/report-configurations";
import { Template } from "core/constants/templates";
import { ITemplateConfig } from "core/types/TemplateConfig";

const FEATURE_FLAG_TEMPLATES = ["control-center", "funnel-test"];

export const filterTemplatesByFeatureFlag = ({
  collection,
  features,
}: {
  collection: ITemplateConfig[];
  features: string[];
}) => {
  return collection.filter((template) => {
    if (template.route === "funnel-test") {
      return false;
    }
    if (FEATURE_FLAG_TEMPLATES.includes(template.route)) {
      return features.includes(template.route);
    }

    return true;
  });
};

export const filterTemplatesByTitle = ({
  collection,
  query,
}: {
  collection: ITemplateConfig[];
  query: string;
}) => {
  return collection.filter((template) =>
    template.title.toLowerCase().includes(query.toLowerCase()),
  );
};

// New templates modal
export const getTemplateImage = (template: Template) => {
  switch (template) {
    case Template.Funnel:
      return "/templates/template-funnel.svg";
    case Template.FeatureAudit:
      return "/templates/template-feature-audit.svg";
    case Template.FeatureReport:
      return "/templates/template-feature-report.svg";
    case Template.Explorer:
      return "/templates/template-explorer.svg";
    case Template.Activation:
      return "/templates/template-activation.svg";
    case Template.ActiveUsers:
      return "/templates/template-active-users.svg";
    case Template.NewSignups:
      return "/templates/template-new-signups.svg";
    case Template.SlippingAway:
      return "/templates/template-slipping-away.svg";
    case Template.Engagement:
      return "/templates/template-engagement.svg";
    case Template.PowerUsers:
      return "/templates/template-power-users.svg";
    case Template.Retention:
      return "/templates/template-retention.svg";
    default:
      return "/templates/template-funnel.svg";
  }
};

export const getTemplateConfig = (template: Template, features: string[]) => {
  const [configuration] = ALL_REPORT_CONFIGURATIONS.filter((template) => {
    if (FEATURE_FLAG_TEMPLATES.includes(template.route)) {
      return features.includes(template.route);
    }
    return true;
  }).filter((config) => config.template === template);

  return configuration;
};
