import { useParams } from "react-router-dom";

import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import {
  InfoBody,
  InfoCard,
  InfoHeader,
} from "core/design-system/components/InfoCard";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetAppObjectQuery } from "core/models/appObjects";
import DefaultHeader from "modules/Pages/DefaultHeader";
import AppObjectTraits from "modules/Settings/Objects/Object/AppObjectTraits";

export const ArchivedTraits: React.FC = () => {
  const currentApp = useCurrentApp();
  const { objectId } = useParams();
  const { data: appObject, isLoading } = useGetAppObjectQuery({
    appId: currentApp.id,
    id: Number(objectId),
  });

  const section = {
    name: "Deleted",
    title: "Deleted traits",
  };

  const pages = [
    { name: "Objects", path: "../objects", current: false },
    {
      name: appObject?.pluralName ?? "Object",
      path: `../objects/${objectId}`,
      current: false,
    },
    {
      name: "Traits",
      path: `../objects/${objectId}/traits`,
      current: false,
    },
    {
      name: "Deleted Traits",
      path: "#",
      current: true,
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex w-[985px] flex-col">
      <div className="flex flex-col gap-4">
        <Breadcrumbs pages={pages} />
        <DefaultHeader w="inherit" section={section} />
      </div>
      <div className="flex flex-col gap-4">
        <InfoCard>
          <InfoHeader>Deleted traits</InfoHeader>
          <InfoBody>
            Once a trait is deleted, it is no longer visible in your workspace
            or synced to your CRM. To get the trait back, simply start tracking
            it again through your SDK. You will have to create the mapping again
            to sync to your CRM.
          </InfoBody>
        </InfoCard>
      </div>
      <div className="mt-4 flex flex-col gap-4">
        {appObject && (
          <AppObjectTraits appObject={appObject} isActive={false} />
        )}
      </div>
    </div>
  );
};
