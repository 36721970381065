import { isTraitNotAvailable } from "core/design-system/charts/Breakdown/utils";
import { IBarItem } from "core/types/BarChart";
import { IBreakdown } from "core/types/Breakdown";

export interface IBreakdownTooltip {
  traitCount?: number;
  traitConversionPercentage?: number;
  name?: string;
  value?: string;
}

export function useFunnelBreakdown(
  item: IBarItem,
  barItems: IBarItem[],
): {
  breakdownTooltip?: IBreakdownTooltip[];
  totalBreakdownCount: number;
  firstConvertedCount: number;
  firstOtherCount: number;
  breakdownWithEmptyValue?: IBreakdown;
} {
  const firstConvertedCount = barItems[0]?.conversion?.count;
  const breakdownWithEmptyValue = item?.conversion?.breakdown?.find((b) =>
    isTraitNotAvailable(b?.traitValue),
  );

  const breakdownTooltip = item?.conversion?.breakdown?.reduce(
    (tooltipInfo: IBreakdownTooltip[], b: IBreakdown) => {
      tooltipInfo.push({
        traitCount: b?.convertedCount,
        traitConversionPercentage: b?.conversionPercentage,
        name: b?.trait,
        value: b?.traitValue,
      });
      return tooltipInfo;
    },
    [],
  );

  const totalBreakdownCount =
    item?.conversion?.breakdown?.reduce(
      (total: number, breakdownItem: IBreakdown) => {
        if (!isTraitNotAvailable(breakdownItem.traitValue)) {
          return total + (breakdownItem?.convertedCount || 0);
        }
        return total;
      },
      0,
    ) || 0;

  const firstTotalBreakdownCount =
    barItems[0]?.conversion?.breakdown?.reduce(
      (total: number, breakdownItem: IBreakdown) => {
        if (!isTraitNotAvailable(breakdownItem.traitValue)) {
          return total + (breakdownItem?.convertedCount || 0);
        }
        return total;
      },
      0,
    ) || 0;
  const firstOtherCount =
    barItems[0]?.conversion?.count - firstTotalBreakdownCount;

  return {
    breakdownTooltip,
    totalBreakdownCount,
    firstConvertedCount,
    firstOtherCount,
    breakdownWithEmptyValue,
  };
}
