import { Issue } from "core/helpers/event-validation/issue";
import { validateGroup } from "core/helpers/event-validation/validate-group";
import { validateIdentify } from "core/helpers/event-validation/validate-identify";
import { validateTrack } from "core/helpers/event-validation/validate-track";
import { LogType } from "modules/Developers/Logs/Log";

export const isDefinedString = (value: any) => {
  return value !== null && typeof value === "string" && value.length > 0;
};

export const isId = (value: any) => {
  return (
    typeof value !== "undefined" &&
    value !== null &&
    (isDefinedString || typeof value === "number")
  );
};

export const isEmptyObject = (obj: any) => {
  if (obj === null || obj === undefined) {
    return true;
  }
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export function getIssues(type: LogType, payload: any): Issue[] {
  switch (type) {
    case LogType.Track:
      return validateTrack(payload);
    case LogType.Identify:
      return validateIdentify(payload);
    case LogType.Group:
      return validateGroup(payload);
    default:
      return [];
  }
}
