import { ReactFormExtendedApi } from "@tanstack/react-form";
import { Fragment } from "react";

import { Button } from "Components/ui/button";

interface FormState {
  slackChannelId: string;
  frequency: number;
  insightTimerange?: number;
  state: number;
  preferredUtcHour: number;
}

interface ISlackViewInsightCTAProps {
  isLoading: boolean;
  isLoadingProp: boolean;
  form: ReactFormExtendedApi<FormState, undefined>;
  alertState: string;
  hasSlackAppInstalled: boolean;
  onConnectSlack: () => void;
  onSave: () => void;
  onUpdate: () => void;
  onPause: () => void;
}

export const SlackViewInsightCTA = ({
  isLoading,
  isLoadingProp,
  form,
  alertState,
  hasSlackAppInstalled,
  onConnectSlack,
  onSave,
  onUpdate,
  onPause,
}: ISlackViewInsightCTAProps): JSX.Element | null => {
  if (!hasSlackAppInstalled) {
    return (
      <Button
        disabled={isLoading || isLoadingProp}
        onClick={onConnectSlack}
        className="bg-green-600 text-white hover:bg-green-700"
      >
        Connect Slack
      </Button>
    );
  }

  return (
    <Fragment>
      {form.Subscribe({
        selector: (state) => ({
          slackChannelId: state.values.slackChannelId,
          canSubmit: state.canSubmit,
          isSubmitting: state.isSubmitting,
        }),
        children: ({ slackChannelId, canSubmit, isSubmitting }) => {
          const isButtonDisabled =
            isLoading ||
            isLoadingProp ||
            !slackChannelId ||
            !canSubmit ||
            isSubmitting;

          if (alertState === "NEW" || alertState === "PAUSED") {
            return (
              <Button
                disabled={isButtonDisabled}
                onClick={onSave}
                className="bg-green-600 text-white hover:bg-green-700"
              >
                {isSubmitting ? "Setting live..." : "Set live"}
              </Button>
            );
          }

          if (alertState === "MODIFIED") {
            return (
              <Button
                disabled={isButtonDisabled}
                onClick={onUpdate}
                className="bg-green-600 text-white hover:bg-green-700"
              >
                {isSubmitting ? "Updating..." : "Update"}
              </Button>
            );
          }

          if (alertState === "ACTIVE") {
            return (
              <Button
                disabled={isLoading || isLoadingProp || isSubmitting}
                onClick={onPause}
                variant="outline"
              >
                {isSubmitting ? "Pausing..." : "Pause"}
              </Button>
            );
          }

          return null;
        },
      })}
    </Fragment>
  );
};
