import { Spinner } from "@chakra-ui/react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { AnimatePresence, motion } from "framer-motion";
import { XIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/Components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useFavourites } from "core/hooks/useFavourites";
import { useSidebarCollapse } from "core/hooks/useSidebarCollapse";
import {
  FavouriteObjectType,
  FavouriteRoute,
  IFavourite,
} from "core/models/favourites";
import { Item } from "modules/Navigation/Sidebar/Favourites/Item";
import { ItemContainer } from "modules/Navigation/Sidebar/ItemContainer";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";

export const Favourites: React.FC<{ objectTypes: FavouriteObjectType[] }> = ({
  objectTypes,
}) => {
  const currentApp = useCurrentApp();
  const { favourites, onDeleteFavourite, isDeleting } = useFavourites(
    currentApp.id,
    objectTypes,
  );
  const navigate = useNavigate();

  const { isFavouritesCollapsed, toggleFavouritesCollapse } =
    useSidebarCollapse();

  return (
    <Disclosure defaultOpen={!isFavouritesCollapsed}>
      {({ open }) => (
        <>
          {favourites && favourites.length > 0 && (
            <Disclosure.Button
              onClick={toggleFavouritesCollapse}
              className="group flex w-full items-center justify-between px-4 text-left font-medium text-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
            >
              <span className="text-xs font-medium">My favourites</span>
              <motion.div animate={{ rotate: open ? 0 : -90 }}>
                <ChevronDownIcon className="h-4 w-4  text-gray-500 group-hover:block group-hover:text-gray-500" />
              </motion.div>
            </Disclosure.Button>
          )}
          <AnimatePresence>
            <Disclosure.Panel className="text-sm text-gray-500">
              <motion.div
                animate={{ opacity: 1, height: "auto" }}
                initial={{ opacity: 0, height: 0 }}
                exit={{ opacity: 0, height: 0 }}
                className="flex flex-col gap-1"
              >
                {favourites?.map((favourite: IFavourite) => {
                  return (
                    <ItemContainer
                      key={favourite.id}
                      isCurrent={window.location.href.includes(
                        `/a/${currentApp.id}/${FavouriteRoute[favourite.objectType]}/${favourite.objectId}`,
                      )}
                      onClick={() =>
                        navigate(
                          `/a/${currentApp.id}/${FavouriteRoute[favourite.objectType]}/${favourite.objectId}`,
                        )
                      }
                      className="group relative flex justify-between pr-0"
                    >
                      <div className="-ml-1 flex w-full items-center">
                        <div className="ml-0.5 line-clamp-1 w-full max-w-[300px]">
                          <ItemText className="flex w-full max-w-[300px] items-center justify-between text-ellipsis">
                            <div className="max-w-[220px]">
                              <Item favourite={favourite} />
                            </div>
                            <div className="hidden items-center group-hover:flex">
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  {isDeleting ? (
                                    <Spinner size="xs" />
                                  ) : (
                                    <Button
                                      variant="unstyled"
                                      size="unset"
                                      className="p-0.5 text-gray-900 [&_svg]:size-3"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onDeleteFavourite(favourite);
                                      }}
                                    >
                                      <XIcon />
                                    </Button>
                                  )}
                                </TooltipTrigger>
                                <TooltipContent>
                                  Remove from my favourites
                                </TooltipContent>
                              </Tooltip>
                            </div>
                          </ItemText>
                        </div>
                      </div>
                    </ItemContainer>
                  );
                })}
              </motion.div>
            </Disclosure.Panel>
          </AnimatePresence>
        </>
      )}
    </Disclosure>
  );
};
