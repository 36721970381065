import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { useParams, useNavigate } from "react-router-dom";

import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "Components/ui/dropdown-menu";
import { Skeleton } from "Components/ui/skeleton";
import AttioIcon from "core/design-system/components/Icon/Interface/AttioIcon";
import { useAttioSettings } from "core/hooks/useAttioSettings";
import { Integration } from "core/models/dataMappings";
import { CrmSection } from "modules/Object/Sections/Crm";

export const Attio: React.FC = () => {
  const { hasAttioInstalled, isLoadingSetting, setting } = useAttioSettings();
  const navigate = useNavigate();
  const { appId } = useParams();
  if (isLoadingSetting)
    return (
      <div className="flex flex-col gap-2 border-b border-gray-300 px-3 py-4">
        <p className="font-medium">Attio</p>
        <Skeleton className="h-[20px] w-full rounded-md" />
      </div>
    );

  return (
    <div className="flex flex-col gap-2 border-b border-gray-300 px-3 py-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <AttioIcon />
          <p className="font-medium">Attio</p>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger className="rounded-md p-1 hover:bg-gray-50">
            <EllipsisVerticalIcon className="size-4 text-muted-foreground" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem>
              <Cog6ToothIcon className="size-4 text-muted-foreground" />
              <p
                onClick={() => {
                  navigate(`/a/${appId}/integrations/attio`);
                }}
              >
                Settings
              </p>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <CrmSection
        integration={Integration.Attio}
        isInstalled={hasAttioInstalled}
        workspaceIdentifier={setting?.workspaceName}
      />
    </div>
  );
};
