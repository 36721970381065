import { Button, ButtonGroup, Tooltip } from "@chakra-ui/react";
import { useState } from "react";

import { PaywallPopover } from "core/components/Paywall/Popover";
import { ConfirmationModal } from "core/components/SmartTrait/ConfirmationModal";
import { Overflow } from "core/components/SmartTrait/Overflow";
import { SMART_TRAITS } from "core/constants/features";
import useFlag from "core/hooks/useFlag";
import { Plan } from "core/types/App";
import { ITraitWithConfig } from "core/types/Trait";

const DEFAULT_NAME = "Untitled";

export const Actions: React.FC<{
  trait: ITraitWithConfig;
  isDirty: boolean;
  onDelete?: () => void;
  onSetLive?: (live: boolean) => void;
  onSave?: (trait: ITraitWithConfig) => void;
  onCompute?: () => void;
}> = ({ trait, isDirty, onDelete, onSetLive, onSave }) => {
  const [showSetLiveModal, setShowSetLiveModal] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [showPauseModal, setShowSetPauseModal] = useState(false);
  const hasSmartTraits = useFlag(SMART_TRAITS);

  return (
    <>
      <ButtonGroup>
        {isDirty && (
          <Tooltip
            shouldWrapChildren
            label={
              trait?.name === DEFAULT_NAME
                ? "Please give the computed trait a name"
                : ""
            }
          >
            <PaywallPopover
              feature="computed traits"
              plan={Plan.Pro}
              overridePaywall={hasSmartTraits}
              redirect={`settings/computed-traits/${trait.id}`}
            >
              <Button
                isDisabled={!isDirty || trait?.name === DEFAULT_NAME}
                onClick={() =>
                  !trait
                    ? setShowSetLiveModal(true)
                    : setShowSaveChangesModal(true)
                }
                colorScheme="purple"
              >
                {trait.isComputed ? "Save changes" : "Create and set live"}
              </Button>
            </PaywallPopover>
          </Tooltip>
        )}
        {!isDirty && (
          <PaywallPopover
            feature="computed traits"
            plan={Plan.Pro}
            overridePaywall={hasSmartTraits}
            redirect={`settings/smart-traits/${trait.id}`}
          >
            <Button
              colorScheme={trait.isLive ? "gray" : "purple"}
              onClick={() =>
                trait.isLive
                  ? setShowSetPauseModal(true)
                  : setShowSetLiveModal(true)
              }
            >
              {trait.isLive ? "Pause" : "Set live"}
            </Button>
          </PaywallPopover>
        )}
        {trait.isComputed && <Overflow trait={trait} onDelete={onDelete} />}
      </ButtonGroup>
      {showSetLiveModal && (
        <ConfirmationModal
          isOpen={showSetLiveModal}
          onClose={() => setShowSetLiveModal(false)}
          title="Are you sure?"
          body={
            "This will set the computed trait live and it will be computed for all users or companies"
          }
          onConfirmation={() => {
            if (trait && onSetLive) {
              onSetLive(!trait.isLive);
              setShowSetPauseModal(false);
            }
          }}
        />
      )}
      {showSaveChangesModal && (
        <ConfirmationModal
          isOpen={showSaveChangesModal}
          onClose={() => setShowSaveChangesModal(false)}
          title="Are you sure?"
          body={"This will update how the computed trait will be computed"}
          onConfirmation={() => {
            trait && onSave && onSave(trait);
            setShowSaveChangesModal(false);
          }}
        />
      )}
      {showPauseModal && (
        <ConfirmationModal
          isOpen={showPauseModal}
          onClose={() => setShowSetPauseModal(false)}
          title="Are you sure?"
          body={
            "This will stop updating the traits values for all users or companies"
          }
          onConfirmation={() => {
            if (!onSetLive || !trait) return;

            onSetLive(!trait.isLive);
            setShowSetPauseModal(false);
          }}
        />
      )}
    </>
  );
};
