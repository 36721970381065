import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { CSVExport } from "core/components/CSVExport";
import InsightCard from "core/components/InsightCard/Index";
import { List } from "core/components/List";
import { IListConfig } from "core/components/List/List.types";
import { JUNE_APP_ID } from "core/constants/appIds";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useReportSetup } from "core/hooks/useReportSetup";
import CompanyLink from "core/modules/reports/report-types/GroupEngagement/CompanyLink";
import GroupDrillDown from "core/modules/reports/report-types/GroupEngagement/GroupDrillDown";
import GroupLink from "core/modules/reports/report-types/GroupEngagement/GroupLink";
import { IReport } from "core/types/Report";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";

const listConfig = ({
  previewMode,
  sharingMode,
  isCompanyLevel,
}: {
  previewMode: boolean;
  sharingMode: boolean;
  isCompanyLevel: boolean;
}) => {
  const LinkComponent = isCompanyLevel ? CompanyLink : GroupLink;
  return {
    resourceName: "companies",
    columns: [
      {
        title: "",
        name: "email",
        Component: LinkComponent,
        width: 400,
        align: "left",
        previewMode,
        sharingMode,
      },
      {
        title: "Events count",
        name: "count",
        width: 200,
        align: "right",
        tooltip: `Amount of times a company performed the event you picked in the set up`,
      },
    ],
  } as IListConfig;
};

interface IInsightGroupContact {
  userId: string;
  traits: { [key: string]: string };
}

export interface IMostEngagedCompany {
  groupId: string;
  count: number;
  traits: { [key: string]: string };
  contacts: IInsightGroupContact[];
  openCompany: () => void;
}

interface ICompaniesListProps {
  appId: number;
  reportId: number;
  previewMode: boolean;
  sharingMode: boolean;
  screenshotMode: boolean;
  sharingSecretToken: string;
  report: IReport;
  config: ITemplateConfig;
}

const CompaniesList = ({
  previewMode,
  sharingMode,
  screenshotMode,
  report,
  config,
  sharingSecretToken = "null",
}: ICompaniesListProps) => {
  const { appId } = useParams<{ appId: string }>();
  const [company, openCompany] = useState<IMostEngagedCompany | null>(null);
  const insight: IInsightSection = config.insights.find(
    ({ slug }) => slug === "most-engaged-companies",
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);

  const { serializeKey, invalidateInsights } = useReportSetup();
  const limit = Number(appId) === JUNE_APP_ID ? 100 : 30;
  const levelQueryParam = useQueryParam("level");
  const isCompanyLevel = levelQueryParam === "company";

  const {
    response,
    isFetching,
    refetch: refetchInsight,
    onRefreshInsight,
  } = useInsightDataFetching({
    report,
    insightType: insight.typeId,
    previewMode,
    insightParams: {
      limit: screenshotMode ? 5 : limit,
    },
    sharingSecretToken,
    skip: !isSetupValid,
  });

  useEffect(() => {
    if (!previewMode) {
      invalidateInsights();
      refetchInsight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializeKey]);

  if (!insight) {
    return null;
  }

  const rowData =
    response?.data?.map((v: IMostEngagedCompany) => ({
      ...v,
      openCompany: () => openCompany(v),
    })) || [];
  const rowDataToCSV = rowData.map(
    (row: IMostEngagedCompany & { sharingMode: boolean }) => [
      row.groupId,
      row.count,
    ],
  );
  const headers = ["company_id", "total_events"];

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            mb={0}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          >
            <Flex align="center" gridGap={2}>
              <Flex>
                <CSVExport
                  disabled={previewMode || sharingMode}
                  data={rowDataToCSV}
                  headers={headers}
                  filename={`${report?.name}-${insight.slug}.csv`}
                />
              </Flex>
            </Flex>
          </Card.Header>
          <Card.Body
            insight={insight}
            isLoading={isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
          >
            {!isFetching && rowData.length >= 0 && (
              <Box>
                <List
                  mutate={() => {}}
                  rows={rowData}
                  config={listConfig({
                    previewMode,
                    sharingMode,
                    isCompanyLevel,
                  })}
                  borderRadius={0}
                  border={0}
                  id="loaded"
                />
                {!previewMode && company && (
                  <GroupDrillDown
                    group={company}
                    isCompanyLevel={isCompanyLevel}
                    isOpen={Boolean(company)}
                    onClose={() => openCompany(null)}
                  />
                )}
              </Box>
            )}
          </Card.Body>
          {insight.description && !screenshotMode && (
            <Card.Footer bg="white">
              <Card.DescriptionAccordion insight={insight} />
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
};

export default CompaniesList;
