import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useCompanyView } from "@/core/hooks/useCompanyView";
import { usePreviewQuery } from "@/core/models/sql";
import { JuneLoadingPage } from "@/modules/Dashboard/LoadingPage";
import { InsightEditorContainer } from "@/modules/Dashboard/SQLBuilder/EditorContainer";
import { useNavigation } from "core/hooks/useNavigation";

export const AddObjectSQLRoute: React.FC = () => {
  const { navigation, toggleSidebar } = useNavigation();
  const { appId } = useParams();
  const { view } = useCompanyView();

  const { data: viewInsight, isLoading } = usePreviewQuery(
    {
      appId: Number(appId),
      viewId: Number(view?.id),
    },
    { skip: !view },
  );

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
  });

  if (isLoading || !viewInsight) {
    return <JuneLoadingPage />;
  }

  return (
    <div className="h-screen w-full overflow-hidden">
      <InsightEditorContainer viewInsight={viewInsight} />
    </div>
  );
};
