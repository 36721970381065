import { Flex, StyleProps, Text } from "@chakra-ui/react";
import { ArrowLongDownIcon, ArrowLongUpIcon } from "@heroicons/react/16/solid";

import { cn } from "lib/utils";

interface IPercentChangeProps extends StyleProps {
  percentChange: number;
  changeText?: string;
  reverseColor?: boolean;
}

export const PercentChange = ({
  percentChange,
  changeText,
  reverseColor = false,
  ...styleProps
}: IPercentChangeProps) => {
  let ChangeIcon;
  let insightColor = "text-gray-600";
  let increased = reverseColor ? percentChange < 0 : percentChange >= 0;

  if (increased) {
    ChangeIcon = ArrowLongUpIcon;
    insightColor = "text-green-500";
  } else {
    ChangeIcon = ArrowLongDownIcon;
    insightColor = "text-red-500";
  }

  if (percentChange && percentChange !== 0) {
    return (
      <Flex alignSelf="right" {...styleProps}>
        <div className="flex items-center">
          <ChangeIcon className={cn(insightColor, "size-3")} />
          <p className={cn("font-mono text-xs font-medium", insightColor)}>
            {percentChange}%
          </p>
        </div>
        {changeText && (
          <Text ml={1} fontSize="xs" color="gray.600">
            {changeText}
          </Text>
        )}
      </Flex>
    );
  }
  return (
    <Flex justifySelf="end" {...styleProps}>
      <p className="text-right font-mono text-xs font-medium text-gray-600">
        0%
      </p>
      <Text ml={1} fontSize="xs" color="gray.600">
        {changeText}
      </Text>
    </Flex>
  );
};
