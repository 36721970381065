import { Center, Skeleton, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { Error } from "@/Components/Error";
import { PageContainer } from "core/components/PageContainer";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetAudienceQuery } from "core/models/audiences";
import Audience from "modules/Audience";

export const AudienceContainer: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const { audienceId } = useParams<{ audienceId: string }>();

  const {
    data: audience,
    isFetching: isFetchingAudience,
    error: audienceError,
  } = useGetAudienceQuery({
    appId,
    audienceId: Number(audienceId),
  });

  const pages = [
    { name: "Audiences", path: `/a/${appId}/audiences`, current: false },
    { name: audience?.name ?? "Audience", path: "#", current: true },
  ];

  if (audienceError) {
    return (
      <Center w="100vw" h="100vh">
        <Error w="50%" />
      </Center>
    );
  }

  if (!isFetchingAudience && !audience) {
    return (
      <Center w="100vw" h="100vh">
        <Text fontSize="lg">This audience does not exist.</Text>
      </Center>
    );
  }

  if (isFetchingAudience || !audience) {
    return (
      <PageContainer
        className="w-full min-w-full max-w-full"
        px="60px"
        maxW="full"
        minW="container.lg"
        overflow="overflow-hidden"
      >
        <div className="mb-2 flex justify-between">
          <Breadcrumbs pages={pages} />
        </div>

        <div className="mt-6 flex flex-col gap-2">
          <Skeleton height={6} w="20%" borderRadius="lg" />
          <Skeleton height={6} w="50%" borderRadius="lg" />
        </div>
      </PageContainer>
    );
  }
  if (audience) {
    return <Audience audience={audience} />;
  }

  return null;
};
