import { Button, useDisclosure } from "@chakra-ui/react";
import React from "react";

import { RecordsDrilldown } from "@/modules/Settings/Crm/SyncSettings/RecordsDrilldown";
import { ICrmSync } from "core/models/crmSyncs";
import { Integration } from "core/models/dataMappings";
import { IAppObject } from "core/types/AppObject";

interface LastSyncButtonProps {
  appObject: IAppObject;
  count: number;
  text: string;
  integration: Integration;
  lastSyncedAt?: string;
  crmSync?: ICrmSync;
}

export const LastSyncStatusButton: React.FC<LastSyncButtonProps> = ({
  appObject,
  count,
  text,
  lastSyncedAt,
  crmSync,
  integration,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  if (!lastSyncedAt)
    return (
      <p>
        {count} {appObject.pluralName.toLocaleLowerCase()} {text} {lastSyncedAt}
      </p>
    );

  return (
    <>
      <Button
        disabled={count === 0}
        colorScheme="purple"
        variant="link"
        size="xs"
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}
      >
        {count}{" "}
        {count === 1
          ? appObject.singularName.toLocaleLowerCase()
          : appObject.pluralName.toLocaleLowerCase()}
      </Button>{" "}
      {text} {lastSyncedAt}
      {isOpen && (
        <RecordsDrilldown
          appObject={appObject}
          integration={integration}
          isOpen={isOpen}
          onClose={onClose}
          crmSync={crmSync}
        />
      )}
    </>
  );
};
