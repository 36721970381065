import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import { SnakeCasedProperties } from "type-fest";

import {
  DeletionType,
  IDataDeletionRequest,
} from "core/types/DataDeletionRequest";

export interface IndexResponse {
  dataDeletionRequests: IDataDeletionRequest[];
}

export const dataDeletionRequestsApi = createApi({
  reducerPath: "dataDeletionRequestsApi",
  tagTypes: ["DataDeletionRequest"],
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_API_HOST }),
  endpoints: (builder) => ({
    getDataDeletionRequests: builder.query<IndexResponse, { appId: number }>({
      query: ({ appId }) => ({
        url: `/data_deletion_requests`,
        params: humps.decamelizeKeys({ appId }),
        credentials: "include",
        mode: "cors",
      }),
      providesTags: ["DataDeletionRequest"],
      transformResponse: (response: SnakeCasedProperties<IndexResponse>) =>
        humps.camelizeKeys(response) as IndexResponse,
    }),
    createTimestampDataDeletionRequest: builder.mutation<
      IDataDeletionRequest,
      { appId: number; endDate: string }
    >({
      query: ({ appId, endDate }) => ({
        url: `/data_deletion_requests`,
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          deletionType: DeletionType.Timestamp,
          endDate,
        }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["DataDeletionRequest"],
      transformResponse: (
        response: SnakeCasedProperties<IDataDeletionRequest>,
      ) => humps.camelizeKeys(response) as IDataDeletionRequest,
    }),
    createEventDataDeletionRequest: builder.mutation<
      IDataDeletionRequest,
      { appId: number; eventId: number }
    >({
      query: ({ appId, eventId }) => ({
        url: `/data_deletion_requests`,
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          deletionType: DeletionType.Event,
          eventId,
        }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["DataDeletionRequest"],
      transformResponse: (
        response: SnakeCasedProperties<IDataDeletionRequest>,
      ) => humps.camelizeKeys(response) as IDataDeletionRequest,
    }),
    updateDataDeletionRequest: builder.mutation<
      IDataDeletionRequest,
      { appId: number; id: number }
    >({
      query: ({ appId, id }) => ({
        url: `/data_deletion_requests/${id}`,
        method: "PUT",
        params: humps.decamelizeKeys({ appId }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["DataDeletionRequest"],
      transformResponse: (
        response: SnakeCasedProperties<IDataDeletionRequest>,
      ) => humps.camelizeKeys(response) as IDataDeletionRequest,
    }),
  }),
});

export const {
  useGetDataDeletionRequestsQuery,
  useCreateTimestampDataDeletionRequestMutation,
  useUpdateDataDeletionRequestMutation,
} = dataDeletionRequestsApi;
