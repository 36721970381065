import { Box, SimpleGrid, Stack } from "@chakra-ui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Navigate } from "react-router-dom";

import { AddTeammate } from "core/components/Teams/AddTeammate";
import { EnableJoinAutomatically } from "core/components/Teams/EnableJoinAutomatically";
import { EnforceGoogleOAuth } from "core/components/Teams/EnforceGoogleOAuth";
import Teammate from "core/components/Teams/Teammate";
import { DEMO_APP_ID } from "core/constants/appIds";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetUsersQuery } from "core/models/users";
import { IAppUser } from "core/types/User";
import DefaultHeader from "modules/Pages/DefaultHeader";

const Teams: React.FC = () => {
  const { id: appId, enforceGoogleOauth } = useCurrentApp();
  const { data: response } = useGetUsersQuery({ appId });

  if (Number(appId) === DEMO_APP_ID) {
    return <Navigate to={`/a/${appId}/home`} />;
  }

  const section = {
    name: "Members",
    title: "Members",
    description: "Manage who has access to your app",
  };

  const users = response || [];

  return (
    <Box>
      <DefaultHeader w="inherit" section={section} />
      <Stack>
        <SimpleGrid
          columns={1}
          borderWidth="1px"
          borderColor="gray.100"
          borderRadius="md"
        >
          <AddTeammate />
          {enforceGoogleOauth && (
            <div className="bg-purple-50 px-4 py-2.5">
              <div className="flex flex-row items-center space-x-1">
                <InformationCircleIcon className="h-4 text-purple-800" />
                <p className="text-sm leading-6 text-purple-800">
                  Google OAuth is currently enforced down below. Only users that
                  can authenticate with Google can be invited.
                </p>
              </div>
            </div>
          )}
          {users.map((member: IAppUser, index: number) => (
            <Teammate key={index} member={member} />
          ))}
        </SimpleGrid>
      </Stack>
      <Stack mt={8} gridGap={2}>
        <EnforceGoogleOAuth />
        <EnableJoinAutomatically />
      </Stack>
    </Box>
  );
};

export default Teams;
