import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";

import {
  generateColorForTraitValueWithIndex,
  getTraitValue,
} from "core/design-system/charts/Breakdown/utils";

interface IFunnelBreakdownTraitSelect {
  labels: string[];
  selectedLabels: string[];
  setSelectedLabels: (labels: string[]) => void;
}

export const FunnelBreakdownTraitSelect: React.FC<
  IFunnelBreakdownTraitSelect
> = ({ labels, selectedLabels, setSelectedLabels }) => {
  const addLabel = (label: string) => {
    setSelectedLabels([...selectedLabels, label]);
  };

  const removeLabel = (label: string) => {
    setSelectedLabels(selectedLabels.filter((l) => l !== label));
  };

  const menuText = selectedLabels.length
    ? selectedLabels.length + " selected"
    : "All";

  return (
    <Menu closeOnSelect={false} closeOnBlur={true}>
      <MenuButton
        size="sm"
        minW="200px"
        height="32px"
        as={Button}
        rightIcon={<ChevronDownIcon />}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="sm">
            <Text as="span" color="gray.500">
              Traits:{" "}
            </Text>
            {menuText}
          </Text>
        </Flex>
      </MenuButton>
      <MenuList minWidth="200px">
        {labels.map((label) => {
          return (
            <MenuItem py={1}>
              <Checkbox
                w="100%"
                colorScheme={"purple"}
                key={label}
                isChecked={selectedLabels.includes(label)}
                size="sm"
                onChange={(e) => {
                  if (e.target.checked) {
                    addLabel(label);
                  } else {
                    removeLabel(label);
                  }
                }}
              >
                <Flex align="center">
                  <Box
                    bg={generateColorForTraitValueWithIndex(
                      label,
                      labels.length - labels.indexOf(label) - 1,
                    )}
                    width={2}
                    height={2}
                    borderRadius="50%"
                  />
                  <Text ml={2} fontSize="sm" color="gray.700">
                    {getTraitValue(label)}
                  </Text>
                </Flex>
              </Checkbox>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
