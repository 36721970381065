import { Box } from "@chakra-ui/react";
import React, { Suspense } from "react";
import { format } from "sql-formatter";

import colors from "core/design-system/constants/theme/colors";

const CodeEditor = React.lazy(() => import("@uiw/react-textarea-code-editor"));

export const ReadonlySQLEditor: React.FC<{
  query: string;
}> = ({ query }) => {
  const formattedQuery = React.useMemo(() => {
    try {
      return format(query || "", {
        keywordCase: "upper",
        tabWidth: 2,
        language: "postgresql",
      });
    } catch (error) {
      console.log("SQL formatting error:", error);
      return query || "";
    }
  }, [query]);

  return (
    <Box
      position="relative"
      w="full"
      borderBottom="1px solid"
      borderColor="gray.300"
      maxH="400px"
      overflowY="auto"
    >
      <Suspense fallback={<div>Loading...</div>}>
        <CodeEditor
          readOnly
          value={formattedQuery}
          language="sql"
          data-color-mode="dark"
          padding={15}
          style={{
            borderRadius: "5px",
            width: "100%",
            height: "100%",
            fontSize: 12,
            backgroundColor: colors.black,
            fontFamily:
              "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
          }}
        />
      </Suspense>
    </Box>
  );
};
