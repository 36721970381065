import { Flex, Text, Tooltip } from "@chakra-ui/react";

import { TooltipBody } from "core/components/TooltipBody";
import HelpCircle from "core/design-system/components/Icon/Interface/HelpCircle";
import Popover from "core/design-system/components/Popover";
import { ReferrerDomain } from "core/modules/reports/report-types/NewUsers/ReferrerDomain";
import { IReferrersData } from "core/modules/reports/report-types/NewUsers/ReferrersContainer";

export const ReferrersTable: React.FC<IReferrersData> = ({
  referrers,
  insightSlug,
}) => {
  return (
    <Flex data-testid="referrers-table" direction="column" w="100%" mb={4}>
      <Flex
        px={8}
        py={4}
        w="100%"
        bg="gray.50"
        color="gray.600"
        fontSize="xs"
        borderRadius="md"
      >
        <Flex align="center" w="70%">
          <Text>REFERRERS</Text>
          <Popover
            placement="bottom"
            backgroundColor="gray.700"
            popoverTrigger={<HelpCircle h={4} w={4} color="gray.600" ml={1} />}
            popoverBody={
              <Flex
                w="100%"
                direction="column"
                align="center"
                justify="space-between"
              >
                <Text fontSize="sm">
                  Referring sites are the websites visited before reaching your
                  domain.
                </Text>
                {/* Waiting for help article */}
                {/* <Button
                  _hover={{ bg: 'gray.500' }}
                  mt={1}
                  w="100%"
                  colorScheme="black"
                  as="a"
                  target="_blank"
                  href="https://segment.com/docs/connections/spec/best-practices-identify/#merging-identified-and-anonymous-user-profiles"
                >
                  <Text p={2}>Learn more</Text>
                </Button> */}
              </Flex>
            }
          />
        </Flex>
        <Flex ml={10} align="center">
          {insightSlug === "referrers" ? "VISITORS" : "USERS"}
          <Tooltip
            label={
              <TooltipBody
                text={
                  insightSlug === "referrers"
                    ? "Number of unique anonymous users: users that were not logged in"
                    : "Number of unique users: users that signed up"
                }
              />
            }
            placement="bottom"
            borderRadius="lg"
            bg="gray.700"
            hasArrow={true}
          >
            <HelpCircle h={4} w={4} color="gray.600" ml={1} />
          </Tooltip>
        </Flex>
      </Flex>

      {referrers?.map(({ referrerDomain, usersCount }, index) => (
        <Flex
          key={index}
          w="100%"
          px={8}
          fontSize="sm"
          align="center"
          data-testid="referrer-list-item"
        >
          <Flex
            py={3}
            w="100%"
            align="center"
            borderBottom={`${index === referrers.length - 1 ? "0" : "1"}px solid`}
            borderBottomColor="gray.100"
          >
            <ReferrerDomain domain={referrerDomain} />
            <Text ml={10}>{usersCount}</Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};
