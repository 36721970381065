import { createIcon } from "@chakra-ui/icons";
import React from "react";

export const LineChartIcon = createIcon({
  path: (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.550781 12.9248C0.384115 12.7581 0.300781 12.5664 0.300781 12.3498C0.300781 12.1331 0.384115 11.9414 0.550781 11.7748L6.95078 5.37477C7.10078 5.22477 7.27578 5.14977 7.47578 5.14977C7.67578 5.14977 7.85078 5.22477 8.00078 5.37477L11.4758 8.87477L18.4258 1.07477C18.5591 0.924766 18.7299 0.841432 18.9383 0.824766C19.1466 0.808099 19.3258 0.874766 19.4758 1.02477C19.6091 1.1581 19.6799 1.32477 19.6883 1.52477C19.6966 1.72477 19.6341 1.89977 19.5008 2.04977L12.0008 10.5248C11.8674 10.6914 11.6883 10.7789 11.4633 10.7873C11.2383 10.7956 11.0508 10.7248 10.9008 10.5748L7.47578 7.14977L1.70078 12.9248C1.53411 13.0914 1.34245 13.1748 1.12578 13.1748C0.909115 13.1748 0.717448 13.0914 0.550781 12.9248Z"
        fill="currentColor"
      />
    </svg>
  ),
});
