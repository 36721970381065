import { StyleProps, Text } from "@chakra-ui/react";
import React from "react";

import { TRACK } from "core/constants/report-setup";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useReportSetup } from "core/hooks/useReportSetup";
import useRequest from "core/hooks/useRequest";
import { EventPill } from "core/modules/reports/setup/Pill/Event";
import { IEvent } from "core/types/Event";

interface ITrackEventPillProps extends StyleProps {
  text?: string;
  event: IEvent;
  onRemoveEvent?: () => void;
  onClick?: () => void;
  showProperties?: boolean;
  showOccurrences?: boolean;
  configKey?: string;
}

export const TrackEventPill: React.FC<ITrackEventPillProps> = ({
  text = undefined,
  event,
  onRemoveEvent = undefined,
  showProperties = false,
  showOccurrences,
  configKey,
  children,
  ...props
}) => {
  const { id: appId } = useCurrentApp();
  const { updateTrackEvent } = useReportSetup();
  const { data, isLoading } = useRequest({
    url: `/events/${event?.id}`,
    params: { appId, id: event?.id },
    withCredentials: true,
  });

  if (isLoading && !event?.name) {
    return (
      <Text
        p={1}
        fontSize="sm"
        bg="white"
        border="1px solid"
        px={2}
        color="gray.600"
        borderColor="gray.200"
        borderLeftRadius={8}
        {...props}
      >
        Loading event...
      </Text>
    );
  }

  if (data && !event?.name) {
    const _event = {
      name: data.name,
      id: event.id,
      properties: event.properties,
      type: TRACK,
    };

    if (configKey) {
      updateTrackEvent({ event: _event, configKey });
    }

    return (
      <EventPill
        showProperties={showProperties}
        showOccurrences={showOccurrences}
        event={_event}
        {...props}
      >
        {children}
      </EventPill>
    );
  }

  return (
    <EventPill
      showProperties={showProperties}
      showOccurrences={showOccurrences}
      event={event}
      {...props}
    >
      {children}
    </EventPill>
  );
};

export default TrackEventPill;
