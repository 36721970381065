import { Skeleton } from "@chakra-ui/react";
import { ComboboxOption } from "@headlessui/react";
import { FixedSizeList } from "react-window";

import { DIVIDER } from "core/constants/filters";
import { FilterType } from "core/constants/report-setup";
import { ITraitKeyResponse } from "core/models/traits";
import { IFilterMenuProps } from "core/modules/audience/Filters/AddFilterButton";
import { EventFilterOption } from "core/modules/audience/Filters/EventFilterOption";
import { TraitOption } from "core/modules/audience/Filters/TraitOption";
import { IFilterOption, searchFilters } from "helpers/searchFilters";

export const TraitOptionsList = ({
  traits,
  searchTrait,
  onAddTraitFilter,
  onAddEventFilter,
  isLoading,
  filterTypeNames,
  isEventFilterDisabled,
  testId,
  onSelectTrait,
  height,
}: Pick<
  IFilterMenuProps,
  "onAddTraitFilter" | "onAddEventFilter" | "isEventFilterDisabled"
> & {
  traits: ITraitKeyResponse[];
  isLoading: boolean;
  testId?: string;
  searchTrait: string;
  onSelectTrait?: (trait: ITraitKeyResponse) => void;
  filterTypeNames?: { [x: number]: string | undefined };
  height?: number;
}) => {
  if (isLoading)
    return (
      <div className="flex flex-col gap-2">
        <Skeleton height="25px" borderRadius="md" />
        <Skeleton height="25px" borderRadius="md" />
        <Skeleton height="25px" borderRadius="md" />
        <Skeleton height="25px" borderRadius="md" />
        <Skeleton height="25px" borderRadius="md" />
        <Skeleton height="25px" borderRadius="md" />
      </div>
    );

  const availableFilters = [
    { trait: "Event performed", filterType: FilterType.EventFilter },
    ...(traits || []),
  ] as IFilterOption[];

  const visibleFilters = searchFilters(availableFilters, searchTrait);

  if (visibleFilters.length === 0)
    return (
      <p data-testid={testId} className="px-3 py-2 text-xs font-medium">
        No filters found
      </p>
    );

  let lastFilterType: FilterType | undefined;

  return (
    <FixedSizeList
      data-testid={testId}
      height={height || 210}
      itemCount={visibleFilters.length}
      itemSize={30}
      width="100%"
    >
      {({ index, style }: { index: number; style: object }) => {
        const trait = visibleFilters[index] as ITraitKeyResponse;
        lastFilterType = trait.filterType as number;
        const filterTypeName =
          filterTypeNames && filterTypeNames[lastFilterType];

        return (
          <div className="h-full w-full" style={{ ...style }}>
            {trait?.filterType === FilterType.EventFilter ? (
              <EventFilterOption
                onAddEventFilter={
                  onAddEventFilter ? onAddEventFilter : () => {}
                }
                isEventFilterDisabled={isEventFilterDisabled}
              />
            ) : (
              <div className="w-full">
                {trait?.filterType === DIVIDER ? (
                  <ComboboxOption value={() => {}} disabled={true}>
                    <p className="flex px-2 pb-2 pt-2 text-xs font-medium text-gray-600">
                      {trait.trait}
                    </p>
                  </ComboboxOption>
                ) : (
                  <TraitOption
                    testId={`audience-${filterTypeName?.toLocaleLowerCase()}-trait-option-${index}`}
                    onClick={() => {
                      if (onSelectTrait) onSelectTrait(trait);

                      trait?.filterType !== undefined &&
                        onAddTraitFilter &&
                        onAddTraitFilter(
                          trait.filterType,
                          trait.trait,
                          trait.dataType,
                        );
                    }}
                    trait={trait}
                  />
                )}
              </div>
            )}
          </div>
        );
      }}
    </FixedSizeList>
  );
};
