import { Box, Flex, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  ArrowLeftOnRectangleIcon,
  BanknotesIcon,
  CircleStackIcon,
  CodeBracketIcon,
  Cog6ToothIcon,
  CubeIcon,
  InboxArrowDownIcon,
  TagIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { USAGE_BASED_PRICING } from "core/constants/features";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import useFlag from "core/hooks/useFlag";
import { BaseSidebar } from "modules/Navigation/Sidebar/BaseSidebar";
import { MenuLink, MenuLinkProps } from "routes/Settings/MenuLink";

const MENU: MenuLinkProps[] = [
  {
    label: "General",
    path: "settings/general",
    Icon: Cog6ToothIcon,
  },
  {
    label: "Members",
    path: "settings/team",
    Icon: UsersIcon,
  },
  {
    label: "Labels",
    path: "settings/labels",
    Icon: TagIcon,
  },
  {
    label: "Billing",
    path: "settings/billing",
    Icon: BanknotesIcon,
  },
  {
    label: "Digest",
    path: "settings/digest",
    Icon: InboxArrowDownIcon,
  },
  {
    label: "Objects",
    path: "settings/objects",
    Icon: CubeIcon,
  },

  {
    label: "Developer tools",
    path: "settings/developers",
    Icon: CodeBracketIcon,
  },
  {
    label: "Data sources",
    path: "settings/integrations",
    Icon: CircleStackIcon,
    subMenu: [
      {
        label: "June SDK",
        path: "settings/integrations/june-sdk",
      },

      {
        label: "Segment",
        path: "settings/integrations/segment",
      },
      {
        label: "Freshpaint",
        path: "settings/integrations/freshpaint",
      },
      {
        label: "Rudderstack",
        path: "settings/integrations/rudderstack",
      },
    ],
  },

  {
    label: "Importers",
    path: "settings/integrations",
    Icon: ArrowLeftOnRectangleIcon,
    subMenu: [
      {
        label: "Amplitude",
        path: "settings/integrations/amplitude",
      },
      {
        label: "Mixpanel",
        path: "settings/integrations/mixpanel",
      },
    ],
  },
];

type ToggleProps = { isOpen: boolean; onToggle: () => void };
const Toggle: React.FC<ToggleProps> = ({ isOpen, onToggle }) => (
  <Flex onClick={onToggle}>
    <motion.div animate={{ rotate: isOpen ? 0 : -90 }}>
      <ChevronDownIcon className="h-4 w-4  text-gray-500 group-hover:block group-hover:text-gray-500" />
    </motion.div>
  </Flex>
);

const NavAccordion: React.FC<MenuLinkProps> = ({ subMenu, label, Icon }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Flex direction="column">
      <MenuLink
        action={<Toggle isOpen={isOpen} onToggle={() => setIsOpen(isOpen)} />}
        label={label}
        Icon={Icon}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div className="ml-[28px] mt-2 flex flex-col gap-2">
          {subMenu?.map((subItem) => (
            <MenuLink key={subItem.label} {...subItem} />
          ))}
        </div>
      )}
    </Flex>
  );
};

export const SettingsNavBar: React.FC = () => {
  const app = useCurrentApp();
  const hasUsageBasedPricing = useFlag(USAGE_BASED_PRICING);

  return (
    <div className="sticky top-0 flex h-screen min-h-screen min-w-[290px] flex-col justify-between">
      <div className="flex h-screen flex-1 flex-col overflow-y-auto">
        <div className="flex w-full">
          <BaseSidebar />

          <div className="flex flex-1 flex-col gap-3 py-4">
            <Box mb={2}>
              <NavLink to={`/a/${app.id}/home`}>
                <Flex gap={2} px={4}>
                  <Text color="gray.900" fontWeight="semibold">
                    Settings
                  </Text>
                </Flex>
              </NavLink>
            </Box>
            {MENU.map((item) => {
              if (item.subMenu) {
                return <NavAccordion key={item.label} {...item} />;
              }

              if (item.label === "Billing" && !hasUsageBasedPricing)
                return null;

              return <MenuLink key={item.label} {...item} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
