import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Table,
  Text,
} from "@chakra-ui/react";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AnimatedToggle from "core/components/AnimatedToggle";
import { classNames } from "core/helpers/classNames";
import { Issue } from "core/helpers/event-validation/issue";
import { validateEventLog } from "core/helpers/event-validation/validate-event-log";
import { DocSection, useDocs } from "core/hooks/useDocs";
import { useSelectedEventLog } from "core/hooks/useSelectedEventLog";
import { useGetDataQuery } from "core/models/debugger";
import { Log } from "modules/Developers/Logs/Log";
import { LogDetails } from "modules/Developers/Logs/LogDetails";
import { LogIcon } from "modules/Developers/Logs/LogIcon";
import { LogTable } from "modules/Developers/Logs/LogTable";

export interface ITab {
  label: string;
  value: "all" | "track" | "page" | "identify" | "group";
  startIcon: React.ReactElement;
}

const ONE_SECOND = 1000;

const TABS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Track",
    value: "track",
    startIcon: <Box h="10px" w="10px" borderRadius="full" bg="cyan.600" />,
  },
  {
    label: "Page",
    value: "page",
    startIcon: <Box h="10px" w="10px" borderRadius="full" bg="green.600" />,
  },
  {
    label: "Identify",
    value: "identify",
    startIcon: <Box h="10px" w="10px" borderRadius="full" bg="purple.600" />,
  },
  {
    label: "Group",
    value: "group",
    startIcon: <Box h="10px" w="10px" borderRadius="full" bg="orange.600" />,
  },
] as ITab[];

const MODE = [
  { value: "live", startIcon: <PlayIcon style={{ height: "15px" }} /> },
  { value: "paused", startIcon: <PauseIcon style={{ height: "15px" }} /> },
];

const issueColor: Record<Issue["level"], string> = {
  error: "red",
  warning: "orange",
  info: "blue",
};

const LogWarning = ({ issue: { level, message } }: { issue: Issue }) => (
  <div
    className={classNames(
      "flex items-center gap-2 border-b p-2 px-4",
      `bg-${issueColor[level]}-50`,
      `text-${issueColor[level]}-800`,
    )}
  >
    <LogIcon size="xs" issueLevel={level} />
    <span className="text-xs">{message}</span>
  </div>
);

const LogWarnings = () => {
  const { selectedLog } = useSelectedEventLog();

  if (!selectedLog) {
    return null;
  }

  const validationResult = validateEventLog(selectedLog);

  if (validationResult.issues.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col">
      {validationResult.issues.map((issue) => (
        <LogWarning issue={issue} />
      ))}
    </div>
  );
};

const LogHeader = () => {
  const { selectedLog } = useSelectedEventLog();

  if (!selectedLog) {
    return null;
  }

  return (
    <div className="bg-white">
      <Table variant="simple">
        <Log data={selectedLog} />
      </Table>
    </div>
  );
};

export const LogsContainer: React.FC = () => {
  const { appId } = useParams();
  const { getLinkByDocSection } = useDocs();
  const [selectedTab, setSelectedTab] = useState(TABS[0].value);
  const [isLive, setIsLive] = useState(true);

  const eventType = selectedTab === "all" ? null : selectedTab;

  const { data: response } = useGetDataQuery(
    {
      appId: Number(appId),
      eventType,
    },
    {
      pollingInterval: isLive ? ONE_SECOND : undefined,
    },
  );
  const { deselectLog, selectedLog } = useSelectedEventLog();
  const validationResult = selectedLog && validateEventLog(selectedLog);
  const errorCount = validationResult ? validationResult.issues.length : 1;
  const data = response?.result?.data || [];

  useEffect(() => {
    return () => {
      deselectLog();
    };
  }, [deselectLog]);

  const docSection: DocSection = selectedTab === "all" ? "main" : selectedTab;
  const linkByDocSection = getLinkByDocSection(docSection);

  return (
    <Flex direction="column">
      <Text fontWeight="semibold" fontSize="xl">
        Most recent data
      </Text>
      <div className="mt-5 flex h-[70vh] rounded-lg border border-gray-200">
        <div className="flex flex-col gap-2 border-r border-gray-200">
          <div className="mt-2 flex w-full gap-2 px-2">
            <Flex>
              <AnimatedToggle
                items={MODE}
                value={isLive ? "live" : "paused"}
                onChange={(val, e) => {
                  e.preventDefault();
                  setIsLive(val === "live");
                }}
              />
            </Flex>
            <Flex>
              <AnimatedToggle
                items={TABS}
                value={selectedTab}
                containerProps={{ marginTop: 0 }}
                onChange={(val, e) => {
                  if (val === selectedTab) return;
                  e.preventDefault();
                  setSelectedTab(val as ITab["value"]);
                  deselectLog();
                }}
              />
            </Flex>
          </div>

          <div className="h-full overflow-scroll">
            {data?.length === 0 && (
              <Center w="full">
                <Flex
                  direction="column"
                  w="full"
                  justifyContent="center"
                  align="center"
                >
                  <Center h="60vh">
                    <Flex direction="column" align="center" gridGap={2}>
                      <div className="flex flex-col items-center gap-2">
                        {isLive && (
                          <Spinner
                            size="sm"
                            color="#6968F4"
                            zIndex={1}
                            speed="0.5s"
                          />
                        )}
                        <span className="text-sm font-semibold">
                          {isLive
                            ? selectedTab !== "all"
                              ? `Waiting for ${selectedTab} events`
                              : "Waiting for all events"
                            : "No events found"}
                        </span>
                      </div>
                      <a
                        href={linkByDocSection || ""}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button mt={1} variant="outline">
                          View docs
                        </Button>
                      </a>
                    </Flex>
                  </Center>
                </Flex>
              </Center>
            )}
            {data?.length > 0 && <LogTable key={eventType} data={data} />}
          </div>
        </div>
        <div className="relative h-full w-full flex-1">
          <div className="absolute top-0 z-sticky w-full">
            <LogHeader />
            <LogWarnings />
          </div>
          <div
            className={classNames(
              "h-full w-full flex-1 overflow-scroll bg-gray-50",
            )}
            style={{ paddingTop: `${errorCount * 40 + 30}px` }}
          >
            <LogDetails />
          </div>
        </div>
      </div>
    </Flex>
  );
};
