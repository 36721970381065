import { useParams } from "react-router-dom";

import { useToast } from "core/hooks/useToast";
import {
  useCreateTimestampDataDeletionRequestMutation,
  useGetDataDeletionRequestsQuery,
  useUpdateDataDeletionRequestMutation,
} from "core/models/dataDeletionRequests";
import { IDataDeletionRequest } from "core/types/DataDeletionRequest";

export const useDataDeletionRequests = (): {
  dataDeletionRequests: IDataDeletionRequest[];
  onCreateTimestampDataDeletionRequest: (endDate: string) => void;
  onCancelDataDeletionRequest: (id: number) => void;
} => {
  const { appId } = useParams<{ appId: string }>();
  const toast = useToast();
  const { data: dataDeletionRequests } = useGetDataDeletionRequestsQuery({
    appId: Number(appId),
  });
  const [createTimestampDataDeletionRequest] =
    useCreateTimestampDataDeletionRequestMutation();
  const [updateDataDeletionRequest] = useUpdateDataDeletionRequestMutation();
  const deletionRequests =
    (dataDeletionRequests?.dataDeletionRequests as IDataDeletionRequest[]) ||
    ([] as IDataDeletionRequest[]);

  function onCreateTimestampDataDeletionRequest(endDate: string) {
    createTimestampDataDeletionRequest({ appId: Number(appId), endDate })
      .unwrap()
      .then(() => {
        toast({
          status: "success",
          title: "Data deletion requested successfully",
          description:
            "You'll get notified when your data deletion is complete.",
        });
      });
  }

  function onCancelDataDeletionRequest(id: number) {
    updateDataDeletionRequest({ appId: Number(appId), id });
  }

  return {
    dataDeletionRequests: deletionRequests,
    onCreateTimestampDataDeletionRequest,
    onCancelDataDeletionRequest,
  };
};
