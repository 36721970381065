import { AspectRatio, Box, Button, Flex, Text } from "@chakra-ui/react";

import { Body } from "Components/PageBody";
import { Icon } from "core/design-system/components/Icon";
import { useNavigation } from "core/hooks/useNavigation";

const ReportsPlaceholder = () => {
  const { setIsNewReportModalOpen } = useNavigation();

  return (
    <Body overflow="hidden">
      <Flex
        mt={5}
        pb={20}
        h={700}
        w={1000}
        bg="gray.100"
        borderRadius="lg"
        direction="column"
      >
        <Flex>
          <Box
            style={{
              backdropFilter: "blur(32px)",
              WebkitBackdropFilter: "blur(32px)",
            }}
            h={700}
            w={1000}
            borderRadius="lg"
            position="fixed"
            bg="rgba(255, 255, 255, 0.7)"
          />
        </Flex>
        <Text
          px={20}
          py={10}
          fontSize="5xl"
          fontFamily="boldHeading"
          color="primary"
          zIndex={10}
        >
          Welcome to June
        </Text>
        <Flex
          p={5}
          mx={20}
          bg="white"
          boxShadow="md"
          borderRadius="lg"
          direction="column"
          zIndex={10}
        >
          <Flex ml={8} mt={4}>
            <Text fontSize="lg" fontWeight="bold">
              Watch this video to learn more about June
            </Text>
          </Flex>
          <Flex>
            <Flex ml={8} my={8}>
              <AspectRatio w={400}>
                <iframe
                  title="june-demo"
                  src="https://www.youtube.com/embed/GhROAw58YPk"
                  allowFullScreen
                />
              </AspectRatio>
            </Flex>
            <Flex ml={8} direction="column" justify="flex-start">
              <Flex my={8}>
                <Text fontWeight="bold">
                  Learn more about reports and templates
                </Text>
              </Flex>
              <Flex>
                <Text w="90%" my={2} color="gray.600">
                  You can set up a report in just a couple of clicks!
                </Text>
              </Flex>
              <Flex mt={8}>
                <Button
                  onClick={() => setIsNewReportModalOpen(true)}
                  colorScheme="purple"
                >
                  Create a new report
                  <Icon
                    iconType="icon"
                    ml={2}
                    h="12px"
                    w="12px"
                    name="arrow-right-3"
                  />
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Body>
  );
};

export default ReportsPlaceholder;
