import { thousandsToK } from "@/core/helpers/thousands";
import { simplifyXAxisTicks, yAxisWidth } from "@/helpers/axis";
import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import {
  CommonCustomTooltip,
  CommonDot,
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { TickText } from "core/design-system/charts/TickText";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { uuid } from "core/helpers/uuid";
import { Size } from "core/types/ViewInsight";

const MOCK_DATA = [
  {
    x: "2024-01-01",
    y: 100,
  },
  {
    x: "2024-01-02",
    y: 200,
  },
  {
    x: "2024-01-03",
    y: 300,
  },
  {
    x: "2024-01-04",
    y: 600,
  },
  {
    x: "2024-01-05",
    y: 1200,
  },
  {
    x: "2024-01-06",
    y: 2400,
  },
  {
    x: "2024-01-07",
    y: 4800,
  },
];

export const SimpleAreaChart: React.FC<{
  slug?: string;
  data: any[];
  tooltipProps?: any;
  TooltipComponent?: React.FC<any>;
  xDataKey?: string;
  yDataKey?: string;
  showTicks?: boolean;
  margin?: { right: number; top: number; bottom: number; left: number };
  color?: IColorPalette;
  padding?: { left: number };
  size?: Size;
  useMockData?: boolean;
  isClickable?: boolean;
  onClickDataPoint?: (payload: { x: string; y: string }) => void;
}> = ({
  TooltipComponent = CommonCustomTooltip,
  data,
  tooltipProps,
  xDataKey = "x",
  yDataKey = "y",
  showTicks = true,
  margin = { right: 5, top: 5, bottom: 0, left: 0 },
  color = colors.purple,
  padding = { left: 0 },
  size = Size.Small,
  useMockData = false,
  slug,
  isClickable = false,
  onClickDataPoint,
}) => {
  const chartData = useMockData ? MOCK_DATA : data || [];

  const id = uuid();
  const { firstTick: firstXTick, lastTick: lastXTick } = simplifyXAxisTicks(
    chartData,
    "x",
  );
  const { lastTick: lastYTick } = simplifyXAxisTicks(chartData, "y");
  const yTickWidth = yAxisWidth(lastYTick);
  const allXTicks = chartData?.map((item) => item[xDataKey]);

  const getXTicks = function () {
    if (showTicks) {
      if (
        [Size.Medium, Size.Large].includes(size) ||
        (firstXTick === lastXTick && allXTicks.length > 1)
      ) {
        return allXTicks;
      }

      return [firstXTick, lastXTick];
    }

    return [];
  };

  return (
    <AreaChart margin={margin} data={chartData} id="loaded">
      {/* @ts-ignore */}
      <CommonTooltip
        content={<TooltipComponent {...tooltipProps} yAxisLabelAffix={slug} />}
      />
      <defs>
        <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor={color[400]} stopOpacity={0.8} />
          <stop offset="95%" stopColor={color[200]} stopOpacity={0.4} />
        </linearGradient>
      </defs>
      <CommonGrid horizontal={true} />
      {/* @ts-ignore */}
      <CommonXAxis
        padding={padding}
        width={100}
        tickCount={2}
        interval="preserveStartEnd"
        ticks={getXTicks()}
        dataKey={xDataKey}
        tickMargin={5}
        tick={({ x, y, ...props }: any) => {
          const { index } = props;
          const { value } = props?.payload;
          return (
            <TickText {...props} y={y + 15} x={index === 0 ? x + 10 : x + 5}>
              {value}
            </TickText>
          );
        }}
      />
      {/* @ts-ignore */}
      <CommonYAxis
        dataKey={yDataKey}
        width={yTickWidth}
        tick={({ x, y, ...props }: any) => {
          const { value } = props?.payload;
          return (
            <TickText {...props} y={y} x={x - 2}>
              {thousandsToK(value)}
            </TickText>
          );
        }}
      />
      <Area
        strokeWidth={2}
        stroke={color[200]}
        fill={`url(#${id})`}
        dot={false}
        dataKey={yDataKey}
        activeDot={
          isClickable ? (
            <CommonDot
              r={6}
              onClick={(
                props,
                dataPoint: { payload: { x: string; y: string } },
              ) => {
                onClickDataPoint?.(dataPoint?.payload);
              }}
              cursor={isClickable && "pointer"}
            />
          ) : (
            false
          )
        }
      />
    </AreaChart>
  );
};
