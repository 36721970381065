import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import type { SnakeCasedPropertiesDeep } from "type-fest";

import { baseQuery } from "core/initializers/rtk";
import { AppObjectType } from "core/types/AppObject";
import { IPagy } from "core/types/Pagy";

export interface ICrmEntity {
  id: number;
  appId: number;
  entityId: string;
  crmId: string;
  action: string;
  traits: object;
  updatedAt: string;
  state: CrmEntityState;
}
export enum CrmEntityState {
  NotSynced = 0,
  Synced = 1,
  All = 2,
}

export interface ICrmEntitiesTotalCounts {
  synced: number;
  notSynced: number;
  all: number;
}

interface IndexResponse {
  crmEntities: ICrmEntity[];
  pagy: IPagy;
  totalCounts: ICrmEntitiesTotalCounts;
}

export enum CrmEntitiesOperator {
  LessThan = "less than",
  MoreThan = "more than",
}

export const crmEntitiesApi = createApi({
  baseQuery,
  reducerPath: "crmEntitiesApi",
  tagTypes: ["CrmSyncs"],
  endpoints: (builder) => ({
    getCrmEntities: builder.query<
      IndexResponse,
      {
        appId: number;
        integration: string;
        objectType: AppObjectType;
        page: number;
        crmSyncId?: number;
      }
    >({
      query: ({ appId, integration, objectType, page, crmSyncId }) => ({
        url: `/crm_entities`,
        params: humps.decamelizeKeys({
          appId,
          integration,
          objectType,
          page,
          crmSyncId,
        }),
      }),
      providesTags: ["CrmSyncs"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IndexResponse>,
      ): IndexResponse => humps.camelizeKeys(response) as IndexResponse,
    }),
  }),
});

export const { useGetCrmEntitiesQuery, useLazyGetCrmEntitiesQuery } =
  crmEntitiesApi;
