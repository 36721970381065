import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";

import { ICohortTableWrapper } from "core/components/CohortTable/CohortTable.types";
import { Container } from "core/components/CohortTable/private/Container";
import { Overview } from "core/components/CohortTable/private/Overview";
import { Table } from "core/components/CohortTable/private/Table";
import { DAY } from "core/constants/timerange";
import { useReportSetup } from "core/hooks/useReportSetup";
import { CohortGroups } from "core/modules/reports/report-types/GroupRetention/CohortGroups";
import { CohortUsers } from "core/modules/reports/report-types/Retention/CohortUsers";

export const CohortTable = ({
  report,
  unit,
  data,
  valueType,
  previewOrSharingMode,
}: ICohortTableWrapper) => {
  const { isOpen, onOpen: onAudienceOpen, onClose } = useDisclosure();
  const { currentReport } = useReportSetup();

  const hideWeekends =
    unit === "user" &&
    currentReport?.config?.desiredFrequency?.[0]?.value === DAY &&
    currentReport?.config?.settings?.removeWeekends;

  const cohortData = data.cohortData;
  const intervalType = data.intervalType;
  const intervalCount = Array.from({ length: cohortData?.length }, (_, i) => i);
  const [cohortStartDate, setCohortStartDate] = useState("");
  const [cohortIntervalStartDate, setCohortIntervalStartDate] = useState("");
  const [retainedUsersCount, setRetainedUsersCount] = useState(0);
  const [droppedUsersCount, setDroppedUsersCount] = useState(0);

  return (
    <Container>
      <Overview
        unit={unit}
        cohortData={cohortData}
        intervalType={intervalType}
        hideWeekends={hideWeekends}
        setCohortStartDate={setCohortStartDate}
        setCohortIntervalStartDate={setCohortIntervalStartDate}
        setRetainedUsersCount={setRetainedUsersCount}
        previewOrSharingMode={previewOrSharingMode}
        onAudienceOpen={onAudienceOpen}
      />
      <Table
        unit={unit}
        valueType={valueType}
        intervalType={intervalType}
        hideWeekends={hideWeekends}
        intervalCount={intervalCount}
        cohortData={cohortData}
        setCohortStartDate={setCohortStartDate}
        setCohortIntervalStartDate={setCohortIntervalStartDate}
        setRetainedUsersCount={setRetainedUsersCount}
        setDroppedUsersCount={setDroppedUsersCount}
        previewOrSharingMode={previewOrSharingMode}
        onAudienceOpen={onAudienceOpen}
      />
      {(!previewOrSharingMode && unit === "company" && (
        <CohortGroups
          key={cohortIntervalStartDate}
          isOpen={isOpen}
          onClose={onClose}
          intervalType={intervalType}
          cohortData={cohortData}
          cohortStartDate={cohortStartDate}
          cohortIntervalStartDate={cohortIntervalStartDate}
        />
      )) || (
        <CohortUsers
          key={cohortIntervalStartDate}
          report={report}
          isOpen={isOpen}
          onClose={onClose}
          intervalType={intervalType}
          cohortStartDate={cohortStartDate}
          cohortIntervalStartDate={cohortIntervalStartDate}
          retainedUsersCount={retainedUsersCount}
          droppedUsersCount={droppedUsersCount}
        />
      )}
    </Container>
  );
};
