import { ComponentDefaultProps } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Cell } from "@tanstack/react-table";
import { AnimatePresence } from "framer-motion";
import { CSSProperties } from "react";

import { Shadow } from "core/components/Table/People/Shadow";
import { bgColor } from "core/hooks/usePeopleTableColumns";
import { useTableTraitConfig } from "core/hooks/useTableTraitConfig";
import { IAppObject } from "core/types/AppObject";
import { IContact } from "core/types/Contact";
import { IGroup } from "core/types/Group";
import { cx } from "helpers/cx";

export const Td: React.FC<
  {
    cell: Cell<IGroup | IContact, unknown>;
    cellIndex: number;
    lastRow: boolean;
    showShadow: boolean;
    isSortedBy: boolean;
    appObject: IAppObject;
    highlightSorting?: boolean;
  } & ComponentDefaultProps
> = ({
  cell,
  cellIndex,
  lastRow,
  children,
  showShadow,
  isSortedBy,
  appObject,
  highlightSorting,
}) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const { tableTraitConfig } = useTableTraitConfig(
    appObject.objectType,
    cell.column.id,
  );

  const style: CSSProperties = {
    position: isDragging ? "relative" : cellIndex === 0 ? "sticky" : "relative",
    transform: CSS.Translate.toString(transform),
    transition: "width transform 0.2s ease-in-out",
    zIndex: cellIndex === 0 ? 2 : isDragging ? 2 : 1,
  };

  return (
    <td
      className={cx(
        "relative w-full border-gray-200 p-1 px-3 text-sm lg:table-cell",
        (cell.getValue() === "-" || cell.getValue() === undefined) &&
          "bg-gray-25",
        highlightSorting ? bgColor(tableTraitConfig, isSortedBy) : "bg-white",
        cellIndex === 0 ? "cursor-pointer pl-6 pr-3 hover:bg-gray-50" : "",
        cell.column.getIsPinned() === "left"
          ? "sticky left-0 block border-r"
          : "",
        lastRow ? "rounded-bl-lg" : "border-b",
      )}
      key={cell.id}
      style={style}
      ref={setNodeRef}
    >
      <div style={{ width: cell.column.getSize() }}>
        {children}
        <AnimatePresence>
          {cellIndex === 0 && showShadow && <Shadow height="41" />}
        </AnimatePresence>
      </div>
    </td>
  );
};
