import { Flex, Skeleton, Text } from "@chakra-ui/react";
import React from "react";

import { thousandsToK } from "core/helpers/thousands";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetAudienceCountQuery } from "core/models/audiences";
import { IAudience } from "core/types/Audience";

interface IAudienceCountCellProps {
  props: IAudience;
}

export const UsersCountCell: React.FC<IAudienceCountCellProps> = ({
  props,
}) => {
  const { id: appId } = useCurrentApp();
  const { data, isLoading, isFetching } = useGetAudienceCountQuery({
    appId: appId,
    audienceFilters: {
      filterGroups: props?.filterGroups,
      joinOperator: props?.joinOperator,
    },
    level: "user",
  });

  if (isLoading || isFetching)
    return (
      <Flex w="100%">
        <Skeleton h={2} w="50%" borderRadius="lg" />
      </Flex>
    );

  return (
    <Flex w="100%">
      <Text fontSize="sm" color="gray.600" justifyContent="center">
        {thousandsToK(data?.count || 0)}
      </Text>
    </Flex>
  );
};
