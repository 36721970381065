import { Center } from "@chakra-ui/react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { JuneSpinner } from "@/core/components/JuneSpinner";
import { PageContainer } from "core/components/PageContainer";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useNavigation } from "core/hooks/useNavigation";
import { useGetTraitListQuery } from "core/models/objects/traits";
import { ObjectContentSection } from "modules/Object/ContentSection";
import { ObjectHeader } from "modules/Object/Header";
import { ObjectNav } from "modules/Object/Nav";
import { ObjectSideSection } from "modules/Object/SideSection";

export const ObjectPage: React.FC = () => {
  const { appId, appObjectId, objectId } = useParams();
  const { data: traits, isLoading } = useGetTraitListQuery({
    appId: Number(appId),
    appObjectId: Number(appObjectId),
    objectId: String(objectId),
  });
  const { appObjects, setActiveAppObject } = useAppObjects();
  const currentObject = appObjects.find((o) => o.id === Number(appObjectId));

  const { navigation, toggleSidebar } = useNavigation();

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
    if (currentObject) setActiveAppObject(currentObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentObject]);

  if (isLoading || !traits || !currentObject) {
    return (
      <PageContainer className="ml-1" maxW="full" py="0" containerless>
        <Center h="full" w="full">
          <div className="flex flex-col items-center gap-2">
            <JuneSpinner />
            <p className="text-sm font-medium text-muted-foreground">
              Loading...
            </p>
          </div>
        </Center>
      </PageContainer>
    );
  }

  return (
    <PageContainer className="ml-1" maxW="full" py="0" containerless>
      <div className="flex h-full max-h-full w-full flex-col">
        <div className="sticky top-0 z-30 border-b border-gray-200 bg-white px-3 py-1">
          <ObjectNav traits={traits} />
        </div>
        <div className="border-b border-gray-200 bg-white px-3 py-4">
          <ObjectHeader traits={traits} />
        </div>
        <div className="flex min-h-0 flex-1">
          <div className="w-1/4 min-w-[300px] max-w-[450px] overflow-y-scroll border-r border-gray-200">
            <ObjectSideSection traits={traits} />
          </div>
          <div className="h-full w-full overflow-y-auto">
            <ObjectContentSection />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};
