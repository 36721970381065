import { Box, Flex, Text } from "@chakra-ui/layout";

import InstallButton from "modules/MobileWidgets/InstallButton";

export default function InstallSteps({ insightUrl, startStep = 1, ...props }) {
  return (
    <>
      <Flex
        flexGrow={1}
        borderBottomWidth={1}
        borderColor="gray.200"
        alignItems="center"
        justifyContent="center"
        py={16}
        px={8}
        {...props}
      >
        <Flex direction="column" alignItems="center">
          <Flex
            mb={4}
            w="40px"
            h="40px"
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            background="linear-gradient(135deg, #C3C3FC 15.79%, #6868F7 85.11%), #6868F7;"
          >
            <Text color="white" fontWeight={600}>
              {startStep}
            </Text>
          </Flex>
          <Text fontWeight={400} fontSize="xl" mb={2}>
            Copy the widget
          </Text>
          <Text color="gray.600" mb={10} textAlign="center">
            Touch the button below to copy the widget code you'll add to
            Scriptable.
          </Text>
          <InstallButton insightUrl={insightUrl} />
        </Flex>
      </Flex>

      <Flex
        flexGrow={1}
        borderBottomWidth={1}
        borderColor="gray.200"
        alignItems="center"
        justifyContent="center"
        py={16}
        px={8}
        {...props}
      >
        <Flex
          direction="column"
          alignItems="center"
          width="100%"
          maxWidth="4xl"
        >
          <Flex
            mb={4}
            w="40px"
            h="40px"
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            background="linear-gradient(135deg, #C3C3FC 15.79%, #6868F7 85.11%), #6868F7;"
          >
            <Text color="white" fontWeight={600}>
              {startStep + 1}
            </Text>
          </Flex>
          <Text fontWeight={400} fontSize="xl" mb={2}>
            Open Scriptable
          </Text>
          <Text color="gray.600" mb={10} textAlign="center">
            Open Scriptable, create a widget and paste the copied code.
          </Text>
          <Box
            minWidth="100%"
            maxWidth="4xl"
            background="gray.900"
            p={2}
            borderRadius="xl"
          >
            <div className="ipad-video-responsive">
              <iframe
                src="https://www.youtube.com/embed/VwXcRGaVy-M?playlist=VwXcRGaVy-M&autoplay=1&loop=1&mute=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                allowfullscreen
              ></iframe>
            </div>
          </Box>
        </Flex>
      </Flex>
      <Flex
        flexGrow={1}
        borderBottomWidth={1}
        borderColor="gray.200"
        alignItems="center"
        justifyContent="center"
        py={16}
        px={8}
        {...props}
      >
        <Flex
          direction="column"
          alignItems="center"
          width="100%"
          maxWidth="4xl"
        >
          <Flex
            mb={4}
            w="40px"
            h="40px"
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            background="linear-gradient(135deg, #C3C3FC 15.79%, #6868F7 85.11%), #6868F7;"
          >
            <Text color="white" fontWeight={600}>
              {startStep + 2}
            </Text>
          </Flex>
          <Text fontWeight={400} fontSize="xl" mb={2}>
            Add to home screen
          </Text>
          <Text color="gray.600" mb={10} textAlign="center">
            Tap and hold the iOS homepage, then select the Scriptable widget.
          </Text>

          <Box
            minWidth="100%"
            maxWidth="4xl"
            background="gray.900"
            p={2}
            borderRadius="xl"
          >
            <div className="ipad-video-responsive">
              <iframe
                src="https://www.youtube.com/embed/H0eGuJsPfeE?playlist=H0eGuJsPfeE&autoplay=1&loop=1&mute=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                allowfullscreen
              ></iframe>
            </div>
          </Box>
        </Flex>
      </Flex>

      <Flex
        flexGrow={1}
        borderBottomWidth={1}
        borderColor="gray.200"
        alignItems="center"
        justifyContent="center"
        py={16}
        px={8}
        {...props}
      >
        <Flex
          direction="column"
          alignItems="center"
          width="100%"
          maxWidth="4xl"
        >
          <Flex
            mb={4}
            w="40px"
            h="40px"
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            background="linear-gradient(135deg, #C3C3FC 15.79%, #6868F7 85.11%), #6868F7;"
          >
            <Text color="white" fontWeight={600}>
              {startStep + 3}
            </Text>
          </Flex>
          <Text fontWeight={400} fontSize="xl" mb={2}>
            You're done! 🎉
          </Text>
          <Text color="gray.600" mb={10} textAlign="center">
            The June widget will show the selected insight and refresh
            periodically.
          </Text>
        </Flex>
      </Flex>
    </>
  );
}
