import { Navigate, useParams } from "react-router-dom";

import { DEMO_APP_ID } from "core/constants/appIds";
import { Questions } from "modules/Onboarding/Questions";

const Onboarding = () => {
  const { appId } = useParams<{ appId?: string }>();

  if (Number(appId) === DEMO_APP_ID) {
    return <Navigate to={`/a/${appId}/home`} />;
  }

  return <Questions />;
};

export default Onboarding;
