import { Flex, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { Action } from "core/components/InsightCard/Action";
import { AddToViewAction } from "core/components/InsightCard/Actions/AddToViewAction";
import { SlackInsightEditorDrawer } from "core/components/InsightCard/SlackInsightEditorDrawer";
import ShareModal from "core/components/ShareModal";
import { DEMO_APP_ID } from "core/constants/appIds";
import { PublicIcon } from "core/design-system/components/Icon/Interface/PublicIcon";
import { ShareIcon } from "core/design-system/components/Icon/Interface/ShareIcon";
import { SlackIconDisabled } from "core/design-system/components/Icon/Interface/SlackIcon";
import { SlackIconEnabled } from "core/design-system/components/Icon/Interface/SlackIconEnabled";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetAlertQuery } from "core/models/alerts";
import { useGetInsightTokenQuery } from "core/models/insights";
import { AppObjectType } from "core/types/AppObject";
import { IReport } from "core/types/Report";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";

interface IActionsProps {
  title: any;
  insight: IInsightSection;
  config?: ITemplateConfig;
  report?: IReport;
  additionalConfigProps?: any;
}

export const Actions = ({
  title,
  insight,
  config,
  report,
  additionalConfigProps,
}: IActionsProps) => {
  const { id: appId } = useCurrentApp();
  const { activeAppObject } = useAppObjects();
  const { reportId } = useParams<{ reportId: string }>() as {
    reportId: string;
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryParams = new URLSearchParams(window.location.search);
  const showSlack = queryParams.get("showSlack");
  const insightParam = queryParams.get("insight");
  const [showSlackInsightEditor, setShowSlackInsightEditor] = useState(
    showSlack && insightParam === insight.slug,
  );

  const { data: alert, isLoading: isAlertLoading } = useGetAlertQuery({
    appId,
    reportId,
    insightId: insight.typeId,
    alertType: 0,
    objectType: activeAppObject?.objectType || AppObjectType.User,
  });

  function onOpenSlackSetup() {
    setShowSlackInsightEditor(true);
  }

  const { data: insightToken, isLoading } = useGetInsightTokenQuery({
    appId: appId,
    reportId: Number(reportId),
    insightType: insight.typeId,
  });

  const tokenData = insightToken?.token;
  const isPublic = Boolean(tokenData);
  const hasAlert = alert?.created && alert?.state === 1;

  const { slackAlertAvailable, shareable, downloadable } = insight;
  const isShareable = shareable === undefined ? true : shareable;
  const isDownloadable = downloadable === undefined ? true : downloadable;

  return (
    <Flex ml={2}>
      {isShareable && (
        <Action
          data-testid="insight-share-action"
          isEnabled
          onClick={onOpen}
          tooltipLabel={isPublic ? "Manage public sharing" : "Share this graph"}
        >
          {isPublic ? (
            <PublicIcon color="purple.500" h={5} w={5} />
          ) : (
            <ShareIcon color="gray.600" h={5} w={5} />
          )}
        </Action>
      )}

      {slackAlertAvailable && (
        <Action
          isEnabled={slackAlertAvailable && Number(appId) !== DEMO_APP_ID}
          onClick={onOpenSlackSetup}
          tooltipLabel="Setup a Slack alert to receive this graph daily, weekly or monthly straight into your Slack channel"
          data-testid="insight-slack-action"
        >
          <>
            {hasAlert ? (
              <SlackIconEnabled w={5} h={5} />
            ) : (
              <SlackIconDisabled color="gray.600" w={5} h={5} />
            )}
          </>
        </Action>
      )}

      {reportId && Boolean(insight.view) && (
        <AddToViewAction insight={insight} reportId={reportId} />
      )}

      {!isLoading && (
        <ShareModal
          title={title}
          unit={"insight"}
          isSharing={isPublic}
          isOpen={isOpen}
          onClose={onClose}
          tokenData={tokenData}
          insight={insight}
          isDownloadable={isDownloadable}
          config={config}
          additionalConfigProps={additionalConfigProps}
        />
      )}

      {slackAlertAvailable && !isAlertLoading && (
        <SlackInsightEditorDrawer
          isOpen={Boolean(showSlackInsightEditor)}
          onClose={() => setShowSlackInsightEditor(false)}
          report={report}
          config={config}
          insight={insight}
          isLoading={isAlertLoading}
          reportId={reportId}
          appId={String(appId)}
        />
      )}
    </Flex>
  );
};
