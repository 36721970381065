import { Button, Image } from "@chakra-ui/react";
import {
  BanknotesIcon,
  BoltIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { PresentationChartBarIcon, UsersIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";

import { usePaywallModal } from "core/hooks/usePaywallModal";

const features = [
  {
    text: "Product Analytics",
    image: "/paywall/product_analytics.png",
    bgColor: "bg-[#F9E2FB]",
    textColor: "text-[#560059]",
    icon: <PresentationChartBarIcon className="h-5 w-5" />,
  },
  {
    text: "Company-level Analytics",
    image: "/paywall/company_analytics.png",
    bgColor: "bg-[#C9F0FF]",
    textColor: "text-[#003D5A]",
    icon: <UsersIcon className="h-5 w-5" />,
  },
  {
    text: "Salesforce, HubSpot and Attio integrations",
    image: "/paywall/crm.png",
    bgColor: "bg-[#DAF9D4]",
    textColor: "text-[#005900]",
    icon: <BanknotesIcon className="h-5 w-5" />,
  },
  {
    text: "Custom insights with SQL and AI",
    image: "/paywall/june_ai.png",
    bgColor: "bg-[#FFF5C7]",
    textColor: "text-[#a68716]",
    icon: <SparklesIcon className="h-5 w-5" />,
  },
  {
    text: "Computed traits",
    image: "/paywall/computed_traits.png",
    bgColor: "bg-[#DAF9D4]",
    textColor: "text-[#005900]",
    icon: <BoltIcon className="h-5 w-5" />,
  },
];

export const UnifiedPricing: React.FC = () => {
  const [hoveredFeature, setHoveredFeature] = useState<number | null>(0);
  const { isTrialFinished } = usePaywallModal();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hoveredFeature === null) {
        setHoveredFeature(0);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [hoveredFeature]);

  return (
    <div className="flex w-full flex-col justify-center">
      {isTrialFinished && (
        <div className="pointer-events-none inset-x-0 bottom-0 mt-4 w-full px-8">
          <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-purple-600 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
            <p className="text-sm/6 text-white">
              <strong className="font-semibold">Your trial has ended</strong>
              <svg
                viewBox="0 0 2 2"
                aria-hidden="true"
                className="mx-2 inline size-0.5 fill-current"
              >
                <circle r={1} cx={1} cy={1} />
              </svg>
              <span
                className="font-semibold hover:cursor-pointer hover:underline"
                onClick={() => window.Intercom("show")}
              >
                Talk to us
              </span>{" "}
              to upgrade your plan
            </p>
          </div>
        </div>
      )}
      <div className="mx-8 mt-4 rounded-xl border border-gray-200 bg-white px-12 pb-8 pt-6 shadow-sm">
        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-2 py-3">
            <div className="mb-4 flex flex-col gap-2">
              <h1 className="text-xl font-bold">All access</h1>
              <div className="flex flex-col gap-1">
                <div className="flex items-end gap-2">
                  <p className="text-4xl font-bold">Talk with us</p>
                </div>
              </div>
              <div className="text-sm font-medium">
                Everything you need as a growing B2B SaaS company
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className={`hover:shadow-xs flex cursor-pointer items-center gap-2 rounded-lg p-1 pr-4 text-sm font-medium transition-all duration-200 ${
                    hoveredFeature === index
                      ? `${feature.bgColor} ${feature.textColor}`
                      : "hover:bg-gray-100"
                  }`}
                  onMouseEnter={() => setHoveredFeature(index)}
                  onMouseLeave={() => setHoveredFeature(null)}
                >
                  <span
                    className={`${feature.textColor} ${feature.bgColor} rounded-md p-1`}
                  >
                    {feature.icon}
                  </span>
                  {feature.text}
                </div>
              ))}
            </div>
          </div>
          <div className="ml-4">
            <Image
              src={features[hoveredFeature ?? 0].image}
              alt={features[hoveredFeature ?? 0].text}
              height={400}
            />
          </div>
        </div>
        <div className="mt-8 flex justify-center gap-2">
          <Button
            onClick={() => {
              window.Intercom(
                "showNewMessage",
                "Hi, I would like to request a quote for upgrading our plan.",
              );
            }}
            size="md"
            w="full"
          >
            Get a quote
          </Button>
          <Button
            as="a"
            href="https://cal.com/alberto-incisa/book-a-call"
            size="md"
            w="full"
            colorScheme="purple"
            target="_blank"
            rel="noopener noreferrer"
          >
            Book a call
          </Button>
        </div>
      </div>
    </div>
  );
};
