import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  BREAKDOWN_TYPE,
  CONVERSION_WINDOW_TYPE,
  ENUM_TYPE,
  MULTI_EVENT_TYPE,
  OCCURRENCES_TYPE,
  SETTINGS_TYPE,
  SINGLE_EVENT_TYPE,
} from "core/constants/report-setup";
import { AND } from "core/constants/traitFilterComponents";
import { uuid } from "core/helpers/uuid";
import { IAudience } from "core/types/Audience";
import { IEvent } from "core/types/Event";
import { IOption } from "core/types/Option";
import {
  IFilter,
  IReport,
  IReportConfig,
  IReportState,
} from "core/types/Report";
import { ITemplateConfigSetup } from "core/types/TemplateConfig";

function buildConfig({
  config,
  configKey,
  report,
}: {
  config: any;
  configKey: string;
  report: IReport;
}) {
  config[configKey] = report?.config?.[configKey]?.map((_event: IEvent) => {
    const updatedProperties =
      _event?.properties?.map((prop) => ({
        ...prop,
        adding: false,
      })) || [];
    const updatedEvent = { ..._event, properties: updatedProperties };
    return {
      ...updatedEvent,
      uuid: _event.uuid || uuid(),
    };
  });
}

export function resetIsAddingOnAudienceFilters(audience: IAudience) {
  const updatedFilterGroups = audience.filterGroups.map((filterGroup) => {
    const updatedFilters = filterGroup.filters.map(
      (filter: IFilter, index: number) => ({
        ...filter,
        id: index,
        adding: false,
      }),
    );
    return {
      ...filterGroup,
      filters: updatedFilters,
    };
  });

  return {
    ...audience,
    filterGroups: updatedFilterGroups,
  };
}

export const DEFAULT_AUDIENCE = {
  id: undefined,
  filterGroups: [],
  joinOperator: AND,
  isSavedAudience: false,
  name: undefined,
  description: undefined,
  reportId: undefined,
  contactCount: undefined,
  groupCount: undefined,
} as IAudience;

export const initialState: IReportState = {
  data: {
    id: undefined,
    appId: undefined,
    config: {
      breakdown: {},
    },
    audience: DEFAULT_AUDIENCE,
    timerangeType: undefined,
    name: undefined,
    reportType: undefined,
    timerangeValue: undefined,
    timerangeEndTimestamp: undefined,
    timerangeStartTimestamp: undefined,
  },
  isLoading: false,
  serializeKey: undefined,
  errors: {
    fetching: undefined,
  },
  isUpdating: false,
  hasBeenPersisted: true,
  isAlreadySetup: undefined,
  reportMailSetting: undefined,
};

export const slice = createSlice({
  name: "report",
  initialState,
  reducers: {
    _fetch: (state) => {
      state.isLoading = true;
    },
    _fetchSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    _fetchError: (state, { payload }) => {
      state.isLoading = false;
      state.errors.fetching = payload;
    },
    /**
     * Add a placeholder for a track event.
     *
     * The specific track event will have to be selected on a different reducer (see _selectTrackEvent).
     * Note that at this stage, only a dummy event is created, and more importantly, with a uuid to track its position in the UI in case of multiple events of the same type.
     */
    _addTrackEvent: (
      state,
      action: PayloadAction<{ event: IEvent; configKey: string }>,
    ) => {
      const event = action.payload.event;
      const configKey = action.payload.configKey;

      if (!state.data.config[configKey]) state.data.config[configKey] = [];

      state.hasBeenPersisted = false;
      state.data.config[configKey] = [...state.data.config[configKey], event];
    },
    /**
     * Select a track event.
     *
     * This is used to select a specific track event from a placeholder (see _addTrackEvent).
     */
    _selectTrackEvent: (
      state,
      action: PayloadAction<{ event: IEvent; configKey: string }>,
    ) => {
      const event = action.payload.event;
      const configKey = action.payload.configKey;
      const events = Array.isArray(state.data.config[configKey])
        ? state.data.config[configKey]
        : [state.data.config[configKey]];

      const index = events.findIndex((e: IEvent) => {
        return e.uuid === event.uuid;
      });

      if (index !== -1) {
        state.data.config[configKey][index] = { ...event, adding: false };
      } else {
        const _event = state.data.config[configKey];
        state.data.config[configKey] = [{ ..._event, ...event }];
      }

      state.hasBeenPersisted = false;
    },
    /**
     * Update a track event.
     *
     */
    _updateTrackEvent: (
      state,
      action: PayloadAction<{ event: IEvent; configKey: string }>,
    ) => {
      const event = action.payload.event;
      const configKey = action.payload.configKey;
      const index = state.data.config[configKey].findIndex(
        (e: IEvent) => e.uuid === event.uuid,
      );

      if (index !== -1) {
        state.data.config[configKey] = [
          ...state.data.config[configKey].slice(0, index),
          { ...state.data.config[configKey][index], ...event },
          ...state.data.config[configKey].slice(index + 1),
        ];
      }
      state.hasBeenPersisted = false;
    },
    _updateEventOrdering: (
      state,
      action: PayloadAction<{ configKey: string; events: IEvent[] }>,
    ) => {
      const events = action.payload.events.map((e, i) => ({ ...e, order: i }));
      const configKey = action.payload.configKey;
      state.data.config[configKey] = events;

      state.hasBeenPersisted = false;
    },
    _updatePageEvent: (
      state,
      action: PayloadAction<{ event: IEvent; configKey: string }>,
    ) => {
      const {
        payload: { event, configKey },
      } = action;
      state.data.config[configKey] = state.data.config[configKey].map(
        (_event: IEvent) => {
          if (_event.uuid === event.uuid) return { ..._event, ...event };
          return _event;
        },
      );
      state.hasBeenPersisted = false;
    },
    _removeTrackEvent: (
      state,
      action: PayloadAction<{ event: IEvent; configKey: string }>,
    ) => {
      const {
        payload: { event, configKey },
      } = action;

      state.hasBeenPersisted = false;
      state.data.config[configKey] = state.data.config[configKey].filter(
        (_event: IEvent) => _event.uuid !== event.uuid,
      );
    },
    _updateEnum: (state, { payload: { option, configKey } }) => {
      state.hasBeenPersisted = false;
      state.data.config[configKey] = [option];
    },
    _updateSetting: (state, { payload: { setting, configKey } }) => {
      state.hasBeenPersisted = false;
      if (state.data.config[configKey]) {
        state.data.config[configKey] = {
          ...state.data.config[configKey],
          ...setting,
        };
      } else {
        state.data.config[configKey] = setting;
      }
    },
    _updateReportLevelSuccess: (state, { payload: { report } }) => {
      state.isUpdating = false;
      state.hasBeenPersisted = true;
      state.data = report;
    },
    _updateReportLevelError: (state) => {
      state.isUpdating = false;
      state.hasBeenPersisted = false;
    },
    _updateReportTimerange: (
      state,
      {
        payload: {
          timerangeType,
          timerangeValue,
          timerangeStartTimestamp,
          timerangeEndTimestamp,
        },
      },
    ) => {
      state.data.timerangeType = timerangeType;
      state.data.timerangeValue = timerangeValue;
      state.data.timerangeStartTimestamp = timerangeStartTimestamp;
      state.data.timerangeEndTimestamp = timerangeEndTimestamp;
    },
    _updateReportTimerangeSuccess: (state, { payload: { report } }) => {
      state.isUpdating = false;
      state.data = report;
    },
    _updateReportTimerangeError: (state) => {
      state.isUpdating = false;
      state.hasBeenPersisted = false;
    },
    _updateReportType: (state, { payload: { reportType } }) => {
      state.data.reportType = reportType;
    },
    _updateReportTypeSuccess: (state, { payload: { report } }) => {
      state.isUpdating = false;
      state.data = report;
    },
    _updateReportTypeError: (state) => {
      state.isUpdating = false;
      state.hasBeenPersisted = false;
    },
    _updateSectionName: (state, { payload: { name, configKey } }) => {
      state.hasBeenPersisted = false;
      const updatedNameState = {
        ...state.data.config.name,
        [configKey]: name,
      };
      state.data.config.name = updatedNameState;
    },
    _loadReportSetup: (
      state,
      action: PayloadAction<{ report: IReport; setup: ITemplateConfigSetup }>,
    ) => {
      const report = action.payload.report;
      const setup = action.payload.setup;
      const config: IReportConfig = {};

      if (!report || !setup || !setup?.setupSections) return;

      setup.setupSections.forEach(
        ({
          configKey,
          type,
          options,
          isDynamic,
        }: {
          configKey: string;
          type: string;
          options?: IOption[];
          isDynamic?: boolean;
        }) => {
          if (type === ENUM_TYPE && options) {
            const noOption: IOption = {
              name: "",
              value: "",
              default: "",
              type: "",
            };
            const option =
              options.find(
                (_option) =>
                  _option.value ===
                  report.config[configKey as keyof IReportConfig],
              ) ||
              options.find((_option) => _option.default) ||
              noOption;
            config[configKey as keyof IReportConfig] = [option];
          } else if (type === SINGLE_EVENT_TYPE && report.config[configKey]) {
            const _events = report.config[configKey];
            const _event = Array.isArray(_events) ? _events[0] : _events;
            const updatedProperties =
              _event && _event.properties && _event.properties.length
                ? _event.properties.map((prop: IEvent) => ({
                    ...prop,
                    adding: false,
                  }))
                : [];
            const updatedEvent = { ..._event, properties: updatedProperties };
            config[configKey] = Array.isArray(updatedEvent)
              ? updatedEvent
              : [updatedEvent];
          } else if (type === MULTI_EVENT_TYPE) {
            if (isDynamic) {
              Object.keys(report?.config).map((cK) => {
                if (cK.includes(configKey)) {
                  buildConfig({ config, configKey: cK, report });
                }
                return null;
              });
            } else {
              buildConfig({ config, configKey, report });
            }
          } else if (
            type === CONVERSION_WINDOW_TYPE ||
            type === BREAKDOWN_TYPE ||
            type === OCCURRENCES_TYPE ||
            type === SETTINGS_TYPE
          ) {
            config[configKey] = report.config[configKey];
          }
        },
      );

      state.data = {
        ...report,
        config: {
          ...config,
          name: report?.config?.name,
          order: report?.config?.order,
        },
        audience: report.audience
          ? resetIsAddingOnAudienceFilters(report.audience)
          : initialState.data.audience,
      };

      state.serializeKey = report.serializeKey;
      state.hasBeenPersisted = true;
      state.isLoading = false;
      state.isAlreadySetup =
        Object.keys(report.config)
          .map((configKey) => {
            const _setup = setup.setupSections.find(
              (ss) => ss.configKey === configKey,
            );
            return _setup?.required && configKey;
          })
          .filter((x) => !!x).length === setup.requiredSections.length;

      return state;
    },
    _updateReportSetup(state) {
      state.isUpdating = true;
    },
    _updateReportSetupSuccess(state, { payload: { report } }) {
      state.isUpdating = false;
      state.hasBeenPersisted = true;
    },
    _updateReportSetupError(state) {
      state.isUpdating = false;
      state.hasBeenPersisted = false;
    },

    // Audience
    _addSavedAudience(state, { payload: { audience } }) {
      state.hasBeenPersisted = false;
      state.data.audience = audience;
    },
    _removeSavedAudience(state) {
      state.hasBeenPersisted = false;
      state.data.audience = initialState.data.audience;
    },
    _updateAudience(state, { payload: { audience } }) {
      state.hasBeenPersisted = false;
      if (state.data?.audience) {
        state.data.audience = audience;
      }
    },
    _addAudienceFilterGroup(state, { payload: { filterGroup } }) {
      state.hasBeenPersisted = false;
      if (state.data?.audience?.filterGroups) {
        state.data.audience.filterGroups =
          state.data.audience.filterGroups.concat([filterGroup]);
      }
    },
    _removeAudienceFilterGroup(state, { payload: { filterGroupIndex } }) {
      state.hasBeenPersisted = false;
      if (state.data?.audience?.filterGroups) {
        state.data.audience.filterGroups =
          state.data.audience.filterGroups.filter(
            (_filterGroup: any, index: number) => index !== filterGroupIndex,
          );
      }
    },
    _updateJoinOperator(state, { payload: { operator } }) {
      state.hasBeenPersisted = false;
      if (typeof state.data?.audience?.joinOperator === "number") {
        state.data.audience.joinOperator = operator;
      }
    },
    _addAudienceFilter(state, { payload: { filterGroupIndex, filter } }) {
      state.hasBeenPersisted = false;
      if (state.data?.audience?.filterGroups?.[filterGroupIndex].filters) {
        state.data.audience.filterGroups[filterGroupIndex].filters =
          state.data.audience.filterGroups[filterGroupIndex].filters.concat([
            filter,
          ]);
      }
    },
    _updateAudienceFilter(state, { payload: { filterGroupIndex, filter } }) {
      state.hasBeenPersisted = false;
      if (state.data?.audience?.filterGroups?.[filterGroupIndex]?.filters) {
        state.data.audience.filterGroups[filterGroupIndex].filters =
          state.data.audience.filterGroups[filterGroupIndex].filters.map(
            (_filter: any) => {
              if (_filter.id === filter.id) return { ..._filter, ...filter };
              return _filter;
            },
          );
      }
    },
    _removeAudienceFilter(state, { payload: { filterGroupIndex, filter } }) {
      state.hasBeenPersisted = false;
      let filterGroups = state.data?.audience?.filterGroups || [];
      if (filterGroups[filterGroupIndex]?.filters) {
        if (filterGroups[filterGroupIndex].filters.length === 1) {
          filterGroups = filterGroups.filter(
            (_filterGroup: any, index: number) => index !== filterGroupIndex,
          );
          state.data.audience.filterGroups =
            filterGroups.length > 0 ? filterGroups : [];
        } else {
          filterGroups[filterGroupIndex].filters = filterGroups[
            filterGroupIndex
          ].filters.filter((_filter: any) => {
            return _filter.id !== filter.id;
          });
        }
      } else {
        state.data.audience.filterGroups = [];
      }
    },
    _updateFilterGroupJoinOperator(
      state,
      { payload: { filterGroupIndex, operator } },
    ) {
      state.hasBeenPersisted = false;
      if (
        typeof state.data?.audience?.filterGroups?.[filterGroupIndex]
          .joinOperator === "number"
      ) {
        state.data.audience.filterGroups[filterGroupIndex].joinOperator =
          operator;
      }
    },

    // Conversion window
    _enableConversionWindow: (state) => {
      state.hasBeenPersisted = false;
      if (!state.data.config.conversionWindow) {
        state.data.config.conversionWindow = {};
      }
      state.data.config.conversionWindow.enabled = true;
    },
    _disableConversionWindow: (state) => {
      state.hasBeenPersisted = false;
      if (!state.data.config.conversionWindow) {
        state.data.config.conversionWindow = {};
      }
      state.data.config.conversionWindow.enabled = false;
    },
    _updateConversionWindowIntervalCount: (
      state,
      { payload: { intervalCount } },
    ) => {
      state.hasBeenPersisted = false;
      if (!state.data.config.conversionWindow) {
        state.data.config.conversionWindow = {};
      }
      state.data.config.conversionWindow.intervalCount = intervalCount;
    },
    _updateConversionWindowIntervalType: (
      state,
      { payload: { intervalType } },
    ) => {
      state.hasBeenPersisted = false;
      if (!state.data.config.conversionWindow) {
        state.data.config.conversionWindow = {};
      }
      state.data.config.conversionWindow.intervalType = intervalType;
    },

    // Breakdown
    _addBreakdown: (state, { payload: { breakdown } }) => {
      state.hasBeenPersisted = false;
      state.data.config[BREAKDOWN_TYPE] = breakdown;
    },
    _removeBreakdown: (state) => {
      state.hasBeenPersisted = false;
      state.data.config[BREAKDOWN_TYPE] = {};
    },

    // Occurrences
    _editOccurrences: (state, { payload: { occurrences } }) => {
      state.hasBeenPersisted = false;
      state.data.config[OCCURRENCES_TYPE] = occurrences;
    },

    // Setting
    _updateMailSetting: (state, { payload: { isEnabled } }) => {
      state.hasBeenPersisted = false;
      if (state.data.reportMailSetting) {
        state.data.reportMailSetting = {
          ...state.data?.reportMailSetting,
          isEnabled,
        };
      }
    },

    // Funnel
    _addFunnelStep: (state, { payload: { configKey } }) => {
      state.hasBeenPersisted = false;
      state.data.config[configKey] = [];
      if (!state.data.config.name) state.data.config.name = {};
      if (!state.data.config.order) state.data.config.order = [];
      state.data.config.name[configKey] = "Untitled";
      state.data.config.order = state.data.config.order.concat(configKey);
    },
    _removeFunnelStep: (state, { payload: { configKey } }) => {
      state.hasBeenPersisted = false;
      const newConfig = state.data.config;
      delete newConfig[configKey];
      delete newConfig.name[configKey];
      const newOrder = newConfig.order.filter((o: string) => o !== configKey);
      newConfig.order = newOrder;

      state.data.config = {
        ...state.data.config,
        ...newConfig,
      };
    },
    _updateFunnelOrder: (state, { payload: { order } }) => {
      state.hasBeenPersisted = false;
      state.data.config.order = order;
    },
  },
});

export default slice.reducer;
