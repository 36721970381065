import { useId } from "react";
import { useParams } from "react-router-dom";

import { useAppObjects } from "@/core/hooks/useAppObjects";
import { useCompanyView } from "@/core/hooks/useCompanyView";
import { Measure, GraphType } from "@/core/types/ViewInsight";
import { InsightEditorContainer } from "@/modules/Dashboard/InsightBuilder/EditorContainer";
import { JuneLoadingPage } from "@/modules/Dashboard/LoadingPage";
import {
  useGetViewInsightPreviewQuery,
  useGetViewInsightQuery,
} from "core/models/viewInsights";

export const AddObjectInsightRoute: React.FC = () => {
  const { appId } = useParams();
  const { userAppObject } = useAppObjects();
  const id = useId();
  const { view, isLoadingView } = useCompanyView();
  const { data: previewInsight, isLoading } = useGetViewInsightPreviewQuery({
    appId: Number(appId),
    viewId: Number(view?.id),
    measure: Measure.ActiveUsers,
    graphType: GraphType.Area,
    objectType: userAppObject?.objectType,
    uuid: id,
  });
  const { data: viewInsight, isLoading: isLoadingViewInsight } =
    useGetViewInsightQuery({
      id: Number(previewInsight?.id),
      viewId: Number(view?.id),
      appId: Number(appId),
    });

  if (isLoading || isLoadingView || isLoadingViewInsight || !previewInsight) {
    return <JuneLoadingPage />;
  }

  if (!viewInsight) return <>View Insight not found</>;

  return (
    <div className="h-screen w-full overflow-hidden">
      <InsightEditorContainer
        viewInsight={viewInsight}
        isAddingNewInsight={true}
      />
    </div>
  );
};
