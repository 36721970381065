import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";

import { baseQuery } from "core/initializers/rtk";
import { IPaginatedPropertyValues } from "core/types/Property";

interface IGetPropertiesArgs {
  appId: number;
  eventId: number;
  withExampleValue?: boolean;
}

export type EventProperty = {
  property: string;
  count: number;
  value: string;
};
export type EventProperties = EventProperty[];

export const propertiesApi = createApi({
  baseQuery,
  reducerPath: "propertiesApi",
  endpoints: (builder) => ({
    getPropertyValues: builder.query({
      query: ({
        appId,
        eventId,
        property,
        days,
        page,
        searchTerm,
      }: {
        appId: number;
        eventId: number;
        property?: string;
        days?: number;
        page?: number;
        searchTerm?: string;
      }) => ({
        method: "GET",
        url: `properties/values`,
        params: {
          app_id: appId,
          event_id: eventId,
          property,
          days,
          page: page ?? 1,
          ...(searchTerm && { search_term: searchTerm }),
        },
      }),
      transformResponse: (response) =>
        humps.camelizeKeys(response as object) as IPaginatedPropertyValues,
    }),
    getProperties: builder.query<EventProperties, IGetPropertiesArgs>({
      query: ({ appId, eventId, withExampleValue = false }) => ({
        method: "GET",
        url: `properties`,
        params: {
          app_id: appId,
          event_id: eventId,
          with_example_value: withExampleValue,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetPropertyValuesQuery,
  useGetPropertyValuesQuery,
  useGetPropertiesQuery,
} = propertiesApi;
