import { capitalize } from "lodash";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { useAppObjects } from "core/hooks/useAppObjects";
import { useToast } from "core/hooks/useToast";
import {
  useGetCrmSyncSettingsQuery,
  useUpdateCrmSyncSettingMutation,
} from "core/models/crmSyncSettings";
import { dataMappingsApi, Integration } from "core/models/dataMappings";
import { AppObjectType } from "core/types/AppObject";
import { Frequency } from "core/types/Crm";

interface IUseCrmSyncSettings {
  integration: Integration;
  objectType: AppObjectType;
}

export function useCrmSyncSettings({
  integration,
  objectType,
}: IUseCrmSyncSettings) {
  const { appId } = useParams();
  const dispatch = useDispatch();
  const { data: crmSyncSettings, isLoading: isLoadingCrmSyncSettings } =
    useGetCrmSyncSettingsQuery({ appId: Number(appId), integration });
  const { userAppObject, companyAppObject, groupAppObject } = useAppObjects();
  const [updateCrmSyncSetting, { isLoading: isUpdatingCrmSyncSetting }] =
    useUpdateCrmSyncSettingMutation();
  const toast = useToast();

  const OBJECT_TYPE_TO_SLUG = {
    [AppObjectType.User]: userAppObject?.pluralName,
    [AppObjectType.Company]: companyAppObject?.pluralName,
    [AppObjectType.Group]: groupAppObject?.pluralName,
  };

  function findByObjectType(objectType: AppObjectType) {
    if (!crmSyncSettings || crmSyncSettings.length === 0) return null;

    return crmSyncSettings.find(
      (css) => css.appObject.objectType === objectType,
    );
  }

  function onUpdateCrmSyncSetting(
    id: number,
    params: {
      createEnabled?: boolean;
      updateEnabled?: boolean;
      frequency?: Frequency;
      objectName?: string;
      objectSlug?: string;
      associate?: boolean;
    },
  ) {
    updateCrmSyncSetting({ appId: Number(appId), id, ...params })
      .unwrap()
      .then(() => {
        dispatch(dataMappingsApi.util.invalidateTags(["DataMappings"]));

        if (params.createEnabled !== undefined)
          toast({
            status: "success",
            title: "Sync settings updated successfully",
            description: `We'll ${
              params.createEnabled ? "create" : "stop creating"
            } your ${OBJECT_TYPE_TO_SLUG[objectType]?.toLowerCase()} in ${capitalize(
              integration,
            )} at the next sync`,
          });

        if (params.updateEnabled !== undefined)
          toast({
            status: "success",
            title: "Sync settings updated successfully",
            description: `We'll now ${
              params.updateEnabled ? "update" : "stop updating"
            } your ${OBJECT_TYPE_TO_SLUG[objectType]?.toLowerCase()} in ${capitalize(
              integration,
            )} at the next sync`,
          });

        if (params.associate !== undefined)
          toast({
            status: "success",
            title: "Sync settings updated successfully",
            description: `We'll now start associating your ${OBJECT_TYPE_TO_SLUG[objectType]?.toLowerCase()} in ${capitalize(
              integration,
            )} at the next sync`,
          });
      });
  }

  return {
    crmSyncSettings,
    userCrmSyncSettings: findByObjectType(AppObjectType.User),
    companyCrmSyncSettings: findByObjectType(AppObjectType.Company),
    groupCrmSyncSettings: findByObjectType(AppObjectType.Group),
    isLoadingCrmSyncSettings,
    crmSyncSetting: findByObjectType(objectType),
    onUpdateCrmSyncSetting,
    isUpdatingCrmSyncSetting,
  };
}
