import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { TwEditable } from "core/design-system/components/TwEditable";
import { ITraitWithConfig } from "core/types/Trait";
import { cx } from "helpers/cx";

export const Title: React.FC<{
  trait?: ITraitWithConfig;
  onChangeTitle: (val: string) => void;
  onChangeDescription: (val: string) => void;
  compact?: boolean;
}> = ({ trait, onChangeTitle, onChangeDescription, compact }) => {
  if (compact) {
    return (
      <div className="flex flex-col">
        {trait?.isComputed ? (
          <Tooltip>
            <TooltipTrigger>
              <p className="text-left text-xl font-medium">
                {trait?.name || "New trait"}
              </p>
            </TooltipTrigger>
            <TooltipContent>
              The name of a computed trait cannot be modified once created.
            </TooltipContent>
          </Tooltip>
        ) : (
          <Tooltip>
            <TooltipTrigger>
              <TwEditable
                defaultValue={trait?.name || "New trait"}
                onSubmit={(val: string) => onChangeTitle(val)}
                placeholder={"Untitled "}
                fontWidth={14}
                className={cx("text-xl font-medium text-black")}
                inputClassName={cx("text-xl font-medium border-gray-300")}
              />
            </TooltipTrigger>
            <TooltipContent>Click to edit the trait name</TooltipContent>
          </Tooltip>
        )}
        <div className="max-w-[220px] truncate">
          <Tooltip>
            <TooltipTrigger>
              <TwEditable
                defaultValue={trait?.description || "Add a description..."}
                placeholder="Add a description..."
                onSubmit={(val: string) => onChangeDescription(val)}
                className="text-sm text-gray-600"
                textColor="text-gray-600"
                inputClassName="border-gray-300 text-sm text-gray-600"
              />
            </TooltipTrigger>
            <TooltipContent>Click to edit the description</TooltipContent>
          </Tooltip>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <div>
        {trait?.isComputed ? (
          <Tooltip>
            <TooltipTrigger>
              <p className="text-xl font-medium">
                {trait?.name || "New trait"}
              </p>
            </TooltipTrigger>
            <TooltipContent>
              The name of a computed trait cannot be modified once created.
            </TooltipContent>
          </Tooltip>
        ) : (
          <Tooltip>
            <TooltipTrigger>
              <TwEditable
                defaultValue={trait?.name || "New trait"}
                onSubmit={(val: string) => onChangeTitle(val)}
                placeholder={"Untitled "}
                fontWidth={14}
                className={cx("text-xl font-medium text-black")}
                inputClassName={cx("text-xl font-medium border-gray-300")}
              />
            </TooltipTrigger>
            <TooltipContent>Click to edit the trait name</TooltipContent>
          </Tooltip>
        )}
      </div>
      <div>
        <Tooltip>
          <TooltipTrigger>
            <TwEditable
              defaultValue={trait?.description || "Add a description..."}
              placeholder="Add a description..."
              onSubmit={(val: string) => onChangeDescription(val)}
              className="text-sm text-gray-500"
              inputClassName="border-gray-300"
            />
          </TooltipTrigger>
          <TooltipContent>Click to edit the description</TooltipContent>
        </Tooltip>
      </div>
    </div>
  );
};
