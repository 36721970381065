import { ThresholdType } from "modules/Events/List/EventAlertEditor";

export const EventAlertThresholdEditor: React.FC<{
  thresholdType: ThresholdType;
  threshold: number;
  setThresholdType: (thresholdType: ThresholdType) => void;
  setThreshold: (threshold: number) => void;
}> = ({ threshold, thresholdType, setThresholdType, setThreshold }) => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <p className="text-sm font-semibold">Thresholds</p>
        <p className="text-sm text-gray-600">
          Receive an alert every time the event is triggered or only when it's
          triggered an exact number of times
        </p>
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center">
          <input
            id={ThresholdType.Recurring}
            name="notification-method"
            type="radio"
            defaultChecked={thresholdType === ThresholdType.Recurring}
            className="h-4 w-4 border-gray-300 text-purple-600 focus:ring-purple-600"
            onClick={() => setThresholdType(ThresholdType.Recurring)}
          />
          <label
            htmlFor={ThresholdType.Recurring}
            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
          >
            Every time
          </label>
        </div>
        <div>
          <div className="flex items-center">
            <input
              id={ThresholdType.Recurring}
              name="notification-method"
              type="radio"
              defaultChecked={thresholdType === ThresholdType.OneTime}
              className="h-4 w-4 border-gray-300 text-purple-600 focus:ring-purple-600"
              onClick={() => setThresholdType(ThresholdType.OneTime)}
            />
            <label
              htmlFor={ThresholdType.OneTime}
              className="ml-3 block text-sm font-medium leading-6 text-gray-900"
            >
              <div className="flex items-center gap-1">
                {" "}
                <p>Exactly </p>
                <input
                  type="number"
                  name="threshold"
                  id="threshold"
                  className="w-full max-w-[50px] rounded-md border-gray-300 py-1 text-sm text-gray-900 shadow-sm focus:border-purple-600 focus:ring-purple-600"
                  placeholder="Enter threshold"
                  value={threshold}
                  min="0"
                  onChange={(e) => setThreshold(Number(e.target.value))}
                />{" "}
                <p>{threshold === 1 ? "time" : "times"}</p>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
