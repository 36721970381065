import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { JuneSpinner } from "@/core/components/JuneSpinner";
import { useAppObjects } from "@/core/hooks/useAppObjects";

export const Contact = () => {
  const { appId, contactId: encodedContactId } = useParams<{
    appId: string;
    contactId: string;
  }>() as {
    appId: string;
    contactId: string;
  };
  const navigate = useNavigate();
  const id = decodeURIComponent(encodedContactId);
  const { userAppObject } = useAppObjects();

  useEffect(() => {
    if (appId && id && userAppObject) {
      navigate(`/a/${appId}/objects/${userAppObject.id}/object/${id}`);
    }
  }, [appId, id, navigate, userAppObject]);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <JuneSpinner />
    </div>
  );
};
