import { IListConfig } from "core/components/List/List.types";
import CompanyNameCell from "modules/Companies/List/CompanyNameCell";

const config = {
  resourceName: "companies",
  columns: [
    {
      title: "Name",
      name: "name",
      width: 400,
      Component: CompanyNameCell,
      align: "left",
    },
  ],
} as IListConfig;

export { config };
