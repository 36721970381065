import { Button, Menu, MenuButton, Tooltip } from "@chakra-ui/react";

import { IAppObject } from "core/types/AppObject";

export const SelectObject: React.FC<{
  appObject: IAppObject;
}> = ({ appObject }) => {
  return (
    <div className="flex justify-between pb-4 pt-2 text-sm">
      <div className="flex flex-col gap-1">
        <div>Which Salesforce object to sync to</div>
        <div className="text-gray-600">
          Which Salesforce object June{" "}
          {appObject.pluralName.toLocaleLowerCase()} will sync to
        </div>
      </div>
      <div>
        <Menu>
          <Tooltip label="Coming soon" hasArrow>
            <MenuButton bg="gray.50" border="none" as={Button} isDisabled>
              Accounts
            </MenuButton>
          </Tooltip>
        </Menu>
      </div>
    </div>
  );
};
