import { Badge, ComponentDefaultProps, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

import { ContactBody } from "core/components/Contact/ContactBody";
import {
  getDarkColor,
  getLightColor,
} from "core/design-system/charts/Breakdown/utils";
import { Icon } from "core/design-system/components/Icon";

interface ISingleContactProps extends ComponentDefaultProps {
  id: string;
  traits: { [key: string]: string };
  previewMode?: boolean;
  description?: string | React.ReactNode;
  truncateName?: boolean;
  hideHoverBorder?: boolean;
  hideExternalLinkIcon?: boolean;
  isNewUser?: string;
  isIdentified?: boolean;
  isAnonymous?: boolean;
  traitValue?: string;
  traitColor?: string;
}

export const UserListSingleContact: React.FC<ISingleContactProps> = ({
  id,
  description,
  traits = {},
  previewMode = false,
  p = 2,
  maxW = 200,
  truncateName = false,
  hideHoverBorder = false,
  hideExternalLinkIcon = false,
  isNewUser = "no",
  isIdentified = true,
  isAnonymous = false,
  traitValue,
  traitColor,
}) => {
  const { appId: stringAppId } = useParams<{ appId: string }>();
  const appId = Number(stringAppId);
  const [showExternalLinkIcon, setShowExternalLinkIcon] = useState(false);

  if (previewMode) {
    return (
      <Flex
        p={p}
        align="center"
        justify="space-between"
        border={hideHoverBorder ? "none" : "1px"}
        borderRadius="lg"
        borderColor="white"
        _hover={{
          borderColor: hideHoverBorder ? "" : "gray.200",
          cursor: "pointer",
        }}
        data-testid={`selector-clickable-contact-${id}`}
      >
        <ContactBody
          userId={id}
          description={description}
          truncateName={truncateName}
          previewMode={previewMode}
        />
      </Flex>
    );
  }

  if (isAnonymous) {
    return (
      <Flex
        p={p}
        align="center"
        justify="space-between"
        border={hideHoverBorder ? "none" : "1px"}
        borderRadius="lg"
        borderColor="white"
        _hover={{ cursor: "pointer" }}
        data-testid={`selector-clickable-contact-${id}`}
      >
        <ContactBody
          userId={id}
          contact={{ id, appId, traits, isIdentified, isAnonymous }}
          description={description}
          truncateName={truncateName}
        />
      </Flex>
    );
  }

  return (
    <Link
      to={`/a/${appId}/contact/${encodeURIComponent(id)}`}
      target="_blank"
      rel="noopener noreferrer"
      onMouseEnter={() => setShowExternalLinkIcon(true)}
      onMouseLeave={() => setShowExternalLinkIcon(false)}
    >
      <Flex
        p={p}
        align="center"
        justify="space-between"
        border={hideHoverBorder ? "none" : "1px"}
        borderRadius="lg"
        borderColor="white"
        _hover={{
          borderColor: hideHoverBorder ? "" : "gray.200",
          cursor: "pointer",
        }}
        data-testid={`selector-clickable-contact-${id}`}
      >
        <ContactBody
          userId={id}
          contact={{ id, appId, traits, isIdentified, isAnonymous }}
          description={description}
          truncateName={truncateName}
          maxW={maxW}
        />
        <Flex align="center">
          {!hideExternalLinkIcon && showExternalLinkIcon && (
            <Icon
              mr={4}
              h={4}
              w={4}
              iconType="icon"
              cursor="pointer"
              color="gray.600"
              name="external-link"
            />
          )}
          {isNewUser === "yes" && <Badge colorScheme="purple">New user</Badge>}
          {typeof traitValue !== "undefined" && traitColor && (
            <Badge
              bg={getLightColor(traitColor)}
              color={getDarkColor(traitColor)}
            >
              {traitValue}
            </Badge>
          )}
        </Flex>
      </Flex>
    </Link>
  );
};
