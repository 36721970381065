import { Switch } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { useUpdateAppObjectMutation } from "core/models/appObjects";
import { IAppObject } from "core/types/AppObject";

export const ObjectRow: React.FC<{ appObject: IAppObject }> = ({
  appObject,
}) => {
  const [updateAppObject] = useUpdateAppObjectMutation();

  const handleToggle = () => {
    updateAppObject({
      appId: appObject.appId,
      id: appObject.id,
      isEnabled: !appObject.isEnabled,
    });
  };

  return (
    <tr key={appObject.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        {appObject.pluralName}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
        {appObject.slug}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
        <Switch
          className="ml-1"
          size="sm"
          colorScheme="purple"
          isChecked={appObject.isEnabled}
          onChange={handleToggle}
          isDisabled={appObject.objectType === "identify"}
        />
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
        <Link
          to={`./${appObject.id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          Edit
          <span className="sr-only">, {appObject.singularName}</span>
        </Link>
      </td>
    </tr>
  );
};
