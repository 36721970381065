import { Tooltip } from "@chakra-ui/react";

import { TooltipBody } from "core/components/TooltipBody";

export const OnlineDot: React.FC = () => {
  return (
    <Tooltip
      label={<TooltipBody text={"Active in the last 5 minutes"} />}
      borderRadius="lg"
      closeDelay={100}
      closeOnClick={false}
      placement="right"
    >
      <div className="absolute -bottom-2 -right-2 inline-flex h-6 w-6 rounded-full border-4 border-white bg-green-300"></div>
    </Tooltip>
  );
};
