import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useToast } from "core/hooks/useToast";
import {
  ISalesforceSetting,
  useCreateSettingMutation,
  useGetSettingQuery,
} from "core/models/salesforceSettings";

export const useSalesforceSettings = () => {
  const { appId } = useParams();
  const [setting, setSetting] = useState<ISalesforceSetting>({
    clientKey: "",
    clientSecret: "",
    appId: Number(appId),
    id: undefined,
    accessToken: undefined,
    refreshToken: undefined,
    instanceUrl: undefined,
    salesforceId: undefined,
    tokenIssuedAt: undefined,
  });
  const { data, isLoading } = useGetSettingQuery({ appId: Number(appId) });
  const [createSetting] = useCreateSettingMutation();
  const toast = useToast();

  useEffect(() => {
    if (data) {
      setSetting(data);
    }
  }, [appId, data]);

  function onDeleteSetting() {}
  function onCreateSetting() {
    createSetting({ appId: Number(appId), setting })
      .unwrap()
      .then(() => {
        window.location.replace(
          `${import.meta.env.VITE_API_HOST}/auth/salesforce/install`,
        );
      })
      .catch((error) => {
        toast({
          title: "Failed to create event",
          description: error.data.message,
          status: "error",
        });
      });
  }

  return {
    setting,
    setClientKey: (clientKey: string) =>
      setSetting(
        (prevSetting) => ({ ...prevSetting, clientKey }) as ISalesforceSetting,
      ),
    setClientSecret: (clientSecret: string) =>
      setSetting(
        (prevSetting) =>
          ({ ...prevSetting, clientSecret }) as ISalesforceSetting,
      ),
    isLoadingSetting: isLoading,
    hasSalesforceInstalled: Boolean(setting?.accessToken),
    onDeleteSetting,
    onCreateSetting,
  };
};
