import { IListConfig } from "core/components/List/List.types";
import { ContactNameCell } from "modules/Contacts/ContactNameCell";

const config = {
  resourceName: "users",
  columns: [
    {
      title: "User",
      name: "email",
      width: 400,
      Component: ContactNameCell,
      align: "left",
      tooltip:
        "We refer to a user as 'Unknown' if we're not able to find 'firstName', 'lastName', 'name', 'username', or 'email' in their traits.",
    },
  ],
} as IListConfig;

export { config };
