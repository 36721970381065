import { useState } from "react";

import { SEGMENT } from "core/constants/apiKeys";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  IOnboardingChecklist,
  useGetOnboardingChecklistQuery,
  useUpdateOnboardingChecklistMutation,
} from "core/models/onboardingChecklist";
import { ISDK, SDKs } from "modules/Onboarding/SetupSources/SDKs";

function gettingStartedBannerChecklistComplete(
  onboardingChecklist: IOnboardingChecklist,
) {
  const { connectedDataSource, isIdentifyingUsers, isSendingEvents } =
    onboardingChecklist;
  return connectedDataSource && isIdentifyingUsers && isSendingEvents;
}

export const useOnboardingChecklist = (options?: {
  pollingEnabled?: boolean;
}) => {
  const { business, id: appId } = useCurrentApp();
  const [sdkLanguage, setSdkLanguage] = useState<ISDK>(SDKs[0]);

  const { data: onboardingChecklist, isLoading } =
    useGetOnboardingChecklistQuery(
      { appId },
      {
        pollingInterval: options?.pollingEnabled ? 2000 : undefined,
      },
    );

  const [updateOnboardingChecklist] = useUpdateOnboardingChecklistMutation();

  function setSdk(_sdk: ISDK) {
    setSdkLanguage(_sdk);
  }

  const shouldShowGettingStartedBanner =
    onboardingChecklist &&
    !gettingStartedBannerChecklistComplete(onboardingChecklist);

  const gettingStartedItems = [
    onboardingChecklist?.connectedDataSource,
    onboardingChecklist?.isIdentifyingUsers,
    onboardingChecklist?.isSendingEvents,
    business === "b2b" ? onboardingChecklist?.callBooked : true,
  ];
  const isSetupComplete = [
    onboardingChecklist?.connectedDataSource,
    onboardingChecklist?.isIdentifyingUsers,
    onboardingChecklist?.isSendingEvents,
  ].every(Boolean);
  const gettingStartedItemsCompleted = gettingStartedItems.filter(
    (item) => item,
  ).length;
  const isChecklistComplete =
    gettingStartedItemsCompleted === gettingStartedItems.length;

  const onboardingProgressPercentage = Math.round(
    (gettingStartedItemsCompleted / gettingStartedItems.length) * 100,
  );

  return {
    isLoading,
    onboardingChecklist,
    isChecklistComplete,
    isSetupComplete,
    dataSource: onboardingChecklist?.dataSource || SEGMENT,
    sdk: sdkLanguage,
    updateOnboardingChecklist,
    onboardingProgressPercentage,
    setSdk,
    shouldShowGettingStartedBanner,
  };
};
