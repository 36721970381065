import { Select } from "@chakra-ui/react";
import { formatInTimeZone } from "date-fns-tz";
import React from "react";

import { FREQUENCIES, IAlertFrequencyEnum } from "core/constants/alert";

interface IEventAlertFrequencyEditorProps {
  frequency: {
    value: IAlertFrequencyEnum;
    label: string;
  };
  setFrequency: (value: IAlertFrequencyEnum) => void;
  preferredUtcHour?: number;
  setPreferredUtcHour?: (hour: number) => void;
}

export const EventAlertFrequencyEditor: React.FC<
  IEventAlertFrequencyEditorProps
> = ({
  frequency,
  setFrequency,
  preferredUtcHour = 11,
  setPreferredUtcHour,
}) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const getTimeDisplay = (utcHour: number, includeTimezone = true) => {
    const today = new Date();
    const utcDate = new Date(
      Date.UTC(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getUTCDate(),
        utcHour,
      ),
    );

    return formatInTimeZone(
      utcDate,
      includeTimezone ? userTimezone : "UTC",
      "h a",
    );
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col">
        <h3 className="mb-1 text-sm font-medium">Alert frequency</h3>
        <p className="mb-3 text-sm text-gray-600">
          Choose how often you want to receive updates about this event
        </p>
        <Select
          size="sm"
          w="md"
          borderRadius="lg"
          value={frequency.value}
          aria-label="Alert frequency"
          onChange={(e) => {
            const newValue = Number(e.target.value) as IAlertFrequencyEnum;
            setFrequency(newValue);
          }}
        >
          {FREQUENCIES.map((f, i) => (
            <option key={i} value={f.value}>
              {f.label} - {f.description}
            </option>
          ))}
        </Select>
      </div>

      {frequency.value !== IAlertFrequencyEnum.Instant && (
        <div className="flex flex-col">
          <h3 className="mb-1 text-sm font-medium">Delivery time</h3>
          <p className="mb-3 text-sm text-gray-600">
            Select when you'd like to receive these alerts in your timezone
          </p>
          <Select
            size="sm"
            w="md"
            borderRadius="lg"
            value={preferredUtcHour}
            aria-label="Delivery time"
            onChange={(e) => setPreferredUtcHour?.(Number(e.target.value))}
          >
            {Array.from({ length: 24 }, (_, i) => (
              <option key={i} value={i}>
                {getTimeDisplay(i, false)} UTC
              </option>
            ))}
          </Select>
          <p className="mt-2 text-sm text-gray-600">
            Will be delivered at {getTimeDisplay(preferredUtcHour)} in your
            timezone ({userTimezone})
          </p>
        </div>
      )}
    </div>
  );
};
