import { ComponentDefaultProps } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { useToast } from "core/hooks/useToast";
import { cx } from "helpers/cx";

interface EditableProps extends ComponentDefaultProps {
  defaultValue: string;
  onSubmit: (value: string) => void;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  fontSize?: string;
  textColor?: string;
  fontStyle?: string;
  fontWidth?: number;
  maxLength?: number;
}

export const TwEditable: React.FC<EditableProps> = ({
  defaultValue,
  onSubmit,
  onChange,
  className,
  inputClassName,
  placeholder = "Click to edit",
  fontSize = "text-md",
  textColor = "text-gray-900",
  fontStyle = "font-normal",
  fontWidth = 9,
  maxLength = 50,
  ...props
}) => {
  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [isDisplayingError, setIsDisplayingError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  const handleBlur = () => {
    setEditing(false);
    onSubmit(value);
  };

  const handleClick = () => {
    setEditing(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > maxLength) {
      if (!isDisplayingError) {
        toast({
          title: `The maximum length is ${maxLength} characters.`,
          status: "error",
        });
        setIsDisplayingError(true);
        setTimeout(() => setIsDisplayingError(false), 4000);
      }
      return;
    }

    setValue(e.target.value);
    onChange?.(e.target.value);
  };

  const inputWidth =
    value.length === 0
      ? fontWidth * 16
      : value.length * fontWidth < 90
        ? value.length * fontWidth + fontWidth * 1.2
        : value.length * fontWidth;

  const maxLengthExceeded = maxLength - value.length === 0;

  return (
    <div
      className={cx(
        "block cursor-text overflow-visible truncate border border-b-2 border-dashed border-transparent px-0 text-left transition-colors duration-200 ease-linear",
        isEditing
          ? "border-b-gray-400"
          : "border-b-gray-400 hover:border-b-black",
        maxLengthExceeded && "border-b-red-500",
        className,
      )}
      onClick={handleClick}
      {...props}
    >
      {isEditing ? (
        <input
          type="text"
          placeholder={placeholder}
          ref={inputRef}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          className={cx(
            "h-full w-fit border-none px-0 py-0 shadow-none focus:ring-0",
            fontSize,
            fontStyle,
            inputClassName,
          )}
          style={{ width: `${inputWidth}px` }}
        />
      ) : (
        <div
          className={cx(
            "h-full w-fit truncate rounded-md px-0 py-0",
            fontSize,
            textColor,
            fontStyle,
            !value && "opacity-40",
          )}
        >
          {value || placeholder}
        </div>
      )}
    </div>
  );
};
