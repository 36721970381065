import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { CalendarIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { DateRange } from "react-date-range";

import { ABSOLUTE, CUSTOM_DATE } from "core/constants/timerange";
import { ICustomTimerange } from "core/hooks/useReportTimerange";
import {
  IGroupedTimerangeOption,
  ITimerangeOption,
} from "core/types/TimerangeOption";
import { cx } from "helpers/cx";

interface ITimerangeSection {
  options: IGroupedTimerangeOption[];
  label: string;
  timerangeType: number;
  relativeTimerange: ITimerangeOption;
  customTimerange: ICustomTimerange[];
  setCustomTimerange: (customTimerange: ICustomTimerange[]) => void;
  saveTimerange: (option: ITimerangeOption) => void;
  size?: string;
  tooltipText?: string;
  footerText?: string;
  customMenuButton?: React.ReactNode;
}

export const TimerangePicker: React.FC<ITimerangeSection> = ({
  options,
  label,
  timerangeType,
  relativeTimerange,
  customTimerange,
  setCustomTimerange,
  saveTimerange,
  size = "large",
  tooltipText,
  footerText = "Reports are in UTC time",
  customMenuButton,
}) => {
  const [customDateSelected, setCustomDateSelected] = useState<boolean>(false);

  return (
    <div className="relative">
      <Menu onOpen={() => setCustomDateSelected(false)}>
        <Tooltip isDisabled={!tooltipText} label={tooltipText} hasArrow>
          <MenuButton
            as={Button}
            onClick={(e) => e.stopPropagation()}
            variant={size === "small" ? "ghost" : "outline"}
            size={size === "small" ? "xs" : "sm"}
            color={size === "small" ? "gray.500" : "gray.900"}
            bg="white"
            data-testid="timepicker-button"
          >
            {customMenuButton ? (
              customMenuButton
            ) : (
              <div className="flex items-center justify-center">
                <CalendarIcon className={cx("mr-1 hidden h-4 w-4 lg:block")} />
                <span className="relative flex-grow truncate">{label}</span>
                {size !== "small" && (
                  <ChevronDownIcon
                    className="ml-1 h-4 w-4"
                    aria-hidden="true"
                  />
                )}
              </div>
            )}
          </MenuButton>
        </Tooltip>
        <MenuList>
          {options.map((optionGroup: IGroupedTimerangeOption) => (
            <>
              {optionGroup.options.map((option: ITimerangeOption) => {
                const isSelected =
                  timerangeType === ABSOLUTE
                    ? option.value === "fixed"
                    : option.value === relativeTimerange.value;
                return (
                  <>
                    {option.value === "fixed" && <MenuDivider my={0.5} />}
                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        setCustomDateSelected(option.value === "fixed");
                        if (option.value !== "fixed") saveTimerange(option);
                      }}
                      bg={isSelected ? "gray.100" : "white"}
                      py={1}
                      my={0.5}
                      _hover={{ bg: "gray.100" }}
                      data-testid={
                        option.value === "fixed" && "custom-timerange-button"
                      }
                    >
                      <CalendarIcon
                        className={cx(
                          "mr-2",
                          size === "small" ? "h-3.5 w-3.5" : "h-4 w-4",
                          isSelected
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-600",
                        )}
                      />
                      {option.label}
                      {isSelected && (
                        <CheckCircleIcon className="ml-auto h-4 w-4 text-gray-900" />
                      )}
                    </MenuItem>
                  </>
                );
              })}
            </>
          ))}
          <MenuDivider my={1} />
          <div className="flex items-center gap-1 px-2 pb-1 pt-2 text-gray-600">
            <InformationCircleIcon className="h-4 w-4" />
            <p className="text-xs">{footerText}</p>
          </div>
        </MenuList>
      </Menu>
      {customDateSelected && (
        <Popover data-testid="custom-timerange-popover">
          <Transition
            as={Fragment}
            show={true}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel
              static
              className="w-100 absolute right-0 z-overlay mt-2 origin-top-right rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <div onClick={(event) => event.stopPropagation()}>
                <DateRange
                  ranges={customTimerange}
                  maxDate={new Date()}
                  onChange={(item: { selection: ICustomTimerange }) => {
                    setCustomTimerange([item.selection]);
                  }}
                  moveRangeOnFirstSelection={false}
                  showDateDisplay={false}
                />
              </div>
              <div className="flex justify-end pb-4">
                <div className="flex w-1/2 justify-end pr-4">
                  <PopoverButton
                    as="button"
                    onClick={(event) => {
                      event.stopPropagation();
                      setCustomDateSelected(false);
                    }}
                    className="justify-center rounded-md bg-white px-3 py-2 text-xs font-semibold ring-1 ring-inset ring-gray-100 hover:bg-gray-50 focus-visible:ring-1"
                  >
                    Cancel
                  </PopoverButton>
                  <PopoverButton
                    as="button"
                    onClick={(event) => {
                      event.stopPropagation();
                      saveTimerange(CUSTOM_DATE);
                      setCustomDateSelected(false);
                    }}
                    className="ml-3 justify-center rounded-md bg-purple-500 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Apply
                  </PopoverButton>
                </div>
              </div>
            </PopoverPanel>
          </Transition>
        </Popover>
      )}
    </div>
  );
};
