import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import { useParams } from "react-router-dom";

import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import {
  GroupListSingleGroup,
  IGroup,
} from "core/components/Group/GroupListSingleGroup";
import InsightCard from "core/components/InsightCard/Index";
import { InsightReactions } from "core/components/InsightReactions";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useGroup } from "core/hooks/useGroup";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { useQueryParam } from "core/hooks/useQueryParam";
import { EmptyState } from "core/modules/reports/Milestones/EmptyState";
import { HottestGroupsList } from "core/modules/reports/report-types/GroupMilestones/HottestGroupsList";
import { hottestMockData as groupMockData } from "core/modules/reports/report-types/GroupMilestones/mockData/hottest";
import { HottestUsersList } from "core/modules/reports/report-types/UserMilestones/HottestUsersList";
import { hottestMockData as userMockData } from "core/modules/reports/report-types/UserMilestones/mockData/hottest";
import { isUserReportLevel } from "core/modules/reports/utils";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";
import { Unit } from "core/types/Unit";

const HottestContainer: React.FC<IReportInsight> = ({
  report,
  sharingSecretToken = null,
  previewMode,
  sharingMode,
  config,
  screenshotMode,
  reportLevel,
}) => {
  const isUserReport = isUserReportLevel(reportLevel);
  const mockData = isUserReport ? userMockData : groupMockData;
  const { reportId, groupId: encodedGroupId } = useParams<{
    reportId: string;
    groupId: string;
  }>() as {
    reportId: string;
    groupId: string;
  };
  const { activeAppObject } = useAppObjects();
  const groupIdQueryParam = useQueryParam("groupId");
  const groupId = groupIdQueryParam
    ? groupIdQueryParam
    : decodeURIComponent(encodedGroupId);
  const { group } = useGroup({ id: groupId });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const insight = config.insights.find(
    ({ slug }) =>
      slug === `milestones-hottest-${isUserReport ? "users" : "companies"}`,
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const { response, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      insight,
      insightParams: { truncate: true, startDate: group?.firstSeenAt },
      sharingSecretToken,
      previewMode,
      skip: sharingMode || !isSetupValid,
    });

  const onViewButtonClick = () => {
    if (!sharingMode && !previewMode) {
      onOpen();
    }
  };

  const dataKey = isUserReport ? "contacts" : "groups";
  // @ts-ignore
  const data = previewMode ? mockData?.[dataKey] : response?.data?.[dataKey];
  const unit = isUserReport
    ? Unit.Users
    : activeAppObject?.pluralName.toLowerCase();

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug} colSpan={0.5}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          />
          <Card.Body
            insight={insight}
            isLoading={!response || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            <Flex p={5} h={340} id="loaded" direction="column">
              {data?.map((item: any | IGroup) =>
                isUserReport ? (
                  <UserListSingleContact
                    key={item.userId}
                    id={item.userId}
                    traits={item.traits}
                    description={`Joined ${moment.utc(item?.milestone1Timestamp).fromNow()}`}
                  />
                ) : (
                  <GroupListSingleGroup
                    key={item.groupId}
                    id={item.groupId}
                    traits={item.traits}
                    groupType={item.groupType}
                    description={`Joined ${moment.utc(item?.milestone1Timestamp).fromNow()}`}
                  />
                ),
              )}
              {data?.length === 0 && (
                <EmptyState
                  description={`No ${unit} completed all milestones within the chosen time range`}
                />
              )}
            </Flex>
            {!sharingMode &&
              !previewMode &&
              (isUserReport ? (
                <HottestUsersList
                  report={report}
                  isOpen={isOpen}
                  onClose={onClose}
                  insight={insight}
                  startDate={group?.firstSeenAt}
                />
              ) : (
                <HottestGroupsList
                  report={report}
                  isOpen={isOpen}
                  onClose={onClose}
                  insight={insight}
                />
              ))}
          </Card.Body>
          <Card.Footer bg="white">
            <Flex w="100%" direction="column">
              <Flex>
                <Flex
                  px={4}
                  pt={4}
                  pb={0}
                  w="100%"
                  direction="column"
                  align="center"
                >
                  <Text fontSize="sm" textAlign="center" mb={3}>
                    Fastest {unit} to reach all milestones
                  </Text>
                  <Button
                    w="full"
                    borderRadius="lg"
                    onClick={onViewButtonClick}
                  >
                    <Text fontSize="sm" fontWeight="semibold">
                      View {unit}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
              <InsightReactions
                reactions={response?.reactions}
                insightId={insight.typeId}
                reportId={Number(reportId)}
              />
            </Flex>
          </Card.Footer>
        </Card.Container>
      )}
    </InsightCard>
  );
};

export default HottestContainer;
