import { ComponentDefaultProps, Flex, Skeleton } from "@chakra-ui/react";
import { ChevronDownIcon } from "@heroicons/react/16/solid";

import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/Components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import {
  IViewInsight,
  useUpdateViewInsightMutation,
} from "@/core/models/viewInsights";
import {
  Aggregation,
  AggregationToLabel,
  AggregationOptions,
  Measure,
} from "@/core/types/ViewInsight";
import { cn } from "@/lib/utils";
import { AggregationTypeToIcon } from "@/modules/Dashboard/InsightBuilder/SidePanel/AggregationSelector";
import { AudienceIndicator } from "core/components/ViewInsight/AudienceIndicator";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { IHightlight } from "core/types/TemplateConfig";

export interface ITitleProps extends ComponentDefaultProps {
  title: string;
  description: string;
  shouldBePaywalled: boolean;
  isLoading: boolean;
  highlight?: IHightlight;
  tooltipText?: string | React.ReactNode;
  icon?: React.ReactNode;
  color?: IColorPalette;
  viewInsight: IViewInsight;
}

const SUPPORTED_AGGREGATION_MENU = [Measure.ActiveUsers, Measure.NewUsers];

export const Title: React.FC<ITitleProps> = ({
  title,
  description,
  shouldBePaywalled,
  highlight,
  isLoading,
  icon,
  color = colors.purple,
  children,
  viewInsight,
}) => {
  const [updateViewInsight] = useUpdateViewInsightMutation();
  if (isLoading)
    return (
      <Flex direction="column" gridGap={2}>
        <Skeleton w="100px" h="20px" />
        <Skeleton w="150px" h="10px" />
        <Skeleton w="40px" h="35px" />
      </Flex>
    );

  return (
    <div className="w-full max-w-full">
      <div className="flex w-full justify-between gap-x-1">
        <div className="flex w-3/5 flex-col">
          <div
            className={cn(
              "flex w-full",
              viewInsight?.query && "relative top-1",
            )}
          >
            <AudienceIndicator viewInsight={viewInsight} />
            <div className="flex w-full flex-col items-start gap-0">
              <div className="flex w-full items-center gap-2">
                {icon ? icon : null}
                <Tooltip>
                  <TooltipTrigger asChild>
                    <p
                      className="max-w-[95%] truncate text-sm font-medium"
                      data-testid="highlight-card-title"
                    >
                      {title}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent>{title}</TooltipContent>
                </Tooltip>
              </div>
              {description && (
                <p className="max-w-full truncate text-xs font-normal text-gray-500">
                  {description}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-2/5">{children}</div>
      </div>
      <div
        className="mt-2 flex w-full flex-col"
        data-testid="highlight-card-title"
      >
        {!shouldBePaywalled &&
          highlight &&
          (highlight?.value || highlight?.description || highlight?.unit) && (
            <p
              className="mt-1 line-clamp-1 text-2xl font-medium leading-tight"
              key={viewInsight.aggregation}
            >
              {Math.round(Number(highlight?.value || 0)).toLocaleString()}
              {highlight?.unit}
              {SUPPORTED_AGGREGATION_MENU.includes(viewInsight.measure) ? (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <span
                      className="ml-1 whitespace-nowrap text-xs underline-offset-2 hover:underline hover:decoration-dotted"
                      style={{ color: color[500] }}
                    >
                      <div className="flex items-center gap-1">
                        {highlight?.description}
                        <ChevronDownIcon className="h-3 w-3" />
                      </div>
                    </span>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {AggregationOptions.map((aggregationValue) => {
                      const Icon = AggregationTypeToIcon[aggregationValue];
                      return (
                        <DropdownMenuItem
                          key={aggregationValue}
                          onClick={() => {
                            updateViewInsight({
                              appId: viewInsight.appId,
                              id: viewInsight.id,
                              aggregation: aggregationValue as Aggregation,
                            });
                          }}
                          className="flex cursor-pointer items-center gap-2"
                        >
                          <div className="flex items-center gap-2">
                            <span className="text-gray-600">
                              <Icon className="h-3 w-3" />
                            </span>
                            <span className="text-xs">
                              {
                                AggregationToLabel[
                                  aggregationValue as Aggregation
                                ]
                              }
                            </span>
                          </div>
                        </DropdownMenuItem>
                      );
                    })}
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : (
                <p
                  className="ml-1 inline-block text-xs"
                  style={{ color: color[500] }}
                >
                  {highlight?.description}
                </p>
              )}
            </p>
          )}
      </div>
    </div>
  );
};
