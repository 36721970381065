import { AiOptIn } from "@/modules/Settings/AiOptIn";
import { DataExport } from "@/modules/Settings/DataExport";

export const DataManagement: React.FC = () => {
  return (
    <>
      <h2 className="text-base font-semibold text-gray-900">Data management</h2>
      <AiOptIn />
      <DataExport />
    </>
  );
};
