import { Button } from "@chakra-ui/react";
import React from "react";

import { useApiKeys } from "core/hooks/useApiKeys";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { ApiKeyKeyType, ApiKeySource } from "core/models/apiKeys";

interface CreateApiKeyProps {
  source: ApiKeySource;
  keyType?: ApiKeyKeyType;
}

const CreateApiKey: React.FC<CreateApiKeyProps> = ({
  source,
  keyType = "write",
}) => {
  const { id: appId } = useCurrentApp();
  const { createApiKey } = useApiKeys({ appId, source, keyType });
  const onCreateApiKey = async (e: any) => {
    e.preventDefault();

    createApiKey();
  };

  return <Button onClick={onCreateApiKey}>Create new key</Button>;
};

export default CreateApiKey;
