import { ChevronDownIcon } from "@heroicons/react/16/solid";
import {
  Cog6ToothIcon,
  EllipsisVerticalIcon,
  IdentificationIcon,
} from "@heroicons/react/24/outline";
import { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "Components/ui/collapsible";
import {
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenu,
} from "Components/ui/dropdown-menu";
import { Input } from "Components/ui/input";
import { Tooltip, TooltipContent, TooltipTrigger } from "Components/ui/tooltip";
import { ClipboardButton } from "core/components/ClipboardButton";
import { ITrait } from "core/models/objects/traits";
import { Trait } from "modules/Object/Sections/Traits/Trait";

const CUTOFF = 10;

export const TraitsList: React.FC<{ traits: ITrait[] }> = ({ traits }) => {
  const { appId, appObjectId, objectId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const filteredTraits = useMemo(() => {
    if (!searchTerm || !traits) return traits;
    return traits.filter((trait) =>
      trait.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [traits, searchTerm]);

  if (!traits) return null;

  return (
    <div className="flex max-w-full flex-col gap-2 border-b border-gray-300 px-3 pb-2 pt-4">
      <div className="flex items-center justify-between">
        <p className="font-medium">Traits</p>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <EllipsisVerticalIcon className="size-4 text-muted-foreground" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem>
              <Cog6ToothIcon className="size-4 text-muted-foreground" />
              <p
                onClick={() => {
                  navigate(
                    `/a/${appId}/settings/objects/${appObjectId}/traits`,
                  );
                }}
              >
                Settings
              </p>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <Input
        size="sm"
        className="shadow-none"
        placeholder="Search traits"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="flex max-w-full flex-col divide-y divide-gray-100">
        {searchTerm ? (
          filteredTraits?.length ? (
            <div className="flex flex-col">
              {filteredTraits.map((trait) => (
                <Trait key={trait.name} trait={trait} />
              ))}
            </div>
          ) : (
            <p className="py-2 text-center text-sm text-gray-500">
              No traits found matching "{searchTerm}"
            </p>
          )
        ) : (
          <>
            <div className="flex w-full max-w-full items-center justify-between gap-x-4 py-2">
              <div className="flex items-center gap-1">
                <IdentificationIcon className="size-4 text-gray-600" />
                <p className="text-sm text-gray-600">Id</p>
              </div>
              <Tooltip>
                <TooltipTrigger asChild>
                  <p className="truncate text-right text-sm font-medium">
                    {objectId}
                  </p>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="flex items-center gap-2">
                    <p>{objectId}</p>
                    <ClipboardButton
                      value={objectId || ""}
                      showLabel={false}
                      bg="black"
                      mr={0}
                      color="white"
                      _hover={{ bg: "gray.800" }}
                    />
                  </div>
                </TooltipContent>
              </Tooltip>
            </div>
            {traits.slice(0, CUTOFF).map((trait) => (
              <Trait key={trait.name} trait={trait} />
            ))}
            {traits.length > CUTOFF && (
              <Collapsible open={isOpen} onOpenChange={setIsOpen}>
                <CollapsibleTrigger className="flex items-center gap-1 py-2 text-sm text-gray-500 hover:text-gray-700">
                  <ChevronDownIcon
                    className={`size-4 transition-transform duration-200 ${
                      isOpen ? "rotate-180" : ""
                    }`}
                  />
                  {isOpen ? "Hide" : "Show"} {traits.length - CUTOFF} more
                  traits
                </CollapsibleTrigger>
                <CollapsibleContent className="divide-y divide-gray-100">
                  {traits.slice(CUTOFF).map((trait) => (
                    <Trait key={trait.name} trait={trait} />
                  ))}
                </CollapsibleContent>
              </Collapsible>
            )}
          </>
        )}
      </div>
    </div>
  );
};
