import { Skeleton } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { GROUP_TRAIT, USER_TRAIT } from "core/constants/filters";
import { AND, HAS_ANY_VALUE } from "core/constants/traitFilterComponents";
import { TRAIT_LEVEL } from "core/constants/traits";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import { getCompanyName } from "core/helpers/companyName";
import { getContactEmail } from "core/helpers/contactEmail";
import { useLazyGetCompaniesQuery } from "core/models/companies";
import { useLazyGetContactsQuery } from "core/models/contacts";
import { useLazyGetGroupsQuery } from "core/models/groups";
import { IContact } from "core/types/Contact";
import { IGroup } from "core/types/Group";

export const PreviewMenu: React.FC<{
  level: TRAIT_LEVEL;
  entity?: IContact | IGroup;
  setEntity: (entity: IContact | IGroup) => void;
}> = ({ level, entity, setEntity }) => {
  const { appId } = useParams();
  const [query, setQuery] = useState("");

  const traitFilter = {
    type: level === TRAIT_LEVEL.USER ? USER_TRAIT : GROUP_TRAIT,
    default: true,
    body: {
      trait: level === TRAIT_LEVEL.USER ? "email" : "name",
      value: "",
      comparisonType: HAS_ANY_VALUE,
    },
  };

  const [getContacts, { data: contactsData, isFetching: isContactsLoading }] =
    useLazyGetContactsQuery();

  const [getGroups, { data: groupsData, isFetching: isGroupsLoading }] =
    useLazyGetGroupsQuery();

  const [
    getCompanies,
    { data: companiesData, isFetching: isCompaniesLoading },
  ] = useLazyGetCompaniesQuery();

  const params = {
    appId: Number(appId),
    page: 1,
    audienceFilters: {
      filterGroups: [{ filters: [traitFilter], joinOperator: AND }],
      joinOperator: AND,
    },
    searchQuery: query,
  };

  useEffect(() => {
    switch (level) {
      case TRAIT_LEVEL.USER:
        getContacts(params);
        break;
      case TRAIT_LEVEL.COMPANY:
        getCompanies(params);
        break;
      case TRAIT_LEVEL.GROUP:
        getGroups(params);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level, query]);

  useEffect(() => {
    if (entity) return;

    switch (level) {
      case TRAIT_LEVEL.USER:
        setEntity(contactsData?.contacts?.[0] as IContact);
        break;
      case TRAIT_LEVEL.COMPANY:
        setEntity(companiesData?.companies?.[0] as IGroup);
        break;
      case TRAIT_LEVEL.GROUP:
        setEntity(groupsData?.groups?.[0] as IGroup);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupsData?.groups?.length,
    contactsData?.contacts?.length,
    companiesData?.companies?.length,
  ]);

  function getName(e?: IContact | IGroup): string {
    if (!e) return "";

    if (level === TRAIT_LEVEL.USER) return getContactEmail(e) || "";

    return getCompanyName(e) || "";
  }

  function getEntities() {
    if (level === TRAIT_LEVEL.USER) return contactsData?.contacts;
    if (level === TRAIT_LEVEL.COMPANY) return companiesData?.companies;
    if (level === TRAIT_LEVEL.GROUP) return groupsData?.groups;
  }

  const displayName = getName(entity) || "";
  const entities = getEntities() || [];
  const isLoading = isContactsLoading || isCompaniesLoading || isGroupsLoading;

  return (
    <Popover className="relative">
      <>
        <p className="mb-2 text-sm font-medium">Preview with</p>

        <PopoverButton className="flex w-full cursor-pointer rounded-lg border bg-white px-3 py-2 text-sm shadow">
          {isLoading ? (
            <Skeleton w="full" h="20px" borderRadius="lg" />
          ) : (
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-1 ">
                <MagnifyingGlassIcon className="h-4 w-4 text-gray-600" />
                <p>{displayName}</p>
              </div>
              <ChevronUpDownIcon className="h-4 w-4  text-gray-600" />
            </div>
          )}
        </PopoverButton>

        <PopoverPanel className="flex flex-col">
          {({ close }) => (
            <>
              <Lifecycle onUnmount={() => setQuery("")} />
              <Command>
                <CommandInput
                  value={query}
                  onChange={(e) => setQuery(e.currentTarget.value)}
                  placeholder="Search..."
                />
                <CommandList>
                  {entities.length === 0 && <div>No results</div>}
                  {entities?.map((e: IGroup | IContact) => (
                    <CommandOption
                      key={e.id}
                      value={() => {
                        getName(e);
                        setEntity(e);
                        close();
                      }}
                    >
                      {getName(e)}
                    </CommandOption>
                  ))}
                </CommandList>
              </Command>
            </>
          )}
        </PopoverPanel>
      </>
    </Popover>
  );
};
