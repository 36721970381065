import moment from "moment";

import { withInView } from "core/components/InsightCard/withInView";
import { SimpleAreaChart } from "core/components/ViewInsight/Graphs/SimpleAreaChart";
import defaultTemplateConfig from "core/constants/report-configurations/report-types/defaultTemplateConfig";
import {
  ANY,
  AUDIENCE_TYPE,
  MULTI_EVENT_TYPE,
  OR,
  PAGE,
  TRACK,
} from "core/constants/report-setup";
import { Template } from "core/constants/templates";
import {
  DAU_GROUPED_OPTIONS,
  MAU_GROUPED_OPTIONS,
  WAU_GROUPED_OPTIONS,
} from "core/constants/timerange";
import AchievementsContainer from "core/modules/reports/Achievements/AchievementsContainer";
import { AdoptedOnceUsersContainer } from "core/modules/reports/report-types/FeatureRelease/AdoptedOnceUsersContainer";
import { WeeklyAdoptionContainer } from "core/modules/reports/report-types/FeatureRelease/Adoption/WeeklyAdoptionContainer";
import { EngagedUsersContainer } from "core/modules/reports/report-types/FeatureRelease/EngagedUsersContainer";
import { FeatureUsageInsights } from "core/modules/reports/report-types/FeatureRelease/FeatureUsageInsights";
import { FeatureUsersInsights } from "core/modules/reports/report-types/FeatureRelease/FeatureUsersInsights";
import { HeaderInsight } from "core/modules/reports/report-types/FeatureRelease/HeaderInsight";
import { LiveActivityContainer } from "core/modules/reports/report-types/FeatureRelease/LiveUsers/LiveActivityContainer";
import RetentionContainer from "core/modules/reports/report-types/FeatureRelease/RetentionContainer";
import { DailyUsageContainer } from "core/modules/reports/report-types/FeatureRelease/Usage/DailyUsageContainer";
import { FeatureUsageContainer } from "core/modules/reports/report-types/FeatureRelease/Usage/FeatureUsageContainer";
import { MonthlyUsageContainer } from "core/modules/reports/report-types/FeatureRelease/Usage/MonthlyUsageContainer";
import { WeeklyUsageContainer } from "core/modules/reports/report-types/FeatureRelease/Usage/WeeklyUsageContainer";
import { DailyUsersContainer } from "core/modules/reports/report-types/FeatureRelease/Users/DailyUsersContainer";
import { FeatureUsersContainer } from "core/modules/reports/report-types/FeatureRelease/Users/FeatureUsersContainer";
import { MonthlyUsersContainer } from "core/modules/reports/report-types/FeatureRelease/Users/MonthlyUsersContainer";
import { WeeklyUsersContainer } from "core/modules/reports/report-types/FeatureRelease/Users/WeeklyUsersContainer";
import { ITemplateConfig } from "core/types/TemplateConfig";

function transformData(data: any, dataKey: string) {
  return data?.usageInTimeRange?.map(({ date, ...d }: any) => ({
    x: moment(date).format("DD MMM"),
    y: d[dataKey],
    data: d,
    chartDate: date,
  }));
}

function buildAverageHighlight(data: any, interval: string) {
  if (!data) return { value: 0 };
  const totalUserCount = data?.usageInTimeRange?.reduce(
    (total: number, d: any) => total + d.uniqueUserCount,
    0,
  );
  const averageCount = Math.round(
    totalUserCount / data?.usageInTimeRange?.length,
  );
  return {
    value: averageCount,
    description: `avg per ${interval}`,
  };
}

function buildUsageHighlight(data: any) {
  if (!data) return { value: 0 };
  const usage = data?.usageInTimeRange?.reduce(
    (total: number, { usageCount }: { usageCount: number }) =>
      total + usageCount,
    0,
  );

  return { value: usage, description: "total" };
}

const LazyFeatureUsageInsights = withInView(FeatureUsageInsights);
const LazyFeatureUsageContainer = withInView(FeatureUsageContainer);
const LazyAdoptedOnceUsersContainer = withInView(AdoptedOnceUsersContainer, {
  colSpan: 1,
});
const LazyEngagedUsersContainer = withInView(EngagedUsersContainer, {
  colSpan: 1,
});
const LazyHeaderInsight = withInView(HeaderInsight);
const LazyWeeklyAdoptionContainer = withInView(WeeklyAdoptionContainer);
const LazyRetentionContainer = withInView(RetentionContainer);

const featureRelease: ITemplateConfig = {
  ...defaultTemplateConfig,
  isPopular: true,
  route: "feature-release",
  reportType: 8,
  subtitle: "See the adoption, retention and usage of your feature",
  title: "Feature report",
  template: Template.FeatureReport,
  aboutTemplate:
    "Get the full picture on how users adopt, engage and retain with your feature. Never ship a new feature in the dark anymore.",
  requiredEvents: ["An event for the feature"],
  sections: {
    user: [
      LiveActivityContainer,
      AchievementsContainer,
      FeatureUsersInsights,
      FeatureUsersContainer,
      // Below the fold
      LazyFeatureUsageInsights,
      LazyFeatureUsageContainer,
      LazyAdoptedOnceUsersContainer,
      LazyEngagedUsersContainer,
      LazyHeaderInsight,
      LazyWeeklyAdoptionContainer,
      LazyRetentionContainer,
    ],
    company: [
      LiveActivityContainer,
      AchievementsContainer,
      FeatureUsersInsights,
      FeatureUsersContainer,
      // Below the fold
      LazyFeatureUsageInsights,
      LazyFeatureUsageContainer,
      LazyAdoptedOnceUsersContainer,
      LazyEngagedUsersContainer,
      LazyHeaderInsight,
      LazyWeeklyAdoptionContainer,
      LazyRetentionContainer,
    ],
  },
  insights: [
    {
      typeId: 17,
      slug: "feature-live-users",
      title: "Live {APP_OBJECT_PLURAL_NAME}",
      section: LiveActivityContainer,
      slackAlertAvailable: false,
      shareable: false,
      copyable: false,
      hasYAxis: true,
    },
    {
      typeId: 30,
      slug: "feature-live-companies",
      title: "Live {APP_OBJECT_PLURAL_NAME}",
      section: LiveActivityContainer,
      slackAlertAvailable: false,
      shareable: false,
      copyable: false,
      hasYAxis: true,
    },
    {
      typeId: 16,
      slug: "feature-adoption-milestones",
      title: "Overall adoption and usage",
      section: AchievementsContainer,
      slackAlertAvailable: false,
      shareable: true,
      copyable: false,
      description: {
        title: "Learn more about feature milestones",
        content: `See how your feature is progressing.`,
      },
      hasYAxis: true,
    },
    {
      typeId: 34,
      slug: "feature-adoption-company-milestones",
      title: "Overall adoption and usage",
      section: AchievementsContainer,
      slackAlertAvailable: false,
      shareable: true,
      copyable: false,
      description: {
        title: "Learn more about feature milestones",
        content: `See how your feature is progressing.`,
      },
      hasYAxis: true,
    },
    {
      typeId: 15,
      slug: "latest-adoption",
      title: "Latest adoption",
      section: FeatureUsersInsights,
      shareable: true,
      slackAlertAvailable: true,
    },
    {
      typeId: 33,
      slug: "latest-company-adoption",
      title: "Latest adoption",
      section: FeatureUsersInsights,
      shareable: true,
      slackAlertAvailable: true,
    },
    {
      typeId: 0,
      slug: "daily-users",
      title: "Daily {APP_OBJECT_PLURAL_NAME}",
      section: DailyUsersContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about feature usage",
        content:
          "The number of times an adoption event was triggered daily. Click on the chart to see the users that triggered these events.",
      },
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "uniqueUserCount"),
        highlight: (data) => buildAverageHighlight(data, "day"),
        hasTimerangePicker: true,
        dateRangeOptions: DAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 1,
      slug: "weekly-users",
      title: "Weekly {APP_OBJECT_PLURAL_NAME}",
      section: WeeklyUsersContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about feature usage",
        content:
          "The number of times an adoption event was triggered weekly. Click on the chart to see the users that triggered these events.",
      },
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "uniqueUserCount"),
        highlight: (data) => buildAverageHighlight(data, "week"),
        hasTimerangePicker: true,
        dateRangeOptions: WAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 2,
      slug: "monthly-users",
      title: "Monthly {APP_OBJECT_PLURAL_NAME}",
      section: MonthlyUsersContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about feature usage",
        content:
          "The number of times an adoption event was triggered monthly. Click on the chart to see the users that triggered these events.",
      },
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "uniqueUserCount"),
        highlight: (data) => buildAverageHighlight(data, "month"),
        hasTimerangePicker: true,
        dateRangeOptions: MAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 18,
      slug: "daily-companies",
      title: "Daily {APP_OBJECT_PLURAL_NAME}",
      section: DailyUsersContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about feature usage",
        content:
          "The number of times an adoption event was triggered daily. Click on the chart to see the companies that triggered these events.",
      },
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "uniqueUserCount"),
        highlight: (data) => buildAverageHighlight(data, "day"),
        hasTimerangePicker: true,
        dateRangeOptions: DAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 19,
      slug: "weekly-companies",
      title: "Weekly {APP_OBJECT_PLURAL_NAME}",
      section: WeeklyUsersContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about feature usage",
        content:
          "The number of times an adoption event was triggered weekly. Click on the chart to see the companies that triggered these events.",
      },
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "uniqueUserCount"),
        highlight: (data) => buildAverageHighlight(data, "week"),
        hasTimerangePicker: true,
        dateRangeOptions: WAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 20,
      slug: "monthly-companies",
      title: "Monthly {APP_OBJECT_PLURAL_NAME}",
      section: MonthlyUsersContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about feature usage",
        content:
          "The number of times an adoption event was triggered monthly. Click on the chart to see the companies that triggered these events.",
      },
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "uniqueUserCount"),
        highlight: (data) => buildAverageHighlight(data, "month"),
        hasTimerangePicker: true,
        dateRangeOptions: MAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 13,
      slug: "latest-usage",
      title: "Latest usage",
      section: FeatureUsageInsights,
      shareable: true,
      slackAlertAvailable: true,
    },
    {
      typeId: 32,
      slug: "latest-company-usage",
      title: "Latest usage",
      section: FeatureUsageInsights,
      shareable: true,
      slackAlertAvailable: true,
    },
    {
      typeId: 21,
      slug: "daily-user-usage",
      title: "Daily usage",
      section: DailyUsageContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about feature usage",
        content:
          "The number of times an adoption event was triggered daily. Click on the chart to see the users that triggered these events.",
      },
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "usageCount"),
        highlight: (data) => buildUsageHighlight(data),
        hasTimerangePicker: true,
        dateRangeOptions: DAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 22,
      slug: "weekly-user-usage",
      title: "Weekly usage",
      section: WeeklyUsageContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about feature usage",
        content:
          "The number of times an adoption event was triggered weekly. Click on the chart to see the users that triggered these events.",
      },
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "usageCount"),
        highlight: (data) => buildUsageHighlight(data),
        hasTimerangePicker: true,
        dateRangeOptions: WAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 23,
      slug: "monthly-user-usage",
      title: "Monthly usage",
      section: MonthlyUsageContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about feature usage",
        content:
          "The number of times an adoption event was triggered monthly. Click on the chart to see the users that triggered these events.",
      },
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "usageCount"),
        highlight: (data) => buildUsageHighlight(data),
        hasTimerangePicker: true,
        dateRangeOptions: MAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 24,
      slug: "daily-company-usage",
      title: "Daily usage",
      section: DailyUsageContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about feature usage",
        content:
          "The number of times an adoption event was triggered daily. Click on the chart to see the companies that triggered these events.",
      },
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "usageCount"),
        highlight: (data) => buildUsageHighlight(data),
        hasTimerangePicker: true,
        dateRangeOptions: DAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 25,
      slug: "weekly-company-usage",
      title: "Weekly usage",
      section: WeeklyUsageContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about feature usage",
        content:
          "The number of times an adoption event was triggered weekly. Click on the chart to see the companies that triggered these events.",
      },
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "usageCount"),
        highlight: (data) => buildUsageHighlight(data),
        hasTimerangePicker: true,
        dateRangeOptions: WAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 26,
      slug: "monthly-company-usage",
      title: "Monthly usage",
      section: MonthlyUsageContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about feature usage",
        content:
          "The number of times an adoption event was triggered monthly. Click on the chart to see the companies that triggered these events.",
      },
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "usageCount"),
        highlight: (data) => buildUsageHighlight(data),
        hasTimerangePicker: true,
        dateRangeOptions: MAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 12,
      slug: "adopted-once-users",
      title: "Who used it once? 👻",
      section: AdoptedOnceUsersContainer,
      slackAlertAvailable: false,
      shareable: false,
      copyable: false,
      downloadable: false,
    },
    {
      typeId: 38,
      slug: "adopted-once-companies",
      title: "Who used it once? 👻",
      section: AdoptedOnceUsersContainer,
      slackAlertAvailable: false,
      shareable: false,
      copyable: false,
      downloadable: false,
    },
    {
      typeId: 11,
      slug: "engaged-users",
      title: "Who used it the most? 💪",
      section: EngagedUsersContainer,
      slackAlertAvailable: false,
      shareable: false,
      copyable: false,
      downloadable: false,
    },
    {
      typeId: 37,
      slug: "engaged-companies",
      title: "Who used it the most? 💪",
      section: EngagedUsersContainer,
      slackAlertAvailable: false,
      shareable: false,
      copyable: false,
      downloadable: false,
    },
    {
      typeId: 7,
      slug: "adoption-changes",
      title: "Latest adoption",
      section: HeaderInsight,
      shareable: true,
      slackAlertAvailable: true,
    },
    {
      typeId: 31,
      slug: "adoption-company-changes",
      title: "Latest adoption",
      section: HeaderInsight,
      shareable: true,
      slackAlertAvailable: true,
    },
    {
      typeId: 8,
      slug: "weekly-user-adoption",
      title: "How many {APP_OBJECT_PLURAL_NAME} adopted the feature over time?",
      section: WeeklyAdoptionContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about adoption over time",
        content:
          "The percentage of users that triggered an adoption event weekly. The starting point is the first time an adoption event was triggered.",
      },
      hasYAxis: true,
    },
    {
      typeId: 27,
      slug: "weekly-company-adoption",
      title: "How many {APP_OBJECT_PLURAL_NAME} adopted the feature over time?",
      section: WeeklyAdoptionContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about adoption over time",
        content:
          "The percentage of companies that triggered an adoption event weekly. The starting point is the first time an adoption event was triggered.",
      },
      hasYAxis: true,
    },
    {
      typeId: 14,
      slug: "user-feature-retention",
      title:
        "How many {APP_OBJECT_PLURAL_NAME} are returning to use the feature?",
      section: RetentionContainer,
      slackAlertAvailable: true,
      shareable: true,
      copyable: true,
      description: {
        title: "Learn more about feature retention",
        content: `The percentage of users that are returning to re-trigger the adoption events on a weekly basis after they've initially triggered them since the feature was released / last 3 months.`,
      },
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        transform: (data) =>
          data?.retentionData?.map(({ x, y }: any) => ({ x, y })),
        highlight: (data) => {
          return data?.retentionData
            ? {
                value:
                  data?.retentionData?.[data?.retentionData?.length - 1]?.y,
                unit: "%",
              }
            : { value: 0, unit: "%" };
        },
      },
    },
    {
      typeId: 28,
      slug: "company-feature-retention",
      title:
        "How many {APP_OBJECT_PLURAL_NAME} are returning to use the feature?",
      section: RetentionContainer,
      slackAlertAvailable: true,
      shareable: true,
      copyable: true,
      description: {
        title: "Learn more about feature retention",
        content: `The percentage of companies that are returning to re-trigger the adoption events on a weekly basis after they've initially triggered them since the feature was released / last 3 months.`,
      },
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        transform: (data) =>
          data?.retentionData?.map(({ x, y }: any) => ({ x, y })),
        highlight: (data) => {
          return data?.retentionData
            ? {
                value:
                  data?.retentionData?.[data?.retentionData?.length - 1]?.y,
                unit: "%",
              }
            : { value: 0, unit: "%" };
        },
      },
    },
  ],
  setup: {
    requiredSections: [2],
    eventOperator: OR,
    setupSections: [
      {
        id: "adoption_event",
        configKey: "events",
        title: "Adoption events",
        description:
          "Adoption means the purpose of your feature has been reached by users. Add events that capture the adoption of your feature.",
        type: MULTI_EVENT_TYPE,
        supportedEventTypes: [ANY, TRACK, PAGE],
        required: true,
        exampleSection: {
          title: "Adoption event example",
          description: "Sending a message to the newly created channel",
          illustration: "/adoption.png",
          event: "new_channel_message_sent",
        },
        validate: (events) =>
          events &&
          events.length > 0 &&
          events.length ===
            events.map((event) => event.id).filter((x) => x).length,
      },
      {
        id: "audience",
        configKey: "audience",
        title: "Audience",
        description: "Select an audience for your report",
        type: AUDIENCE_TYPE,
        supportedUserTypes: ["user"],
      },
    ],
  },
  validateSetup: (config) =>
    config &&
    config?.events &&
    config?.events?.length > 0 &&
    config?.events?.some((e) => e.name),
};

export default featureRelease;
