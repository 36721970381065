import { Icon } from "core/design-system/components/Icon";
import { cx } from "helpers/cx";

export const OnboardingCheck: React.FC<{
  isComplete: boolean;
  order?: number;
}> = ({ isComplete, order }) => {
  if (order) {
    return (
      <div
        className={cx(
          "flex size-5 items-center justify-center border",
          isComplete
            ? "border-green-400 bg-green-400"
            : "border-gray-200 bg-white",
          "mr-2 min-w-5 rounded-full text-center text-xs text-gray-600",
        )}
      >
        {isComplete ? (
          <Icon h={2.5} w={2.5} iconType="icon" name="check" color="white" />
        ) : (
          order.toString()
        )}
      </div>
    );
  }

  return (
    <div
      className={cx(
        `flex size-5 items-center justify-center border`,
        isComplete
          ? "border-green-400 bg-green-400"
          : "border-gray-200 bg-gray-200",
        "mr-2 min-w-5 rounded-full text-center text-xs text-gray-600",
      )}
    >
      <Icon h={2.5} w={2.5} iconType="icon" name="check" color="white" />{" "}
    </div>
  );
};
