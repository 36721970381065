import { MenuItem, Text, Tooltip } from "@chakra-ui/react";

import {
  EventType,
  PAGE,
  TRACK,
  ANY,
  FIRST,
} from "core/constants/report-setup";
import { AnyEventIcon } from "core/design-system/components/Icon/Custom/AnyEvent";
import { EventIcon } from "core/design-system/components/Icon/Custom/EventIcon";
import { FirstEventIcon } from "core/design-system/components/Icon/Custom/FirstEvent";
import { PageViewIcon } from "core/design-system/components/Icon/Custom/PageView";

interface IEventMenuItemProps {
  onAddEvent: (eventType: EventType) => void;
  index: number;
  eventType: EventType;
  isDisabled?: boolean;
}

export const EventMenuItem: React.FC<IEventMenuItemProps> = ({
  onAddEvent,
  eventType,
  index,
  isDisabled,
  ...props
}) => {
  return (
    <MenuItem
      onClick={() => {
        if (!isDisabled) {
          onAddEvent(eventType);
        }
      }}
      isDisabled={isDisabled}
      key={index}
      display="block"
      w="full"
      {...props}
    >
      <Tooltip
        label="You cannot use this event"
        isDisabled={!isDisabled}
        placement="top"
        hasArrow
      >
        <div className="flex w-full">
          <div className="mr-2 mt-1">
            {eventType === PAGE && <PageViewIcon />}
            {eventType === ANY && <AnyEventIcon />}
            {eventType === FIRST && <FirstEventIcon />}
            {eventType === TRACK && <EventIcon />}
          </div>
          <div className="flex w-full flex-col">
            <Text fontSize="sm" fontWeight="medium" color="gray.700">
              {eventType === ANY && "Any event"}
              {eventType === FIRST && "First event"}
              {eventType === TRACK && "Track event"}
              {eventType === PAGE && "Page view"}
            </Text>
            <Text fontSize="xs" color="gray.600">
              {eventType === ANY && "When users trigger any event"}
              {eventType === FIRST && "When a user triggers their first time"}
              {eventType === TRACK &&
                "When users trigger a specific track event"}
              {eventType === PAGE && "When users visit a specific page"}
            </Text>
          </div>
        </div>
      </Tooltip>
    </MenuItem>
  );
};
