import { UserPlusIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { DEMO_APP_ID } from "core/constants/appIds";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { ItemContainer } from "modules/Navigation/Sidebar/ItemContainer";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";

export const InviteLink: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/a/${appId}/settings/team`);
    // eslint-disable-next-line no-undef
    window.analytics.track(
      "invite_team_members_clicked",
      {
        app_id: appId,
      },
      { context: { groupId: appId } },
    );
  }, [navigate, appId]);

  if (appId === DEMO_APP_ID) return null;

  return (
    <ItemContainer onClick={handleClick}>
      <UserPlusIcon className="h-4 w-4 text-gray-600" />
      <ItemText>Invite team</ItemText>
    </ItemContainer>
  );
};
