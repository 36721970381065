import { ComponentDefaultProps } from "@chakra-ui/react";

import { Spinner } from "@/Components/ui/spinner";
import ApiKey from "@/modules/Settings/ApiKey";
import ApiKeysEmptyState from "@/modules/Settings/ApiKeysEmptyState";
import CreateApiKey from "@/modules/Settings/CreateApiKey";
import { useApiKeys } from "core/hooks/useApiKeys";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { IApiKey } from "core/models/apiKeys";
import { DataSource } from "core/models/onboardingChecklist";

interface IApiKeys extends ComponentDefaultProps {
  source: DataSource;
  showCreateApiKey?: boolean;
}

const ApiKeys: React.FC<IApiKeys> = ({ source, showCreateApiKey = true }) => {
  const { id: appId } = useCurrentApp();
  const { apiKeys, isFetching } = useApiKeys({ appId, source });

  if (isFetching && !apiKeys)
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size="medium" />
      </div>
    );

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col gap-2">
        {apiKeys?.length === 0 && <ApiKeysEmptyState />}
        {apiKeys?.map((key: IApiKey) => (
          <div
            key={`api-key-${key.id}`}
            className="flex items-center justify-between gap-2"
          >
            <ApiKey apiKey={key} />
          </div>
        ))}
      </div>
      {showCreateApiKey && (
        <div className="mt-3 flex justify-end">
          <CreateApiKey source={source} />
        </div>
      )}
    </div>
  );
};
export default ApiKeys;
