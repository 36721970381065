import { Button, Flex } from "@chakra-ui/react";

import DemoIllustration from "core/design-system/components/Icon/Illustrations/DemoIllustration.svg";
import { useDemo } from "core/hooks/useDemo";

export const DemoCard = () => {
  const { toggleDemo } = useDemo();

  return (
    <Flex
      direction="column"
      data-testid="demo-card-container"
      display={{ base: "none", xl: "flex" }}
    >
      <Flex
        p={5}
        gap={5}
        direction="column"
        w="100%"
        border="1px solid"
        borderRadius="lg"
        borderColor="gray.200"
      >
        <Flex
          w="100%"
          minH={184}
          border="1px solid"
          borderRadius="lg"
          borderColor="gray.100"
          backgroundImage={DemoIllustration}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          bgSize="cover"
        ></Flex>
        <Button onClick={toggleDemo} data-testid="try-demo-button">
          Try demo
        </Button>
      </Flex>
    </Flex>
  );
};
