import { Box, Flex, HStack, Progress, Text, Tooltip } from "@chakra-ui/react";

import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { thousandsToK } from "core/helpers/thousands";
import { Size } from "core/types/ViewInsight";
import { pluralize } from "helpers/pluralize";

export interface IHomeMilestone {
  name: string;
  convertedCount: number;
  percentage: number;
  isPlaceholder?: boolean;
  entityType?: "user" | "group";
}

export const Milestone: React.FC<{
  milestone: IHomeMilestone;
  entityType: "user" | "group";
  color?: IColorPalette;
  size?: Size;
}> = ({ milestone, entityType, color, size = Size.Small }) => {
  const convertedCount = thousandsToK(milestone.convertedCount);

  const entity =
    entityType === "user"
      ? pluralize(milestone.convertedCount, "user", "users")
      : pluralize(milestone.convertedCount, "company", "companies");
  const percentage = `${Math.round(milestone.percentage)}%`;

  return (
    <Box data-testid="milestone" w="100%">
      <Tooltip
        label={
          milestone.isPlaceholder
            ? `Setup more milestones to measure your activation`
            : `${milestone.name} has ${convertedCount} ${entity} (${percentage})`
        }
        hasArrow
      >
        <HStack
          align="center"
          spacing={0.5}
          direction="row"
          color="gray.600"
          _hover={{ color: colors.gray[800] }}
          py={1}
          borderRadius="lg"
          w="full"
        >
          <Flex w="full" align="center" justifyContent="space-between">
            <Flex w={size === Size.Small ? "50%" : "100%"}>
              <Text
                data-testid={`milestone-name-${milestone.name}`}
                as="div"
                ml={0}
                fontSize="sm"
                w="full"
                flexGrow="1"
                maxW={size === Size.Small ? "160px" : "250px"}
                noOfLines={1}
                color={milestone.isPlaceholder ? "gray.400" : "gray.600"}
              >
                {milestone.name}
              </Text>
            </Flex>
            <Flex w={size === Size.Small ? "30%" : "100%"}>
              <Progress
                borderRadius="lg"
                size="sm"
                w={"full"}
                colorScheme={color?.scheme || "purple"}
                value={Number(milestone.percentage)}
                data-testid={`milestone-convertedCount-${convertedCount}`}
              />
            </Flex>
            <Flex w="20%" justifyContent="flex-end">
              <Text
                justifySelf="flex-end"
                fontWeight="semibold"
                color={
                  milestone.isPlaceholder
                    ? "gray.400"
                    : color?.[500] || "purple.500"
                }
                textAlign="right"
                fontSize="xs"
                w="40px"
                data-testid={`milestone-percentage-${percentage}`}
              >
                {percentage}
              </Text>
            </Flex>
          </Flex>
        </HStack>
      </Tooltip>
    </Box>
  );
};
