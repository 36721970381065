import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import InsightCard from "core/components/InsightCard/Index";
import { InsightReactions } from "core/components/InsightReactions";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { TopPowerUsersList } from "core/modules/reports/report-types/PowerUsers/TopPowerUsersList";
import { IReportInsight } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

export interface ITopPowerUser {
  userId: string;
  traits: { [key: string]: string };
  numberOfActiveWeeks: number;
  numberOfActiveDays: number;
  averageNumberOfActiveDaysPerWeek: number;
}

export interface ITopPowerUsersData {
  contacts?: ITopPowerUser[];
}

const mockData = {
  contacts: [
    {
      userId: "Josephine Edamon",
      numberOfActiveWeeks: 5,
      numberOfActiveDays: 5,
      averageNumberOfActiveDaysPerWeek: 1,
      traits: { plan: "Pro " },
    },
    {
      userId: "Roy Carpent",
      numberOfActiveWeeks: 4,
      numberOfActiveDays: 4,
      averageNumberOfActiveDaysPerWeek: 1,
      traits: { plan: "Pro " },
    },
    {
      userId: "Anita James",
      numberOfActiveWeeks: 3,
      numberOfActiveDays: 3,
      averageNumberOfActiveDaysPerWeek: 1,
      traits: { plan: "Pro " },
    },
    {
      userId: "Alice Terpantine",
      numberOfActiveWeeks: 2,
      numberOfActiveDays: 2,
      averageNumberOfActiveDaysPerWeek: 1,
      traits: { plan: "Pro " },
    },
    {
      userId: "Gus Edams",
      numberOfActiveWeeks: 1,
      numberOfActiveDays: 1,
      averageNumberOfActiveDaysPerWeek: 1,
      traits: { plan: "Pro " },
    },
  ],
};

export const TopPowerUsersContainer: React.FC<IReportInsight> = ({
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken,
  report,
  config,
}) => {
  const { reportId } = useParams<{ reportId: string }>() as {
    reportId: string;
  };
  const insight = config.insights.find(
    ({ slug }) => slug === "top-power-users",
  ) as IInsightSection;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isSetupValid = config.validateSetup(report.config);
  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      insight,
      report,
      insightParams: { truncate: true },
      sharingSecretToken,
      previewMode,
      skip: !isSetupValid,
    });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: 3,
    insightParams: {},
    count: 1, // hard coded to 1 because we don't know count beforehand
  });

  const data = previewMode ? mockData : response?.data;

  const onViewButtonClick = () => {
    if (!sharingMode && !previewMode) {
      onOpen();
    }
  };

  const noData = data?.contacts?.length === 0;

  if (!insight) return null;

  return (
    <>
      <InsightCard>
        {(Card) => (
          <Card.Container insight={insight} id={insight.slug}>
            <Card.Header
              hasCaching={response?.hasCaching}
              screenshotMode={screenshotMode}
              showReportTitle={sharingMode}
              insight={insight}
              showActions={!sharingMode && !previewMode}
              sharingMode={sharingMode}
              config={config}
              report={report}
              refresh={onRefreshInsight}
              cachedAt={response?.cachedAt}
            />
            <Card.Body
              isLoading={!data || isLoading || isFetching}
              isPreviewMode={previewMode}
              isSetupValid={isSetupValid}
              error={error}
            >
              {noData && !isLoading ? (
                <Card.EmptyState />
              ) : (
                <Flex
                  p={5}
                  h={320}
                  id="loaded"
                  data-testid="loaded"
                  direction="column"
                >
                  {data?.contacts?.map((contact: ITopPowerUser) => (
                    <UserListSingleContact
                      key={contact.userId}
                      id={contact.userId}
                      traits={contact.traits}
                      description={`Power user for ${contact?.numberOfActiveWeeks} ${
                        contact?.numberOfActiveWeeks === 1 ? "week" : "weeks"
                      }, used ${contact?.averageNumberOfActiveDaysPerWeek} ${
                        contact?.averageNumberOfActiveDaysPerWeek === 1
                          ? "day"
                          : "days"
                      } per week on average`}
                    />
                  ))}
                </Flex>
              )}
            </Card.Body>
            {!sharingMode && !previewMode && !noData && !isLoading && (
              <Card.Footer topBorder={false}>
                <Flex w="100%" direction="column">
                  <Flex px={4} py={0} w="100%">
                    <Flex
                      px={4}
                      py={4}
                      w="100%"
                      bg="gray.50"
                      align="center"
                      justify="space-between"
                      borderRadius="lg"
                    >
                      <Flex w="70%">
                        <Text fontSize="sm">
                          These are the power users from the last 6 weeks. Look
                          at their user profiles to find common patterns amongst
                          them.
                        </Text>
                      </Flex>
                      <Button
                        h={10}
                        w="15%"
                        borderRadius="lg"
                        onClick={onViewButtonClick}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          View Users
                        </Text>
                      </Button>
                    </Flex>
                  </Flex>
                  <InsightReactions
                    reactions={response?.reactions}
                    insightId={insight.typeId}
                    reportId={Number(reportId)}
                  />
                </Flex>
              </Card.Footer>
            )}
          </Card.Container>
        )}
      </InsightCard>
      {!sharingMode && !previewMode && (
        <TopPowerUsersList
          report={report}
          insight={insight}
          isOpen={isOpen}
          onClose={onClose}
          csvExportLoading={csvExportLoading}
          requestCsvExport={requestCsvExport}
        />
      )}
    </>
  );
};
